import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { GetVatCategoryResponse, VatCategoriesApiClient } from '@wecore/sdk-healthcare';
import { isDefined, isOfType, resetValidation, validateState } from '@wecore/sdk-utilities';
import { IEventAggregator, inject } from 'aurelia';
import { PartialViewResults } from '../../../enums/partial-view-results';
import { BasePartialView } from '../../../infra/base-partial-view';
import { CacheService } from '../../../infra/cache-service';
import { ErrorHandler } from '../../../infra/error-handler';
import { State } from '../../../infra/store/state';
import { cleanTranslatables, validateTranslation } from '../../../infra/utilities';
import { ConfirmationOptions } from '../../../models/confirmation-options';
import { PartialView } from '../../../models/partial-view';
import { ModalService } from '../../../services/service.modals';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, VatCategoriesApiClient, ModalService)
export class PartialManageVatCategoriesEdit extends BasePartialView {
    public category: GetVatCategoryResponse;
    public validation = {
        name: true,
        percentage: true,
        valid: true
    };
    private categoryId: string;

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly categoriesApi: VatCategoriesApiClient,
        private readonly modalService: ModalService
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.categoryId = view.data.id;
    }

    public async attached(): Promise<void> {
        super
            .initView()
            .then(async () => {
                this.category = await this.categoriesApi.getById(this.categoryId, this.authenticated.workspace.id);

                // Delay showing content to prevent flickering.
                setTimeout(async () => {
                    this.baseLoaded = true;
                    await super.handleBaseLoaded();
                }, 250);
            })
            .catch((x) => this.errorHandler.handle('PartialManageVatCategoriesEdit.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }

    public async cancel(): Promise<void> {
        await super.remove({
            result: PartialViewResults.Cancelled,
            updateUrl: true
        });
    }

    public async save(): Promise<void> {
        const valid = this.validate();

        if (valid) {
            this.isLoading = true;
            try {
                cleanTranslatables(['translations'], this.category, 1);
                await this.categoriesApi.update(this.categoryId, this.authenticated.workspace.id, this.category);
                this.notifications.show(
                    this.t.tr('translation:partial-views.manage-vat-categories.notifications.save-successful.title'),
                    this.t
                        .tr('translation:partial-views.manage-vat-categories.notifications.save-successful.message') //
                        .replace('{entity}', `<span>'${this.category.translations[this.language]}'</span>`),
                    {
                        type: 'success',
                        duration: 3000
                    }
                );
                setTimeout(async () => this.remove({ result: PartialViewResults.Ok, updateUrl: true }), 250);
            } catch (e) {
                this.isLoading = false;
                await this.errorHandler.handle('[edit-vat-category]', e);
            }
        }
    }

    public async delete(): Promise<void> {
        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('partial-views.manage-vat-categories.questions.delete.title'),
                message: this.t
                    .tr('partial-views.manage-vat-categories.questions.delete.message') //
                    .replace('{entity}', `<span>'${this.category.translations[this.language]}'</span>`),
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        this.deleting = true;
                        try {
                            await this.categoriesApi.delete(this.categoryId, this.authenticated.workspace.id);
                            setTimeout(async () => this.remove({ result: PartialViewResults.Deleted, updateUrl: true }), 250);
                            this.notifications.show(
                                this.t.tr('translation:partial-views.manage-vat-categories.notifications.deleted-successfully.title'),
                                this.t
                                    .tr('translation:partial-views.manage-vat-categories.notifications.deleted-successfully.message') //
                                    .replace('{entity}', `<span>'${this.category.translations[this.language]}'</span>`),
                                { type: 'success', duration: 3000 }
                            );
                        } catch (e) {
                            this.deleting = false;
                            await this.errorHandler.handle('[delete-vat-category]', e);
                        }
                    }
                }
            })
        );
    }

    public manageTranslationsFor(property: string, required: boolean = false): void {
        this.manageTranslations({
            translations: this.category[property],
            callback: (updatedTranslations: any) => {
                this.category[property] = updatedTranslations;
            },
            required,
            type: property === 'description' ? 'textarea' : 'input'
        });
    }

    private validate(): boolean {
        resetValidation(this.validation);

        this.validation.name = validateTranslation(this.category.translations, this.language);
        this.validation.percentage = isDefined(this.category.percentage) && isOfType(Number(this.category.percentage), 'number');

        this.validation.valid = validateState(this.validation);

        return this.validation.valid;
    }
}
