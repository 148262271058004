import { I18N } from '@aurelia/i18n';
import { inject } from 'aurelia';
import { differenceInMinutes, format } from 'date-fns';

@inject(I18N)
export class DurationValueConverter {

    public constructor(
        private readonly i18n: I18N
    ){}

    public toView(start: Date, end: Date): string {
        const minutesDifference = differenceInMinutes(end, start);
        return `${minutesDifference} ${this.i18n.tr('translation:global.labels.minutes')}`;        
    }
}
