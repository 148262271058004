<section class="overflow-hidden confirm-modal min-h-64">
    <ux-dialog class="flex flex-col w-screen overflow-hidden rounded-none md:h-auto md:rounded-lg">
        <div class="fixed inset-0 z-[9999] overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div class="flex items-end justify-center min-h-screen p-4 text-center md:items-center">
                <div class="fixed inset-0 transition-opacity bg-gray-500/50" aria-hidden="true"></div>
                <span class="hidden h-full sm:inline-block sm:align-middle" aria-hidden="true">&#8203;</span>
                <div class="inline-block w-full p-6 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:max-w-lg sm:p-6 sm:align-middle">
                    <div class="absolute top-0 right-0 hidden pt-5 pr-4 sm:block">
                        <button
                            click.trigger="cancel()"
                            type="button"
                            class="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-1 focus:ring-gray-500 focus:ring-offset-2">
                            <span class="sr-only">Close</span>
                            <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                    <div class="sm:flex sm:items-start">
                        <div class="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-indigo-100 rounded-full sm:mx-0 sm:h-10 sm:w-10">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-indigo-700">
                                <path
                                    stroke-linecap="round"
                                    d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25h-9A2.25 2.25 0 002.25 7.5v9a2.25 2.25 0 002.25 2.25z" />
                            </svg>
                        </div>
                        <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                            <h3 class="text-lg font-medium leading-6 text-gray-900" t="modals.choose-link-or-upload.title"></h3>
                            <div class="mt-2">
                                <p class="mb-2 text-sm text-gray-500" t="modals.choose-link-or-upload.message"></p>
                                <ux-input autofocus.bind="true" t="[placeholder]translation:modals.choose-link-or-upload.placeholders.enter-link" value.two-way="link"></ux-input>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-col mt-5 gap-y-2 sm:mt-4 sm:flex sm:flex-row-reverse sm:space-y-0">
                        <ux-button color="primary" disabled.bind="isLoading" loading.bind="isLoading" click.trigger="ok()">
                            <span if.bind="link && link.length > 0 && link.trim() !== ''" class="text-sm pointer-events-none" t="global.buttons.add"></span>
                            <span else class="text-sm pointer-events-none" t="global.buttons.upload"></span>
                        </ux-button>
                        <ux-button disabled.bind="isLoading" class="sm:mr-2" mode="outline" color="dark" click.trigger="cancel()">
                            <span class="text-sm pointer-events-none" t="global.buttons.cancel"></span>
                        </ux-button>
                    </div>
                </div>
            </div>
        </div>
    </ux-dialog>
</section>
