<import from="converters/translate" />
<import from="converters/side-to-string" />
<import from="converters/medical-result-status-to-string" />
<import from="converters/keys" />
<import from="bx/bx-anatomical-region-selector/bx-anatomical-region-selector" />
<import from="./templates/template-action/template-action" />
<import from="./templates/template-question/template-question" />
<import from="./templates/template-questionnaire/template-questionnaire" />
<import from="./kpi-percentage-per-step/kpi-percentage-per-step" />

<div class="flex flex-col">
    <div mb-2.class="baseLoaded && calculatedResults.length > 0" class="flex items-center justify-between">
        <ux-label class="mb-1">
            <span t="translation:components.widgets.examination-conclusion.labels.conclusion"></span>
        </ux-label>
        <div if.bind="baseLoaded && calculatedResults.length > 0" class="w-full max-w-[300px]">
            <ux-select
                set-value-on-select.bind="false"
                disabled.bind="record.treatment.plans.some((x) => registrations[x.id].therapyPlan.results.some((y) => y.resultId === item.result.id))"
                onselect.trigger="handleGlobalSelect($event)"
                t="[placeholder]translation:components.widgets.examination-conclusion.placeholders.select-status-for-all">
                <ux-select-option repeat.for="status of MedicalRecordResultStatuses | keys" value.bind="status"> ${ status | medicalResultStatusToString | t } </ux-select-option>
            </ux-select>
        </div>
    </div>
    <template if.bind="baseLoaded">
        <template if.bind="calculatedResults.length === 0">
            <div class="flex flex-col items-center justify-center gap-2 border border-gray-200 rounded-lg p-7">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-primary">
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                </svg>
                <p class="text-sm" t="translation:components.widgets.examination-conclusion.messages.no-diagnoses"></p>
            </div>
        </template>
        <ul class="flex flex-col gap-y-2">
            <li repeat.for="item of calculatedResults" class="grid grid-cols-12 gap-3 border border-gray-200 rounded-lg p-7">
                <div class="flex items-center justify-between col-span-12 bg-white border-gray-200">
                    <div class="flex flex-col">
                        <span class="text-xs text-gray-500" t="translation:global.labels.differential-diagnosis"></span>
                        <p class="font-bold text-gray-800">${item.diagnosis.name | translate:language}</p>
                    </div>
                    <div class="flex items-center gap-1">
                        <ux-button mode="outline" color="dark" padding.bind="false" click.trigger="toggleSteps($index)">
                            <div -rotate-180.class=" item.expanded" class="flex h-[38px] w-[38px] items-center justify-center p-1 transition-all duration-150">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                </svg>
                            </div>
                        </ux-button>
                    </div>
                </div>
                <div class="grid grid-cols-12 col-span-12 gap-3">
                    <div class="col-span-3">
                        <ux-select
                            allow-clear.bind="true"
                            data.bind="$index"
                            disabled.bind="record.treatment.plans.some((x) => registrations[x.id].therapyPlan.results.some((y) => y.resultId === item.result.id))"
                            onselect.trigger="handleStatusSelected($event)"
                            onclear.trigger="handleStatusSelected($event)"
                            t="[placeholder]translation:partial-views.clinical-pathways.placeholders.select-status"
                            value.to-view="item.result.status">
                            <ux-select-option repeat.for="status of MedicalRecordResultStatuses | keys" value.bind="status"> ${ status | medicalResultStatusToString | t } </ux-select-option>
                        </ux-select>
                    </div>
                    <div class="col-span-3">
                        <ux-select
                            allow-clear="item.result.manuallyAdded"
                            disabled.bind="!item.result.manuallyAdded || record.treatment.plans.some((x) => registrations[x.id].therapyPlan.results.some((y) => y.resultId === item.result.id))"
                            data.bind="$index"
                            onselect.trigger="handleSideSelected($event)"
                            onclear.trigger="handleSideSelected($event)"
                            value.to-view="item.result.side">
                            <ux-select-option repeat.for="side of BodySides | keys" value.bind="side"> ${ side | sideToString | t } </ux-select-option>
                        </ux-select>
                    </div>
                    <div class="col-span-3">
                        <bx-anatomical-region-selector
                            on-select.bind="handleRegionSelected"
                            language.bind="language"
                            disabled.bind="!item.result.manuallyAdded || record.treatment.plans.some((x) => registrations[x.id].therapyPlan.results.some((y) => y.resultId === item.result.id))"
                            allow-clear="item.result.manuallyAdded"
                            disabled.bind="!item.result.manuallyAdded"
                            data.bind="$index"
                            workspace.bind="workspace"
                            t="[placeholder]translation:components.widgets.examination-conclusion.placeholders.select-location"
                            value.to-view="item.result.location.id">
                        </bx-anatomical-region-selector>
                    </div>
                </div>
                <ux-progress-bar if.bind="item.expanded" class="flex-1 col-span-12" progress.bind="item.percentageCompleted" color="primary">
                    <span class="flex flex-nowrap" t="translation:components.widgets.examination-conclusion.labels.percentage-entered"></span>
                </ux-progress-bar>
                <ux-progress-bar class="flex-1 col-span-12" progress.bind="item.conclusionAccuracy" color="info">
                    <span class="flex flex-nowrap" t="translation:components.widgets.examination-conclusion.labels.conclusion-accuracy"></span>
                </ux-progress-bar>

                <template if.bind="item.expanded">
                    <div class="grid grid-cols-12 col-span-12 gap-2 mt-1">
                        <!-- <div class="col-span-12">
                            <kpi-percentage-per-step></kpi-percentage-per-step>
                        </div> -->
                        <div class="grid w-full grid-cols-12 col-span-12 gap-1">
                            <p class="col-span-12 text-sm text-gray-600" t="translation:components.widgets.examination-conclusion.labels.connected-steps"></p>
                            <template repeat.for="step of item.steps">
                                <template-action
                                    step-result.bind="item"
                                    if.bind="step.type === MedicalExaminationTemplateItemStepTypes.Action"
                                    container.bind="step"
                                    container-index.bind="$index"
                                    item-index.bind="$parent.$index"
                                    step.bind="step"
                                    registration.bind="registrations[step.id]"
                                    language.bind="language">
                                </template-action>
                                <template-question
                                    step-result.bind="item"
                                    if.bind="step.type === MedicalExaminationTemplateItemStepTypes.Question"
                                    container.bind="step"
                                    container-index.bind="$index"
                                    item-index.bind="itemIndex"
                                    registration.bind="registrations[step.id]"
                                    step.bind="step"
                                    language.bind="language">
                                </template-question>
                                <template-questionnaire
                                    step-result.bind="item"
                                    if.bind="step.type === MedicalExaminationTemplateItemStepTypes.Questionnaire"
                                    container.bind="step"
                                    container-index.bind="$index"
                                    item-index.bind="itemIndex"
                                    registration.bind="registrations[step.id]"
                                    step.bind="step"
                                    language.bind="language">
                                </template-questionnaire>
                            </template>
                        </div>
                    </div>
                </template>
            </li>
        </ul>
    </template>
    <template else>
        <div class="flex flex-col items-center justify-center gap-2 border border-gray-200 rounded-lg p-7">
            <ux-spinner size="xs"></ux-spinner>
        </div>
    </template>
</div>
