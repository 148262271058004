import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import {
    AnatomicalRegionEntityReference,
    ColumnBreakpoint,
    CreateMedicalExaminationActionRequest,
    DifferentialDiagnosesRequirements,
    DifferentialDiagnosisCategoryEntityReference,
    DifferentialDiagnosisEntityReference,
    GetAnatomicalRegionResponse,
    GetDifferentialDiagnosisResponse,
    GetMedicalExaminationActionCategoryResponse,
    GetMedicalExaminationPhaseResponse,
    HealthcareCode,
    HealthcarePrice,
    InformationSheet,
    MedicalExaminationActionFlow,
    MedicalExaminationActionItem,
    MedicalExaminationActionItemTypes,
    MedicalExaminationActionRequirements,
    MedicalExaminationActionStepCategory,
    MedicalExaminationActionTypes,
    MedicalExaminationActionsApiClient,
    MedicalExaminationPhaseEntityReference,
    MedicalExaminationStepVisibilityRequirement,
    ResultCheck,
    StringBodySidesKeyValuePair,
    StringBooleanKeyValuePair,
    StringMedicalExaminationModelBoxKeyValuePair,
    StringStringKeyValuePair
} from '@wecore/sdk-healthcare';
import { isDefined, isNotDefined, isNotEmpty, isOfType, resetValidation, validateState } from '@wecore/sdk-utilities';

import { IEventAggregator, inject } from 'aurelia';
import { format } from 'date-fns';
import { PartialViewResults } from '../../../enums/partial-view-results';
import { BasePartialView } from '../../../infra/base-partial-view';
import { CacheService } from '../../../infra/cache-service';
import { ErrorHandler } from '../../../infra/error-handler';
import { CustomEvents } from '../../../infra/events';
import { PartialViews } from '../../../infra/partial-views';
import { clearItem } from '../../../infra/store/actions/copy-paste';
import { State } from '../../../infra/store/state';
import {
    addCategory,
    addStep,
    cleanExpectedResults,
    cleanTranslatables,
    cloneDeep,
    collapseSteps,
    pasteItem,
    removeAllSettings,
    removeItemSettings,
    setTranslation,
    validateTranslation
} from '../../../infra/utilities';
import { PartialView } from '../../../models/partial-view';
import { ViewOptions } from '../../../models/view-options';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, MedicalExaminationActionsApiClient)
export class PartialMedicalExaminationActionsCreate extends BasePartialView {
    public MedicalExaminationActionTypes: typeof MedicalExaminationActionTypes = MedicalExaminationActionTypes;
    public MedicalExaminationActionItemTypes: typeof MedicalExaminationActionItemTypes = MedicalExaminationActionItemTypes;
    public request: CreateMedicalExaminationActionRequest = new CreateMedicalExaminationActionRequest({
        displayOrder: 0,
        keywords: [],
        stepsToTake: [],
        categories: [],
        type: MedicalExaminationActionTypes.Test,
        healthcareSectors: [],
        resultCheck: new ResultCheck({
            items: []
        }),
        flow: new MedicalExaminationActionFlow({
            required: [],
            breakpoints: [],
            visibilityRequirements: [],
            differentialDiagnosesRequirements: [],
            modelBoxes: [],
            sides: [],
            connectedCategories: [],
            connectedSteps: [],
            medicalExaminationActionRequirements: []
        }),
        prices: [],
        synonyms: [],
        codes: []
    });
    public validation = {
        name: true,
        displayOrder: true,
        phase: true,
        steps: [],
        validSteps: true,
        any: true,
        valid: true
    };

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly actionsApi: MedicalExaminationActionsApiClient
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.request.name = setTranslation({}, this.language);
        this.request.description = setTranslation({}, this.language);
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                this.baseLoaded = true;
                this.subscriptions.push(this.events.subscribe(CustomEvents.ActionStepsChanged, () => this.handleItemsChanged()));

                await super.handleBaseLoaded();
            })
            .catch((x) => this.errorHandler.handle('PartialMedicalExaminationActionsCreate.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
        this.store.dispatch(clearItem, 'CopyActionItem');
    }

    public handleItemsChanged(): void {
        this.request.stepsToTake = [...(this.request.stepsToTake.length > 0 ? [this.request.stepsToTake.shift()] : []), ...this.request.stepsToTake];
        this.validation.steps = [...(this.validation.steps.length > 0 ? [this.validation.steps.shift()] : []), ...this.validation.steps];
    }

    public handlePhaseSelected = async (phase: GetMedicalExaminationPhaseResponse): Promise<void> => {
        this.request.phase = new MedicalExaminationPhaseEntityReference({
            id: phase.id,
            translations: phase.name
        });
    };

    public handleRegionSelected = async (region: GetAnatomicalRegionResponse): Promise<void> => {
        this.request.anatomicalRegion = new AnatomicalRegionEntityReference({
            id: region.id,
            translations: region.name
        });
    };

    public handleCategoriesChanged = async (categories: GetMedicalExaminationActionCategoryResponse[]): Promise<void> => {
        this.request.categories = categories.map(
            (c) =>
                new DifferentialDiagnosisCategoryEntityReference({
                    id: c.id,
                    translations: c.name
                })
        );
    };

    public handleDiagnosesChanged = async (diagnoses: GetDifferentialDiagnosisResponse[]): Promise<void> => {
        // Copy over the keywords used for the diagnoses
        // whilst keeping the manually added ones.
        this.request.keywords = [
            ...this.request.keywords, //
            ...(diagnoses.any() //
                ? diagnoses.selectMany<GetDifferentialDiagnosisResponse, string>((x) => x.keywords)
                : [])
        ].distinct((x) => x);
        this.request.differentialDiagnoses = diagnoses.map(
            (d) =>
                new DifferentialDiagnosisEntityReference({
                    id: d.id,
                    translations: d.name
                })
        );
    };

    public async cancel(): Promise<void> {
        await super.remove({
            result: PartialViewResults.Cancelled,
            updateUrl: true
        });
    }

    public async save(edit: boolean = false): Promise<void> {
        const valid = this.validate();
        if (valid) {
            this.isLoading = true;
            try {
                cleanTranslatables(
                    [
                        'name', //
                        'description',
                        'stepsToTake:category:name',
                        'stepsToTake:step:name',
                        'stepsToTake:step:description',
                        'stepsToTake:step:question',
                        'stepsToTake:step:placeholder'
                    ],
                    this.request,
                    3
                );
                cleanExpectedResults(this.request.stepsToTake);
                const response = await this.actionsApi.create(this.authenticated.workspace.id, this.request);
                this.notifications.show(
                    this.t.tr('translation:partial-views.medical-examination-actions.notifications.save-successful.title'),
                    this.t
                        .tr('translation:partial-views.medical-examination-actions.notifications.save-successful.message') //
                        .replace('{entity}', `<span>'${this.request.name[this.language]}'</span>`),
                    { type: 'success', duration: 3000 }
                );
                setTimeout(
                    async () =>
                        this.remove({
                            result: PartialViewResults.Ok,
                            data: { id: response.id, edit }
                        }),
                    250
                );
                this.isLoading = false;
            } catch (e) {
                // When the save() goes wrong make sure to re-set the translations object that
                // are possibly set to null and are not required for this request.
                // Otherwise the cleanTranslatables() will fail because the translations object is null.
                if (isNotDefined(this.request.description)) this.request.description = setTranslation({}, this.language);

                const resetTranslations = (items: MedicalExaminationActionItem[]): void => {
                    // Only NON-REQUIRED values (translations) should be reset to an object
                    // if something failed during saving the request.
                    for (const item of items) {
                        if (item.type === MedicalExaminationActionItemTypes.Category) {
                            // Insert translations to reset here.
                            // if (isNotDefined(item.category.description)) item.category.description = setTranslation({}, this.language);
                            // Reset the translations for all the children of the category.
                            resetTranslations(item.category.stepsToTake);
                        } else {
                            if (isNotDefined(item.step.description)) item.step.description = setTranslation({}, this.language);
                            if (isNotDefined(item.step.placeholder)) item.step.placeholder = setTranslation({}, this.language);
                        }
                    }
                };
                resetTranslations(this.request.stepsToTake);

                this.isLoading = false;
                await this.errorHandler.handle('[create-medical-examination-actions]', e);
            }
        }
    }

    public editAction = async (index: number, array: MedicalExaminationActionItem[], validationItems: any[]): Promise<void> => {
        // Note that JSON.parse(JSON.stringify()) will remove functions and dates from the object's properties.
        const item = MedicalExaminationActionItem.fromJS(JSON.parse(JSON.stringify(array[index])));
        const validation = JSON.parse(JSON.stringify(validationItems[index]));

        await this.removeChildViews();
        await this.addPartialView({
            view: this.partial.base,
            partial: PartialViews.EditMedicalItem.with({
                item,
                validation
            }).whenClosed(async (result: PartialViewResults, response: { item: MedicalExaminationActionItem; validation: any }) => {
                if (result === PartialViewResults.Ok) {
                    array[index] = MedicalExaminationActionItem.fromJS(response.item);
                    validationItems[index] = response.validation;

                    // Force array to refresh.
                    this.request.stepsToTake = [
                        ...(this.request.stepsToTake.length > 0 ? [this.request.stepsToTake.shift()] : []), //
                        ...cloneDeep(this.request.stepsToTake)
                    ];
                }
            }),
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                markItem: true
            })
        });
    };

    public manageTranslationsFor = (
        property: string, //
        required: boolean = false,
        index: number = -1,
        stepsToTake: MedicalExaminationActionStepCategory[] = null
    ): void => {
        let translations: any;
        if (property.includes(':')) {
            const [item, prop] = property.split(':');
            translations = stepsToTake[index][item][prop];
        } else translations = this.request[property];

        this.manageTranslations({
            translations,
            callback: (updatedTranslations: any) => {
                if (property.includes(':')) {
                    const [item, prop] = property.split(':');
                    stepsToTake[index][item][prop] = updatedTranslations;
                } else this.request[property] = updatedTranslations;
            },
            required,
            type: property.includes('description') ? 'textarea' : 'input'
        });
    };

    public addCategory = (index: number = -1): void => {
        collapseSteps(this.request.stepsToTake);
        addCategory(this.request.stepsToTake, this.validation.steps, index, this.language, 'MedicalExaminationActionItem');
    };

    public addStep = (index: number = -1): void => {
        collapseSteps(this.request.stepsToTake);
        addStep(this.request.stepsToTake, this.validation.steps, index, this.language, 'MedicalExaminationActionItem');
    };

    public removeActionItem = (index: number, category: MedicalExaminationActionStepCategory, validation: []): void => {
        let item: MedicalExaminationActionItem;

        if (isDefined(category)) {
            item = category.stepsToTake[index];

            category.stepsToTake.splice(index, 1);
            validation.splice(index, 1);
        } else {
            item = this.request.stepsToTake[index];

            this.request.stepsToTake.splice(index, 1);
            this.validation.steps.splice(index, 1);
        }

        if (item.type === MedicalExaminationActionItemTypes.Category) removeAllSettings(this.request.flow, item.category.stepsToTake);
        removeItemSettings(this.request.flow, item);

        this.handleItemsChanged();

        this.events.publish(CustomEvents.ExaminationStepSettingsChanged);
    };

    public pasteItem = async (index: number = -1): Promise<void> => {
        const copyItem = this.state.clipboard.actionItem;
        if (isNotDefined(copyItem)) return;

        await pasteItem(
            this.store, //
            this.state,
            this.request.stepsToTake,
            this.validation.steps,
            index,
            this.request.flow,
            'MedicalExaminationActionItem'
        );
    };

    public collapseOrExpandAll(command: 'collapse' | 'expand'): void {
        const collapseOrExpand = (items: MedicalExaminationActionItem[], command: 'collapse' | 'expand'): void => {
            for (const item of items) {
                if (item.type === MedicalExaminationActionItemTypes.Category) {
                    item.category.attributes.expanded = command === 'expand';
                    collapseOrExpand(item.category.stepsToTake, command);
                } else item.step.attributes.expanded = command === 'expand';
            }
        };
        collapseOrExpand(this.request.stepsToTake, command);
    }

    public openSettings = async (step: MedicalExaminationActionItem, categoryId: string, categoryName: string): Promise<void> => {
        const label = step.type === MedicalExaminationActionItemTypes.Category ? 'global.labels.category' : 'global.labels.step';
        const name = step.type === MedicalExaminationActionItemTypes.Category ? step.category.name[this.language] : step.step.name[this.language];

        const view = PartialViews.MedicalStepSettings.with({
            language: this.language,
            container: this.request.flow,
            item: step,
            active: this.request,
            settings: ['required', 'columns', 'visibility', 'differentialDiagnosis', 'actions', 'categories', 'steps', 'sides'],
            label,
            name,
            categoryId,
            stepCategoryName: categoryName
        }).whenClosed(
            async (
                result: PartialViewResults,
                response: {
                    breakpoints: ColumnBreakpoint[];
                    required: StringBooleanKeyValuePair;
                    repeatDuringEvaluation: StringBooleanKeyValuePair;
                    visibilityRequirements: MedicalExaminationStepVisibilityRequirement[];
                    ddRequirements: DifferentialDiagnosesRequirements[];
                    actionRequirements: MedicalExaminationActionRequirements[];
                    modelBox: StringMedicalExaminationModelBoxKeyValuePair;
                    side: StringBodySidesKeyValuePair;
                    categories: StringStringKeyValuePair[];
                    connectedSteps: StringStringKeyValuePair[];
                }
            ) => {
                if (result === PartialViewResults.Ok) {
                    // Remove old breakpoints and add new ones.
                    this.request.flow.breakpoints = [
                        ...this.request.flow.breakpoints.filter((x) => x.id !== step.id), //
                        ...response.breakpoints.map(ColumnBreakpoint.fromJS)
                    ];

                    // Remove old requirements and and new one if present.
                    this.request.flow.required = this.request.flow.required.filter((x) => x.key !== step.id);
                    if (isDefined(response.required))
                        this.request.flow.required.push(
                            StringBooleanKeyValuePair.fromJS(response.required) //
                        );

                    // Remove old side and add new one if present.
                    this.request.flow.sides = this.request.flow.sides.filter((x) => x.key !== step.id);
                    if (isDefined(response.side))
                        this.request.flow.sides.push(
                            StringBodySidesKeyValuePair.fromJS(response.side) //
                        );

                    // Remove old visibilityRequirements and add new ones.
                    this.request.flow.visibilityRequirements = [
                        ...this.request.flow.visibilityRequirements.filter((x) => x.id !== step.id), //
                        ...response.visibilityRequirements.map(MedicalExaminationStepVisibilityRequirement.fromJS)
                    ];

                    // Remove old differentialDiagnosesRequirements and add new ones.
                    this.request.flow.differentialDiagnosesRequirements = [
                        ...this.request.flow.differentialDiagnosesRequirements.filter((x) => x.id !== step.id), //
                        ...response.ddRequirements.map(DifferentialDiagnosesRequirements.fromJS)
                    ];

                    // Remove old medicalExaminationActionRequirements and add new ones.
                    this.request.flow.medicalExaminationActionRequirements = [
                        ...this.request.flow.medicalExaminationActionRequirements.filter((x) => x.id !== step.id), //
                        ...response.actionRequirements.map(MedicalExaminationActionRequirements.fromJS)
                    ];

                    // Remove old connected categories and add new ones.
                    this.request.flow.connectedCategories = [
                        ...this.request.flow.connectedCategories.filter((x) => x.key !== step.id), //
                        ...response.categories.map(StringStringKeyValuePair.fromJS)
                    ];

                    // Remove old connected steps and add new ones.
                    this.request.flow.connectedSteps = [
                        ...this.request.flow.connectedSteps.filter((x) => x.key !== step.id), //
                        ...response.connectedSteps.map(StringStringKeyValuePair.fromJS)
                    ];

                    this.events.publish(CustomEvents.ExaminationStepSettingsChanged);
                }
            }
        );

        await this.removeChildViews();
        await this.addPartialView({
            view: this.partial.base,
            partial: view,
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                markItem: true
            })
        });
    };

    public async informationSheet(): Promise<void> {
        this.addPartialView({
            view: this.partial.base,
            partial: PartialViews.InformationSheet.with({
                config: {
                    mode: 'edit',
                    language: this.language
                },
                sheet: this.request.informationSheet
            }).whenClosed(async (result: PartialViewResults, sheet: InformationSheet) => {
                if (result === PartialViewResults.Ok) {
                    this.request.informationSheet = sheet;
                }
            }),
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                markItem: true,
                replace: true
            })
        });
    }

    public async createOrEditPrice(index: number = -1): Promise<void> {
        const price = index > -1 ? this.request.prices[index] : null;
        await this.removeChildViews();
        await this.addPartialView({
            view: this.partial.base,
            partial: PartialViews.HealthcarePrices.with({
                price: cloneDeep(price), //
                index,
                prices: this.request.prices
            }).whenClosed(async (result: PartialViewResults, data: { price: HealthcarePrice; index: number }) => {
                if (result === PartialViewResults.Ok) {
                    if (data.index > -1) this.request.prices[data.index] = data.price;
                    else this.request.prices.push(data.price);

                    this.request.prices = [
                        ...(this.request.prices.length > 0 ? [this.request.prices.shift()] : []), //
                        ...this.request.prices
                    ];
                }
            }),
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                markItem: true,
                updateUrl: false
            })
        });
    }

    public async removePrice(index: number): Promise<void> {
        this.request.prices.splice(index, 1);
    }

    public formatDescription(index: number): string {
        let description = '';
        if (isDefined(this.request.prices[index].periodStart)) description += format(this.request.prices[index].periodStart, 'dd-MM-yyyy');
        if (isDefined(this.request.prices[index].periodStart)) description += ' - ';
        if (isDefined(this.request.prices[index].periodEnd)) description += format(this.request.prices[index].periodEnd, 'dd-MM-yyyy');

        if (isDefined(this.request.prices[index].insurer)) description += ` (${this.request.prices[index].insurer.translations[this.language]})`;

        return description;
    }

    public async addCode(): Promise<void> {
        await this.removeChildViews();
        this.addPartialView({
            view: this.partial.base,
            partial: PartialViews.HealthcareCodes.with({ language: this.language }).whenClosed(async (result: PartialViewResults, data: { code: HealthcareCode }) => {
                if (result === PartialViewResults.Ok) {
                    this.request.codes.push(data.code);
                }
            }),
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                markItem: true,
                replace: true
            })
        });
    }

    public async editCode(index: number): Promise<void> {
        await this.removeChildViews();
        await this.addPartialView({
            view: this.partial.base,
            partial: PartialViews.HealthcareCodes.with({
                code: this.request.codes[index],
                language: this.language,
                index
            }).whenClosed(async (result: PartialViewResults, data: { code: HealthcareCode; index: number }) => {
                if (result === PartialViewResults.Ok) {
                    this.request.codes[data.index] = data.code;

                    this.request.codes = [
                        ...(this.request.codes.length > 0 ? [this.request.codes.shift()] : []), //
                        ...this.request.codes
                    ];
                }
            }),
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                markItem: true,
                replace: true
            })
        });
    }

    public async removeCode(index: number): Promise<void> {
        await this.removeChildViews();
        this.request.codes.splice(index, 1);
    }

    private validate(): boolean {
        this.validation.name = validateTranslation(this.request.name, this.language);
        this.validation.displayOrder = isNotEmpty(this.request.displayOrder) && isOfType(Number(this.request.displayOrder), 'number');
        this.validation.phase = isDefined(this.request.phase);
        this.validation.any = isDefined(this.request.stepsToTake) && this.request.stepsToTake.length > 0;

        let validSteps = true;
        // Recursively validate each step to take.
        const validateSteps = (items: MedicalExaminationActionItem[], validation: any[]): void => {
            for (let index = 0; index < items.length; index++) {
                const item = items[index];
                // Make sure all properties are set to 'true'
                resetValidation(validation[index]);
                // Validate the object.
                if (item.type === MedicalExaminationActionItemTypes.Category) {
                    validation[index].name = validateTranslation(item.category.name, this.language);
                    validateSteps(item.category.stepsToTake, validation[index].steps);
                    // After validation children check if all are valid.
                    validation[index].childrenValid = validation[index].steps.every((x: any) => {
                        if (isDefined(x.childrenValid)) return x.valid && x.childrenValid;
                        return x.valid;
                    });
                } else {
                    // The reset is validation on the view 'partial-medical-examination-actions-edit-item'.
                    validation[index].name = validateTranslation(item.step.name, this.language);
                    validation[index].question = validateTranslation(item.step.question, this.language);
                }

                // Set validation status of the current item.
                validation[index].valid = validateState(validation[index]) && validation[index].choices.every(validateState);
                // // Only mutate the 'validSteps' property if it is still valid.
                if (validSteps) validSteps = validation[index].valid;
            }
        };
        validateSteps(this.request.stepsToTake, this.validation.steps);
        this.validation.validSteps = validSteps;

        this.validation.valid = validateState(this.validation) && validSteps;
        return this.validation.valid;
    }
}
