import { I18N } from '@aurelia/i18n';
import { BlobStorageAttachment } from '@wecore/sdk-attachments';
import {
    CreateMedicalRecordRegistrationBatchRequest,
    GetSchedulerItemResponse,
    GetMedicalRecordRegistrationResponse,
    GetMedicalRecordResponse,
    GetPatientResponse,
    MedicalExaminationFlow,
    MedicalExaminationTemplateItemStep,
    MedicalExaminationTemplateItemStepTypes,
    MedicalRecordEntityReference,
    MedicalRecordRegistrationEntityReference,
    MedicalRecordRegistrationTypes,
    MedicalRecordRegistrationsApiClient,
    MedicalTherapiesApiClient,
    MedicalTherapyEntityReference,
    MedicalTherapyEvaluation,
    MedicalTherapyEvaluationContainer,
    MedicalTherapyEvaluationFlow,
    MedicalTherapyEvaluationItem,
    MedicalTherapyEvaluationItemTypes,
    MedicalTherapyExecution,
    MedicalTherapyExecutionContainer,
    MedicalTherapyExecutionFlow,
    MedicalTherapyExecutionItem,
    MedicalTherapyExecutionItemTypes,
    MedicalTherapyPlanFlow,
    MedicalTherapyPlanItem,
    MedicalTherapyPlanItemTypes,
    MedicalWidgetTypes,
    UpdateMedicalRecordRegistrationBatchRequest,
    UpdateMedicalRecordRegistrationBatchRequestItem,
    WidgetResult,
    WidgetResultTypes
} from '@wecore/sdk-healthcare';
import { guid, isDefined, isNotDefined, resetValidation } from '@wecore/sdk-utilities';
import { IDisposable, IEventAggregator, bindable, containerless, inject } from 'aurelia';

import { SortTherapyPlansOrExecutionsValueConverter } from '../../../../../converters/sort-therapy-plans-or-executions';
import { CustomEvents } from '../../../../../infra/events';
import {
    cleanActionOrTherapyResultsAndAttributes,
    cleanQuestionAnswers,
    cleanTranslatables,
    cloneDeep,
    questionValidation,
    setActionStepOrTherapyStepValidation,
    setExpectedValuesForActionOrTherapy,
    setQuestionExpectedValues,
    setTranslation,
    validateStep,
    widgetValidation
} from '../../../../../infra/utilities';
import { ConfirmationOptions } from '../../../../../models/confirmation-options';
import { FlattenedExaminationStep } from '../../../../../models/flattened-examination-step';
import { PartialView } from '../../../../../models/partial-view';
import { SelectedFile } from '../../../../../models/selected-file';
import { StepState } from '../../../../../models/step-state';
import { ViewOptions } from '../../../../../models/view-options';
import { WidgetRegistration } from '../../../../../models/widget-registration';
import { ModalService } from '../../../../../services/service.modals';

@containerless
@inject(MedicalRecordRegistrationsApiClient, IEventAggregator, ModalService, I18N, MedicalTherapiesApiClient)
export class WidgetExaminationTreatment {
    @bindable() public flattened: FlattenedExaminationStep[];
    @bindable() public record: GetMedicalRecordResponse;
    @bindable() public registration: GetMedicalRecordRegistrationResponse;
    @bindable() public registrations: { [key: string]: GetMedicalRecordRegistrationResponse };
    @bindable() public flow: MedicalExaminationFlow;
    @bindable() public xScrollContainer: string;
    @bindable() public step: MedicalExaminationTemplateItemStep;
    @bindable() public states: { [key: string]: StepState };
    @bindable() public patient: GetPatientResponse;
    @bindable() public required: boolean;
    @bindable() public language: string;
    @bindable() public workspace: string;
    @bindable() public validation: any;
    @bindable() public widgets: WidgetRegistration[] = [];
    @bindable() public addPartial: (partial: PartialView, options: ViewOptions) => Promise<void>;
    @bindable() public loading: (show: boolean) => void;
    @bindable() public onFileSelected: (file: SelectedFile) => void;
    @bindable() public onFileRemoved: (file: SelectedFile) => void;
    @bindable() public manageTranslations: (translations: any, callback: (translations: any) => void, type: 'textarea' | 'input', required: boolean) => void;

    public subscriptions: IDisposable[] = [];
    public execution: GetMedicalRecordRegistrationResponse;
    public evaluation: GetMedicalRecordRegistrationResponse;
    public MedicalTherapyExecutionItemTypes: typeof MedicalTherapyExecutionItemTypes = MedicalTherapyExecutionItemTypes;
    public validationIndex: number;

    public constructor(
        private readonly registrationsApi: MedicalRecordRegistrationsApiClient, //
        private readonly events: IEventAggregator,
        private readonly modalService: ModalService,
        private readonly t: I18N,
        private readonly therapiesApi: MedicalTherapiesApiClient
    ) {}

    public async bound(): Promise<void> {
        if (isNotDefined(this.record.treatment.executions)) this.record.treatment.executions = [];
        this.init();

        // Push the widget with its callbacks for use later on.
        if (isDefined(this.registration?.widget?.result)) this.registration.widget.result.type = WidgetResultTypes.Dynamic;

        this.widgets.push(
            new WidgetRegistration({
                stepId: this.step.id,
                type: MedicalWidgetTypes.ExaminationTreatment,
                onSave: async (): Promise<void> => {
                    // Clean unused labels.
                    for (const execution of this.record.treatment.executions) {
                        cleanTranslatables(['label'], this.registrations[execution.id].therapyExecution, 1);
                        if (isNotDefined(this.registrations[execution.id]) || isNotDefined(this.registrations[execution.id].therapyExecution)) continue;
                        cleanActionOrTherapyResultsAndAttributes(this.registrations[execution.id].therapyExecution.value.stepsToTake);
                    }

                    for (const id of this.record.treatment.evaluations.map((x) => x.id)) {
                        cleanTranslatables(['label'], this.registrations[id].therapyEvaluation, 1);
                        for (const step of this.registrations[id].therapyEvaluation.value.stepsToTake) {
                            if (isNotDefined(this.registrations[step.id])) continue;

                            switch (step.type) {
                                case MedicalTherapyEvaluationItemTypes.Action:
                                    if (isNotDefined(this.registrations[step.id].action)) break;
                                    cleanActionOrTherapyResultsAndAttributes(this.registrations[step.id].action.stepsToTake);
                                    break;
                                case MedicalTherapyEvaluationItemTypes.Question:
                                    if (isNotDefined(this.registrations[step.id].question)) break;
                                    cleanQuestionAnswers(this.registrations[step.id].question);
                                    break;
                                case MedicalTherapyEvaluationItemTypes.Questionnaire:
                                    if (isNotDefined(this.registrations[step.id].questionnaire)) break;
                                    for (const item of this.registrations[step.id].questionnaire.questions) {
                                        cleanQuestionAnswers(item.question);
                                        // Also clean the attributes of the question registration.
                                        // This is because we save temporary data in the attributes
                                        // which has no need to be saved in the database.
                                        item.attributes = {};
                                    }
                                    break;
                                case MedicalTherapyEvaluationItemTypes.Widget:
                                    if (isNotDefined(this.registrations[step.id].widget)) break;
                                    if (
                                        isDefined(this.registrations[step.id].widget.result) && //
                                        isNotDefined(this.registrations[step.id].widget.result.value)
                                    )
                                        this.registrations[step.id].widget.result = null;

                                    break;
                            }
                        }
                    }

                    // Update all executions and evaluations.
                    const [] = await Promise.all([
                        // Updates of all the evaluation STEPS registrations (e.g. question, questionnaires, actions etc.) is done
                        // by the Save() function of the record detail page, which is triggered when the user saves the record.
                        // So no need to update the evaluation STEPS here.
                        // We do however, need to update the evaluations and executions here.
                        this.registrationsApi.updateBatch(
                            this.workspace,
                            new UpdateMedicalRecordRegistrationBatchRequest({
                                items: this.record.treatment.evaluations.map(
                                    (x) =>
                                        new UpdateMedicalRecordRegistrationBatchRequestItem({
                                            id: x.id,
                                            body: this.registrations[x.id]
                                        })
                                )
                            })
                        ),
                        // Update all existing executions.
                        this.registrationsApi.updateBatch(
                            this.workspace,
                            new UpdateMedicalRecordRegistrationBatchRequest({
                                items:
                                    // Take all existing executions that exist in the treatment part of the record.
                                    this.record.treatment.executions
                                        // The rest, we need to update.
                                        .map(
                                            (execution) =>
                                                new UpdateMedicalRecordRegistrationBatchRequestItem({
                                                    id: execution.id,
                                                    body: this.registrations[execution.id]
                                                })
                                        )
                            })
                        )
                    ]);
                },
                validate: (_: WidgetResult, __: any): boolean => {
                    return true;
                },
                refresh: async (): Promise<void> => {},
                onFileUploaded: async (_: BlobStorageAttachment): Promise<void> => {}
            })
        );

        this.subscriptions = [
            ...(this.subscriptions ?? []),
            this.events.subscribe(CustomEvents.MedicalRecordTreatmentStarted, () => this.init()) //
        ];
    }

    public detaching(): void {
        this.subscriptions.forEach((subscription) => subscription.dispose());
    }

    public setActive(id: string): void {
        // Set the active execution and evaluation to null.
        // This forces the components to re-render.
        this.execution = null;
        this.evaluation = null;

        this.execution = this.registrations[id];
        this.validationIndex = this.record.treatment.executions.findIndex((x) => x.id === this.execution.id);

        // Set the active execution in the state of the treatment widget.
        // So that we can keep track of the active execution when the user
        // saves the record and set the same execution as active when the
        // widget is re-rendered.
        this.states[this.step.id].other.active = this.execution.id;

        const evaluation = this.record.treatment.evaluations.find((x) => this.registrations[x.id].therapyEvaluation.executionId === this.execution.id);
        if (isDefined(evaluation)) this.evaluation = this.registrations[evaluation.id];
    }

    public async startExecution(plan: GetMedicalRecordRegistrationResponse): Promise<void> {
        const validateTherapy = (steps: MedicalTherapyPlanItem[], validation: any[], flow: MedicalTherapyPlanFlow): boolean => {
            let valid = true;

            for (let sI = 0; sI < steps.length; sI++) {
                resetValidation(validation[sI]);
                const item = steps[sI];

                const flat = this.flattened.find((x) => x.item.id === item.id);

                if (item.type === MedicalTherapyPlanItemTypes.Category) {
                    const validStep = validateTherapy(item.category.stepsToTake, validation[sI].steps, flow);
                    // Mark category as (in)valid.
                    if (validation[sI].valid) validation[sI].valid = validStep;
                    // Mark entire therapy as (in)valid only if it is currently valid.
                    if (valid) valid = validation[sI].valid;
                } else {
                    if (isNotDefined(flat) || !flat.isVisible) continue;
                    validation[sI] = validateStep(item, flow, validation[sI], this.record, []);
                    // Mark the entire therapy as (in)valid only if the therapy is currently still valid.
                    if (valid) valid = validation[sI].valid;
                }
            }

            return valid;
        };

        // First validate each plan.
        for (const item of this.record.treatment.plans) {
            if (isNotDefined(this.registrations[item.id]) || isNotDefined(this.registrations[item.id].therapyPlan)) continue;

            const indexOfValidation = this.validation.plans.findIndex((x: any) => x.id === item.id);
            // Validate all the categories and steps in the action.
            const validPlanSteps = validateTherapy(
                this.registrations[item.id].therapyPlan.value.stepsToTake,
                this.validation.plans[indexOfValidation].steps,
                this.registrations[item.id].therapyPlan.value.flow
            );
            this.validation.plans[indexOfValidation].valid = validPlanSteps;
        }

        if (this.validation.plans.some((x: any) => !x.valid)) {
            // Change to treatment widget if we can find it.
            const treatmentPlanIndex = this.record.examination.template.phases.findIndex((x) =>
                x.stepsToTake.some((step) => step.type === MedicalExaminationTemplateItemStepTypes.Widget && this.registrations[step.id].widget.type === MedicalWidgetTypes.ExaminationTreatmentPlan)
            );
            if (treatmentPlanIndex > -1) {
                this.validation.phases[treatmentPlanIndex].valid = false;
            }

            return;
        }

        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('translation:components.widgets.examination-treatment.questions.start-execution.title'),
                message: this.t.tr('translation:components.widgets.examination-treatment.questions.start-execution.message'),
                type: 'warning',
                btnOk: this.t.tr('global.buttons.start'),
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        if (isNotDefined(this.record.treatment.executions)) this.record.treatment.executions = [];

                        const therapy = await this.therapiesApi.getById(plan.therapy.id, this.workspace, false, true);

                        const item = new GetMedicalRecordRegistrationResponse({
                            record: new MedicalRecordEntityReference({
                                id: this.record.id,
                                translations: this.record.examination.name
                            }),
                            createdAt: new Date(),
                            type: MedicalRecordRegistrationTypes.TherapyExecution,
                            therapy: new MedicalTherapyEntityReference({
                                id: therapy.id,
                                translations: therapy.name
                            }),
                            therapyExecution: new MedicalTherapyExecutionContainer({
                                trackingNumber: 0,
                                prices: therapy.prices,
                                codes: therapy.codes,
                                planId: plan.id,
                                results: plan.therapyPlan.results,
                                label: setTranslation({}, this.language),
                                value: isDefined(therapy.execution)
                                    ? (this.prepare(therapy.execution) as MedicalTherapyExecution)
                                    : new MedicalTherapyExecution({
                                          stepsToTake: [],
                                          flow: new MedicalTherapyExecutionFlow({
                                              required: [],
                                              breakpoints: [],
                                              visibilityRequirements: [],
                                              connectedCategories: [],
                                              connectedSteps: []
                                          })
                                      })
                            })
                        });

                        const response = await this.registrationsApi.create(this.workspace, true, item);
                        const steps = setActionStepOrTherapyStepValidation(response.therapyExecution.value.stepsToTake, []);
                        this.validation.executions.push({ id: response.id, valid: true, steps });

                        this.registrations[response.id] = response;

                        this.record.treatment.executions.push(
                            new MedicalRecordRegistrationEntityReference({
                                id: response.id,
                                translations: therapy.name
                            })
                        );

                        // Mark the plan as started.
                        this.registrations[plan.id].therapyPlan.startedTreatment = true;
                        this.states[this.step.id].other.active = response.id;

                        // Force a re-flatten of the examination so that it includes the new treatment executions.
                        this.events.publish(CustomEvents.ExaminationReFlatten, { triggeredByAnswer: false });
                        // Publish the event that the medical record has been updated.
                        // This will trigger the full save of the record.
                        this.events.publish(CustomEvents.MedicalRecordUpdated, { record: this.record });
                    }
                }
            })
        );
    }

    public async startEvaluation(): Promise<void> {
        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('translation:components.widgets.examination-treatment.questions.add-evaluation.title'),
                message: this.t.tr('translation:components.widgets.examination-treatment.questions.add-evaluation.message'),
                type: 'warning',
                btnOk: this.t.tr('global.buttons.start'),
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        const therapy = await this.therapiesApi.getById(this.execution.therapy.id, this.workspace, false, true);

                        const item = new GetMedicalRecordRegistrationResponse({
                            createdAt: new Date(),
                            record: new MedicalRecordEntityReference({
                                id: this.record.id,
                                translations: this.record.examination.name
                            }),
                            type: MedicalRecordRegistrationTypes.TherapyEvaluation,
                            therapy: this.execution.therapy,
                            therapyEvaluation: new MedicalTherapyEvaluationContainer({
                                prices: therapy.prices,
                                codes: therapy.codes,
                                executionId: this.execution.id,
                                results: this.registrations[this.execution.id].therapyExecution.results,
                                label: setTranslation({}, this.language),
                                value: isDefined(therapy.evaluation)
                                    ? therapy.evaluation
                                    : new MedicalTherapyEvaluation({
                                          stepsToTake: [],
                                          flow: new MedicalTherapyEvaluationFlow({
                                              required: [],
                                              breakpoints: [],
                                              visibilityRequirements: []
                                          })
                                      })
                            })
                        });
                        const stepsToCreate = [];
                        const steps = await this.therapiesApi.getEvaluationSteps(therapy.id, this.workspace);

                        const register = (step: MedicalTherapyEvaluationItem) => {
                            const action = steps.actions.find((x) => x.id === step.action?.id);
                            const question = steps.questions.find((x) => x.id === step.question?.id);
                            const questionnaire = steps.questionnaires.find((x) => x.id === step.questionnaire?.id);
                            const widget = steps.widgets.find((x) => x.id === step.widget?.id);
                            let type: MedicalRecordRegistrationTypes;
                            if (isDefined(action)) type = MedicalRecordRegistrationTypes.Action;
                            else if (isDefined(question)) type = MedicalRecordRegistrationTypes.Question;
                            else if (isDefined(questionnaire)) type = MedicalRecordRegistrationTypes.Questionnaire;
                            else if (isDefined(widget)) type = MedicalRecordRegistrationTypes.Widget;
                            const registration = new GetMedicalRecordRegistrationResponse({
                                record: new MedicalRecordEntityReference({
                                    id: this.record.id,
                                    translations: this.record.examination.name
                                }),
                                stepId: step.id,
                                type,
                                action,
                                question,
                                questionnaire,
                                widget
                            });
                            stepsToCreate.push(registration);
                        };

                        const evaluation = await this.registrationsApi.create(this.workspace, true, item);
                        const validation = { id: evaluation.id, valid: true, steps: [] };

                        for (const step of therapy.evaluation.stepsToTake) {
                            register(step);
                        }

                        this.validation.evaluations.push(validation);

                        const createdSteps = await this.registrationsApi.createBatch(
                            this.workspace,
                            new CreateMedicalRecordRegistrationBatchRequest({
                                // Make sure not to override the step ID with the ID of the registration (first param).
                                useIdAsStepId: false,
                                items: stepsToCreate
                            })
                        );

                        this.registrations[evaluation.id] = evaluation;
                        for (const item of createdSteps) this.registrations[item.stepId] = item;

                        for (const step of therapy.evaluation.stepsToTake) {
                            switch (step.type) {
                                case MedicalTherapyEvaluationItemTypes.Action:
                                    setExpectedValuesForActionOrTherapy(this.registrations[step.id].action.stepsToTake);
                                    const steps = setActionStepOrTherapyStepValidation(this.registrations[step.id].action.stepsToTake, []);
                                    validation.steps.push({
                                        valid: true,
                                        steps: steps
                                    });
                                    break;
                                case MedicalTherapyEvaluationItemTypes.Question:
                                    setQuestionExpectedValues(this.registrations[step.id].question);
                                    validation.steps.push(questionValidation(step.id));
                                    break;
                                case MedicalTherapyEvaluationItemTypes.Questionnaire:
                                    const v = { valid: true, questions: [] };
                                    for (const item of this.registrations[step.id].questionnaire.questions) {
                                        setQuestionExpectedValues(item.question);
                                        v.questions.push(questionValidation(item.id));
                                    }
                                    validation.steps.push(v);
                                    break;
                                case MedicalTherapyEvaluationItemTypes.Widget:
                                    validation.steps.push(widgetValidation(step.id));
                                    break;
                            }
                        }

                        this.record.treatment.evaluations.push(
                            new MedicalRecordRegistrationEntityReference({
                                id: evaluation.id,
                                translations: therapy.name
                            })
                        );
                        this.evaluation = evaluation;

                        // Force a re-flatten of the examination so that it includes the new treatment evaluations.
                        this.events.publish(CustomEvents.ExaminationReFlatten, { triggeredByAnswer: false });
                        // Publish the event that the medical record has been updated.
                        // This will trigger the full save of the record.
                        this.events.publish(CustomEvents.MedicalRecordUpdated, { record: this.record });
                    }
                }
            })
        );
    }

    public handleExecutionSchedulerItemSelected = async (schedulerItem: GetSchedulerItemResponse): Promise<void> => {
        this.execution.schedulerItem = schedulerItem;
    };

    public handleEvaluationSchedulerItemSelected = async (schedulerItem: GetSchedulerItemResponse): Promise<void> => {
        this.evaluation.schedulerItem = schedulerItem;
    };

    private init(): void {
        // Set unused labels.
        for (const execution of this.record.treatment.executions) {
            if (isNotDefined(this.registrations[execution.id].therapyExecution.label)) this.registrations[execution.id].therapyExecution.label = setTranslation({}, this.language);
        }

        // Check if the widget has an active execution is its state.
        const index = this.record.treatment.executions.findIndex((x) => x.id === this.states[this.step.id].other.active);

        const converter = new SortTherapyPlansOrExecutionsValueConverter();

        let idOfFirstExecution: string;
        if (this.record.treatment.executions.any()) {
            // Group the executions by therapy. This will result to a map with the therapy name as key and the executions as value.
            const groupedByTherapies = converter.toView(this.record.treatment.executions, this.registrations, 'therapyExecution');
            // Conver the map to an array so we can access the executions.
            const groupedTherapiesAsArray = Array.from(groupedByTherapies, ([name, value]) => ({ name, value }));
            // Get the executions from the first therapy.
            const executions = Array.from(groupedTherapiesAsArray[0]?.value, ([name, value]) => ({ name, value }));
            // Get the ID of the first execution.
            idOfFirstExecution = executions[0].value[0];
        }

        if (this.record.treatment.executions.any()) {
            this.setActive(
                index > -1 //
                    ? // Set the active execution to the execution that is active in the widget step state.
                      this.record.treatment.executions[index].id
                    : // Otherwise set the active execution to the first therapy execution from the grouped arrays.
                      idOfFirstExecution
            );
        }
    }

    private prepare(plan: MedicalTherapyExecution): MedicalTherapyExecution {
        if (isNotDefined(plan)) return plan;

        // Make sure to clear the references to the plan.
        const copy = cloneDeep(plan);

        const modify = (item: MedicalTherapyExecutionItem): void => {
            // Only set the copiedFrom when it is not already set.
            // Because we are going to use this property to match the steps
            // in the flow. The ID in the flow never changes, so this
            // values shouldn't change either.
            if (isNotDefined(item.copiedFrom)) item.copiedFrom = item.id;
            item.id = guid();
        };

        const generateIds = (items: MedicalTherapyExecutionItem[]) => {
            for (const item of items) {
                if (item.type === MedicalTherapyExecutionItemTypes.Category) {
                    modify(item);
                    generateIds(item.category.stepsToTake);
                } else modify(item);
            }
        };

        // Generate new IDs for each of the steps.
        generateIds(copy.stepsToTake);
        setExpectedValuesForActionOrTherapy(copy.stepsToTake);

        return copy;
    }
}
