import { SchedulerSettings } from '@wecore/sdk-management';
import { State } from '../state';

/**
 * Updates the scheduler settings in the state.
 * @param state The state to update.
 * @param settings The updated settings.
 */
export function updateSchedulerSettings(state: State, settings: SchedulerSettings): State {
    const newState = { ...state };
    newState.schedulerSettings = settings;
    return newState;
}
