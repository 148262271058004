<import from="converters/translate" />
<import from="converters/keys" />
<import from="converters/result-type-to-string" />
<import from="converters/input-type-to-string" />
<import from="converters/affix-position-to-string" />
<import from="converters/result-matcher-type-to-string" />
<import from="converters/get-ids" />
<import from="converters/get-values" />
<import from="converters/sort" />
<import from="bx/bx-diagnosis-multi-selector/bx-diagnosis-multi-selector" />
<import from="bx/bx-questionnaire-multi-selector/bx-questionnaire-multi-selector" />
<import from="bx/bx-medical-question-category-multi-selector/bx-medical-question-category-multi-selector" />
<import from="bx/bx-examination-phase-selector/bx-examination-phase-selector" />
<import from="components/component-choices-selector/component-choices-selector" />
<import from="components/component-choices-selector/component-choices-selector" />
<import from="components/component-slider-labels-selector/component-slider-labels-selector" />

<template class="!flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex overflow-hidden gap-x-2" css.bind="styles">
        <div class="flex flex-col flex-1 w-full h-full overflow-hidden">
            <div ref="scrollContainer" class="flex flex-col flex-1 px-8 overflow-y-auto">
                <template if.bind="baseLoaded">
                    <div class.bind="hasScrolled ? 'border-b' : ''" class="sticky top-0 z-40 flex justify-between pt-8 pb-3 bg-white border-gray-200">
                        <p class="font-medium text-gray-400" t="translation:partial-views.medical-questions.create.title"></p>
                        <ux-dropdown border.bind="false" type="full" color="dark" size="sm" padding.bind="false" mode="outline" placement="bottom-right">
                            <div au-slot="label" class="p-1 text-gray-600 rounded-full ring-gray-400 focus:outline-none focus:ring-1">
                                <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512">
                                    <path
                                        fill="currentColor"
                                        d="M64 368C90.51 368 112 389.5 112 416C112 442.5 90.51 464 64 464C37.49 464 16 442.5 16 416C16 389.5 37.49 368 64 368zM64 208C90.51 208 112 229.5 112 256C112 282.5 90.51 304 64 304C37.49 304 16 282.5 16 256C16 229.5 37.49 208 64 208zM64 144C37.49 144 16 122.5 16 96C16 69.49 37.49 48 64 48C90.51 48 112 69.49 112 96C112 122.5 90.51 144 64 144z" />
                                </svg>
                            </div>
                            <ux-dropdown-option click.trigger="informationSheet()">
                                <span class="text-sm" t="global.buttons.information-sheet"></span>
                            </ux-dropdown-option>
                        </ux-dropdown>
                    </div>
                    <div class="flex flex-col flex-1 pb-5">
                        <div class="grid grid-cols-12 gap-3">
                            <div class="flex flex-col col-span-12">
                                <div class="flex items-center space-x-1">
                                    <ux-label class="mb-1">
                                        <span t="global.labels.name"></span>
                                    </ux-label>
                                    <span class="flex items-center h-3 text-red-500">*</span>
                                </div>
                                <ux-textarea
                                    action.bind="c => manageTranslationsFor('name', true)"
                                    rows="1"
                                    value.two-way="request.name[language]"
                                    valid.bind="validation.name"
                                    t="[placeholder]partial-views.medical-questions.placeholders.name">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M10.5 21l5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 016-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 01-3.827-5.802" />
                                    </svg>
                                </ux-textarea>
                            </div>
                            <div class="flex flex-col col-span-12">
                                <div class="flex items-center space-x-1">
                                    <ux-label class="mb-1">
                                        <span t="partial-views.medical-questions.labels.select-examination-phase"></span>
                                    </ux-label>
                                    <span class="flex items-center h-3 text-red-500">*</span>
                                </div>
                                <bx-examination-phase-selector
                                    on-select.bind="handlePhaseSelected"
                                    language.bind="language"
                                    workspace.bind="authenticated.workspace.id"
                                    t="[placeholder]global.placeholders.select-examination-phase"
                                    valid.bind="validation.phase"
                                    value.two-way="request.phase.id">
                                </bx-examination-phase-selector>
                            </div>
                            <div class="flex flex-col col-span-12">
                                <ux-label class="mb-1">
                                    <span t="partial-views.medical-questions.labels.select-questionnaire"></span>
                                </ux-label>
                                <bx-questionnaire-multi-selector
                                    values.bind="question.questionnaires | getIds"
                                    workspace.bind="authenticated.workspace.id"
                                    language.bind="language"
                                    on-change.bind="handleQuestionnaireSelected"
                                    t="[placeholder]partial-views.medical-questions.placeholders.select-differential-diagnoses">
                                </bx-questionnaire-multi-selector>
                            </div>
                        </div>
                        <ux-body-divider></ux-body-divider>
                        <div class="grid grid-cols-2 gap-3">
                            <!-- The question content -->
                            <div class="flex flex-col col-span-2">
                                <div class="flex items-center space-x-1">
                                    <ux-label class="mb-1">
                                        <span t="partial-views.medical-questions.labels.question-content"></span>
                                    </ux-label>
                                    <span class="flex items-center h-3 text-red-500">*</span>
                                </div>
                                <ux-textarea
                                    rows="1"
                                    action.bind="c => manageTranslationsFor('content', true)"
                                    value.two-way="request.content[language]"
                                    valid.bind="validation.content"
                                    t="[placeholder]partial-views.medical-questions.placeholders.question-content">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M10.5 21l5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 016-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 01-3.827-5.802" />
                                    </svg>
                                </ux-textarea>
                            </div>
                            <!-- Placeholder input -->
                            <div class="flex flex-col col-span-2">
                                <ux-label class="mb-1">
                                    <span t="partial-views.medical-questions.labels.question-placeholder"></span>
                                </ux-label>
                                <ux-input
                                    action.bind="c => manageTranslationsFor('placeholder')"
                                    value.two-way="request.placeholder[language]"
                                    t="[placeholder]partial-views.medical-questions.placeholders.question-placeholder">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M10.5 21l5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 016-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 01-3.827-5.802" />
                                    </svg>
                                </ux-input>
                            </div>
                            <!-- The answer type selector -->
                            <div class="flex flex-col">
                                <ux-label class="mb-1">
                                    <span t="partial-views.medical-questions.labels.select-answer-type"></span>
                                </ux-label>
                                <ux-select onselect.trigger="handleAnswerTypeSelected($event)" value.two-way="request.answerType">
                                    <ux-select-option repeat.for="type of ResultTypes | keys" value.bind="type"> ${ type | resultTypeToString | t } </ux-select-option>
                                </ux-select>
                            </div>
                            <!-- The input type selector -->
                            <div class="flex flex-col">
                                <ux-label class="mb-1">
                                    <span t="partial-views.medical-questions.labels.select-input-type"></span>
                                </ux-label>
                                <ux-select
                                    value.two-way="request.inputType"
                                    onselect.trigger="handleInputTypeSelected($event)"
                                    if.bind="inputTypesToShow && inputTypesToShow.length > 0"
                                    component.ref="inputTypeSelector"
                                    disabled.bind="request.answerType === ResultTypes.File || request.answerType === ResultTypes.Date">
                                    <ux-select-option repeat.for="type of inputTypesToShow" value.bind="type"> ${ type | inputTypeToString | t } </ux-select-option>
                                </ux-select>
                            </div>
                            <!-- The file type selector (shown when input type is filepicker) -->
                            <div class="flex flex-col col-span-2 px-6 py-5 rounded-lg bg-gray-50" if.bind="request.inputType === InputTypes.Filepicker">
                                <div class="grid grid-cols-12 gap-x-3 gap-y-1">
                                    <div class="flex flex-col col-span-12">
                                        <ux-label>
                                            <span t="partial-views.medical-questions.labels.select-file-type"></span>
                                        </ux-label>
                                        <p class="mb-1 text-left text-gray-400 text-xxs" t="partial-views.medical-questions.messages.leave-empty-for-no-file-type-restriction"></p>
                                        <ux-multi-selector
                                            component.ref="defaultValues"
                                            options.bind="toSet(fileTypes, 'FileTypes')"
                                            on-selected.bind="handleFileTypeSelected"
                                            on-removed.bind="handleFileTypeRemoved"
                                            show-footer.bind="false"
                                            force-remove.bind="true"
                                            background="white"
                                            autocomplete.bind="true"
                                            t="[placeholder]translation:global.placeholders.select-file-types">
                                        </ux-multi-selector>
                                    </div>
                                    <div class="flex flex-col col-span-12">
                                        <ux-label>
                                            <span t="partial-views.medical-questions.labels.select-files-amount"></span>
                                        </ux-label>
                                        <p class="mb-1 text-left text-gray-400 text-xxs" t="partial-views.medical-questions.messages.select-files-amount"></p>
                                        <ux-input
                                            type="number"
                                            valid.bind="validation.filesAmount"
                                            select-text-on-focus="true"
                                            min="1"
                                            value.two-way="request.filesAmount"
                                            t="[placeholder]global.placeholders.enter-numeric-value"></ux-input>
                                    </div>
                                </div>
                            </div>
                            <!-- Rules and settings for input type Free Entry -->
                            <template if.bind="request.inputType === InputTypes.FreeEntry">
                                <div class="grid flex-col grid-cols-2 col-span-2 gap-3 px-6 py-5 rounded-lg bg-gray-50">
                                    <!-- Default value -->
                                    <div class="flex flex-col col-span-2">
                                        <div class="flex flex-col mb-1">
                                            <ux-label>
                                                <span t="partial-views.medical-questions.labels.default-value"></span>
                                            </ux-label>
                                            <p class="col-span-12 text-xs text-gray-500" t="translation:partial-views.medical-questions.messages.default-value-explanation"></p>
                                        </div>
                                        <ux-input
                                            type.bind="request.answerType === ResultTypes.Text ? 'text' : 'number'"
                                            value.two-way="request.defaultValue.value"
                                            t="[placeholder]partial-views.medical-questions.placeholders.enter-default-value"></ux-input>
                                    </div>
                                    <!-- Min/max options for number input for result type Number -->
                                    <template if.bind="request.answerType === ResultTypes.Number">
                                        <div class="flex flex-col">
                                            <ux-label class="mb-1">
                                                <span t="global.labels.min-input-value"></span>
                                            </ux-label>
                                            <ux-input
                                                type="number"
                                                value.two-way="request.inputRules.min"
                                                select-text-on-focus="true"
                                                valid.bind="validation.minValue"
                                                t="[placeholder]translation:global.placeholders.enter-numeric-value"></ux-input>
                                        </div>
                                        <div class="flex flex-col">
                                            <ux-label class="mb-1">
                                                <span t="global.labels.max-input-value"></span>
                                            </ux-label>
                                            <ux-input
                                                type="number"
                                                value.two-way="request.inputRules.max"
                                                select-text-on-focus="true"
                                                valid.bind="validation.maxValue"
                                                t="[placeholder]translation:global.placeholders.enter-numeric-value"></ux-input>
                                        </div>
                                    </template>
                                    <!-- Result matcher type selector -->
                                    <template if.bind="request.answerType === ResultTypes.Number">
                                        <div class="flex flex-col col-span-2">
                                            <ux-label class="mb-1">
                                                <span t="partial-views.medical-questions.labels.result-matcher"></span>
                                            </ux-label>
                                            <ux-select
                                                value.two-way="request.resultMatcher.type"
                                                onselect.trigger="handleResultMatcherSelected($event)"
                                                t="[placeholder]partial-views.medical-questions.placeholders.result-matcher">
                                                <ux-select-option repeat.for="type of ResultMatcherTypes | keys" value.bind="type"> ${ type | resultMatcherTypeToString | t } </ux-select-option>
                                            </ux-select>
                                        </div>
                                    </template>
                                    <!-- Range options (shown when matcher type is 'BetweenRange' and result type is Number) -->
                                    <template if.bind="request.answerType === ResultTypes.Number && request.resultMatcher.type === ResultMatcherTypes.BetweenRange">
                                        <div class="flex flex-col">
                                            <div class="flex items-center space-x-1">
                                                <ux-label class="mb-1">
                                                    <span t="global.labels.range-start"></span>
                                                </ux-label>
                                                <span class="flex items-center h-3 text-red-500">*</span>
                                            </div>
                                            <ux-input
                                                type="number"
                                                value.two-way="request.resultMatcher.minRange"
                                                select-text-on-focus="true"
                                                valid.bind="validation.minRange"
                                                t="[placeholder]translation:global.placeholders.enter-numeric-value"></ux-input>
                                        </div>
                                        <div class="flex flex-col">
                                            <div class="flex items-center space-x-1">
                                                <ux-label class="mb-1">
                                                    <span t="global.labels.range-end"></span>
                                                </ux-label>
                                                <span class="flex items-center h-3 text-red-500">*</span>
                                            </div>
                                            <ux-input
                                                type="number"
                                                value.two-way="request.resultMatcher.maxRange"
                                                select-text-on-focus="true"
                                                valid.bind="validation.maxRange"
                                                t="[placeholder]translation:global.placeholders.enter-numeric-value"></ux-input>
                                        </div>
                                        <p class="col-span-2 text-xs text-gray-500" t="translation:partial-views.edit-action-item.messages.norm-explanation-range"></p>
                                    </template>
                                    <!-- Expected answer (when a simple comparison is selected) -->
                                    <template if.bind="request.resultMatcher.type === ResultMatcherTypes.SimpleComparison">
                                        <div class="flex flex-col col-span-2">
                                            <div class="flex flex-col mb-1">
                                                <ux-label>
                                                    <span t="partial-views.medical-questions.labels.expected-answer"></span>
                                                </ux-label>
                                                <p class="text-xs text-gray-500" t="translation:partial-views.medical-questions.messages.norm-explanation-single"></p>
                                            </div>
                                            <ux-input
                                                type.bind="request.answerType === ResultTypes.Text ? 'text' : 'number'"
                                                value.two-way="request.expectedAnswer.value"
                                                t="[placeholder]partial-views.medical-questions.placeholders.expected-answer"></ux-input>
                                        </div>
                                    </template>
                                </div>
                            </template>
                            <!-- When input type is of type selector -->
                            <div if.bind="request.inputType === InputTypes.Selector" class="grid flex-col grid-cols-2 col-span-2 gap-3 px-6 py-5 rounded-lg bg-gray-50">
                                <div class="flex items-center justify-between col-span-2">
                                    <ux-label>
                                        <span t="partial-views.medical-questions.labels.multiple-choices"></span>
                                    </ux-label>
                                    <ux-toggle
                                        checked.two-way="request.isMultipleChoice"
                                        onchange.trigger="handleChoicesChanged($event)"
                                        label-active.bind="'translation:global.labels.yes' | t"
                                        label-inactive.bind="'translation:global.labels.no' | t"
                                        size="md"
                                        color="primary">
                                    </ux-toggle>
                                </div>
                                <div class="flex items-center justify-between col-span-2">
                                    <ux-label>
                                        <span t="partial-views.medical-questions.labels.calculate-score"></span>
                                    </ux-label>
                                    <ux-toggle
                                        checked.two-way="request.calculateScore"
                                        label-active.bind="'translation:global.labels.yes' | t"
                                        label-inactive.bind="'translation:global.labels.no' | t"
                                        size="md"
                                        color="primary">
                                    </ux-toggle>
                                </div>
                                <!-- Choices input -->
                                <div class="flex flex-col col-span-2">
                                    <component-choices-selector
                                        show-scores.two-way="request.calculateScore"
                                        component.ref="choicesSelector"
                                        type.bind="request.answerType"
                                        validation.two-way="validation.choices"
                                        required="true"
                                        on-choice-changed.bind="handleChoicesChanged"
                                        on-choice-removed.bind="handleChoicesChanged"
                                        t="[label]partial-views.medical-questions.labels.select-choices"
                                        choices.two-way="request.choices">
                                    </component-choices-selector>
                                    <p if.bind="!validation.choices" class="mt-1 text-xs text-red-500" t="partial-views.medical-questions.messages.missing-choices"></p>
                                    <p if.bind="!validation.numericChoices" class="mt-1 text-xs text-red-500" t="partial-views.medical-questions.messages.invalid-choices"></p>
                                </div>
                                <!-- Input amount -->
                                <div if.bind="request.isMultipleChoice" class="flex flex-col col-span-2">
                                    <ux-label class="mb-1">
                                        <span t="partial-views.medical-questions.labels.select-input-amount"></span>
                                    </ux-label>
                                    <ux-input
                                        type="number"
                                        debounce="0"
                                        min.bind="2"
                                        oninput.trigger="handleInputAmountChanged()"
                                        select-text-on-focus="true"
                                        value.two-way="request.inputAmount"
                                        valid.bind="validation.inputAmount && validation.inputAmountValid"
                                        t="[placeholder]global.placeholders.enter-numeric-value">
                                    </ux-input>
                                    <p
                                        if.bind="!validation.inputAmountValidMultipleChoices"
                                        class="mt-1 text-xs text-red-500"
                                        t="translation:partial-views.medical-questions.messages.input-amount-multiple-choices"></p>
                                </div>
                                <!-- Default value (when only one answer is expected) -->
                                <div show.bind="request.inputAmount == 1 && request.resultMatcher.type === ResultMatcherTypes.SimpleComparison" class="flex flex-col col-span-2">
                                    <div class="flex flex-col mb-1">
                                        <ux-label>
                                            <span t="partial-views.medical-questions.labels.default-value"></span>
                                        </ux-label>
                                        <p class="text-xs text-gray-500" t="translation:partial-views.medical-questions.messages.default-value-explanation-select-single"></p>
                                    </div>
                                    <ux-select
                                        allow-clear="true"
                                        component.ref="defaultValue"
                                        valid.bind="validation.defaultValue"
                                        t="[placeholder]translation:partial-views.medical-questions.placeholders.select-default-value"
                                        onselect.trigger="handleDefaultChoiceSelected($event)"
                                        value.two-way="request.defaultValue.value">
                                        <ux-select-option value.bind="choice.value" repeat.for="choice of request.choices | sort:'displayOrder'">
                                            ${ choice.value || 'translation:global.messages.missing-value' | t }
                                        </ux-select-option>
                                    </ux-select>
                                </div>
                                <!-- Expected answers (when only one answer is expected) -->
                                <div show.bind="request.inputAmount == 1 && request.resultMatcher.type === ResultMatcherTypes.SimpleComparison" class="flex flex-col col-span-2">
                                    <div class="flex flex-col mb-1">
                                        <ux-label>
                                            <span t="partial-views.medical-questions.labels.expected-answer"></span>
                                        </ux-label>
                                        <p class="text-xs text-gray-500" t="translation:partial-views.medical-questions.messages.norm-explanation-single"></p>
                                    </div>
                                    <ux-select
                                        allow-clear="true"
                                        component.ref="expectedAnswer"
                                        valid.bind="validation.expectedAnswer"
                                        t="[placeholder]partial-views.medical-questions.placeholders.expected-answer"
                                        onselect.trigger="handleExpectedAnswerSelected($event)"
                                        value.two-way="request.expectedAnswer.value">
                                        <ux-select-option value.bind="choice.value" repeat.for="choice of request.choices | sort:'displayOrder'">
                                            ${ choice.value || 'translation:global.messages.missing-value' | t }
                                        </ux-select-option>
                                    </ux-select>
                                    <p if.bind="!validation.expectedAnswer" class="mt-1 text-xs text-red-500" t="partial-views.medical-questions.messages.expected-answer"></p>
                                </div>
                                <!-- Default values (when more than one answer is expected) -->
                                <div show.bind="request.inputAmount > 1  && request.resultMatcher.type === ResultMatcherTypes.SimpleComparison" class="flex flex-col col-span-2">
                                    <div class="flex flex-col mb-1">
                                        <ux-label>
                                            <span t="partial-views.medical-questions.labels.default-value"></span>
                                        </ux-label>
                                        <p class="text-xs text-gray-500" t="translation:partial-views.medical-questions.messages.default-value-explanation-select-single"></p>
                                    </div>
                                    <ux-multi-selector
                                        component.ref="defaultValues"
                                        options.bind="toSet(request.choices)"
                                        on-selected.bind="handleDefaultValueSelected"
                                        on-removed.bind="handleDefaultValueRemoved"
                                        show-footer.bind="false"
                                        force-remove.bind="true"
                                        autocomplete.bind="true"
                                        values.bind="request.defaultValues | getValues:'value'"
                                        t="[placeholder]translation:partial-views.medical-questions.placeholders.select-default-values">
                                    </ux-multi-selector>
                                </div>
                                <!-- Expected answers (when more than one answer is expected) -->
                                <div show.bind="request.inputAmount > 1  && request.resultMatcher.type === ResultMatcherTypes.SimpleComparison" class="flex flex-col col-span-2">
                                    <div class="flex flex-col mb-1">
                                        <ux-label>
                                            <span t="partial-views.medical-questions.labels.expected-answers"></span>
                                        </ux-label>
                                        <p class="text-xs text-gray-500" t="translation:partial-views.medical-questions.messages.norm-explanation-single"></p>
                                    </div>
                                    <ux-multi-selector
                                        component.ref="expectedAnswers"
                                        options.bind="toSet(request.choices)"
                                        on-selected.bind="handleExpectedAnswersSelected"
                                        on-removed.bind="handleExpectedAnswersRemoved"
                                        valid.bind="validation.expectedAnswers"
                                        show-footer.bind="false"
                                        force-remove.bind="true"
                                        autocomplete.bind="true"
                                        values.bind="request.expectedAnswers | getValues:'value'"
                                        t="[placeholder]translation:partial-views.medical-questions.placeholders.expected-answers">
                                    </ux-multi-selector>
                                </div>
                                <div class="col-span-2">
                                    <p if.bind="!validation.inputAmountValid" class="text-xs text-red-500" t="partial-views.medical-questions.messages.input-amount"></p>
                                    <p if.bind="!validation.expectedAnswers" class="text-xs text-red-500" t="partial-views.medical-questions.messages.expected-answers"></p>
                                </div>
                            </div>
                            <!-- When input type is of type slider -->
                            <div if.bind="request.inputType === InputTypes.RangeSlider" class="grid flex-col grid-cols-12 col-span-2 gap-3 px-6 py-5 rounded-lg bg-gray-50">
                                <div class="flex flex-col col-span-4">
                                    <ux-label class="mb-1">
                                        <span t="translation:global.labels.start-value"></span>
                                    </ux-label>
                                    <ux-input
                                        type="number"
                                        value.two-way="request.slider.startValue"
                                        select-text-on-focus="true"
                                        valid.bind="validation.startValue"
                                        t="[placeholder]translation:global.placeholders.enter-numeric-value"></ux-input>
                                </div>
                                <div class="flex flex-col col-span-4">
                                    <ux-label class="mb-1">
                                        <span t="translation:global.labels.end-value"></span>
                                    </ux-label>
                                    <ux-input
                                        type="number"
                                        value.two-way="request.slider.endValue"
                                        select-text-on-focus="true"
                                        valid.bind="validation.endValue && validation.endValueValid"
                                        t="[placeholder]translation:global.placeholders.enter-numeric-value"></ux-input>
                                </div>
                                <div class="flex flex-col col-span-4">
                                    <ux-label class="mb-1">
                                        <span t="translation:global.labels.initial-value"></span>
                                    </ux-label>
                                    <ux-input
                                        type="number"
                                        value.two-way="request.slider.initialValue"
                                        select-text-on-focus="true"
                                        allow-clear.bind="true"
                                        valid.bind="validation.initialValue && validation.initialValueValid"
                                        t="[placeholder]translation:global.placeholders.enter-numeric-value"></ux-input>
                                </div>
                                <div class="flex flex-col col-span-6">
                                    <ux-label class="mb-1">
                                        <span t="translation:global.labels.major-tick-increment"></span>
                                    </ux-label>
                                    <ux-input
                                        type="number"
                                        value.two-way="request.slider.majorIncrement"
                                        select-text-on-focus="true"
                                        valid.bind="validation.majorIncrement && validation.majorIncrementValid"
                                        t="[placeholder]translation:global.placeholders.enter-numeric-value"></ux-input>
                                </div>
                                <div class="flex flex-col col-span-6">
                                    <ux-label class="mb-1">
                                        <span t="translation:global.labels.minor-tick-increment"></span>
                                    </ux-label>
                                    <ux-input
                                        type="number"
                                        value.two-way="request.slider.minorIncrement"
                                        select-text-on-focus="true"
                                        valid.bind="validation.minorIncrement && validation.minorIncrementValid"
                                        t="[placeholder]translation:global.placeholders.enter-numeric-value"></ux-input>
                                </div>
                                <div class="flex flex-col col-span-12">
                                    <component-slider-labels-selector
                                        manage-translations.bind="manageTranslationsForLabels"
                                        component.ref="labelSelector"
                                        language.bind="language"
                                        validation.two-way="validation.labels"
                                        t="[label]translation:components.component-slider-labels-selector.labels.select-labels"
                                        labels.two-way="request.slider.labels">
                                    </component-slider-labels-selector>
                                </div>
                                <div
                                    if.bind="!validation.endValueValid || !validation.initialValueValid || !validation.majorIncrementValid || !validation.minorIncrementValid"
                                    class="flex flex-col col-span-12 gap-1">
                                    <p if.bind="!validation.endValueValid" class="text-xs text-red-500" t="translation:partial-views.medical-questions.messages.invalid-end-value"></p>
                                    <p if.bind="!validation.initialValueValid" class="text-xs text-red-500" t="translation:partial-views.medical-questions.messages.invalid-initial-value"></p>
                                    <p if.bind="!validation.majorIncrementValid" class="text-xs text-red-500" t="translation:partial-views.medical-questions.messages.invalid-major-tick-value"></p>
                                    <p if.bind="!validation.minorIncrementValid" class="text-xs text-red-500" t="translation:partial-views.medical-questions.messages.invalid-minor-tick-value"></p>
                                </div>
                            </div>
                            <!-- Affix and affix position (only shown when input type is not filepicker or datepicker) -->
                            <template if.bind="request.inputType !== InputTypes.Datepicker && request.inputType !== InputTypes.Filepicker">
                                <div class="flex flex-col">
                                    <ux-label class="mb-1">
                                        <span t="partial-views.medical-questions.labels.affix"></span>
                                    </ux-label>
                                    <ux-input value.two-way="request.affix" t="[placeholder]partial-views.medical-questions.placeholders.affix"></ux-input>
                                </div>
                                <div class="flex flex-col">
                                    <ux-label class="mb-1">
                                        <span t="partial-views.medical-questions.labels.affix-position"></span>
                                    </ux-label>
                                    <ux-select t="[placeholder]partial-views.medical-questions.placeholders.affix-position" value.two-way="request.affixPosition">
                                        <ux-select-option repeat.for="position of AffixPositions | keys" value.bind="position"> ${ position | affixPositionToString | t } </ux-select-option>
                                    </ux-select>
                                </div>
                            </template>
                        </div>
                        <ux-body-divider></ux-body-divider>
                        <div class="grid grid-cols-12 gap-3">
                            <div class="flex flex-col col-span-12">
                                <ux-label class="mb-1">
                                    <span t="global.labels.keywords"></span>
                                </ux-label>
                                <bx-keywords-selector workspace.bind="authenticated.workspace.id" values.two-way="request.keywords"></bx-keywords-selector>
                            </div>
                            <div class="flex flex-col col-span-12">
                                <ux-label class="mb-1">
                                    <span t="partial-views.medical-questions.labels.select-differential-diagnoses"></span>
                                </ux-label>
                                <bx-diagnosis-multi-selector
                                    workspace.bind="authenticated.workspace.id"
                                    on-change.bind="handleDiagnosesChanged"
                                    language.bind="language"
                                    t="[placeholder]partial-views.medical-questions.placeholders.select-differential-diagnoses"
                                    values.bind="request.differentialDiagnoses | getIds">
                                </bx-diagnosis-multi-selector>
                            </div>
                            <div class="flex flex-col col-span-12">
                                <ux-label class="mb-1">
                                    <span t="partial-views.medical-questions.labels.select-categories"></span>
                                </ux-label>
                                <bx-medical-question-category-multi-selector
                                    workspace.bind="authenticated.workspace.id"
                                    on-change.bind="handleCategoryChanged"
                                    language.bind="language"
                                    t="[placeholder]partial-views.medical-questions.placeholders.select-categories"
                                    values.bind="request.categories | getIds">
                                </bx-medical-question-category-multi-selector>
                            </div>
                            <div class="flex flex-col col-span-12">
                                <ux-label class="mb-1">
                                    <span t="global.labels.healthcare-sector"></span>
                                </ux-label>
                                <ux-multi-selector values.bind="request.healthcareSectors" type="healthcare-sectors"></ux-multi-selector>
                            </div>
                        </div>
                    </div>
                </template>
                <template else>
                    <div class="flex flex-col flex-1 pt-10">
                        <div class="grid grid-cols-2 gap-5">
                            <ux-skeleton show-background="true" type="container">
                                <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                    <ux-skeleton type="line" padding="pr-60"></ux-skeleton>
                                    <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                </div>
                            </ux-skeleton>
                            <ux-skeleton show-background="true" type="container">
                                <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                    <ux-skeleton type="line" padding="pr-48"></ux-skeleton>
                                    <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                </div>
                            </ux-skeleton>
                            <ux-skeleton show-background="true" type="container">
                                <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                    <ux-skeleton type="line" padding="pr-32"></ux-skeleton>
                                    <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                </div>
                            </ux-skeleton>
                            <ux-skeleton show-background="true" type="container">
                                <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                    <ux-skeleton type="line" padding="pr-60"></ux-skeleton>
                                    <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                </div>
                            </ux-skeleton>
                            <ux-skeleton class="col-span-2" show-background="true" type="container">
                                <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                    <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                    <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                </div>
                            </ux-skeleton>
                            <ux-skeleton class="col-span-2" show-background="true" type="container">
                                <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                    <ux-skeleton type="line" padding="pr-20"></ux-skeleton>
                                    <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                </div>
                            </ux-skeleton>
                            <ux-skeleton show-background="true" type="container">
                                <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                    <ux-skeleton type="line" padding="pr-60"></ux-skeleton>
                                    <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                </div>
                            </ux-skeleton>
                            <ux-skeleton show-background="true" type="container">
                                <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                    <ux-skeleton type="line" padding="pr-48"></ux-skeleton>
                                    <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                </div>
                            </ux-skeleton>
                            <ux-skeleton show-background="true" type="container">
                                <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                    <ux-skeleton type="line" padding="pr-32"></ux-skeleton>
                                    <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                </div>
                            </ux-skeleton>
                            <ux-skeleton show-background="true" type="container">
                                <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                    <ux-skeleton type="line" padding="pr-60"></ux-skeleton>
                                    <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                </div>
                            </ux-skeleton>
                            <ux-skeleton show-background="true" type="container">
                                <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                    <ux-skeleton type="line" padding="pr-32"></ux-skeleton>
                                    <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                </div>
                            </ux-skeleton>
                            <ux-skeleton show-background="true" type="container">
                                <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                    <ux-skeleton type="line" padding="pr-60"></ux-skeleton>
                                    <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                </div>
                            </ux-skeleton>
                            <ux-skeleton class="col-span-2" show-background="true" type="container">
                                <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                    <ux-skeleton type="line" padding="pr-20"></ux-skeleton>
                                    <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                    <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                                    <ux-skeleton type="line" padding="pr-8"></ux-skeleton>
                                    <ux-skeleton type="line" padding="pr-6"></ux-skeleton>
                                    <ux-skeleton type="line" padding="pr-0"></ux-skeleton>
                                    <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                </div>
                            </ux-skeleton>
                        </div>
                    </div>
                </template>
            </div>
            <div class="px-8">
                <div class="flex items-center justify-between py-4 bg-white border-t border-gray-200 gap-x-2">
                    <div class="flex">
                        <p if.bind="!validation.valid" class="text-xs text-red-500" t="translation:global.messages.not-all-values-entered"></p>
                    </div>
                    <div class="flex gap-2">
                        <ux-button size="sm" color="dark" mode="outline" disabled.bind="isLoading" click.trigger="cancel()">
                            <span class="pointer-events-none" t="global.buttons.cancel"></span>
                        </ux-button>
                        <ux-button
                            size="sm"
                            color="success"
                            disabled.bind="isLoading"
                            loading.bind="isLoading"
                            click.trigger="save()"
                            t="[text-when-loading]translation:global.labels.loading-states.default">
                            <span class="pointer-events-none" t="global.buttons.save"></span>
                        </ux-button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
