import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { GetMedicalTherapyResponse, MedicalRecordResult, MedicalRecordResultStatuses, MedicalTherapiesApiClient } from '@wecore/sdk-healthcare';
import { isDefined, isNotEmpty } from '@wecore/sdk-utilities';

import { IEventAggregator, inject } from 'aurelia';
import { SideToStringValueConverter } from '../../converters/side-to-string';
import { TranslateValueConverter } from '../../converters/translate';
import { PartialViewResults } from '../../enums/partial-view-results';
import { BasePartialView } from '../../infra/base-partial-view';
import { CacheService } from '../../infra/cache-service';
import { ErrorHandler } from '../../infra/error-handler';
import { State } from '../../infra/store/state';
import { cloneDeep } from '../../infra/utilities';
import { EventDetails } from '../../models/event-details';
import { PartialView } from '../../models/partial-view';
import { ModalService } from '../../services/service.modals';
import { UxInput } from '../../ux/ux-input/ux-input';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, MedicalTherapiesApiClient, ModalService)
export class PartialAddTreatmentTherapies extends BasePartialView {
    public therapies: GetMedicalTherapyResponse[] = [];
    public selected: GetMedicalTherapyResponse[] = [];
    public alreadyAddedTherapies: any[] = [];
    public results: MedicalRecordResult[];
    public selectedResults: string[] = [];

    private pageSize: number = 25;
    private triggerEventOn: number = 100;
    private endOfList: boolean = false;
    private skip: number = 0;
    private query: string;

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly therapiesApi: MedicalTherapiesApiClient,
        private readonly modalService: ModalService
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.results = view.data.results;
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                await this.getData(false, true);
                this.baseLoaded = true;
                setTimeout(() => {
                    if (isDefined(this.scrollContainer)) this.scrollContainer.addEventListener('scroll', (e) => this.handleScroll(e));
                });
            })
            .catch((x) => this.errorHandler.handle('PartialAddTreatmentTherapies.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }

    public async handleSearch(event: CustomEvent<EventDetails<UxInput, any>>): Promise<void> {
        const element = event.detail.element;
        element.isLoading = true;

        this.query = event.detail.values?.value;
        await this.getData(true);

        element.isLoading = false;
    }

    public async cancel(): Promise<void> {
        await super.remove({
            result: PartialViewResults.Cancelled,
            updateUrl: true
        });
    }

    public toSet(): { value: string; text: string; data?: any }[] {
        return this.results
            .filter((x) => x.status === MedicalRecordResultStatuses.WorkingDiagnosis)
            .map((result: MedicalRecordResult) => ({ value: result.id, text: result.differentialDiagnosis.name[this.language], data: result }));
    }

    public getExtra = (item: any): string => {
        const result = item.data as MedicalRecordResult;
        const converter = new SideToStringValueConverter();
        const translator = new TranslateValueConverter();

        let returnValue = `<span class="flex items-center gap-2 w-full justify-between">`;

        returnValue += `<span>${translator.toView(result.differentialDiagnosis.name, this.language)}</span>`;

        if (isDefined(result.side) || isDefined(result.location)) {
            returnValue += '<span>';

            if (isDefined(result.side)) returnValue += `${this.t.tr(converter.toView(result.side))}`;
            if (isDefined(result.side) && isDefined(result.location)) returnValue += ` + `;
            if (isDefined(result.location)) returnValue += `${translator.toView(result.location.translations, this.language)}`;

            returnValue += '</span>';
        }

        returnValue += '</span>';

        return returnValue;
    };

    public select(therapy: GetMedicalTherapyResponse): void {
        if (this.selected.some((x) => x.id === therapy.id)) this.selected = this.selected.filter((x) => x.id !== therapy.id);
        else this.selected.push(therapy);

        this.therapies = [
            ...(this.therapies.length > 0 ? [this.therapies.shift()] : []), //
            ...cloneDeep(this.therapies)
        ];
    }

    public async add(): Promise<void> {
        this.remove({
            result: PartialViewResults.Ok,
            data: this.selected.map((therapy) => ({
                therapy,
                results: this.selectedResults.map((id) => {
                    return this.results.find((result) => result.id === id);
                })
            }))
        });
    }

    public isAlreadyAdded(therapy: GetMedicalTherapyResponse): any {
        return this.alreadyAddedTherapies.find((x) => x.id === therapy.id);
    }

    private async getData(reset: boolean = false, initial: boolean = false): Promise<void> {
        if (reset) {
            this.skip = 0;
            this.therapies = [];
            this.endOfList = false;
            if (isDefined(this.scrollContainer)) this.scrollContainer.scrollTop = 0;
        }

        this.isLoading = true;
        const response = await this.therapiesApi.search(this.authenticated.workspace.id, isNotEmpty(this.query) ? this.query : undefined, this.pageSize, this.skip);

        if (!reset && response.data.empty() && !initial) {
            this.endOfList = true;
            this.isLoading = false;
            return;
        }
        this.skip += Number(this.pageSize);
        this.therapies = [...this.therapies, ...response.data];
        this.isLoading = false;
    }

    private async handleScroll(event: Event): Promise<void> {
        const target = event.target as HTMLElement;
        this.hasScrolled = target.scrollTop > 0;

        const isCloseToBottom = target.scrollTop + target.clientHeight >= target.scrollHeight - Number(this.triggerEventOn);
        if (isCloseToBottom && !this.endOfList && !this.isLoading) {
            await this.getData();
        }
    }
}
