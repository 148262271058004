import { IDialogController } from '@aurelia/dialog';
import { isEmpty, isFunction, isNotDefined, resetValidation } from '@wecore/sdk-utilities';
import { inject } from 'aurelia';
import { ErrorHandler } from '../../infra/error-handler';
import { ConfirmationOptions } from '../../models/confirmation-options';
import { AuthenticationService } from '../../services/service.authentication';

@inject(IDialogController, ErrorHandler, AuthenticationService)
export class ModalConfirmWithPhrase {
    public options: ConfirmationOptions;
    public isLoading: boolean = false;
    public phrase: string;
    public enteredPhrase: string;

    public constructor(
        private readonly controller: IDialogController, //
        private readonly errorHandler: ErrorHandler
    ) {}

    public activate(data: { phrase: string; options: ConfirmationOptions }): void {
        console.log(data);
        this.options = data.options;
        this.phrase = data.phrase;
    }

    public async ok(): Promise<void> {
        if (this.phrase !== this.enteredPhrase) return;
        try {
            if (isFunction(this.options.callback)) await this.options.callback(true, null);
            await this.controller.ok();
        } catch (e) {
            await this.errorHandler.handle(`[confirm-with-phrase/callback] ${this.options.title}`, e);
        }
    }

    public async cancel(): Promise<void> {
        if (isFunction(this.options.callback)) await this.options.callback(false, null);
        await this.controller.cancel();
    }
}
