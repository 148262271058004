import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { ContactTypesApiClient } from '@wecore/sdk-crm';
import { LabelTypes, LabelsApiClient, MedicalEquipmentApiClient, MedicationSideEffect, MedicationsApiClient, ProfessionsApiClient, SportsApiClient } from '@wecore/sdk-healthcare';
import { guid, isNotEmpty, resetValidation } from '@wecore/sdk-utilities';
import { IEventAggregator, inject } from 'aurelia';
import { PartialViewResults } from '../../../enums/partial-view-results';
import { BasePartialView } from '../../../infra/base-partial-view';
import { CacheService } from '../../../infra/cache-service';
import { ErrorHandler } from '../../../infra/error-handler';
import { State } from '../../../infra/store/state';
import { PartialView } from '../../../models/partial-view';

@inject(
    CacheService, //
    ErrorHandler,
    IEventAggregator,
    Store<State>,
    I18N,
    MedicationsApiClient,
    SportsApiClient,
    ProfessionsApiClient,
    LabelsApiClient,
    MedicalEquipmentApiClient,
    ContactTypesApiClient
)
export class PartialMasterDataFields extends BasePartialView {
    public item: { id: string; name: string; properties: any };
    public masterData: {
        route: string;
        name: string;
        description: string;
        type: string;
        api: string;
    };

    public medication: {
        items: MedicationSideEffect[];
        validation: any[];
        valid: boolean;
    } = {
        items: [],
        validation: [],
        valid: true
    };

    public LabelTypes: typeof LabelTypes = LabelTypes;
    public label: {
        type: LabelTypes;
        validation: {};
        valid: boolean;
    } = {
        type: LabelTypes.Patient,
        validation: {},
        valid: true
    };

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        // Api are used dynamically in below code and
        // the names should always be in plurar form (e.g. medicalEquipmentsApi).
        private readonly medicationsApi: MedicationsApiClient,
        private readonly sportsApi: SportsApiClient,
        private readonly professionsApi: ProfessionsApiClient,
        private readonly labelsApi: LabelsApiClient,
        private readonly medicalEquipmentsApi: MedicalEquipmentApiClient,
        private readonly contactTypesApi: ContactTypesApiClient
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.item = view.data.item;
        this.masterData = view.data.masterData;
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                switch (this.masterData.type) {
                    case 'medication':
                        this.medication.items = this.item.properties;
                        this.medication.items.forEach(() => {
                            this.medication.validation.push({ description: true });
                        });
                    case 'label':
                        this.label.type = this.item.properties.type;
                        break;
                }

                this.baseLoaded = true;
            })
            .catch((x) => this.errorHandler.handle('PartialMasterDataFields.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }

    public async cancel(): Promise<void> {
        await super.remove({
            result: PartialViewResults.Cancelled,
            updateUrl: true
        });
    }

    public addItem(): void {
        switch (this.masterData.type) {
            case 'medication':
                this.medication.items.push(
                    new MedicationSideEffect({
                        id: guid(),
                        keywords: [],
                        description: {}
                    })
                );
                this.medication.validation.push({ description: true });
                break;
        }
    }

    public async deleteItem(index: number): Promise<void> {
        switch (this.masterData.type) {
            case 'medication':
                this.medication.items.splice(index, 1);
                this.medication.validation.splice(index, 1);
                break;
        }
    }

    public async save(): Promise<void> {
        const valid = this.validate();
        if (valid) {
            try {
                switch (this.masterData.type) {
                    case 'medication':
                        this.item.properties = this.medication.items;
                        break;
                    case 'label':
                        this.item.properties.type = this.label.type;
                        break;
                }

                await this[this.masterData.api].update(this.item.id, this.authenticated.workspace.id, this.item);
                this.remove({
                    result: PartialViewResults.Ok,
                    updateUrl: true
                });
            } catch (e) {
                this.errorHandler.handle('[master-data-fields]', e);
            }
        }
    }

    public manageTranslationsFor(index: number): void {
        switch (this.masterData.type) {
            case 'medication':
                this.manageTranslations({
                    translations: this.medication.items[index].description,
                    callback: (updatedTranslations: any) => {
                        this.medication.items[index].description = updatedTranslations;
                    },
                    required: true,
                    type: 'textarea'
                });
                break;
        }
    }

    private validate(): boolean {
        switch (this.masterData.type) {
            case 'medication':
                resetValidation(this.medication);
                for (let i = 0; i < this.medication.items.length; i++) {
                    resetValidation(this.medication.validation[i]);
                    this.medication.validation[i].description = isNotEmpty(this.medication.items[i].description[this.language]);
                }
                this.medication.valid = this.medication.validation.every((x) => x.description);
                return this.medication.valid;
            default:
                return true;
        }
    }
}
