<import from="converters/keys" />
<import from="converters/get-ids" />
<import from="converters/get-values" />
<import from="converters/sort" />
<import from="converters/result-type-to-string" />
<import from="converters/input-type-to-string" />
<import from="converters/affix-position-to-string" />
<import from="converters/result-matcher-type-to-string" />
<import from="converters/operator-to-string" />
<import from="components/component-choices-selector/component-choices-selector" />

<import from="components/component-slider-labels-selector/component-slider-labels-selector" />

<template class="!flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex overflow-hidden gap-x-2" css.bind="styles">
        <div class="flex flex-col flex-1 w-full h-full overflow-hidden">
            <div class="flex flex-col flex-1 px-8 overflow-y-auto">
                <template if.bind="baseLoaded">
                    <div class="flex flex-col flex-1 pt-8 pb-5 gap-y-3">
                        <div class="flex justify-between">
                            <p class="font-medium text-gray-400" t="translation:partial-views.edit-medical-item.labels.title"></p>
                        </div>
                        <div class="grid grid-cols-12 gap-3">
                            <div class="flex flex-col col-span-12">
                                <div class="flex items-center space-x-1">
                                    <ux-label class="mb-1">
                                        <span t="translation:global.labels.name"></span>
                                    </ux-label>
                                    <span class="flex items-center h-3 text-red-500">*</span>
                                </div>
                                <ux-textarea
                                    rows="1"
                                    action.bind="c => manageTranslationsFor('name', true)"
                                    value.two-way="item.step.name[language]"
                                    valid.bind="validation.name"
                                    t="[placeholder]partial-views.edit-medical-item.placeholders.step-name">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M10.5 21l5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 016-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 01-3.827-5.802" />
                                    </svg>
                                </ux-textarea>
                            </div>
                            <!-- The question content -->
                            <div class="flex flex-col col-span-12">
                                <div class="flex items-center space-x-1">
                                    <ux-label class="mb-1">
                                        <span t="partial-views.edit-medical-item.labels.question"></span>
                                    </ux-label>
                                    <span class="flex items-center h-3 text-red-500">*</span>
                                </div>
                                <ux-textarea
                                    rows="1"
                                    action.bind="c => manageTranslationsFor('question', true)"
                                    value.two-way="item.step.question[language]"
                                    valid.bind="validation.question"
                                    t="[placeholder]partial-views.edit-medical-item.placeholders.question">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M10.5 21l5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 016-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 01-3.827-5.802" />
                                    </svg>
                                </ux-textarea>
                            </div>
                            <!-- Placeholder input -->
                            <div class="flex flex-col col-span-12">
                                <ux-label class="mb-1">
                                    <span t="partial-views.edit-medical-item.labels.question-placeholder"> </span>
                                </ux-label>
                                <ux-input
                                    action.bind="c => manageTranslationsFor('placeholder', false)"
                                    value.two-way="item.step.placeholder[language]"
                                    t="[placeholder]partial-views.edit-medical-item.placeholders.question-placeholder">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M10.5 21l5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 016-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 01-3.827-5.802" />
                                    </svg>
                                </ux-input>
                            </div>
                            <!-- The result type selector -->
                            <div class="flex flex-col col-span-6">
                                <ux-label class="mb-1">
                                    <span t="partial-views.edit-medical-item.labels.select-result-type"></span>
                                </ux-label>
                                <ux-select onselect.trigger="handleResultTypeSelected($event)" value.two-way="item.step.resultType">
                                    <ux-select-option repeat.for="type of ResultTypes | keys" value.bind="type"> ${ type | resultTypeToString | t } </ux-select-option>
                                </ux-select>
                            </div>
                            <!-- The input type selector -->
                            <div class="flex flex-col col-span-6">
                                <ux-label class="mb-1">
                                    <span t="partial-views.edit-medical-item.labels.select-input-type"></span>
                                </ux-label>
                                <ux-select
                                    value.two-way="item.step.inputType"
                                    onselect.trigger="handleInputTypeSelected($event)"
                                    if.bind="inputTypesToShow && inputTypesToShow.length > 0"
                                    component.ref="inputTypeSelector"
                                    disabled.bind="item.step.resultType === ResultTypes.File || item.step.resultType === ResultTypes.Date">
                                    <ux-select-option repeat.for="type of inputTypesToShow" value.bind="type"> ${ type | inputTypeToString | t } </ux-select-option>
                                </ux-select>
                            </div>
                            <!-- The file type selector (shown when input type is filepicker) -->
                            <div class="flex flex-col col-span-12 px-6 py-5 rounded-lg bg-gray-50" if.bind="item.step.inputType === InputTypes.Filepicker">
                                <div class="grid grid-cols-1 gap-y-2">
                                    <div class="flex flex-col">
                                        <ux-label>
                                            <span t="partial-views.edit-medical-item.labels.select-file-type"></span>
                                        </ux-label>
                                        <p class="mb-1 text-left text-gray-400 text-xxs" t="partial-views.edit-medical-item.messages.leave-empty-for-no-file-type-restriction"></p>
                                        <ux-multi-selector
                                            component.ref="defaultValues"
                                            options.bind="toSet(fileTypes, 'FileTypes')"
                                            on-selected.bind="handleFileTypeSelected"
                                            on-removed.bind="handleFileTypeRemoved"
                                            show-footer.bind="false"
                                            force-remove.bind="true"
                                            autocomplete.bind="true"
                                            background="white"
                                            values.bind="item.step.allowedFileTypes | getIds"
                                            t="[placeholder]translation:global.placeholders.select-file-types">
                                        </ux-multi-selector>
                                    </div>
                                    <div class="flex flex-col">
                                        <ux-label>
                                            <span t="partial-views.edit-medical-item.labels.select-files-amount"></span>
                                        </ux-label>
                                        <p class="mb-1 text-left text-gray-400 text-xxs" t="partial-views.edit-medical-item.messages.select-files-amount"></p>
                                        <ux-input
                                            type="number"
                                            valid.bind="validation.filesAmount"
                                            select-text-on-focus="true"
                                            min="1"
                                            value.two-way="item.step.filesAmount"
                                            t="[placeholder]global.placeholders.enter-numeric-value">
                                        </ux-input>
                                    </div>
                                </div>
                            </div>
                            <!-- Rules and settings for input type Free Entry -->
                            <template if.bind="item.step.inputType === InputTypes.FreeEntry">
                                <div class="grid flex-col grid-cols-12 col-span-12 gap-3 px-6 py-5 rounded-lg bg-gray-50">
                                    <!-- Default value -->
                                    <div if.bind="type === 'action'" class="flex flex-col col-span-12">
                                        <div class="flex flex-col mb-1">
                                            <ux-label>
                                                <span t="partial-views.edit-medical-item.labels.default-value"></span>
                                            </ux-label>
                                            <p class="col-span-12 text-xs text-gray-500" t="translation:partial-views.edit-medical-item.messages.default-value-explanation"></p>
                                        </div>
                                        <ux-input
                                            type.bind="item.step.resultType === ResultTypes.Text ? 'text' : 'number'"
                                            value.two-way="item.step.defaultValue.value"
                                            t="[placeholder]partial-views.edit-medical-item.placeholders.enter-default-value"></ux-input>
                                    </div>
                                    <!-- Min/max options for number input for result type Number -->
                                    <template if.bind="item.step.resultType === ResultTypes.Number">
                                        <div class="flex flex-col col-span-6">
                                            <ux-label class="mb-1">
                                                <span t="global.labels.min-input-value"></span>
                                            </ux-label>
                                            <ux-input
                                                type="number"
                                                value.two-way="item.step.inputRules.min"
                                                select-text-on-focus="true"
                                                valid.bind="validation.minValue"
                                                t="[placeholder]translation:global.placeholders.enter-numeric-value">
                                            </ux-input>
                                        </div>
                                        <div class="flex flex-col col-span-6">
                                            <ux-label class="mb-1">
                                                <span t="global.labels.max-input-value"></span>
                                            </ux-label>
                                            <ux-input
                                                type="number"
                                                value.two-way="item.step.inputRules.max"
                                                select-text-on-focus="true"
                                                valid.bind="validation.maxValue"
                                                t="[placeholder]translation:global.placeholders.enter-numeric-value">
                                            </ux-input>
                                        </div>
                                    </template>
                                    <!-- Result matcher type selector -->
                                    <template if.bind="item.step.resultType === ResultTypes.Number">
                                        <div class="flex flex-col col-span-12">
                                            <ux-label class="mb-1">
                                                <span t="translation:partial-views.edit-medical-item.labels.result-matcher"></span>
                                            </ux-label>
                                            <ux-select value.two-way="item.step.resultMatcher.type" onselect.trigger="handleResultMatcherSelected($event)">
                                                <ux-select-option repeat.for="type of ResultMatcherTypes | keys" value.bind="type"> ${ type | resultMatcherTypeToString | t } </ux-select-option>
                                            </ux-select>
                                        </div>
                                    </template>
                                    <!-- Range options (shown when matcher type is 'BetweenRange' and result type is Number) -->
                                    <template if.bind="item.step.resultType === ResultTypes.Number && item.step.resultMatcher.type === ResultMatcherTypes.BetweenRange">
                                        <div class="flex flex-col col-span-6">
                                            <div class="flex items-center space-x-1">
                                                <ux-label class="mb-1">
                                                    <span t="global.labels.range-start"></span>
                                                </ux-label>
                                                <span class="flex items-center h-3 text-red-500">*</span>
                                            </div>
                                            <ux-input
                                                type="number"
                                                value.two-way="item.step.resultMatcher.minRange"
                                                select-text-on-focus="true"
                                                valid.bind="validation.minRange"
                                                t="[placeholder]translation:global.placeholders.enter-numeric-value"></ux-input>
                                        </div>
                                        <div class="flex flex-col col-span-6">
                                            <div class="flex items-center space-x-1">
                                                <ux-label class="mb-1">
                                                    <span t="global.labels.range-end"></span>
                                                </ux-label>
                                                <span class="flex items-center h-3 text-red-500">*</span>
                                            </div>
                                            <ux-input
                                                type="number"
                                                value.two-way="item.step.resultMatcher.maxRange"
                                                select-text-on-focus="true"
                                                valid.bind="validation.maxRange"
                                                t="[placeholder]translation:global.placeholders.enter-numeric-value"></ux-input>
                                        </div>
                                        <p class="col-span-12 text-xs text-gray-500" t="translation:partial-views.edit-medical-item.messages.norm-explanation-range"></p>
                                    </template>
                                    <!-- Norm (when a simple comparison is selected) -->
                                    <template if.bind="item.step.resultMatcher.type === ResultMatcherTypes.SimpleComparison">
                                        <div class="flex flex-col col-span-12">
                                            <div class="flex flex-col mb-1">
                                                <ux-label>
                                                    <span t="partial-views.edit-medical-item.labels.norm"></span>
                                                </ux-label>
                                                <p class="text-xs text-gray-500" t="translation:partial-views.edit-medical-item.messages.norm-explanation-single"></p>
                                            </div>
                                            <ux-input
                                                type.bind="item.step.resultType === ResultTypes.Text ? 'text' : 'number'"
                                                value.two-way="item.step.norm.value"
                                                t="[placeholder]translation:partial-views.edit-medical-item.placeholders.norm-input"></ux-input>
                                        </div>
                                    </template>
                                </div>
                            </template>
                            <!-- When input type is of type selector -->
                            <div if.bind="item.step.inputType === InputTypes.Selector" class="grid flex-col grid-cols-2 col-span-12 gap-3 px-6 py-5 rounded-lg bg-gray-50">
                                <div class="flex items-center justify-between col-span-2">
                                    <ux-label>
                                        <span t="partial-views.edit-medical-item.labels.multiple-choices"></span>
                                    </ux-label>
                                    <ux-toggle
                                        onchange.trigger="handleChoicesChanged($event)"
                                        checked.two-way="item.step.isMultipleChoice"
                                        label-active.bind="'translation:global.labels.yes' | t"
                                        label-inactive.bind="'translation:global.labels.no' | t"
                                        size="md"
                                        color="primary">
                                    </ux-toggle>
                                </div>
                                <div class="flex items-center justify-between col-span-2">
                                    <ux-label>
                                        <span t="partial-views.edit-medical-item.labels.calculate-score"></span>
                                    </ux-label>
                                    <ux-toggle
                                        checked.two-way="item.step.calculateScore"
                                        label-active.bind="'translation:global.labels.yes' | t"
                                        label-inactive.bind="'translation:global.labels.no' | t"
                                        size="md"
                                        color="primary">
                                    </ux-toggle>
                                </div>
                                <!-- Choices input (when input is via selector) -->
                                <div class="flex flex-col col-span-2">
                                    <component-choices-selector
                                        show-scores.bind="item.step.calculateScore"
                                        type.bind="item.step.resultType"
                                        component.ref="choicesSelector"
                                        validation.two-way="validation.choices"
                                        required="true"
                                        on-choice-changed.bind="handleChoicesChanged"
                                        on-choice-removed.bind="handleChoicesChanged"
                                        t="[label]partial-views.edit-medical-item.labels.select-choices"
                                        choices.two-way="item.step.choices">
                                    </component-choices-selector>
                                    <p if.bind="!validation.choices" class="mt-1 text-xs text-red-500" t="partial-views.edit-medical-item.messages.missing-choices"></p>
                                    <p if.bind="!validation.numericChoices" class="mt-1 text-xs text-red-500" t="partial-views.edit-medical-item.messages.invalid-choices"></p>
                                </div>
                                <!-- Input amount (when input is via selector) -->
                                <div if.bind="item.step.isMultipleChoice" class="flex flex-col col-span-2">
                                    <ux-label class="mb-1">
                                        <span t="partial-views.edit-medical-item.labels.select-input-amount"></span>
                                    </ux-label>
                                    <ux-input
                                        type="number"
                                        debounce="0"
                                        min.bind="2"
                                        oninput.trigger="handleInputAmountChanged()"
                                        select-text-on-focus="true"
                                        value.two-way="item.step.inputAmount"
                                        valid.bind="validation.inputAmount && validation.inputAmountValid"
                                        t="[placeholder]global.placeholders.enter-numeric-value"></ux-input>
                                    <p
                                        if.bind="!validation.inputAmountValidMultipleChoices"
                                        class="mt-1 text-xs text-red-500"
                                        t="translation:partial-views.edit-medical-item.messages.input-amount-multiple-choices"></p>
                                </div>
                                <!-- Default value (when input type is selector and only one answer is expected) -->
                                <div
                                    show.bind="type === 'action' && item.step.inputAmount == 1 && item.step.resultMatcher.type === ResultMatcherTypes.SimpleComparison"
                                    class="flex flex-col col-span-2">
                                    <div class="flex flex-col mb-1">
                                        <ux-label>
                                            <span t="partial-views.edit-medical-item.labels.default-value"></span>
                                        </ux-label>
                                        <p class="text-xs text-gray-500" t="translation:partial-views.edit-medical-item.messages.default-value-explanation-select-single"></p>
                                    </div>
                                    <ux-select
                                        allow-clear="true"
                                        component.ref="defaultValue"
                                        onselect.trigger="handleDefaultChoiceSelected($event)"
                                        valid.bind="validation.defaultValue"
                                        t="[placeholder]translation:partial-views.medical-questions.placeholders.select-default-value"
                                        value.two-way=" item.step.defaultValue.value">
                                        <ux-select-option value.bind="choice.value" repeat.for="choice of  item.step.choices">
                                            ${ choice.value || 'translation:global.messages.missing-value' | t }
                                        </ux-select-option>
                                    </ux-select>
                                </div>
                                <!-- Norm (when input type is selector and only one answer is expected) -->
                                <div show.bind="item.step.inputAmount == 1 && item.step.resultMatcher.type === ResultMatcherTypes.SimpleComparison" class="flex flex-col col-span-2">
                                    <div class="flex flex-col mb-1">
                                        <ux-label>
                                            <span t="partial-views.edit-medical-item.labels.norm"></span>
                                        </ux-label>
                                        <p class="text-xs text-gray-500" t="translation:partial-views.edit-medical-item.messages.norm-explanation-single"></p>
                                    </div>
                                    <ux-select
                                        allow-clear="true"
                                        component.ref="norm"
                                        onselect.trigger="handleNormSelected($event)"
                                        valid.bind="validation.norm"
                                        t="[placeholder]partial-views.edit-medical-item.placeholders.norm-selection"
                                        value.two-way="item.step.norm.value">
                                        <ux-select-option value.bind="choice.value" repeat.for="choice of item.step.choices | sort:'displayOrder'">
                                            ${ choice.value || 'translation:global.messages.missing-value' | t }
                                        </ux-select-option>
                                    </ux-select>
                                    <p if.bind="!validation.norm" class="mt-1 text-xs text-red-500" t="partial-views.edit-medical-item.messages.norm"></p>
                                </div>
                                <!-- Default values (when input type is selector and more than one norm is expected) -->
                                <!-- Set to show bind because other  -->
                                <div
                                    show.bind="type === 'action' && item.step.inputAmount > 1 && item.step.resultMatcher.type === ResultMatcherTypes.SimpleComparison"
                                    class="flex flex-col col-span-2">
                                    <div class="flex flex-col mb-1">
                                        <ux-label>
                                            <span t="translation:partial-views.edit-medical-item.labels.default-values"></span>
                                        </ux-label>
                                        <p class="text-xs text-gray-500" t="translation:partial-views.edit-medical-item.messages.default-value-explanation-select-multiple"></p>
                                    </div>
                                    <ux-multi-selector
                                        component.ref="defaultValues"
                                        options.bind="toSet(item.step.choices)"
                                        on-selected.bind="handleDefaultValueSelected"
                                        on-removed.bind="handleDefaultValueRemoved"
                                        show-footer.bind="false"
                                        force-remove.bind="true"
                                        autocomplete.bind="true"
                                        values.bind="item.step.defaultValues | getValues:'value'"
                                        t="[placeholder]translation:partial-views.edit-medical-item.placeholders.select-default-values">
                                    </ux-multi-selector>
                                </div>
                                <!-- Norms (when input type is selector and more than one norm is expected) -->
                                <!-- Set to show bind because other  -->
                                <div show.bind="item.step.inputAmount > 1 && item.step.resultMatcher.type === ResultMatcherTypes.SimpleComparison" class="flex flex-col col-span-2">
                                    <div class="flex flex-col mb-1">
                                        <ux-label>
                                            <span t="partial-views.edit-medical-item.labels.norms"></span>
                                        </ux-label>
                                        <p class="text-xs text-gray-500" t="translation:partial-views.edit-medical-item.messages.norm-explanation-single"></p>
                                    </div>
                                    <ux-multi-selector
                                        component.ref="norms"
                                        valid.bind="validation.norms"
                                        options.bind="toSet(item.step.choices)"
                                        on-selected.bind="handleNormsSelected"
                                        on-removed.bind="handleNormsRemoved"
                                        show-footer.bind="false"
                                        force-remove.bind="true"
                                        autocomplete.bind="true"
                                        values.bind="item.step.norms | getValues:'value'"
                                        t="[placeholder]translation:partial-views.edit-medical-item.placeholders.norms">
                                    </ux-multi-selector>
                                </div>
                                <div class="col-span-2">
                                    <p if.bind="!validation.inputAmountValid" class="text-xs text-red-500" t="partial-views.edit-medical-item.messages.input-amount"></p>
                                    <p if.bind="!validation.norms" class="text-xs text-red-500" t="partial-views.edit-medical-item.messages.norms"></p>
                                </div>
                            </div>
                            <!-- When input type is of type slider -->
                            <div if.bind="item.step.inputType === InputTypes.RangeSlider" class="grid flex-col grid-cols-12 col-span-12 gap-3 px-6 py-5 rounded-lg bg-gray-50">
                                <div class="flex flex-col col-span-4">
                                    <ux-label class="mb-1">
                                        <span t="translation:global.labels.start-value"></span>
                                    </ux-label>
                                    <ux-input
                                        type="number"
                                        value.two-way="item.step.slider.startValue"
                                        select-text-on-focus="true"
                                        valid.bind="validation.startValue"
                                        t="[placeholder]translation:global.placeholders.enter-numeric-value"></ux-input>
                                </div>
                                <div class="flex flex-col col-span-4">
                                    <ux-label class="mb-1">
                                        <span t="translation:global.labels.end-value"></span>
                                    </ux-label>
                                    <ux-input
                                        type="number"
                                        value.two-way="item.step.slider.endValue"
                                        select-text-on-focus="true"
                                        valid.bind="validation.endValue && validation.endValueValid"
                                        t="[placeholder]translation:global.placeholders.enter-numeric-value"></ux-input>
                                </div>
                                <div class="flex flex-col col-span-4">
                                    <ux-label class="mb-1">
                                        <span t="translation:global.labels.initial-value"></span>
                                    </ux-label>
                                    <ux-input
                                        type="number"
                                        value.two-way="item.step.slider.initialValue"
                                        select-text-on-focus="true"
                                        allow-clear.bind="true"
                                        valid.bind="validation.initialValue && validation.initialValueValid"
                                        t="[placeholder]translation:global.placeholders.enter-numeric-value"></ux-input>
                                </div>
                                <div class="flex flex-col col-span-6">
                                    <ux-label class="mb-1">
                                        <span t="translation:global.labels.major-tick-increment"></span>
                                    </ux-label>
                                    <ux-input
                                        type="number"
                                        value.two-way="item.step.slider.majorIncrement"
                                        select-text-on-focus="true"
                                        valid.bind="validation.majorIncrement && validation.majorIncrementValid"
                                        t="[placeholder]translation:global.placeholders.enter-numeric-value"></ux-input>
                                </div>
                                <div class="flex flex-col col-span-6">
                                    <ux-label class="mb-1">
                                        <span t="translation:global.labels.minor-tick-increment"></span>
                                    </ux-label>
                                    <ux-input
                                        type="number"
                                        value.two-way="item.step.slider.minorIncrement"
                                        select-text-on-focus="true"
                                        valid.bind="validation.minorIncrement && validation.minorIncrementValid"
                                        t="[placeholder]translation:global.placeholders.enter-numeric-value"></ux-input>
                                </div>
                                <div class="flex flex-col col-span-12">
                                    <component-slider-labels-selector
                                        manage-translations.bind="manageTranslationsForLabels"
                                        component.ref="labelSelector"
                                        language.bind="language"
                                        validation.two-way="validation.labels"
                                        t="[label]translation:components.component-slider-labels-selector.labels.select-labels"
                                        labels.two-way="item.step.slider.labels">
                                    </component-slider-labels-selector>
                                </div>
                                <div
                                    if.bind="!validation.endValueValid || !validation.initialValueValid || !validation.majorIncrementValid || !validation.minorIncrementValid"
                                    class="flex flex-col col-span-12 gap-1">
                                    <p if.bind="!validation.endValueValid" class="text-xs text-red-500" t="translation:partial-views.edit-medical-item.messages.invalid-end-value"></p>
                                    <p if.bind="!validation.initialValueValid" class="text-xs text-red-500" t="translation:partial-views.edit-medical-item.messages.invalid-initial-value"></p>
                                    <p if.bind="!validation.majorIncrementValid" class="text-xs text-red-500" t="translation:partial-views.edit-medical-item.messages.invalid-major-tick-value"></p>
                                    <p if.bind="!validation.minorIncrementValid" class="text-xs text-red-500" t="translation:partial-views.edit-medical-item.messages.invalid-minor-tick-value"></p>
                                </div>
                            </div>
                            <!-- Affix and affix position (only shown when input type is not filepicker or datepicker) -->
                            <template if.bind="item.step.inputType !== InputTypes.Datepicker && item.step.inputType !== InputTypes.Filepicker">
                                <div class="flex flex-col col-span-7">
                                    <ux-label class="mb-1">
                                        <span t="partial-views.edit-medical-item.labels.affix"></span>
                                    </ux-label>
                                    <ux-input value.two-way="item.step.affix" t="[placeholder]partial-views.edit-medical-item.placeholders.affix"></ux-input>
                                </div>
                                <div class="flex flex-col col-span-5">
                                    <ux-label class="mb-1">
                                        <span t="partial-views.edit-medical-item.labels.affix-position"></span>
                                    </ux-label>
                                    <ux-select t="[placeholder]partial-views.edit-medical-item.placeholders.affix-position" value.two-way="item.step.affixPosition">
                                        <ux-select-option repeat.for="position of AffixPositions | keys" value.bind="position"> ${ position | affixPositionToString | t } </ux-select-option>
                                    </ux-select>
                                </div>
                            </template>
                        </div>
                    </div>
                </template>
                <template else>
                    <div class="flex flex-col flex-1 pt-10">
                        <div class="grid grid-cols-1 gap-5">
                            <div class="flex flex-col flex-1 space-y-3">
                                <ux-skeleton show-background="true" type="container">
                                    <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                        <ux-skeleton type="line" padding="pr-60"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                    </div>
                                </ux-skeleton>
                                <ux-skeleton show-background="true" type="container">
                                    <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                        <ux-skeleton type="line" padding="pr-48"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                    </div>
                                </ux-skeleton>
                                <ux-skeleton show-background="true" type="container">
                                    <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                        <ux-skeleton type="line" padding="pr-20"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-8"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-6"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-0"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                    </div>
                                </ux-skeleton>
                            </div>
                        </div>
                    </div>
                </template>
                <div class="sticky bottom-0 z-50 flex justify-between py-4 bg-white border-t border-gray-200 gap-x-2">
                    <div class="flex gap-x-2">
                        <!-- <ux-button size="sm" color="danger" disabled.bind="isLoading || deleting" loading.bind="deleting" click.trigger="delete()">
                                <span class="pointer-events-none" t="global.buttons.delete"></span>
                            </ux-button> -->
                    </div>
                    <div class="flex gap-x-2">
                        <ux-button
                            size="sm"
                            color="primary"
                            disabled.bind="isLoading || deleting"
                            loading.bind="isLoading"
                            click.trigger="save()"
                            t="[text-when-loading]translation:global.labels.loading-states.default">
                            <span class="pointer-events-none" t="global.buttons.close"></span>
                        </ux-button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
