<import from="converters/date" />

<template class="!flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex flex-col overflow-hidden" css.bind="styles">
        <ux-header>
            <div class="flex flex-col">
                <p class="text-sm font-medium" t="translation:partial-views.patient-merge.title"></p>
                <p class="text-sm text-gray-500" t="translation:partial-views.patient-merge.messages.title"></p>

                <ux-alert color="info" class="mt-3">
                    <span class="text-xs" t="translation:partial-views.patient-merge.messages.information"></span>
                </ux-alert>

                <p if.bind="destination" class="mt-3 text-sm text-red-800" t="translation:partial-views.patient-merge.messages.data"></p>
                <div class="px-4 py-3 my-3 text-sm bg-gray-100 rounded-md">
                    <div class="flex justify-between gap-1">
                        <p t="translation:partial-views.patient-merge.labels.patient-id"></p>
                        <p class="font-medium">${source.id}</p>
                    </div>
                    <div class="flex justify-between gap-1">
                        <p t="translation:partial-views.patient-merge.labels.patient-number"></p>
                        <p class="font-medium">${source.number}</p>
                    </div>
                    <div class="flex justify-between gap-1">
                        <p t="translation:partial-views.patient-merge.labels.patient-name"></p>
                        <p class="font-medium">${source.displayName}</p>
                    </div>
                    <div class="flex justify-between gap-1">
                        <p t="translation:partial-views.patient-merge.labels.patient-dob"></p>
                        <p class="font-medium">${source.dateOfBirth | date}</p>
                    </div>
                </div>
                <template if.bind="destination">
                    <p class="text-sm text-red-800" t="translation:partial-views.patient-merge.messages.merged"></p>
                    <div class="px-4 py-3 my-3 text-sm bg-gray-100 rounded-md">
                        <div class="flex justify-between gap-1">
                            <p t="translation:partial-views.patient-merge.labels.patient-id"></p>
                            <p class="font-medium">${destination.id}</p>
                        </div>
                        <div class="flex justify-between gap-1">
                            <p t="translation:partial-views.patient-merge.labels.patient-number"></p>
                            <p class="font-medium">${destination.number}</p>
                        </div>
                        <div class="flex justify-between gap-1">
                            <p t="translation:partial-views.patient-merge.labels.patient-name"></p>
                            <p class="font-medium">${destination.displayName}</p>
                        </div>
                        <div class="flex justify-between gap-1">
                            <p t="translation:partial-views.patient-merge.labels.patient-dob"></p>
                            <p class="font-medium">${destination.dateOfBirth | date}</p>
                        </div>
                    </div>
                </template>

                <ux-input
                    debounce="450"
                    component.ref="input"
                    allow-clear="true"
                    oninput.trigger="handleSearch($event)"
                    onclear.trigger="clear()"
                    t="[placeholder]translation:partial-views.patient-merge.placeholders.search"></ux-input>
            </div>
        </ux-header>
        <div ref="scrollContainer" class="flex flex-col flex-1 h-full pb-6 overflow-y-auto scroll-smooth">
            <template if.bind="baseLoaded">
                <div class="flex flex-col">
                    <button repeat.for="patient of patients" click.trigger="select(patient)" class="flex flex-col gap-2 px-6 py-3 text-sm border-b border-gray-200 hover:bg-gray-50 active:bg-gray-100">
                        <div class="flex justify-between w-full">
                            <p>${patient.displayName}</p>
                            <div class="flex gap-5 text-gray-500">
                                <span>${patient.dateOfBirth | date}</span>
                                <span>${patient.number}</span>
                            </div>
                        </div>
                    </button>
                    <div if.bind="patients.length === 0 && showNoResults" class="flex justify-center py-5">
                        <p class="text-sm" t="translation:partial-views.patient-merge.messages.no-results"></p>
                    </div>
                </div>
            </template>
            <template else>
                <div class="flex flex-col flex-1 px-6 pt-2 gap-y-2">
                    <ux-skeleton show-background.bind="false" type="container">
                        <div class="flex flex-col w-full py-1 space-y-5">
                            <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-0"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-2"></ux-skeleton>
                            <ux-skeleton type="line"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-2"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                            <ux-skeleton type="line"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-4"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-2"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                        </div>
                    </ux-skeleton>
                </div>
            </template>
        </div>
        <ux-footer>
            <div class="flex items-center justify-end w-full gap-x-2">
                <ux-button size="sm" color="dark" mode="outline" disabled.bind="isLoading" click.trigger="close()">
                    <span class="pointer-events-none" t="global.buttons.cancel"></span>
                </ux-button>
                <ux-button
                    size="sm"
                    color="danger"
                    disabled.bind="isLoading || !destination"
                    loading.bind="isLoading"
                    click.trigger="merge()"
                    t="[text-when-loading]translation:global.labels.loading-states.default">
                    <span class="pointer-events-none" t="global.buttons.merge"></span>
                </ux-button>
            </div>
        </ux-footer>
    </section>
</template>
