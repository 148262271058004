import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { UserRoles } from '@wecore/sdk-core';
import {
    DeclarationEventTypes,
    DeclarationPerformanceStatuses,
    DeclarationPerformanceTypes,
    DeclarationPerformancesApiClient,
    DeclarationSendMethods,
    DeclarationsApiClient,
    GetDeclarationPerformanceResponse,
    GetDeclarationResponse,
    GetPatientResponse,
    InsuranceStatuses,
    InsuranceTypes,
    PatientsApiClient
} from '@wecore/sdk-healthcare';
import { isDefined } from '@wecore/sdk-utilities';
import { IEventAggregator, inject } from 'aurelia';
import { startOfDay } from 'date-fns';
import { PartialViewResults } from '../../../enums/partial-view-results';
import { BasePartialView } from '../../../infra/base-partial-view';
import { CacheService } from '../../../infra/cache-service';
import { ErrorHandler } from '../../../infra/error-handler';
import { CustomEvents } from '../../../infra/events';
import { PartialViews } from '../../../infra/partial-views';
import { State } from '../../../infra/store/state';
import { EventDetails } from '../../../models/event-details';
import { PartialView } from '../../../models/partial-view';
import { ViewOptions } from '../../../models/view-options';
import { ModalService } from '../../../services/service.modals';
import { UxDatepicker } from '../../../ux/ux-datepicker/ux-datepicker';
import { UxSelectOption } from '../../../ux/ux-select-option/ux-select-option';
import { UxSelect } from '../../../ux/ux-select/ux-select';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, PatientsApiClient, DeclarationPerformancesApiClient, ModalService, DeclarationsApiClient)
export class PartialDeclarationPerformancesEdit extends BasePartialView {
    public performance: GetDeclarationPerformanceResponse;
    public DeclarationSendMethods: typeof DeclarationSendMethods = DeclarationSendMethods;
    public DeclarationPerformanceStatuses: typeof DeclarationPerformanceStatuses = DeclarationPerformanceStatuses;
    public DeclarationPerformanceTypes: typeof DeclarationPerformanceTypes = DeclarationPerformanceTypes;
    public DeclarationEventTypes: typeof DeclarationEventTypes = DeclarationEventTypes;
    public InsuranceTypes: typeof InsuranceTypes = InsuranceTypes;
    public InsuranceStatuses: typeof InsuranceStatuses = InsuranceStatuses;
    public patient: GetPatientResponse;
    public declaration: GetDeclarationResponse;

    public viewState: any = {
        events: []
    };

    private performanceId: string;

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly patientsApi: PatientsApiClient,
        private readonly performancesApi: DeclarationPerformancesApiClient,
        private readonly modalService: ModalService,
        private readonly declarationsApi: DeclarationsApiClient
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.performanceId = view.data.id;
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                this.performance = await this.performancesApi.getById(this.performanceId, this.authenticated.workspace.id);
                const [patient, declaration] = await Promise.all([
                    this.hasRole(UserRoles.ReadPatients) ? this.patientsApi.getById(this.performance.patient.id, this.authenticated.workspace.id) : undefined, //
                    isDefined(this.performance.declaration) && this.hasRole(UserRoles.ReadDeclarations)
                        ? this.declarationsApi.getById(this.performance.declaration.id, this.authenticated.workspace.id)
                        : undefined
                ]);

                this.declaration = declaration;

                this.setState();

                this.subscriptions = [
                    ...(this.subscriptions ?? []),
                    this.events.subscribe(CustomEvents.DeclarationsUpdated, async (_: { declaration: GetDeclarationResponse }) => {
                        this.performance = await this.performancesApi.getById(this.performanceId, this.authenticated.workspace.id);
                        this.events.publish(CustomEvents.DeclarationPerformancesUpdated, { performance: this.performance });
                    })
                ];

                this.patient = patient;
                this.baseLoaded = true;
            })
            .catch((x) => this.errorHandler.handle('PartialDeclarationPerformancesEdit.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }

    public async save(): Promise<void> {
        this.isLoading = true;

        try {
            await this.performancesApi.update(this.performance.id, this.authenticated.workspace.id, this.performance);
            this.notifications.show(
                this.t.tr('translation:partial-views.declaration-performances.notifications.save-successful.title'),
                this.t.tr('translation:partial-views.declaration-performances.notifications.save-successful.message'),
                { type: 'success', duration: 3000 }
            );
        } catch (e) {
            this.isLoading = false;
            this.errorHandler.handle('[update-performances]', e);
        }
        setTimeout(async () => this.remove({ result: PartialViewResults.Ok, updateUrl: true }), 250);
    }

    public async openInvoice(id: string): Promise<void> {
        await this.removeChildViews();
        await this.addPartialView({
            view: this.partial.base, //
            partial: PartialViews.EditHealthcareInvoice.with({ id }), //
            options: new ViewOptions({ scrollToView: true, markItem: false, replace: true, updateUrl: false })
        });
    }

    public async openDeclaration(id: string): Promise<void> {
        await this.removeChildViews();
        await this.addPartialView({
            view: this.partial.base, //
            partial: PartialViews.EditDeclaration.with({ id }).whenClosed(async (result: PartialViewResults) => {
                if (result === PartialViewResults.Ok) this.performance = await this.performancesApi.getById(this.performanceId, this.authenticated.workspace.id);
            }), //
            options: new ViewOptions({ scrollToView: true, markItem: false, replace: true, updateUrl: false })
        });
    }

    public handleInsuranceSelected(e: CustomEvent<EventDetails<UxSelect, UxSelectOption>>) {
        const insuranceId = e.detail.values.value;
        this.performance.insurance = this.patient.insurances.find((i) => i.id === insuranceId);
    }

    public close(): void {
        super.remove({
            result: PartialViewResults.Ok,
            updateUrl: true
        });
    }

    public async handleDateChanged(e: CustomEvent<EventDetails<UxDatepicker, any>>): Promise<void> {
        const date = new Date(e.detail.values.date);
        this.performance.performanceDate = startOfDay(date);
    }

    public toggleEventMessage(type: 'container' | 'message' | 'error' | 'notification', parent: number, index: number): void {
        if (isDefined(index)) {
            for (let eI = 0; eI < this.viewState.events.length; eI++) {
                for (const prop in this.viewState.events[eI]) {
                    if (eI === parent && prop === 'messages') {
                        for (let mI = 0; mI < this.viewState.events[eI].messages.length; mI++) {
                            for (const innerProp in this.viewState.events[eI].messages[mI]) {
                                if (mI === index && innerProp === type) {
                                    this.viewState.events[eI].messages[mI][innerProp] = !this.viewState.events[eI].messages[mI][innerProp];
                                } else this.viewState.events[eI].messages[mI][innerProp] = false;
                            }
                        }
                    } else if (prop !== 'messages') this.viewState.events[eI][prop] = false;
                }
            }
        } else {
            for (let eI = 0; eI < this.viewState.events.length; eI++) {
                for (const prop in this.viewState.events[eI]) {
                    if (prop === 'messages') continue;
                    if (eI === parent && prop === type) this.viewState.events[eI][prop] = !this.viewState.events[eI][prop];
                    else this.viewState.events[eI][prop] = false;
                }
            }
        }
    }

    public cancel(): void {
        super.remove({
            result: PartialViewResults.Cancelled,
            updateUrl: true
        });
    }

    private setState(): void {
        this.viewState.events = this.performance.events.map((event) => ({
            message: false,
            payload: false,
            error: false,
            container: false,
            notification: false,
            messages: event.messages.map((message) => ({
                message: false,
                payload: false,
                error: false,
                container: false,
                notification: false
            }))
        }));
    }
}
