import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { Email, InsurersApiClient, Phone, UpdateInsurerRequest } from '@wecore/sdk-healthcare';
import { isDefined, isEmpty, isNotDefined, isNotEmpty, isValid, resetValidation, validateState } from '@wecore/sdk-utilities';

import { IEventAggregator, inject } from 'aurelia';
import { PartialViewResults } from '../../../enums/partial-view-results';
import { BasePartialView } from '../../../infra/base-partial-view';
import { CacheService } from '../../../infra/cache-service';
import { ErrorHandler } from '../../../infra/error-handler';
import { State } from '../../../infra/store/state';
import { checkForCustomDescriptions, cleanTranslatables, validateTranslation } from '../../../infra/utilities';
import { ConfirmationOptions } from '../../../models/confirmation-options';
import { PartialView } from '../../../models/partial-view';
import { ModalService } from '../../../services/service.modals';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, InsurersApiClient, ModalService)
export class PartialManageInsurersEdit extends BasePartialView {
    public insurerId: string;
    public insurer: UpdateInsurerRequest;
    public validation = {
        name: true,
        emails: [],
        phones: []
    };

    public addressDescriptions: { [key: string]: string }[];
    public emailDescriptions: { [key: string]: string }[];
    public phoneDescriptions: { [key: string]: string }[];

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly insurersApi: InsurersApiClient,
        private readonly modalService: ModalService
    ) {
        super(cache, errorHandler, events, store, t);

        this.addressDescriptions = [
            {
                nl: t.tr('translation:global.labels.home-address', { lng: 'nl' }),
                en: t.tr('translation:global.labels.home-address', { lng: 'en' })
            },
            {
                nl: t.tr('translation:global.labels.work', { lng: 'nl' }),
                en: t.tr('translation:global.labels.work', { lng: 'en' })
            },
            {
                nl: t.tr('translation:global.labels.custom', { lng: 'nl' }),
                en: t.tr('translation:global.labels.custom', { lng: 'en' })
            }
        ];

        this.emailDescriptions = [
            { nl: t.tr('translation:global.labels.work', { lng: 'nl' }), en: t.tr('translation:global.labels.work', { lng: 'en' }) },
            { nl: t.tr('translation:global.labels.private', { lng: 'nl' }), en: t.tr('translation:global.labels.private', { lng: 'en' }) },
            { nl: t.tr('translation:global.labels.custom', { lng: 'nl' }), en: t.tr('translation:global.labels.custom', { lng: 'en' }) }
        ];

        this.phoneDescriptions = [
            { nl: t.tr('translation:global.labels.work', { lng: 'nl' }), en: t.tr('translation:global.labels.work', { lng: 'en' }) },
            { nl: t.tr('translation:global.labels.private', { lng: 'nl' }), en: t.tr('translation:global.labels.private', { lng: 'en' }) },
            { nl: t.tr('translation:global.labels.mobile', { lng: 'nl' }), en: t.tr('translation:global.labels.mobile', { lng: 'en' }) },
            { nl: t.tr('translation:global.labels.custom', { lng: 'nl' }), en: t.tr('translation:global.labels.custom', { lng: 'en' }) }
        ];
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.insurerId = view.data.id;
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                this.insurer = await this.insurersApi.getById(this.insurerId, this.authenticated.workspace.id);

                this.validation.emails = this.insurer.emails?.map((_: Email) => ({ email: true, phone: true, valid: true })) || [];
                this.validation.phones = this.insurer.phones?.map((_: Phone) => ({ phone: true, email: true, valid: true })) || [];

                this.emailDescriptions = checkForCustomDescriptions(this.t, this.language, this.emailDescriptions, this.insurer, 'email');
                this.phoneDescriptions = checkForCustomDescriptions(this.t, this.language, this.phoneDescriptions, this.insurer, 'phone');
                this.addressDescriptions = checkForCustomDescriptions(this.t, this.language, this.addressDescriptions, this.insurer, 'address');

                // Delay showing content to prevent flickering.
                setTimeout(async () => {
                    this.baseLoaded = true;
                    await super.handleBaseLoaded();
                }, 250);
            })
            .catch((x) => this.errorHandler.handle('PartialManageInsurersEdit.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }

    public async cancel(): Promise<void> {
        await super.remove({
            result: PartialViewResults.Cancelled,
            updateUrl: true
        });
    }

    public async save(): Promise<void> {
        const valid = this.validate();

        if (valid) {
            this.isLoading = true;
            try {
                // Clean values that are not valid.
                this.insurer.emails = this.insurer.emails.filter((email: Email) => {
                    const cleaned = email.value?.replace(/_/g, '');
                    return isDefined(cleaned) && isNotEmpty(cleaned);
                });

                this.insurer.phones = this.insurer.phones.filter((phone: Phone) => {
                    const cleaned = phone.value?.replace(/_/g, '');
                    return isDefined(cleaned) && isNotEmpty(cleaned);
                });

                cleanTranslatables(['name'], this.insurer, 1);
                await this.insurersApi.update(this.insurerId, this.authenticated.workspace.id, this.insurer);
                this.notifications.show(
                    this.t.tr('translation:partial-views.manage-insurers.notifications.save-successful.title'),
                    this.t
                        .tr('translation:partial-views.manage-insurers.notifications.save-successful.message') //
                        .replace('{entity}', `<span>'${this.insurer.name[this.language]}'</span>`),
                    {
                        type: 'success',
                        duration: 3000
                    }
                );
                setTimeout(async () => this.remove({ result: PartialViewResults.Ok, updateUrl: true }), 250);
            } catch (e) {
                this.isLoading = false;
                await this.errorHandler.handle('[edit-insurer]', e);
            }
        }
    }

    public async delete(): Promise<void> {
        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('partial-views.manage-insurers.questions.delete.title'),
                message: this.t
                    .tr('partial-views.manage-insurers.questions.delete.message') //
                    .replace('{entity}', `<span>'${this.insurer.name[this.language]}'</span>`),
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        this.deleting = true;
                        try {
                            await this.insurersApi.delete(this.insurerId, this.authenticated.workspace.id);
                            setTimeout(async () => this.remove({ result: PartialViewResults.Deleted, updateUrl: true }), 250);
                            this.notifications.show(
                                this.t.tr('translation:partial-views.manage-insurers.notifications.deleted-successfully.title'),
                                this.t
                                    .tr('translation:partial-views.manage-insurers.notifications.deleted-successfully.message') //
                                    .replace('{entity}', `<span>'${this.insurer.name[this.language]}'</span>`),
                                { type: 'success', duration: 3000 }
                            );
                        } catch (e) {
                            this.deleting = false;
                            await this.errorHandler.handle('[delete-insurer]', e);
                        }
                    }
                }
            })
        );
    }

    public manageTranslationsFor(property: string, required: boolean = false): void {
        this.manageTranslations({
            translations: this.insurer[property],
            callback: (updatedTranslations: any) => {
                this.insurer[property] = updatedTranslations;
            },
            required,
            type: property === 'description' ? 'textarea' : 'input'
        });
    }

    private validate(): boolean {
        this.validation.name = validateTranslation(this.insurer.name, this.language);

        for (let index = 0; index < this.insurer.emails.length; index++) {
            // Reset the validation.
            resetValidation(this.validation.emails[index]);

            const email = this.insurer.emails[index].value;
            // Only validate if user entered something.
            if (isNotDefined(email) || isEmpty(email)) continue;
            // Validate the phone number
            this.validation.emails[index].email =
                isNotEmpty(email) &&
                isValid(email.toLowerCase(), /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])+/);
            this.validation.emails[index].valid = this.validation.emails[index].email;
        }

        for (let index = 0; index < this.insurer.phones.length; index++) {
            // Reset the validation.
            resetValidation(this.validation.phones[index]);
            // Remove mask placeholder characters.
            const phone = this.insurer.phones[index].value?.replace(/_/g, '') || undefined;
            // Only validate if user entered something.
            if (isNotDefined(phone) || isEmpty(phone)) continue;
            // Validate the phone number
            this.validation.phones[index].phone = phone.length === 9;
            this.validation.phones[index].valid = this.validation.phones[index].phone;
        }

        return validateState(this.validation);
    }
}
