import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { AttachmentEntities, AttachmentsApiClient, BlobStorageAttachment, MediaLibraryApiClient } from '@wecore/sdk-attachments';
import { validateState } from '@wecore/sdk-utilities';

import { IEventAggregator, inject } from 'aurelia';
import { PartialViewResults } from '../../enums/partial-view-results';
import { BasePartialView } from '../../infra/base-partial-view';
import { CacheService } from '../../infra/cache-service';
import { ErrorHandler } from '../../infra/error-handler';
import { State } from '../../infra/store/state';
import { fileIsImage } from '../../infra/utilities';
import { ModalChooseLinkOrVideoUpload } from '../../modals/modal-choose-link-or-video-upload/modal-choose-link-or-video-upload';
import { ConfirmationOptions } from '../../models/confirmation-options';
import { PartialView } from '../../models/partial-view';
import { ModalService } from '../../services/service.modals';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, AttachmentsApiClient, MediaLibraryApiClient, ModalService)
export class PartialUploadFiles extends BasePartialView {
    public contentLanguage: string;
    public entityId: string;
    public mode: 'attach' | 'library' = 'library';
    public view: 'upload' | 'library' = 'library';
    public type: 'single' | 'multiple' = 'single';
    public validation: any = {
        uploads: true
    };
    public uploads: any[] = [];
    public hasScrolled: boolean = false;
    public container: HTMLDivElement;
    public files: string;
    public entityType: AttachmentEntities;
    public media: {
        attachment: BlobStorageAttachment;
        url: string;
    }[] = [];
    public useThumbnails: boolean = true;
    public hasImages: boolean = false;

    private contentTypes: string[];
    private pageSize: number = 10;
    private triggerEventOn: number = 100;
    private endOfList: boolean = false;
    private selection: {
        attachment: BlobStorageAttachment;
        url: string;
    }[] = [];
    private skip: number = 0;

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly attachmentsApi: AttachmentsApiClient,
        private readonly mediaLibrary: MediaLibraryApiClient,
        private readonly modalService: ModalService
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.contentLanguage = view.data.language;
        this.entityId = view.data.entityId;
        this.entityType = view.data.entityType;
        this.type = view.data.type;
        this.contentTypes = view.data.contentTypes;
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                await this.fetchMedia();
                this.hasImages = this.contentTypes.some((x) => x.includes('image/'));
                this.baseLoaded = true;
                setTimeout(() => this.container.addEventListener('scroll', (e) => this.handleScroll(e)));
            })
            .catch((x) => this.errorHandler.handle('PartialUploadFiles.attached', x));
    }

    public detaching(): void {
        this.container.removeEventListener('scroll', (e) => this.handleScroll(e));
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached, updateUrl: false });
    }

    public select(index: number): void {
        const item = this.media[index];
        if (this.type === 'single') this.selection = [item];
        else if (this.selection.some((x) => x.attachment.id === item.attachment.id)) {
            const index = this.selection.findIndex((x) => x.attachment.id === item.attachment.id);
            this.selection.splice(index, 1);
        } else this.selection.push(item);

        this.media = [
            ...(this.media.length > 0 ? [this.media.shift()] : []), //
            ...this.media
        ];
    }

    public async upload(): Promise<void> {
        const valid = this.validate();
        if (valid) {
            this.isLoading = true;
            try {
                if (this.uploads.any()) {
                    const uploadRequests = this.uploads.map((upload) => {
                        return this.mode === 'attach' ? this.mapToAttach(upload) : this.mapToLibrary(upload);
                    });
                    await Promise.all(uploadRequests);
                    this.changeView('library');
                    this.isLoading = false;
                }
            } catch (e) {
                this.errorHandler.handle('[upload-files]', e);
                this.isLoading = false;
            }
        }
    }

    public async selectMedia(): Promise<void> {
        const valid = this.validate();
        if (valid) {
            this.isLoading = true;
            try {
                // Always return fresh URLS.
                const items = await Promise.all([
                    ...this.selection.map(async (x) => {
                        const url = await this.mediaLibrary.getUrl(x.attachment.id, this.authenticated.workspace.id, this.useThumbnails);
                        return { attachment: x.attachment, url };
                    })
                ]);
                this.remove({
                    result: PartialViewResults.Ok,
                    data: {
                        items,
                        thumbnail: this.useThumbnails
                    }
                });
            } catch (e) {
                this.errorHandler.handle('[upload-files-select-media]', e);
                this.isLoading = false;
            }
        }
    }

    public cancel(): void {
        super.remove({
            result: PartialViewResults.Cancelled
        });
    }

    public isSelected(attachment: BlobStorageAttachment): boolean {
        return this.selection.some((x) => x.attachment.id === attachment.id);
    }

    public async changeView(view: 'upload' | 'library'): Promise<void> {
        this.uploads = [];
        this.selection = [];
        this.view = view;

        if (view === 'library') {
            this.skip = 0;
            this.endOfList = false;
            this.media = [];
            await this.fetchMedia();
        }
    }

    public filesSelected = (files: File[]): void => {
        for (const file of files) {
            this.uploads.push({
                file,
                progress: 0,
                loader: null,
                extension: `.${file.name.split('.').pop()}`,
                name: file.name
            });
        }
    };

    public async deleteFromMediaLibrary(item: { attachment: BlobStorageAttachment; url: string }, index: number): Promise<void> {
        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('translation:partial-views.upload-files.questions.delete.title'),
                message: this.t
                    .tr('translation:partial-views.upload-files.questions.delete.message') //
                    .replace('{entity}', `<span>'${item.attachment.name}${item.attachment.extension}'</span>`),
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        await this.mediaLibrary.delete(item.attachment.id, this.authenticated.workspace.id);
                        this.media.splice(index, 1);

                        const selected = this.selection.findIndex((x) => x.attachment.id === item.attachment.id);
                        if (selected > -1) this.selection.splice(selected, 1);
                    }
                }
            })
        );
    }

    public deleteFromUploads(index: number): void {
        this.uploads.splice(index, 1);
    }

    public isImage(contentType: string): boolean {
        return fileIsImage(contentType);
    }

    public isVideo(contentType: string): boolean {
        const videoTypes = [
            'video/x-flv', //
            'video/mp4',
            'application/x-mpegURL',
            'video/MP2T',
            'video/3gpp',
            'video/quicktime',
            'video/x-msvideo',
            'video/x-ms-wmv'
        ];
        return videoTypes.some((x) => x === contentType);
    }

    public async embedLink(): Promise<void> {
        const res = await this.modalService.open(ModalChooseLinkOrVideoUpload);

        if (res.status === 'cancel') return;
        const link = res.value;

        super.remove({
            result: PartialViewResults.Ok,
            data: {
                items: [{ url: link }],
                thumbnail: false
            }
        });
    }

    private validate(): boolean {
        return validateState(this.validation);
    }

    private async handleScroll(event: Event): Promise<void> {
        const target = event.target as HTMLElement;
        this.hasScrolled = target.scrollTop > 0;

        const isCloseToBottom = target.scrollTop + target.clientHeight >= target.scrollHeight - Number(this.triggerEventOn);
        if (isCloseToBottom && !this.endOfList && !this.isLoading) {
            this.skip += Number(this.pageSize);
            this.isLoading = true;
            await this.fetchMedia();
            this.isLoading = false;
        }
    }

    private async fetchMedia(): Promise<void> {
        const [media] = await Promise.all([
            this.mediaLibrary.search(this.authenticated.workspace.id, '', this.pageSize, this.skip, undefined, undefined, undefined, undefined, this.contentTypes) //
        ]);

        if (media.data.empty()) {
            this.endOfList = true;
            return;
        }

        const newMedia = await Promise.all([
            ...media.data.map(async (attachment) => {
                const url = await this.mediaLibrary.getUrl(attachment.id, this.authenticated.workspace.id, true);
                return { attachment, url };
            })
        ]);

        this.media = [...this.media, ...newMedia];
    }

    private mapToAttach(upload: any): Promise<BlobStorageAttachment> {
        const isImage = upload.file.type === 'image/jpg' || upload.file.type === 'image/jpeg' || upload.file.type === 'image/png';
        return this.attachmentsApi.upload(
            this.entityId, //
            this.authenticated.workspace.id,
            this.entityType,
            undefined,
            upload.name,
            'MediaLibrary',
            isImage,
            undefined,
            { data: upload.file, fileName: upload.file.name }
        );
    }

    private mapToLibrary(upload: any): Promise<BlobStorageAttachment> {
        const isImage = upload.file.type === 'image/jpg' || upload.file.type === 'image/jpeg' || upload.file.type === 'image/png';
        return this.mediaLibrary.upload(this.authenticated.workspace.id, upload.name, isImage, { data: upload.file, fileName: upload.file.name });
    }
}
