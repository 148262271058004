import { Store, connectTo } from '@aurelia/store-v1';
import { GetUserResponse, UserRoles, UsersApiClient } from '@wecore/sdk-core';
import { ExaminationRoom, GetSchedulerItemResponse, GetHealthcareTaskResponse, GetPracticeLocationResponse, PracticeLocationsApiClient } from '@wecore/sdk-healthcare';
import { SchedulerSettings } from '@wecore/sdk-management';
import { IPageState, PageState, getPageState, isDefined, isNotDefined, savePageState } from '@wecore/sdk-utilities';
import { IDisposable, IEventAggregator, bindable, inject } from 'aurelia';
import { addDays, format, getDay, startOfDay, startOfWeek } from 'date-fns';
import { CustomEvents } from '../../infra/events';
import { State } from '../../infra/store/state';
import { ViewEvents } from '../../infra/view-events';
import { PartialView } from '../../models/partial-view';
import { SchedulerPeriod } from '../../models/scheduler-period';
import { SchedulerRoomWrapper } from '../../models/scheduler-room-wrapper';
import { SchedulerState } from '../../models/scheduler-state';
import { BxSchedulerColumn } from '../bx-scheduler-column/bx-scheduler-column';
import { BxSchedulerColumnHeader } from '../bx-scheduler-column-header/bx-scheduler-column-header';
import { cloneDeep } from '../../infra/utilities';

@inject(Store<State>, IEventAggregator, UsersApiClient, PracticeLocationsApiClient)
@connectTo<State>()
export class BxScheduler {
    @bindable() public workspace: string;
    @bindable() public language: string;
    @bindable() public authenticatedUser: GetUserResponse;
    @bindable() public startDate: Date;
    @bindable() public viewWidth: number;
    @bindable() public minViewWidth: number;
    @bindable() public schedulerState: SchedulerState;
    @bindable() public setViewWidth: (setToOriginal: boolean, width?: number) => void;
    @bindable() public constrainViewWidth: (width: number) => number;
    @bindable() public hasRole: (role: UserRoles) => boolean;
    @bindable() public onNewSchedulerItem: (start: Date, end: Date, userId: string) => Promise<void>;
    @bindable() public onTaskClick: (task: GetHealthcareTaskResponse) => Promise<GetHealthcareTaskResponse>;
    @bindable() public onSchedulerItemClick: (schedulerItem: GetSchedulerItemResponse) => Promise<{ success: boolean; schedulerItem: GetSchedulerItemResponse }>;
    @bindable() public onSchedulerItemCreate: (start: Date, end: Date, userId: string, roomId: string, locationId: string) => Promise<{ success: boolean; schedulerItem: GetSchedulerItemResponse }>;
    @bindable() public onSchedulerItemEdit: (schedulerItem: GetSchedulerItemResponse) => Promise<{ success: boolean; schedulerItem: GetSchedulerItemResponse }>;
    @bindable() public onSchedulerItemDelete: (schedulerItem: GetSchedulerItemResponse) => Promise<{ success: boolean }>;
    @bindable() public onSchedulerItemMove: (schedulerItem: GetSchedulerItemResponse, newStart: Date, newEnd: Date) => Promise<{ success: boolean; schedulerItem: GetSchedulerItemResponse }>;
    @bindable() public onSchedulerItemResize: (schedulerItem: GetSchedulerItemResponse, newStart: Date, newEnd: Date) => Promise<{ success: boolean; schedulerItem: GetSchedulerItemResponse }>;
    @bindable() public onSchedulerItemDetails: (schedulerItem: GetSchedulerItemResponse) => Promise<{ success: boolean }>;
    @bindable() public onPatientCard: (schedulerItem: GetSchedulerItemResponse) => Promise<{ success: boolean }>;
    @bindable() public onConfirmSchedulerItem: (schedulerItem: GetSchedulerItemResponse) => Promise<{ success: boolean }>;
    @bindable() public onMarkNoShow: (schedulerItem: GetSchedulerItemResponse) => Promise<{ success: boolean }>;
    @bindable() public onUnmarkNoShow: (schedulerItem: GetSchedulerItemResponse) => Promise<{ success: boolean }>;
    @bindable() public onMarkCancelled: (schedulerItem: GetSchedulerItemResponse) => Promise<{ success: boolean }>;
    @bindable() public onUnmarkCancelled: (schedulerItem: GetSchedulerItemResponse) => Promise<{ success: boolean }>;

    public state: State;
    public periods: SchedulerPeriod[] = [];
    public columns: any = {};
    public headers: BxSchedulerColumnHeader[] = [];
    public showToday: boolean = false;
    public baseLoaded: boolean = false;
    public settings: SchedulerSettings;
    public users: GetUserResponse[] = [];
    public wrappers: SchedulerRoomWrapper[] = [];
    public week: { [key: string]: Date } = {};
    public scrollContainer: HTMLDivElement;
    public body: HTMLDivElement;
    public scrollPositionX: number = 0;
    public UserRoles: typeof UserRoles = UserRoles;
    public scrollWidth: number = 0;
    public refresh: boolean = false;
    public maxAmountOfTasks: number = 0;
    public days: number[] = [];

    private keyEventCb: (e: KeyboardEvent) => void;
    private clickEventCb: (e: MouseEvent) => void;
    private subscriptions: IDisposable[] = [];
    private pageState: IPageState;

    public constructor(
        public readonly store: Store<State>, //
        private readonly events: IEventAggregator,
        private readonly usersApi: UsersApiClient,
        private readonly locationsApi: PracticeLocationsApiClient
    ) {}

    public async bound(): Promise<void> {
        let periodHeight: number = 100;
        // Set the period height based on the configured slot size.
        switch (this.state.schedulerSettings.slotSize) {
            case 5:
                periodHeight = 250;
                break;
            case 10:
                periodHeight = 125;
                break;
            case 15:
                periodHeight = 100;
                break;
            case 30:
                periodHeight = 75;
                break;
        }
        this.settings = new SchedulerSettings({
            ...this.state.schedulerSettings,
            periodHeight
        });

        this.days = cloneDeep(this.settings.days);
        this.pageState =
            getPageState('bx-scheduler') ??
            new PageState({
                name: 'bx-scheduler',
                values: {
                    expandedTasks: true,
                    users: [],
                    locations: [],
                    startDate: this.startDate ?? startOfDay(new Date()),
                    // This will set below.
                    days: undefined,
                    view: 'week',
                    columnWidth: undefined
                }
            });

        this.startDate = new Date(this.pageState.values.startDate);
        this.setColumns();

        // Backwards compatibility for scheduler page settings
        if (isNotDefined(this.pageState.values.days)) {
            this.pageState.values.days = {};
            for (const day of this.days) {
                this.pageState.values.days[day] = { visible: true };
            }
        }
        if (isNotDefined(this.pageState.values.view)) this.pageState.values.view = 'week';
        if (isNotDefined(this.pageState.values.mode)) this.pageState.values.mode = 'all';
        if (isNotDefined(this.pageState.values.showCancelled)) this.pageState.values.showCancelled = false;
        if (isNotDefined(this.pageState.values.columnWidth)) this.pageState.values.columnWidth = this.settings.minColumnWidth;

        savePageState(this.pageState);

        // If no filter is selected, default to the authenticated user.
        const hasUsersInFilter = this.pageState.values.users && this.pageState.values.users.any();
        const hasLocationsInFilter = this.pageState.values.wrappers && this.pageState.values.wrappers.any();
        if (!hasUsersInFilter && !hasLocationsInFilter) {
            this.pageState.values.users = [this.authenticatedUser.id];
            this.pageState.values.wrappers = [];
        }

        if (this.pageState.values.users?.any() ?? false) {
            const response = await this.usersApi.search(this.workspace, undefined, 100, 0, undefined, undefined, undefined, undefined, undefined, undefined, undefined, this.pageState.values.users);
            this.users = response.data;
        }

        if (this.pageState.values.wrappers?.any() ?? false) {
            const response = await this.locationsApi.search(this.workspace, undefined, 100, 0, undefined, undefined, undefined, this.pageState.values.wrappers);
            this.wrappers = response.data
                .selectMany((location: GetPracticeLocationResponse) =>
                    location.rooms
                        .filter((r) => r.isTreatmentRoom) //
                        .map((room: ExaminationRoom) => new SchedulerRoomWrapper({ location, room }))
                )
                // Sort the wrappers by location name and then by room name.
                .sort((a: SchedulerRoomWrapper, b: SchedulerRoomWrapper) => {
                    const nameA = isDefined(a.location.applicationName) ? a.location.applicationName[this.language] : a.location.name[this.language];
                    const nameB = isDefined(b.location.applicationName) ? b.location.applicationName[this.language] : b.location.name[this.language];

                    // Compare location names first
                    var locationComp = nameA.localeCompare(nameB);
                    // If the locations are the same, compare room name
                    if (locationComp === 0) return a.room.name[this.language].localeCompare(b.room.name[this.language]);
                    return locationComp;
                });
        }

        // Render periods based on the start and end hour from the scheduler settings.
        for (let hour = this.settings.start.hour; hour <= this.settings.end.hour; hour++) {
            // Only add the last hour if the end minute is not 0.
            if (hour === this.settings.end.hour && this.settings.end.minute === 0) continue;
            this.periods.push(new SchedulerPeriod({ hour: hour, display: `${hour.toString().padStart(2, '0')}:00` }));
        }

        this.render();

        const removeContextMenus = (e: Event) => {
            const target = e.target as HTMLElement;
            const loadingItem = document.querySelector('.loading[data-type="scheduler-item"]');
            if (
                // When we have a scheduler item that has a loading state.
                isDefined(loadingItem) &&
                // When we have a target WITHOUT a data-function attribute.
                isDefined(target) &&
                !target.hasAttribute('data-function')
            )
                // Remove the loading class.
                loadingItem.classList.remove('loading');

            const clones = document.querySelectorAll('[data-type="context-menu-clone"]');
            clones.forEach((c) => c.remove());
        };

        this.keyEventCb = (e: KeyboardEvent) => {
            if (e.key === 'Escape') removeContextMenus(e);
        };
        this.clickEventCb = (e: MouseEvent) => removeContextMenus(e);

        document.addEventListener('keydown', this.keyEventCb);
        document.addEventListener('mousedown', this.clickEventCb);

        this.subscriptions = [
            ...(this.subscriptions ?? []),
            this.events.subscribe(CustomEvents.SchedulerDateChanged, async (date: Date) => {
                // Because we're changing the way the columns are rendered
                // we need to reset the bindings. So we set
                // the refresh property to true and then set it to false
                // after we have prepared the new columns.
                this.refresh = true;

                this.maxAmountOfTasks = 0;
                this.startDate = date;

                this.resetSchedulerState();
                this.pageState.values.startDate = date;

                this.setColumns();
                savePageState(this.pageState);

                this.render();
                setTimeout(() => (this.refresh = false));
            }),
            this.events.subscribe(CustomEvents.SchedulerUsersChanged, async (users: GetUserResponse[]) => {
                // Because we're changing the way the columns are rendered
                // we need to reset the bindings. So we set
                // the refresh property to true and then set it to false
                // after we have prepared the new columns.
                this.refresh = true;
                // Make sure all references are reset before setting the new wrappers.
                this.wrappers = null;
                this.users = null;

                this.maxAmountOfTasks = 0;
                if (users.any()) {
                    this.wrappers = [];
                    this.pageState.values.wrappers = [];
                }

                this.users = cloneDeep(users).sort((a: GetUserResponse, b: GetUserResponse) => {
                    // Compare last names first
                    var lastNameComparison = a.lastName.localeCompare(b.lastName);
                    // If the last names are the same, compare first names
                    if (lastNameComparison === 0) return a.firstName.localeCompare(b.firstName);
                    return lastNameComparison;
                });

                this.resetSchedulerState();
                this.pageState.values.users = users.map((x) => x.id);
                this.renderScrollWidth();
                this.setColumns();
                savePageState(this.pageState);
                this.render();

                // Render view.
                setTimeout(() => (this.refresh = false));
            }),
            this.events.subscribe(CustomEvents.SchedulerLocationsChanged, async (locations: GetPracticeLocationResponse[]) => {
                // Because we're changing the way the columns are rendered
                // we need to reset the bindings. So we set
                // the refresh property to true and then set it to false
                // after we have prepared the new columns.
                this.refresh = true;
                // Make sure all references are reset before setting the new wrappers.
                this.wrappers = null;

                this.maxAmountOfTasks = 0;
                if (locations.any()) {
                    this.users = [];
                    this.pageState.values.users = [];
                }
                const wrappers = cloneDeep(locations)
                    //  Select all the rooms that are treatment rooms and flatten the array.
                    .selectMany((location: GetPracticeLocationResponse) =>
                        location.rooms
                            .filter((r) => r.isTreatmentRoom) //
                            .map((room: ExaminationRoom) => new SchedulerRoomWrapper({ location, room }))
                    )
                    // Sort the wrappers by location name and then by room name.
                    .sort((a: SchedulerRoomWrapper, b: SchedulerRoomWrapper) => {
                        const nameA = isDefined(a.location.applicationName) ? a.location.applicationName[this.language] : a.location.name[this.language];
                        const nameB = isDefined(b.location.applicationName) ? b.location.applicationName[this.language] : b.location.name[this.language];

                        // Compare location names first
                        var locationComp = nameA.localeCompare(nameB);
                        // If the locations are the same, compare room name
                        if (locationComp === 0) return a.room.name[this.language].localeCompare(b.room.name[this.language]);
                        return locationComp;
                    });
                this.wrappers = wrappers;

                this.resetSchedulerState();
                this.pageState.values.wrappers = wrappers.map((x) => x.location.id);
                this.renderScrollWidth();
                this.setColumns();
                savePageState(this.pageState);
                this.render();
                setTimeout(() => (this.refresh = false));
            }),
            this.events.subscribe(CustomEvents.SchedulerViewChanged, async (view: 'day' | 'week') => {
                if (view === this.pageState.values.view) return;

                // Because we're changing the way the columns are rendered
                // we need to reset the bindings. So we set
                // the refresh property to true and then set it to false
                // after we have prepared the new columns.
                this.refresh = true;

                this.maxAmountOfTasks = 0;

                this.pageState.values.view = view;
                savePageState(this.pageState);
                this.setColumns();
                this.renderScrollWidth();
                this.render();

                setTimeout(() => (this.refresh = false));
            }),
            this.events.subscribe(CustomEvents.SchedulerColumnsVisibilityChanged, () => {
                this.renderScrollWidth();
            }),
            this.events.subscribe(CustomEvents.SchedulerTasksLoaded, (data: { date: Date; amount: number }) => {
                // Because we need to highest amount of tasks in a week/day we
                // only need to check if the amount of tasks is higher than the current max.
                // Also, if the date is not in the current week, we don't need to check.
                if (
                    data.amount <= this.maxAmountOfTasks ||
                    Object.entries(this.week)
                        .map((item) => item[1])
                        .every((day) => format(day, 'yyyyMMdd') !== format(data.date, 'yyyyMMdd'))
                )
                    return;
                this.maxAmountOfTasks = data.amount;
            }),
            this.events.subscribe(CustomEvents.SchedulerColumnsWidthChanged, (width: number) => {
                this.pageState.values.columnWidth = Number(width);
                savePageState(this.pageState);
                this.renderScrollWidth();
            }),
            this.events.subscribe(CustomEvents.SchedulerColumnsModeChanged, (mode: 'all' | 'schedules') => {
                // this.pageState.values.mode = mode;
                // savePageState(this.pageState);
                // this.renderScrollWidth();
            }),
            this.events.subscribe(CustomEvents.SchedulerViewShowCancelledChanged, (showCancelled: boolean) => {
                // Because we're changing the way the columns are rendered
                // we need to reset the bindings. So we set
                // the refresh property to true and then set it to false
                // after we have prepared the new columns.
                this.refresh = true;
                this.pageState.values.showCancelled = showCancelled;
                this.maxAmountOfTasks = 0;

                savePageState(this.pageState);
                this.setColumns();
                this.renderScrollWidth();
                this.render();

                setTimeout(() => (this.refresh = false));
            }),
            this.events.subscribe(ViewEvents.Resizing, (data: { partial: PartialView; width: number }) => {
                if (data.partial.name !== 'scheduler') return;
                // Remove 300 sidebar width from the width of the scheduler.
                if (this.scrollWidth < data.width - 300) this.scrollWidth = data.width - 300;
            })
        ];

        this.baseLoaded = true;

        this.renderScrollWidth();
    }

    public detaching(): void {
        this.subscriptions.forEach((s) => s.dispose());
        document.removeEventListener('keydown', this.keyEventCb);
        document.removeEventListener('mousedown', this.clickEventCb);
    }

    public handleScroll(_: Event): void {
        this.scrollPositionX = this.scrollContainer.scrollLeft;
    }

    public handleNewSchedulerItem = (start: Date, end: Date, userId: string): void => {
        this.onNewSchedulerItem(start, end, userId);
    };

    public handleSchedulerItemClick = (schedulerItem: GetSchedulerItemResponse): Promise<{ success: boolean; schedulerItem: GetSchedulerItemResponse }> => {
        return this.onSchedulerItemClick(schedulerItem);
    };

    public handleSchedulerItemCreate = async (start: Date, end: Date, userId: string, roomId: string, locationId: string): Promise<{ success: boolean; schedulerItem: GetSchedulerItemResponse }> => {
        const response = await this.onSchedulerItemCreate(start, end, userId, roomId, locationId);
        return response;
    };

    public handleSchedulerItemEdit = (schedulerItem: GetSchedulerItemResponse): Promise<{ success: boolean; schedulerItem: GetSchedulerItemResponse }> => {
        return this.onSchedulerItemEdit(schedulerItem);
    };

    public handleSchedulerItemDelete = (schedulerItem: GetSchedulerItemResponse): Promise<{ success: boolean }> => {
        return this.onSchedulerItemDelete(schedulerItem);
    };

    public handleSchedulerItemMove = (schedulerItem: GetSchedulerItemResponse, newStart: Date, newEnd: Date): Promise<{ success: boolean; schedulerItem: GetSchedulerItemResponse }> => {
        return this.onSchedulerItemMove(schedulerItem, newStart, newEnd);
    };

    public handleSchedulerItemResize = (schedulerItem: GetSchedulerItemResponse, newStart: Date, newEnd: Date): Promise<{ success: boolean; schedulerItem: GetSchedulerItemResponse }> => {
        return this.onSchedulerItemResize(schedulerItem, newStart, newEnd);
    };

    public handleSchedulerItemDetails = (schedulerItem: GetSchedulerItemResponse): Promise<{ success: boolean }> => {
        return this.onSchedulerItemDetails(schedulerItem);
    };

    public handlePatientCard = (schedulerItem: GetSchedulerItemResponse): Promise<{ success: boolean }> => {
        return this.onPatientCard(schedulerItem);
    };

    public handleConfirmSchedulerItem = (schedulerItem: GetSchedulerItemResponse): Promise<{ success: boolean }> => {
        return this.onConfirmSchedulerItem(schedulerItem);
    };

    public handleMarkNoShow = (schedulerItem: GetSchedulerItemResponse): Promise<{ success: boolean }> => {
        return this.onMarkNoShow(schedulerItem);
    };

    public handleUnmarkNoShow = (schedulerItem: GetSchedulerItemResponse): Promise<{ success: boolean }> => {
        return this.onUnmarkNoShow(schedulerItem);
    };

    public handleMarkCancelled = (schedulerItem: GetSchedulerItemResponse): Promise<{ success: boolean }> => {
        return this.onMarkCancelled(schedulerItem);
    };

    public handleUnmarkCancelled = (schedulerItem: GetSchedulerItemResponse): Promise<{ success: boolean }> => {
        return this.onUnmarkCancelled(schedulerItem);
    };

    private renderScrollWidth(): void {
        setTimeout(() => {
            this.scrollWidth = this.body.scrollWidth + 60;
        }, 50);
    }

    private render(): void {
        this.renderScrollWidth();
        // Render the week based on the provided start date.
        // First get the monday of the week.
        const firstDay = startOfWeek(this.startDate, { weekStartsOn: 1 });

        // Then render the week.
        for (let day = 0; day < 7; day++) {
            const newDate = startOfDay(addDays(firstDay, day));
            const newDay = getDay(newDate);
            this.week[newDay] = newDate;
        }

        const allDays = Object.entries(this.week).map((item) => item[1]);
        this.showToday =
            this.pageState.values.view === 'day' //
                ? format(this.startDate, 'yyyyMMdd') !== format(new Date(), 'yyyyMMdd')
                : allDays.every((x) => format(x, 'yyyyMMdd') !== format(new Date(), 'yyyyMMdd'));

        Object.getOwnPropertyNames(this.columns).forEach((prop) => {
            this.columns[prop].forEach((column: BxSchedulerColumn) => {
                if (isDefined(column)) column.refresh();
            });
        });

        this.headers.forEach((x) => {
            if (isDefined(x)) x.refresh();
        });
    }

    private resetSchedulerState(): void {
        this.schedulerState.isItemDragging = false;
        this.schedulerState.isItemResizing = false;
        this.schedulerState.isColumnDragging = false;
    }

    private setColumns(): void {
        if (this.pageState.values.view === 'day') {
            this.columns = {
                [0]: []
            } as any;
        } else {
            for (let i = 0; i < this.days.length; i++) {
                this.columns[i] = [];
            }
        }
    }
}
