<import from="converters/translate" />
<import from="./templates/template-therapy-category/template-therapy-category" />
<import from="./templates/template-therapy-step/template-therapy-step" />

<template class="!flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex flex-col" css.bind="styles">
        <div class="flex flex-col flex-1 overflow-hidden pl-7">
            <template if.bind="baseLoaded && def">
                <div class="flex flex-1 w-full overflow-hidden pt-7">
                    <div class="flex flex-col w-full gap-3 pb-5 pl-1 pr-8 overflow-y-auto">
                        <div class="sticky top-0 z-10 flex gap-1 pb-2 bg-white border-b border-gray-200">
                            <p class="font-medium text-gray-400" t="translation:partial-views.medical-treatment-protocols.default-values.title"></p>
                            <p class="font-medium text-gray-400" t="translation:global.labels.for"></p>
                            ${therapy.name | translate:language}
                        </div>
                        <div class="grid grid-cols-12 gap-2">
                            <template repeat.for="item of therapy.plan.stepsToTake">
                                <template if.bind="item.type === MedicalTherapyPlanItemTypes.Category">
                                    <template-therapy-category
                                        component.ref="states[item.id].model"
                                        item.bind="item"
                                        flow.bind="therapy.plan.flow"
                                        def.bind="def"
                                        states.bind="states"
                                        get-flat.bind="getFlat">
                                    </template-therapy-category>
                                </template>
                                <template else>
                                    <template-therapy-step
                                        component.ref="states[item.id].model"
                                        item.bind="item"
                                        flow.bind="therapy.plan.flow"
                                        def.bind="def"
                                        states.bind="states"
                                        get-flat.bind="getFlat">
                                    </template-therapy-step>
                                </template>
                            </template>
                        </div>
                    </div>
                </div>
            </template>
            <template else>
                <div class="flex flex-1">
                    <div class="flex flex-1 pt-8 pb-5 space-x-6 overflow-hidden">
                        <div class="flex flex-1">
                            <div class="flex flex-col flex-1">
                                <div class="grid grid-cols-2 gap-5">
                                    <ux-skeleton class="col-span-2" show-background="true" type="container">
                                        <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                            <ux-skeleton type="line" padding="pr-60"></ux-skeleton>
                                            <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                        </div>
                                    </ux-skeleton>
                                    <ux-skeleton class="col-span-2" show-background="true" type="container">
                                        <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                            <ux-skeleton type="line" padding="pr-20"></ux-skeleton>
                                            <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                        </div>
                                    </ux-skeleton>
                                    <ux-skeleton class="col-span-2" show-background="true" type="container">
                                        <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                            <ux-skeleton type="line" padding="pr-20"></ux-skeleton>
                                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                            <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                                            <ux-skeleton type="line" padding="pr-8"></ux-skeleton>
                                            <ux-skeleton type="line" padding="pr-6"></ux-skeleton>
                                            <ux-skeleton type="line" padding="pr-0"></ux-skeleton>
                                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                        </div>
                                    </ux-skeleton>
                                    <ux-skeleton class="col-span-2" show-background="true" type="container">
                                        <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                            <ux-skeleton type="line" padding="pr-20"></ux-skeleton>
                                            <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                        </div>
                                    </ux-skeleton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <div class="pr-8">
                <div class="flex justify-between py-4 border-t border-gray-200 gap-x-2">
                    <div class="flex gap-x-2">
                        <!-- <ux-button size="sm" color="danger" disabled.bind="isLoading || deleting" loading.bind="deleting" click.trigger="delete()">
                                <span class="pointer-events-none" t="global.buttons.delete"></span>
                            </ux-button> -->
                    </div>
                    <div class="flex gap-x-2">
                        <ux-button size="sm" color="dark" mode="outline" disabled.bind="isLoading" click.trigger="cancel()">
                            <span class="pointer-events-none" t="global.buttons.cancel"></span>
                        </ux-button>
                        <ux-button size="sm" color="dark" disabled.bind="isLoading" loading.bind="isLoading" click.trigger="save(true)">
                            <span class="pointer-events-none" t="global.buttons.process"></span>
                        </ux-button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
