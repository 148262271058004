<import from="converters/translate" />
<import from="converters/format-time" />
<import from="converters/truncate" />
<import from="converters/date" />
<import from="converters/schedule-particular-to-string" />
<import from="bx/bx-scheduler-scheduler-item/bx-scheduler-scheduler-item" />
<import from="bx/bx-scheduler-period/bx-scheduler-period" />

<div css="min-width: ${pageState.values.columnWidth}px; width: ${pageState.values.columnWidth}px;" ref="column" class="relative flex flex-col flex-1 w-full border-r border-gray-200">
    <div if.bind="baseLoaded && schedules && schedules.length > 0 " class="absolute z-[5] flex h-full bg-transparent px-[4px]" style="width: calc(100% - 8px)">
        <template repeat.for="item of schedules">
            <template repeat.for="slotAndRoom of item.slotsAndRooms">
                <let styling.bind="generateStylingForScheduleSlot(slotAndRoom.slot)"></let>
                <div
                    data-type="schedule"
                    css="top: ${styling.top}; height: ${styling.height};"
                    class="absolute flex flex-col items-start justify-center w-full transition duration-300 ease-in-out rounded-sm group bg-blue-100/50">
                    <ux-tooltip class="h-full" content.bind="generateScheduleName(item, slotAndRoom)">
                        <div class="flex w-[25px] flex-1 select-none justify-center gap-2 truncate px-1 py-3 text-xs text-blue-400 [writing-mode:vertical-rl] hover:bg-blue-100/50">
                            <p class="truncate" innerhtml.bind="generateScheduleName(item, slotAndRoom)"></p>
                        </div>
                    </ux-tooltip>
                </div>
            </template>
        </template>
    </div>
    <!-- Render area -->
    <!-- The width is reduced so that the tooltip of a schedule can be hovored to so that it can be read more easily -->
    <div w-[calc(100%-25px)].class="schedules.length > 0" w-full.class="schedules.length === 0" class="absolute right-0 z-10 flex h-full bg-transparent px-[4px]">
        <div
            id="${pageState.values.days[day].visible ? '': 'hidden-'}render-area-${parentIndex}-${index}"
            data-type="render-area"
            mouseleave.trigger="hideHover($event)"
            mousedown.trigger="handleDragStart($event)"
            data-date.bind="date"
            ref="renderContainer"
            class="relative flex-1">
            <bx-scheduler-scheduler-item
                if.bind="baseLoaded"
                repeat.for="schedulerItem of schedulerItems"
                scheduler-item.bind="schedulerItem"
                column-index.bind="index"
                column-parent-index.bind="parentIndex"
                column-date.bind="date"
                column-height.bind="height"
                layers.bind="layers"
                settings.bind="settings"
                state.bind="state"
                has-role.bind="hasRole"
                on-drag-start.bind="handleSchedulerItemDragStart"
                on-drag.bind="handleSchedulerItemDrag"
                on-drag-end.bind="handleSchedulerItemDragEnd"
                on-drag-cancelled.bind="handleSchedulerItemDragCancelled"
                on-resize-start.bind="handleSchedulerItemResizeStart"
                on-resize.bind="handleSchedulerItemResize"
                on-resize-end.bind="handleSchedulerItemResizeEnd"
                on-resize-cancelled.bind="handleSchedulerItemResizeCancelled"
                on-click.bind="onSchedulerItemClick"
                on-edit.bind="onSchedulerItemEdit"
                on-delete.bind="onSchedulerItemDelete"
                on-details.bind="onSchedulerItemDetails"
                on-patient-card.bind="onPatientCard"
                on-confirm-scheduler-item.bind="onConfirmSchedulerItem"
                on-mark-no-show.bind="onMarkNoShow"
                on-unmark-no-show.bind="onUnmarkNoShow"
                on-mark-cancelled.bind="onMarkCancelled"
                on-unmark-cancelled.bind="onUnmarkCancelled"
                page-state.two-way="pageState">
            </bx-scheduler-scheduler-item>
            <template else>
                <div
                    repeat.for="i of loader.amount"
                    class="absolute w-full"
                    css.bind="{
                    'top': loader.top[i] + '%',
                    'height': loader.height[i] + '%'                
                }">
                    <ux-skeleton show-background="true" type="container">
                        <div
                            class="flex flex-col w-full h-full gap-2 px-5 py-4"
                            css.bind="{
                            'height': loader.height[i] + '%'                
                        }">
                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                        </div>
                    </ux-skeleton>
                </div>
            </template>
        </div>
        <div data-type="column-context-menu" class="absolute z-[10000] hidden w-48 rounded-lg border border-gray-200 bg-white">
            <div class="flex justify-between px-4 py-2 border-b border-gray-200">
                <p class="text-xs text-gray-600" t="translation:global.labels.timeslot"></p>
                <p data-type="slot" class="text-xs text-blue-800"></p>
            </div>
            <ul class="p-1">
                <li class="flex">
                    <a
                        href="javascript:"
                        data-function="create"
                        class="flex-1 px-3 py-2 text-sm text-gray-800 rounded-lg hover:bg-gray-100 hover:text-gray-900"
                        t="translation:partial-views.scheduler.buttons.create-scheduler-item"></a>
                </li>
                <li class="flex">
                    <a
                        href="javascript:"
                        data-function="paste"
                        class="flex-1 px-3 py-2 text-sm text-gray-800 rounded-lg hover:bg-gray-100 hover:text-gray-900"
                        t="translation:global.buttons.paste"></a>
                </li>
            </ul>
        </div>
    </div>
    <!-- Periods -->
    <div class="z-0 flex flex-col flex-1">
        <bx-scheduler-period repeat.for="period of periods" page-state.two-way="pageState" period.bind="period" settings.bind="settings"></bx-scheduler-period>
    </div>
</div>
