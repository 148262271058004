<import from="converters/translate" />
<import from="converters/date" />

<template class="!flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex flex-col overflow-hidden" css.bind="styles">
        <ux-header>
            <div class="flex items-center justify-between mb-3">
                <p class="text-sm font-medium" t="partial-views.patient-contact-moments.list.title"></p>
                <div class="flex space-x-1">
                    <button if.bind="closeable" class="flex items-center justify-center p-1 bg-gray-100 rounded-full hover:bg-gray-200" click.trigger="close()">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4">
                            <path
                                fill-rule="evenodd"
                                d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z"
                                clip-rule="evenodd" />
                        </svg>
                    </button>
                </div>
            </div>
            <div class="flex items-center space-x-1">
                <ux-input debounce="450" allow-clear="true" oninput.trigger="handleSearch($event)" onclear.trigger="handleSearch($event)" t="[placeholder]global.placeholders.search"></ux-input>
                <button click.trigger="refresh()" class="h-[38px] items-center rounded-lg border border-gray-300 bg-white p-2 text-blue-500 ring-blue-500 ring-offset-1 active:ring-1">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                    </svg>
                </button>
            </div>
        </ux-header>
        <div ref="scrollContainer" class="flex flex-col flex-1 h-full pb-6 overflow-y-auto scroll-smooth">
            <template if.bind="baseLoaded">
                <ul class="flex flex-col flex-1">
                    <li repeat.for="log of logs" class="group ">
                        <a click.trigger="details(log)" id.bind="log.id" href="javascript:" class="flex items-center justify-between h-full px-6 py-3 border-b border-gray-200">
                            <div class="flex items-center h-full gap-x-2">
                                <div
                                    if.bind="log.status === EmailLogStatuses.New"
                                    class="w-1 h-full transition-all duration-300 ease-in-out bg-blue-500 rounded-full ring-blue-300 group-hover:ring-2"></div>
                                <div
                                    if.bind="log.status === EmailLogStatuses.Failed"
                                    class="w-1 h-full transition-all duration-300 ease-in-out bg-red-500 rounded-full ring-red-300 group-hover:ring-2"></div>
                                <div
                                    if.bind="log.status === EmailLogStatuses.Sent"
                                    class="w-1 h-full transition-all duration-300 ease-in-out bg-green-500 rounded-full ring-green-300 group-hover:ring-2"></div>
                                <div class="flex flex-col w-full">
                                    <div class="flex items-center gap-1">
                                        <p if.bind="log.template" class="text-sm font-semibold group-hover:text-gray-600">${log.template.translations | translate:language}</p>
                                        <p else class="text-sm font-semibold group-hover:text-gray-600">${log.subject}</p>
                                        <svg
                                            if.bind="log.attachments && log.attachments.length > 0"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="currentColor"
                                            class="h-3.5 w-3.5 text-gray-500">
                                            <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                d="m18.375 12.739-7.693 7.693a4.5 4.5 0 0 1-6.364-6.364l10.94-10.94A3 3 0 1 1 19.5 7.372L8.552 18.32m.009-.01-.01.01m5.699-9.941-7.81 7.81a1.5 1.5 0 0 0 2.112 2.13" />
                                        </svg>
                                    </div>
                                    <div if.bind="log.status === EmailLogStatuses.New" class="flex flex-col text-xs text-gray-500">
                                        <p class="text-xs text-gray-500">
                                            <span t="translation:partial-views.patient-contact-moments.labels.created-at"></span>
                                            <span>${ log.createdAt | date:'dd-MM-yyyy HH:mm:ss' }</span>
                                        </p>
                                        <span t="translation:partial-views.patient-contact-moments.labels.waiting-for-send"></span>
                                    </div>
                                    <p if.bind="log.status === EmailLogStatuses.Sent" class="text-xs text-gray-500">
                                        <span t="translation:partial-views.patient-contact-moments.labels.sent-at"></span>
                                        <span>${ log.sentAt | date:'dd-MM-yyyy HH:mm:ss' }</span>
                                    </p>
                                    <div if.bind="log.status === EmailLogStatuses.Failed" class="text-xs text-gray-500">
                                        <p class="text-xs text-gray-500">
                                            <span t="translation:partial-views.patient-contact-moments.labels.failed-at"></span>
                                            <span>${ log.failedAt | date:'dd-MM-yyyy HH:mm:ss' }</span>
                                        </p>
                                        <p class="text-xs text-gray-500"><span t="translation:partial-views.patient-contact-moments.labels.reason"></span><span>: ${ log.reason }</span></p>
                                    </div>
                                </div>
                            </div>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 text-gray-500 group-hover:text-black">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                            </svg>
                        </a>
                    </li>
                    <li if.bind="isLoading" class="flex justify-center py-5">
                        <ux-spinner size="xs"></ux-spinner>
                    </li>
                    <li if.bind="endOfList" class="flex justify-center py-5 text-gray-500">
                        <span class="text-sm" t="global.labels.end-of-list"></span>
                    </li>
                    <li if.bind="logs.length === 0 && !isLoading" class="flex justify-center py-5">
                        <span class="text-sm" t="global.labels.no-results"></span>
                    </li>
                </ul>
            </template>
            <template else>
                <div class="flex flex-col flex-1 px-6 pt-2 gap-y-2">
                    <ux-skeleton show-background.bind="false" type="container">
                        <div class="flex flex-col w-full py-1 space-y-5">
                            <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-0"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-2"></ux-skeleton>
                            <ux-skeleton type="line"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-2"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                            <ux-skeleton type="line"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-4"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-2"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                        </div>
                    </ux-skeleton>
                </div>
            </template>
        </div>
    </section>
</template>
