import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { LegalEntities } from '@wecore/sdk-core';
import { GetWorkspaceResponse, WorkspacesApiClient } from '@wecore/sdk-management';
import { isNotEmpty, resetValidation, validateState } from '@wecore/sdk-utilities';
import { IEventAggregator, inject } from 'aurelia';
import SignaturePad from 'signature_pad';
import { PartialViewResults } from '../../enums/partial-view-results';
import { BasePartialView } from '../../infra/base-partial-view';
import { CacheService } from '../../infra/cache-service';
import { ErrorHandler } from '../../infra/error-handler';
import { State } from '../../infra/store/state';
import { ConfirmationOptions } from '../../models/confirmation-options';
import { PartialView } from '../../models/partial-view';
import { ModalService } from '../../services/service.modals';
import { TemplateEula } from './templates/template-eula/template-eula';
import { TemplateProcessingAgreement } from './templates/template-processing-agreement/template-processing-agreement';
import { TemplateTermsAndConditions } from './templates/template-terms-and-conditions/template-terms-and-conditions';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, WorkspacesApiClient, ModalService)
export class PartialSignDocument extends BasePartialView {
    public documentUrl: string;
    public view: 'document' | 'sign' = 'document';
    public signaturePad: any;
    public signatureAdded: boolean = false;
    public validation: any = {
        signature: true,
        valid: true
    };
    public version: string;
    public entity: LegalEntities;
    public termsAndConditions: TemplateTermsAndConditions;
    public processingAgreement: TemplateProcessingAgreement;
    public eula: TemplateEula;
    public company: GetWorkspaceResponse;
    public documentType: 'terms-and-conditions' | 'processing-agreement' | 'eula';

    private signatureCanvas: HTMLCanvasElement;
    private companyId: string;

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly workspacesApi: WorkspacesApiClient,
        private readonly modalService: ModalService
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        this.documentUrl = view.data.documentUrl;
        this.entity = view.data.entity ?? LegalEntities.Individual;
        this.documentType = view.data.documentType;
        this.version = view.data.version;
        this.companyId = view.data.companyId;

        super.setView({ view });
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                if (this.entity === LegalEntities.Company) this.company = await this.workspacesApi.getById(this.companyId);
                this.baseLoaded = true;

                setTimeout(() => {
                    this.signatureCanvas.height = this.signatureCanvas.offsetHeight;
                    this.signatureCanvas.width = this.signatureCanvas.offsetWidth;
                    this.signaturePad = new SignaturePad(this.signatureCanvas);
                    this.signaturePad.addEventListener('beginStroke', () => (this.signatureAdded = true));
                }, 250);
            })
            .catch((x) => this.errorHandler.handle('PartialSignDocument.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }

    public cancel(): void {
        super.remove({
            result: PartialViewResults.Cancelled
        });
    }

    public async sign(): Promise<void> {
        if (this.view === 'document') {
            this.setViewTo('sign');
            return;
        }

        resetValidation(this.validation);

        // Validate the signature.
        const isBaseValid = this.validate();

        // Validate the document details.
        let isTemplateValid = false;
        switch (this.documentType) {
            case 'terms-and-conditions':
                isTemplateValid = this.termsAndConditions.validate();
                break;
            case 'processing-agreement':
                isTemplateValid = this.processingAgreement.validate();
                break;
            case 'eula':
                isTemplateValid = this.eula.validate();
                break;
            default:
                throw new Error('Invalid document type');
        }

        // If the base or template is invalid, return.
        if (!isBaseValid || !isTemplateValid) {
            this.validation.valid = false;
            return;
        }

        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('translation:partial-views.sign-document.questions.sign.title'),
                message: this.t.tr('translation:partial-views.sign-document.questions.sign.message'),
                type: 'warning',
                btnOk: this.t.tr('translation:global.buttons.sign'),
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        // Disable the signature pad.
                        this.signaturePad.off();
                        this.isLoading = true;

                        try {
                            const signature = this.signaturePad.toDataURL();
                            switch (this.documentType) {
                                case 'terms-and-conditions':
                                    await this.termsAndConditions.signAndSave(signature);
                                    break;
                                case 'processing-agreement':
                                    await this.processingAgreement.signAndSave(signature);
                                    break;
                                case 'eula':
                                    await this.eula.signAndSave(signature);
                                    break;
                                default:
                                    throw new Error('Invalid document type');
                            }
                            setTimeout(() => this.remove({ result: PartialViewResults.Ok, updateUrl: false }), 250);
                        } catch (e) {
                            this.isLoading = false;
                            this.signaturePad.on();
                            this.errorHandler.handle('sign-document', e);
                        }
                    }
                }
            })
        );
    }

    public setViewTo(view: 'document' | 'sign'): void {
        if (view === 'sign') {
            setTimeout(() => {
                this.signatureCanvas.height = this.signatureCanvas.offsetHeight;
                this.signatureCanvas.width = this.signatureCanvas.offsetWidth;
                this.signaturePad = new SignaturePad(this.signatureCanvas);
                this.signaturePad.addEventListener('beginStroke', () => (this.signatureAdded = true));
            }, 250);
        }

        this.view = view;
    }

    public clearSignature(): void {
        this.signaturePad.clear();
        this.signatureAdded = false;
    }

    private validate(): boolean {
        this.validation.signature = isNotEmpty(this.signaturePad) && !this.signaturePad.isEmpty();
        return validateState(this.validation);
    }
}
