import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { ZorgmailApiClient } from '@wecore/sdk-integrations';
import { GetWorkspaceResponse, WorkspacesApiClient as ManagementWorkspacesApiClient, Zorgmail, ZorgmailSettings } from '@wecore/sdk-management';
import { isNotDefined } from '@wecore/sdk-utilities';
import { IEventAggregator, inject } from 'aurelia';
import { PartialViewResults } from '../../enums/partial-view-results';
import { BasePartialView } from '../../infra/base-partial-view';
import { CacheService } from '../../infra/cache-service';
import { ErrorHandler } from '../../infra/error-handler';
import { State } from '../../infra/store/state';
import { ConfirmationOptions } from '../../models/confirmation-options';
import { PartialView } from '../../models/partial-view';
import { ModalService } from '../../services/service.modals';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, ManagementWorkspacesApiClient, ZorgmailApiClient, ModalService)
export class PartialIntegrationsZorgmail extends BasePartialView {
    public workspace: GetWorkspaceResponse;
    public isSyncing: boolean = false;
    public roles: string[] = [
        'Ergotherapeut',
        'Fysiotherapeut',
        'Apotheker',
        'Logopedist',
        'Huisarts',
        'Psycholoog',
        'Overig',
        'Optometrist',
        'Tandarts',
        'Mondhygienist',
        'Verloskundige',
        'Onbekend',
        'Medewerker',
        'Wijkverpleegkundige',
        'Podotherapeut',
        'Dietist',
        'Orthodontist',
        'Arts',
        'Praktijkondersteuner (POH)',
        'Oefentherapeut',
        'Assistent',
        'Osteopaat',
        'Psychiater',
        'Podoloog',
        'Directeur',
        'Paramedicus',
        'Maatschappelijk Dienstverlener',
        'Echoscopist',
        'HAIO (Huisarts in opleiding)',
        'Chiropractor',
        'Pedicure',
        'Huid- en oedeemtherapeut',
        'Oedeemtherapeut',
        'Kinderarts',
        'Tandtechnicus',
        'Verpleegkundige',
        'Vaktherapeut',
        'Dermatoloog',
        'Verpleegkundig Specialist GGZ',
        'Manueel therapeut',
        'Orthopeed',
        'Adviseur',
        'Bedrijfsarts',
        'Manager',
        'Ouderenconsulent',
        'Huidtherapeut',
        'Psychotherapeut',
        'Apotheekhoudend Huisarts',
        'Sociaal Werker',
        'Gestalttherapeut',
        'Leefstijlcoach',
        'Orthopedagoog',
        'Tandprotheticus',
        'Relatietherapeut',
        'Haptotherapeut',
        'Specialist',
        'Specialist Ouderengeneeskunde',
        'Test',
        'ICT Supportmedewerker',
        'Oogarts',
        'Ziekenhuisapotheker',
        'SCEN-arts',
        'Acupuncturist',
        'Huisartsenpraktijk',
        'Apotheek',
        'Fysiotherapiepraktijk',
        'Echocentrum',
        'Dermatologiepraktijk',
        'Tandartsenpraktijk',
        'Zorggroep',
        'Woonzorg',
        'Zelfstandig Behandel Centrum',
        'Medische Dienst',
        'Opticien',
        'Psychologenpraktijk',
        'Kliniek',
        'Laboratorium',
        'Tandtechnici/tandprothetici',
        'Logopediepraktijk',
        'Praktijk voor Psychiatrie',
        'Praktijkondersteuning Huisartsenzorg (POH)',
        'Oefentherapiepraktijk',
        'Verloskundigenpraktijk',
        'Mondhygienepraktijk',
        'Tandheelkundig centrum',
        'Ergotherapiepraktijk',
        'Ziekenhuis',
        'Gemeente',
        'Polikliniek',
        'Dietistenpraktijk',
        'GGZ',
        'Podotherapiepraktijk',
        'Maatschappelijke Dienstverlening',
        'Zorgcentrum',
        'Chiropractiepraktijk',
        'Thuiszorg',
        'Gezondheidscentrum',
        'Huisartsenpost',
        'GGD',
        'Orthodontiepraktijk',
        'Ambulancedienst',
        'Arbodienst',
        'Orthopediepraktijk',
        'Revalidatiecentrum',
        'JGZ',
        'WLZ-gecombineerd',
        'Kraamzorginstelling',
        'Wijkteam',
        'Diagnostisch Centrum',
        'Psychotherapiepraktijk',
        'Huid- en oedeemtherapiepraktijk',
        'Podologiepraktijk',
        'Verpleeginrichting',
        'Pedicurepraktijk',
        'Hospice',
        'ICT Leverancier',
        'Leverancier hulpmiddelen',
        'Polikliniek MKA',
        'Verzorgingstehuis',
        'Plastische Chirurgie',
        'Jeugdzorg',
        'VVT',
        'Praktijk voor Manuele Therapie',
        'Osteopathie',
        'Sportgeneeskunde',
        'Safe Relay Server',
        'Osteopathiepraktijk',
        'Testpraktijk',
        'EDI Server',
        'Adresboek',
        'Kinderopvang',
        'Medisch Centrum',
        'Gezinsvervangend tehuis',
        'Instelling voor verstandelijk gehandicapten',
        'Gestalttherapiepraktijk',
        'RIAGG',
        'Haptotherapiepraktijk',
        'Ouderenzorg',
        'Adviesbureau',
        'Paramedisch Centrum',
        'Cardiologie',
        'Webformulier',
        'Huidcentrum',
        'Oogkliniek',
        'ICT Service Provider',
        'Zorgboerderij',
        'Slaapinstituut',
        'Bureau voor arbeidsrehabilitatie',
        'SCEN Praktijk',
        'Forensisch Psychiatrisch Centrum',
        'Audiologisch Centrum',
        'Praktijk voor Seksuologie',
        'Gehandicaptenzorg',
        'Bestralingsinstituut',
        'Praktijk voor Acupunctuur',
        'Kinderdagverblijf',
        'Radiotherapeutisch Centrum',
        'Rugpoli',
        'Beschermd wonen',
        'Centrum voor vruchtbaarheid',
        'Verslavingszorg',
        'Bloedbank',
        'Pharmaceutische groothandel',
        'Jeugdtandzorg',
        'Vaktherapie',
        'Farmaceutische groothandel',
        'Systeem',
        'Trombosedienst',
        'Centrum voor Cardiologie',
        'Ziekenhuisapotheek'
    ];

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly workspacesApi: ManagementWorkspacesApiClient,
        private readonly zorgmail: ZorgmailApiClient,
        private readonly modalService: ModalService
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                this.workspace = await this.workspacesApi.getById(this.authenticated.workspace.id);

                if (isNotDefined(this.workspace.integrations.zorgmail))
                    this.workspace.integrations.zorgmail = new Zorgmail({
                        rolesToSyncAsAccounts: [],
                        rolesToSyncAsContacts: []
                    });
                if (isNotDefined(this.workspace.integrations.zorgmail.settings)) this.workspace.integrations.zorgmail.settings = new ZorgmailSettings();

                this.baseLoaded = true;
            })
            .catch((x) => this.errorHandler.handle('PartialIntegrationsZorgmail.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }

    public async cancel(): Promise<void> {
        await super.remove({
            result: PartialViewResults.Cancelled,
            updateUrl: true
        });
    }

    public async save(): Promise<void> {
        this.isLoading = true;
        setTimeout(async () => {
            try {
                await this.workspacesApi.update(this.workspace.id, this.workspace);
                // Force cache update.
                await this.cache.getAuthenticatedWorkspace(true);
                this.notifications.show(
                    this.t.tr('translation:partial-views.integrations-zorgmail.notifications.save-successful.title'),
                    this.t.tr('translation:partial-views.integrations-zorgmail.notifications.save-successful.message'),
                    { type: 'success', duration: 3000 }
                );
            } catch (e) {
                this.errorHandler.handle('[integrations/zorgmail', e);
            }
            this.isLoading = false;
        }, 250);
    }

    public toSet(): { value: string; text: string; data?: any }[] {
        return this.roles.map((x: string) => ({ value: x, text: x }));
    }

    public handleRoleSelected = (option: { value: string; text: string }, data: 'account' | 'contact') => {
        if (data === 'account') this.workspace.integrations.zorgmail.rolesToSyncAsAccounts.push(option.value);
        else this.workspace.integrations.zorgmail.rolesToSyncAsContacts.push(option.value);
    };

    public handleRoleRemoved = (_: { value: string; text: string }, index: number, data: 'account' | 'contact') => {
        if (data === 'account') this.workspace.integrations.zorgmail.rolesToSyncAsAccounts.splice(index, 1);
        else this.workspace.integrations.zorgmail.rolesToSyncAsContacts.splice(index, 1);
    };

    public async syncAddressBook(): Promise<void> {
        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('translation:partial-views.integrations-zorgmail.questions.sync-address-book.title'),
                message: this.t.tr('translation:partial-views.integrations-zorgmail.questions.sync-address-book.message'),
                type: 'warning',
                btnOk: this.t.tr('translation:global.buttons.sync'),
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        try {
                            this.isSyncing = true;
                            setTimeout(async () => {
                                await this.zorgmail.syncAddressBook(this.workspace.id);
                                this.notifications.show(
                                    this.t.tr('translation:partial-views.integrations-zorgmail.notifications.sync-started.title'),
                                    this.t.tr('translation:partial-views.integrations-zorgmail.notifications.sync-started.message'),
                                    { type: 'success', duration: 3000 }
                                );
                                this.isSyncing = false;
                            }, 500);
                        } catch (e) {
                            this.errorHandler.handle('[sync-address-book]', e);
                        }
                    }
                }
            })
        );
    }
}
