import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { UserRoles } from '@wecore/sdk-core';
import { isNotEmpty } from '@wecore/sdk-utilities';
import { IEventAggregator, inject } from 'aurelia';
import { PartialViewResults } from '../../enums/partial-view-results';
import { BasePartialView } from '../../infra/base-partial-view';
import { CacheService } from '../../infra/cache-service';
import { ErrorHandler } from '../../infra/error-handler';
import { PartialViews } from '../../infra/partial-views';
import { State } from '../../infra/store/state';
import { PartialView } from '../../models/partial-view';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N)
export class PartialPracticeTemplates extends BasePartialView {
    public active: PartialView;

    public templates: {
        title: string;
        description: string;
        view: PartialView;
        roles: UserRoles[];
    }[];

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N
    ) {
        super(cache, errorHandler, events, store, t);
        this.templates = [
            {
                title: this.t.tr('translation:partial-views.practice-templates.examination-phase.title'),
                description: this.t.tr('translation:partial-views.practice-templates.examination-phase.description'),
                view: PartialViews.MedicalExaminationPhases,
                roles: [UserRoles.Owner, UserRoles.Admin, UserRoles.WriteMedicalExaminationPhases]
            },
            {
                title: this.t.tr('translation:partial-views.practice-templates.differential-diagnosis.title'),
                description: this.t.tr('translation:partial-views.practice-templates.differential-diagnosis.description'),
                view: PartialViews.DifferentalDiagnoses,
                roles: [UserRoles.Owner, UserRoles.Admin, UserRoles.WriteDifferentialDiagnoses]
            },
            {
                title: this.t.tr('translation:partial-views.practice-templates.medical-questionnaire.title'),
                description: this.t.tr('translation:partial-views.practice-templates.medical-questionnaire.description'),
                view: PartialViews.MedicalQuestionnaires,
                roles: [UserRoles.Owner, UserRoles.Admin, UserRoles.WriteMedicalQuestionnaires]
            },
            {
                title: this.t.tr('translation:partial-views.practice-templates.medical-question.title'),
                description: this.t.tr('translation:partial-views.practice-templates.medical-question.description'),
                view: PartialViews.MedicalQuestions,
                roles: [UserRoles.Owner, UserRoles.Admin, UserRoles.WriteMedicalQuestions]
            },
            {
                title: this.t.tr('translation:partial-views.practice-templates.examination-action.title'),
                description: this.t.tr('translation:partial-views.practice-templates.examination-action.description'),
                view: PartialViews.MedicalExaminationActions,
                roles: [UserRoles.Owner, UserRoles.Admin, UserRoles.WriteMedicalExaminationActions]
            },
            // {
            //     title: this.t.tr('translation:partial-views.practice-templates.examination-model.title'),
            //     description: this.t.tr('translation:partial-views.practice-templates.examination-model.description'),
            //     view: PartialViews.MedicalExaminationModels,
            //     roles: [UserRoles.Owner, UserRoles.Admin, UserRoles.WriteMedicalExaminationModels]
            // },
            {
                title: this.t.tr('translation:partial-views.practice-templates.medical-therapy.title'),
                description: this.t.tr('translation:partial-views.practice-templates.medical-therapy.description'),
                view: PartialViews.MedicalTherapies,
                roles: [UserRoles.Owner, UserRoles.Admin, UserRoles.WriteMedicalTherapies]
            },
            {
                title: this.t.tr('translation:partial-views.practice-templates.medical-treatment-protocol.title'),
                description: this.t.tr('translation:partial-views.practice-templates.medical-treatment-protocol.description'),
                view: PartialViews.MedicalTreatmentProtocols,
                roles: [UserRoles.Owner, UserRoles.Admin, UserRoles.WriteMedicalTreatmentProtocols]
            },
            {
                title: this.t.tr('translation:partial-views.practice-templates.examination.title'),
                description: this.t.tr('translation:partial-views.practice-templates.examination.description'),
                view: PartialViews.MedicalExaminations,
                roles: [UserRoles.Owner, UserRoles.Admin, UserRoles.WriteMedicalExaminations]
            },
            {
                title: this.t.tr('translation:partial-views.practice-templates.anatomical-region.title'),
                description: this.t.tr('translation:partial-views.practice-templates.anatomical-region.description'),
                view: PartialViews.AnatomicalRegions,
                roles: [UserRoles.Owner, UserRoles.Admin, UserRoles.WriteAnatomicalRegions]
            },
            {
                title: this.t.tr('translation:partial-views.practice-templates.differential-diagnosis-category.title'),
                description: this.t.tr('translation:partial-views.practice-templates.differential-diagnosis-category.description'),
                view: PartialViews.DifferentalDiagnosisCategories,
                roles: [UserRoles.Owner, UserRoles.Admin, UserRoles.WriteDifferentialDiagnoses]
            },
            {
                title: this.t.tr('translation:partial-views.practice-templates.examination-action-category.title'),
                description: this.t.tr('translation:partial-views.practice-templates.examination-action-category.description'),
                view: PartialViews.MedicalExaminationActionCategories,
                roles: [UserRoles.Owner, UserRoles.Admin, UserRoles.WriteMedicalExaminationActions]
            },
            {
                title: this.t.tr('translation:partial-views.practice-templates.medical-question-category.title'),
                description: this.t.tr('translation:partial-views.practice-templates.medical-question.description'),
                view: PartialViews.MedicalQuestionCategories,
                roles: [UserRoles.Owner, UserRoles.Admin, UserRoles.WriteMedicalQuestions]
            }
        ];
    }

    public activate(view: PartialView): void {
        super.setView({ view });
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                // Wait for the PAGE route to be changed. Otherwise, the
                // correct partial view will not be loaded.
                setTimeout(() => {
                    this.loadViewsFromUrl({
                        open: async (view: string) => {
                            const setAllowedView = async () => {
                                // Find the first view we have access to.
                                let partial: PartialView;
                                outerLoop: for (const template of this.templates) {
                                    for (const role of template.roles) {
                                        if (this.hasRole(role)) {
                                            partial = template.view;
                                            break outerLoop;
                                        }
                                    }
                                }

                                await this.changeToView(partial, true);
                            };
                            if (isNotEmpty(view)) {
                                const partial = PartialViews[view];
                                const template = this.templates.find((x) => x.view.name === partial.name);
                                if (template && template.roles.some((x) => this.hasRole(x))) {
                                    await this.changeToView(partial, false);
                                } else setAllowedView();
                            } else {
                                // The default view is the MedicalExaminationPhases view.
                                // First check if the user has access to this view.
                                // If not, find the first view we have access to.
                                const partial = PartialViews.MedicalExaminationPhases;
                                const setting = this.templates.find((x) => x.view.name === partial.name);
                                if (setting && setting.roles.some((x) => this.hasRole(x))) {
                                    await this.changeToView(partial, false);
                                } else setAllowedView();
                            }
                        }
                    });
                });
                this.baseLoaded = true;
            })
            .catch((x) => this.errorHandler.handle('PartialPracticeTemplates.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }

    public async changeToView(view: PartialView, updateUrl: boolean = true): Promise<void> {
        this.active = view;
        await this.changeTo(view, updateUrl);
    }
}
