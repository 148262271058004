import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { UserRoles } from '@wecore/sdk-core';
import {
    GetHealthcareTaskListResponse,
    GetHealthcareTaskResponse,
    HealthcareTaskLabelTypes,
    HealthcareTaskListsApiClient,
    HealthcareTasksApiClient,
    UpdateHealthcareTaskListPositionRequest
} from '@wecore/sdk-healthcare';
import { isDefined, isNotDefined, savePageState } from '@wecore/sdk-utilities';
import { IEventAggregator, inject } from 'aurelia';
import { PartialViewResults } from '../../enums/partial-view-results';
import { BasePartialView } from '../../infra/base-partial-view';
import { CacheService } from '../../infra/cache-service';
import { ErrorHandler } from '../../infra/error-handler';
import { CustomEvents } from '../../infra/events';
import { PartialViews } from '../../infra/partial-views';
import { State } from '../../infra/store/state';
import { cloneDeep } from '../../infra/utilities';
import { ConfirmationOptions } from '../../models/confirmation-options';
import { PartialView } from '../../models/partial-view';
import { ViewOptions } from '../../models/view-options';
import { ModalService } from '../../services/service.modals';
import { TemplateTaskList } from './template-task-list/template-task-list';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, HealthcareTaskListsApiClient, HealthcareTasksApiClient, ModalService)
export class PartialTasks extends BasePartialView {
    public lists: GetHealthcareTaskListResponse[];
    public HealthcareTaskLabelTypes: typeof HealthcareTaskLabelTypes = HealthcareTaskLabelTypes;
    public selected: GetHealthcareTaskResponse[] = [];
    public container: HTMLDivElement;
    public tasksState: { isDraggingTask: boolean; isDraggingList: boolean } = { isDraggingTask: false, isDraggingList: false };
    public references: TemplateTaskList[] = [];

    private placeholder: HTMLDivElement;
    private displayOrderIncrement: number = 100;

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly listsApi: HealthcareTaskListsApiClient, //
        private readonly tasksApi: HealthcareTasksApiClient,
        private readonly modalService: ModalService
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
    }

    public attached(): void {
        if (isNotDefined(this.pageState.values.lists)) this.pageState.values.lists = {};

        const placeholder = document.createElement('div');
        placeholder.id = 'placeholder-list';
        placeholder.classList.add('col-span-12', 'border', 'rounded-lg', 'border-dashed', 'border-gray-300', 'h-[38px]');
        this.placeholder = placeholder;

        this.subscriptions = [
            ...(this.subscriptions ?? []),
            this.events.subscribe(
                CustomEvents.TasksListsMoved,
                async (data: {
                    list: GetHealthcareTaskListResponse; //
                    displayOrder: number;
                    el: HTMLDivElement;
                }) => {
                    const list = data.list;
                    // Update the display order of the list.
                    list.displayOrder = data.displayOrder;

                    this.lists = [
                        ...(this.lists.length > 0 ? [this.lists.shift()] : []), //
                        ...cloneDeep(this.lists)
                    ];

                    data.el.remove();

                    this.listsApi.updatePosition(
                        list.id,
                        this.authenticated.workspace.id,
                        new UpdateHealthcareTaskListPositionRequest({
                            displayOrder: list.displayOrder
                        })
                    );
                }
            )
        ];

        super
            .initView()
            .then(async () => {
                if (this.hasRole(UserRoles.ReadHealthcareTaskLists)) await this.loadLists();
                this.loadViewsFromUrl({
                    open: async (view: string, entityId: string) => {
                        if (isNotDefined(view)) return;

                        if (view.includes('EditTask')) this.handleEditTask(new GetHealthcareTaskResponse({ id: entityId }));
                        if (view.includes('CreateTaskList')) this.handleCreateList();
                        if (view.includes('EditTaskList')) this.handleEditList(new GetHealthcareTaskListResponse({ id: entityId }));
                        if (view.includes('HealthcareTaskPriorityLabels')) this.handleManageLabels(HealthcareTaskLabelTypes.Priority);
                        if (view.includes('HealthcareTaskStatusesLabels')) this.handleManageLabels(HealthcareTaskLabelTypes.Status);
                        if (view.includes('HealthcareTaskLabels')) this.handleManageLabels(HealthcareTaskLabelTypes.Label);
                    }
                });
                this.baseLoaded = true;
            })
            .catch((x) => this.errorHandler.handle('PartialTasks.attached', x));
    }

    public async handleCreateList(): Promise<void> {
        await this.removeChildViews();

        const last = [...this.lists].orderByDescending((x: GetHealthcareTaskListResponse) => x.displayOrder)[0];
        await this.addPartialView({
            view: this.partial.base, //
            partial: PartialViews.CreateTaskList.with({ displayOrder: (last?.displayOrder ?? 0) + this.displayOrderIncrement }).whenClosed(async (result: PartialViewResults, data: any) => {
                if (result === PartialViewResults.Ok) await this.loadLists();
            }),
            options: new ViewOptions({
                scrollToView: true, //
                markItem: false,
                replace: true,
                updateUrl: true
            })
        });
    }

    public handleEditList = async (list: GetHealthcareTaskListResponse): Promise<void> => {
        await this.removeChildViews();
        await this.addPartialView({
            view: this.partial.base, //
            partial: PartialViews.EditTaskList.with({ id: list.id }).whenClosed(async (result: PartialViewResults, data: any) => {
                if (result === PartialViewResults.Ok || result === PartialViewResults.Deleted) await this.loadLists();
            }),
            options: new ViewOptions({
                scrollToView: true, //
                markItem: false,
                replace: true,
                updateUrl: true
            })
        });
    };

    public handleNewPersonalTask = async (_: GetHealthcareTaskListResponse, __: boolean = false, displayOrder: number = 0): Promise<GetHealthcareTaskResponse> => {
        return this.handleNewTask(null, true, displayOrder);
    };

    public handleNewTask = async (list: GetHealthcareTaskListResponse, isPersonalTask: boolean = false, displayOrder: number = 0): Promise<GetHealthcareTaskResponse> => {
        await this.removeChildViews();
        return new Promise(async (resolve) => {
            await this.addPartialView({
                view: this.partial.base, //
                partial: PartialViews.CreateTask.with({
                    listId: list?.id, //
                    isPersonalTask,
                    displayOrder
                }).whenClosed(async (result: PartialViewResults, data: { task: GetHealthcareTaskResponse }) => {
                    if (result === PartialViewResults.Ok) resolve(data.task);
                    else resolve(null);
                }),
                options: new ViewOptions({
                    scrollToView: true, //
                    markItem: false,
                    replace: true,
                    updateUrl: true
                })
            });
        });
    };

    public handleEditTask = async (task: GetHealthcareTaskResponse): Promise<boolean> => {
        await this.removeChildViews();
        return new Promise(async (resolve) => {
            await this.addPartialView({
                view: this.partial.base, //
                partial: PartialViews.EditTask.with({ id: task.id }).whenClosed(async (result: PartialViewResults) => {
                    if (result === PartialViewResults.Ok || result === PartialViewResults.Deleted) {
                        resolve(true);
                    } else resolve(false);
                }),
                options: new ViewOptions({
                    scrollToView: true, //
                    markItem: false,
                    replace: true,
                    updateUrl: true
                })
            });
        });
    };

    public openUserPosts = async (task: GetHealthcareTaskResponse): Promise<boolean> => {
        await this.removeChildViews();
        return new Promise(async (resolve) => {
            await this.addPartialView({
                view: this.partial.base, //
                partial: PartialViews.UserPosts.with({
                    entityId: task.id, //
                    entityType: 'HealthcareTask',
                    references: isDefined(task.list) ? [task.list] : null
                }).whenClosed(async (result: PartialViewResults) => {
                    if (result === PartialViewResults.Ok || result === PartialViewResults.Deleted) {
                        resolve(true);
                    } else resolve(false);
                }),
                options: new ViewOptions({
                    scrollToView: true, //
                    markItem: false,
                    replace: true,
                    updateUrl: true
                })
            });
        });
    };

    public handleExpandedChange = (list: GetHealthcareTaskListResponse, expanded: boolean): void => {
        if (isDefined(this.pageState.values.lists[list.id])) this.pageState.values.lists[list.id].expanded = expanded;
        else this.pageState.values.lists[list.id] = { expanded };

        savePageState(this.pageState);
    };

    public async handleManageLabels(type: HealthcareTaskLabelTypes): Promise<void> {
        await this.removeChildViews();
        let partial: PartialView;

        switch (type) {
            case HealthcareTaskLabelTypes.Priority:
                partial = PartialViews.HealthcareTaskPriorityLabels.with({ type }).whenClosed(async (result: PartialViewResults, data: any) => {
                    if (result === PartialViewResults.Ok || result === PartialViewResults.Deleted) await this.loadLists();
                });
                break;
            case HealthcareTaskLabelTypes.Status:
                partial = PartialViews.HealthcareTaskStatusesLabels.with({ type }).whenClosed(async (result: PartialViewResults, data: any) => {
                    if (result === PartialViewResults.Ok || result === PartialViewResults.Deleted) await this.loadLists();
                });
                break;
            case HealthcareTaskLabelTypes.Label:
                partial = PartialViews.HealthcareTaskLabels.with({ type }).whenClosed(async (result: PartialViewResults, data: any) => {
                    if (result === PartialViewResults.Ok || result === PartialViewResults.Deleted) await this.loadLists();
                });
                break;
        }

        await this.addPartialView({
            view: this.partial.base, //
            partial,
            options: new ViewOptions({
                scrollToView: true, //
                markItem: false,
                replace: true,
                updateUrl: true
            })
        });
    }

    public async archive(): Promise<void> {
        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('translation:partial-views.tasks.questions.archive-collection.title'),
                message: this.t.tr('translation:partial-views.tasks.questions.archive-collection.message'),
                type: 'warning',
                btnOk: this.t.tr('translation:global.buttons.archive'),
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        try {
                            const taskIds = this.selected.map((x) => x.id);
                            await this.tasksApi.archive(this.authenticated.workspace.id, taskIds);
                            this.selected = [];
                            this.notifications.show(
                                this.t.tr('translation:partial-views.tasks.notifications.archived-collection-successfully.title'),
                                this.t.tr('translation:partial-views.tasks.notifications.archived-collection-successfully.message'),
                                { type: 'success', duration: 3000 }
                            );
                            this.events.publish(CustomEvents.TasksListsArchived);
                        } catch (e) {
                            await this.errorHandler.handle('[archive-task-collection]', e);
                        }
                    }
                }
            })
        );
    }

    public async delete(): Promise<void> {
        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('translation:partial-views.tasks.questions.delete-collection.title'),
                message: this.t.tr('translation:partial-views.tasks.questions.delete-collection.message'),

                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        try {
                            const taskIds = this.selected.map((x) => x.id);
                            await this.tasksApi.deleteCollection(this.authenticated.workspace.id, taskIds);
                            this.selected = [];
                            this.notifications.show(
                                this.t.tr('translation:partial-views.tasks.notifications.deleted-collection-successfully.title'),
                                this.t.tr('translation:partial-views.tasks.notifications.deleted-collection-successfully.message'),
                                { type: 'success', duration: 3000 }
                            );
                            this.events.publish(CustomEvents.TasksListsDeleted);
                        } catch (e) {
                            await this.errorHandler.handle('[delete-task-collection]', e);
                        }
                    }
                }
            })
        );
    }

    public handleListDragStart = async (e: MouseEvent, list: GetHealthcareTaskListResponse, el: HTMLDivElement): Promise<void> => {
        // Prevent other events from firing. E.g. the drag and select events.
        e.preventDefault();
        // Clone the task element.
        const clone = el.cloneNode(true) as HTMLDivElement;
        clone.id = `clone-${list.id}`;
        clone.style.position = 'absolute';
        clone.style.zIndex = '10000';
        clone.style.opacity = '90%';
        clone.style.width = `${el.clientWidth}px`;

        // Set the left position relative to the container left position and mouse position.
        clone.style.top = `${e.clientY - 40}px`;
        clone.style.left = `${e.clientX}px`;
        // Add the clone to the active container.
        document.body.appendChild(clone);
    };

    public handleListDrag = async (e: MouseEvent, list: GetHealthcareTaskListResponse, el: HTMLDivElement): Promise<void> => {
        // Find the clone.
        const clone = document.querySelector(`#clone-${list.id}`) as HTMLDivElement;
        if (isNotDefined(clone)) return;
        // Set the left position relative to the container left position and mouse position.
        clone.style.top = `${e.clientY - 45}px`;
        clone.style.left = `${e.clientX + 16}px`;

        // Find the element after which the clone should be placed.
        const afterElement = this.getDragAfterElement(this.container, e.clientY);
        // Add the placeholder while hovering to indicate where the clone will be placed.
        if (isNotDefined(afterElement)) this.container.appendChild(this.placeholder);
        else this.container.insertBefore(this.placeholder, afterElement);
        // Hide the original element after adding the placeholder.
        el.classList.add('hidden');
    };

    public handleListDragEnd = async (_: MouseEvent, list: GetHealthcareTaskListResponse, el: HTMLDivElement): Promise<void> => {
        // Remove the clone.
        document.querySelector(`#clone-${list.id}`)?.remove();
        // Use the placeholder to find the correct list.
        const placeholder = document.querySelector(`#placeholder-list`);
        // Get the elements after the placeholder and before the placeholder.
        const previousElement = placeholder.previousElementSibling as HTMLDivElement;
        const nextElement = placeholder.nextElementSibling as HTMLDivElement;
        // Get the list where the task is moved to.
        const previous = Number(previousElement?.getAttribute('data-order') ?? 0);
        const next = Number(nextElement?.getAttribute('data-order') ?? 0);

        let displayOrder = 0;
        if (next > 0) displayOrder = (previous + next) / 2;
        else displayOrder = Number(previous) + this.displayOrderIncrement;

        this.events.publish(CustomEvents.TasksListsMoved, { list, displayOrder, el });
        // We don't need the placeholder anymore.
        placeholder.remove();
    };

    public handleListDragCancelled = async (e: KeyboardEvent, list: GetHealthcareTaskListResponse): Promise<void> => {
        if (e.key === 'Escape') {
            this.tasksState.isDraggingList = false;
            document.querySelector(`#clone-${list.id}`)?.remove();
            document.querySelector(`#placeholder-list`)?.remove();
        }
    };

    private getDragAfterElement(container: any, y: number): HTMLElement {
        const draggableElements = [...container.querySelectorAll(`.draggable-list:not(.dragging)`)];
        return draggableElements.reduce(
            (closest, child) => {
                const box = child.getBoundingClientRect();
                const offset = y - box.top - box.height / 2;
                if (offset < 0 && offset > closest.offset) return { offset, element: child };
                else return closest;
            },
            { offset: Number.NEGATIVE_INFINITY }
        ).element;
    }

    private async loadLists(): Promise<void> {
        const response = await this.listsApi.search(this.authenticated.workspace.id, '', 1000, 0);
        this.lists = response.data;
    }
}
