import { SchedulerSettings } from '@wecore/sdk-management';
import { IPageState } from '@wecore/sdk-utilities';
import { bindable, containerless } from 'aurelia';
import { getSchedulerColumnHeight } from '../../infra/utilities';
import { SchedulerPeriod } from '../../models/scheduler-period';

@containerless
export class BxSchedulerTimeline {
    @bindable() public settings: SchedulerSettings;
    @bindable() public periods: SchedulerPeriod[];
    @bindable() public pageState: IPageState;

    public column: HTMLDivElement;
    private hoverCb: (e: MouseEvent) => void;

    public bound(): void {
        this.column.style.height = `${getSchedulerColumnHeight(this.settings, this.periods, this.settings.periodHeight)}px`;
    }

    public attached(): void {
        // Because with quick mouse movements, the mouseleave event is not triggered
        // e.g. the hover element is not removed.
        this.hoverCb = () => {
            document.getElementById('hover-scheduler-item')?.remove();
        };
        this.column.addEventListener('mousemove', this.hoverCb);
    }

    public detaching(): void {
        this.column.removeEventListener('mousemove', this.hoverCb);
    }
}
