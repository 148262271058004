import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { AttachmentEntities, MediaLibraryApiClient } from '@wecore/sdk-attachments';
import { Address, Email, GetAccountResponse, GetContactResponse, Link, Phone } from '@wecore/sdk-crm';
import {
    AffixPositions,
    BlobStorageAttachment,
    ColumnBreakpoint,
    DayOfWeek,
    EmailEntityTypes,
    EmailTemplateTokenPropertyTypes,
    FileType,
    GetEmailTemplateTokenResponse,
    GetInsurerResponse,
    GetMedicalQuestionResponse,
    GetMedicalRecordRegistrationResponse,
    GetMedicalRecordResponse,
    GetPatientResponse,
    InputRules,
    InputTypes,
    MedicalExaminationActionFlow,
    MedicalExaminationActionItem,
    MedicalExaminationActionItemTypes,
    MedicalExaminationActionStep,
    MedicalExaminationActionStepCategory,
    MedicalExaminationFlow,
    MedicalExaminationStepVisibilityRequirementTypes,
    MedicalExaminationTemplateItemStep,
    MedicalExaminationTemplateItemStepTypes,
    MedicalQuestion,
    MedicalQuestionRegistration,
    MedicalQuestionnaireFlow,
    MedicalResult,
    MedicalTherapyEvaluationFlow,
    MedicalTherapyEvaluationItem,
    MedicalTherapyEvaluationItemTypes,
    MedicalTherapyExecutionFlow,
    MedicalTherapyExecutionItem,
    MedicalTherapyExecutionItemStep,
    MedicalTherapyExecutionItemStepCategory,
    MedicalTherapyExecutionItemTypes,
    MedicalTherapyPlanFlow,
    MedicalTherapyPlanItem,
    MedicalTherapyPlanItemStep,
    MedicalTherapyPlanItemStepCategory,
    MedicalTherapyPlanItemTypes,
    ResultMatcher,
    ResultMatcherTypes,
    ResultTypes
} from '@wecore/sdk-healthcare';
import { SchedulerSettings, SchedulerSettingsHour } from '@wecore/sdk-management';
import { StorageKeys, get, getFromStorage, guid, isDefined, isEmpty, isFunction, isNotDefined, isNotEmpty } from '@wecore/sdk-utilities';
import Aurelia, { Registration } from 'aurelia';
import { AureliaConfiguration } from 'aurelia-configuration';
import { endOfDay, format, intervalToDuration, setDay, setHours, setMinutes } from 'date-fns';
import { enUS, nl } from 'date-fns/locale';
import clone from 'lodash.clonedeep';
import { CopyActionItem } from '../models/copy-action-item';
import { CopyTherapyExecutionItem } from '../models/copy-therapy-execution-item';
import { CopyTherapyPlanItem } from '../models/copy-therapy-plan-item';
import { FlattenedExaminationStep } from '../models/flattened-examination-step';
import { PartialView } from '../models/partial-view';
import { SchedulerPeriod } from '../models/scheduler-period';
import { SelectedFile } from '../models/selected-file';
import { View } from '../models/view';
import { AuthenticationService } from '../services/service.authentication';
import { PartialViews } from './partial-views';
import { clearItem } from './store/actions/copy-paste';
import { State } from './store/state';

/**
 * Cleans translatable objects from a object when no translations are present.
 * @param properties The names of the properties holding the translatable objects to clean.
 * @param translationsParent The object holding the translatable objects.
 * @param levels The amount of levels to go to get to the translations object when property is nested.
 */
export function cleanTranslatables(properties: string[], translationsParent: any, levels: number): any {
    const cleanup = (prop: string, parent: any): void => {
        // Make sure we have a translation parent object.
        if (isNotDefined(parent)) return;
        // Get the translation object.
        const translations = parent[prop];
        // Because before every save() the translation property gets checked
        // and possibly set to null by this function and we need to check for it.
        // Use-case: save() is being called, the translations is set to null,
        // the save() throws error. User changes something and this clean()
        // tries to check the translations we've set to null.
        if (isNotDefined(translations)) return;
        // Check if the translatable object has any translations.
        const hasNoTranslations = Object.keys(translations).every(
            (languageKey) => isNotDefined(translations[languageKey]) || isEmpty(translations[languageKey]) //
        );
        // Reset the translation object when no translations are present.
        if (hasNoTranslations) parent[prop] = null;
        // Cleanup null translations.
        else for (const key in translations) if (isNotDefined(translations[key]) || isEmpty(translations[key])) delete parent[prop][key];
    };

    for (const prop of properties) {
        // When we have nested properties to check.
        if (prop.includes(':')) {
            let array: string, obj: string, property: string;
            switch (levels) {
                case 1:
                    // We don't have level one properties when we
                    // need to check nested properties.
                    break;
                case 2:
                    [array, property] = prop.split(':');
                    for (const item of translationsParent[array]) {
                        cleanup(property, item);
                    }
                    break;
                case 3:
                    [array, obj, property] = prop.split(':');
                    for (const item of translationsParent[array]) {
                        cleanup(property, item[obj]);
                    }
                    break;
            }
        }
        // Otherwise just clean the requested property.
        else cleanup(prop, translationsParent);
    }
}

/**
 * Creates a language property for the translatable value and optionally adds a translation.
 * @param translations The translation object.
 * @param language The language to set.
 * @param translation The optional translation to add.
 */
export function setTranslation(translations: any, language: string, translation: string = null): any {
    if (isNotDefined(translations)) translations = {};
    translations[language] = translation;
    return translations;
}

/**
 * Validates a translations object.
 * @param translation The translations to validate.
 * @param language The language key of the required language.
 * @returns
 */
export function validateTranslation(translation: any, language: string): boolean {
    return isDefined(translation) && Object.keys(translation).length > 0 && isNotEmpty(translation[language]);
}

export function addToSpecificIndex(array: any[], itemToAdd: any, index: number): void {
    if (index >= 0) array.splice(index, 0, itemToAdd);
    else array.push(itemToAdd);
}

export async function pasteItem(
    store: Store<State>,
    state: State,
    newArray: MedicalExaminationActionItem[] | MedicalTherapyExecutionItem[] | MedicalTherapyPlanItem[],
    newValidation: any[],
    newIndex: number,
    flow: MedicalExaminationActionFlow | MedicalTherapyExecutionFlow,
    type: 'MedicalExaminationActionItem' | 'MedicalTherapyPlanItem' | 'MedicalTherapyExecutionItem'
): Promise<void> {
    let copyItem: CopyActionItem | CopyTherapyPlanItem | CopyTherapyExecutionItem;
    switch (type) {
        case 'MedicalExaminationActionItem':
            copyItem = state.clipboard.actionItem;
            break;
        case 'MedicalTherapyPlanItem':
            copyItem = state.clipboard.therapyPlanItem;
            break;
        case 'MedicalTherapyExecutionItem':
            copyItem = state.clipboard.therapyExecutionItem;
            break;
    }

    if (isNotDefined(copyItem)) return;
    // If the command is 'cut' remove the item and validation
    // from their old positions.
    if (copyItem.command === 'cut') {
        copyItem.array.splice(copyItem.index, 1);
        copyItem.validation.splice(copyItem.index, 1);
    }

    // If we paste after the current item e.g.
    // the provided index is bigger than the current
    // index, substract 1 from the index to get the item
    // on the correct spot.
    // if (newIndex > copyItem.index && copyItem.command === 'cut') newIndex--;

    // Create a copy without any references.
    // Note that JSON.parse(JSON.stringify()) will remove functions and dates from the object's properties.
    const parsed = JSON.parse(JSON.stringify(copyItem.item));

    let copy: MedicalExaminationActionItem | MedicalTherapyPlanItem | MedicalTherapyExecutionItem;

    switch (type) {
        case 'MedicalExaminationActionItem':
            copy = MedicalExaminationActionItem.fromJS(Object.assign({}, parsed));
            break;
        case 'MedicalTherapyPlanItem':
            copy = MedicalTherapyPlanItem.fromJS(Object.assign({}, parsed));
            break;
        case 'MedicalTherapyExecutionItem':
            copy = MedicalTherapyExecutionItem.fromJS(Object.assign({}, parsed));
            break;
    }
    // If we're copying, make sure the item ids are unique.
    if (copyItem.command === 'copy') {
        const copySettings = (oldId: string, newId: string): void => {
            // ISSUE: When copying category and it settings ideally we would also like
            // to copy over all the settings. But changing the old id (key) to the id of the
            // copy will not suffice. Sure the ID and setting are copied, but the values of the
            // settings will still point to some steps in the original category.
            // const required = flow.required.filter((x) => x.key === oldId);
            // for (const item of required) {
            //     const cp = cloneDeep(item);
            //     cp.key = newId;
            //     flow.required.push(cp);
            // }
            // const breakpoints = flow.breakpoints.filter((x) => x.id === oldId);
            // for (const item of breakpoints) {
            //     const cp = cloneDeep(item);
            //     cp.id = newId;
            //     flow.breakpoints.push(cp);
            // }
            // const hasVisibilityRequirements = flow.hasVisibilityRequirements.filter((x) => x.key === oldId);
            // for (const item of hasVisibilityRequirements) {
            //     const cp = cloneDeep(item);
            //     cp.key = newId;
            //     flow.hasVisibilityRequirements.push(cp);
            // }
            // const visibilityRequirements = flow.visibilityRequirements.filter((x) => x.id === oldId);
            // for (const item of visibilityRequirements) {
            //     const cp = cloneDeep(item);
            //     cp.id = newId;
            //     flow.visibilityRequirements.push(cp);
            // }
            // const modelBoxes = flow.modelBoxes.filter((x) => x.key === oldId);
            // for (const item of modelBoxes) {
            //     const cp = cloneDeep(item);
            //     cp.key = newId;
            //     flow.modelBoxes.push(cp);
            // }
        };
        const copyId = copy.id;

        const updateIds = (items: MedicalExaminationActionItem[] | MedicalTherapyExecutionItem[]) => {
            for (const item of items) {
                const originalId = item.id;
                item.id = guid();
                copySettings(originalId, item.id);
                if (item.type === MedicalExaminationActionItemTypes.Category || item.type === MedicalTherapyExecutionItemTypes.Category) {
                    item.category.id = guid();
                    updateIds(item.category.stepsToTake);
                } else item.step.id = guid();
            }
        };

        copy.id = guid();
        copySettings(copyId, copy.id);
        if (copy.type === MedicalExaminationActionItemTypes.Category || copy.type === MedicalTherapyExecutionItemTypes.Category) {
            copy.category.id = guid();
            updateIds(copy.category.stepsToTake);
        } else copy.step.id = guid();
    }

    // Wait for the old array to update their indexes.
    // 'index' should be updated before pushing new item.
    setTimeout(async () => {
        addToSpecificIndex(newArray, copy, newIndex);
        const parsed = JSON.parse(JSON.stringify(copyItem.validationItem));
        addToSpecificIndex(newValidation, Object.assign({}, parsed), newIndex);
        // Remove the copied item from the state.
        await store.dispatch(clearItem, type);
    });
}

export function addCategory(
    array: MedicalExaminationActionItem[] | MedicalTherapyPlanItem[] | MedicalTherapyExecutionItem[], //
    validation: any[],
    index: number,
    language: string,
    type: 'MedicalExaminationActionItem' | 'MedicalTherapyPlanItem' | 'MedicalTherapyExecutionItem'
): void {
    let item: MedicalExaminationActionItem | MedicalTherapyPlanItem | MedicalTherapyExecutionItem;

    switch (type) {
        case 'MedicalExaminationActionItem':
            item = new MedicalExaminationActionItem({
                id: guid(),
                category: new MedicalExaminationActionStepCategory({
                    id: guid(),
                    name: setTranslation({}, language),
                    stepsToTake: [],
                    displayOrder: 0,
                    attributes: {
                        expanded: true
                    }
                }),
                type: MedicalExaminationActionItemTypes.Category
            });
            break;
        case 'MedicalTherapyPlanItem':
            item = new MedicalTherapyPlanItem({
                id: guid(),
                category: new MedicalTherapyPlanItemStepCategory({
                    id: guid(),
                    name: setTranslation({}, language),
                    stepsToTake: [],
                    attributes: {
                        expanded: true
                    }
                }),
                type: MedicalTherapyPlanItemTypes.Category
            });
            break;
        case 'MedicalTherapyExecutionItem':
            item = new MedicalTherapyExecutionItem({
                id: guid(),
                category: new MedicalTherapyExecutionItemStepCategory({
                    id: guid(),
                    name: setTranslation({}, language),
                    stepsToTake: [],
                    attributes: {
                        expanded: true
                    }
                }),
                type: MedicalTherapyExecutionItemTypes.Category
            });
            break;
    }

    const validationItem = {
        valid: true,
        childrenValid: true,
        name: true,
        steps: [],
        choices: [],
        requirements: []
    };

    // Add the new item.
    addToSpecificIndex(array, item, index);
    addToSpecificIndex(validation, validationItem, index);
}

export function addStep(
    array: MedicalExaminationActionItem[] | MedicalTherapyPlanItem[] | MedicalTherapyExecutionItem[],
    validation: any[],
    index: number,
    language: string,
    type: 'MedicalExaminationActionItem' | 'MedicalTherapyPlanItem' | 'MedicalTherapyExecutionItem'
): void {
    let item: MedicalExaminationActionItem | MedicalTherapyPlanItem | MedicalTherapyExecutionItem;

    switch (type) {
        case 'MedicalExaminationActionItem':
            item = new MedicalExaminationActionItem({
                id: guid(),
                step: new MedicalExaminationActionStep({
                    id: guid(),
                    name: setTranslation({}, language),
                    description: setTranslation({}, language),
                    placeholder: setTranslation({}, language),
                    question: setTranslation({}, language),
                    displayOrder: 0,
                    resultType: ResultTypes.Text,
                    inputType: InputTypes.FreeEntry,
                    affixPosition: AffixPositions.Suffix,
                    inputAmount: 1,
                    filesAmount: 0,
                    choices: [],
                    norm: new MedicalResult(),
                    norms: [],
                    allowedFileTypes: [],
                    attributes: {
                        expanded: true
                    },
                    inputRules: new InputRules(),
                    resultMatcher: new ResultMatcher({
                        type: ResultMatcherTypes.SimpleComparison
                    })
                }),
                type: MedicalExaminationActionItemTypes.Step
            });
            break;
        case 'MedicalTherapyPlanItem':
            item = new MedicalTherapyPlanItem({
                id: guid(),
                step: new MedicalTherapyPlanItemStep({
                    id: guid(),
                    name: setTranslation({}, language),
                    description: setTranslation({}, language),
                    placeholder: setTranslation({}, language),
                    question: setTranslation({}, language),
                    resultType: ResultTypes.Text,
                    inputType: InputTypes.FreeEntry,
                    affixPosition: AffixPositions.Suffix,
                    inputAmount: 1,
                    filesAmount: 0,
                    choices: [],
                    norm: new MedicalResult(),
                    norms: [],
                    allowedFileTypes: [],
                    attributes: {
                        expanded: true
                    },
                    inputRules: new InputRules(),
                    resultMatcher: new ResultMatcher({
                        type: ResultMatcherTypes.SimpleComparison
                    })
                }),
                type: MedicalTherapyPlanItemTypes.Step
            });
            break;
        case 'MedicalTherapyExecutionItem':
            item = new MedicalTherapyExecutionItem({
                id: guid(),
                step: new MedicalTherapyExecutionItemStep({
                    id: guid(),
                    name: setTranslation({}, language),
                    description: setTranslation({}, language),
                    placeholder: setTranslation({}, language),
                    question: setTranslation({}, language),
                    resultType: ResultTypes.Text,
                    inputType: InputTypes.FreeEntry,
                    affixPosition: AffixPositions.Suffix,
                    inputAmount: 1,
                    filesAmount: 0,
                    choices: [],
                    norm: new MedicalResult(),
                    norms: [],
                    allowedFileTypes: [],
                    attributes: {
                        expanded: true
                    },
                    inputRules: new InputRules(),
                    resultMatcher: new ResultMatcher({
                        type: ResultMatcherTypes.SimpleComparison
                    })
                }),
                type: MedicalTherapyExecutionItemTypes.Step
            });
            break;
    }

    const validationItem = {
        valid: true,
        name: true,
        displayOrder: true,
        question: true,
        norm: true,
        norms: true,
        inputAmount: true,
        inputAmountValid: true,
        filesAmount: true,
        choices: [],
        numericChoices: true,
        inputAmountValidMultipleChoices: true,
        choice: true,
        minRange: true,
        maxRange: true,
        minValue: true,
        maxValue: true,
        requirements: [],
        startValue: true,
        endValue: true,
        endValueValid: true,
        initialValue: true,
        initialValueValid: true,
        majorIncrement: true,
        majorIncrementValid: true,
        minorIncrement: true,
        minorIncrementValid: true,
        labels: []
    };

    // Add the new item.
    addToSpecificIndex(array, item, index);
    addToSpecificIndex(validation, validationItem, index);
}

export function collapseSteps(
    items: MedicalExaminationActionItem[] | MedicalTherapyPlanItem[] | MedicalTherapyExecutionItem[],
    category: MedicalExaminationActionStepCategory | MedicalTherapyPlanItemStepCategory | MedicalTherapyExecutionItemStepCategory = null,
    parents: string[] = []
): void {
    for (const item of items) {
        if (item.type === MedicalExaminationActionItemTypes.Category || item.type === MedicalTherapyPlanItemTypes.Category || item.type === MedicalTherapyExecutionItemTypes.Category) {
            if (isNotDefined(category)) item.category.attributes.expanded = false;
            else {
                // Check if current category is a parent of any of the
                // categories we're working in. If so, keep it expanded.
                parents = [...parents, item.category.id];
                if (parents.every((id) => id !== item.category.id)) item.category.attributes.expanded = false;
            }
            collapseSteps(item.category.stepsToTake, category, parents);
        } else item.step.attributes.expanded = false;
    }
}

export function moveStep(array: MedicalExaminationActionItem[] | MedicalTherapyPlanItem[] | MedicalTherapyExecutionItem[], validation: any[], direction: 'up' | 'down', currentIndex: number): void {
    let newIndex = direction === 'up' ? currentIndex - 1 : currentIndex + 1;

    if (newIndex < 0) newIndex = 0;
    if (newIndex > array.length - 1) newIndex = array.length - 1;

    const stepToMove = array[currentIndex];
    const validationToMove = validation[currentIndex];

    array.splice(currentIndex, 1);
    validation.splice(currentIndex, 1);

    array.splice(newIndex, 0, stepToMove as any);
    validation.splice(newIndex, 0, validationToMove);
}

export function cleanExpectedResults(items: MedicalExaminationActionItem[] | MedicalTherapyPlanItem[] | MedicalTherapyExecutionItem[]): void {
    const clean = (array: MedicalExaminationActionItem[] | MedicalTherapyPlanItem[] | MedicalTherapyExecutionItem[]): void => {
        for (const item of array) {
            if (item.type === MedicalExaminationActionItemTypes.Category || item.type === MedicalTherapyPlanItemTypes.Category || item.type === MedicalTherapyExecutionItemTypes.Category)
                clean(item.category.stepsToTake);
            else {
                if (isDefined(item.step.norm) && isNotDefined(item.step.norm.value)) item.step.norm = null;
                if (isDefined(item.step.defaultValue) && isNotDefined(item.step.defaultValue.value)) item.step.defaultValue = null;
            }
        }
    };

    clean(items);
}

export function setExpectedResults(items: MedicalExaminationActionItem[] | MedicalTherapyPlanItem[] | MedicalTherapyExecutionItem[]): void {
    const set = (array: MedicalExaminationActionItem[] | MedicalTherapyPlanItem[] | MedicalTherapyExecutionItem[]): void => {
        for (const item of array) {
            if (item.type === MedicalExaminationActionItemTypes.Category || item.type === MedicalTherapyPlanItemTypes.Category || item.type === MedicalTherapyExecutionItemTypes.Category)
                set(item.category.stepsToTake);
            else {
                if (isNotDefined(item.step.norm)) item.step.norm = new MedicalResult();
                if (isNotDefined(item.step.defaultValue)) item.step.defaultValue = new MedicalResult();
            }
        }
    };

    set(items);
}

export function generateResultPlaceholder(t: I18N, inputType: InputTypes, resultType: ResultTypes): string {
    switch (inputType) {
        case InputTypes.FreeEntry:
            if (resultType === ResultTypes.Text) return t.tr('translation:global.placeholders.enter-answer');
            else return t.tr('translation:global.placeholders.enter-numeric-value');
        case InputTypes.Selector:
            return t.tr('translation:global.placeholders.select-option');
        case InputTypes.Datepicker:
            return t.tr('translation:global.placeholders.select-date');
    }
}

export function generateColumns(breakpoints: ColumnBreakpoint[]): string {
    const sizes = { xs: 0, sm: 1, md: 2, lg: 3, xl: 4 };
    const sorted = breakpoints.sort((a, b) => sizes[a.screenSize] - sizes[b.screenSize]);

    const hasStartSize = sorted.some((x) => x.screenSize === 'xs');
    let columns: string = hasStartSize ? '' : 'col-span-12';

    for (const breakpoint of breakpoints) {
        let column: string;
        switch (breakpoint.screenSize) {
            case 'xs':
                column = `col-span-${breakpoint.width}`;
                break;
            case 'sm':
                column = `sm:col-span-${breakpoint.width}`;
                break;
            case 'md':
                column = `md:col-span-${breakpoint.width}`;
                break;
            case 'lg':
                column = `lg:col-span-${breakpoint.width}`;
                break;
            case 'xl':
                column = `xl:col-span-${breakpoint.width}`;
                break;
        }
        columns += ` ${column}`;
    }

    return columns;
}

export function autocomplete<T>(query: string, list: T[], predicate: (x: T) => string): T[] {
    if (isNotEmpty(query)) return list.filter((x: T) => predicate(x).includes(query.toLowerCase()));
    return list;
}

export function runOperator(operator: string, value1: number | boolean, value2: number | boolean): number | boolean {
    const operators = {
        Add: (val1: number, val2: number): number => val1 + val2,
        Substract: (val1: number, val2: number): number => val1 - val2,
        Divide: (val1: number, val2: number): number => val1 / val2,
        Multiply: (val1: number, val2: number): number => val1 * val2,
        GreaterThan: (val1: number, val2: number): boolean => val1 > val2,
        LessThan: (val1: number, val2: number): boolean => val1 < val2,
        GreaterThanOrEqualTo: (val1: number, val2: number): boolean => val1 >= val2,
        LessThanOrEqualTo: (val1: number, val2: number): boolean => val1 <= val2,
        And: (val1: boolean, val2: boolean): boolean => val1 && val2,
        Or: (val1: boolean, val2: boolean): boolean => val1 || val2
    };
    return operators[operator](value1, value2);
}

export function cloneDeep<T>(source: T): T {
    return clone(source);
    // if (null == source || 'object' != typeof source) return source;
    // if (source instanceof Date) {
    //     var copy = new Date();
    //     copy.setTime(source.getTime());
    //     return copy as T;
    // }
    // if (source instanceof Array) {
    //     let copy = [];
    //     for (var i = 0, len = source.length; i < len; i++) {
    //         copy[i] = cloneDeep(source[i]);
    //     }
    //     return copy as T;
    // }
    // if (source instanceof Object) {
    //     const copy = {} as any;
    //     for (var attr in source) {
    //         if (source.hasOwnProperty(attr)) copy[attr] = cloneDeep(source[attr]);
    //     }
    //     return copy as T;
    // }
    // throw new Error('Unable to copy obj this object.');
}

export function loadViewsFromAUrl(
    partial: PartialView,
    options: {
        open: (viewToOpen: string, entityId: string) => Promise<void>; //
    }
): void {
    const defaults = {
        ...options
    };

    const [_, url] = location.href.split('//');
    const views = Object.keys(PartialViews);
    let [__, ___, ...parts] = url
        // Remove the query parameters from the URL.
        .split('?')[0]
        .split('/')
        .filter((x) => isDefined(x) && isNotEmpty(x))
        // Filter out the asterisk
        .filter((x) => !x.includes('*'));

    // It is possible that the current caller is a base view so check for that.
    let viewName: string = null;
    let entityId: string = null;

    // Check if the parial has a route defined.
    if (isNotEmpty(partial.route)) {
        // Get the last part of the partial route and
        // try to match it with the current parts of the URL.
        const lastRoutePartOfViewRoute = partial.route.split('/').pop();
        const indexOfViewRoute = parts.findIndex((x) => x === lastRoutePartOfViewRoute);
        // Get all the parts that come AFTER the part of the partial view.
        // E.g. a child view with route like ':id/edit' or 'create'.
        const remainingViews = parts.slice(indexOfViewRoute + 1);
        let viewToOpen: string;
        // If we have one view, user it.
        if (remainingViews.length === 1) viewToOpen = remainingViews[0];
        // If we have specifically two views left it COULD be a ID/Route name combination.
        // The general rule is that the ID is the first part and the name is the second part.
        // E.g. ':id/edit'.
        else if (remainingViews.length === 2) {
            // When its an ID save it and replace it with ':id' in the view
            // so that we can match it with an existing route.
            if (isGuid(remainingViews[0])) {
                entityId = remainingViews[0];
                viewToOpen = remainingViews.length > 1 ? [':id', ...remainingViews.slice(1)].join('/') : remainingViews[0];
            }
            // Otherwise just use the first remaining view.
            else viewToOpen = remainingViews[0];
        } else if (remainingViews.length > 2) {
            // For now we support the following structure in case or more than 2 views:
            // 'part-name/:id/part-name' (3 parts in total).
            entityId = remainingViews[1];
            viewToOpen = [remainingViews[0], ':id', remainingViews[2]].join('/');
            // Check if view exists.
            const view = views.find((v) => PartialViews[v].parents.some((p: string) => p === partial.name) && PartialViews[v].route === viewToOpen);
            // If not, fallback to first part.
            if (isNotDefined(view)) {
                entityId = null;
                viewToOpen = remainingViews[0];
            }
        }

        // Try to match the view based on the transformed name and the current partial view as parent.
        viewName = views.find((v) => PartialViews[v].parents.some((p: string) => p === partial.name) && PartialViews[v].route === viewToOpen);
        // Always call the 'open' callback with an optional ID value even when no view is found.
        // Let the caller handle the undefined view name.
        defaults.open(viewName, entityId);
    } else {
        // Try to match the route of the base to a view.
        if (isDefined(partial.base) && isDefined(partial.base.route)) {
            // Get the last part of the base view.
            const lastRoutePartOfViewRoute = partial.base.route.split('/').pop();
            const indexOfViewRoute = parts.findIndex((x) => x === lastRoutePartOfViewRoute);
            // Get all the parts of the URL after the matched view.
            // and try to match the first part to a view.
            let viewToOpen = parts.slice(indexOfViewRoute + 1).shift();

            // Check if we have a view name.
            if (isDefined(viewToOpen)) {
                // Try to match the view name with an existing partial view.
                let partialName = views.find((v) => PartialViews[v].route === viewToOpen);
                // If we have a match return it.
                if (isDefined(partialName)) viewName = partialName;
                else {
                    // The viewToOpen could be an id e.g. the following route : ':id/edit'
                    // so we need to check for that. Check for the second part in the route by adding
                    // 1 to the indexOfTheViewRoute after saving the possible entity ID.
                    const tempEntityId = viewToOpen;
                    viewToOpen = parts.slice(indexOfViewRoute + 2).shift();

                    // Check if we have a second part
                    if (isDefined(viewToOpen)) {
                        // We have a second part. Assuming the first part is an ID we are going to
                        // change the viewToOpen so that it includes the ID marker.
                        viewToOpen = `:id/${viewToOpen}`;
                        // Try to match a partial view with the view and ID marker combined and with the current parent
                        const name = views.find((v) => PartialViews[v].parents.some((p: string) => p === partial.name) && PartialViews[v].route === viewToOpen);
                        if (isDefined(name)) {
                            // We matched a view, so set the viewname and entity ID.
                            viewName = name;
                            entityId = tempEntityId;
                        } else {
                            const first = parts.slice(indexOfViewRoute + 1).shift();
                            const second = parts.slice(indexOfViewRoute + 2).shift();
                            if (isDefined(first) && isDefined(second)) {
                                // If it is a two part route.
                                viewToOpen = `${first}/${second}`;
                                // Try to match a partial view with the view and ID marker combined and with the current parent
                                const name = views.find((v) => PartialViews[v].parents.some((p: string) => p === partial.name) && PartialViews[v].route === viewToOpen);
                                if (isDefined(name)) {
                                    // We matched a view, so set the viewname and entity ID.
                                    viewName = name;
                                    entityId = tempEntityId;
                                }
                            }
                        }
                    }
                }
            }
        }
        // Always call the callback even though the viewname can be undefined.
        // Let the caller handle that.
        defaults.open(viewName, entityId);
    }
}

export function isGuid(value: string): boolean {
    return /^([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})|[0-9]+$/i.test(value);
}

export function getLastPartial(state: State, view: View): PartialView {
    return state.viewEngine[view.id].partials[state.viewEngine[view.id].partials.length - 1];
}

export async function uploadAttachment(
    id: string,
    workspace: string,
    upload: any,
    entityType: AttachmentEntities,
    onProgress: (percentage: number) => void,
    isImage: boolean = false,
    config: AureliaConfiguration,
    auth: AuthenticationService
): Promise<BlobStorageAttachment> {
    return new Promise<BlobStorageAttachment>(async (resolve, reject) => {
        const token = await getToken(auth);

        const form = new FormData();
        form.append('file', upload.file, upload.name);

        let url = `${config.get('api.attachments')}/attachments/${id}/attach?entity=${entityType}`;
        if (isImage) url += '&createThumbnail=true';

        const request = new XMLHttpRequest();
        request.open('PUT', url);
        request.setRequestHeader('workspace', workspace);
        request.setRequestHeader('accept', 'application/json');
        request.setRequestHeader('authorization', `Bearer ${token}`);

        request.upload.addEventListener('progress', (e: ProgressEvent) => {
            let percentage = (e.loaded / e.total) * 100;
            if (percentage > 100) percentage = 100;
            if (percentage < 0) percentage = 0;
            onProgress(Math.round(percentage));
        });

        request.addEventListener('readystatechange', (e) => {
            if (request.readyState === XMLHttpRequest.DONE) {
                const status = request.status;
                if (status === 0 || (status >= 200 && status < 400)) {
                    // Do nothing.
                } else {
                    if (status > 0) {
                        reject({
                            status: request.status,
                            event: 'readystatechange',
                            error: request.statusText,
                            data: e
                        });
                    } else {
                        reject({
                            status: 0,
                            event: 'readystatechange',
                            error: 'Could not connect to the server.',
                            data: e
                        });
                    }
                }
            }
        });

        request.addEventListener('error', (e: ProgressEvent) => {
            reject({
                status: 0,
                event: 'error',
                error: 'Could not connect to the server.',
                data: e
            });
        });
        request.addEventListener('load', async () => {
            const res = JSON.parse(request.response);
            if (request.status !== 200 && request.status !== 201 && request.status !== 200) reject(res);
            else resolve(res);
        });

        request.send(form);
    });
}

export function registerSdk(
    aurelia: Aurelia,
    endpoint: string,
    apiVersion: string,
    auth: AuthenticationService,
    clients: any[],
    beforeRequest?: (options: RequestInit) => Promise<RequestInit>,
    afterRequest?: (url: string, response: Response) => Promise<Response>,
    shouldValidateToken: boolean = true
): void {
    const config = aurelia.container.get(AureliaConfiguration);
    aurelia.register(
        clients.map((t) =>
            Registration.callback(t, () => {
                return new t(
                    {
                        accessToken: () => getToken(auth),
                        apiVersion,
                        // Make sure we have a valid token!
                        beforeRequest: async (options: RequestInit): Promise<RequestInit> => {
                            if (isFunction(beforeRequest)) options = await beforeRequest(options);
                            return shouldValidateToken ? await validateToken(config, options, auth) : options;
                        },
                        afterRequest: async (url: string, response: Response): Promise<Response> => {
                            if (isFunction(afterRequest)) return afterRequest(url, response);
                            return response;
                        }
                    },
                    endpoint
                );
            })
        )
    );
}

export async function getToken(auth: AuthenticationService): Promise<string> {
    const session = await auth.getSession();
    return session.data.session?.access_token;
}

async function validateToken(config: AureliaConfiguration, options: RequestInit, auth: AuthenticationService): Promise<RequestInit> {
    // If the user is still authenticated, just continue with the request.
    const isAuthenticated = await auth.isAuthenticated();
    if (isAuthenticated) return options;
    // Lets see if we can refresh the token automatically.
    const succeeded = await auth.checkSession();
    // If we have a new token update the authorization token with the new token.
    if (succeeded) {
        const token = await getToken(auth);
        options.headers = { ...options.headers, authorization: `bearer ${token}` };
    } else {
        // Force the page to change to the login screen
        // with the current workspace, if available, included.
        const workspace = getFromStorage<string>(StorageKeys.workspace);
        if (isNotEmpty(workspace)) window.location.href = `/${workspace}`;
    }
    // Continue the request if we can.
    return options;
}

/**
 * Validates a step from an action or therapy execution.
 * @param step The item to validate.
 * @param flow The flow to use when validating.
 * @param validation The validation object to update.
 * @param record The medical record.
 * @param uploadsToUpload Any uploads that are not yet uploaded.
 * @returns
 */
export function validateStep(
    step: MedicalExaminationActionItem | MedicalTherapyPlanItem | MedicalTherapyExecutionItem,
    flow: MedicalExaminationActionFlow | MedicalTherapyPlanFlow | MedicalTherapyExecutionFlow,
    validation: any,
    record: GetMedicalRecordResponse,
    uploadsToUpload: SelectedFile[]
): any {
    // Check if the step is required (information is found on the step of the examination).
    const required = flow.required.find((x) => x.key === getSettingsId(step));

    // Collect all added attachments and uploads for this step.
    const attachments = getAttachmentsAndUploadsForStep(step.id, step.step.attachments, record, uploadsToUpload);
    // When a value or values are required lets check if we have any.
    if (isDefined(required) && required.value) {
        if (step.step.resultType === ResultTypes.File) validation.result = attachments.any();
        else if (step.step.resultType === ResultTypes.Date) validation.result = isDefined(step.step.result) && isDefined(step.step.result.date);
        else {
            validation.result =
                (isDefined(step.step.result) && isNotEmpty(step.step.result.value)) || (isDefined(step.step.results) && step.step.results.any() && step.step.results.every((x) => isNotEmpty(x.value)));
        }
    }

    // We have entered results. Lets validate the value(s) on specific requirements if configured.
    if (validation.result) {
        // Check if we have a value and its a free entered number value.
        if (isDefined(step.step.result.value) && step.step.inputType === InputTypes.FreeEntry && step.step.resultType === ResultTypes.Number) {
            // Check if entered value is equal or above the configured min value, if configured.
            if (isDefined(step.step.inputRules.min)) validation.min = Number(step.step.result.value) >= step.step.inputRules.min;
            // Check if entered value is equal or below the configured max value, if configured.
            if (isDefined(step.step.inputRules.max)) validation.max = Number(step.step.result.value) <= step.step.inputRules.max;
        }
        // When files are expected.
        if (step.step.resultType === ResultTypes.File) {
            // Check if enough files are selected, if configured.
            if (step.step.filesAmount > 0) validation.filesQuantity = attachments.length <= step.step.filesAmount;
            // Check if the correct files types are selected, if configured.
            if (isDefined(step.step.allowedFileTypes) && step.step.allowedFileTypes.any()) {
                const flattenedExtensions = step.step.allowedFileTypes.selectMany<FileType, string>((x) => x.extensions);
                validation.fileTypes = attachments.every((attachment) => flattenedExtensions.some((extension) => extension === attachment.extension));
            }
        }
    }

    // Mark step as (in)valid.
    validation.valid =
        validation.result && //
        validation.min &&
        validation.max &&
        validation.filesQuantity &&
        validation.fileTypes;

    return validation;
}

/**
 * Validates a single question or a question from a questionnaire.
 * @param step The item to validate.
 * @param flow The flow to use when validating.
 * @param question The question to validate.
 * @param validation The validation object to update.
 * @param stepId The id of the step.
 * @param record The medical record.
 * @param uploadsToUpload Any uploads that are not yet uploaded.
 * @param registrations All available record registrations
 * @returns
 */
export function validateQuestion(
    step: MedicalExaminationTemplateItemStep | MedicalTherapyExecutionItem | MedicalTherapyEvaluationItem | MedicalQuestionRegistration,
    flow: MedicalExaminationFlow | MedicalTherapyEvaluationFlow | MedicalQuestionnaireFlow,
    question: GetMedicalQuestionResponse,
    validation: any,
    stepId: string,
    record: GetMedicalRecordResponse,
    uploadsToUpload: SelectedFile[],
    registrations: any
): any {
    // Check if the question is required to answer (information is found on the step container).
    const required = flow.required.find((x) => x.key === stepId);
    // Collect all added attachments and uploads for this step.
    const attachments = getAttachmentsAndUploadsForStep(step.id, question.attachments, record, uploadsToUpload);
    // When an answer or answers are required lets check if we have any.
    if (isDefined(required) && required.value) {
        if (question.answerType === ResultTypes.File) validation.answer = attachments.any();
        else if (question.answerType === ResultTypes.Date) validation.answer = isDefined(question.givenAnswer) && isDefined(question.givenAnswer.date);
        else {
            validation.answer =
                (isDefined(question.givenAnswer) && isNotEmpty(question.givenAnswer.value)) ||
                (isDefined(question.givenAnswers) && question.givenAnswers.any() && question.givenAnswers.every((x) => isNotEmpty(x.value)));
        }
    }

    // We have answers. Lets validate the answer(s) on specific requirements if configured.
    if (validation.answer) {
        // Check if we have an answer and its a free entered number value.
        if (isDefined(question.givenAnswer.value) && question.inputType === InputTypes.FreeEntry && question.answerType === ResultTypes.Number) {
            // Check if entered value is equal or above the configured min value, if configured.
            if (isDefined(question.inputRules.min)) validation.min = Number(question.givenAnswer.value) >= question.inputRules.min;
            // Check if entered value is equal or below the configured max value, if configured.
            if (question.inputRules.max) validation.max = Number(question.givenAnswer.value) <= question.inputRules.max;
        }
        // When files are expected.
        if (question.answerType === ResultTypes.File) {
            // Check if enough files are selected, if configured.
            if (question.filesAmount > 0) validation.filesQuantity = attachments.length <= question.filesAmount;
            // Check if the correct files types are selected, if configured.
            if (isDefined(question.allowedFileTypes) && question.allowedFileTypes.any()) {
                const flattenedExtensions = question.allowedFileTypes.selectMany<FileType, string>((x) => x.extensions);
                validation.fileTypes = attachments.every((attachment) => flattenedExtensions.some((extension) => extension === attachment.extension));
            }
        }
    }

    // Decide whether or not this question is valid.
    validation.valid =
        validation.answer && //
        validation.min &&
        validation.max &&
        validation.filesQuantity &&
        validation.fileTypes;

    return validation;
}

/**
 * Collects all attachments and uploads for a single step.
 * @param stepId The id of the step.
 * @param attachmentsIds The ids of the attachments.
 * @param record The medical record.
 * @param uploadsToUpload Any uploads that are not yet uploaded.
 * @returns
 */
export function getAttachmentsAndUploadsForStep(
    stepId: string,
    attachmentsIds: string[],
    record: GetMedicalRecordResponse,
    uploadsToUpload: SelectedFile[]
): {
    extension: string;
    type: 'attachment' | 'upload';
}[] {
    const items: {
        extension: string;
        type: 'attachment' | 'upload';
    }[] = [];
    // Find previously uploaded attachments for the step.
    const attachments = record.attachments.filter((attachment) => attachmentsIds.some((id) => attachment.id === id));
    // Find the uploads (that still need to be processed) for the step.
    const uploads = uploadsToUpload.filter((upload) => upload.step === stepId);
    // Map every attachment.
    for (const attachment of attachments)
        items.push({
            extension: attachment.extension,
            type: 'attachment'
        });
    // Map every upload.
    for (const upload of uploads)
        items.push({
            extension: upload.extension,
            type: 'upload'
        });
    return items;
}

/**
 * Checks if a step is visible based on the provided flow and registrations.
 * @param item The step to check.
 * @param flow The flow to use when checking.
 * @param registrations All available record registrations.
 * @param steps All available steps to check against.
 * @param isEvaluationStep Whether or not the step is an evaluation step.
 * @returns
 */
export function isStepVisible(
    body: FlattenedExaminationStep,
    flow: MedicalExaminationActionFlow,
    registrations: { [key: string]: GetMedicalRecordRegistrationResponse },
    steps: FlattenedExaminationStep[]
): boolean {
    const requirements = flow.visibilityRequirements.filter((x) => x.id === getSettingsId(body.item));
    // If we don't have requirements, mark as visible.
    if (requirements.empty()) return true;
    // Try to match to provided results with t he required results.
    let matched: boolean = false;
    for (let i = 0; i < requirements.length; i++) {
        const requirement = requirements[i];

        // Finds the steps that match the requirement step ID.
        const step = steps.find(
            (step) =>
                getSettingsId(step.item) === requirement.stepId && //
                // If we have two containers (requirement step and body step), they need to match.
                // We do this so that because lets say there are two duplicate actions in the examination
                // who are looking to the same flow settings. We don't want to trigger the same step in different
                // actions. So get the step that belongs to the same container.
                (isDefined(body.container) && isDefined(step.container) ? step.container.id === body.container.id : true)
        );

        // If we can't find the step, continue. This can occur when the requirement is pointing
        // to a step that was removed. For example. An action has visibility requirements
        // that is pointing a single step but that step has been removed (it could be re-added but then)
        // it has a different ID).
        if (isNotDefined(step)) continue;

        // Get the registration for the step so that we can check its result.
        const registration = registrations[step.item.id];

        let match = false;
        if (requirement.type === MedicalExaminationStepVisibilityRequirementTypes.Question) {
            const question = isDefined(registration) ? registration.question : (step.item as MedicalQuestionRegistration).question;
            match =
                // First of all the answered question need to match with the question of the requirement.
                requirement.questionToCompare?.id === question.id &&
                // Second, the answer value should match with the result value.
                (question.givenAnswer.value === requirement.resultToCompare.value ||
                    // Or the result value should be one of the provided answers in case of multiple choice.
                    question.givenAnswers.some((answer: MedicalResult) => answer.value === requirement.resultToCompare.value));
        } else {
            // First of all the answered step need to match with the step of the requirement.
            const item = step.item as MedicalExaminationActionItem | MedicalTherapyPlanItem | MedicalTherapyExecutionItem;
            match =
                requirement.stepToCompare?.id === item.step.id &&
                // Second, the result value should match with the result value.
                (item.step.result.value === requirement.resultToCompare.value ||
                    // Or the result value should be one of the provided result in case of multiple choice.
                    item.step.results.some((result: MedicalResult) => result.value === requirement.resultToCompare.value));
        }

        // Only use operators when it is not the first requirement item.
        // The first requirement will never have an operator selected.
        // For each configured operator it will decided whether this is a 'AND' or 'OR' comparisment
        // and then it will be matched against the previous 'matched' value.
        matched =
            (i > 0 ? (runOperator(requirement.operator, matched, match) as boolean) : match) &&
            // If we have two containers (requirement step and body step), they need to match.
            // We do this so that because lets say there are two duplicate actions in the examination
            // who are looking to the same flow settings. We don't want to trigger the same step in different
            // actions. So only look within the container of the step.
            (isDefined(body.container) && isDefined(step.container) ? step.container.id === body.container.id : true);
    }

    return matched;
}

/**
 * Flattens an examinatio action or therapy execution.
 * @param flattened The array the steps need to be added to.
 * @param items The items that needs to be flattened.
 * @param registration The registration for the action or therapy.
 * @param flow The flow to add to the flattened steps.
 * @param type The type of the items to be flattened.
 * @param isFromEvaluation Whether or not the items are from an evaluation.
 * @returns
 */
export function flattenActionOrTherapySteps(
    flattened: FlattenedExaminationStep[],
    items: MedicalExaminationActionItem[] | MedicalTherapyPlanItem[] | MedicalTherapyExecutionItem[], //
    registration: GetMedicalRecordRegistrationResponse,
    flow: MedicalExaminationActionFlow | MedicalTherapyPlanFlow | MedicalTherapyExecutionFlow | MedicalTherapyEvaluationFlow,
    type: 'action' | 'therapy-plan' | 'therapy-execution',
    isFromEvaluation: boolean,
    container: MedicalExaminationTemplateItemStep | GetMedicalRecordRegistrationResponse | MedicalTherapyEvaluationItem,
    categories: string[]
): FlattenedExaminationStep[] {
    for (const item of items) {
        if (item.type === MedicalExaminationActionItemTypes.Category || item.type === MedicalTherapyExecutionItemTypes.Category) {
            flattened.push(
                new FlattenedExaminationStep({
                    item,
                    registration,
                    isAnswerable: false,
                    isAnswered: false,
                    isAnsweredAndValidated: false,
                    isVisible: false,
                    isRequired: false,
                    flow,
                    type: `${type}-category`,
                    isFromEvaluation,
                    container,
                    // Make sure we have a copy of the categories array.
                    // Otherwise we will be adding the same array to each step
                    // hich will keep the same reference.
                    categories: [...categories]
                })
            );
            // Save the category ID
            categories.push(item.id);
            // Flatten the steps of the category.
            flattenActionOrTherapySteps(
                flattened, //
                item.category.stepsToTake,
                registration,
                flow,
                type,
                isFromEvaluation,
                container,
                categories
            );
            // Remove the current category from the categories array
            // after we have added all the steps from the category.
            // So that we can continue with the next category.
            // This will prevent that categories from the current ladder
            // e.g. the B from [A,B] is added to the next ladder e.g [A,C].
            const index = categories.findIndex((id) => id === item.id);
            if (index > -1) categories.splice(index, 1);
        } else {
            flattened.push(
                new FlattenedExaminationStep({
                    item,
                    registration,
                    isAnswerable: true,
                    isAnswered: false,
                    isAnsweredAndValidated: false,
                    isVisible: false,
                    isRequired: false,
                    flow,
                    type: `${type}-step`,
                    isFromEvaluation,
                    container,
                    // Make sure we have a copy of the categories array.
                    // Otherwise we will be adding the same array to each step
                    // hich will keep the same reference.
                    categories: [...categories]
                })
            );
        }
    }
    return flattened;
}

/**
 * Flattens an entire examinations and marks each step whether ot not
 * they are answerable, answered, validated, visible, required etc.
 * @returns
 */
export function flattenRecordExamination(
    record: GetMedicalRecordResponse, //
    registrations: { [key: string]: GetMedicalRecordRegistrationResponse },
    stepIds: string[],
    uploadsToUpload: SelectedFile[]
): FlattenedExaminationStep[] {
    let flattened: FlattenedExaminationStep[] = [];

    for (const phase of record.examination.template.phases) {
        for (const step of phase.stepsToTake) {
            // When a record is created, a registration is created for each step.
            // Now, it can happen that the step data is not available anymore (e.g. when
            // the action, question, questionnaire, widget or treatment is deleted by an admin).
            // When a registration has no step data, it doesn't need to be added to the steps.
            const stepRegistration = registrations[step.id];
            if (isNotDefined(stepRegistration)) continue;

            switch (step.type) {
                case MedicalExaminationTemplateItemStepTypes.Action:
                    // Only continue if action data is present (see above explanation).
                    if (isNotDefined(stepRegistration.action)) break;

                    flattened.push(
                        new FlattenedExaminationStep({
                            item: step,
                            registration: stepRegistration,
                            isAnswerable: false,
                            isAnswered: false,
                            isAnsweredAndValidated: false,
                            isVisible: false,
                            isRequired: false,
                            type: 'action',
                            flow: record.examination.flow,
                            isFromEvaluation: false
                        })
                    );

                    flattened = [
                        ...flattened, //
                        ...flattenActionOrTherapySteps(
                            [], //
                            stepRegistration.action.stepsToTake,
                            stepRegistration,
                            stepRegistration.action.flow,
                            'action',
                            false,
                            step,
                            []
                        )
                    ];
                    break;
                case MedicalExaminationTemplateItemStepTypes.Question:
                    // Only continue if question data is present (see above explanation).
                    if (isNotDefined(stepRegistration.question)) break;
                    flattened.push(
                        new FlattenedExaminationStep({
                            item: step,
                            registration: stepRegistration,
                            isAnswerable: true,
                            isAnswered: false,
                            isAnsweredAndValidated: false,
                            isVisible: false,
                            isRequired: false,
                            type: 'question',
                            flow: record.examination.flow,
                            isFromEvaluation: false
                        })
                    );
                    break;
                case MedicalExaminationTemplateItemStepTypes.Questionnaire:
                    // Only continue if questionnaire data is present (see above explanation).
                    if (isNotDefined(stepRegistration.questionnaire)) break;

                    flattened.push(
                        new FlattenedExaminationStep({
                            item: step,
                            registration: stepRegistration,
                            isAnswerable: false,
                            isAnswered: false,
                            isAnsweredAndValidated: false,
                            isVisible: false,
                            isRequired: false,
                            type: 'questionnaire',
                            flow: record.examination.flow,
                            isFromEvaluation: false
                        })
                    );

                    for (const item of stepRegistration.questionnaire.questions) {
                        flattened.push(
                            new FlattenedExaminationStep({
                                item, //
                                registration: stepRegistration,
                                isAnswerable: true,
                                isAnswered: false,
                                isAnsweredAndValidated: false,
                                isVisible: false,
                                isRequired: false,
                                type: 'questionnaire-question',
                                flow: stepRegistration.questionnaire.flow,
                                isFromEvaluation: false,
                                container: step
                            })
                        );
                    }
                    break;
                case MedicalExaminationTemplateItemStepTypes.Widget:
                    flattened.push(
                        new FlattenedExaminationStep({
                            item: step,
                            registration: stepRegistration,
                            isAnswerable: false,
                            isAnswered: false,
                            isAnsweredAndValidated: false,
                            isVisible: false,
                            isRequired: false,
                            type: 'widget',
                            flow: record.examination.flow,
                            isFromEvaluation: false
                        })
                    );

                    break;
            }
        }
    }

    for (const plan of record.treatment.plans) {
        const registration = registrations[plan.id];
        if (isNotDefined(registration) || isNotDefined(registration.therapyPlan)) break;

        flattened = [
            ...flattened, //
            ...flattenActionOrTherapySteps(
                [], //
                registration.therapyPlan.value.stepsToTake,
                registration,
                registration.therapyPlan.value.flow,
                'therapy-plan',
                false,
                // step,
                registration,
                []
            )
        ];
    }

    for (const plan of record.treatment.executions) {
        const registration = registrations[plan.id];
        if (isNotDefined(registration) || isNotDefined(registration.therapyExecution)) break;

        flattened = [
            ...flattened, //
            ...flattenActionOrTherapySteps(
                [], //
                registration.therapyExecution.value.stepsToTake,
                registration,
                registration.therapyExecution.value.flow,
                'therapy-execution',
                false,
                // step,
                registration,
                []
            )
        ];
    }

    for (const evaluation of record.treatment.evaluations) {
        const registration = registrations[evaluation.id];
        if (isNotDefined(registration) || isNotDefined(registration.therapyEvaluation)) break;

        for (const step of registration.therapyEvaluation.value.stepsToTake) {
            // When a record is created, a registration is created for each step.
            // Now, it can happen that the step data is not available anymore (e.g. when
            // the action, question, questionnaire, widget or treatment is deleted by an admin).
            // When a registration has no step data, it doesn't need to be added to the steps.
            const stepRegistration = registrations[step.id];
            if (isNotDefined(stepRegistration)) continue;

            switch (step.type) {
                case MedicalTherapyEvaluationItemTypes.Action:
                    // Only continue if action data is present (see above explanation).
                    if (isNotDefined(stepRegistration.action)) break;

                    flattened.push(
                        new FlattenedExaminationStep({
                            item: step,
                            registration: stepRegistration,
                            isAnswerable: false,
                            isAnswered: false,
                            isAnsweredAndValidated: false,
                            isVisible: false,
                            isRequired: false,
                            type: 'action',
                            flow: record.examination.flow,
                            isFromEvaluation: false
                        })
                    );

                    flattened = [
                        ...flattened, //
                        ...flattenActionOrTherapySteps(
                            [], //
                            stepRegistration.action.stepsToTake,
                            stepRegistration,
                            stepRegistration.action.flow,
                            'action',
                            false,
                            step,
                            []
                        )
                    ];
                    break;
                case MedicalTherapyEvaluationItemTypes.Question:
                    // Only continue if question data is present (see above explanation).
                    if (isNotDefined(stepRegistration.question)) break;
                    flattened.push(
                        new FlattenedExaminationStep({
                            item: step,
                            registration: stepRegistration,
                            isAnswerable: true,
                            isAnswered: false,
                            isAnsweredAndValidated: false,
                            isVisible: false,
                            isRequired: false,
                            type: 'question',
                            flow: record.examination.flow,
                            isFromEvaluation: false
                        })
                    );
                    break;
                case MedicalTherapyEvaluationItemTypes.Questionnaire:
                    // Only continue if questionnaire data is present (see above explanation).
                    if (isNotDefined(stepRegistration.questionnaire)) break;

                    flattened.push(
                        new FlattenedExaminationStep({
                            item: step,
                            registration: stepRegistration,
                            isAnswerable: false,
                            isAnswered: false,
                            isAnsweredAndValidated: false,
                            isVisible: false,
                            isRequired: false,
                            type: 'questionnaire',
                            flow: record.examination.flow,
                            isFromEvaluation: false
                        })
                    );

                    for (const item of stepRegistration.questionnaire.questions) {
                        flattened.push(
                            new FlattenedExaminationStep({
                                item, //
                                registration: stepRegistration,
                                isAnswerable: true,
                                isAnswered: false,
                                isAnsweredAndValidated: false,
                                isVisible: false,
                                isRequired: false,
                                type: 'questionnaire-question',
                                flow: stepRegistration.questionnaire.flow,
                                isFromEvaluation: false,
                                container: step
                            })
                        );
                    }
                    break;
                case MedicalTherapyEvaluationItemTypes.Widget:
                    flattened.push(
                        new FlattenedExaminationStep({
                            item: step,
                            registration: stepRegistration,
                            isAnswerable: false,
                            isAnswered: false,
                            isAnsweredAndValidated: false,
                            isVisible: false,
                            isRequired: false,
                            type: 'widget',
                            flow: record.examination.flow,
                            isFromEvaluation: false
                        })
                    );

                    break;
            }
        }
    }

    // Now we have flattened each step of the examination we can
    // check some things like whether or not a step is visible,
    // required, answered etc.
    for (const body of flattened) {
        // Find the flattened body for the container if the current body has a container.
        const container = isDefined(body.container) ? flattened.find((x) => x.item.id === body.container.id) : null;
        const categories = flattened.filter((x) => {
            if (isNotDefined(body.categories)) return false;
            return body.categories.some((id) => id === x.item.id);
        });

        body.isVisible =
            // The body itself should be visible.
            isStepVisible(body, body.flow, registrations, flattened) &&
            // If the body has a container, the container should ALSO be visible.
            // With container we mean the action, question or questionnaire.
            (isDefined(container) ? container.isVisible : true) &&
            // If the body has categories, ALL parent categories should ALSO be visible.
            (categories.any() ? categories.every((x) => x.isVisible) : true);
        body.isAnswered = isStepAnswered(body.item, body.flow, record, registrations, body.type, uploadsToUpload, false);
        body.isAnsweredAndValidated = isStepAnswered(body.item, body.flow, record, registrations, body.type, uploadsToUpload, true);
        body.isRequired =
            isStepRequired(body.flow, getSettingsId(body.item)) && //
            // The step is only required if the parent container is also required.
            (isDefined(container) ? container.isRequired : true);
    }

    if (isNotDefined(stepIds)) return flattened;
    return flattened.filter((x: any) => stepIds.some((id) => id === x.item.id || id === x.item.copiedFrom));
}

/**
 * Checks whether or not a step is answered.
 * @param item The step to check.
 * @param flow The flow to use when checking.
 * @param type The type of step.
 * @param Record A medical record.
 * @param validate A flag indicating whether or not to validate the step.
 * @returns
 */
export function isStepAnswered(
    item: MedicalExaminationTemplateItemStep | MedicalExaminationActionItem | MedicalTherapyExecutionItem | MedicalTherapyEvaluationItem | MedicalQuestionRegistration,
    flow: MedicalExaminationFlow | MedicalExaminationActionFlow | MedicalQuestionnaireFlow | MedicalTherapyExecutionFlow | MedicalTherapyEvaluationFlow,
    record: GetMedicalRecordResponse,
    registrations: { [key: string]: GetMedicalRecordRegistrationResponse },
    type:
        | 'action'
        | 'action-category'
        | 'action-step'
        | 'questionnaire'
        | 'questionnaire-question'
        | 'question'
        | 'widget'
        | 'therapy-plan'
        | 'therapy-plan-category'
        | 'therapy-plan-step'
        | 'therapy-execution'
        | 'therapy-execution-category'
        | 'therapy-execution-step'
        | 'evaluation',
    uploadsToUpload: SelectedFile[],
    validate: boolean = false
): boolean {
    if (type === 'action-step' || type === 'therapy-plan-step' || type === 'therapy-execution-step') {
        const step = (item as MedicalExaminationActionItem | MedicalTherapyPlanItem | MedicalTherapyExecutionItem).step;
        const validation = validateStep(
            item as MedicalExaminationActionItem | MedicalTherapyPlanItem | MedicalTherapyExecutionItem,
            flow,
            {
                valid: true,
                result: true,
                min: true,
                max: true,
                filesQuantity: true,
                fileTypes: true
            },
            record,
            uploadsToUpload
        );
        let answered: boolean = false;
        const attachments = getAttachmentsAndUploadsForStep(item.id, step.attachments, record, uploadsToUpload);
        // When a value or values are required lets check if we have any.
        if (step.resultType === ResultTypes.File) {
            answered = attachments.any() || step.attributes?.answered || false;
        } else if (step.resultType === ResultTypes.Date) answered = isDefined(step.result) && isDefined(step.result.date);
        else {
            answered =
                (isDefined(step.result) && isNotEmpty(step.result.value)) || //
                (isDefined(step.results) && step.results.any() && step.results.every((x) => isNotEmpty(x.value)));
        }
        return answered && (validate ? validation.valid : true);
    } else if (type === 'questionnaire-question' || type === 'question') {
        const step = item as MedicalQuestionRegistration;
        const registration = registrations[step.id];
        const question = isDefined(registration) && isDefined(registration.question) ? registration.question : step.question;
        const validation = validateQuestion(
            item as MedicalExaminationActionItem | MedicalTherapyExecutionItem,
            flow,
            question,
            {
                valid: true,
                answer: true,
                min: true,
                max: true,
                filesQuantity: true,
                fileTypes: true
            },
            step.id,
            record,
            uploadsToUpload,
            registrations
        );
        let answered: boolean = false;
        const attachments = getAttachmentsAndUploadsForStep(step.id, question.attachments, record, uploadsToUpload);
        if (question.answerType === ResultTypes.File) answered = attachments.any() || step.attributes?.answered || false;
        else if (question.answerType === ResultTypes.Date) answered = isDefined(question.givenAnswer) && isDefined(question.givenAnswer.date);
        else
            answered =
                (isDefined(question.givenAnswer) && isNotEmpty(question.givenAnswer.value)) ||
                (isDefined(question.givenAnswers) && question.givenAnswers.any() && question.givenAnswers.every((x) => isNotEmpty(x.value)));
        return answered && (validate ? validation.valid : true);
    }

    return false;
}

/**
 * Checks whether or not a step is required.
 * @param flow The flow to check the setting in.
 * @param stepId The ID of the step to check.
 * @returns
 */
export function isStepRequired(
    flow: MedicalExaminationFlow | MedicalExaminationActionFlow | MedicalQuestionnaireFlow | MedicalTherapyPlanFlow | MedicalTherapyExecutionFlow | MedicalTherapyEvaluationFlow, //
    stepId: string
): boolean {
    const required = flow.required.find((x) => x.key === stepId);
    // If we have a body we use the flow that the body provides.
    return isDefined(required) && required.value;
}

export function getSettingsId(
    item: MedicalExaminationActionItem | MedicalTherapyPlanItem | MedicalTherapyExecutionItem | MedicalQuestionRegistration | MedicalExaminationTemplateItemStep | MedicalTherapyEvaluationItem
): string {
    let stepId: string;
    if (
        item instanceof MedicalExaminationActionItem || //
        item instanceof MedicalQuestionRegistration ||
        item instanceof MedicalTherapyPlanItem ||
        item instanceof MedicalTherapyExecutionItem ||
        item instanceof MedicalTherapyEvaluationItem
    )
        stepId = item.copiedFrom;
    else stepId = item.id;

    return stepId;
}

export function getSettingsForStep(
    record: GetMedicalRecordResponse, //
    registrations: { [key: string]: GetMedicalRecordRegistrationResponse },
    stepId: string
): FlattenedExaminationStep {
    return flattenRecordExamination(record, registrations, [stepId], [])[0];
}

export function removeAllSettings(
    flow: MedicalExaminationActionFlow | MedicalTherapyPlanFlow | MedicalTherapyExecutionFlow, //
    items: MedicalExaminationActionItem[] | MedicalTherapyPlanItem[] | MedicalTherapyExecutionItem[]
): void {
    for (const item of items) {
        if (item.type === MedicalExaminationActionItemTypes.Category || item.type === MedicalTherapyPlanItemTypes.Category || item.type === MedicalTherapyExecutionItemTypes.Category)
            removeAllSettings(flow, item.category.stepsToTake);
        removeItemSettings(flow, item);
    }
}

export function removeItemSettings(
    flow: MedicalExaminationActionFlow | MedicalTherapyPlanFlow | MedicalTherapyExecutionFlow | MedicalQuestionnaireFlow | MedicalTherapyEvaluationFlow | MedicalExaminationFlow, //
    item: MedicalExaminationActionItem | MedicalTherapyPlanItem | MedicalTherapyExecutionItem | MedicalQuestionRegistration | MedicalTherapyEvaluationItem | MedicalExaminationTemplateItemStep
): void {
    // Remove settings that are linked to the current item.
    flow.breakpoints = flow.breakpoints.filter((x) => x.id !== item.id);
    flow.required = flow.required.filter((x) => x.key !== item.id);

    // Lets check if we have any visibility requirements that are linked
    // to the current item. If if so delete them.
    const hasVisibilityRequirementsIds = [];
    flow.visibilityRequirements = flow.visibilityRequirements.filter((x) => {
        // Check if the current item can stay.
        const canStay = x.id !== item.id && x.stepId !== item.id;
        // If the requirement can't stay, add it to the list of IDs.
        // This is because we need to check later on whether or not this item has
        // other visibility requirements. If not, the 'hasVisibilityRequirements'
        // (boolean) value of this item also needs to be deleted.
        if (!canStay) hasVisibilityRequirementsIds.push(x.id);
        return canStay;
    });

    // Lets loop through each item that was added above.
    for (const id of hasVisibilityRequirementsIds) {
        // Check if this item has more visibility requirements.
        // If so, no need to do anything and move on to the next item.
        const requirements = flow.visibilityRequirements.filter((x) => x.id === id);
        if (!requirements.empty()) continue;
    }

    if (flow instanceof MedicalExaminationActionFlow || flow instanceof MedicalTherapyPlanFlow || flow instanceof MedicalTherapyExecutionFlow) {
        flow.connectedCategories = flow.connectedCategories.filter((x) => x.key !== item.id && x.value !== item.id);
        flow.connectedSteps = flow.connectedSteps.filter((x) => x.key !== item.id && x.value !== item.id);
    }

    if (flow instanceof MedicalExaminationActionFlow) {
        flow.modelBoxes = flow.modelBoxes.filter((x) => x.key !== item.id);
        flow.differentialDiagnosesRequirements = flow.differentialDiagnosesRequirements.filter((x) => x.id !== item.id);
        flow.medicalExaminationActionRequirements = flow.medicalExaminationActionRequirements.filter((x) => x.id !== item.id);
        flow.sides = flow.sides.filter((x) => x.key !== item.id);
    }

    if (flow instanceof MedicalTherapyPlanFlow) {
        flow.prices = flow.prices.filter((x) => x.stepId !== item.id);
    }
}

export function getSchedulerColumnHeight(settings: SchedulerSettings, periods: SchedulerPeriod[], periodHeight: number): number {
    const amountOfSlotsInPeriod = 60 / settings.slotSize;
    const heightPerSlot = periodHeight / amountOfSlotsInPeriod;

    let height = 0;

    for (const period of periods) {
        if (period.hour == settings.start.hour && settings.start.minute > 0) {
            // Check the start time of the slot and calculate the height of the slot.
            const slotsForPeriod = amountOfSlotsInPeriod - settings.start.minute / settings.slotSize;
            height += slotsForPeriod * heightPerSlot;
        } else if (period.hour == settings.end.hour && settings.end.minute > 0) {
            const slotsForPeriod = settings.end.minute / settings.slotSize;
            height += slotsForPeriod * heightPerSlot;
        } else height += periodHeight;
    }

    return height;
}

export function getSchedulerPeriodHeight(settings: SchedulerSettings, period: SchedulerPeriod, periodHeight: number): number {
    const amountOfSlotsInPeriod = 60 / settings.slotSize;
    const heightPerSlot = periodHeight / amountOfSlotsInPeriod;

    if (settings.start.hour === period.hour && settings.start.minute > 0) {
        const slotsForPeriod = amountOfSlotsInPeriod - settings.start.minute / settings.slotSize;
        return slotsForPeriod * heightPerSlot;
    } else if (settings.end.hour === period.hour && settings.end.minute > 0) {
        const slotsForPeriod = settings.end.minute / settings.slotSize;
        return slotsForPeriod * heightPerSlot;
    }

    return periodHeight;
}

export function getTotalAmountOfSlots(columnDate: Date, settings: SchedulerSettings): number {
    const duration = intervalToDuration({
        start: setMinutes(setHours(columnDate, settings.start.hour), settings.start.minute),
        end:
            settings.end.hour === 24 //
                ? endOfDay(columnDate)
                : setMinutes(setHours(columnDate, settings.end.hour), settings.end.minute)
    });

    const slotsForTheHours = duration.hours * (60 / settings.slotSize);
    const slotsForTheMinutes = duration.minutes / settings.slotSize;
    return slotsForTheHours + slotsForTheMinutes;
}

export function checkForCustomDescriptions(
    t: I18N,
    language: string,
    descriptions: { [key: string]: string }[],
    entity: GetInsurerResponse | GetAccountResponse | GetContactResponse | GetPatientResponse, //
    prop: 'email' | 'phone' | 'address' | 'link'
): { [key: string]: string }[] {
    // Combine default descriptions with custom descriptions
    // that are used by the user.
    const combined = [
        ...descriptions, //
        ...(prop === 'address' //
            ? entity['addresses'].map((x: Address) => x.translations)
            : entity[`${prop}s`].map((x: Email | Phone | Link) => x.translations))
    ] // Filter out the custom option
        .filter((x) => x[language] !== t.tr('translation:global.labels.custom', { lng: language }))
        .orderBy((x) => x[language]);

    // Re-add the custom option on the end and make
    // sure there aren't any duplicates.
    return [
        ...combined,
        {
            nl: t.tr('translation:global.labels.custom', { lng: 'nl' }), //
            en: t.tr('translation:global.labels.custom', { lng: 'en' })
        } as any
    ].distinct((x) => x[language]) as any[];
}

export function formatForTimeOnly(time: string): string {
    if (time.length == 5) return `${time}:00`;
    return time;
}

export function validateTime(time: string): boolean {
    const [hour, minutes] = time.split(':');
    return (
        !hour.includes('_') && //
        !minutes.includes('_') &&
        Number(hour) >= 0 &&
        Number(hour) <= 23 &&
        Number(minutes) >= 0 &&
        Number(minutes) <= 59
    );
}

export function dayAndTimeToDate(day: DayOfWeek, time: string): Date {
    const [hour, minutes] = time.split(':').map(Number);
    let nmbr = 0;
    switch (day) {
        case DayOfWeek.Monday:
            nmbr = 1;
            break;
        case DayOfWeek.Tuesday:
            nmbr = 2;
            break;
        case DayOfWeek.Wednesday:
            nmbr = 3;
            break;
        case DayOfWeek.Thursday:
            nmbr = 4;
            break;
        case DayOfWeek.Friday:
            nmbr = 5;
            break;
        case DayOfWeek.Saturday:
            nmbr = 6;
            break;
        case DayOfWeek.Sunday:
            nmbr = 0;
            break;
    }
    return setDay(setMinutes(setHours(new Date(), hour), minutes), nmbr);
}

export function toDayOfWeek(date: Date): DayOfWeek {
    switch (date.getDay()) {
        case 0:
            return DayOfWeek.Sunday;
        case 1:
            return DayOfWeek.Monday;
        case 2:
            return DayOfWeek.Tuesday;
        case 3:
            return DayOfWeek.Wednesday;
        case 4:
            return DayOfWeek.Thursday;
        case 5:
            return DayOfWeek.Friday;
        case 6:
            return DayOfWeek.Saturday;
    }
}

export async function refreshAttachmentsUrls(content: any, mediaLibrary: MediaLibraryApiClient, workspace: string): Promise<any> {
    if (isNotDefined(content) || isNotDefined(content.ops)) return content;
    const checks = content.ops
        .filter((item: any) => isDefined(item.insert.imageAttachment) || (isDefined(item.insert.videoAttachment) && isDefined(item.insert.videoAttachment.attachment)))
        .map((item: any) => {
            return new Promise<void>(async (resolve) => {
                const container = item.insert.imageAttachment || item.insert.videoAttachment;
                // Check if URL still has correct authorization.
                var request = new XMLHttpRequest();
                request.open('GET', container.src, true);
                request.send();
                request.onload = async () => {
                    if (request.status !== 200) {
                        const url = await mediaLibrary.getUrl(container.attachment, workspace, container.thumbnail || true);
                        container.src = url;
                    }
                    resolve();
                };
            });
        });

    await Promise.all(checks);
    return content;
}

export function setActionStepOrTherapyStepValidation(steps: MedicalExaminationActionItem[] | MedicalTherapyPlanItem[] | MedicalTherapyExecutionItem[], validation: any[]) {
    for (let sI = 0; sI < steps.length; sI++) {
        const item = steps[sI];
        if (item.type === MedicalExaminationActionItemTypes.Category || item.type === MedicalTherapyPlanItemTypes.Category || item.type === MedicalTherapyExecutionItemTypes.Category) {
            validation.push({ id: item.id, valid: true, steps: [] });
            setActionStepOrTherapyStepValidation(item.category.stepsToTake, validation[sI].steps);
        } else
            validation.push({
                id: item.id,
                valid: true,
                result: true,
                min: true,
                max: true,
                filesQuantity: true,
                fileTypes: true
            });
    }
    return validation;
}

export function questionValidation(id: string): any {
    return cloneDeep({
        id,
        valid: true,
        answer: true,
        min: true,
        max: true,
        filesQuantity: true,
        fileTypes: true
    });
}

export function widgetValidation(id: string): any {
    return cloneDeep({
        id,
        result: true,
        weight: true,
        shoeSize: true,
        length: true,
        file: true,
        system: true,
        referredBy: true,
        values: [],
        treatment: {
            valid: true,
            diagnoses: []
        }
    });
}

export function setQuestionExpectedValues(question: MedicalQuestion): void {
    if (isNotDefined(question.givenAnswer)) question.givenAnswer = new MedicalResult();
    if (isNotDefined(question.givenAnswers)) question.givenAnswers = [];
}

export function cleanActionOrTherapyResultsAndAttributes(array: MedicalExaminationActionItem[] | MedicalTherapyPlanItem[] | MedicalTherapyExecutionItem[]): void {
    for (const item of array) {
        if (item.type === MedicalExaminationActionItemTypes.Category || item.type === MedicalTherapyPlanItemTypes.Category || item.type === MedicalTherapyExecutionItemTypes.Category)
            cleanActionOrTherapyResultsAndAttributes(item.category.stepsToTake);
        else {
            if (
                isDefined(item.step.result) && //
                isNotDefined(item.step.result.value) &&
                isNotDefined(item.step.result.date)
            )
                item.step.result = null;

            // Also clean the attributes of the step.
            // This is because we save temporary data in the attributes
            // which has no need to be saved in the database
            item.step.attributes = {};
        }
    }
}

export function cleanQuestionAnswers(question: MedicalQuestion): void {
    if (
        isDefined(question.givenAnswer) && //
        isNotDefined(question.givenAnswer.value) &&
        isNotDefined(question.givenAnswer.date)
    )
        question.givenAnswer = null;
}

export function setExpectedValuesForActionOrTherapy(array: MedicalExaminationActionItem[] | MedicalTherapyPlanItem[] | MedicalTherapyExecutionItem[]): any[] {
    for (const item of array) {
        if (item.type === MedicalExaminationActionItemTypes.Category || item.type === MedicalTherapyPlanItemTypes.Category || item.type === MedicalTherapyExecutionItemTypes.Category)
            setExpectedValuesForActionOrTherapy(item.category.stepsToTake);
        else {
            if (isNotDefined(item.step.results)) item.step.results = [];
            if (isNotDefined(item.step.result)) item.step.result = new MedicalResult();
        }
    }

    return array;
}

/**
 * A browser resize event listener function that sets new breakpoints in the local storage.
 * @param callback The callback that is fired when a new breakpoint is set.
 * @param reset Whether or not to force a reset of the current breakpoint value (defaults to false).
 */
export function onResize(callback: (breakpoint: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl') => void): void {
    const onBreakPointChanged = (breakpoint: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl') => {
        callback(breakpoint);
    };

    const width = window.innerWidth;
    // Note that this will only set a breakpoint when the correct breakpoint is yet set already.
    if (width < 640) onBreakPointChanged('xs');
    else if (width >= 640 && width < 768) onBreakPointChanged('sm');
    else if (width >= 768 && width < 1024) onBreakPointChanged('md');
    else if (width >= 1024 && width < 1280) onBreakPointChanged('lg');
    else if (width >= 1280 && width < 1536) onBreakPointChanged('xl');
    else if (width >= 1536) onBreakPointChanged('2xl');
}

export function checkForFilters(filters: any): boolean {
    return (
        (filters.practitioners?.any() ?? false) ||
        (filters.invoices?.any() ?? false) ||
        (filters.declarationSendMethods?.any() ?? false) ||
        (filters.declarations?.any() ?? false) ||
        (filters.patients?.any() ?? false) ||
        (filters.performanceStatuses?.any() ?? false) ||
        (filters.performanceTypes?.any() ?? false) ||
        (filters.insurers?.any() ?? false) ||
        isDefined(filters.start) ||
        isDefined(filters.end)
    );
}

export function findAndReplaceEmailTokens(tokens: GetEmailTemplateTokenResponse[], text: string, entity: any, type: EmailEntityTypes, language: string): string {
    for (const token of tokens) {
        if (isNotDefined(get(entity, token.property))) continue;
        if (token.type !== type) continue;

        switch (token.propertyType) {
            case EmailTemplateTokenPropertyTypes.Address:
                const addresses = get(entity, token.property) as Address[];
                const address = addresses.find((x) => x.isPrimary) || addresses[0];
                if (isDefined(address)) {
                    const formatted = `${address.street} ${address.houseNumber}${address.houseNumberSuffix || ''}, ${address.zipCode} ${address.city}`;
                    text = text.replaceAll(token.value, formatted);
                }
                break;
            case EmailTemplateTokenPropertyTypes.Phone:
                const phones = get(entity, token.property) as Phone[];
                const phone = phones.find((x) => x.isPrimary) || phones[0];
                if (isDefined(phone)) {
                    const formatted = `+${phone.callingCode}${phone.value}`;
                    text = text.replaceAll(token.value, formatted);
                }
                break;
            case EmailTemplateTokenPropertyTypes.Email:
                const emails = get(entity, token.property) as Email[];
                const email = emails.find((x) => x.isPrimary) || emails[0];
                if (isDefined(email)) text = text.replaceAll(token.value, email.value);
                break;
            case EmailTemplateTokenPropertyTypes.Date:
                const date = get(entity, token.property) as Date;
                text = text.replaceAll(token.value, format(date, 'EEEE dd MMMM yyyy', { locale: language === 'nl' ? nl : enUS }));
                break;
            case EmailTemplateTokenPropertyTypes.DateTime:
                const dateTime = get(entity, token.property) as Date;
                text = text.replaceAll(token.value, format(dateTime, 'dd-MM-yyyy HH:mm'));
                break;
            case EmailTemplateTokenPropertyTypes.Time:
                const time = get(entity, token.property) as Date;
                text = text.replaceAll(token.value, format(time, 'HH:mm'));
                break;
            case EmailTemplateTokenPropertyTypes.Translations:
                const translations = get(entity, token.property);
                text = text.replaceAll(token.value, translations[language]);
                break;
            case EmailTemplateTokenPropertyTypes.String:
            default:
                const value = get(entity, token.property);
                text = text.replaceAll(token.value, value);
                break;
        }
    }
    return text;
}

export function setWorkspace(workspace: string, location: string = ''): string {
    if (window.location.hostname === 'tst.wezorg.com') return 'demo-tst';
    if (window.location.hostname === 'acc.wezorg.com') return 'demo-acc';
    return workspace;
}

export function base64ToFile(base64: string, filename: string): File {
    const arr = base64.split(',');
    const mime = /:(.*?);/.exec(arr[0])[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) u8arr[n] = bstr.charCodeAt(n);
    return new File([u8arr], filename, { type: mime });
}

export function getFileTypeTranslation(name: string): string {
    switch (name) {
        case 'Text':
            return 'global.labels.type-of-files.text';
        case 'Excel':
            return 'global.labels.type-of-files.excel';
        case 'PDF':
            return 'global.labels.type-of-files.pdf';
        case 'Word':
            return 'global.labels.type-of-files.word';
        case 'Video':
            return 'global.labels.type-of-files.video';
        case 'Image':
            return 'global.labels.type-of-files.image';
    }
}

export function fileIsImage(contentType: string): boolean {
    const imageTypes = [
        'image/jpg', //
        'image/jpeg',
        'image/png'
    ];
    return imageTypes.some((x) => x === contentType);
}

export function defaultSchedulerSettings(): SchedulerSettings {
    return new SchedulerSettings({
        start: new SchedulerSettingsHour({
            hour: 8,
            minute: 0
        }),
        end: new SchedulerSettingsHour({
            hour: 20,
            minute: 0
        }),
        slotSize: 15,
        days: [0, 1, 2, 3, 4, 5, 6],
        minColumnWidth: 210,
        periodHeight: 100
    });
}
