import { isDefined, isNotDefined, isNotEmpty } from '@wecore/sdk-utilities';

import { bindable, inject } from 'aurelia';
import { UxEvents } from '../../infra/ux-events';
import { EventDetails } from '../../models/event-details';

@inject(Element)
export class UxTextarea {
    @bindable() public value: string;
    @bindable() public placeholder: string;
    @bindable() public cols: number;
    @bindable() public rows: number = 4;
    @bindable() public disabled: boolean = false;
    @bindable() public valid: boolean = true;
    @bindable() public debounce: number = 400;
    @bindable() public data: any;
    @bindable() public rounded: 'left' | 'right' | 'full' = 'full';
    @bindable() public allowClear: boolean = false;
    @bindable() public autofocus: boolean = false;
    @bindable() public focusDelay: number = 500;
    @bindable() public spellcheck: boolean = false;
    @bindable() public language: string = 'en';
    @bindable() public action: () => Promise<void>;

    public hasFocus: boolean = false;
    public textarea: HTMLTextAreaElement;
    private timeout: any;

    public constructor(
        private readonly host: HTMLElement //
    ) {}

    public bound() {
        if (this.autofocus && isDefined(this.textarea)) setTimeout(() => this.textarea.focus(), this.focusDelay);
        setTimeout(() => {
            // Wait for the textarea to loade before setting the height of the textarea.
            if (isNotEmpty(this.value) && isDefined(this.textarea)) {
                this.textarea.style.height = this.textarea.scrollHeight < 28 ? '28px' : `${this.textarea.scrollHeight}px`;
            }
        }, 1000);
    }

    public async focusElement(): Promise<void> {
        if (isDefined(this.textarea)) this.textarea.focus();
    }

    public valueChanged(): void {
        this.textarea.style.height = `${this.textarea.scrollHeight < 28 ? 28 : this.textarea.scrollHeight}px`;
    }

    public handleOnChange(e: KeyboardEvent): void {
        this.value = this.textarea.value;
        this.emit(
            UxEvents.OnChange,
            new EventDetails<UxTextarea, any>({
                element: this,
                innerEvent: e,
                data: this.data,
                values: {
                    value: this.textarea.value
                }
            })
        );
    }

    public handleOnInput(e: KeyboardEvent): void {
        this.value = this.textarea.value;

        // Wait a bit before actually fetching the data, maybe
        // the user is still typing a query so wait until he hasn't
        // typed for more than the milliseconds value of the debounce property.
        clearTimeout(this.timeout);
        this.timeout = setTimeout(async () => {
            this.emit(
                UxEvents.OnInput,
                new EventDetails<UxTextarea, any>({
                    element: this,
                    innerEvent: e,
                    data: this.data,
                    values: {
                        value: this.textarea?.value ?? this.value
                    }
                })
            );
        }, this.debounce);
    }

    public handleBlur(e: FocusEvent): void {
        this.hasFocus = false;
        this.emit(
            UxEvents.OnBlur,
            new EventDetails<UxTextarea, any>({
                element: this,
                innerEvent: e,
                data: this.data,
                values: {
                    value: this.value
                }
            })
        );
    }

    public handleFocus(e: FocusEvent): void {
        this.hasFocus = true;
        this.emit(
            UxEvents.OnFocus,
            new EventDetails<UxTextarea, any>({
                element: this,
                innerEvent: e,
                data: this.data,
                values: {
                    value: this.value
                }
            })
        );
    }

    public handleKeyUp(e: KeyboardEvent): void {
        this.emit(
            UxEvents.OnKeyup,
            new EventDetails<UxTextarea, any>({
                element: this,
                innerEvent: e,
                data: this.data,
                values: {
                    value: this.value,
                    data: this.data
                }
            })
        );
    }

    public handleKeyDown(e: KeyboardEvent): boolean {
        this.emit(
            UxEvents.OnKeydown,
            new EventDetails<UxTextarea, any>({
                element: this,
                innerEvent: e,
                data: this.data,
                values: {
                    value: this.value,
                    data: this.data
                }
            })
        );
        return true;
    }

    public handleClear(e: MouseEvent): void {
        e.preventDefault();
        const value = this.textarea.value;
        this.value = null;
        this.textarea.focus();

        this.emit(
            UxEvents.OnClear,
            new EventDetails<UxTextarea, any>({
                element: this,
                innerEvent: e,
                data: this.data,
                values: {
                    deletedValue: value
                }
            })
        );
    }

    public isDefined(value: any): boolean {
        return isDefined(value);
    }

    public isNotDefined(value: any): boolean {
        return isNotDefined(value);
    }

    private emit<T1, T2>(name: string, args: EventDetails<T1, T2>): void {
        this.host.dispatchEvent(
            new CustomEvent(name, {
                bubbles: true,
                detail: args
            })
        );
    }
}
