import { I18N } from '@aurelia/i18n';
import { GetUserResponse, UserRoles } from '@wecore/sdk-core';
import {
    SchedulerItemsApiClient,
    ExaminationRoom,
    GetSchedulerItemResponse,
    GetScheduleResponse,
    PracticeLocation,
    PracticeLocationsApiClient,
    ScheduleTimeSlot,
    SchedulesApiClient,
    UserEntityReference,
    SchedulerItemTypes,
    SchedulerContext
} from '@wecore/sdk-healthcare';
import { IPageState, isDefined, isFunction, isNotDefined } from '@wecore/sdk-utilities';

import { SchedulerSettings } from '@wecore/sdk-management';
import { IDisposable, IEventAggregator, bindable, containerless, inject } from 'aurelia';
import {
    addSeconds,
    areIntervalsOverlapping,
    differenceInSeconds,
    endOfDay,
    format,
    formatISO,
    getDay,
    getHours,
    getMinutes,
    intervalToDuration,
    isValid,
    secondsToMinutes,
    setHours,
    setMinutes,
    startOfDay
} from 'date-fns';
import { FormatTimeValueConverter } from '../../converters/format-time';
import { Broadcasts } from '../../infra/broadcasts/broadcasts';
import { ErrorHandler } from '../../infra/error-handler';
import { CustomEvents } from '../../infra/events';
import { cloneDeep, dayAndTimeToDate, getSchedulerColumnHeight, getTotalAmountOfSlots, toDayOfWeek } from '../../infra/utilities';
import { ConfirmationOptions } from '../../models/confirmation-options';
import { SchedulerPeriod } from '../../models/scheduler-period';
import { SchedulerRoomWrapper } from '../../models/scheduler-room-wrapper';
import { SchedulerState } from '../../models/scheduler-state';
import { ModalService } from '../../services/service.modals';
import { NotificationService } from '../../services/service.notifications';
import { is } from 'date-fns/locale';

@containerless()
@inject(IEventAggregator, SchedulerItemsApiClient, ModalService, I18N, SchedulesApiClient, PracticeLocationsApiClient, NotificationService, ErrorHandler)
export class BxSchedulerColumn {
    @bindable() public date: Date;
    @bindable() public settings: SchedulerSettings;
    @bindable() public periods: SchedulerPeriod[];
    @bindable() public columns: any;
    @bindable() public parentIndex: number;
    @bindable() public index: number;
    @bindable() public user: GetUserResponse;
    @bindable() public wrapper: SchedulerRoomWrapper;
    @bindable() public hasUserFilter: boolean = false;
    @bindable() public state: SchedulerState;
    @bindable() public workspace: string;
    @bindable() public language: string;
    @bindable() public hasRole: (role: UserRoles) => boolean;
    @bindable() public pageState: IPageState;
    @bindable() public onSchedulerItemClick: (schedulerItem: GetSchedulerItemResponse) => Promise<{ success: boolean; schedulerItem: GetSchedulerItemResponse }>;
    @bindable() public onSchedulerItemCreate: (start: Date, end: Date, userId: string, roomId: string, locationId: string) => Promise<{ success: boolean; schedulerItem: GetSchedulerItemResponse }>;
    @bindable() public onSchedulerItemEdit: (schedulerItem: GetSchedulerItemResponse) => Promise<{ success: boolean; schedulerItem: GetSchedulerItemResponse }>;
    @bindable() public onSchedulerItemDelete: (schedulerItem: GetSchedulerItemResponse) => Promise<{ success: boolean }>;
    @bindable() public onSchedulerItemMove: (schedulerItem: GetSchedulerItemResponse, newStart: Date, newEnd: Date) => Promise<{ success: boolean; schedulerItem: GetSchedulerItemResponse }>;
    @bindable() public onSchedulerItemResize: (schedulerItem: GetSchedulerItemResponse, newStart: Date, newEnd: Date) => Promise<{ success: boolean; schedulerItem: GetSchedulerItemResponse }>;
    @bindable() public onSchedulerItemDetails: (schedulerItem: GetSchedulerItemResponse) => Promise<{ success: boolean }>;
    @bindable() public onPatientCard: (schedulerItem: GetSchedulerItemResponse) => Promise<{ success: boolean }>;
    @bindable() public onConfirmSchedulerItem: (schedulerItem: GetSchedulerItemResponse) => Promise<{ success: boolean }>;
    @bindable() public onMarkNoShow: (schedulerItem: GetSchedulerItemResponse) => Promise<{ success: boolean }>;
    @bindable() public onUnmarkNoShow: (schedulerItem: GetSchedulerItemResponse) => Promise<{ success: boolean }>;
    @bindable() public onMarkCancelled: (schedulerItem: GetSchedulerItemResponse) => Promise<{ success: boolean }>;
    @bindable() public onUnmarkCancelled: (schedulerItem: GetSchedulerItemResponse) => Promise<{ success: boolean }>;

    public day: number;
    public schedulerItems: GetSchedulerItemResponse[] = [];
    public layers: { [key: string]: { zIndex: number } } = {};
    public column: HTMLDivElement;
    public renderContainer: HTMLDivElement;
    public height: number;
    public baseLoaded: boolean = false;
    public loader: { amount: number; top: number[]; height: number[] };
    public UserRoles: typeof UserRoles = UserRoles;

    public schedules: {
        schedule: GetScheduleResponse;
        slotsAndRooms: {
            slot: ScheduleTimeSlot;
            room: ExaminationRoom;
        }[];
    }[];

    private gridSnap: number;
    private originalHeight: number;
    private originalY: number;
    private originalTop: number;
    private differenceBetweenMouseAndTopOfClone: number;
    private activeContainer: HTMLDivElement;
    private activeIndex: number;
    private activeParentIndex: number;
    private dragCb: (e: MouseEvent) => void;
    private dragEndCb: (e: MouseEvent) => void;
    private dragKeyCb: (e: KeyboardEvent) => void;
    private hoverCb: (e: MouseEvent) => void;
    private contextMenuCb: (e: MouseEvent) => void;
    private subscriptions: IDisposable[] = [];
    private context: SchedulerContext;

    public constructor(
        private readonly events: IEventAggregator, //
        private readonly itemsApi: SchedulerItemsApiClient,
        private readonly modalService: ModalService,
        private readonly t: I18N,
        private readonly schedulesApi: SchedulesApiClient,
        private readonly locationsApi: PracticeLocationsApiClient,
        private readonly notifications: NotificationService,
        private readonly errorHandler: ErrorHandler
    ) {}

    public bound(): void {
        this.day = getDay(this.date);
        this.height = getSchedulerColumnHeight(this.settings, this.periods, this.settings.periodHeight);
        this.column.style.height = `${this.height}px`;

        const totalSlots = getTotalAmountOfSlots(this.date, this.settings);
        this.gridSnap = 100 / totalSlots;
        this.context = isDefined(this.wrapper) ? SchedulerContext.Room : SchedulerContext.User;

        this.subscriptions = [
            ...(this.subscriptions ?? []),
            this.events.subscribe(
                CustomEvents.SchedulerItemsDeleted,
                (data: {
                    columnDate: Date; //
                    schedulerItem: GetSchedulerItemResponse;
                }) => {
                    // If this is not the column that the scheduler item belongs to, don't do anything.
                    // Otherwise the scheduler item will be rendered on all columns.
                    if (format(data.columnDate, 'yyyyMMdd') !== format(this.date, 'yyyyMMdd')) return;

                    // If the scheduler item is deleted, remove it from the list.
                    const index = this.schedulerItems.findIndex((x) => x.id === data.schedulerItem.id);
                    if (index === -1) return;

                    this.schedulerItems.splice(index, 1);
                    this.render(this.schedulerItems);
                    this.events.publish(CustomEvents.SchedulerItemsRefresh, data);
                }
            ),
            this.events.subscribe(Broadcasts.SchedulerItemCreated, (data: { schedulerItem: GetSchedulerItemResponse }) => {
                // Only add the scheduler item to the column if the scheduler item is created on the same day as the column.
                if (format(this.date, 'yyyyMMdd') !== format(data.schedulerItem.start, 'yyyyMMdd')) return;

                const addSchedulerItem = () => {
                    if (this.schedulerItems.findIndex((x) => x.id === data.schedulerItem.id) === -1) {
                        const schedulerItems = [...this.schedulerItems, data.schedulerItem];
                        this.render(schedulerItems);
                        this.events.publish(CustomEvents.SchedulerItemsRefresh, data);
                    }
                };

                if (isDefined(this.wrapper)) {
                    // Room mode.
                    if (this.wrapper.room.id === data.schedulerItem.examinationRoom.id) {
                        addSchedulerItem();
                    }
                } else {
                    // User mode.
                    if (this.hasUserFilter) {
                        // With mulitple users filtered.
                        if (this.user.id === data.schedulerItem.practitioner.id) {
                            if (format(this.date, 'yyyyMMdd') === format(data.schedulerItem.start, 'yyyyMMdd')) {
                                addSchedulerItem();
                            }
                        }
                    } else {
                        // With the authenticated user.
                        if (format(this.date, 'yyyyMMdd') === format(data.schedulerItem.start, 'yyyyMMdd')) {
                            addSchedulerItem();
                        }
                    }
                }
            }),
            this.events.subscribe(
                Broadcasts.SchedulerItemUpdated,
                (data: {
                    schedulerItem: GetSchedulerItemResponse; //
                    oldStart: Date;
                    oldEnd: Date;
                    oldRoom: ExaminationRoom;
                    oldPractitioner: UserEntityReference;
                }) => {
                    // Because the DRAG-END and RESIZE-END events also change the DOM elements
                    // of the scheduler items we need to wait a bit before we manipulate the DOM
                    // in the autosync event. Otherwise the DOM elements might be in a race condition.
                    // and show the scheduler items in a wrong state.
                    // For example if you move an scheduler item to another column, the scheduler item
                    // will be removed from the DOM and added to the new column. But if the autosync
                    // event is fired before the DOM elements are updated, the scheduler item will be
                    // added to the old column again.
                    setTimeout(() => {
                        const reAddSchedulerItem = () => {
                            // If the scheduler item is cancelled and the cancelled filter is off, don't show the scheduler item.
                            if (!this.pageState.values.showCancelled && data.schedulerItem.status === 'Cancelled') return;

                            // If this is not the column that the scheduler item belongs to, don't do anything.
                            // Otherwise the scheduler item will be rendered on all columns.
                            if (
                                format(data.schedulerItem.start, 'yyyyMMdd') !== format(this.date, 'yyyyMMdd') && //
                                format(data.schedulerItem.end, 'yyyyMMdd') !== format(this.date, 'yyyyMMdd')
                            )
                                return;

                            // Check if the scheduler item belongs to the correct column of its
                            // user or room.
                            if (
                                (isDefined(this.wrapper) && data.schedulerItem.examinationRoom.id !== this.wrapper.room.id) ||
                                (!isDefined(this.wrapper) && data.schedulerItem.practitioner.id !== this.user.id)
                            )
                                return;

                            if (this.schedulerItems.findIndex((x) => x.id === data.schedulerItem.id) === -1) {
                                const schedulerItems = [...this.schedulerItems, data.schedulerItem];
                                this.render(schedulerItems);
                                this.events.publish(CustomEvents.SchedulerItemsRefresh, data);
                            }
                        };

                        // Always remove the old scheduler item from any column.
                        const index = this.schedulerItems.findIndex((x) => x.id === data.schedulerItem.id);
                        if (index > -1) {
                            // Make sure the DOM element is removed also
                            document.getElementById(`scheduler-item-${data.schedulerItem.id}`)?.remove();

                            const schedulerItems = [...this.schedulerItems];
                            schedulerItems.splice(index, 1);
                            this.render(schedulerItems);
                        }

                        // Lets re-add the scheduler item to the column if it matches the filter.
                        if (isDefined(this.wrapper)) {
                            // Room mode.
                            if (data.schedulerItem.examinationRoom.id !== data.oldRoom.id) {
                                // The scheduler item has moved to a different room.
                                if (this.wrapper.room.id === data.schedulerItem.examinationRoom.id) {
                                    // The scheduler item is re-added to the new room.
                                    reAddSchedulerItem();
                                }
                            } else {
                                // The scheduler item has not moved to a different room.
                                if (this.wrapper.room.id === data.oldRoom.id) {
                                    // The scheduler item is re-added to the same room.
                                    reAddSchedulerItem();
                                }
                            }
                        } else {
                            // User mode.
                            if (this.hasUserFilter) {
                                // With multiple users filtered.
                                if (data.schedulerItem.practitioner.id != data.oldPractitioner.id) {
                                    // The scheduler item has moved to a different practitioner.
                                    if (this.user.id === data.schedulerItem.practitioner.id) {
                                        reAddSchedulerItem();
                                    }
                                } else {
                                    // The scheduler item is not moved to a different practitioner.
                                    // Check if the scheduler item is moved to a different day.
                                    if (format(data.schedulerItem.start, 'yyyyMMdd') !== format(data.oldStart, 'yyyyMMdd')) {
                                        // The scheduler item is moved to a different day.
                                        if (format(this.date, 'yyyyMMdd') === format(data.schedulerItem.start, 'yyyyMMdd')) {
                                            // Add the scheduler item to the column if the date matches the scheduler item date.
                                            reAddSchedulerItem();
                                        }
                                    } else {
                                        // The scheduler item has not moved to a different day.
                                        if (this.user.id === data.schedulerItem.practitioner.id) {
                                            // The column user matches the scheduler item user.
                                            reAddSchedulerItem();
                                        }
                                    }
                                }
                            } else {
                                // With the authenticated user.
                                if (format(this.date, 'yyyyMMdd') === format(data.schedulerItem.start, 'yyyyMMdd')) {
                                    reAddSchedulerItem();
                                }
                            }
                        }
                    }, 150);
                }
            ),
            this.events.subscribe(Broadcasts.SchedulerItemDeleted, (data: { schedulerItem: GetSchedulerItemResponse }) => {
                // If the scheduler item is deleted, remove it from the list.
                const index = this.schedulerItems.findIndex((x) => x.id === data.schedulerItem.id);
                if (index === -1) return;

                this.schedulerItems.splice(index, 1);
                this.render(this.schedulerItems);
                this.events.publish(CustomEvents.SchedulerItemsRefresh, data);
            })
            // this.events.subscribe(CustomEvents.SchedulerColumnsModeChanged, (mode: 'all' | 'schedules') => {
            // this.pageState.values.days[this.day].visible = mode === 'all' ? true : this.schedules.any();
            // })
        ];
    }

    public async attached(): Promise<void> {
        this.init();
        this.setBaseLoaders();

        this.hoverCb = (e) => this.handleHover(e);
        this.column.addEventListener('mousemove', this.hoverCb);
    }

    public async refresh(): Promise<void> {
        await this.init();
    }

    public detaching(): void {
        this.subscriptions.forEach((s) => s.dispose());
        document.removeEventListener('mousemove', this.hoverCb);
        document.removeEventListener('contextmenu', this.contextMenuCb);
    }

    public handleHover(e: MouseEvent): void {
        // Remove any other element hover element.
        document.getElementById('hover-scheduler-item')?.remove();

        // Don't render the hover scheduler item when dragging.
        if (
            this.state.isColumnDragging || //
            this.state.isItemDragging ||
            this.state.isItemResizing ||
            this.state.isSaving ||
            this.state.isEditing ||
            !this.baseLoaded
        )
            return;

        let container = document.elementFromPoint(e.clientX, e.clientY);
        if (isNotDefined(container)) return;

        // Lets check if we're hovering over a column.
        if (!container.id.includes('render-area')) {
            // If not, it could be that we're hovering above a schedule.
            // so lets find the closest schedule.
            const schedule = container.closest('[data-type="schedule"]');
            // If no scehdule is found, we're not hovering above a column.
            if (isNotDefined(schedule)) return;

            // Always make sure the column is the render area.
            container = this.column.querySelector('[data-type="render-area"]');
        }
        // Get the position of the column.
        const pos = container.getBoundingClientRect();
        // Get mouse position relative to the column.
        let y = e.clientY - pos.top - 5;
        if (y < 0) y = 0;
        if (y > this.height) y = this.height;
        const { element, start, end } = this.createSchedulerItemElement('hover-scheduler-item', y);

        element.classList.add('opacity-50');
        element.classList.add('bg-gray-200');
        element.style.zIndex = '10000';

        this.contextMenuCb = (e: MouseEvent) => {
            const target = e.target as HTMLElement;
            // Make sure not to interfere with the context menu of the scheduler item.
            const item = target.closest('[data-type="scheduler-item"]');
            if (isDefined(item)) return;

            // Only show the context menu when the user right clicks on the render area.
            const renderArea = target.closest('[data-type="render-area"]');
            if (isNotDefined(renderArea)) return;

            if (isNotDefined(this.column)) return;

            e.preventDefault();

            const clones = document.querySelectorAll('[data-type="context-menu-clone"]');
            clones.forEach((c) => c.remove());

            const doc = document.querySelector('[data-type="app"]');
            const menu = this.column.querySelector('[data-type="column-context-menu"]') as HTMLDivElement;

            const clone = menu.cloneNode(true) as HTMLDivElement;
            clone.classList.remove('hidden');
            clone.setAttribute('data-type', 'context-menu-clone');

            // Fetch the width and height of the window
            const windowWidth = window.innerWidth;
            const windowHeight = window.innerHeight;

            // Fetch the width and height of the menu
            doc.appendChild(clone);
            const menuWidth = clone.offsetWidth;
            const menuHeight = clone.offsetHeight;
            clone.remove();

            // Get the current scroll position of the window
            const scrollX = window.pageXOffset;
            const scrollY = window.pageYOffset;

            // Set the initial position of the menu
            let left = isDefined((e.detail as any).clientX) ? (e.detail as any).clientX : e.clientX + scrollX;
            let top = isDefined((e.detail as any).clientY) ? (e.detail as any).clientY : e.clientY + scrollY;

            // Adjust the position if the menu goes beyond the right edge of the window
            if (left + menuWidth > windowWidth + scrollX) {
                left = windowWidth + scrollX - menuWidth;
            }

            // Adjust the position if the menu goes beyond the bottom edge of the window
            // Add a 5px bottom margin
            if (top + menuHeight > windowHeight + scrollY) {
                top = windowHeight + scrollY - menuHeight - 5;
            }

            // Ensure the menu does not go beyond the top or left edge of the window
            if (left < scrollX) {
                left = scrollX;
            }
            if (top < scrollY) {
                top = scrollY;
            }

            clone.style.left = `${left}px`;
            clone.style.top = `${top}px`;
            clone.style.zIndex = '1000';

            const createBtn = clone.querySelector('[data-function="create"]');
            createBtn.addEventListener('mousedown', async () => {
                const userId = isNotDefined(this.wrapper) ? this.user.id : null;
                const roomId = isDefined(this.wrapper) ? this.wrapper.room.id : null;
                const locationId = isDefined(this.wrapper) ? this.wrapper.location.id : null;
                const { success } = await this.onSchedulerItemCreate(start, end, userId, roomId, locationId);
                if (success) this.init();
            });

            const pastBtn = clone.querySelector('[data-function="paste"]');
            if (isDefined(this.state.cut)) {
                pastBtn.addEventListener('mousedown', () => this.handlePaste(start));
            } else pastBtn.remove();

            // const cutBtn = clone.querySelector('[data-function="cut"]');
            // cutBtn.addEventListener('mousedown', () => this.cut());

            this.setSlotTime(clone, start);
            doc.appendChild(clone);
        };

        if (this.state.usingTouchDevice) {
            element.setAttribute('data-long-press-delay', '400');
            element.addEventListener('long-press', (e) => {
                this.contextMenuCb(e as MouseEvent);
            });
        }

        document.addEventListener('contextmenu', (e) => this.contextMenuCb(e));

        // Set the slot time.
        this.setSlotTime(element, start);
        container.appendChild(element);
    }

    public hideHover(_: MouseEvent): void {
        // Remove hover element
        document.getElementById('hover-scheduler-item')?.remove();
    }

    public handleDragStart(e: MouseEvent): void {
        if (
            // Only left click.
            e.button !== 0 || //
            // No new ones while saving
            this.state.isSaving ||
            this.state.isEditing ||
            !this.baseLoaded
        )
            return;

        // If the user is dragging an scheduler item, don't do anything.
        let el = document.elementFromPoint(e.clientX, e.clientY);

        const schedulerItem = el.closest('[data-type="scheduler-item"]');
        if (isDefined(schedulerItem)) return;

        // We must distinguish between a click and a drag.
        // Record the initial mouse position.
        const startX = e.clientX;
        const startY = e.clientY;
        const handleMouseMove = async (event: MouseEvent): Promise<void> => {
            // Calculate the current mouse position.
            const currentX = event.clientX;
            const currentY = event.clientY;
            // Check if the mouse has moved more than 5 pixels in either direction
            if (Math.abs(currentX - startX) > 5 || Math.abs(currentY - startY) > 5) {
                this.state.isColumnDragging = true;
                // Remove the event listener that listens for the initial mouse down event.
                // Otherwise the event listener will be called multiple times.
                document.removeEventListener('mousemove', handleMouseMove);

                // Get the position of the column.
                const pos = this.column.getBoundingClientRect();
                // Get mouse position relative to the column.
                const y = e.clientY - pos.top;
                // Create container.
                const { element: container, start, end } = this.createSchedulerItemElement('new-scheduler-item', y);
                container.classList.add('bg-gray-100');
                this.renderContainer.appendChild(container);
                // Set the slot time.
                this.setSlotTime(container, start, end);
                // Save the callback otherwise removeEventListener won't work.
                this.dragCb = (e) => this.handleDrag(e);
                this.dragEndCb = (e) => this.handleDragEnd(e);
                this.dragKeyCb = (e) => this.handleDragKeyEvent(e);

                document.addEventListener('mousemove', this.dragCb);
                document.addEventListener('mouseup', this.dragEndCb, { once: true });
                document.addEventListener('keydown', this.dragKeyCb, { once: true });
            }
        };

        const handleMouseUp = async () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);

            // Check if there is already being a drag in the column
            // Otherwise the column is clicked.
            if (this.state.isColumnDragging) return;

            // !! Code here for a click !!
        };

        // Lets listen for the mouse move event.
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    }

    public handleSchedulerItemDragStart = async (e: MouseEvent, schedulerItem: GetSchedulerItemResponse, el: HTMLDivElement): Promise<void> => {
        if (schedulerItem.status === 'Cancelled' || schedulerItem.status === 'NoShow' || schedulerItem.historic) return;

        // Disabled text selected during dragging.
        document.body.classList.add('select-none');
        // Prevent other events from firing. E.g. the drag and select events.
        e.preventDefault();
        // Clone the scheduler item element.
        const clone = el.cloneNode(true) as HTMLDivElement;
        clone.id = `clone-${schedulerItem.id}`;
        // Make sure the clone is always on top.
        clone.style.width = '100%';
        clone.style.zIndex = '10000';
        clone.style.left = '0px';
        // Set the initial active container and index.
        this.activeContainer = this.renderContainer;
        this.activeParentIndex = this.parentIndex;
        this.activeIndex = this.index;
        // Add the clone to the active container.
        this.activeContainer.appendChild(clone);
    };

    public handleSchedulerItemDrag = (e: MouseEvent, schedulerItem: GetSchedulerItemResponse): Promise<void> => {
        // Find the clone.
        const clone = document.querySelector(`#clone-${schedulerItem.id}`) as HTMLDivElement;
        if (isNotDefined(clone)) return;
        // If the user is dragging an scheduler item, don't do anything.
        let el = document.elementFromPoint(e.clientX, e.clientY);
        // Make sure were above a render area before moving the scheduler item
        // do a different column to prevent the scheduler item flickering
        // between different columns.
        const renderArea = el?.closest('[data-type="render-area"]');
        if (isNotDefined(renderArea)) return;
        // Get the position of the active container.
        let pos = this.activeContainer.getBoundingClientRect();
        // Check if the user is dragging the scheduler item to the left or right side of the container.
        const isLeft = e.clientX < pos.x;
        const isRight = e.clientX > pos.x + pos.width;

        // When the user is dragging the scheduler item to the left or right side of the container
        // This can only be done when no user columns are rendered.
        if (isLeft || isRight) {
            // Decide if the user is dragging to the left or right.
            if (isLeft) {
                // Go to the previous column
                this.activeIndex--; // Decrease the current active index to move to the previous column.
                if (this.activeIndex < 0) {
                    // If the index goes below 0, move to the previous parent index (the previous day).
                    this.activeParentIndex--; // Move to the previous day.
                    if (this.activeParentIndex < 0) {
                        // Ensure we don't go beyond the first day.
                        this.activeParentIndex = 0; // Reset the parent index to 0 if it goes below 0.
                    }
                    // Set the active index to the last column of the new parent index (day).
                    this.activeIndex = this.columns[this.activeParentIndex].length - 1;
                }
            } else {
                // Go to the next column
                this.activeIndex++; // Increase the current active index to move to the next column.
                // Check if the active index exceeds the length of columns in the current day,
                if (this.activeIndex > this.columns[this.activeParentIndex].length) {
                    // In that case, move to the next parent index (the next day).
                    this.activeParentIndex++;
                    // Ensure we don't go beyond the last day.
                    if (this.activeParentIndex >= this.settings.days.length) {
                        // Set the parent index to the last day.
                        this.activeParentIndex = this.settings.days.length - 1;
                        // Set the active index to the last column of the current day (the last day).
                        this.activeIndex = this.columns[this.activeParentIndex].length - 1;
                    } else {
                        // Set the active index to the first column of the new day.
                        this.activeIndex = 0;
                    }
                }
            }

            // Note that hidden containers are rendered but with an addition 'insvisible-'
            // to the render area ID. So in that case this below will not be found.
            const container = document.getElementById(`render-area-${this.activeParentIndex}-${this.activeIndex}`) as HTMLDivElement;
            if (isDefined(container)) {
                // We have a new container. Remove the clone from the old container.
                this.activeContainer.querySelector(`#clone-${schedulerItem.id}`)?.remove();
                // Override the active container and position and add the clone to the new container.
                this.activeContainer = container;
                this.activeContainer.appendChild(clone);
                pos = container.getBoundingClientRect();
            }
        }

        // Just move the scheduler item up and down.
        const { percentage } = this.setPosition(this.activeContainer, clone, e.clientY);
        const { hours, minutes } = this.getTimeFromGridPosition(percentage);
        // Set the new start and end date.
        // Note the conversion from seconds to milliseconds.
        const duration = intervalToDuration({ start: 0, end: schedulerItem.duration * 1000 });
        const newStart = setMinutes(setHours(this.date, hours), minutes);
        const newEnd = setMinutes(setHours(this.date, hours + duration.hours), minutes + duration.minutes);
        // Update the time slot.
        this.setSlotTime(clone, newStart, newEnd, clone.clientHeight);
    };

    public handleSchedulerItemDragEnd = async (_: MouseEvent, schedulerItem: GetSchedulerItemResponse): Promise<void> => {
        // Enable text selection again.
        document.body.classList.remove('select-none');
        const originalStart = schedulerItem.start;
        const originalEnd = schedulerItem.end;

        const original = document.querySelector(`#scheduler-item-${schedulerItem.id}`) as HTMLDivElement;
        const clone = document.querySelector(`#clone-${schedulerItem.id}`) as HTMLDivElement;
        if (isNotDefined(clone)) return;
        this.setLoading(original, true);
        // Get the date and update the slot in the original.
        const start = new Date(clone.getAttribute('data-start'));
        const end = new Date(clone.getAttribute('data-end'));

        const render = () => {
            this.schedulerItems.sort(this.sortByStartDateAndDuration);
            this.render(this.schedulerItems);
            // Let the scheduler item know that the scheduler item has changed
            // and they need to re-render.
            this.events.publish(CustomEvents.SchedulerItemsDragged, { columnDate: this.date, schedulerItem });
        };

        // When the user moved the scheduler item to another column.
        if (this.parentIndex !== this.activeParentIndex || this.index !== this.activeIndex) {
            // Copy the original scheduler item.
            const copy = GetSchedulerItemResponse.fromJS(cloneDeep(schedulerItem));
            // Update the start and end date with the new column date.
            const columnDate = this.columns[this.activeParentIndex][this.activeIndex].date;
            // Get the hours and minutes from the originals start and end dates.
            const startData = { hours: getHours(start), minutes: getMinutes(start) };
            const endData = { hours: getHours(end), minutes: getMinutes(end) };
            // Set the new start and end date by using the column date and the hours and minutes from the original scheduler item.
            copy.start = setMinutes(setHours(columnDate, startData.hours), startData.minutes);
            copy.end = setMinutes(setHours(columnDate, endData.hours), endData.minutes);

            // Get the timezone offset of the browser
            // NOTE: Get the timezone offset of the scheduler item date
            // and not the current date because the scheduler item date
            // can be in a different timezone that the current date.
            const timezoneOffset = copy.start.getTimezoneOffset();

            // We are booking for a room instead of a user.
            if (isDefined(this.wrapper)) {
                const wrapper = this.columns[this.activeParentIndex][this.activeIndex].wrapper;

                const response = await this.schedulesApi.getByRoomAndTimestamps(this.workspace, wrapper.room.id, copy.start, copy.end, timezoneOffset);
                let location: PracticeLocation;

                // If no schedule is found, we can't continue with updating.
                // Otherwise we don't have a location to update the scheduler item.
                // This is also true for the type 'Block' because a scheduler item
                // always need a practitioner and a location/room.
                if (isNotDefined(response.schedule)) {
                    this.notifications.show(
                        this.t.tr('translation:partial-views.scheduler.notifications.update-scheduler-item-failed.title'), //
                        this.t.tr(`translation:partial-views.scheduler-items.messages.error-AP403`),
                        {
                            type: 'warning',
                            duration: 10000
                        }
                    );
                    this.state.isSaving = false;
                    this.setLoading(original, false);
                    clone.remove();
                    return;
                }

                // Fetch full location if we found the schedule.
                location = await this.locationsApi.getById(response.schedule.location.id, this.workspace);
                if (copy.type === SchedulerItemTypes.Appointment) copy.createOutsideSchedule = false;

                // Update the scheduler item data with as fallback the original data.
                // If we couldn't find a schedule the update after should fail also
                // because that operation also looks for the schedule.
                copy.location = location ?? copy.location;
                copy.schedule = response.schedule ?? copy.schedule;
                copy.examinationRoom = wrapper?.room ?? copy.examinationRoom;
                copy.practitioner = response.user ?? copy.practitioner;
            } else {
                // We moved to a different column. Find the user of that column and
                // 1) Update the schedule.
                // 2) Update the examination room.
                // 2) Update the location.
                // 3) Update the practitioner.
                const user = this.hasUserFilter ? this.columns[this.activeParentIndex][this.activeIndex].user : this.user;
                const response = await this.schedulesApi.getByPractitionerAndTimestamps(this.workspace, user.id, copy.start, copy.end, timezoneOffset);

                if (copy.type === SchedulerItemTypes.Appointment) {
                    let location: PracticeLocation;

                    // If no schedule is found, we can't continue with updating.
                    // Otherwise we don't have a location to update the scheduler item.
                    // This is also true for the type 'Block' because a scheduler item
                    // always need a practitioner and a location/room.
                    if (isNotDefined(response.schedule)) {
                        this.notifications.show(
                            this.t.tr('translation:partial-views.scheduler.notifications.update-scheduler-item-failed.title'), //
                            this.t.tr(`translation:partial-views.scheduler-items.messages.error-AP403`),
                            {
                                type: 'warning',
                                duration: 10000
                            }
                        );
                        this.state.isSaving = false;
                        clone.remove();
                        this.setLoading(original, false);
                        return;
                    }

                    // Fetch full location if we found the schedule.
                    location = await this.locationsApi.getById(response.schedule.location.id, this.workspace);
                    copy.createOutsideSchedule = false;

                    // Update the scheduler item data with as fallback the original data.
                    // If we couldn't find a schedule the update after should fail also
                    // because that operation also looks for the schedule.
                    copy.location = location ?? copy.location;
                    copy.schedule = response.schedule ?? copy.schedule;
                    copy.examinationRoom = response.room ?? copy.examinationRoom;
                }

                copy.practitioner = isDefined(user)
                    ? new UserEntityReference({
                          id: user.id,
                          name: user.displayName
                      })
                    : copy.practitioner;
            }

            const doIt = async () => {
                // Update the scheduler item
                const { success } = await this.updateSchedulerItem('move', copy, clone);
                if (!success) {
                    this.setLoading(original, false);
                    // Make sure the original time slots are restored
                    copy.start = originalStart;
                    copy.end = originalEnd;
                    copy.duration = differenceInSeconds(originalEnd, originalStart);
                    this.setSlotTime(original, originalStart, originalEnd, original.clientHeight);
                    clone.remove();
                    return;
                }

                // Add the scheduler item to the new list.
                const schedulerItems = [...this.columns[this.activeParentIndex][this.activeIndex].schedulerItems, copy];
                this.columns[this.activeParentIndex][this.activeIndex].render(schedulerItems);
                this.columns[this.activeParentIndex][this.activeIndex].schedulerItems = schedulerItems;
                // Remove original scheduler item from the list.
                const index = this.schedulerItems.findIndex((x) => x.id === schedulerItem.id);
                if (index > -1) this.schedulerItems.splice(index, 1);

                // Remove the clone.
                clone.remove();

                render();
            };

            if (
                (this.hasUserFilter && isDefined(this.user) && copy.practitioner.id !== this.user.id) || //
                (isDefined(this.wrapper) && copy.examinationRoom.id !== this.wrapper.room.id)
            ) {
                const title = isDefined(this.user)
                    ? this.t.tr('translation:partial-views.scheduler.questions.change-practitioner.title')
                    : this.t.tr('translation:partial-views.scheduler.questions.change-room.title');

                const message = isDefined(this.user)
                    ? this.t
                          .tr('translation:partial-views.scheduler.questions.change-practitioner.message') //
                          .replace('{user1}', `<strong>${this.user.displayName}</strong>`)
                          .replace('{user2}', `<strong>${copy.practitioner.name}</strong>`)
                    : this.t
                          .tr('translation:partial-views.scheduler.questions.change-room.message') //
                          .replace('{room1}', `<strong>${this.wrapper.room.name[this.language]} (${this.wrapper.location.name[this.language]})</strong>`)
                          .replace('{room2}', `<strong>${copy.examinationRoom.name[this.language]} (${copy.location.name[this.language]})</strong>`);

                await this.modalService.confirm(
                    new ConfirmationOptions({
                        title,
                        message,
                        type: 'warning',
                        btnOk: this.t.tr('translation:global.buttons.continue'),
                        callback: async (confirmed: boolean): Promise<void> => {
                            if (confirmed) await doIt();
                            else {
                                clone.remove();
                                render();
                            }
                        }
                    })
                );
            } else
                await this.modalService.confirm(
                    new ConfirmationOptions({
                        title: this.t.tr('translation:partial-views.scheduler.questions.confirm-edit.title'),
                        message: this.t.tr('translation:partial-views.scheduler.questions.confirm-edit.message'),
                        type: 'warning',
                        btnOk: this.t.tr('translation:global.buttons.continue'),
                        callback: async (confirmed: boolean): Promise<void> => {
                            if (confirmed) await doIt();
                            else {
                                clone.remove();
                                render();
                            }
                        }
                    })
                );
        } else {
            await this.modalService.confirm(
                new ConfirmationOptions({
                    title: this.t.tr('translation:partial-views.scheduler.questions.confirm-edit.title'),
                    message: this.t.tr('translation:partial-views.scheduler.questions.confirm-edit.message'),
                    type: 'warning',
                    btnOk: this.t.tr('translation:global.buttons.continue'),
                    callback: async (confirmed: boolean): Promise<void> => {
                        if (confirmed) {
                            const index = this.schedulerItems.findIndex((x) => x.id === schedulerItem.id);
                            this.schedulerItems[index].start = start;
                            this.schedulerItems[index].end = end;

                            // Update the scheduler item
                            const { success } = await this.updateSchedulerItem('move', schedulerItem, clone);
                            if (!success) {
                                this.setLoading(original, false);
                                // Make sure the original time slots are restored.
                                this.schedulerItems[index].start = originalStart;
                                this.schedulerItems[index].end = originalEnd;
                                this.setSlotTime(original, originalStart, originalEnd, original.clientHeight);
                                clone.remove();
                                return;
                            }
                            // Because the clone doesn't have the required event listeners we copy
                            // the position of the clone to the original.
                            original.style.top = clone.style.top;
                            original.style.left = clone.style.left;
                            original.style.width = clone.style.width;
                            // Update the slot time.
                            this.setSlotTime(original, start, end, original.clientHeight);
                            // Remove the clone
                            clone.remove();
                            // Reset top difference for the next scheduler item.
                            this.differenceBetweenMouseAndTopOfClone = null;
                            this.setLoading(original, false);
                        } else clone.remove();

                        render();
                    }
                })
            );
        }
    };

    public handleSchedulerItemDragCancelled = async (e: KeyboardEvent, schedulerItem: GetSchedulerItemResponse): Promise<void> => {
        if (e.key === 'Escape') {
            // Enable text selection again.
            document.body.classList.remove('select-none');
            this.state.isItemDragging = false;
            document.querySelector(`#clone-${schedulerItem.id}`)?.remove();
        }
    };

    public handleSchedulerItemResizeStart = async (e: MouseEvent, _: 'top' | 'bottom', schedulerItem: GetSchedulerItemResponse, el: HTMLDivElement): Promise<void> => {
        if (schedulerItem.status === 'Cancelled' || schedulerItem.status === 'NoShow' || schedulerItem.historic) return;
        // Prevent other events from firing. Like the drag and select event.
        e.preventDefault();
        const clone = el.cloneNode(true) as HTMLDivElement;
        clone.id = `clone-${schedulerItem.id}`;
        // Make sure the clone is always on top.
        clone.style.width = '100%';
        clone.style.zIndex = '10000';
        clone.style.left = '0px';

        this.renderContainer.appendChild(clone);

        this.originalHeight = parseFloat(getComputedStyle(clone, null).getPropertyValue('height').replace('px', ''));
        this.originalY = e.clientY;
        this.originalTop = parseInt(document.defaultView.getComputedStyle(clone).top, 10);
    };

    public handleSchedulerItemResize = async (e: MouseEvent, side: 'top' | 'bottom', schedulerItem: GetSchedulerItemResponse): Promise<void> => {
        const clone = this.renderContainer.querySelector(`#clone-${schedulerItem.id}`) as HTMLDivElement;
        if (isNotDefined(clone)) return;

        let heightInPixels = 0;

        const content = clone.querySelector('[data-type="content"]') as HTMLDivElement;
        if (side === 'top') {
            // Calculate the new height and top position for the element
            const newHeight = this.originalHeight - (e.pageY - this.originalY);

            let newTop = this.originalTop + (e.pageY - this.originalY);
            // Dont allow scrolling above the top of the container
            // or let the scheduler item be smaller than 22px.
            if (newTop < 0 || newHeight < 22) return;
            heightInPixels = newHeight;

            // Adjust padding top based on the height.
            if (newHeight <= 25) content.style.paddingTop = '2px';
            else if (newHeight < 44) content.style.paddingTop = '5px';
            else content.style.paddingTop = '12px';

            // Snap values to the
            const top = this.snapToGrid(this.renderContainer, newTop);
            const height = this.snapToGrid(this.renderContainer, newHeight);
            clone.style.height = `${height}%`;
            clone.style.top = `${top}%`;
            // Note, changing this to percentage values will make the ascheduler itemppointment jump when resizing.
            // clone.style.height = `${newHeight}px`;
            // clone.style.top = `${newTop}px`;
            const { hours, minutes } = this.getTimeFromGridPosition(top);
            const newStart = setMinutes(setHours(this.date, hours), minutes);
            this.setSlotTime(clone, newStart, schedulerItem.end, heightInPixels);
        } else {
            const pos = clone.getBoundingClientRect();
            // Set new height and calculate the new end time.
            let height = e.pageY - pos.top;
            // Prevent the height from being smaller than 22px.
            if (height < 22) height = 22;
            heightInPixels = height;

            // Adjust padding top based on the height.
            if (height <= 22) content.style.paddingTop = '2px';
            else if (height < 44) content.style.paddingTop = '5px';
            else content.style.paddingTop = '12px';

            // Convert to percentage.
            let snapped = this.snapToGrid(this.renderContainer, height);
            // Make sure the scheduler item doesn't go over the bottom of the container.
            const elStartPercentage = Number(clone.style.top.replace('%', ''));
            if (snapped + elStartPercentage > 100) return;
            // Update the time slot.
            const { hours, minutes } = this.getTimeFromGridPosition(elStartPercentage + snapped);
            // Set the end time and update the slot times.
            const newEnd = setMinutes(setHours(this.date, hours), minutes);
            this.setSlotTime(clone, schedulerItem.start, newEnd, heightInPixels);
            // Set new height.
            clone.style.height = `${snapped}%`;
        }
    };

    public handleSchedulerItemResizeEnd = async (_: MouseEvent, __: 'top' | 'bottom', schedulerItem: GetSchedulerItemResponse): Promise<void> => {
        const originalStart = schedulerItem.start;
        const originalEnd = schedulerItem.end;

        const original = this.renderContainer.querySelector(`#scheduler-item-${schedulerItem.id}`) as HTMLDivElement;
        const clone = this.renderContainer.querySelector(`#clone-${schedulerItem.id}`) as HTMLDivElement;
        if (isNotDefined(clone)) return;

        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('translation:partial-views.scheduler.questions.confirm-edit.title'),
                message: this.t.tr('translation:partial-views.scheduler.questions.confirm-edit.message'),
                type: 'warning',
                btnOk: this.t.tr('translation:global.buttons.continue'),
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        // Get the date and update the slot in the original.
                        const start = new Date(clone.getAttribute('data-start'));
                        const end = new Date(clone.getAttribute('data-end'));

                        const index = this.schedulerItems.findIndex((x) => x.id === schedulerItem.id);
                        this.schedulerItems[index].start = start;
                        this.schedulerItems[index].end = end;
                        this.schedulerItems[index].duration = differenceInSeconds(end, start);

                        // Update the scheduler item
                        const { success } = await this.updateSchedulerItem('resize', this.schedulerItems[index], clone);
                        if (!success) {
                            // Make sure the original time slots are restored
                            this.schedulerItems[index].start = originalStart;
                            this.schedulerItems[index].end = originalEnd;
                            this.schedulerItems[index].duration = differenceInSeconds(originalEnd, originalStart);
                            this.setSlotTime(original, originalStart, originalEnd, original.clientHeight);
                            clone.remove();
                            return;
                        }

                        this.setSlotTime(original, start, end, original.clientHeight);
                        this.schedulerItems.sort(this.sortByStartDateAndDuration);
                        this.render(this.schedulerItems);

                        // Remove the clone
                        clone.remove();

                        // Let the scheduler items know that the scheduler item has changed
                        // and they need to re-render.
                        this.events.publish(CustomEvents.SchedulerItemsResized, { columnDate: this.date, schedulerItem });

                        this.setLoading(original, false);
                    } else {
                        // Remove the clone
                        clone.remove();
                        this.setLoading(original, false);
                    }
                }
            })
        );
    };

    public handleSchedulerItemResizeCancelled = async (e: KeyboardEvent, side: 'top' | 'bottom', schedulerItem: GetSchedulerItemResponse): Promise<void> => {
        if (e.key === 'Escape') {
            this.state.isItemResizing = false;
            this.renderContainer.querySelector(`#clone-${schedulerItem.id}`)?.remove();
        }
    };

    public generateScheduleName(
        item: {
            schedule: GetScheduleResponse;
            slotsAndRooms: {
                slot: ScheduleTimeSlot;
                room: ExaminationRoom;
            }[];
        },
        slotAndRoom: {
            slot: ScheduleTimeSlot;
            room: ExaminationRoom;
        }
    ): string {
        let name = '';
        const converter = new FormatTimeValueConverter();

        if (isDefined(this.wrapper)) {
            name += slotAndRoom.slot.practitioner.name;
        } else if (isNotDefined(this.wrapper) && isDefined(item.schedule.location.data[this.language])) {
            name += `${item.schedule.location.data[this.language]}`;
        } else if (isNotDefined(this.wrapper) && isNotDefined(item.schedule.location.data[this.language])) {
            name += `${item.schedule.location.translations[this.language]}`;
        }

        name += ` • ${slotAndRoom.room.name[this.language]} • ${converter.toView(slotAndRoom.slot.start)} - ${converter.toView(slotAndRoom.slot.end)}`;

        return name;
    }

    public generateStylingForScheduleSlot(slot: ScheduleTimeSlot): { height: string; top: string; heightNumber: number } {
        const day = toDayOfWeek(this.date);
        const start = dayAndTimeToDate(day, slot.start);
        const end = dayAndTimeToDate(day, slot.end);
        const duration = intervalToDuration({ start, end });
        const durationInSeconds = duration.hours * 3600 + duration.minutes * 60 + duration.seconds;

        // First calculate the total amount of slots and the percentage per slot.
        const totalAmountOfSlots = getTotalAmountOfSlots(start, this.settings);
        const percentagePerSlot = 100 / totalAmountOfSlots;

        const getStartingPosition = (start: Date): number => {
            // First get the duration from the start of the day to the start of the scheduler item.
            const durationBeforeStart = intervalToDuration({
                start: setMinutes(setHours(start, this.settings.start.hour), this.settings.start.minute),
                end: start
            });
            // Calculate how many slots we need for the duration before the start of the scheduler item.
            const slotsForTheHours = durationBeforeStart.hours * (60 / this.settings.slotSize);
            const slotsForTheMinutes = durationBeforeStart.minutes / this.settings.slotSize;
            // Calculate the percentage for the duration before the start of the scheduler item.
            return (slotsForTheHours + slotsForTheMinutes) * percentagePerSlot;
        };

        const getHeight = (): number => {
            let durationInMinutes = secondsToMinutes(durationInSeconds);

            // First calculate how many blocks the scheduler item takes by first parsing the duration (which is in seconds) to minutes.
            // Then devide the duration in minuts it by the minutes per block.
            let height =
                (durationInMinutes / this.settings.slotSize) *
                // Then multiply the amount of blocks by the percentage to get the height for the scheduler item in percentage.
                percentagePerSlot;

            // We need to check if the height doesn't exceed the total height of the column.
            // If it does, we need to set the height to the maximum height.
            if (startPosition + height > 100) {
                // We calculate the height by subtracting the start percentage from the total height of the column.
                height = 100 - startPosition;
            }

            return height;
        };

        const startPosition = getStartingPosition(start);
        const height = getHeight();

        return {
            heightNumber: height,
            height: `${height}%`, //
            top: `${startPosition}%`
        };
    }

    private async handlePaste(start: Date): Promise<void> {
        if (isNotDefined(this.state.cut)) return;
        this.state.isSaving = true;

        // Update the start and end to the new dates (positions).
        const schedulerItemToUpdate = this.state.cut.schedulerItem;

        const doIt = async () => {
            // Update the start and end to the new dates (positions) so that we can find a new schedule later on.
            schedulerItemToUpdate.start = setMinutes(setHours(start, getHours(start)), getMinutes(start));
            schedulerItemToUpdate.end = addSeconds(schedulerItemToUpdate.start, schedulerItemToUpdate.duration);

            // Get the timezone offset of the browser
            // NOTE: Get the timezone offset of the scheduler item date
            // and not the current date because the scheduler item date
            // can be in a different timezone that the current date.
            const timezoneOffset = schedulerItemToUpdate.start.getTimezoneOffset();

            // We are booking for a room instead of a user.
            if (isDefined(this.wrapper)) {
                const response = await this.schedulesApi.getByRoomAndTimestamps(this.workspace, this.wrapper.room.id, schedulerItemToUpdate.start, schedulerItemToUpdate.end, timezoneOffset);
                let location: PracticeLocation;

                if (isNotDefined(response.schedule)) {
                    this.notifications.show(
                        this.t.tr('translation:partial-views.scheduler.notifications.update-scheduler-item-failed.title'), //
                        this.t.tr(`translation:partial-views.scheduler-items.messages.error-AP403`),
                        {
                            type: 'warning',
                            duration: 10000
                        }
                    );
                    this.state.isSaving = false;
                    return;
                }

                // Fetch full location if we found the schedule.
                location = await this.locationsApi.getById(response.schedule.location.id, this.workspace);
                if (schedulerItemToUpdate.type === SchedulerItemTypes.Appointment) schedulerItemToUpdate.createOutsideSchedule = false;

                // Update the scheduler item data with as fallback the original data.
                // If we couldn't find a schedule the update after should fail also
                // because that operation also looks for the schedule.
                schedulerItemToUpdate.location = location ?? schedulerItemToUpdate.location;
                schedulerItemToUpdate.schedule = response.schedule ?? schedulerItemToUpdate.schedule;
                schedulerItemToUpdate.examinationRoom = this.wrapper?.room ?? schedulerItemToUpdate.examinationRoom;
                schedulerItemToUpdate.practitioner = response.user ?? schedulerItemToUpdate.practitioner;
            } else {
                // We pasted to possible a column of a different user. So:
                // 1) Update the schedule.
                // 2) Update the examination room.
                // 2) Update the location.
                // 3) Update the practitioner.
                const response = await this.schedulesApi.getByPractitionerAndTimestamps(this.workspace, this.user.id, schedulerItemToUpdate.start, schedulerItemToUpdate.end, timezoneOffset);
                let location: PracticeLocation;

                if (isNotDefined(response.schedule)) {
                    this.notifications.show(
                        this.t.tr('translation:partial-views.scheduler.notifications.update-scheduler-item-failed.title'), //
                        this.t.tr(`translation:partial-views.scheduler-items.messages.error-AP403`),
                        {
                            type: 'warning',
                            duration: 10000
                        }
                    );
                    this.state.isSaving = false;
                    return;
                }

                // Fetch full location if we found the schedule.
                location = await this.locationsApi.getById(response.schedule.location.id, this.workspace);
                if (schedulerItemToUpdate.type === SchedulerItemTypes.Appointment) schedulerItemToUpdate.createOutsideSchedule = false;

                // Update the scheduler item data with as fallback the original data.
                // If we couldn't find a schedule the update after should fail also
                // because that operation also looks for the schedule.
                schedulerItemToUpdate.location = location ?? schedulerItemToUpdate.location;
                schedulerItemToUpdate.schedule = response.schedule ?? schedulerItemToUpdate.schedule;
                schedulerItemToUpdate.examinationRoom = response.room ?? schedulerItemToUpdate.examinationRoom;
                schedulerItemToUpdate.practitioner = isDefined(this.user)
                    ? new UserEntityReference({
                          id: this.user.id,
                          name: this.user.displayName
                      })
                    : schedulerItemToUpdate.practitioner;
            }

            // Update the scheduler item
            const { success, schedulerItem } = await this.updateSchedulerItem('paste', schedulerItemToUpdate, null);
            if (!success) {
                this.state.isSaving = false;
                return;
            }

            // Remove original scheduler item from the its original column.
            // If we cut from a full calendar and paste it to a column when a user filter is selected
            // the column index could not exist anymore.
            if (isDefined(this.columns[this.state.cut.columnParentIndex][this.state.cut.columnIndex])) {
                const index = this.columns[this.state.cut.columnParentIndex][this.state.cut.columnIndex].schedulerItems.findIndex((x) => x.id === schedulerItem.id);
                // Only remove the scheduler item if it exists.
                if (index > -1) {
                    this.columns[this.state.cut.columnParentIndex][this.state.cut.columnIndex].schedulerItems.splice(index, 1);
                    this.events.publish(CustomEvents.SchedulerItemsPasted, { columnDate: this.columns[this.state.cut.columnParentIndex][this.state.cut.columnIndex].date, schedulerItem });
                }
            }

            // Add the scheduler item to the current column and render the column and scheduler items.
            const schedulerItems = [...this.schedulerItems, schedulerItem];
            this.render(schedulerItems);
            this.events.publish(CustomEvents.SchedulerItemsPasted, { columnDate: this.date, schedulerItem });

            // Reset cut.
            this.state.isSaving = false;
            this.state.cut = null;
        };

        if (
            (isDefined(this.user) && schedulerItemToUpdate.practitioner.id !== this.user.id) || //
            (isDefined(this.wrapper) && schedulerItemToUpdate.examinationRoom.id !== this.wrapper.room.id)
        ) {
            const title = isDefined(this.user)
                ? this.t.tr('translation:partial-views.scheduler.questions.change-practitioner.title')
                : this.t.tr('translation:partial-views.scheduler.questions.change-room.title');

            const message = isDefined(this.user)
                ? this.t
                      .tr('translation:partial-views.scheduler.questions.change-practitioner.message') //
                      .replace('{user1}', `<strong>${schedulerItemToUpdate.practitioner.name}</strong>`)
                      .replace('{user2}', `<strong>${this.user.displayName}</strong>`)
                : this.t
                      .tr('translation:partial-views.scheduler.questions.change-room.message') //
                      .replace('{room1}', `<strong>${schedulerItemToUpdate.examinationRoom.name[this.language]} (${schedulerItemToUpdate.location.name[this.language]})</strong>`)
                      .replace('{room2}', `<strong>${this.wrapper.room.name[this.language]} (${this.wrapper.location.name[this.language]})</strong>`);

            await this.modalService.confirm(
                new ConfirmationOptions({
                    title,
                    message,
                    type: 'warning',
                    btnOk: this.t.tr('translation:global.buttons.continue'),
                    callback: async (confirmed: boolean): Promise<void> => {
                        if (confirmed) doIt();
                        else this.state.isSaving = false;
                    }
                })
            );
        } else doIt();
    }

    private handleDrag(e: MouseEvent): void {
        if (this.state.isColumnDragging) {
            const element = document.getElementById('new-scheduler-item') as HTMLDivElement;
            if (isNotDefined(element)) return;

            const start = new Date(element.getAttribute('data-start'));
            const end = new Date(element.getAttribute('data-end'));

            const side: 'top' | 'bottom' = 'bottom' as any;

            if (side === 'top') {
                // Calculate the new height and top position for the element
                // const newHeight = this.originalHeight - (e.pageY - this.originalY);
                // let newTop = this.originalTop + (e.pageY - this.originalY);
                // // Dont allow scrolling above the top of the container
                // // or let the scheduler item be smaller than 50px.
                // if (newTop < 0 || newHeight < 50) return;
                // // Snap values to the
                // const top = this.snapToGrid(this.renderContainer, newTop);
                // const height = this.snapToGrid(this.renderContainer, newHeight);
                // element.style.height = `${height}%`;
                // element.style.top = `${top}%`;
                // // Note, changing this to percentage values will make the scheduler item jump when resizing.
                // // clone.style.height = `${newHeight}px`;
                // // clone.style.top = `${newTop}px`;
                // const { hours, minutes } = this.getTimeFromGridPosition(top);
                // const newStart = setMinutes(setHours(this.date, hours), minutes);
                // this.setSlotTime(element, newStart, schedulerItem.end);
            } else {
                const pos = element.getBoundingClientRect();
                // Set new height and calculate the new end time.
                let height =
                    e.pageY +
                    // Make sure the hover scheduler item is not triggering.
                    10 -
                    pos.top;

                // Adjust padding top based on the height.
                if (height < 30) element.style.paddingTop = '2px';
                else if (height < 44) element.style.paddingTop = '5px';
                else element.style.paddingTop = '12px';

                // Prevent the height from being smaller than 22px.
                if (height < 22) height = 22;
                // Convert to percentage.
                let snapped = this.snapToGrid(this.renderContainer, height);
                // Make sure the scheduler item doesn't go over the bottom of the container.
                const elStartPercentage = Number(element.style.top.replace('%', ''));
                if (snapped + elStartPercentage > 100) return;
                // Update the time slot.
                const { hours, minutes } = this.getTimeFromGridPosition(elStartPercentage + snapped);
                // Set the end time and update the slot times.
                const newEnd = setMinutes(setHours(this.date, hours), minutes);
                this.setSlotTime(element, start, newEnd);
                // Set new height.
                element.style.height = `${snapped}%`;
            }
        }
    }

    private async handleDragEnd(e: MouseEvent): Promise<void> {
        this.state.isColumnDragging = false;
        // Get the new appoint element.
        const element = document.getElementById('new-scheduler-item') as HTMLDivElement;
        // Get the start and end dates from the element.
        const start = new Date(element.getAttribute('data-start'));
        const end = new Date(element.getAttribute('data-end'));
        // Create a new scheduler item.
        const { success, schedulerItem } = await this.createSchedulerItem(start, end, element);
        if (!success) {
            element.remove();
            return;
        }

        // Render the appoints based on a new list including the new scheduler item.
        // This way the layer settings are ready when the scheduler item is added.
        // Though, only add the scheduler item if it doesn't exist yet.
        // The Broadcasts.ItemCreated event will also be triggered when the scheduler item
        // is added to the list. This can create a race condition and a duplicate scheduler item.
        if (this.schedulerItems.findIndex((x) => x.id === schedulerItem.id) === -1) {
            const schedulerItems = [...this.schedulerItems, schedulerItem];
            this.render(schedulerItems);
        }

        // Remove the new scheduler item element.
        element.remove();
        // Let the scheduler items know that the scheduler item have changed
        // and they need to re-render.
        this.events.publish(CustomEvents.SchedulerItemsCreated, { columnDate: this.date, schedulerItem });
    }

    private async handleDragKeyEvent(e: KeyboardEvent): Promise<void> {
        if (e.key === 'Escape') {
            this.state.isColumnDragging = false;
            document.getElementById('new-scheduler-item')?.remove();
            document.removeEventListener('mousemove', this.dragCb);
            document.removeEventListener('mouseup', this.dragEndCb);
            document.removeEventListener('keydown', this.dragKeyCb);
        }
    }

    private async updateSchedulerItem(
        kind: 'move' | 'resize' | 'paste',
        schedulerItem: GetSchedulerItemResponse,
        element: HTMLDivElement
    ): Promise<{ success: boolean; schedulerItem: GetSchedulerItemResponse }> {
        if (isDefined(element)) this.setLoading(element, true);
        this.state.isSaving = true;
        return new Promise((resolve) => {
            setTimeout(async () => {
                if (kind === 'move') {
                    if (isFunction(this.onSchedulerItemMove)) {
                        const response = await this.onSchedulerItemMove(schedulerItem, schedulerItem.start, schedulerItem.end);
                        this.state.isSaving = false;
                        resolve(response);
                    }
                } else if (kind === 'resize') {
                    if (isFunction(this.onSchedulerItemResize)) {
                        const response = await this.onSchedulerItemResize(schedulerItem, schedulerItem.start, schedulerItem.end);
                        this.state.isSaving = false;
                        resolve(response);
                    }
                } else if (kind === 'paste') {
                    if (isFunction(this.onSchedulerItemResize)) {
                        const response = await this.onSchedulerItemResize(schedulerItem, schedulerItem.start, schedulerItem.end);
                        this.state.isSaving = false;
                        resolve(response);
                    }
                } else resolve({ success: false, schedulerItem: null });
            }, 500);
        });
    }

    private async createSchedulerItem(start: Date, end: Date, element: HTMLDivElement): Promise<{ success: boolean; schedulerItem: GetSchedulerItemResponse }> {
        // this.state.isSaving = true;
        this.setLoading(element, true);
        return new Promise(async (resolve) => {
            if (isFunction(this.onSchedulerItemCreate)) {
                const userId = isNotDefined(this.wrapper) ? this.user.id : null;
                const roomId = isDefined(this.wrapper) ? this.wrapper.room.id : null;
                const locationId = isDefined(this.wrapper) ? this.wrapper.location.id : null;

                const response = await this.onSchedulerItemCreate(start, end, userId, roomId, locationId);
                this.state.isSaving = false;
                resolve(response);
            } else {
                this.state.isSaving = false;
                resolve({ success: false, schedulerItem: null });
            }
        });
    }

    private setLoading(el: HTMLDivElement, load: boolean): void {
        if (load) el.classList.add('loading');
        else el.classList.remove('loading');
    }

    private snapToGrid(container: HTMLDivElement, y: number): number {
        if (isNotDefined(container)) return 0;
        // Get the height of the container.
        const containerHeight = container.clientHeight;
        // Calculate the percentage value of the y (mouse) position.
        const percentageValue = (y / containerHeight) * 100;
        // Calculate the grid snap value by rounding the percentage value to the nearest grid snap value.
        return Math.round(percentageValue / this.gridSnap) * this.gridSnap;
    }

    private getTimeFromGridPosition(y: number): { hours: number; minutes: number } {
        // Calculate the total minutes based on the grid position
        const minutes = Math.round((y / this.gridSnap) * this.settings.slotSize);
        // Calculate the total minutes from the start time and the grid position
        const totalMinutes = this.settings.start.hour * 60 + this.settings.start.minute + minutes;
        // Calculate hours and remaining minutes
        const hours = Math.floor(totalMinutes / 60);
        const remainingMinutes = totalMinutes % 60;

        return { hours, minutes: remainingMinutes };
    }

    private setSlotTime(element: HTMLDivElement, start: Date, end?: Date, height: number = -1): void {
        if (!isValid(start)) return;
        const slot = element.querySelector('[data-type="slot"]') as HTMLElement;

        // Only show the slot above certain height.
        if (height != -1 && height < 60) {
            slot.style.display = 'none';
        } else slot.style.display = 'flex';

        // Always update the slot times
        element.setAttribute('data-start', formatISO(start));
        if (isDefined(end)) {
            if (this.pageState.values.columnWidth >= 210) slot.innerHTML = `${format(start, 'HH:mm')} - ${format(end, 'HH:mm')}`;
            else slot.innerHTML = `${format(start, 'HH:mm')}-${format(end, 'HH:mm')}`;
            element.setAttribute('data-end', formatISO(end));
        } else slot.innerHTML = `${format(start, 'HH:mm')}`;
    }

    private setPosition(container: HTMLDivElement, element: HTMLDivElement, y: number): { top: number; percentage: number } {
        // Get the position of the container.
        const containerPos = container.getBoundingClientRect();
        const elementPos = element.getBoundingClientRect();

        // Calculate top difference between the mouse position and the top of the clone position.
        if (isNotDefined(this.differenceBetweenMouseAndTopOfClone)) this.differenceBetweenMouseAndTopOfClone = y - elementPos.top;

        // Get the position of the mouse relative to the container.
        let top =
            y -
            containerPos.top -
            // Remove the difference between the mouse and the top of the clone.
            // This way it doesn't matter where you click on the scheduler item and
            // it prevents the scheduler item from jumping when you start dragging.
            this.differenceBetweenMouseAndTopOfClone -
            // Keeps the mouse above the clone container so that the cursor stays on the move icon.
            5;

        // The clone can not be outside of the container (top side)
        if (top < 0) top = 0;
        // The clone can not be outside of the container (bottom side)
        // Make to use offsetHeight because the element can have a border.
        if (top + element.offsetHeight > this.height) top = this.height - element.offsetHeight;

        // Make sure the clone snaps to the grid.
        const percentage = this.snapToGrid(container, top);
        // Set the position of the clone, by snapping to the grid.
        element.style.top = `${percentage}%`;
        // The clone should always stay in the left side of the container.
        element.style.left = `${0}px`;
        return { top, percentage };
    }

    private render(schedulerItems: GetSchedulerItemResponse[]): void {
        // Sort first by start date and then by duration.
        schedulerItems = schedulerItems.distinct((x: GetSchedulerItemResponse) => x.id).sort(this.sortByStartDateAndDuration);

        // Lets loop through each scheduler item and find the ones that overlap.
        for (let index = 0; index < schedulerItems.length; index++) {
            const schedulerItem = schedulerItems[index];
            // Reset layer
            this.layers[schedulerItem.id] = { zIndex: 1 };
            // Check if this scheduler item overlaps with any other scheduler item.
            const overlaps = schedulerItems.filter((a) => this.checkIfOverlaps(schedulerItem, a));
            // Check if the overlaps come before the current scheduler item.
            // Note that the scheduler items are already sorted by start date.
            // If the overlap comes before the current scheduler item, we need to
            // increase the z-index.
            const overlapsThatComeBeforeCurrentApointment = overlaps.filter((overlap) => {
                const overlapIndex = schedulerItems.findIndex((a) => a.id === overlap.id);
                return overlapIndex < index;
            });

            const zIndex = overlapsThatComeBeforeCurrentApointment.any()
                ? // If we have overlaps use the biggest z-index + 1.
                  Math.max(...overlapsThatComeBeforeCurrentApointment.map((x) => this.layers[x.id]?.zIndex)) + 1
                : // Otherwise use 0.
                  0;

            this.layers[schedulerItem.id] = { zIndex };
        }

        this.schedulerItems = schedulerItems;
    }

    private checkIfOverlaps(ap1: GetSchedulerItemResponse, ap2: GetSchedulerItemResponse): boolean {
        // Don't compare with self.
        if (ap1.id === ap2.id) return false;

        return areIntervalsOverlapping(
            { start: ap1.start, end: ap1.end }, //
            { start: ap2.start, end: ap2.end }
        );
    }

    private sortByStartDateAndDuration = (a: GetSchedulerItemResponse, b: GetSchedulerItemResponse): number => {
        let startA = a.start;
        let startB = b.start;

        if (startA.getTime() > startB.getTime()) return 1;
        else if (startA.getTime() < startB.getTime()) return -1;

        // Sort on duration
        return b.duration - a.duration;
    };

    private createSchedulerItemElement(id: string, y: number): { element: HTMLDivElement; start: Date; end: Date } {
        const top = this.snapToGrid(this.renderContainer, y);
        // Find the start time based on the mouse position.
        const { hours, minutes } = this.getTimeFromGridPosition(top);
        const element = document.createElement('div');

        const start = setMinutes(setHours(this.date, hours), minutes);
        const end = setMinutes(setHours(this.date, hours), minutes + this.settings.slotSize);

        // Set the start and end time.
        element.setAttribute('data-start', formatISO(start));
        element.setAttribute('data-end', formatISO(end));

        const spinner = document.createElement('ux-spinner');
        spinner.setAttribute('size', 'xs');

        const spinnerContainer = document.createElement('div');
        spinnerContainer.className = 'absolute z-50 group-[&.loading]:flex top-0 left-0 w-full h-full bg-white opacity-25 rounded-md hidden justify-center items-center';
        spinnerContainer.appendChild(spinner);

        // Create the slot label.
        const slot = document.createElement('p');
        slot.className = 'text-xs text-gray-800';
        slot.setAttribute('data-type', 'slot');

        // Create the slot wrapper and container.
        const wrapper = document.createElement('div');
        wrapper.className = 'relative flex flex-col flex-1 px-3';

        // Style the container.
        element.id = id;
        element.className = 'absolute flex flex-col h-full [user-select:none] [-webkit-user-select:none] [-webkit-touch-callout:none] transition duration-300 ease-in-out border-2 border-white rounded-md group';
        element.style.zIndex = '10000';
        element.style.width = '100%';
        element.style.paddingTop = '1px';
        element.style.minHeight = '22px';
        element.style.height = `${this.gridSnap}%`;
        element.style.top = `${top}%`;

        wrapper.appendChild(spinnerContainer);
        wrapper.appendChild(slot);
        element.appendChild(wrapper);

        return { element, start, end };
    }

    private setBaseLoaders(): void {
        const getRandomValue = (min: number, max: number): number => Math.floor(Math.random() * (max - min + 1)) + min;

        // Genereer een willekeurig aantal afspraken, bijvoorbeeld tussen 1 en 5
        const amount = getRandomValue(1, 5);

        let remainingSpace = 100; // Beschikbare ruimte in procenten (100% max)

        const top: number[] = [];
        const height: number[] = [];
        let lastTop = 0;

        for (let i = 0; i < amount; i++) {
            // Zorg dat er genoeg ruimte is voor de resterende afspraken
            const maxHeight = Math.min(remainingSpace, getRandomValue(10, 30)); // Hoogte tussen 10% en 30%, maar niet groter dan beschikbare ruimte
            const newHeight = Math.min(maxHeight, remainingSpace);

            // Zorg dat de top-positie niet overlapt met de vorige afspraak
            const newTop = lastTop + getRandomValue(5, 10); // Random gap tussen 5% en 10% na de laatste afspraak

            top.push(newTop); // Voeg de berekende top-positie toe
            height.push(newHeight); // Voeg de berekende hoogte toe

            lastTop = newTop + newHeight; // Update de laatste top-positie
            remainingSpace -= newTop - lastTop + newHeight; // Update beschikbare ruimte

            if (remainingSpace <= 0) break; // Stop als er geen ruimte meer is
        }

        // Stel de loader in met de gegenereerde waarden
        this.loader = { amount, top, height };
    }

    private async init(): Promise<void> {
        try {
            this.baseLoaded = false;
            // First convert the date to a day of the week.
            const day = toDayOfWeek(this.date);
            const timezoneOffset = this.date.getTimezoneOffset();
            // First fetch all schedules for the day, based on the room or user.
            const scheduleResponse = this.hasRole(UserRoles.ReadSchedules)
                ? isDefined(this.wrapper)
                    ? await this.schedulesApi.getByRoomAndTimestamp(this.workspace, this.wrapper.room.id, this.date, day)
                    : await this.schedulesApi.getByPractitionerAndTimestamp(this.workspace, this.user?.id, this.date, day)
                : null;
            this.schedules = scheduleResponse as any;

            // Fetch all the schedules users for the day from the
            // schedules. We might need them a bit later on.
            const scheduledUsers = this.schedules.selectMany(
                (x: {
                    schedule: GetScheduleResponse;
                    slotsAndRooms: {
                        slot: ScheduleTimeSlot;
                        room: ExaminationRoom;
                    }[];
                }) => x.slotsAndRooms.map((y) => y.slot.practitioner.id)
            );

            const scheduledRooms = this.schedules.selectMany(
                (x: {
                    schedule: GetScheduleResponse;
                    slotsAndRooms: {
                        slot: ScheduleTimeSlot;
                        room: ExaminationRoom;
                    }[];
                }) => x.slotsAndRooms.map((y) => y.room.id)
            );

            // Now lets fetch all the scheduler items for the day.
            const schedulerItems = await this.itemsApi.search(
                this.context,
                this.workspace, //
                '',
                100,
                0,
                undefined,
                undefined,
                undefined,
                startOfDay(this.date),
                endOfDay(this.date),
                undefined,
                // If this is a user column, we only want to show the items of the user.
                this.context === SchedulerContext.User
                    ? [this.user.id]
                    : // Because it is not a user column, we now know it is a room column.
                      // Items of type block are always linked to a user and not a room.
                      // Because of that we fetch the block items for all the users that are scheduled for the day.
                      scheduledUsers,
                this.context === SchedulerContext.Room ? [this.wrapper.room.id] : scheduledRooms,
                undefined,
                [SchedulerItemTypes.Appointment, SchedulerItemTypes.Block],
                this.pageState.values.showCancelled ?? false,
                this.schedules.map((x) => x.schedule.id),
                this.schedules.map((x) => x.slotsAndRooms.map((y) => y.slot.id)).flat(),
                timezoneOffset
            );

            if (this.pageState.values.mode === 'schedules') {
                this.pageState.values.days[this.day].visible = this.schedules.any();
            }

            // Render the scheduler items.
            this.render(schedulerItems.data);

            setTimeout(() => (this.baseLoaded = true), 500);
        } catch (e) {
            this.errorHandler.handle('BxSchedulerColumn.attached', e);
        }
    }
}
