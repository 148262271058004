import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { isDefined, isNotEmpty, isValid, resetValidation, validateState } from '@wecore/sdk-utilities';

import { GetUserResponse } from '@wecore/sdk-core';
import { CreateInvitationRequest, InvitationsApiClient, UserEntityReference, UsersApiClient } from '@wecore/sdk-management';
import { IEventAggregator, inject } from 'aurelia';
import { PartialViewResults } from '../../../enums/partial-view-results';
import { BasePartialView } from '../../../infra/base-partial-view';
import { CacheService } from '../../../infra/cache-service';
import { ErrorHandler } from '../../../infra/error-handler';
import { State } from '../../../infra/store/state';
import { ConfirmationOptions } from '../../../models/confirmation-options';
import { EventDetails } from '../../../models/event-details';
import { PartialView } from '../../../models/partial-view';
import { ModalService } from '../../../services/service.modals';
import { UxInput } from '../../../ux/ux-input/ux-input';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, UsersApiClient, InvitationsApiClient, ModalService)
export class PartialManageInvitationsCreate extends BasePartialView {
    public request: CreateInvitationRequest = new CreateInvitationRequest({
        roles: []
    });
    public user: GetUserResponse;
    public validation = {
        email: true
    };
    public alreadyAdded: boolean = false;
    public checking: boolean = false;

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly usersApi: UsersApiClient,
        private readonly invitationsApi: InvitationsApiClient,
        private readonly modalService: ModalService
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                this.baseLoaded = true;
            })
            .catch((x) => this.errorHandler.handle('PartialManageInvitationsCreate.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }

    public async cancel(): Promise<void> {
        await super.remove({
            result: PartialViewResults.Cancelled,
            updateUrl: true
        });
    }

    public async save(): Promise<void> {
        const valid = this.validate();

        if (valid) {
            await this.modalService.confirm(
                new ConfirmationOptions({
                    title: this.t.tr('translation:partial-views.manage-users.questions.invite.title'),
                    message: this.t.tr('translation:partial-views.manage-users.questions.invite.message'),
                    type: 'warning',
                    btnOk: this.t.tr('translation:global.buttons.continue'),
                    callback: async (confirmed: boolean): Promise<void> => {
                        if (confirmed) {
                            this.isLoading = true;
                            try {
                                await this.invitationsApi.create(this.authenticated.workspace.id, this.request);
                                setTimeout(async () => this.remove({ result: PartialViewResults.Ok, updateUrl: true }), 250);
                            } catch (e) {
                                this.isLoading = false;
                                await this.errorHandler.handle('[manage-users-invitation]', e);
                            }
                        }
                    }
                })
            );
        }
    }

    public async handleEmailAddressChanged(e: CustomEvent<EventDetails<UxInput, string>>): Promise<void> {
        this.user = null;
        this.request.createdFor = null;
        this.alreadyAdded = false;
        const valid = this.validateEmail(this.request.email);

        if (valid) {
            e.detail.element.isLoading = true;
            this.checking = true;
            const response = await this.usersApi.search(undefined, 1, 0, undefined, undefined, undefined, undefined, this.request.email);
            this.user = response.data[0];
            if (isDefined(this.user)) {
                this.alreadyAdded = this.authenticated.workspace.userConfiguration.some((x) => x.user?.id == this.user.id);
                this.request.createdFor = new UserEntityReference({
                    id: this.user.id,
                    name: this.user.displayName,
                    data: {
                        avatarColor: this.user.avatar?.color,
                        avatarShade: this.user.avatar?.shade.toString(),
                        email: this.user.email
                    }
                });
            }
            e.detail.element.isLoading = false;
            this.checking = false;
        }
    }

    private validate(): boolean {
        resetValidation(this.validation);

        this.validation.email = this.validateEmail(this.request.email);

        return validateState(this.validation);
    }

    private validateEmail(email: string): boolean {
        return (
            isNotEmpty(email) && isValid(email.toLowerCase(), /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])+/)
        );
    }
}
