import { GetSchedulerItemResponse } from '@wecore/sdk-healthcare';

export interface ISchedulerState {
    isColumnDragging: boolean;
    isItemDragging: boolean;
    isItemResizing: boolean;
    usingTouchDevice: boolean;
    isSaving: boolean;
    isEditing: boolean;
    column?: number;
    cut?: {
        schedulerItem: GetSchedulerItemResponse;
        columnIndex: number;
        columnParentIndex: number;
    };
}

export class SchedulerState {
    public isColumnDragging: boolean;
    public isItemDragging: boolean;
    public isItemResizing: boolean;
    public usingTouchDevice: boolean;
    public isSaving: boolean;
    public isEditing: boolean;
    public column?: number;
    public cut?: {
        schedulerItem: GetSchedulerItemResponse;
        columnIndex: number;
        columnParentIndex: number;
    };

    public constructor(data: ISchedulerState) {
        this.isColumnDragging = data?.isColumnDragging;
        this.isItemDragging = data?.isItemDragging;
        this.isItemResizing = data?.isItemResizing;
        this.usingTouchDevice = data?.usingTouchDevice;
        this.isEditing = data?.isEditing;
        this.isSaving = data?.isSaving;
        this.column = data?.column;
        this.cut = data?.cut;
    }
}
