import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { CreateMedicalExaminationModelRequest, InformationSheet, MedicalExaminationModelBox, MedicalExaminationModelsApiClient } from '@wecore/sdk-healthcare';
import { guid, isDefined, isNotDefined, isNotEmpty, isOfType, validateState } from '@wecore/sdk-utilities';

import { IEventAggregator, inject } from 'aurelia';
import { PartialViewResults } from '../../../enums/partial-view-results';
import { BasePartialView } from '../../../infra/base-partial-view';
import { CacheService } from '../../../infra/cache-service';
import { ErrorHandler } from '../../../infra/error-handler';
import { PartialViews } from '../../../infra/partial-views';
import { State } from '../../../infra/store/state';
import { cleanTranslatables, setTranslation, validateTranslation } from '../../../infra/utilities';
import { PartialView } from '../../../models/partial-view';
import { ViewOptions } from '../../../models/view-options';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, MedicalExaminationModelsApiClient)
export class PartialMedicalExaminationModelsCreate extends BasePartialView {
    public request: CreateMedicalExaminationModelRequest = new CreateMedicalExaminationModelRequest({
        displayOrder: 0,
        boxes: [],
        healthcareSectors: []
    });
    public validation = {
        name: true,
        displayOrder: true,
        boxesAmount: true,
        boxes: [],
        valid: true
    };

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly modelsApi: MedicalExaminationModelsApiClient
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.request.name = setTranslation({}, this.language);
        this.request.description = setTranslation({}, this.language);

        this.addBox();
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                this.baseLoaded = true;
                await super.handleBaseLoaded();
            })
            .catch((x) => this.errorHandler.handle('PartialMedicalExaminationModelsCreate.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }

    public async cancel(): Promise<void> {
        await super.remove({
            result: PartialViewResults.Cancelled,
            updateUrl: true
        });
    }

    public toggleExpanded(index: number): void {
        this.request.boxes[index].attributes.expanded = !this.request.boxes[index].attributes.expanded;
    }

    public addBox(): void {
        for (const box of this.request.boxes) box.attributes.expanded = false;

        this.request.boxes.push(
            new MedicalExaminationModelBox({
                id: guid(),
                name: setTranslation({}, this.language),
                description: setTranslation({}, this.language),
                registrations: [],
                attributes: {
                    expanded: true
                }
            })
        );

        this.validation.boxes.push({
            name: true
        });
    }

    public removeBox(index: number): void {
        this.request.boxes.splice(index, 1);
        this.validation.boxes.splice(index, 1);

        if (this.request.boxes.empty()) this.addBox();
    }

    public async save(): Promise<void> {
        const valid = this.validate();

        if (valid) {
            this.isLoading = true;
            try {
                cleanTranslatables(
                    [
                        'name', //
                        'description',
                        'boxes:name',
                        'boxes:description'
                    ],
                    this.request,
                    2
                );
                await this.modelsApi.create(this.authenticated.workspace.id, this.request);
                this.notifications.show(
                    this.t.tr('translation:partial-views.medical-examination-models.notifications.save-successful.title'),
                    this.t
                        .tr('translation:partial-views.medical-examination-models.notifications.save-successful.message') //
                        .replace('{entity}', `<span>'${this.request.name[this.language]}'</span>`),
                    {
                        type: 'success',
                        duration: 3000
                    }
                );
                setTimeout(async () => this.remove({ result: PartialViewResults.Ok, updateUrl: true }), 250);
            } catch (e) {
                // When the save() goes wrong make sure to re-set the translations object that
                // are possibly set to null and are not required for this request.
                // Otherwise the cleanTranslatables() will fail because the translations object is null.
                if (isNotDefined(this.request.description)) this.request.description = setTranslation({}, this.language);
                for (const box of this.request.boxes) {
                    if (isNotDefined(box.description)) box.description = setTranslation({}, this.language);
                }

                this.isLoading = false;
                await this.errorHandler.handle('[create-medical-examination-model]', e);
            }
        }
    }

    public manageTranslationsFor(property: string, required: boolean = false, index: number): void {
        let translations: any;
        if (property.includes(':')) {
            const [_, prop] = property.split(':');
            translations = this.request.boxes[index][prop];
        } else translations = this.request[property];

        this.manageTranslations({
            translations,
            callback: (updatedTranslations: any) => {
                if (property.includes(':')) {
                    const [_, prop] = property.split(':');
                    this.request.boxes[index][prop] = updatedTranslations;
                } else this.request[property] = updatedTranslations;
            },
            required,
            type: property.includes('description') ? 'textarea' : 'input'
        });
    }

    public async informationSheet(): Promise<void> {
        this.addPartialView({
            view: this.partial.base,
            partial: PartialViews.InformationSheet.with({
                config: {
                    mode: 'edit',
                    language: this.language
                },
                sheet: this.request.informationSheet
            }).whenClosed(async (result: PartialViewResults, sheet: InformationSheet) => {
                if (result === PartialViewResults.Ok) {
                    this.request.informationSheet = sheet;
                }
            }),
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                markItem: true,
                replace: true
            })
        });
    }

    private validate(): boolean {
        this.validation.name = validateTranslation(this.request.name, this.language);
        this.validation.displayOrder = isNotEmpty(this.request.displayOrder) && isOfType(Number(this.request.displayOrder), 'number');
        this.validation.boxesAmount = isDefined(this.request.boxes) && this.request.boxes.any();

        for (let index = 0; index < this.request.boxes.length; index++) {
            const box = this.request.boxes[index];
            this.validation.boxes[index].name = validateTranslation(box.name, this.language);
        }

        this.validation.valid = validateState(this.validation) && this.validation.boxes.every(validateState);

        return this.validation.valid;
    }
}
