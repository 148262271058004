import { GetUserResponse } from '@wecore/sdk-core';
import { SchedulerSettings } from '@wecore/sdk-management';
import { IPageState, savePageState } from '@wecore/sdk-utilities';
import { IDisposable, IEventAggregator, bindable, containerless, inject } from 'aurelia';
import { format, getDay } from 'date-fns';
import { CustomEvents } from '../../infra/events';
import { SchedulerRoomWrapper } from '../../models/scheduler-room-wrapper';

@containerless
@inject(IEventAggregator)
export class BxSchedulerColumnHeader {
    @bindable() public settings: SchedulerSettings;
    @bindable() public date: Date;
    @bindable() public user: GetUserResponse;
    @bindable() public wrapper: SchedulerRoomWrapper;
    @bindable() public headers: BxSchedulerColumnHeader[];
    @bindable() public language: string;
    @bindable() public index: number;
    @bindable() public isLast: boolean = false;
    @bindable() public pageState: IPageState;
    @bindable() public amountOfColumns: number = 1;

    public isToday: boolean = false;
    public day: number;
    private subscriptions: IDisposable[] = [];

    public constructor(
        private readonly events: IEventAggregator //
    ) {}

    public bound(): void {
        this.day = getDay(this.date);

        this.checkForToday();
        this.subscriptions = [
            ...(this.subscriptions ?? []),
            this.events.subscribe(CustomEvents.SchedulerDateChanged, () => this.checkForToday()) //
        ];
    }

    public refresh(): void {
        this.checkForToday();
    }

    public detaching(): void {
        this.subscriptions.forEach((s) => s.dispose());
    }

    public toggleVisiblity(): void {
        const day = getDay(this.date);

        this.pageState.values.days[day].visible = !this.pageState.values.days[day].visible;

        savePageState(this.pageState);
        this.events.publish(CustomEvents.SchedulerColumnsVisibilityChanged, {
            day,
            state: this.pageState
        });
    }

    private checkForToday(): void {
        this.isToday = format(this.date, 'yyyyMMdd') === format(new Date(), 'yyyyMMdd');
    }
}
