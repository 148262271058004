<import from="converters/translate" />
<import from="converters/date" />
<import from="../template-therapy-category/template-therapy-category" />
<import from="../template-therapy-step/template-therapy-step" />

<div class="flex flex-col">
    <div bg-gray-100.class="validation.executions[validationIndex].valid" bg-red-100.class="!validation.executions[validationIndex].valid" class="flex flex-col px-3 bg-gray-100 rounded-lg">
        <div class="flex items-center">
            <a href="javascript:" click.trigger="collapseOrExpand($event)" cla class="flex items-center justify-between flex-1 min-w-0 py-2 pr-3 ml-2 text-sm gap-x-1">
                <div class="flex items-center flex-1 min-w-0 gap-1">
                    <div class="flex min-w-[1.2rem]">
                        <template if.bind="expanded">
                            <svg class="h-[1.2rem] w-[1.2rem] text-indigo-500" fill="none" viewBox="0 0 24 24">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M9.25 19.25V16.75C9.25 15.6454 8.35457 14.75 7.25 14.75H4.75"></path>
                                <path
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="1.5"
                                    d="M14.75 19.25V16.75C14.75 15.6454 15.6454 14.75 16.75 14.75H19.25"></path>
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M14.75 4.75V7.25C14.75 8.35457 15.6454 9.25 16.75 9.25H19.25"></path>
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M9.25 4.75V7.25C9.25 8.35457 8.35457 9.25 7.25 9.25H4.75"></path>
                            </svg>
                        </template>
                        <template else>
                            <svg class="h-[1.2rem] w-[1.2rem] text-indigo-500" fill="none" viewBox="0 0 24 24">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M4.75 14.75V17.25C4.75 18.3546 5.64543 19.25 6.75 19.25H9.25"></path>
                                <path
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="1.5"
                                    d="M19.25 14.75V17.25C19.25 18.3546 18.3546 19.25 17.25 19.25H14.75"></path>
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M19.25 9.25V6.75C19.25 5.64543 18.3546 4.75 17.25 4.75H14.75"></path>
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M4.75 9.25V6.75C4.75 5.64543 5.64543 4.75 6.75 4.75H9.25"></path>
                            </svg>
                        </template>
                    </div>
                    <div class="flex items-center flex-1 min-w-0">
                        <div
                            bg-gray-100.class="validation.executions[validationIndex].valid"
                            bg-red-100.class="!validation.executions[validationIndex].valid"
                            class="flex justify-between flex-1 min-w-0 gap-2">
                            <p class="truncate" t="global.labels.execution"></p>
                            <p if.bind="registration.schedulerItem">${registration.schedulerItem.start | date}</p>
                            <p else>${registration.createdAt | date}</p>
                        </div>
                    </div>
                </div>
            </a>
        </div>
    </div>
    <template if.bind="expanded && registration">
        <div if.bind="registration.therapyExecution.value.stepsToTake.length > 0" class="pl-4">
            <div class="grid grid-cols-12 gap-1 pl-2 mt-1 border-l-2 border-gray-300">
                <template repeat.for="stepToTake of registration.therapyExecution.value.stepsToTake">
                    <template if.bind="stepToTake.type === MedicalTherapyExecutionItemTypes.Category">
                        <template-therapy-category
                            flattened.bind="recordFlattened"
                            record.bind="record"
                            registration.bind="registration"
                            registrations.bind="registrations"
                            component.ref="states[stepToTake.id].model"
                            states.bind="states"
                            validation.bind="validation.executions[validationIndex].steps[$index]"
                            on-file-selected.bind="onFileSelected"
                            on-file-removed.bind="onFileRemoved"
                            x-scroll-container.bind="xScrollContainer"
                            loading.bind="loading"
                            item.bind="stepToTake"
                            workspace.bind="workspace"
                            language.bind="language">
                        </template-therapy-category>
                    </template>
                    <template else>
                        <template-therapy-step
                            flattened.bind="recordFlattened"
                            record.bind="record"
                            registration.bind="registration"
                            registrations.bind="registrations"
                            component.ref="states[stepToTake.id].model"
                            states.bind="states"
                            validation.bind="validation.executions[validationIndex].steps[$index]"
                            on-file-selected.bind="onFileSelected"
                            on-file-removed.bind="onFileRemoved"
                            x-scroll-container.bind="xScrollContainer"
                            loading.bind="loading"
                            item.bind="stepToTake"
                            workspace.bind="workspace"
                            language.bind="language">
                        </template-therapy-step>
                    </template>
                </template>
            </div>
        </div>
        <div else class="pl-4 mt-1">
            <div class="flex flex-col items-center justify-center p-2 border border-gray-200 rounded-lg">
                <p class="text-xs text-gray-500" t="translation:components.widgets.examination-treatment.messages.no-evaluation"></p>
            </div>
        </div>
    </template>
</div>
