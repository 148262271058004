import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { IEventAggregator, inject } from 'aurelia';
import { PartialViewResults } from '../../enums/partial-view-results';
import { BasePartialView } from '../../infra/base-partial-view';
import { CacheService } from '../../infra/cache-service';
import { ErrorHandler } from '../../infra/error-handler';
import { languages } from '../../infra/languages';
import { State } from '../../infra/store/state';
import { PartialView } from '../../models/partial-view';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N)
export class PartialTranslations extends BasePartialView {
    public translations: any;
    public languages: string[] = languages();
    public required: boolean = false;
    public type: 'input' | 'textarea' = 'input';

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.required = view.data.required;
        this.translations = Object.assign({}, view.data.translations);

        this.type = view.data.type || 'input';
    }

    public attached(): void {
        super
            .initView()
            .then(() => {
                this.baseLoaded = true;
            })
            .catch((x) => this.errorHandler.handle('PartialTranslations.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }

    public save(): void {
        super.remove({
            result: PartialViewResults.Ok,
            data: this.translations
        });
    }

    public cancel(): void {
        super.remove({
            result: PartialViewResults.Cancelled
        });
    }
}
