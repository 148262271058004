export class CustomEvents {
    public static UserAvatarChanged = 'users:changed:avatar';
    public static LegalDocumentSigned = 'workspaces:legal:signed';
    public static ExaminationStepSettingsChanged = 'examinations:settings:changed';
    public static ExaminationStepAnswerChanged = 'examinations:steps:answered';
    public static ExaminationStepAnswerCopy = 'examinations:steps:copy';
    public static ExaminationReFlatten = 'examinations:reflatten';
    public static ExaminationActionStepChangedHeight = 'examinations:actions:steps:changed:height';
    public static ExaminationActionCategoryChangedExpanded = 'examinations:actions:categories:changed:expanded';
    public static ExaminationTherapyCategoryChangedExpanded = 'examinations:therapies:categories:changed:expanded';
    public static ExaminationEvaluateDDSettings = 'examinations:evaluate:ddsettings';
    public static ExaminationResultsChanged = 'examinations:results:changed';
    public static QuestionnaireItemsChanged = 'questionnaire:items:changed';
    public static ActionStepsChanged = 'action:steps:changed';
    public static ActionCategoryChanged = 'action:categories:changed';
    public static TherapyStepsChanged = 'therapy:steps:changed';
    public static TherapyCategoryChanged = 'therapy:categories:changed';
    public static MedicalRecordsCourseOfComplaintsChanged = 'medical-records:course-of-complaints:changed';
    public static MedicalRecordUpdated = 'medical-records:changed';
    public static MedicalRecordPhaseChange = 'medical-records:phases:change';
    public static MedicalRecordTreatmentStarted = 'medical-records:treatement:started';
    public static PatientUpdated = 'patients:updated';
    public static AccountUpdated = 'accounts:updated';
    public static ContactUpdated = 'contacts:updated';
    public static NavigationButtonPressed = 'navigation:buttons:back';
    public static NavigationFowardButtonPressed = 'navigation:buttons:forward';
    public static EmailSent = 'emails:sent';
    public static SchedulerItemsCreated = 'scheduler:items:created';
    public static SchedulerItemsUpdated = 'scheduler:items:updated';
    public static SchedulerItemsDeleted = 'scheduler:items:deleted';
    public static SchedulerItemsDragged = 'scheduler:items:dragged';
    public static SchedulerItemsResized = 'scheduler:items:resized';
    public static SchedulerItemsRefresh = 'scheduler:items:refresh';
    public static SchedulerItemsPasted = 'scheduler:items:pasted';
    public static SchedulerDateChanged = 'scheduler:dates:changed';
    public static SchedulerUsersChanged = 'scheduler:users:changed';
    public static SchedulerLocationsChanged = 'scheduler:locations:changed';
    public static SchedulerOverlappingTimeslot = 'scheduler:timeslots:overlapping';
    public static SchedulerSettingsChanged = 'scheduler:settings:changed';
    public static SchedulerColumnsVisibilityChanged = 'scheduler:columns:visibilty:changed';
    public static SchedulerColumnsWidthChanged = 'scheduler:columns:width:changed';
    public static SchedulerColumnsModeChanged = 'scheduler:columns:mode:changed';
    public static SchedulerViewChanged = 'scheduler:view:changed';
    public static SchedulerViewShowCancelledChanged = 'scheduler:view:changed:show-cancelled';
    public static SchedulerTasksLoaded = 'scheduler:tasks:loaded';
    public static TasksListsArchived = 'tasks:lists:archived';
    public static TasksListsDeleted = 'tasks:lists:deleted';
    public static TasksListsMoved = 'tasks:lists:moved';
    public static TasksMoved = 'tasks:moved';
    public static UserPostsCreated = 'user-posts:created';
    public static UserPostsDeleted = 'user-posts:deleted';
    public static UserPostsReplied = 'user-posts:replied';
    public static DeclarationsUpdated = 'declarations:updated';
    public static DeclarationPerformancesUpdated = 'declarations:performances:updated';
    public static DeclarationPerformancesCredited = 'declarations:performances:credited';
    public static HealthcareInvoicesUpdated = 'healthcare-invoices:updated';
}
