import { guid, isDefined, isEmpty, isNotDefined, isNotEmpty } from '@wecore/sdk-utilities';
import { IDisposable, IEventAggregator, bindable, inject, slotted } from 'aurelia';
import { UxEvents } from '../../infra/ux-events';
import { EventDetails } from '../../models/event-details';

@inject(IEventAggregator, Element)
export class UxCombobox {
    @bindable() public value: string;
    @bindable() public data: any;
    @bindable() public debounce: number = 400;
    @bindable() public disabled: boolean = false;
    @bindable() public valid: boolean = true;
    @bindable() public allowClear: boolean = false;
    @bindable() public rounded: 'left' | 'right' | 'full' = 'full';
    @bindable() public padding: 'small' | 'default' = 'default';
    @bindable() public isLoading: boolean = false;
    @bindable() public placeholder: string = 'Selecteer een optie';
    @bindable() public placeholderNoresults: string = 'Geen resultaten gevonden';
    @bindable() public placeholderSearch: string = 'Wat zoek je?';
    @bindable() public showFooter: boolean = false;
    @bindable() public autocomplete: boolean = false;
    @bindable() public allowCustomInput: boolean = false;
    @bindable() public multiple: boolean = false;
    @bindable() public setValueOnSelect: boolean = true;
    @bindable() public focused: number = -1;
    @bindable() public offset: number = 250;
    @bindable() public used: string[] = [];
    @bindable() public label: string;

    @(slotted('ux-combobox-option') as any) options: HTMLElement[];

    public inputFocused: boolean = false;
    public isVisible: boolean = false;
    public html: string;
    public noResults: boolean = false;
    public hasFocus: boolean = false;
    public container: HTMLDivElement;
    public placement: 'top' | 'bottom' = 'bottom';

    private subscriptions: IDisposable[];
    private input: HTMLInputElement;
    private btn: HTMLButtonElement;
    private id: string = guid();
    private allowHoverChange: boolean = true;
    private focusTimeOut: any;
    private debounceTimeOut: any;
    private preventSearchEvent: boolean = false;
    private onSelect: (e: KeyboardEvent) => void;
    private onClick: (e: MouseEvent) => void;
    private onClose: () => void;

    public constructor(
        private readonly events: IEventAggregator,
        private readonly host: HTMLElement
    ) {}

    public bound(): void {
        // Listen to all combobox option clicks and
        // hide the combobox if one the option, which
        // is a child of this combobox, is clicked.
        this.subscriptions = [
            ...(this.subscriptions ?? []),
            this.events.subscribe(
                UxEvents.UxComboboxOptionClicked,
                (event: {
                    value: string; //
                    text: string;
                    data: any;
                    parent: string;
                    byEnter: boolean;
                }) => {
                    if (event.parent !== this.id) return;
                    this.handleSelect(event.value, event.text, event.data, event.byEnter);
                }
            ),
            this.events.subscribe(UxEvents.UxComboboxOpened, (data: { id: string }) => {
                if (data.id === this.id) return;
                // Hide other combobox.
                this.isVisible = false;
            }),
            this.events.subscribe(UxEvents.UxComboboxOptionHovered, (data: { id: string; index: number }) => {
                if (data.id !== this.id) return;
                this.focused = data.index;
                this.focusOption(false);
            })
        ];

        // Listen to all global clicks and hide the combobox
        // if the click is not on this combobox.
        this.onClick = (e: MouseEvent) => {
            const target = e.target as HTMLElement;
            const combobox = target.closest('ux-combobox');
            const parent = target.getAttribute('data-parent');
            if (isNotDefined(combobox) && isNotDefined(parent)) {
                // Always reset the search query and result, when the combobox
                // by clicking outside of the combobox.
                if (this.isVisible) {
                    if (isDefined(this.input)) this.input.value = '';
                    this.emit(
                        UxEvents.OnSearch,
                        new EventDetails<UxCombobox, any>({
                            element: this,
                            innerEvent: null,
                            data: this.data,
                            values: null
                        })
                    );
                    this.handleAutocomplete(null);
                    if (isDefined(this.onClose)) this.onClose();
                }

                this.isVisible = false;
            }
        };
        this.onSelect = (e: KeyboardEvent) => this.handleKeyDown(e);

        document.addEventListener('click', this.onClick);
        document.addEventListener('keydown', this.onSelect);
    }

    public detaching(): void {
        this.subscriptions.forEach((x) => x.dispose());
        document.removeEventListener('click', this.onClick);
        document.removeEventListener('keydown', this.onSelect);
    }

    public async open(): Promise<void> {
        this.isVisible = true;
        this.setFocusToValue();
    }

    public async close(): Promise<void> {
        this.isVisible = false;
        if (isDefined(this.onClose)) this.onClose();
    }

    public async clear(): Promise<void> {
        const value = this.value;
        this.html = null;
        this.value = null;
        this.focused = 0;

        this.emit(
            UxEvents.OnClear,
            new EventDetails<UxCombobox, any>({
                element: this,
                innerEvent: null,
                data: this.data,
                values: {
                    deletedValue: value,
                }
            })
        );

        // Reset options
        this.emit(
            UxEvents.OnSearch,
            new EventDetails<UxCombobox, any>({
                element: this,
                innerEvent: null,
                data: this.data,
                values: null
            })
        );
    }

    public async setValue(value: string): Promise<void> {
        const option = this.options.find((x) => x.getAttribute('data-value') === value?.toString());
        if (isDefined(option)) {
            const content = option.querySelector('[data-type="content"]');
            this.html = content?.innerHTML.trim();
        }
        this.value = value;
    }

    /**
     * Gets the current text of the input.
     */
    public async getText(): Promise<string> {
        return this.input?.value;
    }

    public handleFocus(): void {
        this.hasFocus = true;
    }

    public handleBlur(): void {
        this.hasFocus = false;
    }

    public toggleVisibility(_: MouseEvent): void {
        if (isDefined(this.container)) {
            const pos = this.container.getBoundingClientRect();
            const offsetBottom = window.innerHeight - pos.bottom;
            // Decide whether the dropdown should place on the top or bottom.
            if (offsetBottom < this.offset) this.placement = 'top';
            else this.placement = 'bottom';
        } else this.placement = 'bottom';

        this.isVisible = !this.isVisible;
        if (!this.isVisible) {
            if (isDefined(this.onClose)) this.onClose();
            return;
        }

        this.updateOptions();

        this.events.publish(UxEvents.UxComboboxOpened, { id: this.id });

        setTimeout(() => {
            if (isDefined(this.input)) this.input.focus();
        }, 250);
    }

    public handleInputFocus(hasFocus: boolean): void {
        this.inputFocused = hasFocus;
    }

    public handleInput(e: KeyboardEvent): void {
        clearTimeout(this.debounceTimeOut);

        const search = (query: string): void => {
            this.debounceTimeOut = setTimeout(() => {
                // If use can enter custom input, reset the focused index.
                if (this.allowCustomInput) this.focused = -1;
                // If we need to autocomplete the input.
                if (this.autocomplete) this.handleAutocomplete(query);
                // Search event is prevented, reset flag and skip triggering the event.
                if (this.preventSearchEvent) this.preventSearchEvent = false;
                else
                    this.emit(
                        UxEvents.OnSearch,
                        new EventDetails<UxCombobox, string>({
                            element: this,
                            innerEvent: e,
                            data: this.data,
                            values: query
                        })
                    );
            }, this.debounce);
        };

        // If no option is focused and the user presses enter.
        if (this.allowCustomInput && e.key === 'Enter' && this.focused === -1) {
            const value = this.input.value;
            // If the input is not empty, emit the select event.
            // and optionally set the value to the input.
            if (isNotEmpty(value)) {
                if (this.setValueOnSelect) {
                    this.html = value;
                    this.value = value;
                } else this.input.value = '';
                this.emit(
                    UxEvents.OnSelect,
                    new EventDetails<UxCombobox, any>({
                        element: this,
                        innerEvent: e,
                        data: this.data,
                        values: {
                            value: value,
                            text: value,
                            data: this.data,
                            customInput: true
                        }
                    })
                );
                // Reset the search options by searching for an empty string.
                search('');
                // Close the dropdown.
                this.close();
            }
        } else {
            const exceptions = ['Backspace', 'Delete'];
            // Only trigger when the user types a letter or number or one of the exceptions is pressed.
            if (/^[a-z0-9]$/i.test(e.key) || exceptions.includes(e.key)) setTimeout(() => search(this.input.value));
        }
    }

    public handlePaste(): boolean {
        // setTimeout(() => this.input.dispatchEvent(new KeyboardEvent('keydown', { key: 'Backspace' })));
        return true;
    }

    public handleClear(): void {
        this.clear();
    }

    /**
     * Fired when @slotted options changes.
     */
    public optionsChanged(): void {
        this.updateOptions();
        if (isDefined(this.value)) this.setValue(this.value);
    }

    /**
     * Fired when @value changes.
     */
    public valueChanged(): void {
        this.setValue(this.value);
        this.updateOptions();
    }

    public isDefined(value: any): boolean {
        return isDefined(value);
    }

    public isNotDefined(value: any): boolean {
        return isNotDefined(value);
    }

    private handleAutocomplete(query: string) {
        setTimeout(() => {
            for (const option of this.options) {
                option.setAttribute(
                    'hide',
                    isDefined(query) //
                        ? (!option.textContent?.trim()?.toLowerCase().includes(query?.toLowerCase())).toString()
                        : 'true'
                );
            }

            this.noResults = this.options.every((x) => x.getAttribute('hide') === 'true') || this.options.length === 0;
        });
    }

    private emit<T1, T2>(name: string, args: EventDetails<T1, T2>): void {
        this.host.dispatchEvent(
            new CustomEvent(name, {
                bubbles: true,
                detail: args
            })
        );
    }

    private handleSelect(value: string, text: string, data: any, byEnter: boolean): void {
        if (this.setValueOnSelect) {
            this.html = text;
            this.value = value;
            if (isDefined(this.input)) this.input.value = '';
        } else if (isDefined(this.input) && !this.multiple) this.input.value = '';

        this.emit(
            UxEvents.OnSelect,
            new EventDetails<UxCombobox, any>({
                element: this,
                innerEvent: null,
                data: this.data,
                values: { value, text, data, customInput: false }
            })
        );

        if (byEnter && isDefined(this.btn)) this.btn.blur();

        // If we need to close the dropdown after selecting an option.
        // we also reset the search query and results.
        if (!this.multiple) {
            this.close();
            this.emit(
                UxEvents.OnSearch,
                new EventDetails<UxCombobox, any>({
                    element: this,
                    innerEvent: null,
                    data: this.data,
                    values: null
                })
            );
        }

        // if (this.allowCustomInput)
        this.focused = -1;
        this.focusOption();
        // Make sure the is reset back to the original autocomplete set
        // if the user has selected an option and we should close the dropdown.
        if (this.autocomplete && !this.multiple) this.handleAutocomplete(null);
    }

    private updateOptions(): void {
        // Mark all options with the parent id.
        for (let index = 0; index < this.options.length; index++) {
            const option = this.options[index];

            option.setAttribute('parent', this.id);
            option.setAttribute(
                'hide',
                this.used
                    .filter((x) => isDefined(x))
                    .some((x) => x.toString() === option.getAttribute('data-value').toString())
                    ?.toString()
            );
            const value = option.getAttribute('data-value');

            if (!this.multiple) option.setAttribute('selected', (value?.toString() === this.value?.toString()).toString());
            option.setAttribute('index', index.toString());
        }

        this.noResults = this.options.every((x) => x.getAttribute('hide') === 'true') || this.options.length === 0;
    }

    private handleKeyDown(event: KeyboardEvent): void {
        // If the input is focused and arrow up or down is pressed
        // show the options dropdown and focus first or last item.
        if (this.hasFocus && (event.key === 'ArrowUp' || event.key === 'ArrowDown') && !this.isVisible) {
            this.open();
            if (event.key === 'ArrowUp') this.focused = this.options.length - 1;
            else this.focused = 0;
            this.focusOption();
            this.scrollToFocused();
            return;
        }

        // Hide the options when the escape button is pressed.
        if (event.key === 'Escape') this.close();

        // If we have no options, navigation is not needed.
        if (this.options.length === 0) return;

        // Don't listen to key strokes if the options arent visible.
        if (!this.isVisible) return;

        // Disable the hover focus change while we are navigating.
        this.allowHoverChange = false;

        // If the 'ArrayDown' key is pressed navigate to the previous item.
        if (event.key === 'ArrowUp') {
            // Prevent other elements from scrolling
            event.preventDefault();

            if (this.focused === -1) {
                this.focused = this.options.length - 1;
            } else if (this.focused === 0) {
                if (this.allowCustomInput) this.focused = -1;
                else this.focused = this.options.length - 1;
            } else this.focused--;
            this.focusOption();
            this.scrollToFocused();
        }

        // If the ArrowUp key is pressed and the focused index
        // is not bigger than the amout of results, focus the next item.
        if (event.key === 'ArrowDown') {
            // Prevent other elements from scrolling
            event.preventDefault();
            if (this.focused === -1) {
                this.focused = 0;
            } else if (this.focused === this.options.length - 1) {
                if (this.allowCustomInput) this.focused = -1;
                else this.focused = 0;
            } else this.focused++;

            this.focusOption();
            this.scrollToFocused();
        }

        // Scroll to first or last option with Home/End keys.
        if (event.key === 'Home' || event.key === 'End') {
            // Prevent other elements from scrolling
            event.preventDefault();
            this.focused = event.key === 'Home' ? 0 : this.options.length - 1;
            this.focusOption();
            this.scrollToFocused();
        }

        // To make sure the scroll is smooth when a bit
        // until allowing changing the focused item with mouse again.
        clearTimeout(this.focusTimeOut);
        this.focusTimeOut = setTimeout(async () => (this.allowHoverChange = true), 250);
    }

    private focusOption(scrollToFocused: boolean = true): void {
        if (isEmpty(this.value)) return;

        for (let index = 0; index < this.options.length; index++) {
            const option = this.options[index];
            const isFocused = index === this.focused;
            option.setAttribute('focused', isFocused.toString());
        }

        if (scrollToFocused) this.scrollToFocused();
    }

    private setFocusToValue(): void {
        if (isEmpty(this.value) || this.allowCustomInput) return;

        for (const option of this.options) {
            const isFocused = option.getAttribute('data-value') === this.value.toString();
            if (isFocused) option.setAttribute('focused', isFocused.toString());
        }

        this.scrollToFocused();
    }

    private scrollToFocused(smooth: boolean = true): void {
        const element = this.options.find((x) => x.getAttribute('focused') === 'true');
        if (isDefined(element)) element.scrollIntoView({ behavior: smooth ? 'smooth' : 'auto', block: 'nearest', inline: 'start' });
    }
}
