import { isNotDefined } from "@wecore/sdk-utilities";

export class SortByFirstDayOfWeekValueConverter {
    public toView(days: number[]): number[] {
        if(isNotDefined(days)) return [];
        // These are the day numbers. 0 is Sunday, 1 is Monday, etc.
        // We need to start on the monday of the week, so we need to sort the array
        // so that the monday (1) is first, then tuesday (2), etc. until sunday (0).
        return days.sort((a, b) => {
            // If the value is 0 (sunday), treat it as a larger value than 6 (saturday)
            const dayA = a === 0 ? 7 : a;
            const dayB = b === 0 ? 7 : b;
            return dayA - dayB;
        });
    }
}
