import { UserRoles } from '@wecore/sdk-core';
import { bindable, containerless } from 'aurelia';
import { EventDetails } from '../../models/event-details';
import { UxToggle } from '../../ux/ux-toggle/ux-toggle';

@containerless
export class ComponentManageRights {
    @bindable() public roles: UserRoles[];

    public rights: string;
    public UserRoles: typeof UserRoles = UserRoles;
    public isPractitioner: boolean = false;
    public isFrontOffice: boolean = false;
    public isFinance: boolean = false;

    private groups: {
        practitioner: UserRoles[];
        frontoffice: UserRoles[];
        finance: UserRoles[];
    } = {
        practitioner: [
            UserRoles.ReadAnatomicalRegions,
            UserRoles.ReadSchedules,
            UserRoles.WriteSchedulerItems,
            UserRoles.DeleteSchedulerItems,
            UserRoles.ReadAppointmentTypes,
            UserRoles.ReadDeclarationPerformances,
            UserRoles.WriteDeclarationPerformances,
            UserRoles.ReadDifferentialDiagnoses,
            UserRoles.ReadHealthcareInvoices,
            UserRoles.WriteHealthcareInvoices,
            UserRoles.DeleteHealthcareInvoices,
            UserRoles.ReadEmailTemplates,
            UserRoles.ReadHealthcareTasks,
            UserRoles.WriteHealthcareTasks,
            UserRoles.DeleteHealthcareTasks,
            UserRoles.ReadHealthcareTaskLists,
            UserRoles.ReadHealthcareTaskLabels,
            UserRoles.ReadInsurers,
            UserRoles.ReadMedicalExaminations,
            UserRoles.ReadMedicalExaminationActions,
            UserRoles.ReadMedicalExaminationModels,
            UserRoles.ReadMedicalExaminationPhases,
            UserRoles.ReadMedicalQuestions,
            UserRoles.ReadMedicalQuestionnaires,
            UserRoles.ReadMedicalRecords,
            UserRoles.WriteMedicalRecords,
            UserRoles.DeleteMedicalRecords,
            UserRoles.ReadMedicalTherapies,
            UserRoles.ReadMedicalTreatmentProtocols,
            UserRoles.ReadPatients,
            UserRoles.WritePatients,
            UserRoles.DeletePatients,
            UserRoles.ReadPracticeLocations,
            UserRoles.ReadSchedules,
            UserRoles.ReadAccounts,
            UserRoles.ReadContacts
        ],
        frontoffice: [
            UserRoles.ReadSchedulerItems,
            UserRoles.WriteSchedulerItems,
            UserRoles.DeleteSchedulerItems,
            UserRoles.ReadAppointmentTypes,
            UserRoles.ReadHealthcareTasks,
            UserRoles.WriteHealthcareTasks,
            UserRoles.DeleteHealthcareTasks,
            UserRoles.ReadHealthcareTaskLists,
            UserRoles.ReadHealthcareTaskLabels,
            UserRoles.ReadEmailTemplates,
            UserRoles.ReadInsurers,
            UserRoles.ReadPatients,
            UserRoles.WritePatients,
            UserRoles.DeletePatients,
            UserRoles.ReadPracticeLocations,
            UserRoles.ReadSchedules,
            UserRoles.ReadAccounts,
            UserRoles.ReadContacts
        ],
        finance: [
            UserRoles.ReadDeclarations,
            UserRoles.WriteDeclarations,
            UserRoles.DeleteDeclarations,
            UserRoles.ReadDeclarationPerformances,
            UserRoles.WriteDeclarationPerformances,
            UserRoles.DeleteDeclarationPerformances,
            UserRoles.ReadHealthcareInvoices,
            UserRoles.WriteHealthcareInvoices,
            UserRoles.DeleteHealthcareInvoices,
            UserRoles.ReadHealthcareTasks,
            UserRoles.WriteHealthcareTasks,
            UserRoles.DeleteHealthcareTasks,
            UserRoles.ReadHealthcareTaskLists,
            UserRoles.ReadHealthcareTaskLabels,
            UserRoles.ReadInsurers,
            UserRoles.ReadPatients,
            UserRoles.ReadPracticeLocations,
            UserRoles.ReadSchedules,
            UserRoles.ReadAccounts,
            UserRoles.ReadContacts
        ]
    };

    public attached(): void {
        // Check if all rights for specific groups are enabled
        this.isPractitioner = this.groups.practitioner.every((role) => this.roles.includes(role));
        this.isFrontOffice = this.groups.frontoffice.every((role) => this.roles.includes(role));
        this.isFinance = this.groups.finance.every((role) => this.roles.includes(role));
    }

    public onGroupToggle(e: CustomEvent<EventDetails<UxToggle, any>>) {
        const group = e.detail.data;
        const checked = e.detail.values.checked;

        // Update the group state variables
        switch (group) {
            case 'practitioner':
                this.isPractitioner = checked;
                break;
            case 'frontoffice':
                this.isFrontOffice = checked;
                break;
            case 'finance':
                this.isFinance = checked;
                break;
        }

        // Collect roles from currently enabled groups
        const enabledRoles = new Set<UserRoles>();

        if (this.isPractitioner) this.groups.practitioner.forEach((role) => enabledRoles.add(role));
        if (this.isFrontOffice) this.groups.frontoffice.forEach((role) => enabledRoles.add(role));
        if (this.isFinance) this.groups.finance.forEach((role) => enabledRoles.add(role));

        // Ensure we preserve existing roles that are not part of any group
        this.roles.forEach((role) => {
            if (!Object.values(this.groups).flat().includes(role)) enabledRoles.add(role);
        });

        // Assign the updated roles back to this.roles, ensuring uniqueness
        this.roles = Array.from(enabledRoles);
    }

    public toggle(container: string): void {
        if (this.rights === container) this.rights = null;
        else this.rights = container;
    }
}
