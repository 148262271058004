import { isDefined, isNotDefined, isNotEmpty } from '@wecore/sdk-utilities';
import { PartialView } from '../../../models/partial-view';
import { View } from '../../../models/view';
import { ViewOptions } from '../../../models/view-options';

export function updateUrlToViews(view: View, partial: PartialView, partials: PartialView[], options: ViewOptions, mutation: 'add' | 'delete'): string {
    // Get the parts of the URL exluding the scheme.
    const [scheme, pathAndQuery] = location.href.split('//');
    const [path, query] = pathAndQuery.split('?');
    let [host, workspace, _] = path.split('/').filter((x) => isNotEmpty(x));

    // There are some routes that don't have a workspace.
    // If the route is one of those, we'll set the workspace to an empty string.
    const noWorkspaceRoutes = ['settings'];
    if (noWorkspaceRoutes.includes(workspace)) workspace = '';

    // Built the URL base on the view engine state.
    let parts = view.route.split('/');

    for (const partial of partials) {
        if (isNotDefined(partial.route)) continue;
        const route = transformRoute(partial);
        parts = [...parts, ...route.split('/')];
    }

    // Build the route
    let route = [scheme, [host, workspace, ...parts].filter((x) => isNotEmpty(x)).join('/')].join('////');
    if (isDefined(query) && query.length > 0) route += '?' + query;
    const addedBy = partial.addedBy as PartialView;

    // Set the new URL.
    history.pushState(
        // This state is used by the 'handleBackOrForwardBrowserButton' function
        // on the 'starters' page to determine which partials to add or remove.
        // We strip the partial from any functions. So this means
        // we'll lose the content of the 'handleWhenClosed' callback function
        // when re-adding views by using the browser back/forward buttons.
        {
            id: partial.id,
            name: partial.name,
            route: partial.route,
            parents: partial.parents,
            data: isDefined(partial.data) ? JSON.parse(JSON.stringify(partial.data)) : null,
            options,
            addedBy: addedBy.name
        },
        partial.displayName,
        route
    );

    return route;
}

export function removeQueryParameters(name: string): void {
    const url = new URL(window.location.href);
    const params = url.searchParams;

    params.delete(name);

    const route = url.pathname + (isNotEmpty(params.toString()) ? `?${params.toString()}` : '');
    window.history.replaceState({}, document.title, route);
}

/**
 * Injects an id into the partial route if it is present.
 * @param partial The partial holding the id.
 */
export function transformRoute(partial: PartialView): string {
    return isDefined(partial.data) && isDefined(partial.data.id) //
        ? partial.route.replace(':id', partial.data.id)
        : partial.route;
}
