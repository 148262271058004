import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { GetUserResponse } from '@wecore/sdk-core';
import {
    DeclarationPerformanceStatuses,
    DeclarationPerformanceTypes,
    DeclarationSendMethods,
    DeclarationStatuses,
    GetDeclarationResponse,
    GetHealthcareInvoiceResponse,
    GetInsurerResponse,
    GetPatientResponse
} from '@wecore/sdk-healthcare';
import { IEventAggregator, inject } from 'aurelia';
import { addDays, addMonths, addWeeks, endOfMonth, endOfWeek, startOfDay, startOfMonth, startOfWeek } from 'date-fns';
import { DeclarationPerformanceStatusToStringValueConverter } from '../../converters/declaration-performance-status-to-string';
import { DeclarationPerformanceTypeToStringValueConverter } from '../../converters/declaration-performance-type-to-string';
import { DeclarationSendMethodToStringValueConverter } from '../../converters/declaration-send-method-to-string';
import { DeclarationStatusToStringValueConverter } from '../../converters/declaration-status-to-string';
import { PartialViewResults } from '../../enums/partial-view-results';
import { BasePartialView } from '../../infra/base-partial-view';
import { CacheService } from '../../infra/cache-service';
import { ErrorHandler } from '../../infra/error-handler';
import { State } from '../../infra/store/state';
import { checkForFilters } from '../../infra/utilities';
import { EventDetails } from '../../models/event-details';
import { KeyValuePair } from '../../models/key-value-pair';
import { PartialView } from '../../models/partial-view';
import { UxDatepicker } from '../../ux/ux-datepicker/ux-datepicker';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N)
export class PartialFilters extends BasePartialView {
    public hasFilters: boolean = false;
    public settings: any = {
        practitioners: false,
        invoices: false,
        declarationSendMethods: false,
        patients: false,
        performanceStatuses: false,
        performanceTypes: false,
        declarationStatuses: false,
        declarations: false,
        insurers: false,
        start: false,
        end: false
    };

    // NOTE: If you add new filters, make sure to add them to the
    // utility function checkForFilters() in src/infra/utilities.ts
    public filters: {
        practitioners: KeyValuePair[];
        invoices: KeyValuePair[];
        declarationSendMethods: KeyValuePair[];
        patients: KeyValuePair[];
        performanceStatuses: KeyValuePair[];
        performanceTypes: KeyValuePair[];
        declarationStatuses: KeyValuePair[];
        declarations: KeyValuePair[];
        insurers: KeyValuePair[];
        start: Date | undefined;
        end: Date | undefined;
    } = {
        practitioners: [],
        invoices: [],
        declarationSendMethods: [],
        patients: [],
        performanceStatuses: [],
        performanceTypes: [],
        declarationStatuses: [],
        declarations: [],
        insurers: [],
        start: undefined,
        end: undefined
    };

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.settings = {
            ...this.settings,
            ...(view.data.settings ?? {})
        };
        this.filters = {
            ...this.filters,
            ...(view.data.filters ?? {})
        };

        // Make sure all filters are set.
        this.filters.practitioners = this.filters.practitioners ?? [];
        this.filters.invoices = this.filters.invoices ?? [];
        this.filters.declarationSendMethods = this.filters.declarationSendMethods ?? [];
        this.filters.patients = this.filters.patients ?? [];
        this.filters.performanceStatuses = this.filters.performanceStatuses ?? [];
        this.filters.performanceTypes = this.filters.performanceTypes ?? [];
        this.filters.declarationStatuses = this.filters.declarationStatuses ?? [];
        this.filters.declarations = this.filters.declarations ?? [];
        this.filters.insurers = this.filters.insurers ?? [];
        this.filters.start = this.filters.start ?? undefined;
        this.filters.end = this.filters.end ?? undefined;

        this.hasFilters = checkForFilters(this.filters);
    }

    public attached(): void {
        super
            .initView()
            .then(() => {
                this.baseLoaded = true;
            })
            .catch((x) => this.errorHandler.handle('PartialFilters.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }

    public save(): void {
        super.remove({
            result: PartialViewResults.Ok,
            data: this.filters
        });
    }

    public toSet(type: 'DeclarationSendMethods' | 'DeclarationPerformanceStatuses' | 'DeclarationStatuses' | 'DeclarationPerformanceTypes'): { value: string; text: string; data?: any }[] {
        if (type === 'DeclarationSendMethods') {
            const converter = new DeclarationSendMethodToStringValueConverter();
            return Object.keys(DeclarationSendMethods).map((key) => ({
                value: DeclarationSendMethods[key],
                text: this.t.tr(converter.toView(DeclarationSendMethods[key]))
            }));
        }
        if (type === 'DeclarationPerformanceStatuses') {
            const converter = new DeclarationPerformanceStatusToStringValueConverter();
            return Object.keys(DeclarationPerformanceStatuses).map((key) => ({
                value: DeclarationPerformanceStatuses[key],
                text: this.t.tr(converter.toView(DeclarationPerformanceStatuses[key]))
            }));
        }
        if (type === 'DeclarationStatuses') {
            const converter = new DeclarationStatusToStringValueConverter();
            return Object.keys(DeclarationStatuses).map((key) => ({
                value: DeclarationStatuses[key],
                text: this.t.tr(converter.toView(DeclarationStatuses[key]))
            }));
        }
        if (type === 'DeclarationPerformanceTypes') {
            const converter = new DeclarationPerformanceTypeToStringValueConverter();
            return Object.keys(DeclarationPerformanceTypes).map((key) => ({
                value: DeclarationPerformanceTypes[key],
                text: this.t.tr(converter.toView(DeclarationPerformanceTypes[key]))
            }));
        }
    }

    public handleUserSelected = (selection: GetUserResponse[]): void => {
        this.filters.practitioners = selection.map(
            (user) =>
                new KeyValuePair({
                    key: user.id,
                    value: user.displayName
                })
        );
    };

    public handleInvoiceSelected = (selection: GetHealthcareInvoiceResponse[]): void => {
        this.filters.invoices = selection.map(
            (invoice) =>
                new KeyValuePair({
                    key: invoice.id,
                    value: `${invoice.trackingNumber?.toString() ?? `${this.t.tr('translation:global.labels.concept')} ${invoice.conceptNumber.toString()}`}`
                })
        );
    };

    public handlePatientSelected = (selection: GetPatientResponse[]): void => {
        this.filters.patients = selection.map(
            (patient) =>
                new KeyValuePair({
                    key: patient.id,
                    value: patient.displayName
                })
        );
    };

    public handleInsurerSelected = (selection: GetInsurerResponse[]): void => {
        this.filters.insurers = selection.map(
            (insurer) =>
                new KeyValuePair({
                    key: insurer.id,
                    value: insurer.name[this.language]
                })
        );
    };

    public handleDeclarationSelected = (selection: GetDeclarationResponse[]): void => {
        this.filters.declarations = selection.map(
            (declaration) =>
                new KeyValuePair({
                    key: declaration.id,
                    value: declaration.trackingNumber
                })
        );
    };

    public handleSendMethodSelected = (option: { value: string; text: string }): void => {
        this.filters.declarationSendMethods.push(
            new KeyValuePair({
                key: option.value,
                // Remove html tags from the text
                value: option.text.replace(/<[^>]*>?/gm, '')
            })
        );
    };

    public handleSendMethodRemoved = (option: { value: string; text: string }): void => {
        this.filters.declarationSendMethods = this.filters.declarationSendMethods.filter((type: KeyValuePair) => type.key !== option.value);
    };

    public handlePerformanceStatusSelected = (option: { value: string; text: string }): void => {
        this.filters.performanceStatuses.push(
            new KeyValuePair({
                key: option.value,
                // Remove html tags from the text
                value: option.text.replace(/<[^>]*>?/gm, '')
            })
        );
    };

    public handlePeformanceStatusRemoved = (option: { value: string; text: string }): void => {
        this.filters.performanceStatuses = this.filters.performanceStatuses.filter((type: KeyValuePair) => type.key !== option.value);
    };

    public handlePerformanceTypeSelected = (option: { value: string; text: string }): void => {
        this.filters.performanceTypes.push(
            new KeyValuePair({
                key: option.value,
                // Remove html tags from the text
                value: option.text.replace(/<[^>]*>?/gm, '')
            })
        );
    };

    public handlePeformanceTypeRemoved = (option: { value: string; text: string }): void => {
        this.filters.performanceTypes = this.filters.performanceTypes.filter((type: KeyValuePair) => type.key !== option.value);
    };

    public handleDeclarationStatusSelected = (option: { value: string; text: string }): void => {
        this.filters.declarationStatuses.push(
            new KeyValuePair({
                key: option.value,
                // Remove html tags from the text
                value: option.text.replace(/<[^>]*>?/gm, '')
            })
        );
    };

    public handleDeclarationStatusRemoved = (option: { value: string; text: string }): void => {
        this.filters.declarationStatuses = this.filters.declarationStatuses.filter((type: KeyValuePair) => type.key !== option.value);
    };

    public reset(): void {
        this.hasFilters = false;
        super.remove({
            result: PartialViewResults.Ok,
            data: {
                practitioners: [],
                invoices: [],
                declarationSendMethods: [],
                declarations: [],
                patients: [],
                performanceStatuses: [],
                performanceTypes: [],
                declarationStatuses: [],
                insurers: [],
                start: undefined,
                end: undefined
            }
        });
    }

    public cancel(): void {
        super.remove({
            result: PartialViewResults.Cancelled
        });
    }

    public async handleDateChanged(e: CustomEvent<EventDetails<UxDatepicker, any>>): Promise<void> {
        const date = new Date(e.detail.values.date);
        const prop = e.detail.data as 'start' | 'end';
        this.filters[prop] = startOfDay(date);
    }

    public async handleDateCleared(e: CustomEvent<EventDetails<UxDatepicker, any>>): Promise<void> {
        const prop = e.detail.data as 'start' | 'end';
        this.filters[prop] = null;
    }

    public selectPeriod(period: 'yesterday' | 'today' | 'tomorrow' | 'previous-week' | 'this-week' | 'next-week' | 'previous-month' | 'this-month' | 'next-month'): void {
        switch (period) {
            case 'yesterday':
                this.filters.start = startOfDay(addDays(new Date(), -1));
                this.filters.end = startOfDay(addDays(new Date(), -1));
                break;
            case 'today':
                this.filters.start = startOfDay(new Date());
                this.filters.end = startOfDay(new Date());
                break;
            case 'tomorrow':
                this.filters.start = startOfDay(addDays(new Date(), 1));
                this.filters.end = startOfDay(addDays(new Date(), 1));
                break;
            case 'previous-week':
                this.filters.start = startOfWeek(addWeeks(new Date(), -1));
                this.filters.end = endOfWeek(addWeeks(new Date(), -1));
                break;
            case 'this-week':
                this.filters.start = startOfWeek(new Date());
                this.filters.end = endOfWeek(new Date());
                break;
            case 'next-week':
                this.filters.start = startOfWeek(addWeeks(new Date(), 1));
                this.filters.end = endOfWeek(addWeeks(new Date(), 1));
                break;
            case 'previous-month':
                this.filters.start = startOfMonth(addMonths(new Date(), -1));
                this.filters.end = endOfMonth(addMonths(new Date(), -1));
                break;
            case 'this-month':
                this.filters.start = startOfMonth(new Date());
                this.filters.end = endOfMonth(new Date());
                break;
            case 'next-month':
                this.filters.start = startOfMonth(addMonths(new Date(), 1));
                this.filters.end = endOfMonth(addMonths(new Date(), 1));
                break;
        }
    }
}
