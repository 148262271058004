<template class="!flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex overflow-hidden gap-x-2" css.bind="styles">
        <div class="flex flex-col flex-1 w-full h-full overflow-hidden">
            <div class="flex flex-col flex-1 px-8 overflow-y-auto">
                <template if.bind="baseLoaded">
                    <div class="flex flex-col flex-1 pt-8 pb-5 gap-y-3">
                        <div class="flex justify-between">
                            <p class="font-medium text-gray-400" t="translation:partial-views.medical-question-categories.create.title"></p>
                        </div>
                        <div class="flex flex-col">
                            <div class="flex items-center space-x-1">
                                <ux-label class="mb-1">
                                    <span t="global.labels.name"></span>
                                </ux-label>
                                <span class="flex items-center h-3 text-red-500">*</span>
                            </div>
                            <ux-textarea
                                action.bind="c => manageTranslationsFor('name', true)"
                                rows="1"
                                value.two-way="request.name[language]"
                                valid.bind="validation.name"
                                t="[placeholder]partial-views.medical-question-categories.placeholders.name">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M10.5 21l5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 016-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 01-3.827-5.802" />
                                </svg>
                            </ux-textarea>
                        </div>
                        <div class="flex flex-col">
                            <ux-label class="mb-1">
                                <span t="global.labels.description"></span>
                            </ux-label>
                            <ux-textarea
                                action.bind="c => manageTranslationsFor('description')"
                                value.two-way="request.description[language]"
                                rows="8"
                                t="[placeholder]partial-views.medical-question-categories.placeholders.description">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M10.5 21l5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 016-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 01-3.827-5.802" />
                                </svg>
                            </ux-textarea>
                        </div>
                        <div class="flex flex-col">
                            <ux-label class="mb-1">
                                <span t="global.labels.healthcare-sector"></span>
                            </ux-label>
                            <ux-multi-selector values.two-way="request.healthcareSectors" type="healthcare-sectors"></ux-multi-selector>
                        </div>
                    </div>
                </template>
                <template else>
                    <div class="flex flex-col flex-1 pt-10">
                        <div class="grid grid-cols-1 gap-5">
                            <div class="flex flex-col flex-1 space-y-3">
                                <ux-skeleton show-background="true" type="container">
                                    <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                        <ux-skeleton type="line" padding="pr-60"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                    </div>
                                </ux-skeleton>
                                <ux-skeleton show-background="true" type="container">
                                    <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                        <ux-skeleton type="line" padding="pr-48"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                    </div>
                                </ux-skeleton>
                                <ux-skeleton show-background="true" type="container">
                                    <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                        <ux-skeleton type="line" padding="pr-20"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-8"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-6"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-0"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                    </div>
                                </ux-skeleton>
                            </div>
                        </div>
                    </div>
                </template>
                <div class="sticky bottom-0 z-50 flex items-center justify-between py-4 bg-white border-t border-gray-200 gap-x-2">
                    <div class="flex">
                        <p if.bind="!validation.valid" class="text-xs text-red-500" t="translation:global.messages.not-all-values-entered"></p>
                    </div>
                    <div class="flex gap-2">
                        <ux-button size="sm" color="dark" mode="outline" disabled.bind="isLoading" click.trigger="cancel()">
                            <span class="pointer-events-none" t="global.buttons.cancel"></span>
                        </ux-button>
                        <ux-button
                            size="sm"
                            color="success"
                            disabled.bind="isLoading"
                            loading.bind="isLoading"
                            click.trigger="save()"
                            t="[text-when-loading]translation:global.labels.loading-states.default">
                            <span class="pointer-events-none" t="global.buttons.save"></span>
                        </ux-button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
