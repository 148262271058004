export enum Broadcasts {
    OnConnected = 'OnConnected',
    OnReconnected = 'OnReconnected',
    OnReconnecting = 'OnReconnecting',
    OnDisconnecting = 'OnDisconnecting',
    OnDisconnected = 'OnDisconnected',
    SchedulerItemCreated = 'SchedulerItemCreated',
    SchedulerItemUpdated = 'SchedulerItemUpdated',
    SchedulerItemDeleted = 'SchedulerItemDeleted',
    DeclarationStatusLog = 'DeclarationStatusLog'
}
