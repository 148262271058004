import { SchedulerSettings } from '@wecore/sdk-management';
import { Clipboard } from '../../models/clipboard';
import { ViewEngine } from '../../models/view-engine';

export interface State {
    viewEngine: { [key: string]: ViewEngine };
    language: string;
    clipboard: Clipboard;
    route: string;
    schedulerSettings: SchedulerSettings;
}

export const initialState: State = {
    viewEngine: {},
    language: null,
    clipboard: new Clipboard(),
    route: null,
    schedulerSettings: null
};
