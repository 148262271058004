import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { Address, Email, ExaminationRoom, Phone, PracticeLocationsApiClient, UpdatePracticeLocationRequest } from '@wecore/sdk-healthcare';
import { isDefined, isEmpty, isNotDefined, isNotEmpty, isValid, resetValidation, validateState } from '@wecore/sdk-utilities';

import { IEventAggregator, inject } from 'aurelia';
import { PartialViewResults } from '../../../enums/partial-view-results';
import { BasePartialView } from '../../../infra/base-partial-view';
import { CacheService } from '../../../infra/cache-service';
import { ErrorHandler } from '../../../infra/error-handler';
import { PartialViews } from '../../../infra/partial-views';
import { State } from '../../../infra/store/state';
import { cleanTranslatables, cloneDeep, setTranslation, validateTranslation } from '../../../infra/utilities';
import { ConfirmationOptions } from '../../../models/confirmation-options';
import { PartialView } from '../../../models/partial-view';
import { ViewOptions } from '../../../models/view-options';
import { ModalService } from '../../../services/service.modals';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, PracticeLocationsApiClient, ModalService)
export class PartialManagePracticeLocationsEdit extends BasePartialView {
    public locationId: string;
    public location: UpdatePracticeLocationRequest;
    public addresses: Address[] = [];
    public emails: Email[] = [];
    public phones: Phone[] = [];
    public validation = {
        name: true,
        rooms: true,
        phones: [],
        emails: []
    };

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly locationsApi: PracticeLocationsApiClient,
        private readonly modalService: ModalService
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.locationId = view.data.id;
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                this.location = await this.locationsApi.getById(this.locationId, this.authenticated.workspace.id);
                if (isNotDefined(this.location.applicationName)) this.location.applicationName = setTranslation({}, this.language);

                if (isDefined(this.location.address)) this.addresses = [this.location.address];
                if (isDefined(this.location.phone)) this.phones = [this.location.phone];
                if (isDefined(this.location.email)) this.emails = [this.location.email];

                this.validation.phones = this.phones?.map((_: Phone) => ({ phone: true, email: true, valid: true })) || [];
                this.validation.emails = this.emails?.map((_: Email) => ({ email: true, phone: true, valid: true })) || [];

                // Delay showing content to prevent flickering.
                setTimeout(async () => {
                    this.baseLoaded = true;
                    await super.handleBaseLoaded();
                }, 250);
            })
            .catch((x) => this.errorHandler.handle('PartialManagePracticeLocationsEdit.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }

    public async cancel(): Promise<void> {
        await super.remove({
            result: PartialViewResults.Cancelled,
            updateUrl: true
        });
    }

    public async save(): Promise<void> {
        const valid = this.validate();

        if (valid) {
            this.isLoading = true;
            try {
                cleanTranslatables(['name', 'applicationName'], this.location, 1);
                this.location.rooms.forEach((x) => cleanTranslatables(['name'], x, 1));
                await this.locationsApi.update(this.locationId, this.authenticated.workspace.id, this.location);
                this.notifications.show(
                    this.t.tr('translation:partial-views.manage-practice-locations.notifications.save-successful.title'),
                    this.t
                        .tr('translation:partial-views.manage-practice-locations.notifications.save-successful.message') //
                        .replace('{entity}', `<span>'${this.location.name[this.language]}'</span>`),
                    {
                        type: 'success',
                        duration: 3000
                    }
                );
                setTimeout(async () => this.remove({ result: PartialViewResults.Ok, updateUrl: true }), 250);
            } catch (e) {
                this.isLoading = false;
                await this.errorHandler.handle('[edit-practice-location]', e);
            }
        }
    }

    public async delete(): Promise<void> {
        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('partial-views.manage-practice-locations.questions.delete.title'),
                message: this.t
                    .tr('partial-views.manage-practice-locations.questions.delete.message') //
                    .replace('{entity}', `<span>'${this.location.name[this.language]}'</span>`),
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        this.deleting = true;
                        try {
                            await this.locationsApi.delete(this.locationId, this.authenticated.workspace.id);
                            setTimeout(async () => this.remove({ result: PartialViewResults.Deleted, updateUrl: true }), 250);
                            this.notifications.show(
                                this.t.tr('translation:partial-views.manage-practice-locations.notifications.deleted-successfully.title'),
                                this.t
                                    .tr('translation:partial-views.manage-practice-locations.notifications.deleted-successfully.message') //
                                    .replace('{entity}', `<span>'${this.location.name[this.language]}'</span>`),
                                { type: 'success', duration: 3000 }
                            );
                        } catch (e) {
                            this.deleting = false;
                            await this.errorHandler.handle('[delete-practice-location]', e);
                        }
                    }
                }
            })
        );
    }

    public manageTranslationsFor(property: string, required: boolean = false): void {
        this.manageTranslations({
            translations: this.location[property],
            callback: (updatedTranslations: any) => {
                this.location[property] = updatedTranslations;
            },
            required,
            type: property === 'description' ? 'textarea' : 'input'
        });
    }

    public handleAddressSelected = (address: Address): void => {
        address.translations = { nl: 'Werk', en: 'Work' };
        this.location.address = address;
    };

    public handlePhoneChanged = (phone: Phone) => {
        const cleaned = phone.value?.replace(/_/g, '');
        if (isNotDefined(cleaned) || isEmpty(cleaned)) this.location.phone = null;
        else {
            phone.translations = { nl: 'Werk', en: 'Work' };
            this.location.phone = phone;
        }
    };

    public handleEmailChanged = (email: Email) => {
        if (isNotDefined(email.value) || isEmpty(email.value)) this.location.email = null;
        else {
            email.translations = { nl: 'Werk', en: 'Work' };
            this.location.email = email;
        }
    };

    public async createOrEditRoom(index: number = -1): Promise<void> {
        const room = index > -1 ? this.location.rooms[index] : null;
        await this.removeChildViews();
        await this.addPartialView({
            view: this.partial.base,
            partial: PartialViews.PracticeLocationsRoom.with({ room: cloneDeep(room), index }).whenClosed(async (result: PartialViewResults, data: { room: ExaminationRoom; index: number }) => {
                if (result === PartialViewResults.Ok) {
                    if (data.index > -1) this.location.rooms[data.index] = data.room;
                    else this.location.rooms.push(data.room);

                    this.location.rooms = [
                        ...(this.location.rooms.length > 0 ? [this.location.rooms.shift()] : []), //
                        ...this.location.rooms
                    ];
                }
            }),
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                markItem: true,
                updateUrl: false
            })
        });
    }

    public async removeRoom(index: number): Promise<void> {
        const room = this.location.rooms[index];
        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('partial-views.manage-practice-locations.questions.delete-room.title'),
                message: this.t
                    .tr('partial-views.manage-practice-locations.questions.delete-room.message') //
                    .replace('{entity}', `<span>'${room.name[this.language]}'</span>`),
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        this.location.rooms.splice(index, 1);
                    }
                }
            })
        );
    }

    private validate(): boolean {
        resetValidation(this.validation);
        resetValidation(this.validation.phones[0]);
        resetValidation(this.validation.emails[0]);

        this.validation.name = validateTranslation(this.location.name, this.language);
        this.validation.rooms = this.location.rooms.any();

        if (isDefined(this.location.email)) {
            this.validation.emails[0].email =
                isNotEmpty(this.location.email.value) &&
                isValid(
                    this.location.email.value.toLowerCase(),
                    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])+/
                );
            this.validation.emails[0].valid = this.validation.emails[0].email;
        }

        if (isDefined(this.location.phone)) {
            const phone = this.location.phone.value?.replace(/_/g, '') || undefined;
            // Validate the phone number
            this.validation.phones[0].phone = phone.length === 9;
            this.validation.phones[0].valid = this.validation.phones[0].phone;
        }

        return validateState(this.validation);
    }
}
