import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import {
    AnatomicalRegionEntityReference,
    DifferentialDiagnosesApiClient,
    DifferentialDiagnosisCategoryEntityReference,
    DifferentialDiagnosisEntityReference,
    GetAnatomicalRegionResponse,
    GetDifferentialDiagnosisCategoryResponse,
    GetDifferentialDiagnosisResponse,
    GetMedicalExaminationActionResponse,
    GetMedicalExaminationPhaseResponse,
    GetMedicalQuestionResponse,
    GetMedicalQuestionnaireResponse,
    HealthcareCode,
    InformationSheet,
    MedicalExaminationActionsApiClient,
    MedicalExaminationPhaseEntityReference,
    MedicalQuestionnairesApiClient,
    MedicalQuestionsApiClient
} from '@wecore/sdk-healthcare';
import { isDefined, isNotDefined, isNotEmpty, isOfType, validateState } from '@wecore/sdk-utilities';

import { IEventAggregator, inject } from 'aurelia';
import { PartialViewResults } from '../../../enums/partial-view-results';
import { BasePartialView } from '../../../infra/base-partial-view';
import { CacheService } from '../../../infra/cache-service';
import { ErrorHandler } from '../../../infra/error-handler';
import { PartialViews } from '../../../infra/partial-views';
import { State } from '../../../infra/store/state';
import { cleanTranslatables, setTranslation, validateTranslation } from '../../../infra/utilities';
import { ConfirmationOptions } from '../../../models/confirmation-options';
import { PartialView } from '../../../models/partial-view';
import { ViewOptions } from '../../../models/view-options';
import { ModalService } from '../../../services/service.modals';

@inject(
    CacheService, //
    ErrorHandler,
    IEventAggregator,
    Store<State>,
    I18N,
    DifferentialDiagnosesApiClient,
    MedicalQuestionsApiClient,
    MedicalExaminationActionsApiClient,
    MedicalQuestionnairesApiClient,
    ModalService
)
export class PartialDifferentialDiagnosesEdit extends BasePartialView {
    public diagnosisId: string;
    public diagnosis: GetDifferentialDiagnosisResponse;
    public validation = {
        name: true,
        displayOrder: true,
        phase: true,
        valid: true
    };

    public questions: GetMedicalQuestionResponse[];
    public actions: GetMedicalExaminationActionResponse[];
    public questionnaires: GetMedicalQuestionnaireResponse[];
    public isLinked: boolean = false;

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly diagnosesApi: DifferentialDiagnosesApiClient,
        private readonly questionsApi: MedicalQuestionsApiClient,
        private readonly actionsApi: MedicalExaminationActionsApiClient,
        private readonly questionnairesApi: MedicalQuestionnairesApiClient,
        private readonly modalService: ModalService
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.diagnosisId = view.data.id;
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                const [diagnosis, questions, actions, questionnaires] = await Promise.all([
                    this.diagnosesApi.getById(this.diagnosisId, this.authenticated.workspace.id), //
                    this.questionsApi.search(this.authenticated.workspace.id, undefined, 100, 0, undefined, undefined, undefined, undefined, [this.diagnosisId]),
                    this.actionsApi.search(this.authenticated.workspace.id, undefined, 100, 0, undefined, undefined, undefined, undefined, [this.diagnosisId]),
                    this.questionnairesApi.search(this.authenticated.workspace.id, undefined, 100, 0, undefined, undefined, undefined, undefined, [this.diagnosisId])
                ]);
                this.diagnosis = diagnosis;
                this.questions = questions.data;
                this.actions = actions.data;
                this.questionnaires = questionnaires.data;
                this.isLinked = this.questions.any() || this.actions.any() || this.questionnaires.any();

                if (this.isLinked) {
                    this.changeStyle({
                        width: '1000px',
                        minWidth: '1000px'
                    });
                    this.scrollTo(this.partial);
                }

                if (isNotDefined(this.diagnosis.synonyms)) this.diagnosis.synonyms = [];
                if (isNotDefined(this.diagnosis.description)) this.diagnosis.description = setTranslation({}, this.language);

                // Delay showing content to prevent flickering.
                setTimeout(async () => {
                    this.baseLoaded = true;
                    await super.handleBaseLoaded();
                }, 250);
            })
            .catch((x) => this.errorHandler.handle('PartialDifferentialDiagnosesEdit.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }

    public handlePhaseSelected = async (phase: GetMedicalExaminationPhaseResponse): Promise<void> => {
        this.diagnosis.phase = new MedicalExaminationPhaseEntityReference({
            id: phase.id,
            translations: phase.name
        });
    };

    public handleRegionSelected = async (region: GetAnatomicalRegionResponse): Promise<void> => {
        this.diagnosis.anatomicalRegion = new AnatomicalRegionEntityReference({
            id: region.id,
            translations: region.name
        });
    };

    public handleCategoriesChanged = async (categories: GetDifferentialDiagnosisCategoryResponse[]): Promise<void> => {
        this.diagnosis.categories = categories.map(
            (c) =>
                new DifferentialDiagnosisCategoryEntityReference({
                    id: c.id,
                    translations: c.name
                })
        );
    };

    public handleDistinguishFromChanged = async (dds: GetDifferentialDiagnosisResponse[]): Promise<void> => {
        this.diagnosis.distinguishFrom = dds.map(
            (c) =>
                new DifferentialDiagnosisEntityReference({
                    id: c.id,
                    translations: c.name
                })
        );
    };

    public handleOccursWithChanged = async (dds: GetDifferentialDiagnosisResponse[]): Promise<void> => {
        this.diagnosis.occursWith = dds.map(
            (c) =>
                new DifferentialDiagnosisEntityReference({
                    id: c.id,
                    translations: c.name
                })
        );
    };

    public async addCode(): Promise<void> {
        await this.removeChildViews();
        await this.addPartialView({
            view: this.partial.base,
            partial: PartialViews.HealthcareCodes.with({ language: this.language }).whenClosed(async (result: PartialViewResults, data: { code: HealthcareCode }) => {
                if (result === PartialViewResults.Ok) {
                    this.diagnosis.codes.push(data.code);
                }
            }),
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                markItem: true,
                replace: true
            })
        });
    }

    public async editCode(index: number): Promise<void> {
        await this.removeChildViews();
        await this.addPartialView({
            view: this.partial.base,
            partial: PartialViews.HealthcareCodes.with({
                code: this.diagnosis.codes[index],
                language: this.language,
                index
            }).whenClosed(async (result: PartialViewResults, data: { code: HealthcareCode; index: number }) => {
                if (result === PartialViewResults.Ok) {
                    this.diagnosis.codes[data.index] = data.code;
                    this.diagnosis.codes = [
                        ...(this.diagnosis.codes.length > 0 ? [this.diagnosis.codes.shift()] : []), //
                        ...this.diagnosis.codes
                    ];
                }
            }),
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                markItem: true,
                replace: true
            })
        });
    }

    public async removeCode(index: number): Promise<void> {
        await this.removeChildViews();
        this.diagnosis.codes.splice(index, 1);
    }

    public async cancel(): Promise<void> {
        await super.remove({
            result: PartialViewResults.Cancelled,
            updateUrl: true
        });
    }

    public async save(): Promise<void> {
        const valid = this.validate();
        if (valid) {
            this.isLoading = true;
            try {
                cleanTranslatables(['name', 'description'], this.diagnosis, 1);
                await this.diagnosesApi.update(this.diagnosisId, this.authenticated.workspace.id, this.diagnosis);
                this.notifications.show(
                    this.t.tr('translation:partial-views.differential-diagnoses.notifications.save-successful.title'),
                    this.t
                        .tr('translation:partial-views.differential-diagnoses.notifications.save-successful.message') //
                        .replace('{entity}', `<span>'${this.diagnosis.name[this.language]}'</span>`),
                    {
                        type: 'success',
                        duration: 3000
                    }
                );
                setTimeout(async () => this.remove({ result: PartialViewResults.Ok, updateUrl: true }), 250);
            } catch (e) {
                // When the save() goes wrong make sure to re-set the translations object that
                // are possibly set to null and are not required for this request.
                // Otherwise the cleanTranslatables() will fail because the translations object is null.
                if (isNotDefined(this.diagnosis.description)) this.diagnosis.description = setTranslation({}, this.language);
                this.isLoading = false;
                await this.errorHandler.handle('[edit-differential-diagnosis]', e);
            }
        }
    }

    public async delete(): Promise<void> {
        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('partial-views.differential-diagnoses.questions.delete.title'),
                message: this.t
                    .tr('partial-views.differential-diagnoses.questions.delete.message') //
                    .replace('{entity}', `<span>'${this.diagnosis.name[this.language]}'</span>`),
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        this.deleting = true;
                        try {
                            await this.diagnosesApi.delete(this.diagnosisId, this.authenticated.workspace.id);
                            this.notifications.show(
                                this.t.tr('translation:partial-views.differential-diagnoses.notifications.deleted-successfully.title'),
                                this.t
                                    .tr('translation:partial-views.differential-diagnoses.notifications.deleted-successfully.message') //
                                    .replace('{entity}', `<span>'${this.diagnosis.name[this.language]}'</span>`),
                                { type: 'success', duration: 3000 }
                            );
                            setTimeout(async () => this.remove({ result: PartialViewResults.Deleted, updateUrl: true }), 250);
                        } catch (e) {
                            this.deleting = false;
                            await this.errorHandler.handle('[delete-differential-diagnosis-detail]', e);
                        }
                    }
                }
            })
        );
    }

    public informationSheet(): void {
        this.addPartialView({
            view: this.partial.base,
            partial: PartialViews.InformationSheet.with({
                config: {
                    mode: 'edit',
                    language: this.language
                },
                sheet: this.diagnosis.informationSheet
            }).whenClosed(async (result: PartialViewResults, sheet: InformationSheet) => {
                if (result === PartialViewResults.Ok) {
                    this.diagnosis.informationSheet = sheet;
                }
            }),
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                markItem: true,
                replace: true
            })
        });
    }

    public manageTranslationsFor(property: string, required: boolean = false): void {
        this.manageTranslations({
            translations: this.diagnosis[property],
            callback: (updatedTranslations: any) => {
                this.diagnosis[property] = updatedTranslations;
            },
            required,
            type: property === 'description' ? 'textarea' : 'input'
        });
    }

    public async openView(id: string, type: 'question' | 'action'): Promise<void> {
        await this.removeChildViews();
        const callback = async (result: PartialViewResults, _: any): Promise<void> => {
            if (result === PartialViewResults.Ok || result === PartialViewResults.Deleted) {
                const [questions, actions, questionnaires] = await Promise.all([
                    this.questionsApi.search(this.authenticated.workspace.id, undefined, 100, 0, undefined, undefined, undefined, undefined, [this.diagnosisId]),
                    this.actionsApi.search(this.authenticated.workspace.id, undefined, 100, 0, undefined, undefined, undefined, undefined, [this.diagnosisId]),
                    this.questionnairesApi.search(this.authenticated.workspace.id, undefined, 100, 0, undefined, undefined, undefined, undefined, [this.diagnosisId])
                ]);
                this.questions = questions.data;
                this.actions = actions.data;
                this.questionnaires = questionnaires.data;
            }
        };

        await this.addPartialView({
            view: this.partial.base,
            partial:
                type === 'question'
                    ? PartialViews.EditMedicalQuestion.with({ id }).whenClosed(callback) //
                    : type === 'action'
                      ? PartialViews.EditMedicalExaminationAction.with({ id }).whenClosed(callback)
                      : PartialViews.EditMedicalQuestionnaires.with({ id }).whenClosed(callback),
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                markItem: true,
                replace: true
            })
        });
    }

    public async copy(): Promise<void> {
        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('translation:partial-views.differential-diagnoses.questions.copy.title'),
                message: this.t.tr('translation:partial-views.differential-diagnoses.questions.copy.message'),
                btnOk: this.t.tr('translation:global.buttons.copy'),
                type: 'warning',
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        try {
                            await this.diagnosesApi.copy(this.diagnosisId, this.authenticated.workspace.id);
                            this.notifications.show(
                                this.t.tr('translation:partial-views.differential-diagnoses.notifications.copied-successfully.title'),
                                this.t.tr('translation:partial-views.differential-diagnoses.notifications.copied-successfully.message'),
                                { type: 'success', duration: 3000 }
                            );
                            setTimeout(async () => this.remove({ result: PartialViewResults.Ok, updateUrl: true }), 250);
                        } catch (e) {
                            await this.errorHandler.handle('[copy-differnential-diagnosis]', e);
                        }
                    }
                }
            })
        );
    }

    private validate(): boolean {
        this.validation.name = validateTranslation(this.diagnosis.name, this.language);
        this.validation.displayOrder = isNotEmpty(this.diagnosis.displayOrder) && isOfType(Number(this.diagnosis.displayOrder), 'number');
        this.validation.phase = isDefined(this.diagnosis.phase);
        this.validation.valid =  validateState(this.validation);

        return this.validation.valid;
    }
}
