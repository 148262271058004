import { IRoute } from '@aurelia/router';
import { UserRoles } from '@wecore/sdk-core';

export class Routes {
    public static globalRoutes(): IRoute[] {
        return [
            {
                id: 'user-settings',
                path: 'settings',
                component: () => import('../views/page-user-settings/page-user-settings'),
                title: 'Settings',
                data: {
                    allowAnonymous: false,
                    skipRequireWorkspace: true
                },
                viewport: 'main'
            } as IRoute,
            {
                id: 'validate-workspace',
                path: '',
                component: () => import('../views/page-validate-workspace/page-validate-workspace'),
                title: '',
                data: {
                    allowAnonymous: false
                },
                viewport: 'global'
            } as IRoute,
            {
                id: 'starter-page',
                path: ':workspace',
                component: () => import('../views/page-starter/page-starter'),
                title: '',
                data: {
                    allowAnonymous: false
                },
                viewport: 'global'
            } as IRoute,
            {
                id: 'onboarding',
                path: 'onboarding',
                component: () => import('../views/page-onboarding/page-onboarding'),
                title: 'Onboarding',
                data: {
                    allowAnonymous: true
                },
                viewport: 'global'
            } as IRoute,
            {
                id: 'onboarding-user',
                path: 'onboarding/user',
                component: () => import('../views/page-onboarding-user/page-onboarding-user'),
                title: 'Onboarding',
                data: {
                    allowAnonymous: true
                },
                viewport: 'global'
            } as IRoute,
            {
                id: 'onboarding-workspace',
                path: 'onboarding/workspace',
                component: () => import('../views/page-onboarding-workspace/page-onboarding-workspace'),
                title: 'Onboarding',
                data: {
                    allowAnonymous: false,
                    skipRequireWorkspace: true
                },
                viewport: 'global'
            } as IRoute,
            // Authentication
            {
                id: 'login',
                path: 'login',
                component: () => import('../views/page-login/page-login'),
                title: 'Inloggen',
                data: {
                    allowAnonymous: true
                },
                viewport: 'global'
            } as IRoute,
            {
                id: 'request-password-reset',
                path: 'request-password-reset',
                component: () => import('../views/page-request-password-reset/page-request-password-reset'),
                title: 'Aanvraag reset wachtwoord',
                data: {
                    allowAnonymous: true
                },
                viewport: 'global'
            } as IRoute,
            {
                id: 'reset-password',
                path: 'reset-password',
                component: () => import('../views/page-reset-password/page-reset-password'),
                title: 'Wachtwoord resetten',
                data: {
                    allowAnonymous: true
                },
                viewport: 'global'
            } as IRoute,
            {
                id: 'ux',
                path: 'ux',
                component: () => import('../ux/page-ux'),
                title: 'UX',
                data: {
                    allowAnonymous: false,
                    skipRequireWorkspace: true
                },
                viewport: 'global'
            } as IRoute,
            {
                id: 'logout',
                path: ['logout', 'logoff', 'signoff', 'signout'],
                component: () => import('../views/page-logout/page-logout'),
                title: 'Afmelden',
                data: {
                    allowAnonymous: false
                },
                viewport: 'global'
            } as IRoute,
            {
                path: '*',
                component: () => import('../views/page-not-found/page-not-found'),
                viewport: 'global'
            } as IRoute
        ] as IRoute[];
    }

    public static mainRoutes(): IRoute[] {
        return [
            {
                // We never want the path to be empty, so we redirect to the default route
                path: '',
                redirectTo: 'healthcare'
            },
            {
                id: 'healthcare',
                path: 'healthcare',
                component: () => import('../views/section-healthcare/section-healthcare'),
                title: '',
                settings: {
                    component: 'section-healthcare',
                    allowAnonymous: false,
                    authorization: [
                        UserRoles.Admin, //
                        UserRoles.Owner,
                        UserRoles.ReadSchedulerItems,
                        UserRoles.ReadPatients,
                        UserRoles.ReadHealthcareTasks,
                        UserRoles.ReadAccounts,
                        UserRoles.ReadContacts
                    ]
                },
                viewport: 'main'
            } as IRoute,
            {
                id: 'practice',
                path: 'practice',
                component: () => import('../views/section-practice/section-practice'),
                title: '',
                settings: {
                    component: 'section-practice',
                    allowAnonymous: false,
                    authorization: [
                        UserRoles.Admin, //
                        UserRoles.Owner,
                        UserRoles.WritePracticeLocations,
                        UserRoles.WriteInsurers,
                        UserRoles.WriteSchedules,
                        UserRoles.WriteAppointmentTypes,
                        UserRoles.WriteVatCategories,
                        UserRoles.WriteEmailTemplates,
                        UserRoles.WriteMedicalExaminationPhases
                    ]
                },
                viewport: 'main'
            } as IRoute,
            {
                id: 'finance',
                path: 'finance',
                component: () => import('../views/section-finance/section-finance'),
                title: '',
                settings: {
                    component: 'section-finance',
                    allowAnonymous: false,
                    authorization: [
                        UserRoles.Admin, //
                        UserRoles.Owner,
                        UserRoles.DeleteDeclarationPerformances,
                        UserRoles.ReadDeclarations
                    ]
                },
                viewport: 'main'
            } as IRoute,
            {
                path: '*',
                component: () => import('../views/page-not-found/page-not-found'),
                viewport: 'main'
            } as IRoute,
            {
                id: 'access-denied',
                path: 'access-denied',
                component: () => import('../views/page-access-denied/page-access-denied'),
                title: 'Geen toegang',
                data: {
                    allowAnonymous: false
                },
                viewport: 'main'
            } as IRoute
        ] as IRoute[];
    }

    public static healthcareRoutes(): IRoute[] {
        return [
            {
                // We never want the path to be empty, so we redirect to the default route
                path: '',
                redirectTo: 'dashboard'
            },
            {
                id: 'dashboard',
                path: 'dashboard/*',
                component: () => import('../views/page-healthcare-dashboard/page-healthcare-dashboard'),
                title: 'Dashboard',
                data: {
                    component: 'page-healthcare-dashboard',
                    allowAnonymous: false,
                    authorization: [
                        UserRoles.Owner, //
                        UserRoles.Admin,
                        UserRoles.ReadSchedulerItems,
                        UserRoles.ReadPatients,
                        UserRoles.ReadHealthcareTasks,
                        UserRoles.ReadAccounts,
                        UserRoles.ReadContacts
                    ]
                },
                viewport: 'healthcare'
            } as IRoute,
            {
                id: 'scheduler',
                path: 'scheduler/*',
                component: () => import('../views/page-scheduler/page-scheduler'),
                title: 'Scheduler',
                data: {
                    component: 'page-scheduler',
                    allowAnonymous: false,
                    authorization: [
                        UserRoles.Admin, //
                        UserRoles.Owner,
                        UserRoles.ReadSchedulerItems
                    ]
                },
                viewport: 'healthcare'
            } as IRoute,
            {
                id: 'patients',
                path: 'patients/*',
                component: () => import('../views/page-patients/page-patients'),
                title: 'Patients',
                data: {
                    component: 'page-patients',
                    allowAnonymous: false,
                    authorization: [
                        UserRoles.Admin, //
                        UserRoles.Owner,
                        UserRoles.ReadPatients
                    ]
                },
                viewport: 'healthcare'
            } as IRoute,
            {
                id: 'tasks',
                path: 'tasks/*',
                component: () => import('../views/page-tasks/page-tasks'),
                title: 'Tasks',
                data: {
                    component: 'page-tasks',
                    allowAnonymous: false,
                    authorization: [
                        UserRoles.Admin, //
                        UserRoles.Owner,
                        UserRoles.ReadHealthcareTasks
                    ]
                },
                viewport: 'healthcare'
            } as IRoute,
            {
                id: 'contacts',
                path: 'contacts/*',
                component: () => import('../views/page-contacts/page-contacts'),
                title: 'Contacts',
                data: {
                    component: 'page-contacts',
                    allowAnonymous: false,
                    authorization: [
                        UserRoles.Admin, //
                        UserRoles.Owner,
                        UserRoles.ReadContacts
                    ]
                },
                viewport: 'healthcare'
            } as IRoute,
            {
                id: 'accounts',
                path: 'accounts/*',
                component: () => import('../views/page-accounts/page-accounts'),
                title: 'Accounts',
                data: {
                    component: 'page-accounts',
                    allowAnonymous: false,
                    authorization: [
                        UserRoles.Admin, //
                        UserRoles.Owner,
                        UserRoles.ReadAccounts
                    ]
                },
                viewport: 'healthcare'
            } as IRoute,
            {
                id: 'access-denied',
                path: 'access-denied',
                component: () => import('../views/page-access-denied/page-access-denied'),
                title: 'Geen toegang',
                data: {
                    allowAnonymous: false
                },
                viewport: 'healthcare'
            } as IRoute
        ] as IRoute[];
    }

    public static practiceRoutes(): IRoute[] {
        return [
            {
                // We never want the path to be empty, so we redirect to the default route
                path: '',
                redirectTo: 'settings'
            },
            {
                id: 'settings',
                path: 'settings/*',
                component: () => import('../views/page-practice-settings/page-practice-settings'),
                title: 'Praktijk instellingen',
                data: {
                    component: 'page-practice-settings',
                    allowAnonymous: false,
                    authorization: [
                        UserRoles.Admin, //
                        UserRoles.Owner,
                        UserRoles.WritePracticeLocations,
                        UserRoles.WriteInsurers,
                        UserRoles.WriteSchedules,
                        UserRoles.WriteAppointmentTypes,
                        UserRoles.WriteVatCategories,
                        UserRoles.WriteEmailTemplates
                    ]
                },
                viewport: 'practice'
            } as IRoute,
            {
                id: 'templates',
                path: 'templates/*',
                component: () => import('../views/page-practice-templates/page-practice-templates'),
                title: 'Onderzoek templates',
                data: {
                    component: 'page-practice-templates',
                    allowAnonymous: false,
                    authorization: [
                        UserRoles.Admin, //
                        UserRoles.Owner,
                        UserRoles.WriteMedicalExaminationPhases,
                        UserRoles.WriteDifferentialDiagnoses,
                        UserRoles.WriteMedicalQuestionnaires,
                        UserRoles.WriteMedicalQuestions,
                        UserRoles.WriteMedicalExaminationActions,
                        UserRoles.WriteMedicalExaminationModels,
                        UserRoles.WriteMedicalTherapies,
                        UserRoles.WriteMedicalTreatmentProtocols,
                        UserRoles.WriteMedicalExaminations,
                        UserRoles.WriteAnatomicalRegions
                    ]
                },
                viewport: 'practice'
            } as IRoute,
            {
                id: 'integrations',
                path: 'integrations/*',
                component: () => import('../views/page-practice-integrations/page-practice-integrations'),
                title: 'Praktijk integraties',
                data: {
                    component: 'page-practice-integrations',
                    allowAnonymous: false,
                    authorization: [
                        UserRoles.Admin, //
                        UserRoles.Owner
                    ]
                },
                viewport: 'practice'
            } as IRoute,
            {
                id: 'master-data',
                path: 'master-data/*',
                component: () => import('../views/page-practice-master-data/page-practice-master-data'),
                title: 'Stamdata',
                data: {
                    component: 'page-practice-master-data',
                    allowAnonymous: false,
                    authorization: [
                        UserRoles.Admin, //
                        UserRoles.Owner
                    ]
                },
                viewport: 'practice'
            } as IRoute,
            {
                id: 'access-denied',
                path: 'access-denied',
                component: () => import('../views/page-access-denied/page-access-denied'),
                title: 'Geen toegang',
                data: {
                    allowAnonymous: false
                },
                viewport: 'practice'
            } as IRoute
        ] as IRoute[];
    }

    public static financeRoutes(): IRoute[] {
        return [
            {
                // We never want the path to be empty, so we redirect to the default route
                path: '',
                redirectTo: 'dashboard'
            },
            {
                id: 'dashboard',
                path: 'dashboard/*',
                component: () => import('../views/page-finance-dashboard/page-finance-dashboard'),
                title: 'Financieen',
                data: {
                    component: 'page-finance-dashboard',
                    allowAnonymous: false,
                    authorization: [
                        UserRoles.Admin, //
                        UserRoles.Owner,
                        UserRoles.DeleteDeclarationPerformances,
                        UserRoles.ReadDeclarations
                    ]
                },
                viewport: 'finance'
            } as IRoute,

            {
                id: 'declarations/performances',
                path: 'declarations/performances/*',
                component: () => import('../views/page-declaration-performances/page-declaration-performances'),
                title: 'Prestaties',
                data: {
                    component: 'page-declaration-performances',
                    allowAnonymous: false,
                    authorization: [
                        UserRoles.Admin, //
                        UserRoles.Owner,
                        UserRoles.DeleteDeclarationPerformances
                    ]
                },
                viewport: 'finance'
            } as IRoute,
            {
                id: 'declarations',
                path: 'declarations/*',
                component: () => import('../views/page-declarations/page-declarations'),
                title: 'Prestaties',
                data: {
                    component: 'page-declarations',
                    allowAnonymous: false,
                    authorization: [
                        UserRoles.Admin, //
                        UserRoles.Owner,
                        UserRoles.ReadDeclarations
                    ]
                },
                viewport: 'finance'
            } as IRoute,
            {
                id: 'access-denied',
                path: 'access-denied',
                component: () => import('../views/page-access-denied/page-access-denied'),
                title: 'Geen toegang',
                data: { allowAnonymous: false },
                viewport: 'finance'
            } as IRoute
        ] as IRoute[];
    }
}
