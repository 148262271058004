import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';

import { GetInvitationResponse, InvitationsApiClient } from '@wecore/sdk-management';
import { isDefined } from '@wecore/sdk-utilities';
import { IEventAggregator, inject } from 'aurelia';
import { PartialViewResults } from '../../../enums/partial-view-results';
import { BasePartialView } from '../../../infra/base-partial-view';
import { CacheService } from '../../../infra/cache-service';
import { ErrorHandler } from '../../../infra/error-handler';
import { State } from '../../../infra/store/state';
import { ConfirmationOptions } from '../../../models/confirmation-options';
import { PartialView } from '../../../models/partial-view';
import { ModalService } from '../../../services/service.modals';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, InvitationsApiClient, ModalService)
export class PartialManageInvitationsEdit extends BasePartialView {
    public invitation: GetInvitationResponse;
    private id: string;

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly invitationsApi: InvitationsApiClient,
        private readonly modalService: ModalService
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.id = view.data.id;
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                const [invitation] = await Promise.all([
                    this.invitationsApi.getById(this.id, this.authenticated.workspace.id) //
                ]);

                this.invitation = invitation;
                this.baseLoaded = true;
            })
            .catch((x) => this.errorHandler.handle('PartialManageInvitationsEdit.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }

    public async cancel(): Promise<void> {
        await super.remove({
            result: PartialViewResults.Cancelled,
            updateUrl: true
        });
    }

    public async save(): Promise<void> {
        this.isLoading = true;
        try {
            await this.invitationsApi.update(this.invitation.id, this.authenticated.workspace.id, this.invitation);
            this.notifications.show(
                this.t.tr('translation:partial-views.manage-users.notifications.save-invitation-successful.title'), //
                this.t.tr(`translation:partial-views.manage-users.notifications.save-invitation-successful.message`),
                {
                    type: 'success',
                    duration: 3000
                }
            );
            setTimeout(async () => this.remove({ result: PartialViewResults.Ok, updateUrl: true }), 250);
        } catch (e) {
            this.isLoading = false;
            await this.errorHandler.handle('[manage-invitations-edit]', e);
        }
    }

    public async delete(): Promise<void> {
        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('translation:partial-views.manage-users.questions.delete-invitation.title'),
                message: isDefined(this.invitation.createdFor)
                    ? this.t
                          .tr('translation:partial-views.manage-users.questions.delete-invitation.message-user') //
                          .replace('{user}', `<span>'${this.invitation.createdFor.name}'</span>`)
                    : this.t
                          .tr('translation:partial-views.manage-users.questions.delete-invitation.message-email') //
                          .replace('{email}', `<span>'${this.invitation.email}'</span>`),
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        this.deleting = true;
                        try {
                            await this.invitationsApi.delete(this.invitation.id, this.authenticated.workspace.id);
                            this.notifications.show(
                                this.t.tr('translation:partial-views.manage-users.notifications.deleted-invitation-successfully.title'),
                                this.t.tr('translation:partial-views.manage-users.notifications.deleted-invitation-successfully.message'),
                                { type: 'success', duration: 3000 }
                            );
                            setTimeout(async () => this.remove({ result: PartialViewResults.Deleted, updateUrl: true }), 250);
                        } catch (e) {
                            this.deleting = false;
                            await this.errorHandler.handle('[delete-examination-phase-detail]', e);
                        }
                    }
                }
            })
        );
    }
}
