import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { GetUserResponse } from '@wecore/sdk-core';
import {
    GetHealthcareTaskLabelResponse,
    GetHealthcareTaskListResponse,
    GetHealthcareTaskResponse,
    GetPatientResponse,
    HealthcareTaskLabelTypes,
    HealthcareTaskListsApiClient,
    HealthcareTasksApiClient,
    PatientEntityReference,
    UserEntityReference
} from '@wecore/sdk-healthcare';
import { isDefined, isNotDefined, validateState } from '@wecore/sdk-utilities';

import { IEventAggregator, inject } from 'aurelia';
import { startOfDay } from 'date-fns';
import { PartialViewResults } from '../../../enums/partial-view-results';
import { BasePartialView } from '../../../infra/base-partial-view';
import { CacheService } from '../../../infra/cache-service';
import { ErrorHandler } from '../../../infra/error-handler';
import { State } from '../../../infra/store/state';
import { cleanTranslatables, setTranslation, validateTranslation } from '../../../infra/utilities';
import { ConfirmationOptions } from '../../../models/confirmation-options';
import { EventDetails } from '../../../models/event-details';
import { PartialView } from '../../../models/partial-view';
import { ModalService } from '../../../services/service.modals';
import { UxDatepicker } from '../../../ux/ux-datepicker/ux-datepicker';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, HealthcareTasksApiClient, HealthcareTaskListsApiClient, ModalService)
export class PartialTasksEdit extends BasePartialView {
    public task: GetHealthcareTaskResponse;
    public HealthcareTaskLabelTypes: typeof HealthcareTaskLabelTypes = HealthcareTaskLabelTypes;
    public validation = {
        title: true
    };

    public shouldClose: boolean = false;
    public shouldReopen: boolean = false;
    public list: GetHealthcareTaskListResponse;
    private taksId: string;

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly tasksApi: HealthcareTasksApiClient,
        private readonly listsApi: HealthcareTaskListsApiClient,
        private readonly modalService: ModalService
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.taksId = view.data?.id;
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                this.task = await this.tasksApi.getById(this.taksId, this.authenticated.workspace.id);
                if (isDefined(this.task.list)) {
                    const [list] = await Promise.all([
                        this.listsApi.getById(this.task.list.id, this.authenticated.workspace.id) //
                    ]);
                    this.list = list;
                }

                // Delay showing content to prevent flickering.
                setTimeout(async () => {
                    this.baseLoaded = true;
                    await super.handleBaseLoaded();
                }, 250);
            })
            .catch((x) => this.errorHandler.handle('PartialTasksEdit.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }

    public async cancel(): Promise<void> {
        await super.remove({
            result: PartialViewResults.Cancelled,
            updateUrl: true
        });
    }

    public async save(): Promise<void> {
        const valid = this.validate();

        if (valid) {
            this.isLoading = true;
            try {
                cleanTranslatables(['title', 'description'], this.task, 1);
                if (isDefined(this.task.deadline)) this.task.deadline = startOfDay(this.task.deadline);
                const response = await this.tasksApi.update(this.taksId, this.authenticated.workspace.id, this.task);
                this.notifications.show(
                    this.t.tr('translation:partial-views.tasks.notifications.save-successful.title'),
                    this.t
                        .tr('translation:partial-views.tasks.notifications.save-successful.message') //
                        .replace('{entity}', `<span>'${this.task.title[this.language]}'</span>`),
                    {
                        type: 'success',
                        duration: 3000
                    }
                );

                if (this.shouldClose) await this.tasksApi.close(this.authenticated.workspace.id, [this.task.id]);
                if (this.shouldReopen) await this.tasksApi.reopen(this.authenticated.workspace.id, [this.task.id]);

                setTimeout(async () => this.remove({ result: PartialViewResults.Ok, data: { task: response }, updateUrl: true }), 250);
            } catch (e) {
                // When the save() goes wrong make sure to re-set the translations object that
                // are possibly set to null and are not required for this request.
                // Otherwise the cleanTranslatables() will fail because the translations object is null.
                if (isNotDefined(this.task.description)) this.task.description = setTranslation({}, this.language);
                this.isLoading = false;
                await this.errorHandler.handle('[edit-task-list]', e);
            }
        }
    }

    public async handleDeadlineSelected(e: CustomEvent<EventDetails<UxDatepicker, any>>): Promise<void> {
        const date = new Date(e.detail.values.date);
        this.task.deadline = date;
    }

    public manageTranslationsFor(property: string, required: boolean = false): void {
        this.manageTranslations({
            translations: this.task[property],
            callback: (updatedTranslations: any) => {
                this.task[property] = updatedTranslations;
            },
            required,
            type: property === 'description' ? 'textarea' : 'input'
        });
    }

    public handleUserSelected = (_: GetUserResponse[], __: 'added' | 'deleted', user: GetUserResponse): void => {
        const index = this.task.assignedTo.findIndex((x) => x.id === user?.id);
        if (index > -1) this.task.assignedTo.splice(index, 1);
        else if (isDefined(user))
            this.task.assignedTo = [
                ...this.task.assignedTo,
                new UserEntityReference({
                    id: user.id,
                    name: user.displayName,
                    data: {
                        avatarColor: user.avatar.color,
                        avatarShade: user.avatar.shade.toString(),
                        email: user.email
                    }
                })
            ];
    };

    public handlePatientSelected = (_: GetPatientResponse[], __: 'added' | 'deleted', patient: GetPatientResponse): void => {
        if (isNotDefined(this.task.patients)) this.task.patients = [];
        const index = this.task.patients.findIndex((x) => x.id === patient.id);
        if (index > -1) this.task.patients.splice(index, 1);
        else
            this.task.patients = [
                ...this.task.patients,
                new PatientEntityReference({
                    id: patient.id,
                    name: patient.displayName,
                    data: {
                        avatarColor: patient.avatar.color,
                        avatarShade: patient.avatar.shade.toString()
                    }
                })
            ];
    };

    public handleLabelSelected = (_: GetHealthcareTaskLabelResponse[], __: 'added' | 'deleted', label: GetHealthcareTaskLabelResponse): void => {
        const index = this.task.labels.findIndex((x) => x.id === label.id);
        if (index > -1) this.task.labels.splice(index, 1);
        else this.task.labels = [...this.task.labels, label];
    };

    public handleDeadlineCleared(e: CustomEvent<EventDetails<UxDatepicker, any>>) {
        this.task.deadline = null;
    }

    public handlePrioritySelected = async (priority: GetHealthcareTaskLabelResponse): Promise<void> => {
        this.task.priority = priority;
    };

    public handleStatusSelected = async (status: GetHealthcareTaskLabelResponse): Promise<void> => {
        if (isNotDefined(status)) {
            this.task.status = null;
            this.shouldReopen = true;
            return;
        }

        this.shouldClose = false;
        this.shouldReopen = false;

        // Lets check if we should close or reopen the task.
        if (this.task.isClosed)
            // If the task is closed and status does not close the task then we should reopen the task.
            this.shouldReopen = !status.shouldCloseTask;
        // If the task is not closed and status does close the task then we should close the task.
        else this.shouldClose = status.shouldCloseTask;

        this.task.status = status;
    };

    public async delete(): Promise<void> {
        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('translation:partial-views.tasks.questions.delete.title'),
                message: this.t
                    .tr('translation:partial-views.tasks.questions.delete.message') //
                    .replace('{entity}', `<span>'${this.task.title[this.language]}'</span>`),
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        try {
                            await this.tasksApi.delete(this.task.id, this.authenticated.workspace.id);
                            await this.remove({ result: PartialViewResults.Deleted, updateUrl: true });
                            this.notifications.show(
                                this.t.tr('translation:partial-views.tasks.notifications.deleted-successfully.title'),
                                this.t
                                    .tr('translation:partial-views.tasks.notifications.deleted-successfully.message') //
                                    .replace('{entity}', `<span>'${this.task.title[this.language]}'</span>`),
                                { type: 'success', duration: 3000 }
                            );
                        } catch (e) {
                            await this.errorHandler.handle('[delete-healthcare-task]', e);
                        }
                    }
                }
            })
        );
    }

    private validate(): boolean {
        this.validation.title = validateTranslation(this.task.title, this.language);
        return validateState(this.validation);
    }
}
