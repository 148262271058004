<import from="converters/keys" />
<import from="converters/filter-document-versions" />

<template class="!flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex overflow-hidden" css.bind="styles">
        <div class="flex flex-col flex-1 h-full">
            <template if.bind="baseLoaded">
                <section class="flex flex-col h-full">
                    <ux-header padding="py-5 px-8">
                        <div class="flex items-center justify-between">
                            <div class="flex flex-col">
                                <h6 class="mb-0 leading-tight" t="translation:partial-views.user-settings.labels.title"></h6>
                                <span class="block text-sm leading-tight text-gray-500" t="translation:partial-views.user-settings.messages.manage-settings"></span>
                            </div>
                        </div>
                    </ux-header>
                    <div class="flex h-full pl-3 overflow-hidden">
                        <div class="flex flex-col flex-1 h-full pr-5 overflow-hidden sm:flex-row sm:pr-0">
                            <div class="flex py-6 pl-5 overflow-hidden border-r border-gray-200 w-44">
                                <ul class="flex items-center w-full gap-x-3 sm:flex-col sm:items-start sm:space-x-0 sm:space-y-3">
                                    <li class="w-full pr-3 group" repeat.for="key of tabs | keys">
                                        <a
                                            class="${ tabs[key].valid ? tabs[key].active ? 'text-gray-900 font-semibold': 'text-gray-500 group-hover:text-gray-900': tabs[key].active ? 'text-red-500 font-semibold':'text-red-500 group-hover:text-red-600' } relative"
                                            href="javascript:"
                                            click.trigger="setActiveTab(key)">
                                            <div class="flex items-center gap-1">
                                                <span class="text-sm">${ tabs[key].name }</span>
                                                <svg if.bind="!tabs[key].valid" class="w-5 h-5 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        stroke-width="2"
                                                        d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"></path>
                                                </svg>
                                                <div
                                                    if.bind="tabs[key].valid && key === 'agreements' && unsigned.individual.length > 0"
                                                    class="absolute right-0 ml-1 flex size-[18px] items-center justify-center rounded-lg bg-red-500 text-xxs leading-none text-white">
                                                    <p class="relative leading-none">${unsigned.individual.length}</p>
                                                </div>
                                                <div
                                                    if.bind="tabs[key].valid && key === 'workspaces' && invitations.length > 0"
                                                    class="absolute right-0 ml-1 flex size-[18px] items-center justify-center rounded-lg bg-red-500 text-xxs leading-none text-white">
                                                    <p class="relative top-[0.5px] leading-none">${invitations.length}</p>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div class="flex flex-col flex-1 gap-8 overflow-hidden">
                                <!-- General tab -->
                                <div show.bind="tabs.general.active" class="flex flex-col flex-1 gap-8 px-10 pt-6 pb-10 overflow-y-auto">
                                    <!-- Account settings -->
                                    <div class="pb-12 border-b border-gray-900/10">
                                        <h2 class="text-base font-semibold leading-7 text-gray-900" t="translation:partial-views.user-settings.labels.personal-info"></h2>
                                        <p class="mt-1 text-sm leading-6 text-gray-600" t="translation:partial-views.user-settings.messages.personal-info"></p>
                                        <div class="grid grid-cols-12 gap-4 mt-6">
                                            <div class="flex flex-col col-span-2">
                                                <ux-label class="mb-1">
                                                    <span t="translation:global.labels.initials"></span>
                                                </ux-label>
                                                <ux-input value.two-way="user.initials"></ux-input>
                                            </div>
                                            <div class="flex flex-col col-span-4">
                                                <ux-label class="mb-1">
                                                    <span t="translation:global.labels.first-name"></span>
                                                </ux-label>
                                                <ux-input
                                                    value.two-way="user.firstName"
                                                    valid.bind="validation.firstName"
                                                    t="[placeholder]translation:views.onboarding-user.placeholders.first-name"></ux-input>
                                            </div>
                                            <div class="flex flex-col col-span-2">
                                                <ux-label class="mb-1">
                                                    <span t="translation:global.labels.last-name-preposition"></span>
                                                </ux-label>
                                                <ux-input value.two-way="user.lastNamePreposition" t="[placeholder]translation:views.onboarding-user.placeholders.last-name-preposition"></ux-input>
                                            </div>
                                            <div class="flex flex-col col-span-4">
                                                <ux-label class="mb-1">
                                                    <span t="translation:global.labels.last-name"></span>
                                                </ux-label>
                                                <ux-input
                                                    value.two-way="user.lastName"
                                                    valid.bind="validation.lastName"
                                                    t="[placeholder]translation:views.onboarding-user.placeholders.last-name"></ux-input>
                                            </div>
                                            <div class="col-span-full">
                                                <label class="block text-sm font-medium leading-6 text-gray-900" t="translation:global.labels.avatar"></label>
                                                <div class="flex items-center mt-2 gap-x-5">
                                                    <ux-image if.bind="avatarSrc" bg-size="contain" width="90px" height="90px" border.bind="true" src.bind="avatarSrc"></ux-image>
                                                    <div else class="flex h-[90px] w-[90px] min-w-[90px] items-center justify-center rounded-md border border-gray-100 bg-gray-100">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="text-gray-300 size-12">
                                                            <path
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                                d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                                        </svg>
                                                    </div>
                                                    <div class="flex flex-col gap-2">
                                                        <input change.trigger="handleFilesSelected($event)" type="file" class="hidden" ref="fileInput" accept=".jpg,.jpeg,.png" multiple="0" />
                                                        <div class="flex gap-2">
                                                            <ux-button color="primary" click.trigger="selectAvatar()">
                                                                <span t="translation:global.labels.change"></span>
                                                            </ux-button>
                                                            <ux-button if.bind="avatarSrc" color="danger" click.trigger="deleteAvatar()">
                                                                <span t="translation:global.buttons.delete"></span>
                                                            </ux-button>
                                                        </div>
                                                        <p class="text-xs text-gray-500" t="translation:partial-views.user-settings.messages.avatar"></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="flex flex-col col-span-4">
                                                <ux-label class="mb-1">
                                                    <span t="translation:global.labels.sex"></span>
                                                </ux-label>
                                                <ux-select value.two-way="user.gender" allow-clear.bind="true">
                                                    <ux-select-option value.bind="Genders.Male">
                                                        <span t="translation:global.labels.male"></span>
                                                    </ux-select-option>
                                                    <ux-select-option value.bind="Genders.Female">
                                                        <span t="translation:global.labels.female"></span>
                                                    </ux-select-option>
                                                    <ux-select-option value.bind="Genders.Other">
                                                        <span t="translation:global.labels.other"></span>
                                                    </ux-select-option>
                                                </ux-select>
                                            </div>
                                            <div class="flex flex-col col-span-4">
                                                <ux-label class="mb-1">
                                                    <span t="translation:global.labels.dob"></span>
                                                </ux-label>
                                                <ux-datepicker value.two-way="user.dateOfBirth" allow-clear.bind="true" mode="input"></ux-datepicker>
                                            </div>
                                            <div class="flex flex-col col-span-4">
                                                <ux-label class="mb-1">
                                                    <span t="translation:partial-views.user-settings.labels.application-language"></span>
                                                </ux-label>
                                                <ux-select value.two-way="user.settings.language">
                                                    <ux-select-option value="nl">
                                                        <div class="flex items-center gap-2">
                                                            <svg class="size-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                                                                <path fill="#fff" d="M1 11H31V21H1z"></path>
                                                                <path d="M5,4H27c2.208,0,4,1.792,4,4v4H1v-4c0-2.208,1.792-4,4-4Z" fill="#a1292a"></path>
                                                                <path d="M5,20H27c2.208,0,4,1.792,4,4v4H1v-4c0-2.208,1.792-4,4-4Z" transform="rotate(180 16 24)" fill="#264387"></path>
                                                                <path
                                                                    d="M27,4H5c-2.209,0-4,1.791-4,4V24c0,2.209,1.791,4,4,4H27c2.209,0,4-1.791,4-4V8c0-2.209-1.791-4-4-4Zm3,20c0,1.654-1.346,3-3,3H5c-1.654,0-3-1.346-3-3V8c0-1.654,1.346-3,3-3H27c1.654,0,3,1.346,3,3V24Z"
                                                                    opacity=".15"></path>
                                                                <path
                                                                    d="M27,5H5c-1.657,0-3,1.343-3,3v1c0-1.657,1.343-3,3-3H27c1.657,0,3,1.343,3,3v-1c0-1.657-1.343-3-3-3Z"
                                                                    fill="#fff"
                                                                    opacity=".2"></path>
                                                            </svg>
                                                            <span t="translation:global.languages.nl"></span>
                                                        </div>
                                                    </ux-select-option>
                                                    <ux-select-option value="en">
                                                        <div class="flex items-center gap-2">
                                                            <svg class="size-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                                                                <rect x="1" y="4" width="30" height="24" rx="4" ry="4" fill="#071b65"></rect>
                                                                <path d="M5.101,4h-.101c-1.981,0-3.615,1.444-3.933,3.334L26.899,28h.101c1.981,0,3.615-1.444,3.933-3.334L5.101,4Z" fill="#fff"></path>
                                                                <path d="M22.25,19h-2.5l9.934,7.947c.387-.353,.704-.777,.929-1.257l-8.363-6.691Z" fill="#b92932"></path>
                                                                <path d="M1.387,6.309l8.363,6.691h2.5L2.316,5.053c-.387,.353-.704,.777-.929,1.257Z" fill="#b92932"></path>
                                                                <path
                                                                    d="M5,28h.101L30.933,7.334c-.318-1.891-1.952-3.334-3.933-3.334h-.101L1.067,24.666c.318,1.891,1.952,3.334,3.933,3.334Z"
                                                                    fill="#fff"></path>
                                                                <rect x="13" y="4" width="6" height="24" fill="#fff"></rect>
                                                                <rect x="1" y="13" width="30" height="6" fill="#fff"></rect>
                                                                <rect x="14" y="4" width="4" height="24" fill="#b92932"></rect>
                                                                <rect x="14" y="1" width="4" height="30" transform="translate(32) rotate(90)" fill="#b92932"></rect>
                                                                <path d="M28.222,4.21l-9.222,7.376v1.414h.75l9.943-7.94c-.419-.384-.918-.671-1.471-.85Z" fill="#b92932"></path>
                                                                <path d="M2.328,26.957c.414,.374,.904,.656,1.447,.832l9.225-7.38v-1.408h-.75L2.328,26.957Z" fill="#b92932"></path>
                                                                <path
                                                                    d="M27,4H5c-2.209,0-4,1.791-4,4V24c0,2.209,1.791,4,4,4H27c2.209,0,4-1.791,4-4V8c0-2.209-1.791-4-4-4Zm3,20c0,1.654-1.346,3-3,3H5c-1.654,0-3-1.346-3-3V8c0-1.654,1.346-3,3-3H27c1.654,0,3,1.346,3,3V24Z"
                                                                    opacity=".15"></path>
                                                                <path
                                                                    d="M27,5H5c-1.657,0-3,1.343-3,3v1c0-1.657,1.343-3,3-3H27c1.657,0,3,1.343,3,3v-1c0-1.657-1.343-3-3-3Z"
                                                                    fill="#fff"
                                                                    opacity=".2"></path>
                                                            </svg>
                                                            <span t="translation:global.languages.en"></span>
                                                        </div>
                                                    </ux-select-option>
                                                </ux-select>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Professional settings -->
                                    <div class="border-gray-900/10">
                                        <h2 class="text-base font-semibold leading-7 text-gray-900" t="translation:partial-views.user-settings.labels.care-info"></h2>
                                        <p class="mt-1 text-sm leading-6 text-gray-600" t="translation:partial-views.user-settings.messages.care-info"></p>
                                        <div class="grid grid-cols-12 gap-6 mt-10">
                                            <div class="flex flex-col col-span-4">
                                                <ux-label class="mb-1">
                                                    <span t="translation:global.labels.agb-code"></span>
                                                </ux-label>
                                                <ux-input value.two-way="user.extensions.healthcare.agbCode" t="[placeholder]translation:views.onboarding-user.placeholders.agb-code"></ux-input>
                                            </div>
                                            <div class="flex flex-col col-span-4">
                                                <ux-label class="mb-1">
                                                    <span t="translation:global.labels.big-code"></span>
                                                </ux-label>
                                                <ux-input value.two-way="user.extensions.healthcare.bigCode" t="[placeholder]translation:views.onboarding-user.placeholders.big-code"></ux-input>
                                            </div>
                                            <div class="flex flex-col col-span-4">
                                                <ux-label class="mb-1">
                                                    <span t="translation:global.labels.quality-register-code"></span>
                                                </ux-label>
                                                <ux-input
                                                    value.two-way="user.extensions.healthcare.qualityRegisterCode"
                                                    t="[placeholder]translation:views.onboarding-user.placeholders.quality-register-code"></ux-input>
                                            </div>
                                            <div class="flex flex-col col-span-12">
                                                <ux-label class="mb-1">
                                                    <span t="translation:views.onboarding-user.labels.membership-code"></span>
                                                </ux-label>
                                                <ux-input
                                                    value.two-way="user.extensions.healthcare.membershipCode"
                                                    t="[placeholder]translation:views.onboarding-user.placeholders.membership-code"></ux-input>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Authentication tab -->
                                <div show.bind="tabs.authentication.active" class="flex flex-col gap-8 px-10 py-6 overflow-y-auto">
                                    <!-- Email/Password settings -->
                                    <div class="pb-12 border-b border-gray-900/10">
                                        <h2 class="text-base font-semibold leading-7 text-gray-900" t="translation:partial-views.user-settings.labels.login-info"></h2>
                                        <p class="mt-1 text-sm leading-6 text-gray-600" t="translation:partial-views.user-settings.messages.login-info"></p>

                                        <div class="grid grid-cols-12 gap-6 mt-6">
                                            <div class="flex flex-col col-span-8">
                                                <ux-label class="mb-1">
                                                    <span t="translation:global.labels.email"></span>
                                                </ux-label>
                                                <ux-input value.two-way="user.email" valid.bind="validation.email && validation.unique"></ux-input>
                                                <p if.bind="!validation.unique" class="mt-1 text-xs text-red-500" t="translation:global.errors.email-in-use"></p>
                                            </div>
                                            <div class="flex flex-col col-span-4">
                                                <ux-label class="mb-1">
                                                    <span t="translation:partial-views.user-settings.labels.current-password"></span>
                                                </ux-label>
                                                <ux-input value.two-way="currentPassword" valid.bind="validation.currentPassword && validation.currentPasswordValid" type="password"></ux-input>
                                            </div>
                                            <div class="flex flex-col col-span-6">
                                                <ux-label class="mb-1">
                                                    <span t="translation:partial-views.user-settings.labels.password"></span>
                                                </ux-label>
                                                <ux-input value.two-way="password" valid.bind="validation.password && validation.match && validation.length" type="password"> </ux-input>
                                            </div>
                                            <div class="flex flex-col col-span-6">
                                                <ux-label class="mb-1">
                                                    <span t="translation:partial-views.user-settings.labels.repeat-password"></span>
                                                </ux-label>
                                                <ux-input value.two-way="repeatPassword" valid.bind="validation.repeat && validation.match" type="password"></ux-input>
                                            </div>
                                            <div
                                                if.bind="!validation.length || !validation.valid || !validation.match || !validation.currentPasswordValid"
                                                class="col-span-12 mb-3 text-xs text-red-500">
                                                <p if.bind="!validation.length" t="translation:views.reset-password.messages.requirement-length"></p>
                                                <p if.bind="!validation.match" t="translation:views.reset-password.messages.requirement-match"></p>
                                                <p if.bind="!validation.password" t="translation:views.reset-password.messages.requirement-valid"></p>
                                                <p if.bind="!validation.currentPasswordValid" t="translation:partial-views.user-settings.messages.invalid-current-password"></p>
                                            </div>
                                            <div class="col-span-12">
                                                <ux-alert color="info">
                                                    <p class="mb-2 text-sm font-semibold" t="translation:views.reset-password.labels.rules"></p>
                                                    <ul class="list-disc">
                                                        <li class="text-sm" t="translation:views.reset-password.labels.requirement-lowercase"></li>
                                                        <li class="text-sm" t="translation:views.reset-password.labels.requirement-uppercase"></li>
                                                        <li class="text-sm" t="translation:views.reset-password.labels.requirement-numeric"></li>
                                                        <li class="text-sm" t="translation:views.reset-password.labels.requirement-symbols"></li>
                                                        <li class="text-sm" t="translation:views.reset-password.labels.requirement-length"></li>
                                                    </ul>
                                                </ux-alert>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- MFA -->
                                    <div class="pb-12">
                                        <h2 class="text-base font-semibold leading-7 text-gray-900" t="translation:partial-views.user-settings.labels.mfa-info"></h2>
                                        <p class="mt-1 text-sm leading-6 text-gray-600" t="translation:partial-views.user-settings.messages.mfa-info"></p>
                                        <div class="grid grid-cols-12 gap-6 mt-6">
                                            <div class="flex flex-col col-span-12">
                                                <template if.bind="user.factorId && !qr">
                                                    <div class="flex flex-col w-full gap-4 p-8 rounded-lg bg-gray-50">
                                                        <p class="text-sm" t="translation:partial-views.user-settings.messages.already-enrolled"></p>
                                                        <ux-button disabled.bind="loadingMfa" color="danger" loading.bind="loadingMfa" click.trigger="unenroll()">
                                                            <span t="translation:views.enroll-mfa.buttons.unenroll"></span
                                                        ></ux-button>
                                                    </div>
                                                </template>
                                                <template else>
                                                    <div if.bind="qr" class="flex items-start w-full gap-4 px-8 pb-8 rounded-lg bg-gray-50 pt-7">
                                                        <img src.bind="qr" alt="qr-code" />
                                                        <div class="flex flex-col items-end flex-1 gap-2 pt-4">
                                                            <ux-input
                                                                disabled.bind="loadingMfa"
                                                                autofocus.bind="true"
                                                                valid.bind="validation.token"
                                                                value.two-way="token"
                                                                t="[placeholder]translation:partial-views.user-settings.placeholders.mfa-token">
                                                            </ux-input>
                                                            <ux-button disabled.bind="loadingMfa" loading.bind="loadingMfa" click.trigger="verify()">
                                                                <span t="translation:views.enroll-mfa.buttons.verify-token"></span>
                                                            </ux-button>
                                                        </div>
                                                    </div>
                                                    <div if.bind="!qr" class="flex flex-col gap-3 p-8 rounded-lg bg-gray-50">
                                                        <p class="text-sm" t="translation:partial-views.user-settings.messages.mfa-intro"></p>
                                                        <ux-button disabled.bind="loadingMfa" loading.bind="loadingMfa" click.trigger="enroll()">
                                                            <span t="translation:global.buttons.start"></span>
                                                        </ux-button>
                                                    </div>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Workspaces tab -->
                                <div show.bind="tabs.workspaces.active" class="flex flex-col gap-5 px-10 py-6 overflow-y-auto">
                                    <!-- Email/Password settings -->
                                    <div border-b.class="workspaces.length > 0" class="pb-12 border-gray-900/10">
                                        <h2 class="text-base font-semibold leading-7 text-gray-900" t="translation:partial-views.user-settings.labels.workspaces-info"></h2>
                                        <p class="mt-1 text-sm leading-6 text-gray-600" t="translation:partial-views.user-settings.messages.workspaces-info"></p>

                                        <div if.bind="invitations.length > 0" class="flex flex-col pt-6 pb-8 mt-4 border-gray-200 border-y">
                                            <p class="mb-2 text-sm" t="translation:partial-views.manage-users.messages.you-have-invitations"></p>
                                            <ul class="flex flex-col">
                                                <li repeat.for="invitation of invitations" class="flex gap-5 px-5 py-3 border border-gray-300 rounded-lg">
                                                    <div class="flex items-center justify-center text-yellow-800 bg-yellow-100 rounded-full size-10">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5">
                                                            <path
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                                d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" />
                                                        </svg>
                                                    </div>
                                                    <div class="flex-1">
                                                        <p
                                                            class="text-sm"
                                                            t="translation:partial-views.manage-users.messages.you-have-been-invited"
                                                            t-params.bind="{ user: invitation.createdBy.name, workspace: invitation.workspaceName}"></p>
                                                    </div>
                                                    <div class="flex">
                                                        <ux-button-group>
                                                            <ux-button click.trigger="acceptInvitation(invitation)" color="success" padding.bind="false">
                                                                <div class="p-2">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5">
                                                                        <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                                                                    </svg>
                                                                </div>
                                                            </ux-button>
                                                            <ux-button click.trigger="declineInvitation(invitation)" color="danger" padding.bind="false">
                                                                <div class="p-2">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5">
                                                                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                                                                    </svg>
                                                                </div>
                                                            </ux-button>
                                                        </ux-button-group>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>

                                        <div class="grid grid-cols-12 gap-2 mt-4">
                                            <div repeat.for="workspace of workspaces" class="flex items-center justify-between col-span-6 gap-3 px-5 py-3 border border-gray-300 rounded-md">
                                                <div class="flex items-center gap-3">
                                                    <ux-avatar size="sm" name.bind="workspace.name" color.bind="workspace.avatar.color" shade.bind="workspace.avatar.shade"></ux-avatar>
                                                    <div class="flex flex-col text-sm">
                                                        <p class="leading-tight">${workspace.name}</p>
                                                        <p class="text-sm leading-tight text-gray-400">${workspace.id}</p>
                                                    </div>
                                                </div>
                                                <ux-dropdown border.bind="false" type="full" color="dark" size="sm" padding.bind="false" mode="outline" placement="bottom-right">
                                                    <div au-slot="label" class="p-1 text-gray-600 rounded-full ring-gray-400 focus:outline-none focus:ring-1">
                                                        <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512">
                                                            <path
                                                                fill="currentColor"
                                                                d="M64 368C90.51 368 112 389.5 112 416C112 442.5 90.51 464 64 464C37.49 464 16 442.5 16 416C16 389.5 37.49 368 64 368zM64 208C90.51 208 112 229.5 112 256C112 282.5 90.51 304 64 304C37.49 304 16 282.5 16 256C16 229.5 37.49 208 64 208zM64 144C37.49 144 16 122.5 16 96C16 69.49 37.49 48 64 48C90.51 48 112 69.49 112 96C112 122.5 90.51 144 64 144z" />
                                                        </svg>
                                                    </div>
                                                    <ux-dropdown-option click.trigger="open(workspace)">
                                                        <span class="text-sm" t="translation:global.buttons.open"></span>
                                                    </ux-dropdown-option>
                                                    <ux-dropdown-divider if.bind="isNotOwner(workspace)"></ux-dropdown-divider>
                                                    <ux-dropdown-option if.bind="isNotOwner(workspace)" click.trigger="removeFromWorkspace(workspace)">
                                                        <span class="text-sm text-red-500" t="translation:global.buttons.leave"></span>
                                                    </ux-dropdown-option>
                                                </ux-dropdown>
                                            </div>
                                        </div>
                                        <div if.bind="workspaces.length === 0" class="flex flex-col items-center justify-center p-20 mt-5 border border-gray-200 rounded-lg">
                                            <div class="flex flex-col gap-4">
                                                <p class="text-sm" t="translation:partial-views.user-settings.messages.no-workspaces"></p>
                                                <ux-button click.trigger="onboard()">
                                                    <span t="translation:partial-views.user-settings.buttons.create-workspace"></span>
                                                </ux-button>
                                            </div>
                                        </div>
                                    </div>
                                    <ux-button if.bind="workspaces.length > 0" click.trigger="onboard()">
                                        <span t="translation:partial-views.user-settings.buttons.create-workspace"></span>
                                    </ux-button>
                                </div>
                                <!-- Agreements tab -->
                                <div show.bind="tabs.agreements.active" class="flex flex-col gap-8 px-10 py-6 overflow-y-auto">
                                    <div class="flex flex-col">
                                        <h2 class="text-base font-semibold leading-7 text-gray-900" t="translation:global.labels.agreements"></h2>
                                        <p class="mt-1 text-sm leading-6 text-gray-600" t="translation:partial-views.user-settings.messages.agreements"></p>
                                        <div class="grid grid-cols-12 gap-6 mt-8">
                                            <div if.bind="versions" class="flex flex-col w-full col-span-12">
                                                <ul class="pl-0">
                                                    <li class="flex flex-col">
                                                        <div class="flex flex-col w-full">
                                                            <p class="text-sm font-semibold">
                                                                <span t="translation:partial-views.user-settings.labels.terms-and-conditions"></span>
                                                            </p>
                                                            <span class="text-sm text-gray-500" t="translation:partial-views.user-settings.messages.terms-and-conditions"> </span>
                                                        </div>
                                                        <ul class="pl-0 mt-2">
                                                            <li
                                                                repeat.for="version of versions.termsAndConditions | filterDocumentVersions:'termsAndConditions':user"
                                                                class="flex justify-between mt-2">
                                                                <a
                                                                    if.bind="hasSignedVersion('termsAndConditions', version)"
                                                                    href="javascript:"
                                                                    click.trigger="showDocument('terms-and-conditions', version)"
                                                                    class="text-sm underline decoration-primary decoration-2">
                                                                    <span t="translation:global.labels.version"></span>
                                                                    <span>${version}</span>
                                                                </a>
                                                                <a
                                                                    else
                                                                    href="javascript:"
                                                                    click.trigger="sign('terms-and-conditions', version)"
                                                                    class="text-sm underline decoration-primary decoration-2">
                                                                    <span t="translation:global.labels.version"></span>
                                                                    <span>${version}</span>
                                                                </a>
                                                                <ux-badge if.bind="hasSignedVersion('termsAndConditions', version)" type="success">
                                                                    <span t="translation:global.labels.signed"></span>
                                                                </ux-badge>
                                                                <ux-badge else type="danger">
                                                                    <span t="translation:partial-views.user-settings.labels.still-to-sign"></span>
                                                                </ux-badge>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    <li class="flex flex-col">
                                                        <ux-body-divider margin="mt-5 mb-4"></ux-body-divider>
                                                        <div class="flex flex-col w-full">
                                                            <p class="text-sm font-semibold">
                                                                <span t="translation:partial-views.user-settings.labels.eula"></span>
                                                            </p>
                                                            <span class="text-sm text-gray-500" t="translation:partial-views.user-settings.messages.eula"> </span>
                                                        </div>
                                                        <ul class="pl-0 mt-2">
                                                            <li repeat.for="version of versions.eula | filterDocumentVersions:'eulas':user" class="flex justify-between mt-2">
                                                                <a
                                                                    if.bind="hasSignedVersion('eulas', version)"
                                                                    href="javascript:"
                                                                    click.trigger="showDocument('eula', version)"
                                                                    class="text-sm underline decoration-primary decoration-2">
                                                                    <span t="translation:global.labels.version"></span>
                                                                    <span>${version}</span>
                                                                </a>
                                                                <a else href="javascript:" click.trigger="sign('eula', version)" class="text-sm underline decoration-primary decoration-2">
                                                                    <span t="translation:global.labels.version"></span>
                                                                    <span>${version}</span>
                                                                </a>
                                                                <ux-badge if.bind="hasSignedVersion('eulas', version)" type="success">
                                                                    <span t="translation:global.labels.signed"></span>
                                                                </ux-badge>
                                                                <ux-badge else type="danger">
                                                                    <span t="translation:partial-views.user-settings.labels.still-to-sign"></span>
                                                                </ux-badge>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div else class="flex col-span-12">
                                                <ux-skeleton show-background.bind="false" type="container">
                                                    <div class="flex flex-col w-full py-1 space-y-5">
                                                        <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                                                        <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                                        <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                                                        <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                                                        <ux-skeleton type="line" padding="pr-0"></ux-skeleton>
                                                        <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                                                        <ux-skeleton type="line" padding="pr-2"></ux-skeleton>
                                                        <ux-skeleton type="line"></ux-skeleton>
                                                    </div>
                                                </ux-skeleton>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ux-footer if.bind="baseLoaded">
                        <div class="flex justify-end flex-1">
                            <div class="flex gap-x-2">
                                <ux-button
                                    size="sm"
                                    color="success"
                                    disabled.bind="isLoading || loadingMfa"
                                    loading.bind="isLoading"
                                    click.trigger="save()"
                                    t="[text-when-loading]translation:global.labels.loading-states.default">
                                    <span class="pointer-events-none" t="global.buttons.save"></span>
                                </ux-button>
                            </div>
                        </div>
                    </ux-footer>
                </section>
            </template>
            <template else>
                <div class="flex p-20">
                    <ux-skeleton show-background.bind="false" type="container">
                        <div class="flex flex-col w-full py-1 space-y-5">
                            <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-0"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-2"></ux-skeleton>
                            <ux-skeleton type="line"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-2"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                            <ux-skeleton type="line"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-4"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-2"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                        </div>
                    </ux-skeleton>
                </div>
            </template>
        </div>
    </section>
</template>
