// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ux-avatar .xxs {height: 1.5rem;width: 1.5rem;font-size: .65rem
}ux-avatar .xxs span {margin-top: 0.125rem
}ux-avatar .xxs svg {font-size: .65rem
}ux-avatar .xs {height: 1.75rem;width: 1.75rem;font-size: 0.75rem;line-height: 1rem
}ux-avatar .xs svg {font-size: 0.75rem;line-height: 1rem
}ux-avatar .sm {height: 2rem;width: 2rem;font-size: 0.75rem;line-height: 1rem
}ux-avatar .sm svg {font-size: 0.75rem;line-height: 1rem
}ux-avatar .md {height: 2.5rem;width: 2.5rem;font-size: 1rem;line-height: 1.5rem
}ux-avatar .md svg {font-size: 0.875rem;line-height: 1.25rem
}ux-avatar .lg {height: 4rem;width: 4rem;font-size: 1.125rem;line-height: 1.75rem
}ux-avatar .lg svg {font-size: 1rem;line-height: 1.5rem
}ux-avatar .xl {height: 6rem;width: 6rem;font-size: 1.5rem;line-height: 2rem
}ux-avatar .xl svg {font-size: 1.25rem;line-height: 1.75rem
}ux-avatar .xxl {height: 8rem;width: 8rem;font-size: 2.25rem;line-height: 2.5rem
}ux-avatar .xxl svg {font-size: 1.5rem;line-height: 2rem
}ux-avatar .xxxl {height: 12rem;width: 12rem;font-size: 2.25rem;line-height: 2.5rem
}ux-avatar .xxxl svg {font-size: 1.5rem;line-height: 2rem
}`, "",{"version":3,"sources":["webpack://./src/ux/ux-avatar/ux-avatar.scss"],"names":[],"mappings":"AAEI,gBAAA,cAAA,CACA,aAAA,CACA;AAFA,CAKE,qBAAA;AAAA,CAIA,oBAAA;AAAA,CAKF,eAAA,eAAA,CACA,cAAA,CACA,kBAAA,CAAA;AAFA,CAKE,mBAAA,kBAAA,CAAA;AAAA,CAKF,eAAA,YAAA,CACA,WAAA,CACA,kBAAA,CAAA;AAFA,CAKE,mBAAA,kBAAA,CAAA;AAAA,CAKF,eAAA,cAAA,CACA,aAAA,CACA,eAAA,CAAA;AAFA,CAKE,mBAAA,mBAAA,CAAA;AAAA,CAKF,eAAA,YAAA,CACA,WAAA,CACA,mBAAA,CAAA;AAFA,CAKE,mBAAA,eAAA,CAAA;AAAA,CAKF,eAAA,YAAA,CACA,WAAA,CACA,iBAAA,CAAA;AAFA,CAKE,mBAAA,kBAAA,CAAA;AAAA,CAKF,gBAAA,YAAA,CACA,WAAA,CACA,kBAAA,CAAA;AAFA,CAKE,oBAAA,iBAAA,CAAA;AAAA,CAKF,iBAAA,aAAA,CACA,YAAA,CACA,kBAAA,CAAA;AAFA,CAKE,qBAAA,iBAAA,CAAA;AAAA","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
