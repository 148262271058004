<import from="converters/date" />
<import from="converters/translate" />
<import from="converters/sort-by-first-day-of-week" />
<import from="bx/bx-scheduler-sidebar/bx-scheduler-sidebar" />
<import from="bx/bx-scheduler-column-header/bx-scheduler-column-header" />
<import from="bx/bx-scheduler-column/bx-scheduler-column" />
<import from="bx/bx-scheduler-timeline/bx-scheduler-timeline" />
<import from="bx/bx-scheduler-column-tasks/bx-scheduler-column-tasks" />

<template class="flex flex-col flex-1 h-full overflow-hidden">
    <ux-bar-loader if.bind="schedulerState.isSaving"></ux-bar-loader>
    <div if.bind="baseLoaded" class="flex flex-1 overflow-hidden">
        <bx-scheduler-sidebar
            on-new-scheduler-item.bind="handleNewSchedulerItem"
            has-role.bind="hasRole"
            user-selection.one-time="users"
            wrapper-selection.one-time="wrappers"
            show-today.bind="showToday"
            workspace.bind="workspace"
            authenticated-user.bind="authenticatedUser"
            start-date.bind="startDate"
            language.bind="language"
            page-state.two-way="pageState">
        </bx-scheduler-sidebar>
        <div class="flex w-full overflow-x-auto overflow-y-hidden">
            <div ref="scrollContainer" scroll.trigger="handleScroll($event)" class="flex flex-col flex-1 overflow-y-auto">
                <!-- Scheduler header -->
                <div css="width: ${scrollWidth}px; min-width: ${scrollWidth}px;" class="sticky top-0 z-[70] flex w-full flex-col bg-white">
                    <div class="flex min-h-[45px] w-full border-b border-gray-200">
                        <div class="sticky left-0 z-[70] w-[61px] min-w-[61px] border-r border-gray-200 bg-transparent bg-white"></div>
                        <template if.bind="pageState.values.view === 'week'">
                            <bx-scheduler-column-header
                                repeat.for="day of days | sortByFirstDayOfWeek"
                                component.ref="headers[$index]"
                                headers.bind="headers"
                                date.bind="week[day]"
                                index.bind="$index"
                                amount-of-columns.bind="users.length > 0 ? users.length : wrappers.length"
                                settings.bind="settings"
                                is-last.bind="$index === days.length - 1"
                                language.bind="language"
                                page-state.two-way="pageState">
                            </bx-scheduler-column-header>
                        </template>
                        <template else>
                            <template if.bind="users.length > 0">
                                <bx-scheduler-column-header
                                    repeat.for="user of users"
                                    component.ref="headers[$index]"
                                    headers.bind="headers"
                                    user.bind="user"
                                    date.bind="startDate"
                                    settings.bind="settings"
                                    language.bind="language"
                                    page-state.two-way="pageState">
                                </bx-scheduler-column-header>
                            </template>
                            <template else>
                                <bx-scheduler-column-header
                                    repeat.for="wrapper of wrappers"
                                    component.ref="headers[$index]"
                                    headers.bind="headers"
                                    wrapper.bind="wrapper"
                                    date.bind="startDate"
                                    settings.bind="settings"
                                    language.bind="language"
                                    page-state.two-way="pageState">
                                </bx-scheduler-column-header>
                            </template>
                        </template>
                    </div>
                </div>
                <!-- Scheduler columns -->
                <div css="width: ${scrollWidth}px; min-width: ${scrollWidth}px;" class="relative flex w-full">
                    <!-- Scheduler timeline -->
                    <div class="sticky left-0 z-[60] h-auto border-r border-gray-200 bg-white">
                        <template if.bind="pageState.values.view === 'week'">
                            <div
                                if.bind="hasRole(UserRoles.ReadHealthcareTasks) && users.length === 1"
                                css="min-height: ${maxAmountOfTasks < 1 ? 44 : 44 + ((maxAmountOfTasks - 1) * 30)}px;"
                                class="sticky top-[46px] z-50 border-b border-gray-200 bg-white"></div>
                            <div
                                if.bind="users.length > 1"
                                top-[46px].class="hasRole(UserRoles.ReadHealthcareTasks) && users.length > 0"
                                css="min-height: ${maxAmountOfTasks < 1 ? 74 : 74 + ((maxAmountOfTasks - 1) * 30)}px;"
                                class="sticky z-[999] border-b border-gray-200 bg-white"></div>
                            <div if.bind="wrappers.length > 1" class="sticky top-[46px] z-[999] h-[48px] min-h-[48px] border-b border-gray-200 bg-white"></div>
                        </template>
                        <template else>
                            <div
                                if.bind="hasRole(UserRoles.ReadHealthcareTasks) && users.length > 0"
                                css="min-height: ${maxAmountOfTasks < 1 ? 44 : 44 + ((maxAmountOfTasks - 1) * 30)}px;"
                                class="sticky top-[52px] z-50 border-b border-gray-200 bg-white"></div>
                        </template>
                        <bx-scheduler-timeline periods.bind="periods" settings.bind="settings" page-state.two-way="pageState"></bx-scheduler-timeline>
                    </div>
                    <!-- Scheduler body  -->
                    <div ref="body" class="flex">
                        <!-- Scheduler columns -->
                        <template if.bind="!refresh">
                            <!-- Scheduler week view  -->
                            <template if.bind="pageState.values.view === 'week'" repeat.for="day of days | sortByFirstDayOfWeek">
                                <!-- Scheduler columns hidden view  -->
                                <div show.bind="!pageState.values.days[day].visible" class="relative flex w-[50px] min-w-[50px] items-center justify-center border-r border-gray-200 bg-gray-50">
                                    <div class="absolute w-full h-full" style="opacity: 0.02; background: repeating-linear-gradient(-45deg, #000, #000 5px, transparent 5px, transparent 15px)"></div>
                                    <div class="size-lg font-medium uppercase text-gray-300 [writing-mode:vertical-rl]">${ week[day] | date:'EEEE dd MMMM' }</div>
                                </div>
                                <!-- Scheduler columns visible view -->
                                <div show.bind="pageState.values.days[day].visible" class="flex w-full">
                                    <!-- Scheduler columns for users -->
                                    <template if.bind="users.length > 0">
                                        <div repeat.for="user of users" class="flex flex-col w-full">
                                            <!-- Scheduler column user header -->
                                            <div
                                                css="min-width: ${pageState.values.columnWidth}px; width: ${pageState.values.columnWidth}px;" 
                                                top-[46px].class="hasRole(UserRoles.ReadHealthcareTasks) && users.length > 0"
                                                top-[37px].class="!hasRole(UserRoles.ReadHealthcareTasks) || users.length === 0"
                                                class="sticky z-50 flex flex-col bg-white border-b border-gray-200">
                                                <div
                                                    if.bind="users.length > 1"
                                                    css="min-width: ${pageState.values.columnWidth}px; width: ${pageState.values.columnWidth}px;"
                                                    class="flex h-[30px] border-b border-r border-gray-200 px-3 py-1 text-sm font-semibold text-gray-700">
                                                    <p class="truncate">${user.displayName}</p>
                                                </div>
                                                <bx-scheduler-column-tasks
                                                    user.bind="user"
                                                    date.bind="week[day]"
                                                    parent-index.bind="$parent.$index"
                                                    index.bind="$index"
                                                    settings.bind="settings"
                                                    on-task-click.bind="onTaskClick"
                                                    authenticated-user.bind="authenticatedUser"
                                                    language.bind="language"
                                                    max-amount-of-tasks.bind="maxAmountOfTasks"
                                                    workspace.bind="workspace"
                                                    page-state.two-way="pageState">
                                                </bx-scheduler-column-tasks>
                                            </div>
                                            <!-- Scheduler user column -->
                                            <!-- Do NOT change the component ref, it needs to be the indexes and not the day -->
                                            <bx-scheduler-column
                                                has-user-filter.bind="true"
                                                workspace.bind="workspace"
                                                language.bind="language"
                                                parent-index.bind="$parent.$index"
                                                index.bind="$index"
                                                user.bind="user"
                                                component.ref="columns[$parent.$index][$index]"
                                                columns.bind="columns"
                                                periods.bind="periods"
                                                state.bind="schedulerState"
                                                date.bind="week[day]"
                                                settings.bind="settings"
                                                has-role.bind="hasRole"
                                                page-state.two-way="pageState"
                                                on-scheduler-item-click.bind="handleSchedulerItemClick"
                                                on-scheduler-item-create.bind="handleSchedulerItemCreate"
                                                on-scheduler-item-edit.bind="handleSchedulerItemEdit"
                                                on-scheduler-item-delete.bind="handleSchedulerItemDelete"
                                                on-scheduler-item-move.bind="handleSchedulerItemMove"
                                                on-scheduler-item-resize.bind="handleSchedulerItemResize"
                                                on-scheduler-item-details.bind="handleSchedulerItemDetails"
                                                on-patient-card.bind="handlePatientCard"
                                                on-confirm-scheduler-item.bind="handleConfirmSchedulerItem"
                                                on-mark-no-show.bind="handleMarkNoShow"
                                                on-unmark-no-show.bind="handleUnmarkNoShow"
                                                on-mark-cancelled.bind="handleMarkCancelled"
                                                on-unmark-cancelled.bind="handleUnmarkCancelled">
                                            </bx-scheduler-column>
                                        </div>
                                    </template>
                                    <!-- Scheduler columns for locations/rooms -->
                                    <template else>
                                        <div repeat.for="wrapper of wrappers" class="flex flex-col w-full min-w-0">
                                            <!-- Scheduler column wrapper header -->
                                            <div
                                                css="min-width: ${pageState.values.columnWidth}px; width: ${pageState.values.columnWidth}px;"
                                                if.bind="wrappers.length > 1"
                                                top-[80px].class="hasRole(UserRoles.ReadHealthcareTasks) && users.length > 0"
                                                top-[46px].class="!hasRole(UserRoles.ReadHealthcareTasks) || users.length === 0"
                                                class="sticky z-50 flex h-[48px] flex-col border-b border-r border-gray-200 bg-white px-3 py-1 text-sm font-semibold text-gray-700">
                                                <p if.bind="wrapper.location.applicationName" class="text-sm text-gray-800 truncate">${wrapper.location.applicationName | translate:language}</p>
                                                <p else class="text-sm text-gray-800 truncate">${wrapper.location.name | translate:language}</p>
                                                <p class="text-xs font-semibold text-gray-500 truncate">${wrapper.room.name | translate:language}</p>
                                            </div>
                                            <!-- Scheduler column wrapper -->
                                            <!-- Do NOT change the component ref, it needs to be the indexes and not the day -->
                                            <bx-scheduler-column
                                                has-user-filter.bind="true"
                                                workspace.bind="workspace"
                                                language.bind="language"
                                                parent-index.bind="$parent.$index"
                                                index.bind="$index"
                                                wrapper.bind="wrapper"
                                                component.ref="columns[$parent.$index][$index]"
                                                columns.bind="columns"
                                                periods.bind="periods"
                                                state.bind="schedulerState"
                                                date.bind="week[day]"
                                                settings.bind="settings"
                                                has-role.bind="hasRole"
                                                page-state.two-way="pageState"
                                                on-scheduler-item-click.bind="handleSchedulerItemClick"
                                                on-scheduler-item-create.bind="handleSchedulerItemCreate"
                                                on-scheduler-item-edit.bind="handleSchedulerItemEdit"
                                                on-scheduler-item-delete.bind="handleSchedulerItemDelete"
                                                on-scheduler-item-move.bind="handleSchedulerItemMove"
                                                on-scheduler-item-resize.bind="handleSchedulerItemResize"
                                                on-scheduler-item-details.bind="handleSchedulerItemDetails"
                                                on-patient-card.bind="handlePatientCard"
                                                on-confirm-scheduler-item.bind="handleConfirmSchedulerItem"
                                                on-mark-no-show.bind="handleMarkNoShow"
                                                on-unmark-no-show.bind="handleUnmarkNoShow"
                                                on-mark-cancelled.bind="handleMarkCancelled"
                                                on-unmark-cancelled.bind="handleUnmarkCancelled">
                                            </bx-scheduler-column>
                                        </div>
                                    </template>
                                </div>
                            </template>
                            <!-- Scheduler day view -->
                            <template else>
                                <div if.bind="users && users.length > 0" repeat.for="user of users" class="flex flex-col w-full">
                                    <div class="sticky top-[52px] z-50 border-b border-gray-200 bg-white">
                                        <bx-scheduler-column-tasks
                                            user.bind="user"
                                            date.bind="startDate"
                                            parent-index.bind="0"
                                            index.bind="$index"
                                            settings.bind="settings"
                                            on-task-click.bind="onTaskClick"
                                            authenticated-user.bind="authenticatedUser"
                                            language.bind="language"
                                            on-tasks-loaded.bind="onTasksLoaded"
                                            max-amount-of-tasks.bind="maxAmountOfTasks"
                                            workspace.bind="workspace"
                                            page-state.two-way="pageState">
                                        </bx-scheduler-column-tasks>
                                    </div>
                                    <bx-scheduler-column
                                        has-user-filter.bind="true"
                                        workspace.bind="workspace"
                                        language.bind="language"
                                        parent-index.bind="0"
                                        index.bind="$index"
                                        user.bind="user"
                                        component.ref="columns[0][$index]"
                                        columns.bind="columns"
                                        periods.bind="periods"
                                        state.bind="schedulerState"
                                        date.bind="startDate"
                                        settings.bind="settings"
                                        has-role.bind="hasRole"
                                        page-state.two-way="pageState"
                                        on-scheduler-item-click.bind="handleSchedulerItemClick"
                                        on-scheduler-item-create.bind="handleSchedulerItemCreate"
                                        on-scheduler-item-edit.bind="handleSchedulerItemEdit"
                                        on-scheduler-item-delete.bind="handleSchedulerItemDelete"
                                        on-scheduler-item-move.bind="handleSchedulerItemMove"
                                        on-scheduler-item-resize.bind="handleSchedulerItemResize"
                                        on-scheduler-item-details.bind="handleSchedulerItemDetails"
                                        on-patient-card.bind="handlePatientCard"
                                        on-confirm-scheduler-item.bind="handleConfirmSchedulerItem"
                                        on-mark-no-show.bind="handleMarkNoShow"
                                        on-unmark-no-show.bind="handleUnmarkNoShow"
                                        on-mark-cancelled.bind="handleMarkCancelled"
                                        on-unmark-cancelled.bind="handleUnmarkCancelled">
                                    </bx-scheduler-column>
                                </div>
                                <div if.bind="wrappers && wrappers.length > 0" repeat.for="wrapper of wrappers" class="flex flex-col w-full">
                                    <bx-scheduler-column
                                        has-user-filter.bind="false"
                                        workspace.bind="workspace"
                                        language.bind="language"
                                        parent-index.bind="0"
                                        index.bind="$index"
                                        wrapper.bind="wrapper"
                                        component.ref="columns[0][$index]"
                                        columns.bind="columns"
                                        periods.bind="periods"
                                        state.bind="schedulerState"
                                        date.bind="startDate"
                                        settings.bind="settings"
                                        has-role.bind="hasRole"
                                        page-state.two-way="pageState"
                                        on-scheduler-item-click.bind="handleSchedulerItemClick"
                                        on-scheduler-item-create.bind="handleSchedulerItemCreate"
                                        on-scheduler-item-edit.bind="handleSchedulerItemEdit"
                                        on-scheduler-item-delete.bind="handleSchedulerItemDelete"
                                        on-scheduler-item-move.bind="handleSchedulerItemMove"
                                        on-scheduler-item-resize.bind="handleSchedulerItemResize"
                                        on-scheduler-item-details.bind="handleSchedulerItemDetails"
                                        on-patient-card.bind="handlePatientCard"
                                        on-confirm-scheduler-item.bind="handleConfirmSchedulerItem"
                                        on-mark-no-show.bind="handleMarkNoShow"
                                        on-unmark-no-show.bind="handleUnmarkNoShow"
                                        on-mark-cancelled.bind="handleMarkCancelled"
                                        on-unmark-cancelled.bind="handleUnmarkCancelled">
                                    </bx-scheduler-column>
                                </div>
                            </template>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
