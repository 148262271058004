import { bindable } from 'aurelia';
import * as PDFJS from 'pdfjs-dist';
PDFJS.GlobalWorkerOptions.workerSrc = 'https://cdn.jsdelivr.net/npm/pdfjs-dist@4.7.76/build/pdf.worker.mjs';

export class UxPdfPreview {
    @bindable() public src: string;
    @bindable() public scale: string;

    private scaleToUse: number = 2;
    private pdf: any;
    private preview: any;
    private canvasses: any = [];

    public bound(): void {
        this.renderPdf();
    }

    private async renderPdf(): Promise<void> {
        this.scaleToUse = parseFloat(this.scale);
        // Fetch the PDF
        this.pdf = await PDFJS.getDocument(this.src).promise;
        // Render each page to a canvas.
        for (let page = 1; page <= this.pdf.numPages; page++) {
            const canvas = document.createElement('canvas');
            canvas.className = 'ux-pdf-preview-canvas';
            this.preview.appendChild(canvas);
            this.renderPdfPage(page, canvas);
        }
    }

    private async renderPdfPage(pageNumber: number, canvas: HTMLCanvasElement): Promise<void> {
        const page = await this.pdf.getPage(pageNumber);
        const viewport = page.getViewport({ scale: this.scaleToUse });
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        this.canvasses.push(canvas);
        page.render({ canvasContext: canvas.getContext('2d'), viewport });
    }
}
