import { Store, connectTo } from '@aurelia/store-v1';
import { BodySides, GetMedicalExaminationActionResponse, MedicalExaminationActionItem, MedicalExaminationActionItemTypes, MedicalExaminationActionStepCategory } from '@wecore/sdk-healthcare';
import { isNotDefined } from '@wecore/sdk-utilities';

import { IEventAggregator, bindable, containerless, inject } from 'aurelia';
import { CustomEvents } from '../../../../infra/events';
import { copyItem } from '../../../../infra/store/actions/copy-paste';
import { State } from '../../../../infra/store/state';
import { addCategory, addStep, collapseSteps, generateColumns, moveStep, pasteItem } from '../../../../infra/utilities';
import { CopyActionItem } from '../../../../models/copy-action-item';
import { EventDetails } from '../../../../models/event-details';
import { UxInput } from '../../../../ux/ux-input/ux-input';

@connectTo<State>()
@containerless
@inject(Store<State>, IEventAggregator)
export class TemplateActionCategory {
    @bindable() public action: GetMedicalExaminationActionResponse;
    @bindable() public array: MedicalExaminationActionItem[];
    @bindable() public parent: MedicalExaminationActionStepCategory;
    @bindable() public parentIndex: number;
    @bindable() public actionItem: MedicalExaminationActionItem;
    @bindable() public validation: any;
    @bindable() public level: number = 0;
    @bindable() public index: number;
    @bindable() public language: string;
    @bindable() public toplevel: boolean = false;
    @bindable() public manageTranslations: (property: string, required: boolean, index: number) => void;
    @bindable() public openSettings: (index: number, nested: boolean) => Promise<void>;
    @bindable() public remove: (index: number, category: MedicalExaminationActionStepCategory, validation: any[]) => void;
    @bindable() public edit: (item: MedicalExaminationActionItem) => Promise<void>;

    public state: State;
    public MedicalExaminationActionItemTypes: typeof MedicalExaminationActionItemTypes = MedicalExaminationActionItemTypes;
    public columns: string;
    public hasConnectedCategories: boolean = false;
    public hasVisibilityRequirements: boolean = false;
    public editMode: boolean = false;
    public side: BodySides;
    public renderPasteOptions: boolean = true;

    private timer: any;
    private subscriptions: any[];

    public constructor(
        public store: Store<State>, //
        private readonly events: IEventAggregator
    ) {}

    public attached(): void {
        if (isNotDefined(this.actionItem.attributes)) this.actionItem.attributes = {};
        if (!this.actionItem.category.name[this.language]) this.editMode = true;

        this.setColumns();
        this.checkIfHasSettings();
        this.subscriptions = [
            ...(this.subscriptions ?? []),
            this.events.subscribe(CustomEvents.ExaminationStepSettingsChanged, () => {
                this.setColumns();
                this.checkIfHasSettings();
            }),
            this.events.subscribe(CustomEvents.ActionCategoryChanged, (data: { stepId: string; expand: boolean }) => {
                const shouldChange = this.action.flow.connectedCategories //
                    .filter((x) => x.key === this.actionItem.id)
                    .some((x) => x.value === data.stepId);
                if (shouldChange) this.actionItem.attributes.expanded = data.expand;
            })
        ];
    }

    public detached(): void {
        this.subscriptions.forEach((x) => x.dispose());
    }

    public collapseOrExpand(e: MouseEvent): void {
        if (e.detail === 1) {
            this.timer = setTimeout(() => {
                this.actionItem.attributes.expanded = !this.actionItem.attributes.expanded;
                this.events.publish(CustomEvents.ActionCategoryChanged, {
                    stepId: this.actionItem.id, //
                    expand: this.actionItem.attributes.expanded
                });
            }, 250);
        } else {
            clearTimeout(this.timer);
            this.toggleEditMode();
        }
    }

    public async copyOrCut(command: 'copy'): Promise<void> {
        // When copying an item and trying to paste it right after the item that was copied,
        // the item would not be pasted. This is because the state of the dropdown options
        // doesnt update properly. To fix this, we hide the dropdown options and show them again.
        this.renderPasteOptions = false;

        await this.store.dispatch(
            copyItem,
            new CopyActionItem({
                item: this.actionItem,
                array: this.array,
                index: this.index,
                validationItem: this.validation[this.index],
                validation: this.validation,
                command
            })
        );

        setTimeout(() => (this.renderPasteOptions = true));
    }

    public toggleEditMode(): void {
        if (this.editMode) {
            if (!this.actionItem.category.name[this.language]) {
                this.validation[this.index].name = false;
                return;
            } else this.validation[this.index].name = true;
        }

        this.editMode = !this.editMode;
    }

    public async handleInputChanged(e: CustomEvent<EventDetails<UxInput, any>>): Promise<void> {
        const event = e.detail.innerEvent as KeyboardEvent;
        if (event.key === 'Enter') this.toggleEditMode();
    }

    public pasteItem = async (position: 'before' | 'after' | 'end', pasteInToChildren: boolean): Promise<void> => {
        const copyItem = this.state.clipboard.actionItem;
        if (isNotDefined(copyItem)) return;

        const arrayToUse = pasteInToChildren ? this.actionItem.category.stepsToTake : this.array;
        const validationArrayToUse = pasteInToChildren ? this.validation[this.index].steps : this.validation;

        let indexToUse = this.index;

        // The item gets a new ID during the paste action.
        if (position === 'after') indexToUse++;
        if (position === 'end') indexToUse = -1;

        await pasteItem(
            this.store, //
            this.state,
            arrayToUse,
            validationArrayToUse,
            indexToUse,
            this.action.flow,
            'MedicalExaminationActionItem'
        );
    };

    public moveItem(direction: 'up' | 'down'): void {
        moveStep(this.array, this.validation, direction, this.index);
        this.events.publish(CustomEvents.ActionStepsChanged);
    }

    public addNewCategory = (index: number = -1): void => {
        collapseSteps(this.array);
        addCategory(this.array, this.validation, index, this.language, 'MedicalExaminationActionItem');
    };

    public addNewStep = (index: number = -1): void => {
        collapseSteps(this.array);
        addStep(this.array, this.validation, index, this.language, 'MedicalExaminationActionItem');
    };

    public addCategoryToSteps = (index: number = -1): void => {
        this.actionItem.attributes.expanded = true;
        addCategory(this.actionItem.category.stepsToTake, this.validation[this.index].steps, index, this.language, 'MedicalExaminationActionItem');
    };

    public addStepToSteps = (index: number = -1): void => {
        this.actionItem.attributes.expanded = true;
        addStep(this.actionItem.category.stepsToTake, this.validation[this.index].steps, index, this.language, 'MedicalExaminationActionItem');
    };

    private setColumns = (): void => {
        this.columns = generateColumns(
            this.action.flow.breakpoints.filter((x) => x.id === this.actionItem.id) || [] //
        );
    };

    private checkIfHasSettings(): void {
        this.hasConnectedCategories = this.action.flow.connectedCategories?.filter((x) => x.key === this.actionItem.id).any() || false;
        this.hasVisibilityRequirements = this.action.flow.visibilityRequirements?.filter((x) => x.id === this.actionItem.id).any() || false;
        this.side = this.action.flow.sides?.find((x) => x.key === this.actionItem.id)?.value;
    }
}
