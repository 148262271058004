<import from="converters/translate" />
<import from="converters/date" />
<import from="converters/get-ids" />
<import from="converters/duration" />
<import from="bx/bx-appointment-type-multi-selector/bx-appointment-type-multi-selector" />
<import from="bx/bx-practice-location-selector/bx-practice-location-selector" />
<import from="bx/bx-examination-room-selector/bx-examination-room-selector" />
<import from="bx/bx-patient-selector/bx-patient-selector" />
<import from="bx/bx-user-selector/bx-user-selector" />

<template class="!flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex overflow-hidden gap-x-2" css.bind="styles">
        <div class="flex flex-col flex-1 w-full h-full overflow-hidden">
            <div class="flex flex-col flex-1 px-8 overflow-y-auto">
                <template if.bind="baseLoaded">
                    <div class="sticky top-0 z-50 flex flex-col gap-3 pt-5 pb-3 bg-white border-b border-gray-200">
                        <div class="flex justify-between">
                            <p class="font-medium text-gray-400" t="translation:partial-views.scheduler-items.edit.title"></p>
                        </div>
                        <div class="flex flex-col col-span-12">
                            <ux-label class="mb-1">
                                <span t="translation:global.labels.type"></span>
                            </ux-label>
                            <ux-select disabled.bind="true" value.two-way="schedulerItem.type">
                                <ux-select-option value.bind="SchedulerItemTypes.Appointment">
                                    <span t="translation:global.labels.appointment"></span>
                                </ux-select-option>
                                <ux-select-option value.bind="SchedulerItemTypes.Block">
                                    <span t="translation:global.labels.block"></span>
                                </ux-select-option>
                            </ux-select>
                        </div>
                        <div class="relative flex flex-col rounded-md h-44">
                            <div if.bind="schedulerItem.type === SchedulerItemTypes.Block" class="relative rounded-md">
                                <div class="absolute flex w-full bg-red-100 rounded-md h-44"></div>
                                <div
                                    class="absolute z-[20] flex h-44 w-full rounded-md"
                                    style="opacity: 0.14; background: repeating-linear-gradient(-45deg, #b22222, #b22222 5px, transparent 5px, transparent 15px)"></div>
                            </div>
                            <div
                                if.bind="schedulerItem.appointmentTypes.length === 0 && schedulerItem.type === SchedulerItemTypes.Appointment"
                                class="flex w-full rounded-md h-44"
                                style="background-color: #e5e7eb; color: '#1f2937'"></div>
                            <div if.bind="schedulerItem.appointmentTypes.length > 0 && schedulerItem.type === SchedulerItemTypes.Appointment" class="absolute z-10 flex w-full h-44">
                                <div
                                    repeat.for="type of schedulerItem.appointmentTypes"
                                    rounded-l-md.class="$index === 0"
                                    rounded-r-md.class="$index === schedulerItem.appointmentTypes.length - 1"
                                    class="flex items-end justify-center flex-1 w-full min-w-0 p-5 h-44"
                                    css="background-color: ${type.color.background}; color: ${type.color.text}">
                                    <p class="text-xs truncate">${type.name | translate:language}</p>
                                </div>
                            </div>
                            <div class="absolute z-50 flex flex-col flex-1 px-5 pt-5 pb-3">
                                <div if.bind="schedulerItem.type === SchedulerItemTypes.Appointment" class="flex gap-1 text-sm font-semibold">
                                    <span>#${schedulerItem.patients[0]?.data.number ?? '12345'}</span>
                                    <span>${schedulerItem.patients[0]?.name ?? 'John Doe'}</span>
                                </div>
                                <div class="flex gap-1 text-sm">
                                    <span if.bind="schedulerItem.practitioner">${schedulerItem.practitioner.name}</span>
                                    <div class="flex">
                                        <span if.bind="schedulerItem.practitioner && schedulerItem.location">(</span>
                                        <div if.bind="schedulerItem.location" class="flex gap-1 text-sm">
                                            <span if.bind="schedulerItem.location.applicationName">${schedulerItem.location.applicationName | translate:language}</span>
                                            <span else>${schedulerItem.location.name | translate:language}</span>
                                            -
                                            <span>${schedulerItem.examinationRoom.name | translate:language}</span>
                                        </div>
                                        <span if.bind="schedulerItem.practitioner && schedulerItem.location">)</span>
                                    </div>
                                </div>
                                <hr class="my-2 border-gray-700 opacity-10" />
                                <div class="flex gap-1 text-sm capitalize">
                                    <span>${schedulerItem.start | date:'EEEE dd MMMM yyyy' }</span>
                                </div>
                                <div class="flex gap-1 text-sm">
                                    <span>${schedulerItem.start | date:'HH:mm' } - ${schedulerItem.end | date:'HH:mm' } (${schedulerItem.start | duration:schedulerItem.end })</span>
                                </div>
                            </div>
                        </div>
                        <template if.bind="errorCode">
                            <ux-alert color="danger">
                                <div class="text-sm">
                                    <span if.bind="errorCode === 'AP300'" t="translation:partial-views.scheduler-items.messages.error-AP300"></span>
                                    <span if.bind="errorCode === 'AP401'" t="translation:partial-views.scheduler-items.messages.error-AP401"></span>
                                    <span if.bind="errorCode === 'AP402'" t="translation:partial-views.scheduler-items.messages.error-AP402"></span>
                                    <span if.bind="errorCode === 'AP403'" t="translation:partial-views.scheduler-items.messages.error-AP403"></span>
                                    <span if.bind="errorCode === 'AP404'" t="translation:partial-views.scheduler-items.messages.error-AP404"></span>
                                    <span if.bind="errorCode === 'AP405'" t="translation:partial-views.scheduler-items.messages.error-AP405"></span>
                                    <span if.bind="errorCode === 'AP406'" t="translation:partial-views.scheduler-items.messages.error-AP406"></span>
                                </div>
                            </ux-alert>
                        </template>
                    </div>
                    <div class="grid grid-cols-12 gap-2 pt-3 pb-5">
                        <div if.bind="schedulerItem.type === SchedulerItemTypes.Appointment" class="flex flex-col col-span-12">
                            <div class="flex items-center space-x-1">
                                <ux-label class="mb-1">
                                    <span t="translation:global.labels.appointment-types"></span>
                                </ux-label>
                                <span class="flex items-center h-3 text-red-500">*</span>
                            </div>
                            <bx-appointment-type-multi-selector
                                workspace.bind="authenticated.workspace.id"
                                on-change.bind="handleTypesSelected"
                                disabled.bind="schedulerItem.historic"
                                language.bind="language"
                                valid.bind="validation.types"
                                t="[placeholder]translation:partial-views.scheduler-items.placeholders.select-appointment-types"
                                values.bind="schedulerItem.appointmentTypes | getIds">
                            </bx-appointment-type-multi-selector>
                        </div>
                        <div if.bind="schedulerItem.type === SchedulerItemTypes.Appointment" class="flex flex-col col-span-6">
                            <div class="flex items-center space-x-1">
                                <ux-label class="mb-1">
                                    <span t="translation:global.labels.patient"></span>
                                </ux-label>
                                <span class="flex items-center h-3 text-red-500">*</span>
                            </div>
                            <bx-patient-selector
                                on-select.bind="handlePatientSelected"
                                language.bind="language"
                                disabled.bind="schedulerItem.historic"
                                workspace.bind="authenticated.workspace.id"
                                value.two-way="schedulerItem.patients[0].id"
                                valid.bind="validation.patient"
                                on-patient-created.bind="createPatient"
                                allow-new-patient.bind="true"
                                t="[placeholder]translation:partial-views.scheduler-items.placeholders.select-patient">
                            </bx-patient-selector>
                        </div>
                        <div
                            if.bind="schedulerItem.type === SchedulerItemTypes.Appointment || context === SchedulerContext.User"
                            col-span-12.class="schedulerItem.type === SchedulerItemTypes.Block"
                            col-span-6.class="schedulerItem.type === SchedulerItemTypes.Appointment"
                            class="flex flex-col">
                            <div class="flex items-center space-x-1">
                                <ux-label class="mb-1">
                                    <span t="translation:global.labels.practitioner"></span>
                                </ux-label>
                                <span class="flex items-center h-3 text-red-500">*</span>
                            </div>
                            <bx-user-selector
                                on-select.bind="handleUserSelected"
                                language.bind="language"
                                disabled.bind="schedulerItem.type === SchedulerItemTypes.Block || schedulerItem.historic"
                                workspace.bind="authenticated.workspace.id"
                                valid.bind="validation.practitioner"
                                value.two-way="schedulerItem.practitioner.id"
                                t="[placeholder]translation:global.placeholders.select-user;">
                            </bx-user-selector>
                        </div>
                        <div class="flex flex-col col-span-4">
                            <div class="flex items-center space-x-1">
                                <ux-label class="mb-1">
                                    <span t="translation:partial-views.scheduler-items.labels.date"></span>
                                </ux-label>
                                <span class="flex items-center h-3 text-red-500">*</span>
                            </div>
                            <ux-datepicker
                                value.to-view="schedulerItem.start"
                                disabled.bind="schedulerItem.historic"
                                onselect.trigger="handleDateChanged($event)"
                                valid.bind="validation.date"
                                placeholder.bind="placeholder"></ux-datepicker>
                        </div>
                        <div class="col-span-4">
                            <div class="flex items-center space-x-1">
                                <ux-label class="mb-1">
                                    <span t="translation:global.labels.start"></span>
                                </ux-label>
                                <span class="flex items-center h-3 text-red-500">*</span>
                            </div>
                            <ux-input
                                component.ref="startInput"
                                mask="00:00"
                                select-text-on-focus.bind="true"
                                oninput.trigger="handleTimeInput($event)"
                                data.bind="'start'"
                                value.two-way="start"
                                valid.bind="validation.start"
                                disabled.bind="schedulerItem.historic"
                                t="[placeholder]translation:global.labels.start">
                            </ux-input>
                        </div>
                        <div class="col-span-4">
                            <div class="flex items-center space-x-1">
                                <ux-label class="mb-1">
                                    <span t="translation:global.labels.end"></span>
                                </ux-label>
                                <span class="flex items-center h-3 text-red-500">*</span>
                            </div>
                            <ux-input
                                component.ref="endInput"
                                mask="00:00"
                                select-text-on-focus.bind="true"
                                oninput.trigger="handleTimeInput($event)"
                                data.bind="'end'"
                                value.two-way="end"
                                disabled.bind="schedulerItem.historic"
                                valid.bind="validation.end"
                                t="[placeholder]translation:global.labels.end">
                            </ux-input>
                        </div>
                        <div class="col-span-12" if.bind="schedulerItem.type === SchedulerItemTypes.Appointment && !schedulerItem.createOutsideSchedule && schedulerItem.practitioner">
                            <ux-alert color="info" if.bind="schedulerItem.schedule">
                                <div class="flex items-center justify-between">
                                    <span if.bind="checkingSchedule" class="text-sm" t="translation:partial-views.scheduler-items.messages.searching-schedules"></span>
                                    <div class="flex gap-1" else>
                                        <span class="text-sm" t="translation:partial-views.scheduler-items.labels.schedule"></span>
                                        <span class="text-sm">${schedulerItem.schedule.location.translations | translate:language}</span>
                                    </div>
                                    <ux-spinner if.bind="checkingSchedule" size="xxs"></ux-spinner>
                                </div>
                            </ux-alert>
                            <ux-alert color="warning" else>
                                <div class="flex items-center justify-between">
                                    <span if.bind="checkingSchedule" class="text-sm" t="translation:partial-views.scheduler-items.messages.searching-schedules"></span>
                                    <span else class="text-sm" t="translation:partial-views.scheduler-items.messages.no-schedule-found"></span>
                                    <ux-spinner if.bind="checkingSchedule" size="xxs"></ux-spinner>
                                </div>
                            </ux-alert>
                        </div>
                        <div if.bind="schedulerItem.type === SchedulerItemTypes.Appointment" class="flex items-center justify-between col-span-12">
                            <ux-label>
                                <span t="translation:partial-views.scheduler-items.labels.ignore-schedule"></span>
                            </ux-label>
                            <ux-toggle
                                disabled.bind="schedulerItem.historic"
                                onchange.trigger="handleIgnoringSchedule($event)"
                                checked.two-way="schedulerItem.createOutsideSchedule"
                                label-active.bind="'translation:global.labels.yes' | t"
                                label-inactive.bind="'translation:global.labels.no' | t"
                                size="md"
                                color="warning">
                            </ux-toggle>
                        </div>
                        <div if.bind="schedulerItem.type === SchedulerItemTypes.Appointment || context === SchedulerContext.Room" class="flex flex-col col-span-6">
                            <ux-label class="mb-1">
                                <span t="translation:partial-views.scheduler-items.labels.location"></span>
                            </ux-label>
                            <bx-practice-location-selector
                                component.ref="locationSelector"
                                disabled.bind="!schedulerItem.createOutsideSchedule || schedulerItem.type === SchedulerItemTypes.Block || schedulerItem.historic"
                                on-select.bind="handleLocationSelected"
                                language.bind="language"
                                workspace.bind="authenticated.workspace.id"
                                t="[placeholder]translation:partial-views.scheduler-items.labels.location"
                                valid.bind="validation.location"
                                value.bind="schedulerItem.location.id">
                            </bx-practice-location-selector>
                        </div>
                        <div if.bind="schedulerItem.type === SchedulerItemTypes.Appointment || context === SchedulerContext.Room" class="flex flex-col col-span-6">
                            <ux-label class="mb-1">
                                <span t="translation:partial-views.scheduler-items.labels.room"></span>
                            </ux-label>
                            <ux-select
                                disabled.bind="!schedulerItem.createOutsideSchedule || (schedulerItem.createOutsideSchedule && !schedulerItem.location)  || schedulerItem.type === SchedulerItemTypes.Block || schedulerItem.historic"
                                component.ref="roomSelector"
                                valid.bind="validation.room"
                                onselect.trigger="handleRoomSelected($event)"
                                t="[placeholder]translation:partial-views.scheduler-items.placeholders.select-room"
                                value.to-view="schedulerItem.examinationRoom.id">
                                <ux-select-option repeat.for="room of schedulerItem.location.rooms" value.bind="room.id"> ${ room.name | translate:language } </ux-select-option>
                            </ux-select>
                        </div>
                        <div if.bind="schedulerItem.type === SchedulerItemTypes.Block" class="flex flex-col col-span-12">
                            <ux-label class="mb-1">
                                <span t="translation:global.labels.comments"></span>
                            </ux-label>
                            <ux-textarea rows="5" disabled.bind="schedulerItem.historic" value.two-way="schedulerItem.comments"> </ux-textarea>
                        </div>
                    </div>
                </template>
                <template else>
                    <div class="flex flex-col flex-1 pt-10">
                        <div class="grid grid-cols-1 gap-5">
                            <div class="flex flex-col flex-1 space-y-3">
                                <ux-skeleton show-background="true" type="container">
                                    <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                        <ux-skeleton type="line" padding="pr-60"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                    </div>
                                </ux-skeleton>
                                <ux-skeleton show-background="true" type="container">
                                    <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                        <ux-skeleton type="line" padding="pr-48"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                    </div>
                                </ux-skeleton>
                                <ux-skeleton show-background="true" type="container">
                                    <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                        <ux-skeleton type="line" padding="pr-20"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-8"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-6"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-0"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                    </div>
                                </ux-skeleton>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
            <div class="sticky bottom-0 z-[999] flex justify-end gap-x-2 border-t border-gray-200 bg-white px-8 py-4">
                <template if.bind="schedulerItem.historic">
                    <ux-button size="sm" color="dark" mode="outline" disabled.bind="isLoading" click.trigger="cancel()">
                        <span class="pointer-events-none" t="global.buttons.close"></span>
                    </ux-button>
                </template>
                <template else>
                    <ux-button size="sm" color="dark" mode="outline" disabled.bind="isLoading" click.trigger="cancel()">
                        <span class="pointer-events-none" t="global.buttons.cancel"></span>
                    </ux-button>
                    <ux-button
                        disabled.bind="(!schedulerItem.schedule && !schedulerItem.createOutsideSchedule) || isLoading"
                        size="sm"
                        color="success"
                        loading.bind="isLoading"
                        click.trigger="save()"
                        t="[text-when-loading]translation:global.labels.loading-states.default">
                        <span class="pointer-events-none" t="global.buttons.save"></span>
                    </ux-button>
                </template>
            </div>
        </div>
    </section>
</template>
