import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import {
    SchedulerItemsApiClient,
    EmailsApiClient,
    GetSchedulerItemResponse,
    GetHealthcareTaskResponse,
    MarkSchedulerItemAsCancelledRequest,
    MarkSchedulerItemAsNoShowRequest
} from '@wecore/sdk-healthcare';
import { isDefined, isNotEmpty } from '@wecore/sdk-utilities';
import { IEventAggregator, inject } from 'aurelia';
import { format } from 'date-fns';
import { PartialViewResults } from '../../enums/partial-view-results';
import { BasePartialView } from '../../infra/base-partial-view';
import { SchedulerBroadcaster } from '../../infra/broadcasts/scheduler-broadcaster';
import { CacheService } from '../../infra/cache-service';
import { ErrorHandler } from '../../infra/error-handler';
import { CustomEvents } from '../../infra/events';
import { PartialViews } from '../../infra/partial-views';
import { State } from '../../infra/store/state';
import { ConfirmationOptions } from '../../models/confirmation-options';
import { PartialView } from '../../models/partial-view';
import { SchedulerState } from '../../models/scheduler-state';
import { ViewOptions } from '../../models/view-options';
import { ModalService } from '../../services/service.modals';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, SchedulerItemsApiClient, ModalService, SchedulerBroadcaster, EmailsApiClient)
export class PartialScheduler extends BasePartialView {
    public settingsLoaded: boolean = false;

    private patientId: string;

    public schedulerState: SchedulerState = new SchedulerState({
        isColumnDragging: false,
        isItemDragging: false,
        isItemResizing: false,
        isSaving: false,
        usingTouchDevice: 'ontouchstart' in window || navigator.maxTouchPoints > 0,
        isEditing: false,
        column: -1
    });

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly itemsApi: SchedulerItemsApiClient,
        private readonly modalService: ModalService,
        private readonly schedulerBroadcaster: SchedulerBroadcaster
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.patientId = view.data.patientId;
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                this.subscriptions = [
                    // Only start the scheduler when we know for sure the settings
                    // are properly loaded. The settings are loaded and the event is fired
                    // in the init method of the page-starter component.
                    this.events.subscribe(CustomEvents.SchedulerSettingsChanged, () => {
                        this.baseLoaded = false;
                        this.schedulerBroadcaster.stop();
                        this.baseLoaded = true;
                        // Allow the scheduler some time to load and subscribe
                        // to the broadcaster connected event.
                        setTimeout(() => this.schedulerBroadcaster.start(this.cache.workspace), 1000);
                    })
                ];

                // If the settings are already loaded, start the scheduler
                if (isDefined(this.state.schedulerSettings)) {
                    this.baseLoaded = true;
                    // Allow the scheduler some time to load and subscribe
                    // to the broadcaster connected event.
                    setTimeout(() => this.schedulerBroadcaster.start(this.cache.workspace), 1000);
                }
            })
            .catch((x) => this.errorHandler.handle('PartialScheduler.attached', x));
    }

    public detaching(): void {
        this.schedulerBroadcaster.stop();
    }

    public handleSchedulerItemEdit = async (schedulerItem: GetSchedulerItemResponse): Promise<{ success: boolean; schedulerItem: GetSchedulerItemResponse }> => {
        return new Promise(async (resolve) => {
            this.schedulerState.isEditing = true;
            await this.removeChildViews();
            await this.addPartialView({
                view: this.partial.base,
                partial: PartialViews.EditSchedulerItem.with({ id: schedulerItem.id }).whenClosed(async (result: PartialViewResults, updated: GetSchedulerItemResponse) => {
                    if (result === PartialViewResults.Ok) {
                        resolve({ success: true, schedulerItem: updated });
                    } else resolve({ success: false, schedulerItem: null });
                    this.schedulerState.isEditing = false;
                }),
                options: new ViewOptions({
                    index: this.partial.index + 1,
                    scrollToView: true,
                    markItem: true,
                    updateUrl: false
                })
            });
        });
    };

    public handleSchedulerItemClick = async (schedulerItem: GetSchedulerItemResponse): Promise<{ success: boolean; schedulerItem: GetSchedulerItemResponse }> => {
        return { success: true, schedulerItem };
    };

    public handleSchedulerItemCreate = async (start: Date, end: Date, userId: string, roomId: string, locationId: string): Promise<{ success: boolean; schedulerItem: GetSchedulerItemResponse }> => {
        return new Promise(async (resolve) => {
            this.schedulerState.isEditing = true;
            await this.removeChildViews();
            await this.addPartialView({
                view: this.partial.base,
                partial: PartialViews.CreateSchedulerItem.with({ start, end, userId, roomId, locationId, patientId: this.patientId }).whenClosed(
                    async (result: PartialViewResults, schedulerItem: GetSchedulerItemResponse) => {
                        if (result === PartialViewResults.Ok) {
                            // If the scheduler item is created, we can remove the 'id' query parameter
                            // from the URL to prevent the scheduler item selecting the patient again.
                            this.removeQueryParameter('id');
                            this.patientId = null;
                            resolve({ success: true, schedulerItem });
                        } else resolve({ success: false, schedulerItem: null });
                        this.schedulerState.isEditing = false;
                    }
                ),
                options: new ViewOptions({
                    index: this.partial.index + 1,
                    scrollToView: true,
                    markItem: true,
                    updateUrl: false
                })
            });
        });
    };

    public handleSchedulerItemDelete = async (schedulerItem: GetSchedulerItemResponse): Promise<{ success: boolean }> => {
        return new Promise(async (resolve, reject) => {
            await this.modalService.confirm(
                new ConfirmationOptions({
                    title: this.t.tr('translation:partial-views.scheduler.questions.delete-scheduler-item.title'),
                    message: isDefined(schedulerItem.patients[0])
                        ? this.t
                              .tr('translation:partial-views.scheduler.questions.delete-scheduler-item-with-patient.message') //
                              .replace('{timestamps}', `<span class="font-semibold text-red-500">${format(schedulerItem.start, 'dd-MM-yyyy HH:mm')} - ${format(schedulerItem.end, 'HH:mm')}</span>`)
                              .replace('{patient}', `<span class="font-semibold text-red-500">${schedulerItem.patients[0].name}</span>`)
                        : this.t
                              .tr('translation:partial-views.scheduler.questions.delete-scheduler-item.message') //
                              .replace('{timestamps}', `<span class="font-semibold text-red-500">${format(schedulerItem.start, 'dd-MM-yyyy HH:mm')} - ${format(schedulerItem.end, 'HH:mm')}</span>`),
                    callback: async (confirmed: boolean): Promise<void> => {
                        if (confirmed) {
                            try {
                                await this.itemsApi.delete(schedulerItem.id, this.authenticated.workspace.id);
                                resolve({ success: true });
                            } catch (e) {
                                await this.errorHandler.handle('[scheduler/delete]', e);
                                resolve({ success: false });
                            }
                        } else resolve({ success: false });
                    }
                })
            );
        });
    };

    public handleSchedulerItemResizedOrMoved = async (
        schedulerItem: GetSchedulerItemResponse,
        newStart: Date,
        newEnd: Date
    ): Promise<{ success: boolean; schedulerItem: GetSchedulerItemResponse }> => {
        try {
            schedulerItem.start = newStart;
            schedulerItem.end = newEnd;

            // Get the timezone offset of the browser
            // NOTE: Get the timezone offset of the scheduler item date
            // and not the current date because the scheduler item date
            // can be in a different timezone that the current date.
            const timezoneOffset = schedulerItem.start.getTimezoneOffset();
            const updated = await this.itemsApi.update(schedulerItem.id, this.authenticated.workspace.id, timezoneOffset, schedulerItem);

            this.notifications.show(
                this.t.tr('translation:partial-views.scheduler.notifications.save-successful.title'),
                this.t.tr('translation:partial-views.scheduler.notifications.save-successful.message'),
                {
                    type: 'success',
                    duration: 3000
                }
            );

            return { success: true, schedulerItem: updated };
        } catch (e) {
            this.isLoading = false;
            try {
                const error = JSON.parse(e.response);
                if (error.code === 212) {
                    const code = error.message.substring(error.message.indexOf('(') + 1, error.message.lastIndexOf(')'));
                    const message = this.t.tr(`translation:partial-views.scheduler-items.messages.error-${code}`);
                    this.notifications.show(this.t.tr('translation:partial-views.scheduler.notifications.update-scheduler-item-failed.title'), message, {
                        type: 'warning',
                        duration: 10000
                    });
                } else await this.errorHandler.handle('[update-scheduler-item-resize]', e);
            } catch (err) {
                await this.errorHandler.handle('[update-scheduler-item-resize]', { e, err });
            }
            return { success: false, schedulerItem: null };
        }
    };

    public handlePatientCard = async (schedulerItem: GetSchedulerItemResponse): Promise<{ success: boolean }> => {
        return new Promise(async (resolve) => {
            await this.removeChildViews();
            await this.addPartialView({
                view: this.partial.base,
                partial: PartialViews.PatientCard.with({ id: schedulerItem.patients[0].id, closeable: true, updateUrl: false }).whenClosed(
                    async (result: PartialViewResults, updated: GetSchedulerItemResponse) => {
                        if (result === PartialViewResults.Ok) {
                            resolve({ success: true });
                        } else resolve({ success: false });
                    }
                ),
                options: new ViewOptions({
                    index: this.partial.index + 1,
                    scrollToView: true,
                    markItem: true,
                    updateUrl: false
                })
            });
        });
    };

    public handleSchedulerItemDetails = async (schedulerItem: GetSchedulerItemResponse): Promise<{ success: boolean }> => {
        return new Promise(async (resolve) => {
            await this.removeChildViews();
            await this.addPartialView({
                view: this.partial.base,
                partial: PartialViews.SchedulerItemDetails.with({ id: schedulerItem.id }).whenClosed(async (result: PartialViewResults) => {
                    if (result === PartialViewResults.Ok) {
                        resolve({ success: true });
                    } else resolve({ success: false });
                }),
                options: new ViewOptions({
                    index: this.partial.index + 1,
                    scrollToView: true,
                    markItem: true,
                    updateUrl: false
                })
            });
        });
    };

    public handleConfirmSchedulerItem = async (schedulerItem: GetSchedulerItemResponse): Promise<{ success: boolean }> => {
        return new Promise(async (resolve) => {
            await this.addPartialView({
                view: this.partial.base, //
                partial: PartialViews.SendEmailToPatient.with({
                    template: schedulerItem.appointmentTypes[0].templateForConfirmation?.id,
                    id: schedulerItem.patients[0].id,
                    schedulerItem: schedulerItem.id
                }).whenClosed(async (result: PartialViewResults, data: { success: boolean }) => {
                    if (result === PartialViewResults.Ok) {
                        resolve({ success: true });
                    } else resolve({ success: false });
                }),
                options: new ViewOptions({ index: this.partial.index + 1, markItem: true, scrollToView: true })
            });
        });
    };

    public handleMarkNoShow = async (schedulerItem: GetSchedulerItemResponse): Promise<{ success: boolean }> => {
        return new Promise(async (resolve) => {
            await this.modalService.confirmWithComments(
                new ConfirmationOptions({
                    title: this.t.tr('translation:partial-views.scheduler.questions.mark-no-show.title'),
                    message: this.t.tr('translation:partial-views.scheduler.questions.mark-no-show.message'),
                    btnOk: this.t.tr('translation:global.buttons.yes'),
                    callback: async (confirmed: boolean, comments: string): Promise<void> => {
                        if (confirmed) {
                            try {
                                await this.itemsApi.markAsNoShow(
                                    schedulerItem.id,
                                    this.authenticated.workspace.id,
                                    new MarkSchedulerItemAsNoShowRequest({
                                        comments: isNotEmpty(comments) ? comments : null
                                    })
                                );
                                resolve({ success: true });
                            } catch (e) {
                                await this.errorHandler.handle('[scheduler/mark-noshow]', e);
                                resolve({ success: false });
                            }
                        } else resolve({ success: false });
                    }
                })
            );
        });
    };

    public handleUnmarkNoShow = async (schedulerItem: GetSchedulerItemResponse): Promise<{ success: boolean }> => {
        return new Promise(async (resolve) => {
            await this.modalService.confirm(
                new ConfirmationOptions({
                    title: this.t.tr('translation:partial-views.scheduler.questions.unmark-no-show.title'),
                    message: this.t.tr('translation:partial-views.scheduler.questions.unmark-no-show.message'),
                    btnOk: this.t.tr('translation:global.buttons.yes'),
                    callback: async (confirmed: boolean): Promise<void> => {
                        if (confirmed) {
                            try {
                                await this.itemsApi.unmarkAsNoShow(schedulerItem.id, this.authenticated.workspace.id);
                                resolve({ success: true });
                            } catch (e) {
                                await this.errorHandler.handle('[scheduler/unmark-noshow]', e);
                                resolve({ success: false });
                            }
                        } else resolve({ success: false });
                    }
                })
            );
        });
    };

    public handleMarkCancelled = async (schedulerItem: GetSchedulerItemResponse): Promise<{ success: boolean }> => {
        return new Promise(async (resolve) => {
            await this.modalService.confirmWithComments(
                new ConfirmationOptions({
                    title: this.t.tr('translation:partial-views.scheduler.questions.mark-cancelled.title'),
                    message: this.t.tr('translation:partial-views.scheduler.questions.mark-cancelled.message'),
                    btnOk: this.t.tr('translation:global.buttons.yes'),
                    callback: async (confirmed: boolean, comments: string): Promise<void> => {
                        if (confirmed) {
                            try {
                                await this.itemsApi.markAsCancelled(
                                    schedulerItem.id,
                                    this.authenticated.workspace.id,
                                    new MarkSchedulerItemAsCancelledRequest({
                                        comments: isNotEmpty(comments) ? comments : null
                                    })
                                );
                                resolve({ success: true });
                            } catch (e) {
                                await this.errorHandler.handle('[scheduler/mark-cancelled]', e);
                                resolve({ success: false });
                            }
                        } else resolve({ success: false });
                    }
                })
            );
        });
    };

    public handleUnmarkCancelled = async (schedulerItem: GetSchedulerItemResponse): Promise<{ success: boolean }> => {
        return new Promise(async (resolve, reject) => {
            await this.modalService.confirm(
                new ConfirmationOptions({
                    title: this.t.tr('translation:partial-views.scheduler.questions.unmark-cancelled.title'),
                    message: this.t.tr('translation:partial-views.scheduler.questions.unmark-cancelled.message'),
                    btnOk: this.t.tr('translation:global.buttons.yes'),
                    callback: async (confirmed: boolean): Promise<void> => {
                        if (confirmed) {
                            try {
                                await this.itemsApi.unmarkAsCancelled(schedulerItem.id, this.authenticated.workspace.id);
                                resolve({ success: true });
                            } catch (e) {
                                await this.errorHandler.handle('[scheduler/unmark-cancelled]', e);
                                resolve({ success: false });
                            }
                        } else resolve({ success: false });
                    }
                })
            );
        });
    };

    public setViewWidth = (setToOriginal: boolean, width?: number): void => {
        if (setToOriginal) this.setWidth(this.partial.width);
        else this.setWidth(width);
    };

    public handleTaskClick = async (task: GetHealthcareTaskResponse): Promise<GetHealthcareTaskResponse> => {
        return new Promise(async (resolve) => {
            await this.removeChildViews();
            await this.addPartialView({
                view: this.partial.base,
                partial: PartialViews.EditTask.with({ id: task.id }).whenClosed(async (result: PartialViewResults, data: { task: GetHealthcareTaskResponse }) => {
                    if (result === PartialViewResults.Ok) {
                        resolve(data.task);
                    } else resolve(null);
                }),
                options: new ViewOptions({
                    index: this.partial.index + 1,
                    scrollToView: true,
                    markItem: true,
                    updateUrl: true
                })
            });
        });
    };
}
