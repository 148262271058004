<import from="converters/translate" />
<import from="converters/currency" />
<import from="converters/sort-healthcare-prices" />
<import from="bx/bx-insurer-selector/bx-insurer-selector" />

<template class="!flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex flex-col overflow-hidden" css.bind="styles">
        <ux-header>
            <div class="flex items-center justify-between">
                <p t="translation:partial-views.manage-practice-prices.labels.price-overview"></p>
                <div class="max-w-[200px]">
                    <ux-input
                        debounce="450"
                        disabled.bind="!baseLoaded || isLoading"
                        allow-clear="true"
                        oninput.trigger="handleSearch($event)"
                        onclear.trigger="handleSearch($event)"
                        t="[placeholder]global.placeholders.search"></ux-input>
                </div>
            </div>
        </ux-header>
        <div class="flex flex-col w-full h-full gap-3 px-10 py-5 overflow-y-auto">
            <template if.bind="baseLoaded">
                <div repeat.for="[key, group] of prices | sortHealthcarePrices" if.bind="group.some((x) => states[x.entityId].visible)">
                    <div class="mb-1 text-sm font-medium text-gray-500">
                        <p if.bind="key === 'MedicalExaminationAction'" t="translation:global.labels.actions"></p>
                        <p if.bind="key === 'MedicalTherapy'" t="translation:global.labels.therapies"></p>
                        <p if.bind="key === 'MedicalExamination'" t="translation:global.labels.examinations"></p>
                        <p if.bind="key === 'AppointmentType'" t="translation:global.labels.appointment-types"></p>
                    </div>
                    <div class="flex flex-col gap-1">
                        <div repeat.for="item of group" if.bind="states[item.entityId].visible" class="flex flex-col border border-gray-200 rounded-lg">
                            <ux-card>
                                <div pb-7.class="item.stepPrices.length > 0" pb-4.class="item.stepPrices.length === 0" class="flex flex-col px-5 pt-4">
                                    <div class="flex items-center justify-between">
                                        <p class="text-sm">${item.name | translate:language}</p>

                                        <ux-button disabled.bind="isLoading" click.trigger="createOrEditPrice(item.entityId)" padding.bind="false" size="xs">
                                            <div class="flex p-1">
                                                <svg class="size-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                                </svg>
                                            </div>
                                        </ux-button>
                                    </div>
                                    <let valindex.bind="validation.items.findIndex((x) => x.id === item.entityId)"></let>

                                    <ul if.bind="item.prices.length > 0" class="flex flex-col w-full gap-1 mt-3">
                                        <li class="flex flex-col w-full" repeat.for="price of item.prices">
                                            <div class="relative flex items-center flex-1 rounded-lg bg-gray-50">
                                                <let description.bind="formatDescription(price)"></let>
                                                <button
                                                    click.trigger="createOrEditPrice(item.entityId, $index)"
                                                    class="flex h-[38px] flex-1 items-center gap-6 rounded-l-lg border-y border-l border-gray-200 px-4 py-1 hover:bg-gray-50 focus:z-10 focus:outline-none active:bg-gray-100">
                                                    <div class="flex items-center text-sm">
                                                        <p class="mr-3 text-gray-800">${price.netValue | currency}</p>
                                                        <p class="text-xs text-gray-500">${description}</p>
                                                    </div>
                                                </button>
                                                <button
                                                    click.trigger="removePrice(item.entityId, $index)"
                                                    class="flex h-[38px] w-10 items-center justify-center rounded-r-lg border border-gray-200 bg-gray-50 p-2 text-red-500 ring-red-500 ring-offset-1 hover:bg-red-50 focus:z-10 focus:outline-none focus:ring-1 active:bg-red-100">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                                        <path
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                            d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                                    </svg>
                                                </button>
                                            </div>
                                        </li>
                                    </ul>

                                    <div if.bind="item.stepPrices.length > 0" class="flex flex-col pl-3 mt-3 ml-1 border-l-2 border-gray-300">
                                        <p class="mb-2 text-xs text-gray-500" t="translation:partial-views.manage-practice-prices.messages.step-prices"></p>
                                        <ul class="flex flex-col flex-1 gap-2">
                                            <li repeat.for="stepPriceItem of item.stepPrices" class="flex flex-col flex-1 gap-1">
                                                <p class="text-xs">${stepPriceItem.description} (${stepPriceItem.name | translate:language})</p>
                                                <ul class="flex flex-col w-full gap-1">
                                                    <li class="flex flex-col w-full" repeat.for="stepPrice of stepPriceItem.prices">
                                                        <div class="relative flex items-center flex-1 rounded-lg bg-gray-50">
                                                            <let description.bind="formatDescription(stepPrice)"></let>
                                                            <button
                                                                click.trigger="createOrEditSubPrice(item.entityId, $parent.$index, $index)"
                                                                class="py- flex h-[35px] flex-1 items-center gap-6 rounded-lg border border-l border-gray-200 px-4 hover:bg-gray-50 focus:z-10 focus:outline-none active:bg-gray-100">
                                                                <div class="flex items-center text-sm">
                                                                    <p class="mr-3 text-gray-800">${stepPrice.netValue | currency}</p>
                                                                    <p class="text-xs text-gray-500">${description}</p>
                                                                </div>
                                                            </button>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </ux-card>
                        </div>
                    </div>
                </div>
            </template>
            <div else>
                <ux-skeleton class="col-span-2" show-background.bind="false" type="container">
                    <div class="flex flex-col w-full gap-5 px-5 py-4">
                        <ux-skeleton type="line" padding="pr-60"></ux-skeleton>
                        <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                        <ux-skeleton type="line" padding="pr-20"></ux-skeleton>
                        <ux-skeleton type="line" padding="pr-4"></ux-skeleton>
                        <ux-skeleton type="line" padding="pr-30"></ux-skeleton>
                        <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                        <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                        <ux-skeleton type="line" padding="pr-32"></ux-skeleton>
                        <ux-skeleton type="line" padding="pr-8"></ux-skeleton>
                        <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                    </div>
                </ux-skeleton>
            </div>
        </div>
        <div justify-end.class="validation.valid" justify-between.class="!validation.valid" class="flex items-center p-4 border-t border-gray-200">
            <p if.bind="!validation.valid" class="text-xs text-red-500" t="translation:partial-views.manage-practice-prices.messages.invalid"></p>
            <ux-button size="sm" color="success" disabled.bind="isLoading" loading.bind="isLoading" click.trigger="save()" t="[text-when-loading]translation:global.labels.loading-states.default">
                <span class="pointer-events-none" t="global.buttons.save"></span>
            </ux-button>
        </div>
    </section>
</template>
