import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { GetUserResponse } from '@wecore/sdk-core';
import {
    CreateHealthcareTaskRequest,
    GetHealthcareTaskLabelResponse,
    GetHealthcareTaskListResponse,
    GetPatientResponse,
    HealthcareTaskLabelTypes,
    HealthcareTaskListEntityReference,
    HealthcareTaskListsApiClient,
    HealthcareTasksApiClient,
    PatientEntityReference,
    UserEntityReference
} from '@wecore/sdk-healthcare';
import { isDefined, isNotDefined, validateState } from '@wecore/sdk-utilities';

import { IEventAggregator, inject } from 'aurelia';
import { startOfDay } from 'date-fns';
import { PartialViewResults } from '../../../enums/partial-view-results';
import { BasePartialView } from '../../../infra/base-partial-view';
import { CacheService } from '../../../infra/cache-service';
import { ErrorHandler } from '../../../infra/error-handler';
import { State } from '../../../infra/store/state';
import { cleanTranslatables, setTranslation, validateTranslation } from '../../../infra/utilities';
import { EventDetails } from '../../../models/event-details';
import { PartialView } from '../../../models/partial-view';
import { UxDatepicker } from '../../../ux/ux-datepicker/ux-datepicker';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, HealthcareTasksApiClient, HealthcareTaskListsApiClient)
export class PartialTasksCreate extends BasePartialView {
    public request: CreateHealthcareTaskRequest;
    public HealthcareTaskLabelTypes: typeof HealthcareTaskLabelTypes = HealthcareTaskLabelTypes;
    public validation = {
        title: true
    };

    public shouldClose: boolean = false;
    public list: GetHealthcareTaskListResponse;

    private listId: string;
    private displayOrder: number;
    private isPersonal: boolean;

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly tasksApi: HealthcareTasksApiClient,
        private readonly listsApi: HealthcareTaskListsApiClient
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.listId = view.data?.listId;
        this.isPersonal = view.data?.isPersonalTask ?? false;
        this.displayOrder = view.data?.displayOrder;
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                this.request = new CreateHealthcareTaskRequest({
                    title: setTranslation({}, this.language),
                    description: setTranslation({}, this.language),
                    patients: [],
                    assignedTo: this.isPersonal
                        ? [
                              new UserEntityReference({
                                  id: this.authenticated.user.id,
                                  name: this.authenticated.user.displayName,
                                  data: {
                                      avatarColor: this.authenticated.user.avatar.color,
                                      avatarShade: this.authenticated.user.avatar.shade.toString(),
                                      email: this.authenticated.user.email
                                  }
                              })
                          ]
                        : [],
                    posts: [],
                    labels: [],
                    isPersonal: this.isPersonal,
                    displayOrder: Number(this.displayOrder)
                });
                if (isDefined(this.listId)) {
                    const [list] = await Promise.all([
                        this.listsApi.getById(this.listId, this.authenticated.workspace.id) //
                    ]);
                    this.list = list;
                    this.request.list = new HealthcareTaskListEntityReference({
                        id: list.id,
                        translations: list.name,
                        data: {
                            listColor: list.color
                        }
                    });
                }

                // Delay showing content to prevent flickering.
                setTimeout(async () => {
                    this.baseLoaded = true;
                    await super.handleBaseLoaded();
                }, 250);
            })
            .catch((x) => this.errorHandler.handle('PartialTasksCreate.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }

    public async cancel(): Promise<void> {
        await super.remove({
            result: PartialViewResults.Cancelled,
            updateUrl: true
        });
    }

    public async save(): Promise<void> {
        const valid = this.validate();

        if (valid) {
            this.isLoading = true;
            try {
                cleanTranslatables(['title', 'description'], this.request, 1);
                if (isDefined(this.request.deadline)) this.request.deadline = startOfDay(this.request.deadline);
                const response = await this.tasksApi.create(this.authenticated.workspace.id, this.request);
                this.notifications.show(
                    this.t.tr('translation:partial-views.tasks.notifications.save-successful.title'),
                    this.t
                        .tr('translation:partial-views.tasks.notifications.save-successful.message') //
                        .replace('{entity}', `<span>'${this.request.title[this.language]}'</span>`),
                    {
                        type: 'success',
                        duration: 3000
                    }
                );
                if (this.shouldClose) await this.tasksApi.close(this.authenticated.workspace.id, [response.id]);
                setTimeout(async () => this.remove({ result: PartialViewResults.Ok, data: { task: response }, updateUrl: true }), 250);
            } catch (e) {
                // When the save() goes wrong make sure to re-set the translations object that
                // are possibly set to null and are not required for this request.
                // Otherwise the cleanTranslatables() will fail because the translations object is null.
                if (isNotDefined(this.request.description)) this.request.description = setTranslation({}, this.language);
                this.isLoading = false;
                await this.errorHandler.handle('[create-task-list]', e);
            }
        }
    }

    public async handleDeadlineSelected(e: CustomEvent<EventDetails<UxDatepicker, any>>): Promise<void> {
        const date = new Date(e.detail.values.date);
        this.request.deadline = date;
    }

    public manageTranslationsFor(property: string, required: boolean = false): void {
        this.manageTranslations({
            translations: this.request[property],
            callback: (updatedTranslations: any) => {
                this.request[property] = updatedTranslations;
            },
            required,
            type: property === 'description' ? 'textarea' : 'input'
        });
    }

    public handleUserSelected = (_: GetUserResponse[], __: 'added' | 'deleted', user: GetUserResponse): void => {
        const index = this.request.assignedTo.findIndex((x) => x.id === user.id);
        if (index > -1) this.request.assignedTo.splice(index, 1);
        else
            this.request.assignedTo = [
                ...this.request.assignedTo,
                new UserEntityReference({
                    id: user.id,
                    name: user.displayName,
                    data: {
                        avatarColor: user.avatar.color,
                        avatarShade: user.avatar.shade.toString(),
                        email: user.email
                    }
                })
            ];
    };

    public handlePatientSelected = (_: GetPatientResponse[], __: 'added' | 'deleted', patient: GetPatientResponse): void => {
        if (isNotDefined(this.request.patients)) this.request.patients = [];
        const index = this.request.patients.findIndex((x) => x.id === patient.id);
        if (index > -1) this.request.patients.splice(index, 1);
        else
            this.request.patients = [
                ...this.request.patients,
                new PatientEntityReference({
                    id: patient.id,
                    name: patient.displayName,
                    data: {
                        avatarColor: patient.avatar.color,
                        avatarShade: patient.avatar.shade.toString()
                    }
                })
            ];
    };

    public handleLabelSelected = (_: GetHealthcareTaskLabelResponse[], __: 'added' | 'deleted', label: GetHealthcareTaskLabelResponse): void => {
        const index = this.request.labels.findIndex((x) => x.id === label.id);
        if (index > -1) this.request.labels.splice(index, 1);
        else this.request.labels = [...this.request.labels, label];
    };

    public handleDeadlineCleared(e: CustomEvent<EventDetails<UxDatepicker, any>>) {
        this.request.deadline = null;
    }

    public handlePrioritySelected = async (priority: GetHealthcareTaskLabelResponse): Promise<void> => {
        this.request.priority = priority;
    };

    public handleStatusSelected = async (status: GetHealthcareTaskLabelResponse): Promise<void> => {
        this.shouldClose = false;

        if (isNotDefined(status)) {
            this.request.status = null;
            return;
        }

        // Lets check if we should close task right away.
        this.shouldClose = status.shouldCloseTask;
        this.request.status = status;
    };

    private validate(): boolean {
        this.validation.title = validateTranslation(this.request.title, this.language);
        return validateState(this.validation);
    }
}
