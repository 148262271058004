import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import {
    CreateMedicalExaminationRequest,
    GetMedicalExaminationActionResponse,
    GetMedicalExaminationModelResponse,
    GetMedicalExaminationPhaseResponse,
    GetMedicalQuestionResponse,
    GetMedicalQuestionnaireResponse,
    GetMedicalWidgetResponse,
    HealthcareCode,
    HealthcarePrice,
    InformationSheet,
    MedicalExaminationActionEntityReference,
    MedicalExaminationActionItem,
    MedicalExaminationActionItemTypes,
    MedicalExaminationFlow,
    MedicalExaminationStepVisibilityRequirement,
    MedicalExaminationTemplate,
    MedicalExaminationTemplateItem,
    MedicalExaminationTemplateItemStep,
    MedicalExaminationTemplateItemStepTypes,
    MedicalExaminationsApiClient,
    MedicalQuestionEntityReference,
    MedicalQuestionnaireEntityReference,
    MedicalWidgetEntityReference
} from '@wecore/sdk-healthcare';
import { guid, isDefined, isNotDefined, isNotEmpty, isOfType, validateState } from '@wecore/sdk-utilities';

import { IEventAggregator, inject } from 'aurelia';
import { format } from 'date-fns';
import { PartialViewResults } from '../../../enums/partial-view-results';
import { BasePartialView } from '../../../infra/base-partial-view';
import { CacheService } from '../../../infra/cache-service';
import { ErrorHandler } from '../../../infra/error-handler';
import { CustomEvents } from '../../../infra/events';
import { PartialViews } from '../../../infra/partial-views';
import { clearTemplateItem, copyTemplateItem } from '../../../infra/store/actions/copy-paste';
import { State } from '../../../infra/store/state';
import { addToSpecificIndex, cleanTranslatables, cloneDeep, setTranslation, validateTranslation } from '../../../infra/utilities';
import { CopyTemplateItem } from '../../../models/copy-template-item';
import { EventDetails } from '../../../models/event-details';
import { PartialView } from '../../../models/partial-view';
import { ViewOptions } from '../../../models/view-options';
import { UxInput } from '../../../ux/ux-input/ux-input';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, MedicalExaminationsApiClient)
export class PartialMedicalExaminationsCreate extends BasePartialView {
    public request: CreateMedicalExaminationRequest = new CreateMedicalExaminationRequest({
        displayOrder: 0,
        template: new MedicalExaminationTemplate({
            phases: []
        }),
        healthcareSectors: [],
        flow: new MedicalExaminationFlow({
            repeatDuringEvaluation: [],
            breakpoints: [],
            required: [],
            visibilityRequirements: []
        }),
        prices: [],
        codes: []
    });
    public active: MedicalExaminationTemplateItem;
    public activeIndex: number = 0;
    public MedicalExaminationTemplateItemStepTypes: typeof MedicalExaminationTemplateItemStepTypes = MedicalExaminationTemplateItemStepTypes;
    public validation = {
        name: true,
        displayOrder: true,
        any: true,
        validPhases: true,
        phases: [],
        valid: true
    };
    public editWidth: boolean = false;
    public widthInput: UxInput;

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly examinationsApi: MedicalExaminationsApiClient
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.request.name = setTranslation({}, this.language);
        this.request.description = setTranslation({}, this.language);
    }

    public attached(): void {
        super
            .initView()
            .then(() => {
                this.baseLoaded = true;
            })
            .catch((x) => this.errorHandler.handle('PartialMedicalExaminationsCreate.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
        this.store.dispatch(clearTemplateItem);
    }

    public async cancel(): Promise<void> {
        await super.removeChildViews();
        await super.remove({
            result: PartialViewResults.Cancelled,
            updateUrl: true
        });
    }

    public async addPhase(): Promise<void> {
        await this.removeChildViews();
        this.request.template.phases.push(
            new MedicalExaminationTemplateItem({
                id: guid(),
                stepsToTake: [],
                width: 1278
            })
        );
        this.validation.phases.push({
            phase: true,
            valid: true,
            steps: [],
            width: true
        });

        // Activate last item.
        this.setActive(this.request.template.phases.length - 1);
    }

    public async removePhase(): Promise<void> {
        this.request.template.phases.splice(this.activeIndex, 1);
        this.validation.phases.splice(this.activeIndex, 1);

        // Activate last item if any.
        if (this.request.template.phases.any()) this.setActive(this.request.template.phases.length - 1);
        await this.removeChildViews();
    }

    public movePhase(direction: 'up' | 'down'): void {
        let newIndex = direction === 'up' ? this.activeIndex - 1 : this.activeIndex + 1;

        if (newIndex < 0) newIndex = 0;
        if (newIndex > this.request.template.phases.length - 1) newIndex = this.request.template.phases.length - 1;

        const phaseToMove = this.request.template.phases[this.activeIndex];
        const validationToMove = this.validation.phases[this.activeIndex];

        this.request.template.phases.splice(this.activeIndex, 1);
        this.validation.phases.splice(this.activeIndex, 1);

        this.request.template.phases.splice(newIndex, 0, phaseToMove);
        this.validation.phases.splice(newIndex, 0, validationToMove);

        this.active = phaseToMove;
        this.activeIndex = newIndex;

        this.request.template.phases = [...(this.request.template.phases.length > 0 ? [this.request.template.phases.shift()] : []), ...this.request.template.phases];
        this.validation.phases = [...(this.validation.phases.length > 0 ? [this.validation.phases.shift()] : []), ...this.validation.phases];
    }

    public async setActive(index: number): Promise<void> {
        this.active = this.request.template.phases[index];
        this.activeIndex = index;
        await this.removeChildViews();

        this.setViewWidth();
    }

    public handlePhaseSelected = async (phase: GetMedicalExaminationPhaseResponse): Promise<void> => {
        this.active.phase = phase;
        await this.removeChildViews();
    };

    public handleModelSelected = (model: GetMedicalExaminationModelResponse): void => {
        this.request.model = model;
    };

    public addStep = (index: number): void => {
        addToSpecificIndex(
            this.active.stepsToTake, //
            new MedicalExaminationTemplateItemStep({
                id: guid(),
                attributes: {}
            }),
            index
        );

        addToSpecificIndex(
            this.validation.phases[this.activeIndex].steps, //
            {
                type: true,
                action: true,
                question: true,
                widget: true,
                questionnaire: true,
                breakpoints: [],
                valid: true
            },
            index
        );
    };

    public removeStep = (index: number): void => {
        this.active.stepsToTake.splice(index, 1);
        this.validation.phases[this.activeIndex].steps.splice(index, 1);
    };

    public moveStep = async (direction: 'up' | 'down', currentIndex: number): Promise<void> => {
        await this.removeChildViews();

        let newIndex = direction === 'up' ? currentIndex - 1 : currentIndex + 1;

        if (newIndex < 0) newIndex = 0;
        if (newIndex > this.request.template.phases[this.activeIndex].stepsToTake.length - 1) newIndex = this.request.template.phases[this.activeIndex].stepsToTake.length - 1;

        const stepToMove = this.request.template.phases[this.activeIndex].stepsToTake[currentIndex];
        const validationToMove = this.validation.phases[this.activeIndex].steps[currentIndex];

        this.request.template.phases[this.activeIndex].stepsToTake.splice(currentIndex, 1);
        this.validation.phases[this.activeIndex].steps.splice(currentIndex, 1);

        this.request.template.phases[this.activeIndex].stepsToTake.splice(newIndex, 0, stepToMove);
        this.validation.phases[this.activeIndex].steps.splice(newIndex, 0, validationToMove);
    };

    public toggleSetWidth(): void {
        this.validation.phases[this.activeIndex].width = true;
        if (this.editWidth) {
            const width = Number(this.widthInput.value);
            if (width < 1278) {
                this.validation.phases[this.activeIndex].width = false;
                return;
            }
            this.active.width = width;
        } else {
            if (isNotDefined(this.active.width) || this.active.width < 1278) this.active.width = 1278;
        }

        this.setViewWidth();
        this.editWidth = !this.editWidth;

        setTimeout(() => {
            if (isDefined(this.widthInput)) this.widthInput.value = this.active.width;
        });
    }

    public async handleWidthInputChanged(e: CustomEvent<EventDetails<UxInput, any>>): Promise<void> {
        const event = e.detail.innerEvent as KeyboardEvent;
        if (event.key === 'Enter') this.toggleSetWidth();
    }

    public selectType(type: MedicalExaminationTemplateItemStepTypes, index: number): void {
        this.active.stepsToTake[index].type = type;
    }

    public copyOrCut = async (command: 'copy' | 'cut', index: number): Promise<void> => {
        await this.removeChildViews();
        await this.store.dispatch(
            copyTemplateItem,
            new CopyTemplateItem({
                item: this.request.template.phases[this.activeIndex].stepsToTake[index],
                array: this.request.template.phases[this.activeIndex].stepsToTake,
                index: index,
                validationItem: this.validation.phases[this.activeIndex].steps[index],
                validation: this.validation.phases[this.activeIndex].steps,
                command
            })
        );
    };

    public pasteItem = async (index: number = -1): Promise<void> => {
        await this.removeChildViews();
        const itemToCopy = this.state.clipboard.templateItem;
        if (isNotDefined(itemToCopy)) return;
        // If the command is 'cut' remove the item and validation
        // from their old positions.
        if (itemToCopy.command === 'cut') {
            itemToCopy.array.splice(itemToCopy.index, 1);
            itemToCopy.validation.splice(itemToCopy.index, 1);
        }

        // If we paste after the current item e.g.
        // the provided index is bigger than the current
        // index, substract 1 from the index to get the item
        // on the correct spot.
        if (index > itemToCopy.index) index--;

        // Create a copy without any references.
        // Note that JSON.parse(JSON.stringify()) will remove functions and dates from the object's properties.
        const parsed = JSON.parse(JSON.stringify(itemToCopy.item));
        const copy = MedicalExaminationTemplateItemStep.fromJS(Object.assign({}, parsed));
        // If we're copying, make sure the item ids are unique.
        if (itemToCopy.command === 'copy') copy.id = guid();

        // Wait for the old array to update.
        setTimeout(async () => {
            addToSpecificIndex(this.request.template.phases[this.activeIndex].stepsToTake, copy, index);
            const parsed = JSON.parse(JSON.stringify(itemToCopy.validationItem));
            addToSpecificIndex(this.validation.phases[this.activeIndex].steps, Object.assign({}, parsed), index);
            // Remove the copied item from the state.
            await this.store.dispatch(clearTemplateItem);
        });
    };

    public handleQuestionSelected = (question: GetMedicalQuestionResponse, index: number): void => {
        this.active.stepsToTake[index].question = new MedicalQuestionEntityReference({
            id: question.id,
            translations: question.name
        });
        this.active.stepsToTake[index].attributes.question = question;
    };

    public handleWidgetSelected = (widget: GetMedicalWidgetResponse, index: number): void => {
        this.active.stepsToTake[index].widget = new MedicalWidgetEntityReference({
            id: widget.id,
            translations: widget.name
        });
        this.active.stepsToTake[index].attributes.widget = widget;
    };

    public handleActionSelected = (action: GetMedicalExaminationActionResponse, index: number): void => {
        this.active.stepsToTake[index].action = new MedicalExaminationActionEntityReference({
            id: action.id,
            translations: action.name
        });
        this.active.stepsToTake[index].attributes.action = action;
    };

    public handleQuestionnaireSelected = (questionnaire: GetMedicalQuestionnaireResponse, index: number): void => {
        this.active.stepsToTake[index].questionnaire = new MedicalQuestionnaireEntityReference({
            id: questionnaire.id,
            translations: questionnaire.name
        });
        this.active.stepsToTake[index].attributes.questionnaire = questionnaire;
    };

    public openSettings = async (step: MedicalExaminationTemplateItemStep | MedicalExaminationActionItem): Promise<void> => {
        const settings = ['required', 'columns'];
        if (
            step.type === MedicalExaminationTemplateItemStepTypes.Questionnaire ||
            step.type === MedicalExaminationTemplateItemStepTypes.Question ||
            step.type === MedicalExaminationTemplateItemStepTypes.Widget
        )
            settings.push('visibility');
        const view = PartialViews.MedicalStepSettings.with({
            language: this.language,
            container: this.request.flow,
            item: step,
            model: this.request.model,
            active: this.active,
            settings
        }).whenClosed(async (result: PartialViewResults, response: any) => {
            if (result === PartialViewResults.Ok) {
                // Remove old breakpoints and add new ones.
                this.request.flow.breakpoints = [
                    ...this.request.flow.breakpoints.filter((x) => x.id !== step.id), //
                    ...response.breakpoints
                ];

                // Remove old requirements and and new one if present.
                this.request.flow.required = this.request.flow.required.filter((x) => x.key !== step.id);
                if (isDefined(response.required)) this.request.flow.required.push(response.required);

                // Remove old repeats and and new one if present.
                this.request.flow.repeatDuringEvaluation = this.request.flow.repeatDuringEvaluation.filter((x) => x.key !== step.id);
                if (isDefined(response.repeatDuringEvaluation)) this.request.flow.repeatDuringEvaluation.push(response.repeatDuringEvaluation);

                // Remove old visibilityRequirements and add new ones.
                this.request.flow.visibilityRequirements = [
                    ...this.request.flow.visibilityRequirements.filter((x) => x.id !== step.id), //
                    ...response.visibilityRequirements.map(MedicalExaminationStepVisibilityRequirement.fromJS)
                ];

                this.events.publish(CustomEvents.ExaminationStepSettingsChanged);
            }
        });

        await this.removeChildViews();
        await this.addPartialView({
            view: this.partial.base,
            partial: view,
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                markItem: true
            })
        });
    };

    public collapseOrExpandAll(command: 'collapse' | 'expand'): void {
        const collapseOrExpandAction = (items: MedicalExaminationActionItem[], command: 'collapse' | 'expand'): void => {
            for (const item of items) {
                if (item.type === MedicalExaminationActionItemTypes.Category) {
                    setTimeout(() => (item.attributes.expanded = command === 'expand'));
                    setTimeout(() => collapseOrExpandAction(item.category.stepsToTake, command));
                } else setTimeout(() => (item.attributes.expanded = command === 'expand'));
            }
        };

        const collapseOrExpand = (items: MedicalExaminationTemplateItemStep[], command: 'collapse' | 'expand'): void => {
            for (const item of items) {
                switch (item.type) {
                    case MedicalExaminationTemplateItemStepTypes.Question:
                        item.attributes.expanded = command === 'expand';
                        break;
                    case MedicalExaminationTemplateItemStepTypes.Questionnaire:
                        item.attributes.expanded = command === 'expand';
                        setTimeout(() => {
                            for (const step of item.attributes.questionnaire.questions) //
                                (step as any).attributes.expanded = command === 'expand';
                        });
                        break;
                    case MedicalExaminationTemplateItemStepTypes.Questionnaire:
                        item.attributes.expanded = command === 'expand';
                        setTimeout(() => {
                            for (const step of item.attributes.questionnaire.questions) //
                                (step as any).attributes.expanded = command === 'expand';
                        });
                        break;
                    case MedicalExaminationTemplateItemStepTypes.Action:
                        item.attributes.expanded = command === 'expand';
                        setTimeout(() => collapseOrExpandAction(item.attributes.action.stepsToTake, command));
                        break;
                }
            }
        };
        collapseOrExpand(this.active.stepsToTake, command);
    }

    public async save(edit: boolean = false): Promise<void> {
        const valid = this.validate();

        if (valid) {
            this.isLoading = true;
            try {
                cleanTranslatables(['name', 'description'], this.request, 1);
                const response = await this.examinationsApi.create(this.authenticated.workspace.id, this.request);
                this.notifications.show(
                    this.t.tr('translation:partial-views.medical-examination-actions.notifications.save-successful.title'),
                    this.t
                        .tr('translation:partial-views.medical-examination-actions.notifications.save-successful.message') //
                        .replace('{entity}', `<span>'${this.request.name[this.language]}'</span>`),
                    { type: 'success', duration: 3000 }
                );
                setTimeout(async () => this.remove({ result: PartialViewResults.Ok, updateUrl: true, data: { id: response.id, edit } }), 250);
            } catch (e) {
                // When the save() goes wrong make sure to re-set the translations object that
                // are possibly set to null and are not required for this request.
                // Otherwise the cleanTranslatables() will fail because the translations object is null.
                if (isNotDefined(this.request.description)) this.request.description = setTranslation({}, this.language);
                this.isLoading = false;
                await this.errorHandler.handle('[create-medical-examination]', e);
            }
        }
    }

    public manageTranslationsFor(property: string, required: boolean = false): void {
        this.manageTranslations({
            translations: this.request[property],
            callback: (updatedTranslations: any) => {
                this.request[property] = updatedTranslations;
            },
            required,
            type: property === 'description' ? 'textarea' : 'input'
        });
    }

    public async informationSheet(): Promise<void> {
        this.addPartialView({
            view: this.partial.base,
            partial: this.PartialViews.InformationSheet.with({
                config: {
                    mode: 'edit',
                    language: this.language
                },
                sheet: this.request.informationSheet
            }).whenClosed(async (result: PartialViewResults, sheet: InformationSheet) => {
                if (result === PartialViewResults.Ok) {
                    this.request.informationSheet = sheet;
                }
            }),
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                markItem: true,
                replace: true
            })
        });
    }

    public async createOrEditPrice(index: number = -1): Promise<void> {
        const price = index > -1 ? this.request.prices[index] : null;
        await this.removeChildViews();
        await this.addPartialView({
            view: this.partial.base,
            partial: PartialViews.HealthcarePrices.with({
                price: cloneDeep(price), //
                index,
                prices: this.request.prices
            }).whenClosed(async (result: PartialViewResults, data: { price: HealthcarePrice; index: number }) => {
                if (result === PartialViewResults.Ok) {
                    if (data.index > -1) this.request.prices[data.index] = data.price;
                    else this.request.prices.push(data.price);

                    this.request.prices = [
                        ...(this.request.prices.length > 0 ? [this.request.prices.shift()] : []), //
                        ...this.request.prices
                    ];
                }
            }),
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                markItem: true,
                updateUrl: false
            })
        });
    }

    public async removePrice(index: number): Promise<void> {
        this.request.prices.splice(index, 1);
    }

    public formatDescription(index: number): string {
        let description = '';
        if (isDefined(this.request.prices[index].periodStart)) description += format(this.request.prices[index].periodStart, 'dd-MM-yyyy');
        if (isDefined(this.request.prices[index].periodStart)) description += ' - ';
        if (isDefined(this.request.prices[index].periodEnd)) description += format(this.request.prices[index].periodEnd, 'dd-MM-yyyy');

        if (isDefined(this.request.prices[index].insurer)) description += ` (${this.request.prices[index].insurer.translations[this.language]})`;

        return description;
    }

    public async addCode(): Promise<void> {
        await this.removeChildViews();
        this.addPartialView({
            view: this.partial.base,
            partial: PartialViews.HealthcareCodes.with({ language: this.language }).whenClosed(async (result: PartialViewResults, data: { code: HealthcareCode }) => {
                if (result === PartialViewResults.Ok) {
                    this.request.codes.push(data.code);
                }
            }),
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                markItem: true,
                replace: true
            })
        });
    }

    public async editCode(index: number): Promise<void> {
        await this.removeChildViews();
        await this.addPartialView({
            view: this.partial.base,
            partial: PartialViews.HealthcareCodes.with({
                code: this.request.codes[index],
                language: this.language,
                index
            }).whenClosed(async (result: PartialViewResults, data: { code: HealthcareCode; index: number }) => {
                if (result === PartialViewResults.Ok) {
                    this.request.codes[data.index] = data.code;
                    this.request.codes = [
                        ...(this.request.codes.length > 0 ? [this.request.codes.shift()] : []), //
                        ...this.request.codes
                    ];
                }
            }),
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                markItem: true,
                replace: true
            })
        });
    }

    public async removeCode(index: number): Promise<void> {
        await this.removeChildViews();
        this.request.codes.splice(index, 1);
    }

    private validate(): boolean {
        this.validation.name = validateTranslation(this.request.name, this.language);
        this.validation.displayOrder = isNotEmpty(this.request.displayOrder) && isOfType(Number(this.request.displayOrder), 'number');
        this.validation.any = isDefined(this.request.template.phases) && this.request.template.phases.any();

        for (let phaseIndex = 0; phaseIndex < this.request.template.phases.length; phaseIndex++) {
            const template = this.request.template.phases[phaseIndex];

            this.validation.phases[phaseIndex].phase = isDefined(template.phase);
            this.validation.phases[phaseIndex].width = isDefined(template.width) && template.width >= 1278;

            for (let stepIndex = 0; stepIndex < template.stepsToTake.length; stepIndex++) {
                const element = template.stepsToTake[stepIndex];
                this.validation.phases[phaseIndex].steps[stepIndex].type = isDefined(element.type);

                let item: boolean;
                if (this.validation.phases[phaseIndex].steps[stepIndex].type) {
                    switch (element.type) {
                        case MedicalExaminationTemplateItemStepTypes.Action:
                            this.validation.phases[phaseIndex].steps[stepIndex].action = item = isDefined(element.action);
                            break;
                        case MedicalExaminationTemplateItemStepTypes.Question:
                            this.validation.phases[phaseIndex].steps[stepIndex].question = item = isDefined(element.question);
                            break;
                        case MedicalExaminationTemplateItemStepTypes.Widget:
                            this.validation.phases[phaseIndex].steps[stepIndex].widget = item = isDefined(element.widget);
                            break;
                        case MedicalExaminationTemplateItemStepTypes.Questionnaire:
                            this.validation.phases[phaseIndex].steps[stepIndex].questionnaire = item = isDefined(element.questionnaire);
                            break;
                    }
                }

                this.validation.phases[phaseIndex].steps[stepIndex].valid = this.validation.phases[phaseIndex].steps[stepIndex].type && item;
            }

            this.validation.phases[phaseIndex].valid =
                this.validation.phases[phaseIndex].phase && //
                this.validation.phases[phaseIndex].width &&
                this.validation.phases[phaseIndex].steps.every(validateState);
        }

        this.validation.validPhases = this.validation.phases.every((x) => x.valid);

        this.validation.valid = validateState(this.validation) && this.validation.validPhases;
        return this.validation.valid;
    }

    private setViewWidth(): void {
        const viewWidth = Number(this.active?.width || 1278) + 600;
        this.changeStyle({
            width: `${viewWidth}px`,
            minWidth: `${viewWidth}px`
        });
        this.scrollTo(this.partial);
    }
}
