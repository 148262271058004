import { isDefined, isNotDefined } from '@wecore/sdk-utilities';

import { bindable, inject } from 'aurelia';
import IMask, { InputMask } from 'imask';
import { UxEvents } from '../../infra/ux-events';
import { EventDetails } from '../../models/event-details';

@inject(Element)
export class UxInput {
    @bindable() public value: string | number | null;
    @bindable() public type: 'date' | 'email' | 'number' | 'password' | 'search' | 'tel' | 'text' | 'url' | 'time' | 'week' | 'month' = 'text';
    @bindable() public disabled: boolean = false;
    @bindable() public valid: boolean = true;
    @bindable() public placeholder: string = '';
    @bindable() public rounded: 'left' | 'right' | 'full' = 'full';
    @bindable() public focusDelay: number = 500;
    @bindable() public allowClear: boolean = false;
    @bindable() public autofocus: boolean = false;
    @bindable() public data: any;
    @bindable() public mask: string | any;
    @bindable() public placeholderMask: string = '_';
    @bindable() public selectText: boolean = false;
    @bindable() public selectTextOnFocus: boolean = false;
    @bindable() public isCurrency: boolean = false;
    @bindable() public isLoading: boolean = false;
    @bindable() public min: number;
    @bindable() public max: number;
    @bindable() public minLength: number;
    @bindable() public maxLength: number;
    @bindable() public step: number;
    @bindable() public debounce: number = 500;
    @bindable() public spellcheck: boolean = false;
    @bindable() public language: string = 'en';
    @bindable() public label: string;
    @bindable() public action: () => Promise<void>;
    @bindable() public blockPasswordManager: boolean = true;

    public hasFocus: boolean = false;
    public input: HTMLInputElement;

    private maskReference: InputMask<any>;
    private inputTimeout: any;

    public constructor(
        private readonly host: HTMLElement //
    ) {}

    public bound(): void {
        if (this.autofocus && isDefined(this.input)) setTimeout(() => this.input.focus(), this.focusDelay);
        if (this.selectText && isDefined(this.input)) setTimeout(() => this.selectTheText(), 0);

        // Add new one if we have a mask option.
        if (isDefined(this.mask)) {
            this.maskReference = IMask(
                this.input,
                typeof this.mask === 'string'
                    ? {
                          mask: this.mask,
                          placeholderChar: this.placeholderMask,
                          lazy: false,
                          overwrite: true,
                          autofix: true
                      }
                    : this.mask
            );
        }
    }

    public detaching(): void {
        if (isDefined(this.maskReference)) this.maskReference.destroy();
    }

    public setValue(value: string | number): void {
        this.value = value;
    }

    public async focusElement(): Promise<void> {
        if (isDefined(this.input)) this.input.focus();
    }

    public async setCursorPosition(position: number): Promise<void> {
        if (isDefined(this.input)) {
            this.input.selectionStart = position;
            this.input.selectionEnd = position;
        }
    }

    public async selectTheText(): Promise<void> {
        // Note: Make sure some other DOM element isn't stealing
        // focus before checking if this function works.
        setTimeout(() => {
            if (isDefined(this.input)) this.input.select();
        });
    }

    public async updateMaskValue(): Promise<void> {
        if (isDefined(this.maskReference)) this.maskReference.updateValue();
    }

    public clear(e?: MouseEvent, fireEvent: boolean = true): void {
        e?.preventDefault();
        const value = this.value;
        this.value = null;
        this.input.focus();
        this.input.value = '';

        if (fireEvent)
            this.emit(
                UxEvents.OnClear,
                new EventDetails<UxInput, any>({
                    element: this,
                    innerEvent: e,
                    data: this.data,
                    values: {
                        deletedValue: value
                    }
                })
            );
    }

    public handleOnInput(e: KeyboardEvent): void {
        this.value = this.processValue(this.input.value);
        clearTimeout(this.inputTimeout);
        this.inputTimeout = setTimeout(async () => {
            this.emit(
                UxEvents.OnInput,
                new EventDetails<UxInput, any>({
                    element: this,
                    innerEvent: e,
                    data: this.data,
                    values: {
                        value: this.value,
                        data: this.data
                    }
                })
            );
        }, this.debounce);
    }

    public handleOnChange(e: KeyboardEvent): void {
        this.value = this.processValue(this.input.value);
        this.emit(
            UxEvents.OnChange,
            new EventDetails<UxInput, any>({
                element: this,
                innerEvent: e,
                data: this.data,
                values: {
                    value: this.value,
                    data: this.data
                }
            })
        );
    }

    public handleKeyUp(e: KeyboardEvent): void {
        this.value = this.processValue(this.input.value);
        this.emit(
            UxEvents.OnKeyup,
            new EventDetails<UxInput, any>({
                element: this,
                innerEvent: e,
                data: this.data,
                values: {
                    value: this.value,
                    data: this.data
                }
            })
        );
    }

    public getText(): string {
        if (isNotDefined(this.input)) return null;
        return this.input.value;
    }

    public handleKeyDown(e: KeyboardEvent): boolean {
        this.value = this.processValue(this.input.value);
        this.emit(
            UxEvents.OnKeydown,
            new EventDetails<UxInput, any>({
                element: this,
                innerEvent: e,
                data: this.data,
                values: {
                    value: this.value,
                    data: this.data
                }
            })
        );
        return true;
    }

    public handleBlur(e: FocusEvent): void {
        this.hasFocus = false;
        this.emit(
            UxEvents.OnBlur,
            new EventDetails<UxInput, any>({
                element: this,
                innerEvent: e,
                data: this.data,
                values: {
                    value: this.value,
                    data: this.data
                }
            })
        );
    }

    public handleFocus(e: FocusEvent): void {
        this.hasFocus = true;
        if (this.selectTextOnFocus) this.selectTheText();
        this.emit(
            UxEvents.OnFocus,
            new EventDetails<UxInput, any>({
                element: this,
                innerEvent: e,
                data: this.data,
                values: {
                    value: this.value,
                    data: this.data
                }
            })
        );
    }

    public isDefined(value: any): boolean {
        return isDefined(value);
    }

    public isNotDefined(value: any): boolean {
        return isNotDefined(value);
    }

    private processValue(value: string): string {
        if (this.isCurrency) value = value.replace(',', '.');
        return value;
    }

    private emit<T1, T2>(name: string, args: EventDetails<T1, T2>): void {
        this.host.dispatchEvent(
            new CustomEvent(name, {
                bubbles: true,
                detail: args
            })
        );
    }
}
