<import from="converters/date" />
<import from="converters/translate" />

<div show.bind="!pageState.values.days[day].visible && pageState.values.view === 'week'" class="flex w-[50px] min-w-[50px] items-center justify-center border-r border-gray-200 px-3">
    <ux-button click.trigger="toggleVisiblity()" mode="outline" padding.bind="false" color="dark" border.bind="false">
        <div class="p-1 text-sm text-primary">
            <svg class="size-[1.3rem]" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.75 14.75V19.25H9.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M19.25 9.25V4.75H14.75" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M5 19L10.25 13.75" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M19 5L13.75 10.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>
        </div>
    </ux-button>
</div>
<div
    show.bind="pageState.values.days[day].visible || pageState.values.view === 'day'"
    css="min-width: ${pageState.values.columnWidth * amountOfColumns}px; width: ${pageState.values.columnWidth * amountOfColumns}px;"
    class="items-center border-r border-gray-200 px-3 py-2 pr-[8px]">
    <div class="flex flex-col flex-1 min-w-0">
        <div if.bind="user" class="flex flex-col">
            <p class="text-sm font-semibold leading-tight text-gray-800 truncate">${user.displayName}</p>
            <p if.bind="pageState.values.columnWidth >= 210" class="text-sm leading-tight text-gray-500 capitalize">${date | date:'EEEE dd MMMM'}</p>
            <p else class="text-sm capitalize">${date | date:'EEE dd MMM'}</p>
        </div>
        <div class="flex items-center justify-between pr-1">
            <div class="flex">
                <div if.bind="wrapper" class="flex flex-col min-w-0">
                    <p class="text-sm font-semibold text-gray-800 truncate">${wrapper.room.name | translate:language}</p>
                    <p if.bind="wrapper.location.applicationName" class="text-sm text-gray-800 truncate">${wrapper.location.applicationName | translate:language}</p>
                    <p else class="text-sm text-gray-800 truncate">${wrapper.location.name | translate:language}</p>
                    <p if.bind="pageState.values.columnWidth >= 210" class="text-sm leading-tight text-gray-500 capitalize">${date | date:'EEEE dd MMMM'}</p>                    
                    <p else class="text-sm leading-tight text-gray-500 capitalize">${date | date:'EEE dd MMM'}</p>
                </div>
                <div if.bind="!user && !wrapper" class.bind="isToday? 'text-primary' : 'text-gray-800'" class="flex sticky left-[65px] gap-2">
                    <p if.bind="pageState.values.columnWidth >= 210"  class="text-sm capitalize">${date | date:'EEEE'}</p>
                    <p else class="text-sm capitalize">${date | date:'EEE'}</p>
                    <p if.bind="pageState.values.columnWidth >= 210" class="text-sm font-bold capitalize">${date | date:'dd MMM'}</p>
                    <p else class="text-sm font-bold capitalize">${date | date:'dd'}</p>
                </div>
            </div>
            <ux-button if.bind="pageState.values.view === 'week'" click.trigger="toggleVisiblity()" mode="outline" padding.bind="false" color="dark" border.bind="false">
                <div class="p-1 text-sm text-primary">
                    <svg class="size-[1.3rem]" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.25 18.25V13.75H5.75" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        <path d="M13.75 5.75V10.25H18.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        <path d="M4.75 19.25L10.25 13.75" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        <path d="M19.25 4.75L13.75 10.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>
                </div>
            </ux-button>
        </div>
    </div>
</div>
