import { IDialogController } from '@aurelia/dialog';
import { isEmpty, isFunction } from '@wecore/sdk-utilities';
import { inject } from 'aurelia';
import { ErrorHandler } from '../../infra/error-handler';
import { ConfirmationOptions } from '../../models/confirmation-options';
import { EventDetails } from '../../models/event-details';
import { UxInput } from '../../ux/ux-input/ux-input';

@inject(IDialogController, ErrorHandler)
export class ModalConfirmWithComments {
    public options: ConfirmationOptions;
    public isLoading: boolean = false;
    public comments: string;

    public constructor(
        private readonly controller: IDialogController, //
        private readonly errorHandler: ErrorHandler
    ) {}

    public activate(params: ConfirmationOptions): void {
        this.options = params;
    }

    public async ok(): Promise<void> {
        if (this.options.showLoading) this.isLoading = true;
        try {
            if (isFunction(this.options.callback)) await this.options.callback(true, this.comments);
            await this.controller.ok();
        } catch (e) {
            await this.errorHandler.handle(`[confirm-with-comments/callback] ${this.options.title}`, e);
            if (this.options.showLoading) this.isLoading = false;

            throw e;
        }
    }

    public async handleKeydown(e: CustomEvent<EventDetails<UxInput, any>>): Promise<void> {
        const event = e.detail.innerEvent as KeyboardEvent;
        if (event.key === 'Enter') this.ok();
    }

    public async cancel(): Promise<void> {
        if (isFunction(this.options.callback)) await this.options.callback(false, null);
        await this.controller.cancel();
    }
}
