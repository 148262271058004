import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { MediaLibraryApiClient } from '@wecore/sdk-attachments';
import { BlobStorageAttachment, Phone } from '@wecore/sdk-crm';
import {
    SchedulerItemsApiClient,
    EmailEntityTypes,
    EmailTemplateEntityReference,
    EmailTemplateTokensApiClient,
    EmailTemplatesApiClient,
    EmailsApiClient,
    GetSchedulerItemResponse,
    GetEmailTemplateResponse,
    GetEmailTemplateTokenResponse,
    GetPatientResponse,
    PatientEntityReference,
    PatientsApiClient,
    SendEmailRequest
} from '@wecore/sdk-healthcare';
import { isDefined, isFunction, isNotDefined, isNotEmpty, isValid, resetValidation, validateState } from '@wecore/sdk-utilities';

import { IEventAggregator, inject } from 'aurelia';
import Quill from 'quill';
import Delta from 'quill-delta';
import { PartialViewResults } from '../../enums/partial-view-results';
import { BasePartialView } from '../../infra/base-partial-view';
import { CacheService } from '../../infra/cache-service';
import { ErrorHandler } from '../../infra/error-handler';
import { CustomEvents } from '../../infra/events';
import { languages } from '../../infra/languages';
import { PartialViews } from '../../infra/partial-views';
import { State } from '../../infra/store/state';
import { findAndReplaceEmailTokens } from '../../infra/utilities';
import { ConfirmationOptions } from '../../models/confirmation-options';
import { EventDetails } from '../../models/event-details';
import { PartialView } from '../../models/partial-view';
import { ViewOptions } from '../../models/view-options';
import { ModalService } from '../../services/service.modals';
import { UxSelect } from '../../ux/ux-select/ux-select';
import { UxTextarea } from '../../ux/ux-textarea/ux-textarea';

@inject(
    CacheService, //
    ErrorHandler,
    IEventAggregator,
    Store<State>,
    I18N,
    ModalService,
    MediaLibraryApiClient,
    EmailsApiClient,
    PatientsApiClient,
    EmailTemplateTokensApiClient,
    EmailTemplatesApiClient,
    SchedulerItemsApiClient
)
export class PartialSendEmailToPatient extends BasePartialView {
    public email: string;
    public editor: HTMLDivElement;
    public request: SendEmailRequest = new SendEmailRequest({
        to: {},
        cc: {},
        bcc: {},
        useZorgmail: false,
        isEdifact: false,
        entitities: {}
    });
    public patient: GetPatientResponse;
    public validation: any = {
        subject: true,
        content: true,
        email: true,
        hasPhoneNumber: true
    };
    public canUseZorgmail: boolean = false;

    public settings: {
        forceZorgmail: boolean;
    };

    private quill: Quill;
    private patientId: string;
    private templateId: string;
    private schedulerItemId: string;
    private addTokenTo: 'subject' | 'editor';
    private subjectCursorStart: number;
    private subjectCursorEnd: number;
    private tokens: GetEmailTemplateTokenResponse[];
    private template: GetEmailTemplateResponse;
    private schedulerItem: GetSchedulerItemResponse;
    private onSend: (log: SendEmailRequest) => Promise<void>;

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly modalService: ModalService,
        private readonly mediaLibrary: MediaLibraryApiClient,
        private readonly emailsApi: EmailsApiClient,
        private readonly patientsApi: PatientsApiClient,
        private readonly tokensApi: EmailTemplateTokensApiClient,
        private readonly templatesApi: EmailTemplatesApiClient,
        private readonly itemsApi: SchedulerItemsApiClient
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.email = view.data.email?.value;
        this.patientId = view.data.id;
        this.templateId = view.data.template;
        this.schedulerItemId = view.data.schedulerItem;
        this.onSend = view.data.onSend;

        this.settings = {
            forceZorgmail: false,
            ...view.data.settings
        };
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                const [tokens, patient, template, schedulerItem] = await Promise.all([
                    this.tokensApi.search('', 500, 0, undefined, undefined, undefined, [EmailEntityTypes.Patient, EmailEntityTypes.Other]), //
                    this.patientsApi.getById(this.patientId, this.authenticated.workspace.id),
                    isDefined(this.templateId) ? await this.templatesApi.getById(this.templateId, this.authenticated.workspace.id) : undefined,
                    isDefined(this.schedulerItemId) ? await this.itemsApi.getById(this.schedulerItemId, this.authenticated.workspace.id) : undefined
                ]);

                this.tokens = tokens.data;
                this.patient = patient;
                this.schedulerItem = schedulerItem;

                // If no email is provided, use the first email from the patient.
                if (isNotDefined(this.email) && isDefined(this.patient.emails) && this.patient.emails.length > 0) {
                    if (this.patient.emails.some((e) => e.isPrimary)) this.email = this.patient.emails.find((e) => e.isPrimary).value;
                    else this.email = this.patient.emails[0].value;
                }

                this.request.patient = new PatientEntityReference({ id: this.patient.id, name: this.patient.displayName });
                this.request.entitities['SchedulerItem'] = this.schedulerItem.id;

                // Because we are sending through Zorgmail by default we want
                // to preset the subject with the patient's phone number for SMS validation.
                this.setSubject();

                this.canUseZorgmail = this.request.useZorgmail =
                    isDefined(this.authenticated.workspace.integrations) && //
                    isDefined(this.authenticated.workspace.integrations.zorgmail) &&
                    this.authenticated.workspace.integrations.zorgmail.settings.enabled;

                this.baseLoaded = true;
                await super.handleBaseLoaded();

                setTimeout(async () => {
                    if (isDefined(this.editor)) await this.initEditor();
                    if (isDefined(this.quill)) {
                        this.quill.focus();
                        if (isDefined(template)) this.handleTemplateSelected(template);
                    }
                });
            })
            .catch((x) => this.errorHandler.handle('PartialSendEmailToPatient.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }

    public async cancel(): Promise<void> {
        await super.remove({
            result: PartialViewResults.Cancelled,
            updateUrl: true
        });
    }

    public async send(): Promise<void> {
        const valid = this.validate();
        if (valid) {
            this.request.to[this.patient.displayName] = this.email;
            // Only send the OPS array as JSON string content.
            const content = this.getContents();
            this.request.content = JSON.stringify(content.ops);

            if (isFunction(this.onSend)) {
                await this.onSend(this.request);
                this.events.publish(CustomEvents.EmailSent, this.request);
                this.notifications.show(
                    this.t.tr('translation:partial-views.send-email-to-patient.notifications.send-successful.title'),
                    this.t.tr('translation:partial-views.send-email-to-patient.notifications.send-successful.message'),
                    {
                        type: 'success',
                        duration: 3000
                    }
                );
                setTimeout(async () => this.remove({ result: PartialViewResults.Ok, updateUrl: true }), 250);
            } else {
                await this.modalService.confirm(
                    new ConfirmationOptions({
                        title: this.t.tr('translation:partial-views.send-email-to-patient.questions.send.title'),
                        message: this.t.tr('translation:partial-views.send-email-to-patient.questions.send.message'),
                        type: 'warning',
                        btnOk: this.t.tr('translation:global.buttons.send'),
                        callback: async (confirmed: boolean): Promise<void> => {
                            if (confirmed) {
                                try {
                                    await this.emailsApi.send(this.authenticated.workspace.id, this.request);
                                    this.events.publish(CustomEvents.EmailSent, this.request);
                                    this.notifications.show(
                                        this.t.tr('translation:partial-views.send-email-to-patient.notifications.send-successful.title'),
                                        this.t.tr('translation:partial-views.send-email-to-patient.notifications.send-successful.message'),
                                        {
                                            type: 'success',
                                            duration: 3000
                                        }
                                    );
                                    setTimeout(async () => this.remove({ result: PartialViewResults.Ok, updateUrl: false }), 250);
                                } catch (e) {
                                    this.errorHandler.handle('send-email', e);
                                }
                            }
                        }
                    })
                );
            }
        }
    }

    public async clearHtml(confirm: boolean = true): Promise<void> {
        const callback = () => {
            if (isDefined(this.quill)) this.quill.setText('');
        };

        if (confirm)
            await this.modalService.confirm(
                new ConfirmationOptions({
                    title: this.t.tr('translation:partial-views.information-sheet.questions.delete.title'),
                    message: this.t.tr('translation:partial-views.information-sheet.questions.delete.message'),
                    callback: async (confirmed: boolean): Promise<void> => {
                        if (confirmed) callback();
                    }
                })
            );
        else callback();
    }

    public handleTemplateSelected = (template: GetEmailTemplateResponse): void => {
        if (isNotDefined(template)) {
            if (this.request.useZorgmail) this.setSubject();
            else this.request.subject = null;

            this.request.template = null;
            this.template = null;
            this.clearHtml(false);
            return;
        }

        resetValidation(this.validation);

        this.template = template;
        this.request.template = new EmailTemplateEntityReference({
            id: template.id, //
            translations: template.name
        });

        this.clearHtml(false);

        // Only set the subject if we are not using Zorgmail.
        if (!this.request.useZorgmail) {
            this.request.subject = findAndReplaceEmailTokens(this.tokens, template.subject, this.patient, EmailEntityTypes.Patient, this.language) as string;
            if (isDefined(this.schedulerItem)) this.request.subject = findAndReplaceEmailTokens(this.tokens, template.subject, this.schedulerItem, EmailEntityTypes.Patient, this.language) as string;
        }

        // Sort by active language first.
        const langs = languages().sort((a, _) => (a === this.language ? -1 : 0));
        // Combine all languages into one delta.
        let delta = new Delta();
        for (let index = 0; index < langs.length; index++) {
            const lang = langs[index];
            // Do nothing if we don't have content for this language.
            if (isNotDefined(template.content[lang])) continue;
            // Add divider if we have more than one language.
            if (index > 0)
                delta = delta.concat(
                    new Delta().insert(
                        `\n ---------------------- ${this.t.tr(`translation:global.languages.${lang}`, {
                            lng: lang
                        })} ----------------------\n\n`
                    )
                );

            // Replace tokens in content.
            let content = template.content[lang];
            for (const item of content.ops) {
                if (isDefined(item.insert) && typeof item.insert === 'string') {
                    item.insert = findAndReplaceEmailTokens(this.tokens, item.insert, this.patient, EmailEntityTypes.Patient, this.language);
                    item.insert = findAndReplaceEmailTokens(this.tokens, item.insert, this.authenticated.user, EmailEntityTypes.Other, this.language);
                    if (isDefined(this.schedulerItem)) item.insert = findAndReplaceEmailTokens(this.tokens, item.insert, this.schedulerItem, EmailEntityTypes.Patient, this.language);
                }
            }

            // Convert content to delta.
            const temp = new Delta(content);
            // Merge the delta with the temp delta.
            delta = delta.concat(temp);
        }
        this.setContents(delta);
    };

    public handleSubjectBlur(e: CustomEvent<EventDetails<UxTextarea, any>>): void {
        this.subjectCursorStart = (e.detail.element as any).textarea.selectionStart || 0;
        this.subjectCursorEnd = (e.detail.element as any).textarea.selectionEnd || 0;
        this.addTokenTo = 'subject';
    }

    public handleTokenSelected = (token: GetEmailTemplateTokenResponse): void => {
        if (isNotDefined(token)) return;
        resetValidation(this.validation);

        let replaced = findAndReplaceEmailTokens(this.tokens, token.value, this.patient, EmailEntityTypes.Patient, this.language);
        replaced = findAndReplaceEmailTokens(this.tokens, token.value, this.authenticated.user, EmailEntityTypes.Other, this.language);
        if (isDefined(this.schedulerItem)) replaced = findAndReplaceEmailTokens(this.tokens, token.value, this.schedulerItem, EmailEntityTypes.Patient, this.language);

        if (this.addTokenTo === 'subject') {
            const value = this.request.subject || '';
            const newValue = value.substring(0, this.subjectCursorStart) + replaced + value.substring(this.subjectCursorEnd, value.length);
            this.request.subject = newValue;
        } else {
            const selection = this.quill.getSelection(true);
            this.quill.insertText(selection.index, replaced);
        }
    };

    public async handleZorgmailSettingChanged(e: CustomEvent<EventDetails<UxSelect, any>>): Promise<void> {
        const checked = e.detail.values.checked;
        if (checked) this.setSubject();
        else {
            if (isDefined(this.template)) {
                this.request.subject = findAndReplaceEmailTokens(this.tokens, this.template.subject, this.patient, EmailEntityTypes.Patient, this.language) as string;
                if (isDefined(this.schedulerItem))
                    this.request.subject = findAndReplaceEmailTokens(this.tokens, this.template.subject, this.schedulerItem, EmailEntityTypes.Patient, this.language) as string;
            } else this.request.subject = null;
        }
    }

    private setSubject(): void {
        const phone = this.patient.phones.find((p: Phone) => p.isPrimary) || this.patient.phones[0];
        this.request.subject = `SMS ${isDefined(phone) ? `+${phone.callingCode}${phone.value}` : ''}`;
    }

    private getContents(): any {
        return this.quill.getContents();
    }

    private async setContents(content: any): Promise<void> {
        content = await this.refreshAttachmentsUrls(content);
        this.quill.setContents(content);
    }

    private async refreshAttachmentsUrls(content: any): Promise<any> {
        if (isNotDefined(content) || isNotDefined(content.ops)) return content;
        const checks = content.ops
            .filter((item: any) => isDefined(item.insert.imageAttachment) || (isDefined(item.insert.videoAttachment) && isDefined(item.insert.videoAttachment.attachment)))
            .map((item: any) => {
                return new Promise<void>(async (resolve) => {
                    const container = item.insert.imageAttachment || item.insert.videoAttachment;
                    // Check if URL still has correct authorization.
                    var request = new XMLHttpRequest();
                    request.open('GET', container.src, true);
                    request.send();
                    request.onload = async () => {
                        if (request.status !== 200) {
                            const url = await this.mediaLibrary.getUrl(container.attachment, this.authenticated.workspace.id, container.thumbnail || true);
                            container.src = url;
                        }
                        resolve();
                    };
                });
            });

        await Promise.all(checks);
        return content;
    }

    private initEditor(): Promise<void> {
        return new Promise((resolve) => {
            this.quill = new Quill(this.editor, {
                modules: {
                    // toolbar: [
                    //     ['bold', 'italic', 'underline', 'strike'], // toggled buttons
                    //     ['blockquote', 'code-block'],
                    //     [{ list: 'ordered' }, { list: 'bullet' }],
                    //     [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
                    //     [{ direction: 'rtl' }], // text direction
                    //     [{ header: [1, 2, 3, 4, 5, 6, false] }],

                    //     [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                    //     // [{ font: [] }],
                    //     [{ align: [] }],
                    //     ['link', 'image'],
                    //     ['clean'] // remove formatting button
                    // ]
                    toolbar: {
                        container: `#toolbar-${this.partial.id}`,
                        handlers: {
                            image: this.handleImageSelected,
                            video: this.handleVideoSelected
                        }
                    }
                },
                theme: 'snow'
            });

            this.quill.on('selection-change', (range) => {
                if (isDefined(range)) this.addTokenTo = 'editor';
            });

            resolve();
        });
    }

    private handleVideoSelected = async (): Promise<void> => {
        this.addPartialView({
            view: this.partial.base,
            partial: PartialViews.UploadFiles.with({
                entityId: this.patientId,
                entityType: 'Patient',
                // language: this.contentLanguage,
                type: 'single',
                contentTypes: ['video/x-flv', 'video/mp4', 'application/x-mpegURL', 'video/MP2T', 'video/3gpp', 'video/quicktime', 'video/x-msvideo', 'video/x-ms-wmv']
            }).whenClosed(async (result: PartialViewResults, response: { items: { attachment: BlobStorageAttachment; url: string }[]; thumbnails: boolean }) => {
                if (result === PartialViewResults.Ok) {
                    this.quill.focus();
                    const range = this.quill.getSelection();
                    this.quill.insertEmbed(
                        range.index,
                        'videoAttachment',
                        {
                            src: response.items[0]?.url,
                            attachment: response.items[0].attachment?.id,
                            alt: isDefined(response.items[0].attachment) ? `${response.items[0].attachment.name}${response.items[0].attachment.extension}` : '',
                            width: '100%',
                            height: '480px'
                        },
                        Quill.sources.USER
                    );
                }
            }),
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                markItem: true,
                replace: true
            })
        });
    };

    private handleImageSelected = async (): Promise<void> => {
        this.addPartialView({
            view: this.partial.base,
            partial: PartialViews.UploadFiles.with({
                entityId: this.patientId,
                entityType: 'Patient',
                // language: this.contentLanguage,
                type: 'single',
                contentTypes: ['image/png', 'image/jpg', 'image/jpeg']
            }).whenClosed(async (result: PartialViewResults, response: { items: { attachment: BlobStorageAttachment; url: string }[]; thumbnail: boolean; language: string }) => {
                if (result === PartialViewResults.Ok) {
                    this.quill.focus();
                    const range = this.quill.getSelection();
                    this.quill.insertEmbed(
                        range.index,
                        'imageAttachment',
                        {
                            src: response.items[0]?.url,
                            attachment: response.items[0]?.attachment.id,
                            alt: isDefined(response.items[0].attachment) ? `${response.items[0].attachment.name}${response.items[0].attachment.extension}` : '',
                            width: '100%',
                            height: '480px',
                            thumbnail: response.thumbnail
                        },
                        Quill.sources.USER
                    );
                }
            }),
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                markItem: true,
                replace: true
            })
        });
    };

    private validate(): boolean {
        const content = this.getContents();
        this.validation.content = isDefined(content) && content.length() > 1;
        this.validation.email =
            isDefined(this.email) &&
            isNotEmpty(this.email) &&
            isValid(this.email.toLowerCase(), /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])+/);

        this.validation.subject = isDefined(this.request.subject) && isNotEmpty(this.request.subject);
        return validateState(this.validation);
    }
}
