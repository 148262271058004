import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { isNotEmpty, resetValidation, validateState } from '@wecore/sdk-utilities';

import { GetUserResponse, UserRoles } from '@wecore/sdk-core';
import { GetWorkspaceResponse, InvitationsApiClient, UsersApiClient, WorkspacesApiClient } from '@wecore/sdk-management';
import { IEventAggregator, inject } from 'aurelia';
import { PartialViewResults } from '../../../enums/partial-view-results';
import { BasePartialView } from '../../../infra/base-partial-view';
import { CacheService } from '../../../infra/cache-service';
import { ErrorHandler } from '../../../infra/error-handler';
import { State } from '../../../infra/store/state';
import { ConfirmationOptions } from '../../../models/confirmation-options';
import { PartialView } from '../../../models/partial-view';
import { ModalService } from '../../../services/service.modals';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, UsersApiClient, InvitationsApiClient, WorkspacesApiClient, ModalService)
export class PartialManageUsersEdit extends BasePartialView {
    public roles: UserRoles[] = [];
    public user: GetUserResponse;
    public validation: any = {
        lastName: true,
        firstName: true
    };

    public isOwner: boolean;
    private workspace: GetWorkspaceResponse;
    private id: string;

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly usersApi: UsersApiClient,
        private readonly invitationApi: InvitationsApiClient,
        private readonly workspacesApi: WorkspacesApiClient,
        private readonly modalService: ModalService
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.id = view.data.id;
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                const [user, workspace] = await Promise.all([
                    this.usersApi.getById(this.id), //
                    this.workspacesApi.getById(this.authenticated.workspace.id)
                ]);

                this.user = user;
                this.workspace = workspace;
                const config = workspace.userConfiguration.find((x) => x.user.id == user.id);
                this.roles = config?.roles ?? [];

                this.isOwner = this.roles.includes(UserRoles.Owner);

                this.baseLoaded = true;
            })
            .catch((x) => this.errorHandler.handle('PartialManageUsersEdit.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }

    public async cancel(): Promise<void> {
        await super.remove({
            result: PartialViewResults.Cancelled,
            updateUrl: true
        });
    }

    public async save(): Promise<void> {
        const valid = this.validate();

        if (valid) {
            this.isLoading = true;
            try {
                const index = this.workspace.userConfiguration.findIndex((x) => x.user.id == this.user.id);
                if (index > -1) this.workspace.userConfiguration[index].roles = this.roles;

                await Promise.all([
                    this.usersApi.update(this.user.id, this.user), //
                    this.workspacesApi.update(this.authenticated.workspace.id, this.workspace)
                ]);

                this.notifications.show(
                    this.t.tr('translation:partial-views.manage-users.notifications.save-user-successful.title'), //
                    this.t.tr(`translation:partial-views.manage-users.notifications.save-user-successful.message`),
                    {
                        type: 'success',
                        duration: 3000
                    }
                );

                setTimeout(async () => this.remove({ result: PartialViewResults.Ok, updateUrl: true }), 250);
            } catch (e) {
                this.isLoading = false;
                await this.errorHandler.handle('[manage-users-edit]', e);
            }
        }
    }

    public async delete(): Promise<void> {
        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('translation:partial-views.manage-users.questions.delete-user.title'),
                message: this.t
                    .tr('translation:partial-views.manage-users.questions.delete-user.message') //
                    .replace('{user}', `<span>'${this.user.displayName}'</span>`),
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        this.deleting = true;
                        try {
                            await this.workspacesApi.deleteUser(this.authenticated.workspace.id, this.user.id);
                            this.notifications.show(
                                this.t.tr('translation:partial-views.manage-users.notifications.deleted-user-successfully.title'),
                                this.t
                                    .tr('translation:partial-views.manage-users.notifications.deleted-user-successfully.message') //
                                    .replace('{user}', `<span>'${this.user.displayName}'</span>`),
                                { type: 'success', duration: 3000 }
                            );
                            setTimeout(async () => this.remove({ result: PartialViewResults.Deleted, updateUrl: true }), 250);
                        } catch (e) {
                            this.deleting = false;
                            await this.errorHandler.handle('[delete-examination-phase-detail]', e);
                        }
                    }
                }
            })
        );
    }

    private validate(): boolean {
        resetValidation(this.validation);

        this.validation.lastName = isNotEmpty(this.user.lastName);
        this.validation.firstName = isNotEmpty(this.user.firstName);

        return validateState(this.validation);
    }
}
