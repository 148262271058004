<div class="color-picker-${guid} relative flex">
    <button
        click.trigger="toggleVisibility()"
        class="group flex flex-1 items-center justify-between gap-3 rounded-lg border border-gray-300 px-3 py-2 text-sm hover:bg-gray-50 focus:outline-none active:bg-gray-100">
        <div class="flex items-center gap-3">
            <div class="h-5 w-5 min-w-[1.25rem] flex-initial rounded-full border border-gray-200" css="background-color: ${value}"></div>
            <input class="h-full w-full group-hover:bg-gray-50 group-active:bg-gray-100 focus:outline-none" type="text" value.bind="value" />
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-4 w-4 text-gray-400">
            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
        </svg>
    </button>
    <div if.bind="isOpen" class="absolute top-[45px] z-[9999] flex min-h-[200px] w-60 flex-col rounded-lg border border-gray-300 bg-white shadow-lg">
        <div class="flex-1 rounded-t-lg p-3">
            <div show.bind="view === 'palette' && showPalette" class="grid flex-1 grid-cols-12 gap-2">
                <button
                    repeat.for="color of palette"
                    class="col-span-2 h-7 w-7 rounded-lg border border-white focus:outline-none"
                    css="background-color: ${color}"
                    click.trigger="setColor(color)"
                    !border-gray-600.class="value === color"></button>
            </div>
            <div show.bind="view === 'picker'" ref="picker" class="flex flex-1 justify-center"></div>
        </div>
        <div if.bind="showPalette" class="flex border-t border-gray-300 text-sm">
            <button bg-gray-100.class="view === 'palette'" click.trigger="changeView('palette')" class="flex-1 rounded-bl-lg border-r border-x-gray-300 py-2 hover:bg-gray-50 active:bg-gray-100">
                <span t="translation:ux.color-picker.buttons.palette"></span>
            </button>
            <button bg-gray-100.class="view === 'picker'" click.trigger="changeView('picker')" class="flex-1 rounded-br-lg py-2 hover:bg-gray-50 active:bg-gray-100">
                <span t="translation:ux.color-picker.buttons.picker"></span>
            </button>
        </div>
    </div>
</div>
