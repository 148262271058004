<template class="flex">
    <div
        bg-gray-100.class="type === 'primary'"
        text-gray-800.class="type === 'primary'"
        bg-purple-100.class="type === 'secondary'"
        text-purple-800.class="type === 'secondary'"
        bg-green-100.class="type === 'success'"
        text-green-800.class="type === 'success'"
        bg-yellow-100.class="type === 'warning'"
        text-yellow-800.class="type === 'warning'"
        bg-red-100.class="type === 'danger'"
        text-red-800.class="type === 'danger'"
        bg-blue-100.class="type === 'info'"
        text-blue-800.class="type === 'info'"
        shadow.class="shadow"
        class="${background} ${text} ${padding} inline-flex items-center rounded-md text-xs font-medium">
        <au-slot />
    </div>
</template>
