import { SchedulerSettings } from '@wecore/sdk-management';
import { IPageState } from '@wecore/sdk-utilities';
import { bindable, containerless } from 'aurelia';
import { getSchedulerPeriodHeight } from '../../infra/utilities';
import { SchedulerPeriod } from '../../models/scheduler-period';

@containerless
export class BxSchedulerTimelineItem {
    @bindable() public settings: SchedulerSettings;
    @bindable() public period: SchedulerPeriod;
    @bindable() public pageState: IPageState;

    public pointers: { top: number; label: string }[] = [];
    public container: HTMLDivElement;
    public height: number;

    public bound(): void {
        this.height = getSchedulerPeriodHeight(this.settings, this.period, this.settings.periodHeight);        
        this.container.style.height = `${this.height}px`;
        // De totale slots in één uur
        const slotsInHour = 60 / this.settings.slotSize;

        // Loop door het aantal slots en voeg de pointers toe
        for (let i = 1; i < slotsInHour; i++) {
            const topPercentage = i * (100 / slotsInHour);
            const label = (i * this.settings.slotSize).toString();
            this.pointers.push({ top: topPercentage, label });
        }
    }
}
