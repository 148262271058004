import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { GetMedicalTreatmentProtocolResponse, MedicalTreatmentProtocolsApiClient } from '@wecore/sdk-healthcare';
import { isDefined, isNotEmpty } from '@wecore/sdk-utilities';

import { IEventAggregator, inject } from 'aurelia';
import { PartialViewResults } from '../../enums/partial-view-results';
import { BasePartialView } from '../../infra/base-partial-view';
import { CacheService } from '../../infra/cache-service';
import { ErrorHandler } from '../../infra/error-handler';
import { State } from '../../infra/store/state';
import { cloneDeep } from '../../infra/utilities';
import { ConfirmationOptions } from '../../models/confirmation-options';
import { EventDetails } from '../../models/event-details';
import { PartialView } from '../../models/partial-view';
import { ModalService } from '../../services/service.modals';
import { UxInput } from '../../ux/ux-input/ux-input';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, MedicalTreatmentProtocolsApiClient, ModalService)
export class PartialAddTreatmentProtocols extends BasePartialView {
    public protocols: GetMedicalTreatmentProtocolResponse[] = [];
    public selected: GetMedicalTreatmentProtocolResponse[] = [];
    public alreadyAddedProtocols: any[];

    private pageSize: number = 25;
    private triggerEventOn: number = 100;
    private endOfList: boolean = false;
    private skip: number = 0;
    private query: string;

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly protocolsApi: MedicalTreatmentProtocolsApiClient,
        private readonly modalService: ModalService
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.alreadyAddedProtocols = view.data || [];
    }

    public attached(): void {
        super.initView().then(async () => {
            await this.getData(false, true);
            this.baseLoaded = true;
            setTimeout(() => {
                if (isDefined(this.scrollContainer)) this.scrollContainer.addEventListener('scroll', (e) => this.handleScroll(e));
            });
        });
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }

    public async handleSearch(event: CustomEvent<EventDetails<UxInput, any>>): Promise<void> {
        const element = event.detail.element;
        element.isLoading = true;

        this.query = event.detail.values?.value;
        await this.getData(true);

        element.isLoading = false;
    }

    public async cancel(): Promise<void> {
        await super.remove({
            result: PartialViewResults.Cancelled,
            updateUrl: true
        });
    }

    public select(protocol: GetMedicalTreatmentProtocolResponse): void {
        if (this.selected.some((x) => x.id === protocol.id)) this.selected = this.selected.filter((x) => x.id !== protocol.id);
        else this.selected.push(protocol);

        this.protocols = [
            ...(this.protocols.length > 0 ? [this.protocols.shift()] : []), //
            ...cloneDeep(this.protocols)
        ];
    }

    public async add(): Promise<void> {
        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('translation:partial-views.add-treatment-protocols.questions.copy.title'),
                message: this.t.tr('translation:partial-views.add-treatment-protocols.questions.copy.message'),
                type: 'warning',
                btnOk: this.t.tr('translation:global.buttons.add'),
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        this.remove({
                            result: PartialViewResults.Ok,
                            data: this.selected
                        });
                    }
                }
            })
        );
    }

    public isAlreadyAdded(protocol: GetMedicalTreatmentProtocolResponse): any {
        return this.alreadyAddedProtocols.find((x) => x.id === protocol.id);
    }

    public getTherapies(protocol: GetMedicalTreatmentProtocolResponse): string {
        return protocol.therapies.map((x) => x.therapy.translations[this.language]).join(', ');
    }

    private async getData(reset: boolean = false, initial: boolean = false): Promise<void> {
        if (reset) {
            this.skip = 0;
            this.protocols = [];
            this.endOfList = false;
            if (isDefined(this.scrollContainer)) this.scrollContainer.scrollTop = 0;
        }

        this.isLoading = true;
        const response = await this.protocolsApi.search(this.authenticated.workspace.id, isNotEmpty(this.query) ? this.query : undefined, this.pageSize, this.skip);

        if (!reset && response.data.empty() && !initial) {
            this.endOfList = true;
            this.isLoading = false;
            return;
        }
        this.skip += Number(this.pageSize);
        this.protocols = [...this.protocols, ...response.data];
        this.isLoading = false;
    }

    private async handleScroll(event: Event): Promise<void> {
        const target = event.target as HTMLElement;
        this.hasScrolled = target.scrollTop > 0;

        const isCloseToBottom = target.scrollTop + target.clientHeight >= target.scrollHeight - Number(this.triggerEventOn);
        if (isCloseToBottom && !this.endOfList && !this.isLoading) {
            await this.getData();
        }
    }
}
