import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { ExaminationRoom, PracticeLocationsApiClient } from '@wecore/sdk-healthcare';
import { guid, validateState } from '@wecore/sdk-utilities';

import { IEventAggregator, inject } from 'aurelia';
import { PartialViewResults } from '../../../enums/partial-view-results';
import { BasePartialView } from '../../../infra/base-partial-view';
import { CacheService } from '../../../infra/cache-service';
import { ErrorHandler } from '../../../infra/error-handler';
import { State } from '../../../infra/store/state';
import { setTranslation, validateTranslation } from '../../../infra/utilities';
import { PartialView } from '../../../models/partial-view';
import { ModalService } from '../../../services/service.modals';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, PracticeLocationsApiClient, ModalService)
export class PartialManagePracticeLocationsRoom extends BasePartialView {
    public room: ExaminationRoom;
    public index: number;
    public validation = {
        name: true,
        displayOrder: true
    };

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.index = view.data.index;
        this.room =
            view.data.room ??
            new ExaminationRoom({
                id: guid(),
                isTreatmentRoom: true,
                name: setTranslation({}, this.language)
            });
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                // Delay showing content to prevent flickering.
                setTimeout(async () => {
                    this.baseLoaded = true;
                    await super.handleBaseLoaded();
                }, 250);
            })
            .catch((x) => this.errorHandler.handle('PartialManagePracticeLocationsRoom.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }

    public async cancel(): Promise<void> {
        await super.remove({
            result: PartialViewResults.Cancelled,
            updateUrl: true
        });
    }

    public async save(): Promise<void> {
        const valid = this.validate();

        if (valid) {
            this.isLoading = true;
            this.remove({ result: PartialViewResults.Ok, data: { room: this.room, index: this.index } });
        }
    }

    public manageTranslationsFor(property: string, required: boolean = false): void {
        this.manageTranslations({
            translations: this.room[property],
            callback: (updatedTranslations: any) => {
                this.room[property] = updatedTranslations;
            },
            required,
            type: property === 'description' ? 'textarea' : 'input'
        });
    }

    private validate(): boolean {
        this.validation.name = validateTranslation(this.room.name, this.language);
        return validateState(this.validation);
    }
}
