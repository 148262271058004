<import from="converters/translate" />
<import from="converters/file-to-icon" />
<import from="converters/blob-to-url" />
<import from="converters/get-ids" />
<import from="converters/progress-color" />
<import from="converters/date" />
<import from="./templates/template-action/template-action" />
<import from="./templates/template-question/template-question" />
<import from="./templates/template-questionnaire/template-questionnaire" />
<import from="./templates/template-widget/template-widget" />
<import from="bx/bx-medical-examination-selector/bx-medical-examination-selector" />
<import from="bx/bx-scheduler-item-selector/bx-scheduler-item-selector" />

<template class="!flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex flex-col overflow-hidden gap-x-2" css.bind="styles">
        <template if.bind="view === 'pathway'">
            <div class="flex flex-col items-center flex-1 px-20 pt-52">
                <div class="flex flex-col items-center max-w-md">
                    <div class="flex gap-1 text-2xl font-medium">
                        <p t="translation:global.labels.clinical-pathway"></p>
                        <p>#${pathway.trackingNumber}</p>
                    </div>
                    <p class="mt-2 text-center text-gray-600" t="translation:partial-views.clinical-pathways.messages.clinical-pathway"></p>
                    <div class="flex flex-col w-full gap-2 mt-5">
                        <div if.bind="loadingRecords" class="flex justify-center w-full mt-5">
                            <ux-spinner size="xs"></ux-spinner>
                        </div>
                        <button
                            if.bind="!loadingRecords"
                            repeat.for="record of records"
                            click.trigger="loadMedicalRecord(record)"
                            class="flex items-center justify-between gap-3 px-5 py-3 text-sm border border-gray-300 rounded-md active:bg-gray-100 hover:bg-gray-50">
                            <div class="flex flex-col items-start flex-1 min-w-0">
                                <div class="flex gap-1 font-medium text-gray-500 uppercase">
                                    <p t="translation:global.labels.record"></p>
                                    <p>${record.trackingNumber}</p>
                                </div>
                                <div class="flex justify-between flex-1 min-w-0 gap-3 font-bold flex-nowrap">
                                    <p class="truncate">${record.examination.name | translate:language}</p>
                                </div>
                                <div class="flex gap-1 text-gray-600 text-nowrap">
                                    <p t="translation:global.labels.created-at"></p>
                                    <p>${record.createdAt | date}</p>
                                </div>
                            </div>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5 text-primary">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                            </svg>
                        </button>
                    </div>
                    <div if.bind="!loadingRecords" class="flex gap-2 mt-10">
                        <ux-button color="dark" mode="outline" disabled.bind="isLoading || deleting || saving || generating" click.trigger="cancel()">
                            <span class="pointer-events-none" t="global.buttons.close"></span>
                        </ux-button>
                        <ux-button click.trigger="addMedicalRecord()">
                            <span t="translation:partial-views.clinical-pathways.buttons.new-record"></span>
                        </ux-button>
                    </div>
                </div>
            </div>
        </template>
        <template else>
            <template if.bind="activeRecord">
                <template if.bind="saving">
                    <div class="flex flex-col flex-1 px-10 pb-10 overflow-y-auto">
                        <div class="sticky top-0 z-40 flex flex-col w-full pt-10 pb-5 bg-white border-b border-gray-300">
                            <template if.bind="uploadsFailed">
                                <p class="text-xl font-bold" t="translation:global.labels.note"></p>
                                <p class="text-sm text-gray-500" t="translation:partial-views.clinical-pathways.messages.attachments-failed-to-upload"></p>
                                <ux-button if.bind="uploadsToUpload.some((x) => x.status === 'failed')" click.trigger="hideSavescreen()" class="mt-5">
                                    <span t="translation:global.buttons.continue"></span>
                                </ux-button>
                            </template>
                            <template else>
                                <p class="text-xl font-bold" t="translation:partial-views.clinical-pathways.messages.patience"></p>
                                <p class="text-sm text-gray-500" t="translation:partial-views.clinical-pathways.messages.saving-record"></p>
                                <div class="flex items-end mt-2 text-sm">
                                    <span class="flex items-center text-indigo-700" innerhtml.bind="savingMessage"></span>
                                    <span class="w-3 text-left loading-text"></span>
                                </div>
                            </template>
                        </div>
                        <ul class="mt-5 space-y-1">
                            <li id="medical-record-upload-${$index}" class="flex items-center gap-5 p-5 rounded-lg bg-gray-50" repeat.for="upload of uploadsToUpload">
                                <div class="flex flex-1 gap-10">
                                    <template if.bind="isImage(upload.file.type)">
                                        <ux-image height="50px" width="75px" size="cover" src.bind="upload.file | blobToUrl"></ux-image>
                                    </template>
                                    <template else>
                                        <div class="flex h-[50px] w-[75px] items-center justify-center rounded-lg bg-white">
                                            <span class="text-indigo-600" innerhtml.bind="upload.name | fileToIcon:'w-6 h-6'"></span>
                                        </div>
                                    </template>
                                    <ux-progress-bar
                                        if.bind="upload.status !== 'failed'"
                                        class="flex-1"
                                        lowercase.bind="false"
                                        progress.bind="upload.progress"
                                        color.bind="upload.progress < 100 ? 'info' : upload.progress && upload.status === 'done' ? 'success' : 'warning'">
                                        <span class="flex flex-nowrap" innerhtml.bind="upload.statusLabel"></span>
                                    </ux-progress-bar>
                                    <ux-progress-bar else class="flex-1" lowercase.bind="false" progress.bind="0" color="danger">
                                        <span class="flex flex-nowrap" innerhtml.bind="upload.statusLabel"></span>
                                    </ux-progress-bar>
                                </div>
                                <ux-button if.bind="upload.status === 'failed'" loading.bind="upload.isLoading" click.trigger="retryUpload($index)" color="danger" size="xs">
                                    <span t="translation:global.buttons.retry"></span>
                                </ux-button>
                            </li>
                        </ul>
                    </div>
                </template>
                <template else>
                    <div class="relative flex flex-col flex-1 w-full h-full overflow-hidden">
                        <div if.bind="showLoader" class="absolute top-0 z-50 w-full">
                            <ux-bar-loader></ux-bar-loader>
                        </div>
                        <template if.bind="baseLoaded && recordLoaded">
                            <div class="flex h-full overflow-hidden">
                                <div class="flex w-[250px] flex-col border-r border-gray-200">
                                    <div class="flex flex-col flex-1">
                                        <div class="flex flex-col flex-1 px-6 pt-5 pb-0">
                                            <p class="mb-1 text-sm">${activeRecord.trackingNumber}</p>
                                            <p class="mb-1 font-semibold">${ activeRecord.examination.name | translate:language }</p>
                                            <p class="text-sm text-primary">${ patient.displayName } (${patient.number})</p>
                                            <div class="flex flex-col mt-2">
                                                <div class="flex flex-col flex-1 w-full">
                                                    <div class="flex mb-2 gap-x-2">
                                                        <ux-badge type="${percentageCompleted === 100 ? 'success' : 'primary'}">
                                                            <div class="flex space-x-1">
                                                                <span>${percentageCompleted}%</span>
                                                                <!-- <span t="translation:partial-views.clinical-pathways.labels.percentage-filled-questions"></span> -->
                                                                <span>(${totalCompletedSteps}/${totalSteps})</span>
                                                            </div>
                                                        </ux-badge>
                                                        <ux-badge if.bind="hasRequiredFields" type="${percentageRequiredCompleted === 100 ? 'success' : 'danger'}">
                                                            <div class="flex space-x-1">
                                                                <span>${percentageRequiredCompleted}%</span>
                                                                <!-- <span t="translation:partial-views.clinical-pathways.labels.percentage-required-questions"></span> -->
                                                                <span>(${totalCompletedRequiredSteps}/${totalRequiredSteps})</span>
                                                            </div>
                                                        </ux-badge>
                                                    </div>
                                                    <div class="flex">
                                                        <div class="relative flex flex-1 h-3 bg-gray-200 rounded-sm">
                                                            <div
                                                                if.bind="hasRequiredFields"
                                                                class.bind="percentageRequiredCompleted <= percentageCompleted ? 'z-10': ''"
                                                                css="width: ${percentageRequiredCompleted}%;"
                                                                class="${percentageRequiredCompleted === 100 ? 'bg-green-400' : 'bg-red-400'} absolute h-3 rounded-sm"></div>
                                                            <div
                                                                class.bind="percentageCompleted < percentageRequiredCompleted ? 'z-10': ''"
                                                                css="width: ${percentageCompleted}%;"
                                                                class="${percentageCompleted === 100 ? 'bg-green-400' : 'bg-gray-500'} absolute h-3 rounded-sm"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="flex flex-col flex-1 gap-2 mt-3">
                                                <div class="flex flex-col flex-1 gap-2">
                                                    <!-- <p class="flex-1 text-xs text-gray-600" t="translation:partial-views.clinical-pathways.messages.validate-medical-record"></p> -->
                                                    <ux-button disabled.bind="saving" type="full" mode="outline" color="dark" click.trigger="setViewTo('pathway')">
                                                        <div class="flex items-center space-x-1">
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5">
                                                                <path
                                                                    stroke-linecap="round"
                                                                    stroke-linejoin="round"
                                                                    d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0ZM3.75 12h.007v.008H3.75V12Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm-.375 5.25h.007v.008H3.75v-.008Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
                                                            </svg>

                                                            <span class="text-sm" t="translation:global.labels.clinical-pathway"></span>
                                                            <span>(${records.length})</span>
                                                        </div>
                                                    </ux-button>
                                                    <ux-button disabled.bind="saving" type="full" mode="outline" color="dark" click.trigger="validate()">
                                                        <div class="flex items-center space-x-1">
                                                            <svg class="w-5 h-5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M7.25 9.25L4.75 7L7.25 4.75" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                                                <path
                                                                    d="M4.75 7H16.25C17.9069 7 19.25 8.34315 19.25 10V11.25"
                                                                    stroke="currentColor"
                                                                    stroke-width="1.5"
                                                                    stroke-linecap="round"
                                                                    stroke-linejoin="round"></path>
                                                                <path
                                                                    d="M16.75 19.25L19.25 17L16.75 14.75"
                                                                    stroke="currentColor"
                                                                    stroke-width="1.5"
                                                                    stroke-linecap="round"
                                                                    stroke-linejoin="round"></path>
                                                                <path
                                                                    d="M19.25 17H7.75C6.09315 17 4.75 15.6569 4.75 14V12.75"
                                                                    stroke="currentColor"
                                                                    stroke-width="1.5"
                                                                    stroke-linecap="round"
                                                                    stroke-linejoin="round"></path>
                                                            </svg>
                                                            <span class="text-sm" t="translation:global.buttons.validate"></span>
                                                        </div>
                                                    </ux-button>
                                                    <ux-button disabled.bind="saving" type="full" mode="outline" color="dark" click.trigger="export()">
                                                        <div class="flex items-center space-x-1">
                                                            <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                                                <path
                                                                    d="M16 480H0V448H16 368h16v32H368 16zM203.3 379.3L192 390.6l-11.3-11.3-128-128L41.4 240 64 217.4l11.3 11.3L176 329.4V224 48 32h32V48 224 329.4L308.7 228.7 320 217.4 342.6 240l-11.3 11.3-128 128z" />
                                                            </svg>
                                                            <span class="text-sm" t="translation:global.buttons.export"></span>
                                                        </div>
                                                    </ux-button>
                                                    <ux-dropdown
                                                        if.bind="hasRole(UserRoles.WriteMedicalRecords)"
                                                        disabled.bind="saving"
                                                        type="full"
                                                        placement="bottom-left"
                                                        color="dark"
                                                        mode="outline"
                                                        width="w-64">
                                                        <div au-slot="label" class="flex items-center gap-1">
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                            </svg>
                                                            <span class="text-sm" t="translation:global.labels.add"></span>
                                                        </div>
                                                        <ux-dropdown-option color="dark" click.trigger="addActions()">
                                                            <span class="text-sm" t="translation:global.labels.action"></span>
                                                        </ux-dropdown-option>
                                                        <ux-dropdown-option color="dark" click.trigger="addDds()">
                                                            <span class="text-sm" t="translation:global.labels.differential-diagnosis"></span>
                                                        </ux-dropdown-option>
                                                    </ux-dropdown>
                                                    <!-- <ux-button
                                        type="full"
                                        size="xs"
                                        mode="outline"
                                        color.bind="patient.generalPractitioner ? 'dark' : 'danger'"
                                        disabled.bind="!patient.generalPractitioner || saving"
                                        click.trigger="report()">
                                        <div class.bind="patient.generalPractitioner ? '' : 'text-red-800'" class="flex items-center space-x-1">
                                            <svg
                                                class.bind="patient.generalPractitioner ? '' : 'text-red-800'"
                                                class="w-4 h-4"
                                                xmlns="http://www.w3.org/2000/svg"
                                                height="1em"
                                                viewBox="0 0 576 512">
                                                <path
                                                    fill="currentColor"
                                                    d="M512 64c17.7 0 32 14.3 32 32v19.6L364.4 247.3c-16.9 12.4-39.9 12.4-56.8 0L128 115.6V96c0-17.7 14.3-32 32-32H512zM128 320V155.3L288.7 273.1c28.2 20.7 66.5 20.7 94.6 0L544 155.3V320c0 17.7-14.3 32-32 32H160c-17.7 0-32-14.3-32-32zM160 32c-35.3 0-64 28.7-64 64V320c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H160zM32 112c0-8.8-7.2-16-16-16s-16 7.2-16 16V352c0 70.7 57.3 128 128 128H464c8.8 0 16-7.2 16-16s-7.2-16-16-16H128c-53 0-96-43-96-96V112z" />
                                            </svg>
                                            <span class="text-sm" t="translation:global.buttons.report"></span>
                                        </div>
                                    </ux-button> -->
                                                    <ux-button if.bind="activeRecord.queue.length > 0" type="full" color="warning" click.trigger="showQueue()">
                                                        <div class="flex items-center gap-x-1">
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                                                <path
                                                                    stroke-linecap="round"
                                                                    stroke-linejoin="round"
                                                                    d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                                                            </svg>

                                                            <span class="text-sm" t="translation:partial-views.clinical-pathways.buttons.queue"></span>
                                                            <span>(${activeRecord.queue.length})</span>
                                                        </div>
                                                    </ux-button>
                                                </div>
                                            </div>
                                        </div>
                                        <div if.bind="hasRole(UserRoles.ReadHealthcareInvoices)" class="flex flex-col px-6 py-3 border-t border-gray-200">
                                            <div if.bind="invoices.length > 0" class="flex flex-col mb-3">
                                                <ux-label class="mb-1">
                                                    <span t="translation:global.labels.invoices"></span>
                                                </ux-label>
                                                <a repeat.for="invoice of invoices" click.trigger="editInvoice(invoice)" class="flex gap-1 text-sm text-primary" href="javascript:">
                                                    <span if.bind="invoice.type === HealthcareInvoiceTypes.Debit" t="translation:global.labels.invoice"></span>
                                                    <span else t="translation:global.labels.credit-invoice"></span>
                                                    <div if.bind="invoice.status === HealthcareInvoiceStatuses.Draft" class="flex items-center gap-1">
                                                        <span>${invoice.conceptNumber}</span>
                                                        <span class="flex"> (<span t="translation:global.labels.draft" class="lowercase"></span>) </span>
                                                    </div>
                                                    <span else>${invoice.trackingNumber}</span>
                                                </a>
                                            </div>
                                            <ux-button
                                                if.bind="hasRole(UserRoles.WriteHealthcareInvoices)"
                                                disabled.bind="saving"
                                                type="full"
                                                mode="outline"
                                                color="dark"
                                                click.trigger="generateInvoice()">
                                                <span class="text-sm" t="translation:partial-views.clinical-pathways.buttons.generate-invoice"></span>
                                            </ux-button>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex flex-col flex-1 overflow-hidden">
                                    <div class="flex items-center justify-between border-b-1 border-gray-200 px-6 py-2.5">
                                        <ux-tabs>
                                            <!-- Only show tab if phase has steps to take. -->
                                            <ux-tab
                                                repeat.for="item of activeRecord.examination.template.phases"
                                                if.bind="item.stepsToTake.length > 0"
                                                active.bind="item.phase.id === activeItem.phase.id"
                                                click.trigger="setActive(item, $index)"
                                                valid.bind="validation.phases[$index].valid">
                                                <span if.bind="phases[item.phase.id]">${ phases[item.phase.id].name | translate:language }</span>
                                                <span else>${ item.phase.name | translate:language }</span>
                                            </ux-tab>
                                        </ux-tabs>
                                        <ux-dropdown border.bind="false" type="full" color="dark" size="sm" padding.bind="false" mode="outline" placement="bottom-right">
                                            <div au-slot="label" class="p-1 text-gray-600 rounded-full ring-gray-400 focus:outline-none focus:ring-1">
                                                <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512">
                                                    <path
                                                        fill="currentColor"
                                                        d="M64 368C90.51 368 112 389.5 112 416C112 442.5 90.51 464 64 464C37.49 464 16 442.5 16 416C16 389.5 37.49 368 64 368zM64 208C90.51 208 112 229.5 112 256C112 282.5 90.51 304 64 304C37.49 304 16 282.5 16 256C16 229.5 37.49 208 64 208zM64 144C37.49 144 16 122.5 16 96C16 69.49 37.49 48 64 48C90.51 48 112 69.49 112 96C112 122.5 90.51 144 64 144z" />
                                                </svg>
                                            </div>
                                            <ux-dropdown-option click.trigger="collapseOrExpandAll('expand')">
                                                <span class="text-sm" t="global.buttons.expand-all"></span>
                                            </ux-dropdown-option>
                                            <ux-dropdown-option click.trigger="collapseOrExpandAll('collapse')">
                                                <span class="text-sm" t="global.buttons.collapse-all"></span>
                                            </ux-dropdown-option>
                                        </ux-dropdown>
                                    </div>
                                    <!-- 
                        Render every phase so that we can register every available widget in the entire examination.
                        This way we can always call the 'onSave' and 'validate' callbacks of every widget.
                        We know that rendering all phases is not the most efficient/performant way,
                        but it is the most reliable way.
                        -->
                                    <template repeat.for="item of activeRecord.examination.template.phases">
                                        <!-- Only show the active phase though -->
                                        <div class="flex flex-col flex-1 overflow-hidden" show.bind="item.phase.id === activeItem.phase.id">
                                            <div ref="scrollContainers[$index]" id="medical-records-scrollcontainer" class="flex flex-col flex-1 px-6 pt-6 overflow-y-auto">
                                                <!-- <div class.bind="hasScrolled ? 'border-b' : ''" class="sticky top-0 z-40 flex flex-col pt-2 bg-white border-gray-200">
                                        <div class="flex justify-between pr-2 mb-2">
                                            <p if.bind="phases[item.phase.id]" class="font-medium text-gray-400">${ phases[item.phase.id].name | translate:language}</p>
                                            <p else class="font-medium text-gray-400">${ item.phase.name | translate:language }</p>
                                        </div>
                                    </div> -->
                                                <div class="flex flex-col flex-1">
                                                    <div class="grid grid-cols-12 gap-2 pb-6 auto-cols-fr">
                                                        <template repeat.for="step of item.stepsToTake">
                                                            <template-action
                                                                if.bind="step.type === MedicalExaminationTemplateItemStepTypes.Action"
                                                                flattened.bind="flattened"
                                                                record.bind="activeRecord"
                                                                container.bind="step"
                                                                states.bind="states"
                                                                step.bind="step"
                                                                x-scroll-container.bind="partial.base.id"
                                                                workspace.bind="authenticated.workspace.id"
                                                                validation.bind="validation.phases[$parent.$index].phaseSteps[$index]"
                                                                on-file-selected.bind="handleFileSelected"
                                                                on-file-removed.bind="handleFileRemoved"
                                                                flow.bind="activeRecord.examination.flow"
                                                                add-partial.bind="addPartial"
                                                                remove.bind="removeStep"
                                                                loading.bind="loader"
                                                                language.bind="language"
                                                                duplicate.bind="duplicateStep"
                                                                phase-index.bind="$parent.$index"
                                                                index.bind="$index"
                                                                registrations.bind="registrations"
                                                                registration.bind="registrations[step.id]">
                                                            </template-action>
                                                            <template-question
                                                                if.bind="step.type === MedicalExaminationTemplateItemStepTypes.Question"
                                                                flattened.bind="flattened"
                                                                record.bind="activeRecord"
                                                                container.bind="step"
                                                                step.bind="step"
                                                                states.bind="states"
                                                                x-scroll-container.bind="partial.base.id"
                                                                workspace.bind="authenticated.workspace.id"
                                                                validation.bind="validation.phases[$parent.$index].phaseSteps[$index]"
                                                                on-file-selected.bind="handleFileSelected"
                                                                on-file-removed.bind="handleFileRemoved"
                                                                add-partial.bind="addPartial"
                                                                flow.bind="activeRecord.examination.flow"
                                                                phase-index.bind="$parent.$index"
                                                                remove.bind="removeStep"
                                                                loading.bind="loader"
                                                                language.bind="language"
                                                                registrations.bind="registrations"
                                                                manage-translations.bind="manageTranslationsFor"
                                                                registrations.bind="registrations"
                                                                registration.bind="registrations[step.id]">
                                                            </template-question>
                                                            <template-questionnaire
                                                                if.bind="step.type === MedicalExaminationTemplateItemStepTypes.Questionnaire"
                                                                flattened.bind="flattened"
                                                                record.bind="activeRecord"
                                                                container.bind="step"
                                                                step.bind="step"
                                                                states.bind="states"
                                                                x-scroll-container.bind="partial.base.id"
                                                                workspace.bind="authenticated.workspace.id"
                                                                validation.bind="validation.phases[$parent.$index].phaseSteps[$index]"
                                                                on-file-selected.bind="handleFileSelected"
                                                                on-file-removed.bind="handleFileRemoved"
                                                                flow.bind="activeRecord.examination.flow"
                                                                remove.bind="removeStep"
                                                                add-partial.bind="addPartial"
                                                                phase-index.bind="$parent.$index"
                                                                loading.bind="loader"
                                                                language.bind="language"
                                                                registrations.bind="registrations"
                                                                manage-translations.bind="manageTranslationsFor"
                                                                registrations.bind="registrations"
                                                                registration.bind="registrations[step.id]">
                                                            </template-questionnaire>
                                                            <template-widget
                                                                if.bind="step.type === MedicalExaminationTemplateItemStepTypes.Widget"
                                                                flattened.bind="flattened"
                                                                record.bind="activeRecord"
                                                                container.bind="step"
                                                                step.bind="step"
                                                                states.bind="states"
                                                                x-scroll-container.bind="partial.base.id"
                                                                all-validation.bind="validation"
                                                                validation.two-way="validation.phases[$parent.$index].phaseSteps[$index]"
                                                                workspace.bind="authenticated.workspace.id"
                                                                on-file-selected.bind="handleFileSelected"
                                                                on-file-removed.bind="handleFileRemoved"
                                                                flow.bind="activeRecord.examination.flow"
                                                                phase-index.bind="$parent.$index"
                                                                remove.bind="removeStep"
                                                                loading.bind="loader"
                                                                language.bind="language"
                                                                patient.bind="patient"
                                                                widgets.bind="widgets"
                                                                add-partial.bind="addPartial"
                                                                scroll-to-view.bind="scrollToView"
                                                                manage-translations.bind="manageTranslationsFor"
                                                                current-user.bind="authenticated.user"
                                                                registrations.bind="registrations"
                                                                registration.bind="registrations[step.id]">
                                                            </template-widget>
                                                        </template>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </template>
                        <template else>
                            <div class="flex items-center justify-center flex-1 px-8">
                                <div class="grid flex-1 max-w-lg grid-cols-1 gap-5">
                                    <div class="flex flex-col flex-1 space-y-3">
                                        <ux-skeleton show-background="true" type="container">
                                            <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                                <ux-skeleton type="line" padding="pr-60"></ux-skeleton>
                                                <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                            </div>
                                        </ux-skeleton>
                                        <ux-skeleton show-background="true" type="container">
                                            <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                                <ux-skeleton type="line" padding="pr-48"></ux-skeleton>
                                                <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                            </div>
                                        </ux-skeleton>
                                        <ux-skeleton show-background="true" type="container">
                                            <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                                <ux-skeleton type="line" padding="pr-20"></ux-skeleton>
                                                <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                                <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                                                <ux-skeleton type="line" padding="pr-8"></ux-skeleton>
                                                <ux-skeleton type="line" padding="pr-6"></ux-skeleton>
                                                <ux-skeleton type="line" padding="pr-0"></ux-skeleton>
                                                <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                            </div>
                                        </ux-skeleton>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </template>
                <ux-footer if.bind="baseLoaded && !saving">
                    <div class="flex justify-between flex-1 gap-x-2">
                        <div class="flex gap-x-2">
                            <ux-button
                                if.bind="baseLoaded && hasRole(UserRoles.DeleteMedicalRecords) && canDelete()"
                                color="danger"
                                disabled.bind="isLoading || deleting|| saving || generating"
                                loading.bind="deleting"
                                click.trigger="delete()">
                                <span class="pointer-events-none" t="global.buttons.delete"></span>
                            </ux-button>
                        </div>
                        <div class="flex gap-x-2">
                            <ux-button color="dark" mode="outline" disabled.bind="isLoading || deleting || saving || generating" click.trigger="cancel()">
                                <span class="pointer-events-none" t="global.buttons.close"></span>
                            </ux-button>
                            <ux-button
                                if.bind="baseLoaded && hasRole(UserRoles.WriteMedicalRecords)"
                                color="success"
                                disabled.bind="isLoading || deleting || saving || generating || !activeRecord"
                                loading.bind="isLoading || saving"
                                click.trigger="save()"
                                t="[text-when-loading]translation:global.labels.loading-states.default">
                                <span class="pointer-events-none" t="global.buttons.save"></span>
                            </ux-button>
                        </div>
                    </div>
                </ux-footer>
            </template>
            <template else>
                <div class="flex flex-col items-center flex-1 pt-52">
                    <div class="flex flex-col items-center flex-1 max-w-lg">
                        <div class="flex gap-1 mb-5 text-2xl font-medium">
                            <p t="translation:global.labels.clinical-pathway"></p>
                            <p>#${pathway.trackingNumber}</p>
                        </div>
                        <p class="text-lg font-bold text-center" t="translation:partial-views.clinical-pathways.labels.select-examination"></p>
                        <span class="mb-5 text-sm text-center text-gray-500" t="translation:partial-views.clinical-pathways.messages.select-examination"></span>
                        <div class="flex flex-col w-full gap-3">
                            <div class="flex flex-col">
                                <ux-label class="mb-1">
                                    <span t="translation:partial-views.clinical-pathways.labels.examination"></span>
                                </ux-label>
                                <bx-medical-examination-selector
                                    component.ref="examinationSelector"
                                    disabled.bind="starting"
                                    valid.bind="creationValidation.examination"
                                    on-select.bind="handleExaminationSelected"
                                    language.bind="language"
                                    workspace.bind="authenticated.workspace.id"
                                    t="[placeholder]translation:partial-views.clinical-pathways.placeholders.select-examination">
                                </bx-medical-examination-selector>
                            </div>
                            <div class="flex flex-col">
                                <ux-label class="mb-1">
                                    <span t="translation:partial-views.clinical-pathways.labels.scheduler-item"></span>
                                </ux-label>
                                <bx-scheduler-item-selector
                                    component.ref="schedulerItemSelector"
                                    disabled.bind="starting"
                                    valid.bind="creationValidation.schedulerItem"
                                    on-select.bind="handleSchedulerItemSelected"
                                    language.bind="language"
                                    workspace.bind="authenticated.workspace.id"
                                    patients.bind="[patient.id]"
                                    t="[placeholder]translation:partial-views.clinical-pathways.placeholders.select-scheduler-item">
                                </bx-scheduler-item-selector>
                            </div>
                        </div>
                        <div class="flex flex-col gap-5 mt-5">
                            <div class="flex items-center gap-2">
                                <ux-button if.bind="previousRecord" click.trigger="loadPreviousRecord()" disabled.bind="starting" loading.bind="canceling" color="dark" mode="outline">
                                    <span t="translation:global.buttons.cancel"></span>
                                </ux-button>
                                <ux-button else click.trigger="cancel()" disabled.bind="starting" loading.bind="canceling" color="dark" mode="outline">
                                    <span t="translation:global.buttons.close"></span>
                                </ux-button>
                                <ux-button click.trigger="startRecord()" loading.bind="starting" disabled.bind="canceling" color="success">
                                    <span t="translation:global.buttons.start"></span>
                                </ux-button>
                            </div>
                            <a if.bind="records.length === 0" href="javascript:" class="text-sm text-center text-red-500 hover:underline" click.trigger="deletePathway()">
                                <span class="pointer-events-none" t="translation:partial-views.clinical-pathways.buttons.delete-pathway"></span>
                            </a>
                        </div>
                    </div>
                </div>
            </template>
        </template>
    </section>
</template>
