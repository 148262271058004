import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { SchedulerItemsApiClient, ExaminationRoom, GetSchedulerItemResponse, GetPatientResponse, PatientsApiClient, UserEntityReference, SchedulerItemStatuses, SchedulerContext } from '@wecore/sdk-healthcare';
import { IEventAggregator, inject } from 'aurelia';
import { format } from 'date-fns';
import { PartialViewResults } from '../../enums/partial-view-results';
import { BasePartialView } from '../../infra/base-partial-view';
import { Broadcasters } from '../../infra/broadcasts/broadcasters';
import { Broadcasts } from '../../infra/broadcasts/broadcasts';
import { SchedulerBroadcaster } from '../../infra/broadcasts/scheduler-broadcaster';
import { CacheService } from '../../infra/cache-service';
import { ErrorHandler } from '../../infra/error-handler';
import { PartialViews } from '../../infra/partial-views';
import { State } from '../../infra/store/state';
import { cloneDeep } from '../../infra/utilities';
import { ConfirmationOptions } from '../../models/confirmation-options';
import { PartialView } from '../../models/partial-view';
import { ViewOptions } from '../../models/view-options';
import { ModalService } from '../../services/service.modals';
import { SchedulerItemTypes } from '@wecore/sdk-core';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, PatientsApiClient, SchedulerItemsApiClient, ModalService, SchedulerBroadcaster)
export class PartialPatientAppointments extends BasePartialView {
    public patient: GetPatientResponse;
    public appointments: GetSchedulerItemResponse[];
    public connectorState: string = Broadcasts.OnReconnecting;
    public SchedulerItemStatuses: typeof SchedulerItemStatuses = SchedulerItemStatuses;

    private patientId: string;

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly patientsApi: PatientsApiClient,
        private readonly itemsApi: SchedulerItemsApiClient,
        private readonly modalService: ModalService,
        private readonly schedulerBroadcaster: SchedulerBroadcaster
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.patientId = view.data.id;
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                const [patient] = await Promise.all([
                    this.patientsApi.getById(this.patientId, this.authenticated.workspace.id), //
                    this.fetchData()
                ]);

                this.subscriptions = [
                    ...(this.subscriptions ?? []),
                    this.events.subscribe(Broadcasts.OnConnected, (data: { broadcaster: string; connectionId: string }) => {
                        if (data.broadcaster !== Broadcasters.SchedulerBroadcaster) return;
                        this.connectorState = Broadcasts.OnConnected;
                    }),
                    this.events.subscribe(Broadcasts.OnDisconnected, (data: { broadcaster: string; state: string; error: string }) => {
                        if (data.broadcaster !== Broadcasters.SchedulerBroadcaster) return;
                        this.connectorState = data.state;
                    }),
                    this.events.subscribe(Broadcasts.OnReconnected, (data: { broadcaster: string; state: string; error: string }) => {
                        if (data.broadcaster !== Broadcasters.SchedulerBroadcaster) return;
                        this.connectorState = data.state;
                    }),
                    this.events.subscribe(Broadcasts.OnReconnecting, (data: { broadcaster: string; state: string; error: string }) => {
                        if (data.broadcaster !== Broadcasters.SchedulerBroadcaster) return;
                        this.connectorState = data.state;
                    }),
                    this.events.subscribe(Broadcasts.SchedulerItemCreated, (data: { schedulerItem: GetSchedulerItemResponse }) => {
                        if (data.schedulerItem.patients.every((x) => x.id !== this.patient.id)) return;
                        this.fetchData();
                    }),
                    this.events.subscribe(
                        Broadcasts.SchedulerItemUpdated,
                        (data: { schedulerItem: GetSchedulerItemResponse; oldStart: Date; oldEnd: Date; oldRoom: ExaminationRoom; oldPractitioner: UserEntityReference }) => {
                            if (data.schedulerItem.patients.every((x) => x.id !== this.patient.id)) return;
                            this.fetchData();
                        }
                    ),
                    this.events.subscribe(Broadcasts.SchedulerItemDeleted, (data: { schedulerItem: GetSchedulerItemResponse }) => {
                        if (data.schedulerItem.patients.every((x) => x.id !== this.patient.id)) return;
                        this.fetchData();
                    })
                ];

                this.patient = patient;
                this.schedulerBroadcaster.start(this.cache.workspace);
                this.baseLoaded = true;
            })
            .catch((x) => this.errorHandler.handle('PartialPatientSchedulerItems.attached', x));
    }

    public detaching(): void {
        this.schedulerBroadcaster.stop();
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }

    public async close(): Promise<void> {
        await super.removeChildViews();
        await super.remove({
            result: PartialViewResults.Cancelled,
            updateUrl: true
        });
    }

    public async open(schedulerItem: GetSchedulerItemResponse): Promise<void> {
        await this.removeChildViews();
        await this.addPartialView({
            view: this.partial.base, //
            partial: PartialViews.EditSchedulerItem.with({ id: schedulerItem.id }).whenClosed(async (result: PartialViewResults) => {
                if (result === PartialViewResults.Ok) {
                    await this.fetchData();
                }
            }),
            options: new ViewOptions({ scrollToView: true, markItem: false, replace: true, updateUrl: false })
        });
    }

    public async delete(schedulerItem: GetSchedulerItemResponse): Promise<void> {
        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('translation:partial-views.patient-appointments.questions.delete-scheduler-item.title'),
                message: this.t
                    .tr('translation:partial-views.patient-scheduler-item.questions.delete-scheduler-item.message') //
                    .replace('{timestamps}', `<span class="font-semibold text-red-500">${format(schedulerItem.start, 'dd-MM-yyyy HH:mm')} - ${format(schedulerItem.end, 'HH:mm')}</span>`)
                    .replace('{patient}', `<span class="font-semibold text-red-500">${schedulerItem.patients[0].name}</span>`),
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        await this.itemsApi.delete(schedulerItem.id, this.authenticated.workspace.id);
                        this.appointments = this.appointments.filter((x) => x.id !== schedulerItem.id);
                    }
                }
            })
        );
    }

    public async markAsNoShow(schedulerItem: GetSchedulerItemResponse): Promise<void> {
        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('translation:partial-views.patient-appointments.questions.mark-no-show.title'),
                message: this.t.tr('translation:partial-views.patient-appointments.questions.mark-no-show.message'),
                btnOk: this.t.tr('translation:global.buttons.yes'),
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        try {
                            await this.itemsApi.markAsNoShow(schedulerItem.id, this.authenticated.workspace.id);
                            const index = this.appointments.findIndex((x) => x.id === schedulerItem.id);
                            if (index !== -1) this.appointments[index].status = SchedulerItemStatuses.NoShow;
                        } catch (e) {
                            await this.errorHandler.handle('[patient-appointments/mark-noshow]', e);
                        }
                    }
                }
            })
        );
    }

    public async unmarkAsNoShow(schedulerItem: GetSchedulerItemResponse): Promise<void> {
        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('translation:partial-views.patient-appointments.questions.unmark-no-show.title'),
                message: this.t.tr('translation:partial-views.patient-appointments.questions.unmark-no-show.message'),
                btnOk: this.t.tr('translation:global.buttons.yes'),
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        try {
                            await this.itemsApi.unmarkAsNoShow(schedulerItem.id, this.authenticated.workspace.id);
                            const index = this.appointments.findIndex((x) => x.id === schedulerItem.id);
                            if (index !== -1) this.appointments[index].status = SchedulerItemStatuses.Scheduled;
                        } catch (e) {
                            await this.errorHandler.handle('[patient-appointments/unmark-noshow]', e);
                        }
                    }
                }
            })
        );
    }

    private async fetchData(): Promise<void> {
        this.appointments = [];
        const response = await this.itemsApi.search(
            SchedulerContext.Unknown,
            this.authenticated.workspace.id,
            '',
            500,
            0,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            [this.patientId],
            [SchedulerItemTypes.Appointment]
        );
        this.appointments = response.data;
    }
}
