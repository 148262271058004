<import from="converters/translate" />
<import from="./template-action-category/template-action-category" />
<import from="./template-action-step/template-action-step" />

<div class="${columns} flex flex-col">
    <div
        class.bind="state.clipboard.templateItem && state.clipboard.templateItem.item.id === step.id ? 'bg-gray-200' : !step.attributes.action && !nested ? 'bg-red-100 text-red-600': 'bg-gray-100'"
        class="flex items-center justify-between pr-3 text-sm font-medium text-gray-800 rounded-lg">
        <a href="javascript:" click.trigger="collapseOrExpand()" class="flex items-center flex-1 min-w-0 py-2 pl-3 gap-x-1">
            <template if.bind="step.attributes.expanded">
                <svg class="h-[1.2rem] w-[1.2rem] text-indigo-500" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M9.25 19.25V16.75C9.25 15.6454 8.35457 14.75 7.25 14.75H4.75"></path>
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M14.75 19.25V16.75C14.75 15.6454 15.6454 14.75 16.75 14.75H19.25"></path>
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M14.75 4.75V7.25C14.75 8.35457 15.6454 9.25 16.75 9.25H19.25"></path>
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M9.25 4.75V7.25C9.25 8.35457 8.35457 9.25 7.25 9.25H4.75"></path>
                </svg>
            </template>
            <template else>
                <svg class="h-[1.2rem] w-[1.2rem] text-indigo-500" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M4.75 14.75V17.25C4.75 18.3546 5.64543 19.25 6.75 19.25H9.25"></path>
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M19.25 14.75V17.25C19.25 18.3546 18.3546 19.25 17.25 19.25H14.75"></path>
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M19.25 9.25V6.75C19.25 5.64543 18.3546 4.75 17.25 4.75H14.75"></path>
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M4.75 9.25V6.75C4.75 5.64543 5.64543 4.75 6.75 4.75H9.25"></path>
                </svg>
            </template>
            <div class="flex items-center justify-between flex-1 min-w-0 pr-2">
                <div class="flex items-center min-w-0 gap-x-3">
                    <span class="text-gray-500" t="translation:global.labels.action"></span>
                    <span if.bind="nested" class="block truncate">${ step.action.name | translate:language }</span>
                    <span if.bind="!nested && step.attributes.action" class="block truncate">${ step.attributes.action.name | translate:language }</span>
                    <span if.bind="!nested && !step.attributes.action" class="block truncate" t="translation:global.messages.deleted-action"></span>
                </div>
                <div class="flex gap-0.5">
                    <div if.bind="isRequired" class="h-2 w-2 rounded-sm bg-[#F25366]"></div>
                </div>
            </div>
        </a>
        <ux-dropdown border.bind="false" transparent.bind="true" type="full" color="dark" size="sm" padding.bind="false" mode="outline" placement="bottom-right">
            <div au-slot="label" class="p-1 text-gray-600 rounded-full ring-gray-400 focus:outline-none focus:ring-1">
                <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512">
                    <path
                        fill="currentColor"
                        d="M64 368C90.51 368 112 389.5 112 416C112 442.5 90.51 464 64 464C37.49 464 16 442.5 16 416C16 389.5 37.49 368 64 368zM64 208C90.51 208 112 229.5 112 256C112 282.5 90.51 304 64 304C37.49 304 16 282.5 16 256C16 229.5 37.49 208 64 208zM64 144C37.49 144 16 122.5 16 96C16 69.49 37.49 48 64 48C90.51 48 112 69.49 112 96C112 122.5 90.51 144 64 144z" />
                </svg>
            </div>
            <template if.bind="amountOfSteps > 1 && !nested">
                <ux-dropdown-option if.bind="index !== 0" click.trigger="moveStep('up', index)">
                    <span class="text-sm" t="global.buttons.move-up"></span>
                </ux-dropdown-option>
                <ux-dropdown-option if.bind="index < amountOfSteps - 1" click.trigger="moveStep('down', index)">
                    <span class="text-sm" t="global.buttons.move-down"></span>
                </ux-dropdown-option>
            </template>
            <ux-dropdown-option if.bind="!nested" click.trigger="copyOrCut('cut', index)">
                <span class="text-sm" t="global.buttons.cut"></span>
            </ux-dropdown-option>
            <ux-dropdown-option click.trigger="addStep(index)" if.bind="!nested">
                <span class="text-sm" t="translation:global.buttons.add-before"></span>
            </ux-dropdown-option>
            <ux-dropdown-option click.trigger="addStep(index + 1)" if.bind="!nested">
                <span class="text-sm" t="translation:global.buttons.add-after"></span>
            </ux-dropdown-option>
            <ux-dropdown-option click.trigger="pasteItem(getIndex('before'))" if.bind="state.clipboard.templateItem && state.clipboard.templateItem.item.id !== step.id && !nested">
                <span class="text-sm" t="translation:global.buttons.paste-before"></span>
            </ux-dropdown-option>
            <ux-dropdown-option click.trigger="pasteItem(getIndex('after'))" if.bind="state.clipboard.templateItem && state.clipboard.templateItem.item.id !== step.id && !nested">
                <span class="text-sm" t="translation:global.buttons.paste-after"></span>
            </ux-dropdown-option>
            <ux-dropdown-option click.trigger="openSettings(step)">
                <span class="text-sm" t="global.buttons.settings"></span>
            </ux-dropdown-option>
            <ux-dropdown-divider if.bind="!nested"></ux-dropdown-divider>
            <ux-dropdown-option if.bind="!nested" click.trigger="removeStep(index)">
                <span class="text-sm text-red-500" t="global.buttons.delete"></span>
            </ux-dropdown-option>
        </ux-dropdown>
    </div>
    <template if.bind="step.attributes.expanded">
        <div class="grid grid-cols-12 gap-1 pl-2 mt-1 ml-1 border-l-2 border-gray-300">
            <template repeat.for="stepToTake of step.attributes.action.stepsToTake">
                <template if.bind="stepToTake.type === MedicalExaminationActionItemTypes.Category">
                    <template-action-category item.bind="stepToTake" index.bind="$index" flow.bind="step.attributes.action.flow" language.bind="language"> </template-action-category>
                </template>
                <template if.bind="stepToTake.type === MedicalExaminationActionItemTypes.Step">
                    <template-action-step item.bind="stepToTake" index.bind="$index" flow.bind="step.attributes.action.flow" language.bind="language"> </template-action-step>
                </template>
            </template>
        </div>
    </template>
</div>
