import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { GetHealthcareInvoiceResponse, GetPatientResponse, HealthcareInvoiceStatuses, HealthcareInvoiceTypes, HealthcareInvoicesApiClient, PatientsApiClient } from '@wecore/sdk-healthcare';

import { IEventAggregator, inject } from 'aurelia';
import { PartialViewResults } from '../../enums/partial-view-results';
import { BasePartialView } from '../../infra/base-partial-view';
import { CacheService } from '../../infra/cache-service';
import { ErrorHandler } from '../../infra/error-handler';
import { CustomEvents } from '../../infra/events';
import { PartialViews } from '../../infra/partial-views';
import { State } from '../../infra/store/state';
import { PartialView } from '../../models/partial-view';
import { ViewOptions } from '../../models/view-options';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, PatientsApiClient, HealthcareInvoicesApiClient)
export class PartialPatientInvoices extends BasePartialView {
    public patient: GetPatientResponse;
    public HealthcareInvoiceStatuses: typeof HealthcareInvoiceStatuses = HealthcareInvoiceStatuses;
    public HealthcareInvoiceTypes: typeof HealthcareInvoiceTypes = HealthcareInvoiceTypes;
    public invoices: GetHealthcareInvoiceResponse[];

    private patientId: string;

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly patientsApi: PatientsApiClient,
        private readonly invoicesApi: HealthcareInvoicesApiClient
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.patientId = view.data.id;
        this.subscriptions = [
            ...(this.subscriptions ?? []),
            this.events.subscribe(CustomEvents.HealthcareInvoicesUpdated, async () => {
                const response = await this.invoicesApi.search(this.authenticated.workspace.id, '', 500, 0, undefined, undefined, undefined, undefined, [this.patientId]);
                this.invoices = response.data;
            })
        ];
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                const [patient, invoices] = await Promise.all([
                    this.patientsApi.getById(this.patientId, this.authenticated.workspace.id), //
                    this.invoicesApi.search(this.authenticated.workspace.id, '', 500, 0, undefined, undefined, undefined, undefined, [this.patientId])
                ]);

                this.patient = patient;
                this.invoices = invoices.data;
                this.baseLoaded = true;

                // this.open(invoices.data[0]);
            })
            .catch((x) => this.errorHandler.handle('PartialPatientInvoices.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }

    public async close(): Promise<void> {
        await super.removeChildViews();
        await super.remove({
            result: PartialViewResults.Cancelled,
            updateUrl: true
        });
    }

    public async open(invoice: GetHealthcareInvoiceResponse): Promise<void> {
        await this.removeChildViews();
        await this.addPartialView({
            view: this.partial.base, //
            partial: PartialViews.EditHealthcareInvoice.with({ id: invoice.id }),
            options: new ViewOptions({ scrollToView: true, markItem: false, replace: true, updateUrl: false })
        });
    }
}
