import { DialogCloseResult, IDialogService } from '@aurelia/dialog';
import { inject } from 'aurelia';
import { ModalConfirmWithPassword } from '../modals/modal-confirm-with-password/modal-confirm-with-password';
import { ModalConfirm } from '../modals/modal-confirm/modal-confirm';
import { ConfirmationOptions } from '../models/confirmation-options';
import { ModalConfirmWithComments } from '../modals/modal-confirm-with-comments/modal-confirm-with-comments';
import { ModalConfirmWithPhrase } from '../modals/modal-confirm-with-phrase/modal-confirm-with-phrase';

@inject(IDialogService)
export class ModalService {
    public constructor(
        private readonly service: IDialogService //
    ) {}

    public async confirm(options: ConfirmationOptions): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.service
                .open({
                    component: () => ModalConfirm,
                    model: options,
                    overlayDismiss: false
                })
                .whenClosed((result) => resolve(result.status !== 'cancel'))
                .catch((err) => reject(err));
        });
    }

    public async confirmWithPassword(options: ConfirmationOptions): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.service
                .open({
                    component: () => ModalConfirmWithPassword,
                    model: options,
                    overlayDismiss: false
                })
                .whenClosed((result) => resolve(result.status !== 'cancel'))
                .catch((err) => reject(err));
        });
    }

    public async confirmWithPhrase(phrase: string, options: ConfirmationOptions): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.service
                .open({
                    component: () => ModalConfirmWithPhrase,
                    model: {
                        options,
                        phrase
                    },
                    overlayDismiss: false
                })
                .whenClosed((result) => resolve(result.status !== 'cancel'))
                .catch((err) => reject(err));
        });
    }

    public async confirmWithComments(options: ConfirmationOptions): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.service
                .open({
                    component: () => ModalConfirmWithComments,
                    model: options,
                    overlayDismiss: false
                })
                .whenClosed((result) => resolve(result.status !== 'cancel'))
                .catch((err) => reject(err));
        });
    }

    public async open(model: any, options: any = {}): Promise<DialogCloseResult> {
        return new Promise<DialogCloseResult>((resolve, reject) => {
            this.service
                .open({
                    component: () => model,
                    model: options,
                    overlayDismiss: true
                })
                .whenClosed((result) => resolve(result))
                .catch((err) => reject(err));
        });
    }
}
