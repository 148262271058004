import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { isNotEmpty } from '@wecore/sdk-utilities';
import { IEventAggregator, inject } from 'aurelia';
import { PartialViewResults } from '../../enums/partial-view-results';
import { BasePartialView } from '../../infra/base-partial-view';
import { CacheService } from '../../infra/cache-service';
import { ErrorHandler } from '../../infra/error-handler';
import { PartialViews } from '../../infra/partial-views';
import { State } from '../../infra/store/state';
import { PartialView } from '../../models/partial-view';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N)
export class PartialPracticeIntegrations extends BasePartialView {
    public active: PartialView;
    public settings: {
        title: string;
        view: PartialView;
    }[];

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N
    ) {
        super(cache, errorHandler, events, store, t);
        this.settings = [
            // {
            //     title: 'Vecozo',
            //     view: PartialViews.IntegrationsVecozo
            // },
            {
                title: 'Zorgdomein',
                view: PartialViews.IntegrationsZorgdomein
            },
            {
                title: 'Zorgmail',
                view: PartialViews.IntegrationsZorgmail
            }
        ];
    }

    public activate(view: PartialView): void {
        super.setView({ view });
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                // Wait for the PAGE route to be changed. Otherwise, the
                // correct partial view will not be loaded.
                setTimeout(() => {
                    this.loadViewsFromUrl({
                        open: async (view: string) => {
                            if (isNotEmpty(view)) await this.changeToView(PartialViews[view], false);
                            else {
                                this.active = this.settings[0].view;
                                await this.changeToView(this.active, true);
                            }
                        }
                    });
                });
            })
            .catch((x) => this.errorHandler.handle('PartialPracticeIntegrations.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }

    public async changeToView(view: PartialView, updateUrl: boolean = true): Promise<void> {
        this.active = view;
        await this.changeTo(
            view.whenClosed(async (result: PartialViewResults) => {
                if (result === PartialViewResults.Cancelled) {
                    this.active = null;
                }
            }),
            updateUrl
        );
    }
}
