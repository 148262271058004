<import from="converters/date" />

<template class="!flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex overflow-hidden gap-x-2" css.bind="styles">
        <div id="patients-scroll-container" ref="scrollContainer" class="flex flex-col flex-1 overflow-y-auto">
            <div class="sticky top-0 z-10 bg-white/50 backdrop-blur-xl">
                <ux-card-header>
                    <div class="flex flex-col flex-1 px-6 py-4">
                        <div class="flex h-[36px] items-center justify-between">
                            <h4 class="text-lg font-semibold text-gray-800" t="translation:partial-views.patients.list.title"></h4>
                            <div class="flex gap-1">
                                <div if.bind="fetching" class="flex size-[38px] items-center justify-center rounded-lg border border-gray-300">
                                    <ux-spinner size="xxs"></ux-spinner>
                                </div>
                                <ux-button if.bind="baseLoaded && hasRole(UserRoles.WritePatients)" click.trigger="create()" size="sm">
                                    <span t="translation:global.buttons.new"></span>
                                </ux-button>
                                <ux-dropdown
                                    if.bind="baseLoaded && integrationEnabled('jamesSoftware') && hasRole(UserRoles.WritePatients)"
                                    type="full"
                                    color="dark"
                                    padding.bind="false"
                                    mode="outline"
                                    placement="bottom-right">
                                    <div au-slot="label" class="flex h-[34px] items-center justify-center rounded-full p-2 text-gray-600 ring-gray-400 focus:outline-none focus:ring-1">
                                        <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512">
                                            <path
                                                fill="currentColor"
                                                d="M64 368C90.51 368 112 389.5 112 416C112 442.5 90.51 464 64 464C37.49 464 16 442.5 16 416C16 389.5 37.49 368 64 368zM64 208C90.51 208 112 229.5 112 256C112 282.5 90.51 304 64 304C37.49 304 16 282.5 16 256C16 229.5 37.49 208 64 208zM64 144C37.49 144 16 122.5 16 96C16 69.49 37.49 48 64 48C90.51 48 112 69.49 112 96C112 122.5 90.51 144 64 144z" />
                                        </svg>
                                    </div>
                                    <ux-dropdown-option click.trigger="sync()">
                                        <span t="global.buttons.sync"></span>
                                    </ux-dropdown-option>
                                </ux-dropdown>
                            </div>
                        </div>
                        <div class="grid grid-cols-3 mt-2 gap-x-3">
                            <div class="p-16 bg-gray-100 rounded-lg"></div>
                            <div class="p-16 bg-gray-100 rounded-lg"></div>
                            <div class="p-16 bg-gray-100 rounded-lg"></div>
                        </div>
                    </div>
                </ux-card-header>
                <ux-table-header>
                    <ux-table-row lock="false" location="header" class="border-b border-gray-200">
                        <ux-table-cell class="py-[0.3rem] pl-6 pr-0">
                            <a href="javascript:" click.trigger="sort('LastName')" class="flex items-center justify-between flex-1 text-gray-500">
                                <span t="translation:global.labels.patient"></span>
                                <div class="flex flex-col">
                                    <svg
                                        class="${ sorting.field === 'LastName' && sorting.direction === SortDirection.Desc ? 'text-red-500' : 'text-gray-400'} relative top-[0.2rem] h-4 w-4"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7"></path>
                                    </svg>
                                    <svg
                                        class="${ sorting.field === 'LastName' && sorting.direction === SortDirection.Asc ? 'text-red-500' : 'text-gray-400'} relative bottom-[0.2rem] h-4 w-4"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
                                    </svg>
                                </div>
                            </a>
                        </ux-table-cell>
                        <ux-table-cell class="py-[0.3rem] pl-6 pr-0" width="200">
                            <a href="javascript:" click.trigger="sort('CreatedAt')" class="flex items-center justify-between flex-1 text-gray-500">
                                <span t="translation:global.labels.created-at"></span>
                                <div class="flex flex-col">
                                    <svg
                                        class="${ sorting.field === 'CreatedAt' && sorting.direction === SortDirection.Desc ? 'text-red-500' : 'text-gray-400'} relative top-[0.2rem] h-4 w-4"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7"></path>
                                    </svg>
                                    <svg
                                        class="${ sorting.field === 'CreatedAt' && sorting.direction === SortDirection.Asc ? 'text-red-500' : 'text-gray-400'} relative bottom-[0.2rem] h-4 w-4"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
                                    </svg>
                                </div>
                            </a>
                        </ux-table-cell>
                        <ux-table-cell class="py-[0.3rem] pl-6 pr-0" width="200">
                            <span class="text-gray-500" t="translation:global.labels.practitioner"></span>
                        </ux-table-cell>
                        <ux-table-cell class="py-[0.3rem] pl-6 pr-0" width="150">
                            <span class="text-gray-500" t="translation:global.labels.dob"></span>
                        </ux-table-cell>
                        <ux-table-cell class="px-0 py-[0.3rem]" width="75"></ux-table-cell>
                    </ux-table-row>
                </ux-table-header>
            </div>
            <ux-table class="flex flex-1" if.bind="baseLoaded  && authenticated.user && authenticated.workspace">
                <ux-table-body class="flex-1">
                    <ux-table-row class.bind="patients.length === 1 ? 'border-b border-gray-200' : ''" id="row-${$index}" repeat.for="patient of patients">
                        <ux-table-cell>
                            <div class="flex items-center min-w-0">
                                <ux-avatar size="xs" name.bind="patient.displayName" color.bind="patient.avatar.color" shade.bind="patient.avatar.shade" email.bind="patient.emails[0]?.value"></ux-avatar>
                                <div class="flex flex-col min-w-0 ml-2">
                                    <div class="flex flex-col min-w-0 leading-tight">
                                        <a click.trigger="openCard(patient)" class="underline truncate gap-x-1" href="javascript:">
                                            ${patient.displayName}
                                        </a>
                                    </div>
                                    <div class="flex items-center">
                                        <span class="block text-xs leading-tight text-gray-400">${ patient.number }</span>
                                    </div>
                                </div>
                            </div>
                        </ux-table-cell>
                        <ux-table-cell width="200"> ${patient.createdAt | date:'yyyy-MM-dd HH:mm'} </ux-table-cell>
                        <ux-table-cell width="200"> ${patient.headPractitioner.name} </ux-table-cell>
                        <ux-table-cell width="150"> ${patient.dateOfBirth | date} </ux-table-cell>
                        <ux-table-cell width="75">
                            <ux-dropdown
                                if.bind="hasRole(UserRoles.WritePatients) || hasRole(UserRoles.DeletePatients)"
                                transparent.bind="true"
                                border.bind="false"
                                type="full"
                                color="dark"
                                size="sm"
                                padding.bind="false"
                                mode="outline"
                                placement="bottom-right">
                                <div au-slot="label" class="p-1 text-gray-600 rounded-full ring-gray-400 focus:outline-none focus:ring-1">
                                    <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512">
                                        <path
                                            fill="currentColor"
                                            d="M64 368C90.51 368 112 389.5 112 416C112 442.5 90.51 464 64 464C37.49 464 16 442.5 16 416C16 389.5 37.49 368 64 368zM64 208C90.51 208 112 229.5 112 256C112 282.5 90.51 304 64 304C37.49 304 16 282.5 16 256C16 229.5 37.49 208 64 208zM64 144C37.49 144 16 122.5 16 96C16 69.49 37.49 48 64 48C90.51 48 112 69.49 112 96C112 122.5 90.51 144 64 144z" />
                                    </svg>
                                </div>
                                <ux-dropdown-option if.bind="hasRole(UserRoles.WritePatients)" click.trigger="edit(patient)">
                                    <span t="global.buttons.edit"></span>
                                </ux-dropdown-option>
                                <ux-dropdown-divider if.bind="hasRole(UserRoles.WritePatients) && hasRole(UserRoles.DeletePatients)"></ux-dropdown-divider>
                                <ux-dropdown-option if.bind="hasRole(UserRoles.DeletePatients)" click.trigger="delete(patient)">
                                    <span class="text-sm text-red-500" t="global.buttons.delete"></span>
                                </ux-dropdown-option>
                            </ux-dropdown>
                        </ux-table-cell>
                    </ux-table-row>
                    <ux-table-row lock="false" style="height: 100%" if.bind="!noResults && baseLoaded && patients.length === 0">
                        <div class="flex flex-col items-center justify-center flex-1 h-full">
                            <span class="text-sm" t="translation:global.labels.no-results"></span>
                        </div>
                    </ux-table-row>
                    <ux-table-row lock="false" if.bind="noResults">
                        <ux-table-cell>
                            <div class="flex flex-col items-center flex-1 py-12">
                                <div class="flex justify-center">
                                    <img class="h-32" src="/static/svg/empty.svg" />
                                </div>
                                <span class="block mt-3" t="translation:partial-views.patients.messages.no-patients-found"></span>
                                <small t="translation:partial-views.patients.messages.lets-change-that"></small>
                                <ux-button size="sm" mode="outline" class="mt-3" color="info">
                                    <span class="text-sm" t="translation:partial-views.patients.buttons.add-patient"></span>
                                </ux-button>
                            </div>
                        </ux-table-cell>
                    </ux-table-row>
                </ux-table-body>
            </ux-table>
            <ux-table else>
                <ux-table-body>
                    <ux-table-row repeat.for="i of 10">
                        <ux-table-cell>
                            <ux-skeleton show-background.bind="false" type="container">
                                <div class="flex flex-col w-full px-1 space-y-1">
                                    <ux-skeleton type="line"></ux-skeleton>
                                    <ux-skeleton type="line" padding="pr-2"></ux-skeleton>
                                </div>
                            </ux-skeleton>
                        </ux-table-cell>
                        <ux-table-cell width="300">
                            <ux-skeleton show-background.bind="false" type="container">
                                <div class="flex flex-col w-full px-1 space-y-1">
                                    <ux-skeleton type="line"></ux-skeleton>
                                    <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                                </div>
                            </ux-skeleton>
                        </ux-table-cell>
                        <ux-table-cell width="150">
                            <ux-skeleton show-background.bind="false" type="container">
                                <div class="flex flex-col w-full px-1 space-y-1">
                                    <ux-skeleton type="line"></ux-skeleton>
                                    <ux-skeleton type="line" padding="pr-2"></ux-skeleton>
                                </div>
                            </ux-skeleton>
                        </ux-table-cell>
                        <ux-table-cell width="150">
                            <ux-skeleton show-background.bind="false" type="container">
                                <div class="flex flex-col w-full px-1 space-y-1">
                                    <ux-skeleton type="line"></ux-skeleton>
                                    <ux-skeleton type="line" padding="pr-6"></ux-skeleton>
                                </div>
                            </ux-skeleton>
                        </ux-table-cell>
                        <ux-table-cell width="75"></ux-table-cell>
                    </ux-table-row>
                </ux-table-body>
            </ux-table>
            <div class="sticky bottom-0 z-10 bg-white">
                <ux-card-footer>
                    <div class="flex items-start justify-between flex-1 px-4 py-3 gap-x-2">
                        <div class="flex flex-wrap flex-1 gap-2">
                            <div class="w-[300px] min-w-[300px] max-w-[300px]">
                                <ux-input
                                    component.ref="searchInput"
                                    debounce="450"
                                    allow-clear="true"
                                    oninput.trigger="handleSearch($event)"
                                    onclear.trigger="handleSearch($event)"
                                    t="[placeholder]translation:global.placeholders.search">
                                </ux-input>
                            </div>
                            <ux-dropdown transparent.bind="true" padding.bind="false" type="full" color="dark" size="sm" mode="outline" placement="top-left">
                                <div
                                    au-slot="label"
                                    class="rot flex h-[38px] flex-nowrap items-center gap-1 whitespace-nowrap rounded-full p-1 px-3 text-gray-600 ring-gray-400 focus:outline-none focus:ring-1">
                                    <p t="translation:views.starter.placeholders.select-a-filter"></p>

                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="relative size-3">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="m11.99 7.5 3.75-3.75m0 0 3.75 3.75m-3.75-3.75v16.499H4.49" />
                                    </svg>
                                </div>
                                <ux-dropdown-option click.trigger="addFilter('email')">
                                    <span t="global.labels.email"></span>
                                </ux-dropdown-option>
                                <ux-dropdown-option click.trigger="addFilter('phone')">
                                    <span t="global.labels.phone-number"></span>
                                </ux-dropdown-option>
                                <ux-dropdown-option click.trigger="addFilter('street')">
                                    <span t="global.labels.street"></span>
                                </ux-dropdown-option>
                                <ux-dropdown-option click.trigger="addFilter('houseNumber')">
                                    <span t="global.labels.house-number"></span>
                                </ux-dropdown-option>
                                <ux-dropdown-option click.trigger="addFilter('zipCode')">
                                    <span t="global.labels.zip-code"></span>
                                </ux-dropdown-option>
                                <ux-dropdown-option click.trigger="addFilter('city')">
                                    <span t="global.labels.city"></span>
                                </ux-dropdown-option>
                            </ux-dropdown>
                            <div repeat.for="filter of filters" class="flex items-center px-3 py-2 text-sm border rounded-md bg-gray-50">
                                <div class="flex lowercase flex-nowrap whitespace-nowrap">
                                    <p if.bind="filter.name === 'email'" t="global.labels.email-short"></p>
                                    <p if.bind="filter.name === 'phone'" t="global.labels.phone"></p>
                                    <p if.bind="filter.name === 'street'" t="global.labels.street"></p>
                                    <p if.bind="filter.name === 'houseNumber'" t="global.labels.house-number"></p>
                                    <p if.bind="filter.name === 'zipCode'" t="global.labels.zip-code"></p>
                                    <p if.bind="filter.name === 'city'" t="global.labels.city"></p>
                                    <span>:</span>
                                </div>
                                <input
                                    data-1p-ignore="true"
                                    input.trigger="handleFilterValueChanged($event)"
                                    value.bind="filter.value"
                                    ref="filterInputs[$index]"
                                    autofocus
                                    size="6"
                                    class="mr-1 placeholder-gray-400 lowercase appearance-none leading-tigh bg-gray-50 text-primary focus:shadow-none focus:outline-none"
                                    t="[placeholder]translation:views.starter.placeholders.add-value" />
                                <button click.trigger="removeFilter($index)" class="rounded-full ring-red-500 active:ring-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="text-red-500 size-4" viewBox="0 0 20 20" fill="currentColor">
                                        <path
                                            fill-rule="evenodd"
                                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                            clip-rule="evenodd" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div class="flex justify-end pl-5">
                            <div class="flex h-[38px] items-center gap-1 text-sm">
                                <p class="font-medium">${patients.length}</p>
                                <p class="text-gray-700" t="translation:global.labels.from"></p>
                                <p class="font-medium">${total}</p>
                            </div>
                        </div>
                    </div>
                </ux-card-footer>
            </div>
        </div>
    </section>
</template>
