<div class="flex flex-col px-6 py-3 rounded-lg bg-gray-50">
    <div class="flex flex-col divide-y-1">
        <div class="flex items-start justify-between w-full h-full py-3">
            <div class="flex flex-col flex-1 h-full pr-3">
                <h6 class="text-sm font-bold leading-tight">Admin rechten</h6>
                <ul class="pl-4 mt-1 text-xs text-gray-500 list-disc">
                    <li t="translation:components.manage-rights.messages.rights.admin.workspace"></li>
                    <li t="translation:components.manage-rights.messages.rights.admin.user"></li>
                    <li t="translation:components.manage-rights.messages.rights.admin.master-data"></li>
                    <li t="translation:components.manage-rights.messages.rights.admin.access"></li>
                </ul>
            </div>
            <ux-toggle model.bind="UserRoles.Admin" checked.two-way="roles" color="success"></ux-toggle>
        </div>
        <template if.bind="roles.every((r) => r !== UserRoles.Admin)">
            <div class="py-3">
                <p class="mb-1 text-sm font-bold" t="translation:components.manage-rights.labels.roles"></p>
                <p class="mb-2 text-xs text-gray-500" t="translation:components.manage-rights.messages.roles"></p>
                <div class="flex flex-col gap-2">
                    <div class="flex items-center w-full justify-content">
                        <div class="flex items-center justify-between w-full">
                            <h6 class="text-sm leading-tight underline" t="translation:global.labels.practitioner"></h6>
                            <ux-toggle checked.bind="isPractitioner" data="practitioner" onchange.trigger="onGroupToggle($event)" color="success"></ux-toggle>
                        </div>
                    </div>
                    <div class="flex items-center w-full justify-content">
                        <div class="flex items-center justify-between w-full">
                            <h6 class="text-sm leading-tight underline" t="translation:components.manage-rights.labels.front-office"></h6>
                            <ux-toggle checked.bind="isFrontOffice" data="frontoffice" onchange.trigger="onGroupToggle($event)" color="success"></ux-toggle>
                        </div>
                    </div>
                    <div class="flex items-center w-full justify-content">
                        <div class="flex items-center justify-between w-full">
                            <h6 class="text-sm leading-tight underline" t="translation:components.manage-rights.labels.finance"></h6>
                            <ux-toggle checked.bind="isFinance" data="finance" onchange.trigger="onGroupToggle($event)" color="success"></ux-toggle>
                        </div>
                    </div>
                </div>
            </div>
            <div class="py-3">
                <p class="mb-2 text-sm font-bold" t="translation:components.manage-rights.labels.explanation-rights"></p>
                <div class="flex items-center w-full justify-content">
                    <div class="flex flex-col flex-1 pr-5">
                        <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.read-rights"></h6>
                        <ul class="pl-4 mt-1 text-xs text-gray-500 list-disc">
                            <li t="translation:components.manage-rights.messages.rights.read"></li>
                        </ul>
                    </div>
                </div>
                <div class="flex items-center justify-between w-full mt-3">
                    <div class="flex flex-col flex-1 pr-5">
                        <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.write-rights"></h6>
                        <ul class="pl-4 mt-1 text-xs text-gray-500 list-disc">
                            <li t="translation:components.manage-rights.messages.rights.write-1"></li>
                            <li t="translation:components.manage-rights.messages.rights.write-2"></li>
                        </ul>
                    </div>
                </div>
                <div class="flex items-center justify-between w-full my-3">
                    <div class="flex flex-col flex-1 pr-5">
                        <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.delete-rights"></h6>
                        <ul class="pl-4 mt-1 text-xs text-gray-500 list-disc">
                            <li t="translation:components.manage-rights.messages.rights.delete"></li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- Anatomical Regions -->
            <div class="py-3">
                <let hasanatomicalregions.bind="roles.some((r) => r === UserRoles.ReadAnatomicalRegions || r === UserRoles.WriteAnatomicalRegions || r === UserRoles.DeleteAnatomicalRegions)"></let>
                <a href="javascript:" click.trigger="toggle('anatomical-regions')" text-primary.class="rights === 'anatomical-regions' " class="flex justify-between font-semibold hover:text-primary">
                    <span class="text-xs" t="translation:components.manage-rights.labels.rights-anatomical-regions"></span>
                    <div class="flex items-center gap-2">
                        <div if.bind="hasanatomicalregions" class="size-1.5 rounded-sm bg-green-500"></div>
                        <svg if.bind="rights === 'anatomical-regions'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h14" />
                        </svg>
                        <svg else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                        </svg>
                    </div>
                </a>
                <div show.bind="rights === 'anatomical-regions'">
                    <p class="mt-1 text-xs text-gray-500" t="translation:components.manage-rights.messages.rights.anatomical-regions"></p>
                    <div class="mt-2">
                        <div class="flex items-center justify-between w-full">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.read-rights"></h6>
                            <ux-toggle disabled.bind="isPractitioner" model.bind="UserRoles.ReadAnatomicalRegions" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                        <div class="flex items-center justify-between w-full mt-2">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.write-rights"></h6>
                            <ux-toggle model.bind="UserRoles.WriteAnatomicalRegions" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                        <div class="flex items-center justify-between w-full mt-2">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.delete-rights"></h6>
                            <ux-toggle model.bind="UserRoles.DeleteAnatomicalRegions" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Scheduler Items -->
            <div class="py-3">
                <let hasscheduleritems.bind="roles.some((r) => r === UserRoles.ReadSchedulerItems || r === UserRoles.WriteSchedulerItems || r === UserRoles.DeleteSchedulerItems)"></let>
                <a href="javascript:" click.trigger="toggle('scheduler-items')" text-primary.class="rights === 'scheduler-items' " class="flex justify-between font-semibold hover:text-primary">
                    <span class="text-xs" t="translation:components.manage-rights.labels.rights-appointments"></span>
                    <div class="flex items-center gap-2">
                        <div if.bind="hasscheduleritems" class="size-1.5 rounded-sm bg-green-500"></div>
                        <svg if.bind="rights === 'scheduler-items'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h14" />
                        </svg>
                        <svg else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                        </svg>
                    </div>
                </a>
                <div show.bind="rights === 'scheduler-items'">
                    <p class="mt-1 text-xs text-gray-500" t="translation:components.manage-rights.messages.rights.scheduler-items"></p>
                    <div class="mt-2">
                        <div class="flex items-center justify-between w-full">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.read-rights"></h6>
                            <ux-toggle disabled.bind="isPractitioner || isFrontOffice" model.bind="UserRoles.ReadSchedulerItems" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                        <div class="flex items-center justify-between w-full mt-2">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.write-rights"></h6>
                            <ux-toggle disabled.bind="isPractitioner || isFrontOffice" model.bind="UserRoles.WriteSchedulerItems" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                        <div class="flex items-center justify-between w-full mt-2">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.delete-rights"></h6>
                            <ux-toggle disabled.bind="isPractitioner || isFrontOffice" model.bind="UserRoles.DeleteSchedulerItems" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Appointment Types -->
            <div class="py-3">
                <let hasSchedulerItemTypes.bind="roles.some((r) => r === UserRoles.ReadAppointmentTypes || r === UserRoles.WriteAppointmentTypes || r === UserRoles.DeleteAppointmentTypes)"></let>
                <a href="javascript:" click.trigger="toggle('appointment-types')" text-primary.class="rights === 'appointment-types'" class="flex justify-between font-semibold hover:text-primary">
                    <span class="text-xs" t="translation:components.manage-rights.labels.rights-appointment-types"></span>
                    <div class="flex items-center gap-2">
                        <div if.bind="hasappointmenttypes" class="size-1.5 rounded-sm bg-green-500"></div>
                        <svg if.bind="rights === 'appointment-types'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h14" />
                        </svg>
                        <svg else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                        </svg>
                    </div>
                </a>
                <div show.bind="rights === 'appointment-types'">
                    <p class="mt-1 text-xs text-gray-500" t="translation:components.manage-rights.messages.rights.appointment-types"></p>
                    <div class="mt-2">
                        <div class="flex items-center justify-between w-full">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.read-rights"></h6>
                            <ux-toggle disabled.bind="isPractitioner || isFrontOffice" model.bind="UserRoles.ReadAppointmentTypes" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                        <div class="flex items-center justify-between w-full mt-2">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.write-rights"></h6>
                            <ux-toggle model.bind="UserRoles.WriteAppointmentTypes" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                        <div class="flex items-center justify-between w-full mt-2">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.delete-rights"></h6>
                            <ux-toggle model.bind="UserRoles.DeleteAppointmentTypes" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Declarations -->
            <div class="py-3">
                <let hasdeclarations.bind="roles.some((r) => r === UserRoles.ReadDeclarations || r === UserRoles.WriteDeclarations || r === UserRoles.DeleteDeclarations)"></let>
                <a href="javascript:" click.trigger="toggle('declarations')" text-primary.class="rights === 'declarations'" class="flex justify-between font-semibold hover:text-primary">
                    <span class="text-xs" t="translation:components.manage-rights.labels.rights-declarations"></span>
                    <div class="flex items-center gap-2">
                        <div if.bind="hasdeclarations" class="size-1.5 rounded-sm bg-green-500"></div>
                        <svg if.bind="rights === 'declarations'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h14" />
                        </svg>
                        <svg else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                        </svg>
                    </div>
                </a>
                <div show.bind="rights === 'declarations'">
                    <p class="mt-1 text-xs text-gray-500" t="translation:components.manage-rights.messages.rights.declarations"></p>
                    <div class="mt-2">
                        <div class="flex items-center justify-between w-full">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.read-rights"></h6>
                            <ux-toggle disabled.bind="isFinance" model.bind="UserRoles.ReadDeclarations" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                        <div class="flex items-center justify-between w-full mt-2">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.write-rights"></h6>
                            <ux-toggle disabled.bind="isFinance" model.bind="UserRoles.WriteDeclarations" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                        <div class="flex items-center justify-between w-full mt-2">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.delete-rights"></h6>
                            <ux-toggle disabled.bind="isFinance" model.bind="UserRoles.DeleteDeclarations" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Declaration Performances -->
            <div class="py-3">
                <let
                    hasdeclarationperformances.bind="roles.some((r) => r === UserRoles.ReadDeclarationPerformances || r === UserRoles.WriteDeclarationPerformances || r === UserRoles.DeleteDeclarationPerformances)"></let>
                <a
                    href="javascript:"
                    click.trigger="toggle('declaration-performances')"
                    text-primary.class="rights === 'declaration-performances' "
                    class="flex justify-between font-semibold hover:text-primary">
                    <span class="text-xs" t="translation:components.manage-rights.labels.rights-performances"></span>
                    <div class="flex items-center gap-2">
                        <div if.bind="hasdeclarationperformances" class="size-1.5 rounded-sm bg-green-500"></div>
                        <svg
                            if.bind="rights === 'declaration-performances'"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-4 h-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h14" />
                        </svg>
                        <svg else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                        </svg>
                    </div>
                </a>
                <div show.bind="rights === 'declaration-performances'">
                    <p class="mt-1 text-xs text-gray-500" t="translation:components.manage-rights.messages.rights.declaration-performances"></p>
                    <div class="mt-2">
                        <div class="flex items-center justify-between w-full">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.read-rights"></h6>
                            <ux-toggle disabled.bind="isPractitioner || isFinance" model.bind="UserRoles.ReadDeclarationPerformances" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                        <div class="flex items-center justify-between w-full mt-2">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.write-rights"></h6>
                            <ux-toggle disabled.bind="isPractitioner || isFinance" model.bind="UserRoles.WriteDeclarationPerformances" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                        <div class="flex items-center justify-between w-full mt-2">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.delete-rights"></h6>
                            <ux-toggle disabled.bind="isFinance" model.bind="UserRoles.DeleteDeclarationPerformances" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Differential Diagnoses -->
            <div class="py-3">
                <let
                    hasdifferentialdiagnoses.bind="roles.some((r) => r === UserRoles.ReadDifferentialDiagnoses || r === UserRoles.WriteDifferentialDiagnoses || r === UserRoles.DeleteDifferentialDiagnoses)"></let>
                <a
                    href="javascript:"
                    click.trigger="toggle('differential-diagnoses')"
                    text-primary.class="rights === 'differential-diagnoses'"
                    class="flex justify-between font-semibold hover:text-primary">
                    <span class="text-xs" t="translation:components.manage-rights.labels.rights-differential-diagnoses"></span>
                    <div class="flex items-center gap-2">
                        <div if.bind="hasdifferentialdiagnoses" class="size-1.5 rounded-sm bg-green-500"></div>
                        <svg if.bind="rights === 'differential-diagnoses'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h14" />
                        </svg>
                        <svg else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                        </svg>
                    </div>
                </a>
                <div show.bind="rights === 'differential-diagnoses'">
                    <p class="mt-1 text-xs text-gray-500" t="translation:components.manage-rights.messages.rights.differential-diagnoses"></p>
                    <div class="mt-2">
                        <div class="flex items-center justify-between w-full">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.read-rights"></h6>
                            <ux-toggle disabled.bind="isPractitioner" model.bind="UserRoles.ReadDifferentialDiagnoses" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                        <div class="flex items-center justify-between w-full mt-2">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.write-rights"></h6>
                            <ux-toggle model.bind="UserRoles.WriteDifferentialDiagnoses" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                        <div class="flex items-center justify-between w-full mt-2">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.delete-rights"></h6>
                            <ux-toggle model.bind="UserRoles.DeleteDifferentialDiagnoses" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Email Templates -->
            <div class="py-3">
                <let hasemailtemplates.bind="roles.some((r) => r === UserRoles.ReadEmailTemplates || r === UserRoles.WriteEmailTemplates || r === UserRoles.DeleteEmailTemplates)"></let>
                <a href="javascript:" click.trigger="toggle('email-templates')" text-primary.class="rights === 'email-templates'" class="flex justify-between font-semibold hover:text-primary">
                    <span class="text-xs" t="translation:components.manage-rights.labels.rights-email-templates"></span>
                    <div class="flex items-center gap-2">
                        <div if.bind="hasemailtemplates" class="size-1.5 rounded-sm bg-green-500"></div>
                        <svg if.bind="rights === 'email-templates'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h14" />
                        </svg>
                        <svg else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                        </svg>
                    </div>
                </a>
                <div show.bind="rights === 'email-templates'">
                    <p class="mt-1 text-xs text-gray-500" t="translation:components.manage-rights.messages.rights.email-templates"></p>
                    <div class="mt-2">
                        <div class="flex items-center justify-between w-full">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.read-rights"></h6>
                            <ux-toggle disabled.bind="isPractitioner || isFrontOffice" model.bind="UserRoles.ReadEmailTemplates" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                        <div class="flex items-center justify-between w-full mt-2">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.write-rights"></h6>
                            <ux-toggle model.bind="UserRoles.WriteEmailTemplates" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                        <div class="flex items-center justify-between w-full mt-2">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.delete-rights"></h6>
                            <ux-toggle model.bind="UserRoles.DeleteEmailTemplates" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Healthcare Invoices -->
            <div class="py-3">
                <let
                    hashealthcareinvoices.bind="roles.some((r) => r === UserRoles.ReadHealthcareInvoices || r === UserRoles.WriteHealthcareInvoices || r === UserRoles.DeleteHealthcareInvoices)"></let>
                <a href="javascript:" click.trigger="toggle('healthcare-invoices')" text-primary.class="rights === 'healthcare-invoices'" class="flex justify-between font-semibold hover:text-primary">
                    <span class="text-xs" t="translation:components.manage-rights.labels.rights-healthcare-invoices"></span>
                    <div class="flex items-center gap-2">
                        <div if.bind="hashealthcareinvoices" class="size-1.5 rounded-sm bg-green-500"></div>
                        <svg if.bind="rights === 'healthcare-invoices'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h14" />
                        </svg>
                        <svg else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                        </svg>
                    </div>
                </a>
                <div show.bind="rights === 'healthcare-invoices'">
                    <p class="mt-1 text-xs text-gray-500" t="translation:components.manage-rights.messages.rights.healthcare-invoices"></p>
                    <div class="mt-2">
                        <div class="flex items-center justify-between w-full">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.read-rights"></h6>
                            <ux-toggle disabled.bind="isPractitioner || isFinance" model.bind="UserRoles.ReadHealthcareInvoices" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                        <div class="flex items-center justify-between w-full mt-2">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.write-rights"></h6>
                            <ux-toggle disabled.bind="isPractitioner || isFinance" model.bind="UserRoles.WriteHealthcareInvoices" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                        <div class="flex items-center justify-between w-full mt-2">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.delete-rights"></h6>
                            <ux-toggle disabled.bind="isPractitioner ||isFinance" model.bind="UserRoles.DeleteHealthcareInvoices" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Healthcare Tasks -->
            <div class="py-3">
                <let hashealthcaretasks.bind="roles.some((r) => r === UserRoles.ReadHealthcareTasks || r === UserRoles.WriteHealthcareTasks || r === UserRoles.DeleteHealthcareTasks)"></let>
                <a href="javascript:" click.trigger="toggle('healthcare-tasks')" text-primary.class="rights === 'healthcare-tasks'" class="flex justify-between font-semibold hover:text-primary">
                    <span class="text-xs" t="translation:components.manage-rights.labels.rights-healthcare-tasks"></span>
                    <div class="flex items-center gap-2">
                        <div if.bind="hashealthcaretasks" class="size-1.5 rounded-sm bg-green-500"></div>
                        <svg if.bind="rights === 'healthcare-tasks'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h14" />
                        </svg>
                        <svg else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                        </svg>
                    </div>
                </a>
                <div show.bind="rights === 'healthcare-tasks'">
                    <p class="mt-1 text-xs text-gray-500" t="translation:components.manage-rights.messages.rights.healthcare-tasks"></p>
                    <div class="mt-2">
                        <div class="flex items-center justify-between w-full">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.read-rights"></h6>
                            <ux-toggle disabled.bind="isPractitioner || isFrontOffice || isFinance" model.bind="UserRoles.ReadHealthcareTasks" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                        <div class="flex items-center justify-between w-full mt-2">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.write-rights"></h6>
                            <ux-toggle disabled.bind="isPractitioner || isFrontOffice || isFinance" model.bind="UserRoles.WriteHealthcareTasks" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                        <div class="flex items-center justify-between w-full mt-2">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.delete-rights"></h6>
                            <ux-toggle disabled.bind="isPractitioner || isFrontOffice || isFinance" model.bind="UserRoles.DeleteHealthcareTasks" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Healthcare Task Lists -->
            <div class="py-3">
                <let
                    hashealthcaretaskllists.bind="roles.some((r) => r === UserRoles.ReadHealthcareTaskLists || r === UserRoles.WriteHealthcareTaskLists || r === UserRoles.DeleteHealthcareTaskLists)"></let>
                <a
                    href="javascript:"
                    click.trigger="toggle('healthcare-task-lists')"
                    text-primary.class="rights === 'healthcare-task-lists' "
                    class="flex justify-between font-semibold hover:text-primary">
                    <span class="text-xs" t="translation:components.manage-rights.labels.rights-healthcare-task-lists"></span>
                    <div class="flex items-center gap-2">
                        <div if.bind="hashealthcaretaskllists" class="size-1.5 rounded-sm bg-green-500"></div>
                        <svg if.bind="rights === 'healthcare-task-lists'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h14" />
                        </svg>
                        <svg else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                        </svg>
                    </div>
                </a>
                <div show.bind="rights === 'healthcare-task-lists'">
                    <p class="mt-1 text-xs text-gray-500" t="translation:components.manage-rights.messages.rights.healthcare-task-lists"></p>
                    <div class="mt-2">
                        <div class="flex items-center justify-between w-full">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.read-rights"></h6>
                            <ux-toggle disabled.bind="isPractitioner || isFrontOffice || isFinance" model.bind="UserRoles.ReadHealthcareTaskLists" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                        <div class="flex items-center justify-between w-full mt-2">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.write-rights"></h6>
                            <ux-toggle model.bind="UserRoles.WriteHealthcareTaskLists" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                        <div class="flex items-center justify-between w-full mt-2">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.delete-rights"></h6>
                            <ux-toggle model.bind="UserRoles.DeleteHealthcareTaskLists" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Healthcare Task Labels -->
            <div class="py-3">
                <let
                    hashealthcaretasklabels.bind="roles.some((r) => r === UserRoles.ReadHealthcareTaskLabels || r === UserRoles.WriteHealthcareTaskLabels || r === UserRoles.DeleteHealthcareTaskLabels)"></let>
                <a
                    href="javascript:"
                    click.trigger="toggle('healthcare-task-labels')"
                    text-primary.class="rights === 'healthcare-task-labels'"
                    class="flex justify-between font-semibold hover:text-primary">
                    <span class="text-xs" t="translation:components.manage-rights.labels.rights-healthcare-task-labels"></span>
                    <div class="flex items-center gap-2">
                        <div if.bind="hashealthcaretasklabels" class="size-1.5 rounded-sm bg-green-500"></div>
                        <svg if.bind="rights === 'healthcare-task-labels'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h14" />
                        </svg>
                        <svg else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                        </svg>
                    </div>
                </a>
                <div show.bind="rights === 'healthcare-task-labels'">
                    <p class="mt-1 text-xs text-gray-500" t="translation:components.manage-rights.messages.rights.healthcare-task-labels"></p>
                    <div class="mt-2">
                        <div class="flex items-center justify-between w-full">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.read-rights"></h6>
                            <ux-toggle disabled.bind="isPractitioner || isFrontOffice || isFinance" model.bind="UserRoles.ReadHealthcareTaskLabels" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                        <div class="flex items-center justify-between w-full mt-2">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.write-rights"></h6>
                            <ux-toggle model.bind="UserRoles.WriteHealthcareTaskLabels" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                        <div class="flex items-center justify-between w-full mt-2">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.delete-rights"></h6>
                            <ux-toggle model.bind="UserRoles.DeleteHealthcareTaskLabels" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Insurers -->
            <div class="py-3">
                <let hasinsurers.bind="roles.some((r) => r === UserRoles.ReadInsurers || r === UserRoles.WriteInsurers || r === UserRoles.DeleteInsurers)"></let>
                <a href="javascript:" click.trigger="toggle('insurers')" text-primary.class="rights === 'insurers' " class="flex justify-between font-semibold hover:text-primary">
                    <span class="text-xs" t="translation:components.manage-rights.labels.rights-insurers"></span>
                    <div class="flex items-center gap-2">
                        <div if.bind="hasinsurers" class="size-1.5 rounded-sm bg-green-500"></div>
                        <svg if.bind="rights === 'insurers'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h14" />
                        </svg>
                        <svg else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                        </svg>
                    </div>
                </a>
                <div show.bind="rights === 'insurers'">
                    <p class="mt-1 text-xs text-gray-500" t="translation:components.manage-rights.messages.rights.insurers"></p>
                    <div class="mt-2">
                        <div class="flex items-center justify-between w-full">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.read-rights"></h6>
                            <ux-toggle disabled.bind="isPractitioner || isFrontOffice || isFinance" model.bind="UserRoles.ReadInsurers" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                        <div class="flex items-center justify-between w-full mt-2">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.write-rights"></h6>
                            <ux-toggle model.bind="UserRoles.WriteInsurers" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                        <div class="flex items-center justify-between w-full mt-2">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.delete-rights"></h6>
                            <ux-toggle model.bind="UserRoles.DeleteInsurers" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Medical Examinations -->
            <div class="py-3">
                <let
                    hasmedicalexaminations.bind="roles.some((r) => r === UserRoles.ReadMedicalExaminations || r === UserRoles.WriteMedicalExaminations || r === UserRoles.DeleteMedicalExaminations)"></let>
                <a
                    href="javascript:"
                    click.trigger="toggle('medical-examinations')"
                    text-primary.class="rights === 'medical-examinations'"
                    class="flex justify-between font-semibold hover:text-primary">
                    <span class="text-xs" t="translation:components.manage-rights.labels.rights-medical-examinations"></span>
                    <div class="flex items-center gap-2">
                        <div if.bind="hasmedicalexaminations" class="size-1.5 rounded-sm bg-green-500"></div>
                        <svg if.bind="rights === 'medical-examinations'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h14" />
                        </svg>
                        <svg else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                        </svg>
                    </div>
                </a>
                <div show.bind="rights === 'medical-examinations'">
                    <p class="mt-1 text-xs text-gray-500" t="translation:components.manage-rights.messages.rights.medical-examinations"></p>
                    <div class="mt-2">
                        <div class="flex items-center justify-between w-full">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.read-rights"></h6>
                            <ux-toggle disabled.bind="isPractitioner" model.bind="UserRoles.ReadMedicalExaminations" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                        <div class="flex items-center justify-between w-full mt-2">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.write-rights"></h6>
                            <ux-toggle model.bind="UserRoles.WriteMedicalExaminations" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                        <div class="flex items-center justify-between w-full mt-2">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.delete-rights"></h6>
                            <ux-toggle model.bind="UserRoles.DeleteMedicalExaminations" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Medical Examination Actions -->
            <div class="py-3">
                <let
                    hasmedicalexaminationactions.bind="roles.some((r) => r === UserRoles.ReadMedicalExaminationActions || r === UserRoles.WriteMedicalExaminationActions || r === UserRoles.DeleteMedicalExaminationActions)"></let>
                <a
                    href="javascript:"
                    click.trigger="toggle('medical-examination-actions')"
                    text-primary.class="rights === 'medical-examination-actions'"
                    class="flex justify-between font-semibold hover:text-primary">
                    <span class="text-xs" t="translation:components.manage-rights.labels.rights-medical-examination-actions"></span>
                    <div class="flex items-center gap-2">
                        <div if.bind="hasmedicalexaminationactions" class="size-1.5 rounded-sm bg-green-500"></div>
                        <svg
                            if.bind="rights === 'medical-examination-actions'"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-4 h-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h14" />
                        </svg>
                        <svg else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                        </svg>
                    </div>
                </a>
                <div show.bind="rights === 'medical-examination-actions'">
                    <p class="mt-1 text-xs text-gray-500" t="translation:components.manage-rights.messages.rights.medical-examination-actions"></p>
                    <div class="mt-2">
                        <div class="flex items-center justify-between w-full">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.read-rights"></h6>
                            <ux-toggle disabled.bind="isPractitioner" model.bind="UserRoles.ReadMedicalExaminationActions" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                        <div class="flex items-center justify-between w-full mt-2">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.write-rights"></h6>
                            <ux-toggle model.bind="UserRoles.WriteMedicalExaminationActions" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                        <div class="flex items-center justify-between w-full mt-2">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.delete-rights"></h6>
                            <ux-toggle model.bind="UserRoles.DeleteMedicalExaminationActions" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Medical Examination Models -->
            <div class="py-3">
                <let
                    hasmedicalexaminationmodels.bind="roles.some((r) => r === UserRoles.ReadMedicalExaminationModels || r === UserRoles.WriteMedicalExaminationModels || r === UserRoles.DeleteMedicalExaminationModels)"></let>
                <a
                    href="javascript:"
                    click.trigger="toggle('medical-examination-models')"
                    text-primary.class="rights === 'medical-examination-models'"
                    class="flex justify-between font-semibold hover:text-primary">
                    <span class="text-xs" t="translation:components.manage-rights.labels.rights-medical-examination-models"></span>
                    <div class="flex items-center gap-2">
                        <div if.bind="hasmedicalexaminationmodels" class="size-1.5 rounded-sm bg-green-500"></div>
                        <svg
                            if.bind="rights === 'medical-examination-models'"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-4 h-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h14" />
                        </svg>
                        <svg else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                        </svg>
                    </div>
                </a>
                <div show.bind="rights === 'medical-examination-models'">
                    <p class="mt-1 text-xs text-gray-500" t="translation:components.manage-rights.messages.rights.medical-examination-models"></p>
                    <div class="mt-2">
                        <div class="flex items-center justify-between w-full">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.read-rights"></h6>
                            <ux-toggle disabled.bind="isPractitioner" model.bind="UserRoles.ReadMedicalExaminationModels" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                        <div class="flex items-center justify-between w-full mt-2">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.write-rights"></h6>
                            <ux-toggle model.bind="UserRoles.WriteMedicalExaminationModels" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                        <div class="flex items-center justify-between w-full mt-2">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.delete-rights"></h6>
                            <ux-toggle model.bind="UserRoles.DeleteMedicalExaminationModels" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Medical Examination Phases -->
            <div class="py-3">
                <let
                    hasmedicalexaminationphases.bind="roles.some((r) => r === UserRoles.ReadMedicalExaminationPhases || r === UserRoles.WriteMedicalExaminationPhases || r === UserRoles.DeleteMedicalExaminationPhases)"></let>
                <a
                    href="javascript:"
                    click.trigger="toggle('medical-examination-phases')"
                    text-primary.class="rights === 'medical-examination-phases'"
                    class="flex justify-between font-semibold hover:text-primary">
                    <span class="text-xs" t="translation:components.manage-rights.labels.rights-medical-examination-phases"></span>
                    <div class="flex items-center gap-2">
                        <div if.bind="hasmedicalexaminationphases" class="size-1.5 rounded-sm bg-green-500"></div>
                        <svg
                            if.bind="rights === 'medical-examination-phases'"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-4 h-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h14" />
                        </svg>
                        <svg else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                        </svg>
                    </div>
                </a>
                <div show.bind="rights === 'medical-examination-phases'">
                    <p class="mt-1 text-xs text-gray-500" t="translation:components.manage-rights.messages.rights.medical-examination-phases"></p>
                    <div class="mt-2">
                        <div class="flex items-center justify-between w-full">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.read-rights"></h6>
                            <ux-toggle disabled.bind="isPractitioner" model.bind="UserRoles.ReadMedicalExaminationPhases" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                        <div class="flex items-center justify-between w-full mt-2">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.write-rights"></h6>
                            <ux-toggle model.bind="UserRoles.WriteMedicalExaminationPhases" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                        <div class="flex items-center justify-between w-full mt-2">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.delete-rights"></h6>
                            <ux-toggle model.bind="UserRoles.DeleteMedicalExaminationPhases" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Medical Questions -->
            <div class="py-3">
                <let hasmedicalquestions.bind="roles.some((r) => r === UserRoles.ReadMedicalQuestions || r === UserRoles.WriteMedicalQuestions || r === UserRoles.DeleteMedicalQuestions)"></let>
                <a href="javascript:" click.trigger="toggle('medical-questions')" text-primary.class="rights === 'medical-questions'" class="flex justify-between font-semibold hover:text-primary">
                    <span class="text-xs" t="translation:components.manage-rights.labels.rights-medical-examination-questions"></span>
                    <div class="flex items-center gap-2">
                        <div if.bind="hasmedicalquestions" class="size-1.5 rounded-sm bg-green-500"></div>
                        <svg if.bind="rights === 'medical-questions'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h14" />
                        </svg>
                        <svg else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                        </svg>
                    </div>
                </a>
                <div show.bind="rights === 'medical-questions'">
                    <p class="mt-1 text-xs text-gray-500" t="translation:components.manage-rights.messages.rights.medical-questions"></p>
                    <div class="mt-2">
                        <div class="flex items-center justify-between w-full">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.read-rights"></h6>
                            <ux-toggle disabled.bind="isPractitioner" model.bind="UserRoles.ReadMedicalQuestions" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                        <div class="flex items-center justify-between w-full mt-2">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.write-rights"></h6>
                            <ux-toggle model.bind="UserRoles.WriteMedicalQuestions" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                        <div class="flex items-center justify-between w-full mt-2">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.delete-rights"></h6>
                            <ux-toggle model.bind="UserRoles.DeleteMedicalQuestions" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Medical Questionnaires -->
            <div class="py-3">
                <let
                    hasmedicalquestionnaires.bind="roles.some((r) => r === UserRoles.ReadMedicalQuestionnaires || r === UserRoles.WriteMedicalQuestionnaires || r === UserRoles.DeleteMedicalQuestionnaires)"></let>
                <a
                    href="javascript:"
                    click.trigger="toggle('medical-questionnaires')"
                    text-primary.class="rights === 'medical-questionnaires'"
                    class="flex justify-between font-semibold hover:text-primary">
                    <span class="text-xs" t="translation:components.manage-rights.labels.rights-medical-examination-questionnaires"></span>
                    <div class="flex items-center gap-2">
                        <div if.bind="hasmedicalquestionnaires" class="size-1.5 rounded-sm bg-green-500"></div>
                        <svg if.bind="rights === 'medical-questionnaires'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h14" />
                        </svg>
                        <svg else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                        </svg>
                    </div>
                </a>
                <div show.bind="rights === 'medical-questionnaires'">
                    <p class="mt-1 text-xs text-gray-500" t="translation:components.manage-rights.messages.rights.medical-questionnaires"></p>
                    <div class="mt-2">
                        <div class="flex items-center justify-between w-full">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.read-rights"></h6>
                            <ux-toggle disabled.bind="isPractitioner" model.bind="UserRoles.ReadMedicalQuestionnaires" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                        <div class="flex items-center justify-between w-full mt-2">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.write-rights"></h6>
                            <ux-toggle model.bind="UserRoles.WriteMedicalQuestionnaires" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                        <div class="flex items-center justify-between w-full mt-2">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.delete-rights"></h6>
                            <ux-toggle model.bind="UserRoles.DeleteMedicalQuestionnaires" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Medical Records -->
            <div class="py-3">
                <let hasmedicalrecords.bind="roles.some((r) => r === UserRoles.WriteMedicalRecords || r === UserRoles.WriteMedicalRecords || r === UserRoles.DeleteMedicalRecords)"></let>
                <a href="javascript:" click.trigger="toggle('medical-records')" text-primary.class="rights === 'medical-records'" class="flex justify-between font-semibold hover:text-primary">
                    <span class="text-xs" t="translation:components.manage-rights.labels.rights-medical-records"></span>
                    <div class="flex items-center gap-2">
                        <div if.bind="hasmedicalrecords" class="size-1.5 rounded-sm bg-green-500"></div>
                        <svg if.bind="rights === 'medical-records'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h14" />
                        </svg>
                        <svg else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                        </svg>
                    </div>
                </a>
                <div show.bind="rights === 'medical-records'">
                    <p class="mt-1 text-xs text-gray-500" t="translation:components.manage-rights.messages.rights.medical-records"></p>
                    <div class="mt-2">
                        <div class="flex items-center justify-between w-full">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.read-rights"></h6>
                            <ux-toggle disabled.bind="isPractitioner" model.bind="UserRoles.ReadMedicalRecords" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                        <div class="flex items-center justify-between w-full mt-2">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.write-rights"></h6>
                            <ux-toggle disabled.bind="isPractitioner" model.bind="UserRoles.WriteMedicalRecords" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                        <div class="flex items-center justify-between w-full mt-2">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.delete-rights"></h6>
                            <ux-toggle disabled.bind="isPractitioner" model.bind="UserRoles.DeleteMedicalRecords" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Medical Therapies -->
            <div class="py-3">
                <let hasmedicaltherapies.bind="roles.some((r) => r === UserRoles.ReadMedicalTherapies || r === UserRoles.WriteMedicalTherapies || r === UserRoles.DeleteMedicalTherapies)"></let>
                <a href="javascript:" click.trigger="toggle('medical-therapies')" text-primary.class="rights === 'medical-therapies'" class="flex justify-between font-semibold hover:text-primary">
                    <span class="text-xs" t="translation:components.manage-rights.labels.rights-medical-therapies"></span>
                    <div class="flex items-center gap-2">
                        <div if.bind="hasmedicaltherapies" class="size-1.5 rounded-sm bg-green-500"></div>
                        <svg if.bind="rights === 'medical-therapies'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h14" />
                        </svg>
                        <svg else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                        </svg>
                    </div>
                </a>
                <div show.bind="rights === 'medical-therapies'">
                    <p class="mt-1 text-xs text-gray-500" t="translation:components.manage-rights.messages.rights.medical-therapies"></p>
                    <div class="mt-2">
                        <div class="flex items-center justify-between w-full">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.read-rights"></h6>
                            <ux-toggle disabled.bind="isPractitioner" model.bind="UserRoles.ReadMedicalTherapies" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                        <div class="flex items-center justify-between w-full mt-2">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.write-rights"></h6>
                            <ux-toggle model.bind="UserRoles.WriteMedicalTherapies" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                        <div class="flex items-center justify-between w-full mt-2">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.delete-rights"></h6>
                            <ux-toggle model.bind="UserRoles.DeleteMedicalTherapies" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Medical Treatment Protocols -->
            <div class="py-3">
                <let
                    hasmedicaltreatmentprotocols.bind="roles.some((r) => r === UserRoles.ReadMedicalTreatmentProtocols || r === UserRoles.WriteMedicalTreatmentProtocols || r === UserRoles.DeleteMedicalTreatmentProtocols)"></let>
                <a
                    href="javascript:"
                    click.trigger="toggle('medical-treatment-protocols')"
                    text-primary.class="rights === 'medical-treatment-protocols' "
                    class="flex justify-between font-semibold hover:text-primary">
                    <span class="text-xs" t="translation:components.manage-rights.labels.rights-medical-treatment-protocols"></span>
                    <div class="flex items-center gap-2">
                        <div if.bind="hasmedicaltreatmentprotocols" class="size-1.5 rounded-sm bg-green-500"></div>
                        <svg
                            if.bind="rights === 'medical-treatment-protocols'"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-4 h-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h14" />
                        </svg>
                        <svg else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                        </svg>
                    </div>
                </a>
                <div show.bind="rights === 'medical-treatment-protocols'">
                    <p class="mt-1 text-xs text-gray-500" t="translation:components.manage-rights.messages.rights.medical-treatment-protocols"></p>
                    <div class="mt-2">
                        <div class="flex items-center justify-between w-full">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.read-rights"></h6>
                            <ux-toggle disabled.bind="isPractitioner" model.bind="UserRoles.ReadMedicalTreatmentProtocols" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                        <div class="flex items-center justify-between w-full mt-2">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.write-rights"></h6>
                            <ux-toggle model.bind="UserRoles.WriteMedicalTreatmentProtocols" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                        <div class="flex items-center justify-between w-full mt-2">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.delete-rights"></h6>
                            <ux-toggle model.bind="UserRoles.DeleteMedicalTreatmentProtocols" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Patients -->
            <div class="py-3">
                <let haspatients.bind="roles.some((r) => r === UserRoles.ReadPatients || r === UserRoles.WritePatients || r === UserRoles.DeletePatients)"></let>
                <a href="javascript:" click.trigger="toggle('patients')" text-primary.class="rights === 'patients' " class="flex justify-between font-semibold hover:text-primary">
                    <span class="text-xs" t="translation:components.manage-rights.labels.rights-patients"></span>
                    <div class="flex items-center gap-2">
                        <div if.bind="haspatients" class="size-1.5 rounded-sm bg-green-500"></div>
                        <svg if.bind="rights === 'patients'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h14" />
                        </svg>
                        <svg else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                        </svg>
                    </div>
                </a>
                <div show.bind="rights === 'patients'">
                    <p class="mt-1 text-xs text-gray-500" t="translation:components.manage-rights.messages.rights.patients"></p>
                    <div class="mt-2">
                        <div class="flex items-center justify-between w-full">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.read-rights"></h6>
                            <ux-toggle disabled.bind="isPractitioner || isFrontOffice || isFinance" model.bind="UserRoles.ReadPatients" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                        <div class="flex items-center justify-between w-full mt-2">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.write-rights"></h6>
                            <ux-toggle disabled.bind="isPractitioner || isFrontOffice" model.bind="UserRoles.WritePatients" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                        <div class="flex items-center justify-between w-full mt-2">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.delete-rights"></h6>
                            <ux-toggle disabled.bind="isPractitioner || isFrontOffice" model.bind="UserRoles.DeletePatients" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Practice Locations -->
            <div class="py-3">
                <let haspracticelocations.bind="roles.some((r) => r === UserRoles.ReadPracticeLocations || r === UserRoles.WritePracticeLocations || r === UserRoles.DeletePracticeLocations)"></let>
                <a href="javascript:" click.trigger="toggle('practice-locations')" text-primary.class="rights === 'practice-locations' " class="flex justify-between font-semibold hover:text-primary">
                    <span class="text-xs" t="translation:components.manage-rights.labels.rights-practice-locations"></span>
                    <div class="flex items-center gap-2">
                        <div if.bind="haspracticelocations" class="size-1.5 rounded-sm bg-green-500"></div>
                        <svg if.bind="rights === 'practice-locations'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h14" />
                        </svg>
                        <svg else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                        </svg>
                    </div>
                </a>
                <div show.bind="rights === 'practice-locations'">
                    <p class="mt-1 text-xs text-gray-500" t="translation:components.manage-rights.messages.rights.practice-locations"></p>
                    <div class="mt-2">
                        <div class="flex items-center justify-between w-full">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.read-rights"></h6>
                            <ux-toggle disabled.bind="isPractitioner || isFrontOffice || isFinance" model.bind="UserRoles.ReadPracticeLocations" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                        <div class="flex items-center justify-between w-full mt-2">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.write-rights"></h6>
                            <ux-toggle model.bind="UserRoles.WritePracticeLocations" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                        <div class="flex items-center justify-between w-full mt-2">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.delete-rights"></h6>
                            <ux-toggle model.bind="UserRoles.DeletePracticeLocations" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Schedules -->
            <div class="py-3">
                <let hasschedules.bind="roles.some((r) => r === UserRoles.ReadSchedules || r === UserRoles.WriteSchedules || r === UserRoles.DeleteSchedules)"></let>
                <a href="javascript:" click.trigger="toggle('schedules')" text-primary.class="rights === 'schedules'" class="flex justify-between font-semibold hover:text-primary">
                    <span class="text-xs" t="translation:components.manage-rights.labels.rights-schedules"></span>
                    <div class="flex items-center gap-2">
                        <div if.bind="hasschedules" class="size-1.5 rounded-sm bg-green-500"></div>
                        <svg if.bind="rights === 'schedules'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h14" />
                        </svg>
                        <svg else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                        </svg>
                    </div>
                </a>
                <div show.bind="rights === 'schedules'">
                    <p class="mt-1 text-xs text-gray-500" t="translation:components.manage-rights.messages.rights.schedules"></p>
                    <div class="mt-2">
                        <div class="flex items-center justify-between w-full">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.read-rights"></h6>
                            <ux-toggle disabled.bind="isPractitioner || isFrontOffice || isFinance" model.bind="UserRoles.ReadSchedules" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                        <div class="flex items-center justify-between w-full mt-2">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.write-rights"></h6>
                            <ux-toggle model.bind="UserRoles.WriteSchedules" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                        <div class="flex items-center justify-between w-full mt-2">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.delete-rights"></h6>
                            <ux-toggle model.bind="UserRoles.DeleteSchedules" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Vat Categories -->
            <div class="py-3">
                <let hasvat.bind="roles.some((r) => r === UserRoles.WriteVatCategories || r === UserRoles.DeleteVatCategories)"></let>
                <a href="javascript:" click.trigger="toggle('vat-categories')" text-primary.class="rights === 'vat-categories' " class="flex justify-between font-semibold hover:text-primary">
                    <span class="text-xs" t="translation:components.manage-rights.labels.rights-vat-categories"></span>
                    <div class="flex items-center gap-2">
                        <div if.bind="hasvat" class="size-1.5 rounded-sm bg-green-500"></div>
                        <svg if.bind="rights === 'vat-categories'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h14" />
                        </svg>
                        <svg else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                        </svg>
                    </div>
                </a>
                <div show.bind="rights === 'vat-categories'">
                    <p class="mt-1 text-xs text-gray-500" t="translation:components.manage-rights.messages.rights.vat-categories"></p>
                    <div class="mt-2">
                        <div class="flex items-center justify-between w-full mt-2">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.write-rights"></h6>
                            <ux-toggle model.bind="UserRoles.WriteVatCategories" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                        <div class="flex items-center justify-between w-full mt-2">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.delete-rights"></h6>
                            <ux-toggle model.bind="UserRoles.DeleteVatCategories" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Accounts -->
            <div class="py-3">
                <let hasaccounts.bind="roles.some((r) => r === UserRoles.ReadAccounts || r === UserRoles.WriteAccounts || r === UserRoles.DeleteAccounts)"></let>
                <a href="javascript:" click.trigger="toggle('accounts')" text-primary.class="rights === 'accounts'" class="flex justify-between font-semibold hover:text-primary">
                    <span class="text-xs" t="translation:components.manage-rights.labels.rights-accounts"></span>
                    <div class="flex items-center gap-2">
                        <div if.bind="hasaccounts" class="size-1.5 rounded-sm bg-green-500"></div>
                        <svg if.bind="rights === 'accounts'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h14" />
                        </svg>
                        <svg else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                        </svg>
                    </div>
                </a>
                <div show.bind="rights === 'accounts'">
                    <p class="mt-1 text-xs text-gray-500" t="translation:components.manage-rights.messages.rights.accounts"></p>
                    <div class="mt-2">
                        <div class="flex items-center justify-between w-full">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.read-rights"></h6>
                            <ux-toggle disabled.bind="isPractitioner || isFrontOffice || isFinance" model.bind="UserRoles.ReadAccounts" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                        <div class="flex items-center justify-between w-full mt-2">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.write-rights"></h6>
                            <ux-toggle model.bind="UserRoles.WriteAccounts" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                        <div class="flex items-center justify-between w-full mt-2">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.delete-rights"></h6>
                            <ux-toggle model.bind="UserRoles.DeleteAccounts" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Contacts -->
            <div class="py-3">
                <let hascontacts.bind="roles.some((r) => r === UserRoles.ReadContacts || r === UserRoles.WriteContacts || r === UserRoles.DeleteContacts)"></let>
                <a href="javascript:" click.trigger="toggle('contacts')" text-primary.class="rights === 'contacts'" class="flex justify-between font-semibold hover:text-primary">
                    <span class="text-xs" t="translation:components.manage-rights.labels.rights-contacts"></span>
                    <div class="flex items-center gap-2">
                        <div if.bind="hascontacts" class="size-1.5 rounded-sm bg-green-500"></div>
                        <svg if.bind="rights === 'contacts'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h14" />
                        </svg>
                        <svg else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                        </svg>
                    </div>
                </a>
                <div show.bind="rights === 'contacts'">
                    <p class="mt-1 text-xs text-gray-500" t="translation:components.manage-rights.messages.rights.contacts"></p>
                    <div class="mt-2">
                        <div class="flex items-center justify-between w-full">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.read-rights"></h6>
                            <ux-toggle disabled.bind="isPractitioner || isFrontOffice || isFinance" model.bind="UserRoles.ReadContacts" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                        <div class="flex items-center justify-between w-full mt-2">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.write-rights"></h6>
                            <ux-toggle model.bind="UserRoles.WriteContacts" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                        <div class="flex items-center justify-between w-full mt-2">
                            <h6 class="text-sm font-light leading-tight" t="translation:components.manage-rights.labels.delete-rights"></h6>
                            <ux-toggle model.bind="UserRoles.DeleteContacts" checked.bind="roles" color="success"></ux-toggle>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</div>
