import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import {
    BlobStorageAttachment,
    CalculateInvoiceTotalsRequest,
    CreditHealthcareInvoiceRequest,
    DeclarationPerformanceStatuses,
    DeclarationPerformancesApiClient,
    GetDeclarationPerformanceResponse,
    GetHealthcareInvoiceProductResponse,
    GetHealthcareInvoiceResponse,
    GetPatientResponse,
    HealthcareCode,
    HealthcareCodeTypes,
    HealthcareInvoiceCharge,
    HealthcareInvoiceLine,
    HealthcareInvoiceStatuses,
    HealthcareInvoiceTypes,
    HealthcareInvoicesApiClient,
    PatientsApiClient,
    PrepareInvoiceLineRequest
} from '@wecore/sdk-healthcare';
import { guid, isDefined, isEmpty, isNotDefined, isNotEmpty } from '@wecore/sdk-utilities';

import { AttachmentEntities } from '@wecore/sdk-attachments';
import { ActivitiesApiClient, GetActivityResponse } from '@wecore/sdk-core';
import { IEventAggregator, inject } from 'aurelia';
import { isAfter, isEqual, startOfDay } from 'date-fns';
import { PartialViewResults } from '../../../enums/partial-view-results';
import { BasePartialView } from '../../../infra/base-partial-view';
import { CacheService } from '../../../infra/cache-service';
import { ErrorHandler } from '../../../infra/error-handler';
import { CustomEvents } from '../../../infra/events';
import { PartialViews } from '../../../infra/partial-views';
import { State } from '../../../infra/store/state';
import { cloneDeep } from '../../../infra/utilities';
import { ConfirmationOptions } from '../../../models/confirmation-options';
import { EventDetails } from '../../../models/event-details';
import { PartialView } from '../../../models/partial-view';
import { ViewOptions } from '../../../models/view-options';
import { ModalService } from '../../../services/service.modals';
import { UxCheckbox } from '../../../ux/ux-checkbox/ux-checkbox';
import { UxInput } from '../../../ux/ux-input/ux-input';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, HealthcareInvoicesApiClient, ModalService, DeclarationPerformancesApiClient, PatientsApiClient, ActivitiesApiClient)
export class PartialHealthcareInvoicesEdit extends BasePartialView {
    public invoice: GetHealthcareInvoiceResponse;
    public HealthcareInvoiceStatuses: typeof HealthcareInvoiceStatuses = HealthcareInvoiceStatuses;
    public HealthcareCodeTypes: typeof HealthcareCodeTypes = HealthcareCodeTypes;
    public HealthcareInvoiceTypes: typeof HealthcareInvoiceTypes = HealthcareInvoiceTypes;
    public validation: any = {
        valid: true,
        missingGroupId: true,
        lines: []
    };
    public selection: (HealthcareInvoiceLine | HealthcareInvoiceCharge)[] = [];
    public selectedAll: boolean = false;
    public performances: GetDeclarationPerformanceResponse[];
    public patient: GetPatientResponse;
    public groups: any = {};
    public activities: GetActivityResponse[];
    public collapsed: boolean = true;

    public states: any = {};

    private invoiceId: string;

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly invoicesApi: HealthcareInvoicesApiClient,
        private readonly modalService: ModalService,
        private readonly performancesApi: DeclarationPerformancesApiClient,
        private readonly patientsApi: PatientsApiClient,
        private readonly activitiesApi: ActivitiesApiClient
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.invoiceId = view.data.id;
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                this.invoice = await this.invoicesApi.getById(this.invoiceId, this.authenticated.workspace.id);

                const [patient] = await Promise.all([
                    this.patientsApi.getById(this.invoice.patient.id, this.authenticated.workspace.id), //
                    this.getDeclarationPerformances(),
                    this.getActivities()
                ]);
                this.patient = patient;

                for (const _ of this.invoice.lines) {
                    this.validation.lines.push({
                        product: true,
                        quantity: true,
                        price: true,
                        vat: true,
                        code: true
                    });
                }

                this.subscriptions = [
                    ...(this.subscriptions ?? []),
                    this.events.subscribe(CustomEvents.HealthcareInvoicesUpdated, async () => {
                        this.invoicesApi.getById(this.invoiceId, this.authenticated.workspace.id).then((invoice) => (this.invoice = invoice));
                        setTimeout(() => this.getActivities(), 250);
                    })
                ];

                this.setGroups();
                this.baseLoaded = true;
            })
            .catch((x) => this.errorHandler.handle('PartialHealthcareInvoicesEdit.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }

    public async delete(): Promise<void> {
        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('translation:partial-views.healthcare-invoices.questions.delete.title'),
                message: this.t
                    .tr('translation:partial-views.healthcare-invoices.questions.delete.message') //
                    .replace('{entity}', `<span>'${this.invoice.trackingNumber ?? this.invoice.conceptNumber}'</span>`),
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        this.deleting = true;
                        try {
                            await this.invoicesApi.delete(this.invoiceId, this.authenticated.workspace.id);
                            this.notifications.show(
                                this.t.tr('translation:partial-views.healthcare-invoices.notifications.deleted-successfully.title'),
                                this.t
                                    .tr('translation:partial-views.healthcare-invoices.notifications.deleted-successfully.message') //
                                    .replace('{entity}', `<span>'${this.invoice.trackingNumber ?? this.invoice.conceptNumber}'</span>`),
                                { type: 'success', duration: 3000 }
                            );
                            setTimeout(async () => this.remove({ result: PartialViewResults.Deleted, updateUrl: true }), 250);
                        } catch (e) {
                            this.deleting = false;
                            await this.errorHandler.handle('[delete-edit-invoice]', e);
                        }
                    }
                }
            })
        );
    }

    public toggleCollapse(): void {
        this.collapsed = !this.collapsed;

        if (this.collapsed) this.setWidth(1200);
        else {
            this.setWidth(1500);
            this.scrollTo(this.partial);
        }
    }

    public async handleQuantityChanged(_: CustomEvent<EventDetails<UxInput, any>>): Promise<void> {
        this.calculateTotals();
    }

    public async handlePriceChanged(_: CustomEvent<EventDetails<UxInput, any>>): Promise<void> {
        this.calculateTotals();
    }

    public async handleVatChanged(e: CustomEvent<EventDetails<UxInput, any>>): Promise<void> {
        const index = e.detail.data;

        if (isNotDefined(this.invoice.lines[index].productPrice.vat.percentage) || Number(this.invoice.lines[index].productPrice.vat.percentage) < 0)
            this.invoice.lines[index].productPrice.vat.percentage = 0;

        this.calculateTotals();
    }

    public getLineTotal(line: HealthcareInvoiceLine): number {
        if (isNotDefined(line.productPrice)) return 0;
        return line.productPrice.netValue * line.quantity;
    }

    public handleProductSelected = async (p: GetHealthcareInvoiceProductResponse, index: number): Promise<void> => {
        const line = await this.invoicesApi.prepareInvoiceLine(
            this.authenticated.workspace.id,
            new PrepareInvoiceLineRequest({
                productId: p.id,
                productType: p.type
            })
        );
        this.invoice.lines[index] = line;

        this.invoice.lines = [
            ...(this.invoice.lines.length > 0 ? [this.invoice.lines.shift()] : []), //
            ...cloneDeep(this.invoice.lines)
        ];

        this.calculateTotals();
        this.setGroups();
    };

    public async save(validateGroupIds: boolean = true): Promise<void> {
        const valid = this.validate(validateGroupIds);
        if (valid) {
            this.isLoading = true;
            try {
                await this.invoicesApi.update(this.invoiceId, this.authenticated.workspace.id, this.invoice);
                if (validateGroupIds)
                    this.notifications.show(
                        this.t.tr('translation:partial-views.healthcare-invoices.notifications.saved-successfully.title'),
                        this.t.tr('translation:partial-views.healthcare-invoices.notifications.saved-successfully.message'),
                        { type: 'success', duration: 3000 }
                    );
            } catch (e) {
                this.errorHandler.handle('[edit-invoice]', e);
            }
            setTimeout(() => (this.isLoading = false), 250);
        }
    }

    public async markAsPaid(): Promise<void> {
        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('translation:partial-views.healthcare-invoices.questions.mark-as-paid.title'),
                message: this.t.tr('translation:partial-views.healthcare-invoices.questions.mark-as-paid.message'),
                btnOk: this.t.tr('translation:global.buttons.mark'),
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        this.isLoading = true;
                        try {
                            const response = await this.invoicesApi.markAsPaid(this.invoiceId, this.authenticated.workspace.id);
                            this.invoice = response;
                            this.isLoading = false;
                            this.events.publish(CustomEvents.HealthcareInvoicesUpdated, response);
                        } catch (e) {
                            this.isLoading = false;
                            await this.errorHandler.handle('[invoices-mark-as-paid]', e);
                        }
                    }
                }
            })
        );
    }

    public close(): void {
        super.remove({
            result: PartialViewResults.Ok
        });
    }

    public addLine(): void {
        this.validation.lines.push({
            product: true,
            quantity: true,
            price: true,
            vat: true,
            code: true,
            groupId: true
        });
        this.invoice.lines.push(
            new HealthcareInvoiceLine({
                id: guid(),
                additionalCharges: [],
                isAutoGenerated: false,
                codes: []
            })
        );

        this.calculateTotals();
    }

    public async addCode(index: number): Promise<void> {
        await this.removeChildViews();
        this.addPartialView({
            view: this.partial.base,
            partial: PartialViews.HealthcareCodes.with({ language: this.language }).whenClosed(async (result: PartialViewResults, data: { code: HealthcareCode }) => {
                if (result === PartialViewResults.Ok) {
                    this.invoice.lines[index].codes = [data.code];
                    const line = await this.invoicesApi.prepareInvoiceLine(
                        this.authenticated.workspace.id,
                        new PrepareInvoiceLineRequest({
                            line: this.invoice.lines[index]
                        })
                    );
                    this.invoice.lines[index] = line;

                    this.invoice.lines = [
                        ...(this.invoice.lines.length > 0 ? [this.invoice.lines.shift()] : []), //
                        ...cloneDeep(this.invoice.lines)
                    ];
                }
            }),
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                markItem: true,
                replace: true
            })
        });
    }

    public deleteLine(index: number): void {
        const line = this.invoice.lines[index];

        this.invoice.lines.splice(index, 1);
        this.validation.lines.splice(index, 1);
        this.selection = this.selection.filter((x) => x.id !== line.id);
        this.calculateTotals();

        // Clear al related group IDs
        for (const relatedLine of this.invoice.lines.filter(
            // Filter to only the lines that have the same group ID and have underlying codes.
            (x) => x.groupId === line.groupId && x.codes.some((y) => y.type === HealthcareCodeTypes.Underlying)
        )) {
            relatedLine.groupId = null;
            this.selection = this.selection.filter((x) => x.id !== relatedLine.id);
        }

        this.setGroups();

        this.invoice.lines = [
            ...(this.invoice.lines.length > 0 ? [this.invoice.lines.shift()] : []), //
            ...cloneDeep(this.invoice.lines)
        ];
    }

    public deleteCharge(parent: number, index: number): void {
        this.invoice.lines[parent].additionalCharges.splice(index, 1);
        this.calculateTotals();
    }

    public removeCode(index: number): void {
        this.invoice.lines[index].codes = [];
        this.invoice.lines[index].groupId = null;
    }

    public handleSelectAll(e: CustomEvent<EventDetails<UxCheckbox, any>>): void {
        const checked = e.detail.values.checked;
        if (checked) {
            const selection: HealthcareInvoiceLine[] | HealthcareInvoiceCharge[] = [];
            for (const line of this.invoice.lines) {
                // Check if the line can be selected.
                if (this.canSelect(line, null)) selection.push(line);
                // Check if the charges can be selected.
                for (const charge of line.additionalCharges) {
                    if (this.canSelect(line, charge)) selection.push(charge);
                }
            }
            this.selection = cloneDeep(selection);
        } else this.selection = [];
    }

    public handleItemChecked(e: CustomEvent<EventDetails<UxCheckbox, any>>): void {
        // Rules:
        // 1. Charges within in a invoice line with the same code should be selected together.
        // 2. If the line has the same code as one of the charges, the line should be (de)selected together with the charges
        // that have the same code.
        // 3. Invoice lines with the same group ID should be (de)selected together.
        // Remember the checkItems() function is only to check if any OTHER items should be (de)selected.
        // The item that is checked/unchecked by the user is handled by Aurelia itself.

        const isChecked = e.detail.values.value;
        let selection = cloneDeep(this.selection);

        const selectedLineOrCharge = e.detail.values.model as HealthcareInvoiceLine | HealthcareInvoiceCharge;
        this.selection = this.checkOtherItems(selectedLineOrCharge, isChecked, selection);

        // Check if we should (de)select the 'Select all' checkbox.
        if (
            // The amount of additional charges should be equal to the amount of selected charges and invoices.
            this.selection.length ===
            this.invoice.lines
                // Filter out the lines that are allowed to be selected.
                .filter((line) => this.canSelect(line, null)).length +
                this.invoice.lines
                    // Map the charges to a single line/charge combination array,
                    .map(this.getCharges)
                    // Flatten the arrays to a single array.
                    .selectMany((x: { line: HealthcareInvoiceLine; charge: HealthcareInvoiceCharge }[]) => x)
                    // Filter out the charges that are allowed to be selected.
                    .filter((x) => this.canSelect(x.line, x.charge)).length
        )
            this.selectedAll = true;
        else this.selectedAll = false;
    }

    public async declare(): Promise<void> {
        await this.addPartialView({
            view: this.partial.base, //
            partial: PartialViews.CreateDeclarationPerformances.with({ patient: this.invoice.patient.id, invoice: this.invoice.id, selection: this.selection }) //
                .whenClosed(async (result: PartialViewResults) => {
                    if (result === PartialViewResults.Ok) {
                        this.selection = [];
                        this.selectedAll = false;

                        const [invoice] = await Promise.all([
                            this.invoicesApi.getById(this.invoice.id, this.authenticated.workspace.id), //
                            this.getDeclarationPerformances()
                        ]);
                        this.invoice = invoice;
                    }
                }),
            options: new ViewOptions({ index: this.partial.index + 1, markItem: true, scrollToView: true, updateUrl: false })
        });
    }

    public async preview(type: 'restition' | 'remaining' | 'credit'): Promise<void> {
        const valid = this.validate();
        if (valid) {
            await this.addPartialView({
                view: this.partial.base, //
                partial: PartialViews.HealthcareInvoicesPreview.with({ type, invoice: this.invoice.id, patient: this.patient.id }).whenClosed(async (result: PartialViewResults) => {
                    if (result === PartialViewResults.Ok || result === PartialViewResults.Cancelled) {
                        this.selection = [];
                        this.selectedAll = false;
                    }
                }),
                options: new ViewOptions({ index: this.partial.index + 1, markItem: true, scrollToView: true, updateUrl: false })
            });
        }
    }

    public async linkLine(index: number): Promise<void> {
        const line = this.invoice.lines[index];
        const validateItem = (item: HealthcareInvoiceLine | HealthcareInvoiceCharge) =>
            isDefined(item.codes) &&
            // The line should have a VEKTIS code.
            item.codes.some((x) => x.system === 'Vektis') &&
            // The VEKTIS code should be independent.
            item.codes.find((x) => x.system === 'Vektis').type === HealthcareCodeTypes.Independent &&
            // It should have group ID
            isDefined(item.groupId);

        const startOfItemToGroup = startOfDay(line.productDate);

        // Get all lines and charges that have not been declared.
        const lines = this.invoice.lines
            // The line should not match the current line.
            .filter((x) => x.id !== line.id)
            // The product date of the itemToGroup should be the same or greater than the current line.
            // We can't link the itemToGroup with a line that has a product date before the itemToGroup.
            .filter((x) => {
                const startOfCurrentItem = startOfDay(x.productDate);
                return isEqual(startOfItemToGroup, startOfCurrentItem) || isAfter(startOfItemToGroup, startOfCurrentItem);
            })
            .filter(validateItem);

        const choices = [
            ...lines.map((x) => ({
                label: x.description,
                groupId: x.groupId
            }))
        ];

        await this.addPartialView({
            view: this.partial.base, //
            partial: PartialViews.HealthcareInvoicesLineSelection.with({ choices }).whenClosed(async (result: PartialViewResults, groupId: string) => {
                if (result === PartialViewResults.Ok) {
                    this.invoice.lines[index].groupId = groupId;
                    this.save(false);
                    this.selection = [];
                    this.setGroups();

                    this.invoice.lines = [
                        ...(this.invoice.lines.length > 0 ? [this.invoice.lines.shift()] : []), //
                        ...cloneDeep(this.invoice.lines)
                    ];
                }
            }),
            options: new ViewOptions({ index: this.partial.index + 1, markItem: true, scrollToView: true, updateUrl: false })
        });
    }

    public async showDocument(attachment: BlobStorageAttachment): Promise<void> {
        await this.addPartialView({
            view: this.partial.base, //
            partial: PartialViews.DocumentsPreview.with({
                entityId: this.invoice.id,
                entityType: AttachmentEntities.HealthcareInvoices,
                attachmentId: attachment.id,
                attachmentName: attachment.name,
                attachmentExtension: attachment.extension
            }),
            options: new ViewOptions({ index: this.partial.index + 1, markItem: true, scrollToView: true, updateUrl: false })
        });
    }

    public async credit(): Promise<void> {
        if (this.selection.length === 0) return;
        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('translation:partial-views.healthcare-invoices.questions.credit.title'),
                message: this.t.tr('translation:partial-views.healthcare-invoices.questions.credit.message'),
                btnOk: this.t.tr('translation:partial-views.healthcare-invoices.buttons.credit'),
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        this.isLoading = true;
                        try {
                            const response = await this.invoicesApi.credit(
                                this.invoiceId,
                                this.authenticated.workspace.id,
                                new CreditHealthcareInvoiceRequest({
                                    lines: this.selection.map((x: HealthcareInvoiceLine) => x.id)
                                })
                            );
                            setTimeout(async () => this.remove({ result: PartialViewResults.Ok, updateUrl: true, data: response.id }), 0);
                        } catch (e) {
                            this.isLoading = false;
                            await this.errorHandler.handle('[credit-edit-invoice]', e);
                        }
                    }
                }
            })
        );
    }

    public canSelect(line: HealthcareInvoiceLine, charge: HealthcareInvoiceCharge): boolean {
        // DEBIT rules:
        // The line/charge can only be selected if the invoice has status 'Draft', 'PartiallyDeclared', 'PartiallyCompensated' or 'Compensated'.

        // When the invoice has status 'Draft' or 'PartiallyDeclared', this means the line or charge
        // can be declared. The following rules apply:
        // 1. The line or charge MUST have a 'VEKTIS' code.
        // 2. The line or charge can NOT have a performance.
        // 3. The line or charge must have a group ID.

        // When the invoice has status 'PartiallyCompensated' or 'Compensated', this means that the line
        // or charge can be credited. The following rules apply:
        // 1. The line or charge MUST have a 'VEKTIS' code.
        // 2. The line or charge MUST have a performance with status 'Compensated'.
        // 3. The line or charge must have a group ID.

        // CREDIT rules:
        // The line/charge can only be selected if the invoice has status 'Draft' or 'PartiallyDeclared'.
        // 1. The line or charge should have a VEKTIS code (which is always true because the code is copied from the original line/charge).
        // 2. The line or charge can NOT have a performance.
        // 3. The line or charge must have a group ID.

        // Find the performance for the line or charge.
        let performance: GetDeclarationPerformanceResponse;
        if (isNotDefined(charge))
            performance = performance = this.performances.find(
                // Note: This comparison is also done in the view for the <let lineperformance /> binding.
                // If you change this comparison, also change the comparison in the view.
                (x) =>
                    // A line performance is matched when the performance has the same invoice line ID and
                    // no additional charge ID and no merges.
                    (x.invoiceLineId === line.id && isNotDefined(x.additionalChargeId) && x.merges.length === 0) ||
                    // Or the invoice line is part of a merge which can only consist of 2 or more lines.
                    (x.merges.length > 1 && x.merges.includes(line.id))
            );
        else performance = this.performances.find((x) => (x.invoiceLineId === line.id && x.additionalChargeId === charge.id) || x.merges.includes(charge.id));

        if (this.invoice.type === HealthcareInvoiceTypes.Debit) {
            // Check invoice status.
            if (
                this.invoice.status !== HealthcareInvoiceStatuses.Draft &&
                this.invoice.status !== HealthcareInvoiceStatuses.PartiallyDeclared &&
                this.invoice.status !== HealthcareInvoiceStatuses.PartiallyCompensated &&
                this.invoice.status !== HealthcareInvoiceStatuses.Compensated
            )
                return false;
            // Check performance and code based on the invoice status.
            if (this.invoice.status === HealthcareInvoiceStatuses.Draft || this.invoice.status === HealthcareInvoiceStatuses.PartiallyDeclared) {
                return isDefined(charge)
                    ? isNotDefined(performance) && isDefined(charge.codes) && charge.codes.some((x: HealthcareCode) => x.system === 'Vektis') && isDefined(charge.groupId)
                    : isNotDefined(performance) && isDefined(line.codes) && line.codes.some((x: HealthcareCode) => x.system === 'Vektis') && isDefined(line.groupId);
            } else if (this.invoice.status === HealthcareInvoiceStatuses.PartiallyCompensated || this.invoice.status === HealthcareInvoiceStatuses.Compensated) {
                return isDefined(charge)
                    ? isDefined(performance) &&
                          performance.status == DeclarationPerformanceStatuses.Accepted &&
                          isDefined(charge.codes) &&
                          charge.codes.some((x: HealthcareCode) => x.system === 'Vektis') &&
                          isDefined(charge.groupId)
                    : isDefined(performance) &&
                          performance.status == DeclarationPerformanceStatuses.Accepted &&
                          isDefined(line.codes) &&
                          line.codes.some((x: HealthcareCode) => x.system === 'Vektis') &&
                          isDefined(line.groupId);
            }
        }

        if (this.invoice.type === HealthcareInvoiceTypes.Credit) {
            // Check invoice status.
            if (this.invoice.status !== HealthcareInvoiceStatuses.Draft && this.invoice.status !== HealthcareInvoiceStatuses.PartiallyDeclared) return false;
            return isDefined(charge)
                ? isNotDefined(performance) && isDefined(charge.codes) && charge.codes.some((x: HealthcareCode) => x.system === 'Vektis') && isDefined(charge.groupId)
                : isNotDefined(performance) && isDefined(line.codes) && line.codes.some((x: HealthcareCode) => x.system === 'Vektis') && isDefined(line.groupId);
        }

        // If none of the checks pass, return false.
        return false;
    }

    public findMergedItem(id: string): HealthcareInvoiceLine | HealthcareInvoiceCharge {
        return [
            ...this.invoice.lines, //
            ...this.invoice.lines.selectMany((line: HealthcareInvoiceLine) => line.additionalCharges)
        ].find((x: HealthcareInvoiceLine | HealthcareInvoiceCharge) => x.id === id);
    }

    public togglePerformance(id: string) {
        if (isNotDefined(this.states[id])) this.states[id] = { expanded: true };
        else this.states[id].expanded = !this.states[id].expanded;
    }

    private async calculateTotals(): Promise<void> {
        const invalidValue = (value: number): boolean => isNotDefined(value) || isEmpty(value.toString()) || isNaN(value) || isNaN(value);
        // Check if every line has a proper quantity before calculating.
        for (const line of this.invoice.lines) {
            if (invalidValue(line.quantity) || invalidValue(line.productPrice?.netValue) || invalidValue(line.productPrice?.vat.percentage)) return;
            for (const additionalCharge of line.additionalCharges) if (invalidValue(additionalCharge.quantity) || invalidValue(additionalCharge.price.netValue)) return;
        }

        const response = await this.invoicesApi.calculateTotals(
            this.authenticated.workspace.id,
            new CalculateInvoiceTotalsRequest({
                lines: this.invoice.lines
            })
        );

        for (let index = 0; index < this.invoice.lines.length; index++) {
            this.invoice.lines[index].totals = response.lines[index].totals;
            if (this.invoice.lines[index].additionalCharges.any()) {
                for (let cI = 0; cI < this.invoice.lines[index].additionalCharges.length; cI++)
                    this.invoice.lines[index].additionalCharges[cI].totals = response.lines[index].additionalCharges[cI].totals;
            }
        }

        this.invoice.lines = [
            ...(this.invoice.lines.length > 0 ? [this.invoice.lines.shift()] : []), //
            ...cloneDeep(this.invoice.lines)
        ];

        this.invoice.totals = response.totals;
    }

    private async getDeclarationPerformances(): Promise<void> {
        const response = await this.performancesApi.search(this.authenticated.workspace.id, 250, 0, undefined, undefined, undefined, [this.invoice.id]);
        this.performances = response.data;

        this.invoice.lines = [
            ...(this.invoice.lines.length > 0 ? [this.invoice.lines.shift()] : []), //
            ...cloneDeep(this.invoice.lines)
        ];
    }

    private validate(validateGroupIds: boolean = true): boolean {
        for (let index = 0; index < this.invoice.lines.length; index++) {
            const line = this.invoice.lines[index];
            this.validation.lines[index].product = isDefined(line.productId);
            this.validation.lines[index].groupId = validateGroupIds //
                ? line.codes.some((x) => x.type === HealthcareCodeTypes.Underlying)
                    ? isDefined(line.groupId)
                    : true
                : true;
            this.validation.lines[index].quantity = isDefined(line.quantity) && !isNaN(Number(line.quantity));
            this.validation.lines[index].vat = isDefined(line.productPrice.vat.percentage) && !isNaN(Number(line.productPrice.vat.percentage)) && Number(line.productPrice.vat.percentage) >= 0;
            this.validation.lines[index].price = isDefined(line.productPrice) && isDefined(line.productPrice.netValue) && !isNaN(Number(line.productPrice.netValue));
            this.validation.lines[index].code = line.codes.every((x) => isDefined(x.value) && isNotEmpty(x.value.trim()));
        }

        this.validation.missingGroupId = validateGroupIds ? this.validation.lines.every((x: any) => x.groupId) : true;
        this.validation.valid = this.validation.lines.every((x: any) => x.product && x.quantity && x.price && x.vat && x.code) && this.validation.missingGroupId;

        return this.validation.valid;
    }

    private setGroups(): void {
        this.groups = {};
        const validateItem = (item: HealthcareInvoiceLine | HealthcareInvoiceCharge) =>
            isDefined(item.codes) &&
            // The line should have a VEKTIS code.
            item.codes.some((x) => x.system === 'Vektis') &&
            // It should have group ID
            isDefined(item.groupId);

        const lines = this.invoice.lines.filter(validateItem).map((x) => x.groupId);
        const charges = this.invoice.lines
            .selectMany((x: HealthcareInvoiceLine) => x.additionalCharges)
            .filter(validateItem)
            .map((x) => x.groupId);

        const groups = [...lines, ...charges].groupBy((x: string) => x);
        // Filter out groups that have only one item.
        const groupIds = new Map([...groups].filter(([_, value]) => value.length > 1));

        var colors = ['text-green-700', 'text-purple-700', 'text-yellow-700'];
        var index = 0;
        for (const key of groupIds.keys()) {
            this.groups[key] = colors[index];
            index++;
        }
    }

    private getCharges(line: HealthcareInvoiceLine): { line: HealthcareInvoiceLine; charge: HealthcareInvoiceCharge }[] {
        return line.additionalCharges.map((x) => ({ line, charge: x }));
    }

    private async getActivities(): Promise<void> {
        const response = await this.activitiesApi.search(this.authenticated.workspace.id, '', 250, 0, undefined, undefined, undefined, undefined, undefined, undefined, [this.invoice.id]);
        this.activities = response.data;
    }

    private checkOtherItems(
        selectedItem: HealthcareInvoiceLine | HealthcareInvoiceCharge,
        isChecked: boolean,
        selection: (HealthcareInvoiceLine | HealthcareInvoiceCharge)[]
    ): (HealthcareInvoiceLine | HealthcareInvoiceCharge)[] {
        const itemToCheckIsLine = selectedItem instanceof HealthcareInvoiceLine;
        const itemToCheckIsCharge = !itemToCheckIsLine;

        const itemToCheckCode = selectedItem.codes.find((x) => x.system === 'Vektis');

        const items = [
            ...this.invoice.lines.map((line) => ({ value: line, isLine: true })),
            ...this.invoice.lines
                .selectMany((line: HealthcareInvoiceLine) => line.additionalCharges) //
                .map((charge) => ({ value: charge, isLine: false }))
        ];

        for (const item of items) {
            if (item.value.id === selectedItem.id) continue;

            const itemCode = item.value.codes.find((x) => x.system === 'Vektis');
            const isSelected = selection.some((x) => x.id === item.value.id);
            const matchOnGroupId = selectedItem.groupId === item.value.groupId;
            const matchOnCodeValue = isDefined(itemCode) && isDefined(itemToCheckCode) && `${itemCode.list}${itemCode.value}` === `${itemToCheckCode.list}${itemToCheckCode.value}`;
            const itemsAreRelated =
                // Or the itemToCheck has the current item as a charge.
                ((selectedItem as HealthcareInvoiceLine).additionalCharges?.some((x) => x.id === item.value.id) ?? false) ||
                // Or the current item has the itemToCheck as a charge.
                ((item.value as HealthcareInvoiceLine).additionalCharges?.some((x) => x.id === selectedItem.id) ?? false) ||
                // Or when both items are charges and they have the same line as parent
                (!item.isLine &&
                    itemToCheckIsCharge &&
                    this.invoice.lines.find((x) => x.additionalCharges.some((y) => y.id === selectedItem.id))?.id ===
                        this.invoice.lines.find((x) => x.additionalCharges.some((y) => y.id === item.value.id))?.id);

            if (isChecked) {
                // If an item was selected we should check if any other items should be selected.
                const shouldSelect = matchOnGroupId || (matchOnCodeValue && itemsAreRelated);
                if (shouldSelect && !isSelected) {
                    selection.push(item.value);
                    // If we select the current item, it can be so that other items should also be selected,
                    // other items that are related and/or have the same code value. So recursively
                    // call this function again but with the current item as the selected item.
                    selection = this.checkOtherItems(item.value, isChecked, selection);
                }
            } else {
                // If an item is deselected we should check if any other items should be deselected.
                const shouldDeselect = matchOnGroupId || (matchOnCodeValue && itemsAreRelated);
                if (shouldDeselect && isSelected) {
                    selection.splice(
                        selection.findIndex((x) => x.id === item.value.id),
                        1
                    );
                    // If we deselect the current item, it can be so that other items should also be deselect,
                    // other items that are related and/or have the same code value. So recursively
                    // call this function again but with the current item as the deselected item.
                    selection = this.checkOtherItems(item.value, isChecked, selection);
                }
            }
        }

        return selection;
    }
}
