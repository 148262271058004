<import from="converters/translate" />
<import from="converters/date" />

<template class="!flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex flex-col overflow-hidden" css.bind="styles">
        <ux-header>
            <div class="flex items-center justify-between">
                <div class="flex items-center gap-2 px-2">
                    <p class="text-sm font-medium" t="translation:partial-views.patient-appointments.title"></p>
                    <div if.bind="connectorState === 'OnConnected'" class="w-2 h-2 bg-green-500 rounded-full animation-pulse-green"></div>
                    <div if.bind="connectorState === 'OnDisconnected' || state === 'Disconnected'" class="w-2 h-2 bg-red-500 rounded-full animation-pulse-red"></div>
                    <div
                        if.bind="connectorState === 'OnConnecting' || connectorState === 'OnReconnecting' || connectorState === 'OnDisconnecting'"
                        class="w-2 h-2 bg-yellow-500 rounded-full animation-pulse-yellow"></div>
                </div>
                <div class="flex space-x-1">
                    <button class="flex items-center justify-center p-1 bg-gray-100 rounded-full hover:bg-gray-200" click.trigger="close()">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4">
                            <path
                                fill-rule="evenodd"
                                d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z"
                                clip-rule="evenodd" />
                        </svg>
                    </button>
                </div>
            </div>
        </ux-header>
        <div ref="scrollContainer" class="flex flex-col flex-1 h-full pb-6 overflow-y-auto scroll-smooth">
            <template if.bind="baseLoaded">
                <div class="flex flex-col">
                    <div repeat.for="appointment of appointments" class="flex items-center justify-between w-full min-w-0 gap-5 py-3 pl-6 pr-4 text-sm border-b border-gray-200">
                        <div class="flex flex-1 h-full min-w-0 gap-3">
                            <div class="relative flex h-full">
                                <div
                                    if.bind="appointment.status === SchedulerItemStatuses.NoShow"
                                    class="absolute flex w-full h-full bg-transparent rounded-lg"
                                    style="opacity: 0.1; background: repeating-linear-gradient(-45deg, #000, #000 5px, transparent 5px, transparent 15px)"></div>
                                <div
                                    if.bind="appointment.status === SchedulerItemStatuses.Cancelled"
                                    class="absolute flex w-full h-full bg-transparent rounded-lg"
                                    style="opacity: 0.1; background: repeating-linear-gradient(-45deg, #000, #000 5px, transparent 5px, transparent 15px)"></div>
                                <div
                                    if.bind="appointment.status === SchedulerItemStatuses.Cancelled"
                                    class="absolute flex w-full h-full bg-transparent rounded-lg"
                                    style="opacity: 0.1; background: repeating-linear-gradient(45deg, #000, #000 5px, transparent 5px, transparent 15px)"></div>
                                <div class="flex w-16">
                                    <div
                                        repeat.for="type of appointment.appointmentTypes"
                                        rounded-l-md.class="$index === 0"
                                        rounded-r-md.class="$index === appointment.appointmentTypes.length - 1"
                                        class="flex w-full h-full"
                                        css="background-color: ${type.color.background}; color: ${type.color.text}"></div>
                                </div>
                            </div>
                            <div class="flex flex-col items-start flex-1 min-w-0">
                                <div class="flex items-start justify-between w-full min-w-0 gap-5">
                                    <div class="flex flex-col min-w-0">
                                        <p repeat.for="type of appointment.appointmentTypes" class="font-medium leading-tight truncate">${type.name | translate}</p>
                                    </div>
                                    <p class="whitespace-nowrap">${appointment.practitioner.name }</p>
                                </div>

                                <div class="flex items-center justify-between w-full min-w-0 gap-5">
                                    <p class="leading-tight text-gray-600">
                                        <span>${appointment.start | date}</span>
                                        <span>${appointment.start | date:'HH:mm'}</span>
                                        <span> - </span>
                                        <span>${appointment.end | date:'HH:mm'}</span>
                                    </p>
                                    <p class="leading-tight text-gray-600 truncate">
                                        <span if.bind="appointment.location.applicationName">${appointment.location.applicationName | translate}</span>
                                        <span else>${appointment.location.name | translate}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <ux-dropdown
                            if.bind="hasRole(UserRoles.DeleteSchedulerItems) || hasRole(UserRoles.WriteSchedulerItems)"
                            border.bind="false"
                            type="full"
                            color="dark"
                            size="sm"
                            padding.bind="false"
                            mode="outline"
                            placement="bottom-right">
                            <div au-slot="label" class="p-1 text-gray-600 rounded-full ring-gray-400 focus:outline-none focus:ring-1">
                                <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512">
                                    <path
                                        fill="currentColor"
                                        d="M64 368C90.51 368 112 389.5 112 416C112 442.5 90.51 464 64 464C37.49 464 16 442.5 16 416C16 389.5 37.49 368 64 368zM64 208C90.51 208 112 229.5 112 256C112 282.5 90.51 304 64 304C37.49 304 16 282.5 16 256C16 229.5 37.49 208 64 208zM64 144C37.49 144 16 122.5 16 96C16 69.49 37.49 48 64 48C90.51 48 112 69.49 112 96C112 122.5 90.51 144 64 144z" />
                                </svg>
                            </div>
                            <ux-dropdown-option if.bind="hasRole(UserRoles.WriteSchedulerItems)" click.trigger="open(appointment)">
                                <span t="global.buttons.edit"></span>
                            </ux-dropdown-option>
                            <ux-dropdown-option if.bind="hasRole(UserRoles.WriteSchedulerItems) && appointment.status !== SchedulerItemStatuses.NoShow" click.trigger="markAsNoShow(appointment)">
                                <span t="translation:partial-views.patient-appointments.buttons.no-show-mark"></span>
                            </ux-dropdown-option>
                            <ux-dropdown-option if.bind="hasRole(UserRoles.WriteSchedulerItems) && appointment.status === SchedulerItemStatuses.NoShow" click.trigger="unmarkAsNoShow(appointment)">
                                <span t="translation:partial-views.patient-appointments.buttons.no-show-unmark"></span>
                            </ux-dropdown-option>
                            <ux-dropdown-divider if.bind="hasRole(UserRoles.DeleteSchedulerItems)"></ux-dropdown-divider>
                            <ux-dropdown-option if.bind="hasRole(UserRoles.DeleteSchedulerItems)" click.trigger="delete(appointment)">
                                <span class="text-sm text-red-500" t="global.buttons.delete"></span>
                            </ux-dropdown-option>
                        </ux-dropdown>
                    </div>
                    <div if.bind="appointments.length === 0" class="flex justify-center py-5">
                        <p class="text-sm" t="translation:partial-views.patient-appointments.messages.no-results"></p>
                    </div>
                </div>
            </template>
            <template else>
                <div class="flex flex-col flex-1 px-6 pt-2 gap-y-2">
                    <ux-skeleton show-background.bind="false" type="container">
                        <div class="flex flex-col w-full py-1 space-y-5">
                            <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-0"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-2"></ux-skeleton>
                            <ux-skeleton type="line"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-2"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                            <ux-skeleton type="line"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-4"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-2"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                        </div>
                    </ux-skeleton>
                </div>
            </template>
        </div>
    </section>
</template>
