<import from="converters/translate" />
<import from="converters/get-ids" />
<import from="bx/bx-anatomical-region-selector/bx-anatomical-region-selector" />
<import from="bx/bx-diagnosis-multi-selector/bx-diagnosis-multi-selector" />
<import from="bx/bx-keywords-selector/bx-keywords-selector" />
<import from="bx/bx-examination-phase-selector/bx-examination-phase-selector" />
<import from="bx/bx-diagnosis-category-multi-selector/bx-diagnosis-category-multi-selector" />

<template class="!flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex overflow-hidden gap-x-2" css.bind="styles">
        <div class="flex flex-col flex-1 w-full h-full overflow-hidden">
            <div class="px-8">
                <div class.bind="hasScrolled ? 'border-b' : ''" class="flex justify-between pt-8 pb-3 bg-white border-gray-200">
                    <p class="font-medium text-gray-400" t="translation:partial-views.differential-diagnoses.edit.title"></p>
                    <ux-dropdown border.bind="false" type="full" color="dark" size="sm" padding.bind="false" mode="outline" placement="bottom-right">
                        <div au-slot="label" class="p-1 text-gray-600 rounded-full ring-gray-400 focus:outline-none focus:ring-1">
                            <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512">
                                <path
                                    fill="currentColor"
                                    d="M64 368C90.51 368 112 389.5 112 416C112 442.5 90.51 464 64 464C37.49 464 16 442.5 16 416C16 389.5 37.49 368 64 368zM64 208C90.51 208 112 229.5 112 256C112 282.5 90.51 304 64 304C37.49 304 16 282.5 16 256C16 229.5 37.49 208 64 208zM64 144C37.49 144 16 122.5 16 96C16 69.49 37.49 48 64 48C90.51 48 112 69.49 112 96C112 122.5 90.51 144 64 144z" />
                            </svg>
                        </div>
                        <ux-dropdown-option click.trigger="informationSheet()">
                            <span class="text-sm" t="global.buttons.information-sheet"></span>
                        </ux-dropdown-option>
                        <ux-dropdown-option click.trigger="copy()">
                            <span class="text-sm" t="global.buttons.copy"></span>
                        </ux-dropdown-option>
                        <ux-dropdown-divider if.bind="hasRole(UserRoles.DeleteDifferentialDiagnoses)"></ux-dropdown-divider>
                        <ux-dropdown-option if.bind="hasRole(UserRoles.DeleteDifferentialDiagnoses)" click.trigger="delete()">
                            <span class="text-sm text-red-500" t="global.buttons.delete"></span>
                        </ux-dropdown-option>
                    </ux-dropdown>
                </div>
            </div>
            <div ref="scrollContainer" class="grid flex-col flex-1 grid-cols-12 gap-6 px-8 overflow-y-auto">
                <template if.bind="baseLoaded">
                    <div class="${isLinked ? 'col-span-6' : 'col-span-12'} flex flex-1 flex-col gap-y-3 pb-5">
                        <div class="flex flex-col">
                            <div class="flex items-center space-x-1">
                                <ux-label class="mb-1">
                                    <span t="global.labels.name"></span>
                                </ux-label>
                                <span class="flex items-center h-3 text-red-500">*</span>
                            </div>
                            <ux-textarea
                                action.bind="c => manageTranslationsFor('name', true)"
                                rows="1"
                                value.two-way="diagnosis.name[language]"
                                valid.bind="validation.name"
                                t="[placeholder]partial-views.differential-diagnoses.placeholders.name">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M10.5 21l5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 016-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 01-3.827-5.802" />
                                </svg>
                            </ux-textarea>
                        </div>
                        <div class="flex flex-col">
                            <div class="flex items-center space-x-1">
                                <ux-label class="mb-1">
                                    <span t="partial-views.differential-diagnoses.labels.select-examination-phase"></span>
                                </ux-label>
                                <span class="flex items-center h-3 text-red-500">*</span>
                            </div>
                            <bx-examination-phase-selector
                                on-select.bind="handlePhaseSelected"
                                language.bind="language"
                                workspace.bind="authenticated.workspace.id"
                                t="[placeholder]global.placeholders.select-examination-phase"
                                valid.bind="validation.phase"
                                value.two-way="diagnosis.phase.id">
                            </bx-examination-phase-selector>
                        </div>
                        <div class="flex flex-col col-span-12">
                            <ux-label class="mb-1">
                                <span t="global.labels.keywords"></span>
                            </ux-label>
                            <bx-keywords-selector workspace.bind="authenticated.workspace.id" values.two-way="diagnosis.keywords"></bx-keywords-selector>
                        </div>
                        <div class="flex flex-col col-span-12">
                            <ux-label class="mb-1">
                                <span t="partial-views.differential-diagnoses.labels.enter-synonyms"></span>
                            </ux-label>
                            <ux-tags tags.two-way="diagnosis.synonyms" t="[placeholder]global.placeholders.enter-synonyms-with-enter"></ux-tags>
                        </div>
                        <div class="flex flex-col">
                            <ux-label class="mb-1">
                                <span t="partial-views.differential-diagnoses.labels.select-region"></span>
                            </ux-label>
                            <bx-anatomical-region-selector
                                on-select.bind="handleRegionSelected"
                                language.bind="language"
                                allow-clear="true"
                                workspace.bind="authenticated.workspace.id"
                                t="[placeholder]partial-views.differential-diagnoses.placeholders.select-region"
                                value.two-way="diagnosis.anatomicalRegion.id">
                            </bx-anatomical-region-selector>
                        </div>
                        <div class="flex flex-col">
                            <ux-label class="mb-1">
                                <span t="global.labels.description"></span>
                            </ux-label>
                            <ux-textarea
                                action.bind="c => manageTranslationsFor('description')"
                                value.two-way="diagnosis.description[language]"
                                rows="8"
                                t="[placeholder]partial-views.differential-diagnoses.placeholders.description">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M10.5 21l5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 016-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 01-3.827-5.802" />
                                </svg>
                            </ux-textarea>
                        </div>
                        <div class="flex flex-col col-span-12">
                            <ux-label class="mb-1">
                                <span t="partial-views.differential-diagnoses.labels.select-distinguish-from"></span>
                            </ux-label>
                            <bx-diagnosis-multi-selector
                                workspace.bind="authenticated.workspace.id"
                                on-change.bind="handleDistinguishFromChanged"
                                language.bind="language"
                                used.bind="[diagnosis.id]"
                                t="[placeholder]translation:partial-views.differential-diagnoses.placeholders.select-differential-diagnoses"
                                values.bind="diagnosis.distinguishFrom | getIds">
                            </bx-diagnosis-multi-selector>
                        </div>
                        <div class="flex flex-col col-span-12">
                            <ux-label class="mb-1">
                                <span t="partial-views.differential-diagnoses.labels.select-occurs-with"></span>
                            </ux-label>
                            <bx-diagnosis-multi-selector
                                workspace.bind="authenticated.workspace.id"
                                on-change.bind="handleOccursWithChanged"
                                language.bind="language"
                                used.bind="[diagnosis.id]"
                                t="[placeholder]translation:partial-views.differential-diagnoses.placeholders.select-differential-diagnoses"
                                values.bind="diagnosis.occursWith | getIds">
                            </bx-diagnosis-multi-selector>
                        </div>
                        <div class="flex flex-col col-span-12">
                            <ux-label class="mb-1">
                                <span t="partial-views.differential-diagnoses.labels.select-categories"> </span>
                            </ux-label>
                            <bx-diagnosis-category-multi-selector
                                workspace.bind="authenticated.workspace.id"
                                on-change.bind="handleCategoriesChanged"
                                language.bind="language"
                                t="[placeholder]partial-views.differential-diagnoses.placeholders.select-categories"
                                values.bind="diagnosis.categories | getIds">
                            </bx-diagnosis-category-multi-selector>
                        </div>
                        <div class="flex flex-col">
                            <ux-label class="mb-1">
                                <span t="global.labels.healthcare-sector"></span>
                            </ux-label>
                            <ux-multi-selector values.two-way="diagnosis.healthcareSectors" type="healthcare-sectors"></ux-multi-selector>
                        </div>
                        <div class="flex flex-col">
                            <div mb-2.class="diagnosis.codes.length > 0" mb-1.class="diagnosis.codes.length === 0" class="flex items-center justify-between">
                                <ux-label>
                                    <span t="translation:global.labels.healthcare-codes"></span>
                                </ux-label>
                                <ux-button if.bind="diagnosis.codes.length > 0" click.trigger="addCode()" padding.bind="false" size="xs">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                    </svg>
                                </ux-button>
                            </div>
                            <ul if.bind="diagnosis.codes.length > 0" class="flex flex-col space-y-1">
                                <li repeat.for="code of diagnosis.codes" class="flex w-full">
                                    <div class="relative flex items-center flex-1 rounded-lg bg-gray-50">
                                        <button
                                            click.trigger="editCode($index)"
                                            class="flex items-center flex-1 h-full gap-6 px-4 py-1 border-l border-gray-200 rounded-l-lg border-y hover:bg-gray-50 focus:z-10 focus:outline-none active:bg-gray-100">
                                            <div class="flex items-center text-sm">${code.system}/${code.value}</div>
                                        </button>
                                        <button
                                            click.trigger="removeCode($index)"
                                            class="flex items-center justify-center w-10 p-2 text-red-500 border border-gray-200 rounded-r-lg bg-gray-50 ring-red-500 ring-offset-1 hover:bg-red-50 focus:z-10 focus:outline-none focus:ring-1 active:bg-red-100">
                                            <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                            </svg>
                                        </button>
                                    </div>
                                </li>
                            </ul>
                            <button
                                else
                                click.trigger="addCode($index)"
                                type="button"
                                class="text-gray-800.class relative flex w-full items-center justify-center gap-1 rounded-lg border-2 border-dashed border-gray-300 p-3 text-center hover:border-gray-400 focus:outline-none active:border-gray-500">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M9.568 3H5.25A2.25 2.25 0 0 0 3 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 0 0 5.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 0 0 9.568 3Z" />
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 6h.008v.008H6V6Z" />
                                </svg>

                                <span class="block text-sm font-semibold" t="translation:partial-views.medical-step-settings.buttons.add-code"></span>
                            </button>
                        </div>
                    </div>
                    <div if.bind="isLinked" class="flex flex-col col-span-6 gap-3">
                        <template if.bind="actions.length > 0">
                            <div class="flex flex-col col-span-2">
                                <div class="flex items-center justify-between mb-1">
                                    <ux-label>
                                        <span t="partial-views.differential-diagnoses.labels.related-actions"></span>
                                    </ux-label>
                                </div>
                                <ul class="flex flex-col gap-1">
                                    <li repeat.for="action of actions" class="group">
                                        <a
                                            href="javascript:"
                                            click.trigger="openView(action.id, 'action')"
                                            class="flex items-center justify-between px-3 py-2 text-sm border border-gray-300 rounded-lg bg-gray-50">
                                            <span> ${ action.name | translate:language }</span>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                                            </svg>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </template>
                        <template if.bind="questionnaires.length > 0">
                            <div class="flex flex-col">
                                <div class="flex items-center justify-between mb-1">
                                    <ux-label>
                                        <span t="partial-views.differential-diagnoses.labels.related-questionnaires"></span>
                                    </ux-label>
                                </div>
                                <ul class="flex flex-col gap-1">
                                    <li repeat.for="questionnaire of questionnaires" class="group">
                                        <a
                                            href="javascript:"
                                            click.trigger="openView(questionnaire.id, 'questionnaire')"
                                            class="flex items-center justify-between px-3 py-2 text-sm border border-gray-300 rounded-lg bg-gray-50">
                                            <span> ${ questionnaire.name | translate:language }</span>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                                            </svg>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </template>
                        <template if.bind="questions.length > 0">
                            <div class="flex flex-col col-span-2">
                                <div class="flex items-center justify-between mb-1">
                                    <ux-label>
                                        <span t="partial-views.differential-diagnoses.labels.related-questions"></span>
                                    </ux-label>
                                </div>
                                <ul class="flex flex-col gap-1">
                                    <li repeat.for="question of questions" class="group">
                                        <a
                                            href="javascript:"
                                            click.trigger="openView(question.id, 'question')"
                                            class="flex items-center justify-between px-3 py-2 text-sm border border-gray-300 rounded-lg bg-gray-50">
                                            <span> ${ question.name | translate:language }</span>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                                            </svg>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </template>
                    </div>
                </template>
                <template else>
                    <div class="flex flex-col flex-1 col-span-12">
                        <div class="grid grid-cols-1 gap-5">
                            <div class="flex flex-col flex-1 space-y-3">
                                <ux-skeleton show-background="true" type="container">
                                    <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                        <ux-skeleton type="line" padding="pr-60"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                    </div>
                                </ux-skeleton>
                                <ux-skeleton show-background="true" type="container">
                                    <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                        <ux-skeleton type="line" padding="pr-48"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                    </div>
                                </ux-skeleton>
                                <ux-skeleton show-background="true" type="container">
                                    <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                        <ux-skeleton type="line" padding="pr-32"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                    </div>
                                </ux-skeleton>
                                <ux-skeleton show-background="true" type="container">
                                    <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                        <ux-skeleton type="line" padding="pr-60"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                    </div>
                                </ux-skeleton>
                                <ux-skeleton show-background="true" type="container">
                                    <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                        <ux-skeleton type="line" padding="pr-20"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-8"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-6"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-0"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                    </div>
                                </ux-skeleton>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
            <div class="px-8">
                <div class="flex items-center justify-between py-4 bg-white border-t border-gray-200 gap-x-2">
                    <div class="flex gap-x-2">
                        <p if.bind="!validation.valid" class="text-xs text-red-500" t="translation:global.messages.not-all-values-entered"></p>
                    </div>
                    <div class="flex gap-x-2">
                        <ux-button size="sm" color="dark" mode="outline" disabled.bind="isLoading || deleting" click.trigger="cancel()">
                            <span class="pointer-events-none" t="global.buttons.cancel"></span>
                        </ux-button>
                        <ux-button
                            size="sm"
                            color="success"
                            disabled.bind="isLoading || deleting"
                            loading.bind="isLoading"
                            click.trigger="save()"
                            t="[text-when-loading]translation:global.labels.loading-states.default">
                            <span class="pointer-events-none" t="global.buttons.save"></span>
                        </ux-button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
