<import from="converters/replace" />

<div class="flex h-[38px] items-center gap-1 rounded-md border bg-gray-50 p-2 text-sm lowercase">
    <span class="whitespace-nowrap">${key}:<span class="text-primary">${display(value)}</span></span>
    <button click.trigger="remove()" class="rounded-full ring-red-500 active:ring-1">
        <svg xmlns="http://www.w3.org/2000/svg" class="text-red-500 size-4" viewBox="0 0 20 20" fill="currentColor">
            <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd" />
        </svg>
    </button>
</div>
