import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { GetUserResponse } from '@wecore/sdk-core';
import { HealthcareTaskListsApiClient, UpdateHealthcareTaskListRequest, UserEntityReference } from '@wecore/sdk-healthcare';
import { validateState } from '@wecore/sdk-utilities';

import { IEventAggregator, inject } from 'aurelia';
import { PartialViewResults } from '../../../enums/partial-view-results';
import { BasePartialView } from '../../../infra/base-partial-view';
import { CacheService } from '../../../infra/cache-service';
import { ErrorHandler } from '../../../infra/error-handler';
import { State } from '../../../infra/store/state';
import { cleanTranslatables, validateTranslation } from '../../../infra/utilities';
import { ConfirmationOptions } from '../../../models/confirmation-options';
import { PartialView } from '../../../models/partial-view';
import { ModalService } from '../../../services/service.modals';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, HealthcareTaskListsApiClient, ModalService)
export class PartialTaskListsEdit extends BasePartialView {
    public list: UpdateHealthcareTaskListRequest;
    public validation = {
        name: true
    };

    private listId: string;

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly listsApi: HealthcareTaskListsApiClient,
        private readonly modalService: ModalService
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.listId = view.data.id;
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                this.list = await this.listsApi.getById(this.listId, this.authenticated.workspace.id);
                this.baseLoaded = true;
            })
            .catch((x) => this.errorHandler.handle('PartialTaskListsEdit.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }

    public async cancel(): Promise<void> {
        await super.remove({
            result: PartialViewResults.Cancelled,
            updateUrl: true
        });
    }

    public async save(): Promise<void> {
        const valid = this.validate();

        if (valid) {
            this.isLoading = true;
            try {
                cleanTranslatables(['title'], this.list, 1);
                await this.listsApi.update(this.listId, this.authenticated.workspace.id, this.list);
                this.notifications.show(
                    this.t.tr('translation:partial-views.tasks.notifications-list.save-successful.title'),
                    this.t
                        .tr('translation:partial-views.tasks.notifications-list.save-successful.message') //
                        .replace('{entity}', `<span>'${this.list.name[this.language]}'</span>`),
                    {
                        type: 'success',
                        duration: 3000
                    }
                );
                setTimeout(async () => this.remove({ result: PartialViewResults.Ok, updateUrl: true }), 250);
            } catch (e) {
                this.isLoading = false;
                await this.errorHandler.handle('[edit-task-list]', e);
            }
        }
    }

    public manageTranslationsFor(property: string, required: boolean = false): void {
        this.manageTranslations({
            translations: this.list[property],
            callback: (updatedTranslations: any) => {
                this.list[property] = updatedTranslations;
            },
            required,
            type: property === 'description' ? 'textarea' : 'input'
        });
    }

    public handleUserSelected = (_: GetUserResponse[], __: 'added' | 'deleted', user: GetUserResponse): void => {
        const index = this.list.visibleTo.findIndex((x) => x.id === user.id);
        if (index > -1) this.list.visibleTo.splice(index, 1);
        else
            this.list.visibleTo = [
                ...this.list.visibleTo,
                new UserEntityReference({
                    id: user.id,
                    name: user.displayName
                })
            ];
    };

    public async delete(): Promise<void> {
        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('translation:partial-views.tasks.questions-list.delete.title'),
                message: this.t
                    .tr('translation:partial-views.tasks.questions-list.delete.message') //
                    .replace('{entity}', `<span>'${this.list.name[this.language]}'</span>`),
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        this.deleting = true;
                        try {
                            await this.listsApi.delete(this.listId, this.authenticated.workspace.id);
                            setTimeout(async () => this.remove({ result: PartialViewResults.Deleted, updateUrl: true }), 250);
                            this.notifications.show(
                                this.t.tr('translation:partial-views.tasks.notifications-list.deleted-successfully.title'),
                                this.t
                                    .tr('translation:partial-views.tasks.notifications-list.deleted-successfully.message') //
                                    .replace('{entity}', `<span>'${this.list.name[this.language]}'</span>`),
                                { type: 'success', duration: 3000 }
                            );
                        } catch (e) {
                            this.deleting = false;
                            await this.errorHandler.handle('[delete-list]', e);
                        }
                    }
                }
            })
        );
    }

    private validate(): boolean {
        this.validation.name = validateTranslation(this.list.name, this.language);
        return validateState(this.validation);
    }
}
