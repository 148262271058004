import { bindable } from 'aurelia';
import tippy from 'tippy.js';

export class UxTooltip {
    @bindable() public content: string;
    @bindable() public showWhen: boolean = true;

    public container: HTMLDivElement;

    public bound(): void {
        if(this.showWhen) {
            tippy(this.container, {
                content: this.content
            });
        }
    }
}
