import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import {
    AnatomicalRegionEntityReference,
    ColumnBreakpoint,
    DifferentialDiagnosesRequirements,
    DifferentialDiagnosisCategoryEntityReference,
    DifferentialDiagnosisEntityReference,
    GetAnatomicalRegionResponse,
    GetDifferentialDiagnosisResponse,
    GetMedicalExaminationActionCategoryResponse,
    GetMedicalExaminationPhaseResponse,
    HealthcareCode,
    HealthcarePrice,
    InformationSheet,
    MedicalExaminationActionItem,
    MedicalExaminationActionItemTypes,
    MedicalExaminationActionRequirements,
    MedicalExaminationActionStepCategory,
    MedicalExaminationActionTypes,
    MedicalExaminationActionsApiClient,
    MedicalExaminationPhaseEntityReference,
    MedicalExaminationStepVisibilityRequirement,
    Operators,
    ResultCheck,
    ResultCheckItem,
    StringBodySidesKeyValuePair,
    StringBooleanKeyValuePair,
    StringMedicalExaminationModelBoxKeyValuePair,
    StringStringKeyValuePair,
    UpdateMedicalExaminationActionRequest
} from '@wecore/sdk-healthcare';
import { guid, isDefined, isNotDefined, isNotEmpty, isOfType, resetValidation, validateState } from '@wecore/sdk-utilities';

import { IEventAggregator, inject } from 'aurelia';
import { format } from 'date-fns';
import { PartialViewResults } from '../../../enums/partial-view-results';
import { BasePartialView } from '../../../infra/base-partial-view';
import { CacheService } from '../../../infra/cache-service';
import { ErrorHandler } from '../../../infra/error-handler';
import { CustomEvents } from '../../../infra/events';
import { PartialViews } from '../../../infra/partial-views';
import { clearItem } from '../../../infra/store/actions/copy-paste';
import { State } from '../../../infra/store/state';
import {
    addCategory,
    addStep,
    cleanExpectedResults,
    cleanTranslatables,
    cloneDeep,
    collapseSteps,
    pasteItem,
    removeAllSettings,
    removeItemSettings,
    setExpectedResults,
    setTranslation,
    validateTranslation
} from '../../../infra/utilities';
import { ConfirmationOptions } from '../../../models/confirmation-options';
import { EventDetails } from '../../../models/event-details';
import { PartialView } from '../../../models/partial-view';
import { ViewOptions } from '../../../models/view-options';
import { ModalService } from '../../../services/service.modals';
import { UxSelectOption } from '../../../ux/ux-select-option/ux-select-option';
import { UxSelect } from '../../../ux/ux-select/ux-select';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, MedicalExaminationActionsApiClient, ModalService)
export class PartialMedicalExaminationActionsEdit extends BasePartialView {
    public MedicalExaminationActionTypes: typeof MedicalExaminationActionTypes = MedicalExaminationActionTypes;
    public MedicalExaminationActionItemTypes: typeof MedicalExaminationActionItemTypes = MedicalExaminationActionItemTypes;
    public actionId: string;
    public action: UpdateMedicalExaminationActionRequest;
    public itemsToCompare: any[] = [];
    public Operators: typeof Operators = Operators;
    public validation = {
        name: true,
        displayOrder: true,
        phase: true,
        steps: [],
        validSteps: true,
        any: true,
        items: [],
        valid: true
    };

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly actionsApi: MedicalExaminationActionsApiClient,
        private readonly modalService: ModalService
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.actionId = view.data.id;
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                const [action] = await Promise.all([
                    this.actionsApi.getById(this.actionId, this.authenticated.workspace.id) //
                ]);
                this.action = action;

                this.setItemsToCompare();
                this.setValidationAndTranslations();
                this.collapseOrExpandAll('collapse');

                if (isNotDefined(this.action.prices)) this.action.prices = [];
                if (isNotDefined(this.action.synonyms)) this.action.synonyms = [];
                if (isNotDefined(this.action.codes)) this.action.codes = [];
                if (isNotDefined(this.action.flow.sides)) this.action.flow.sides = [];

                this.subscriptions.push(this.events.subscribe(CustomEvents.ActionStepsChanged, () => this.handleItemsChanged()));

                // Delay showing content to prevent flickering.
                setTimeout(async () => {
                    this.baseLoaded = true;
                    await super.handleBaseLoaded();
                }, 250);
            })
            .catch((x) => this.errorHandler.handle('PartialMedicalExaminationActionsEdit.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
        this.store.dispatch(clearItem, 'CopyActionItem');
    }

    public handleItemsChanged(): void {
        this.action.stepsToTake = [...(this.action.stepsToTake.length > 0 ? [this.action.stepsToTake.shift()] : []), ...this.action.stepsToTake];
        this.validation.steps = [...(this.validation.steps.length > 0 ? [this.validation.steps.shift()] : []), ...this.validation.steps];
    }

    public handlePhaseSelected = async (phase: GetMedicalExaminationPhaseResponse): Promise<void> => {
        this.action.phase = new MedicalExaminationPhaseEntityReference({
            id: phase.id,
            translations: phase.name
        });
    };

    public handleRegionSelected = async (region: GetAnatomicalRegionResponse): Promise<void> => {
        this.action.anatomicalRegion = new AnatomicalRegionEntityReference({
            id: region.id,
            translations: region.name
        });
    };

    public handleCategoriesChanged = async (categories: GetMedicalExaminationActionCategoryResponse[]): Promise<void> => {
        this.action.categories = categories.map(
            (c) =>
                new DifferentialDiagnosisCategoryEntityReference({
                    id: c.id,
                    translations: c.name
                })
        );
    };

    public handleDiagnosesChanged = async (diagnoses: GetDifferentialDiagnosisResponse[]): Promise<void> => {
        // Copy over the keywords used for the diagnoses
        // whilst keeping the manually added ones.
        this.action.keywords = [
            ...this.action.keywords, //
            ...(diagnoses.any() //
                ? diagnoses.selectMany<GetDifferentialDiagnosisResponse, string>((x) => x.keywords)
                : [])
        ].distinct((x) => x);
        this.action.differentialDiagnoses = diagnoses.map(
            (d) =>
                new DifferentialDiagnosisEntityReference({
                    id: d.id,
                    translations: d.name
                })
        );
    };

    public async cancel(): Promise<void> {
        await super.remove({
            result: PartialViewResults.Cancelled,
            updateUrl: true
        });
    }

    public async save(close: boolean = true): Promise<void> {
        const valid = this.validate();
        if (valid) {
            this.isLoading = true;
            try {
                cleanTranslatables(
                    [
                        'name', //
                        'description',
                        'stepsToTake:category:name',
                        'stepsToTake:step:name',
                        'stepsToTake:step:description',
                        'stepsToTake:step:question',
                        'stepsToTake:step:placeholder'
                    ],
                    this.action,
                    3
                );
                cleanExpectedResults(this.action.stepsToTake);
                await this.actionsApi.update(this.actionId, this.authenticated.workspace.id, this.action);
                this.notifications.show(
                    this.t.tr('translation:partial-views.medical-examination-actions.notifications.save-successful.title'),
                    this.t
                        .tr('translation:partial-views.medical-examination-actions.notifications.save-successful.message') //
                        .replace('{entity}', `<span>'${this.action.name[this.language]}'</span>`),
                    { type: 'success', duration: 3000 }
                );
                if (close) setTimeout(async () => this.remove({ result: PartialViewResults.Ok, updateUrl: true }), 250);
                else {
                    setExpectedResults(this.action.stepsToTake);
                    this.resetTranslations();
                    this.isLoading = false;
                }
            } catch (e) {
                this.resetTranslations();
                this.isLoading = false;
                await this.errorHandler.handle('[edit-medical-examination-actions]', e);
            }
        }
    }

    public editAction = async (index: number, array: MedicalExaminationActionItem[], validationItems: any[]): Promise<void> => {
        // Note that JSON.parse(JSON.stringify()) will remove functions and dates from the object's properties.
        const item = MedicalExaminationActionItem.fromJS(array[index]);
        const validation = JSON.parse(JSON.stringify(validationItems[index]));

        await this.removeChildViews();
        await this.addPartialView({
            view: this.partial.base,
            partial: PartialViews.EditMedicalItem.with({
                item,
                validation
            }).whenClosed(async (result: PartialViewResults, response: { item: MedicalExaminationActionItem; validation: any }) => {
                if (result === PartialViewResults.Ok) {
                    array[index] = MedicalExaminationActionItem.fromJS(response.item);
                    validationItems[index] = response.validation;

                    // Force array to refresh.
                    this.action.stepsToTake = [
                        ...(this.action.stepsToTake.length > 0 ? [this.action.stepsToTake.shift()] : []), //
                        ...cloneDeep(this.action.stepsToTake)
                    ];

                    this.setItemsToCompare();
                }
            }),
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                markItem: true
            })
        });
    };

    public async delete(): Promise<void> {
        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('partial-views.medical-examination-actions.questions.delete.title'),
                message: this.t
                    .tr('partial-views.medical-examination-actions.questions.delete.message') //
                    .replace('{entity}', `<span>'${this.action.name[this.language]}'</span>`),
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        this.deleting = true;
                        try {
                            await this.actionsApi.delete(this.actionId, this.authenticated.workspace.id);
                            this.notifications.show(
                                this.t.tr('translation:partial-views.medical-examination-actions.notifications.deleted-successfully.title'),
                                this.t
                                    .tr('translation:partial-views.medical-examination-actions.notifications.deleted-successfully.message') //
                                    .replace('{entity}', `<span>'${this.action.name[this.language]}'</span>`),
                                { type: 'success', duration: 3000 }
                            );
                            setTimeout(async () => this.remove({ result: PartialViewResults.Deleted, updateUrl: true }), 250);
                        } catch (e) {
                            this.deleting = false;
                            await this.errorHandler.handle('[delete-medical-examination-actions-detail]', e);
                        }
                    }
                }
            })
        );
    }

    public manageTranslationsFor = (
        property: string, //
        required: boolean = false,
        index: number = -1,
        stepsToTake: MedicalExaminationActionStepCategory[] = null
    ): void => {
        let translations: any;
        if (property.includes(':')) {
            const [item, prop] = property.split(':');
            translations = stepsToTake[index][item][prop];
        } else translations = this.action[property];

        this.manageTranslations({
            translations,
            callback: (updatedTranslations: any) => {
                if (property.includes(':')) {
                    const [item, prop] = property.split(':');
                    stepsToTake[index][item][prop] = updatedTranslations;
                } else this.action[property] = updatedTranslations;
            },
            required,
            type: property.includes('description') ? 'textarea' : 'input'
        });
    };

    public addCategory = (index: number = -1): void => {
        collapseSteps(this.action.stepsToTake);
        addCategory(this.action.stepsToTake, this.validation.steps, index, this.language, 'MedicalExaminationActionItem');

        this.setItemsToCompare();
    };

    public addStep = (index: number = -1): void => {
        collapseSteps(this.action.stepsToTake);
        addStep(this.action.stepsToTake, this.validation.steps, index, this.language, 'MedicalExaminationActionItem');

        this.setItemsToCompare();
    };

    public removeActionItem = (index: number, category: MedicalExaminationActionStepCategory, validation: any[]): void => {
        let item: MedicalExaminationActionItem;

        if (isDefined(category)) {
            item = category.stepsToTake[index];

            category.stepsToTake.splice(index, 1);
            validation.splice(index, 1);
        } else {
            item = this.action.stepsToTake[index];

            this.action.stepsToTake.splice(index, 1);
            this.validation.steps.splice(index, 1);
        }

        if (item.type === MedicalExaminationActionItemTypes.Category) removeAllSettings(this.action.flow, item.category.stepsToTake);
        removeItemSettings(this.action.flow, item);

        this.handleItemsChanged();
        this.setItemsToCompare();

        this.events.publish(CustomEvents.ExaminationStepSettingsChanged);
    };

    public pasteItem = async (index: number = -1): Promise<void> => {
        const copyItem = this.state.clipboard.actionItem;
        if (isNotDefined(copyItem)) return;

        await pasteItem(
            this.store, //
            this.state,
            this.action.stepsToTake,
            this.validation.steps,
            index,
            this.action.flow,
            'MedicalExaminationActionItem'
        );
    };

    public collapseOrExpandAll(command: 'collapse' | 'expand'): void {
        const collapseOrExpand = (items: MedicalExaminationActionItem[], command: 'collapse' | 'expand'): void => {
            for (const item of items) {
                if (item.type === MedicalExaminationActionItemTypes.Category) {
                    item.attributes.expanded = command === 'expand';
                    collapseOrExpand(item.category.stepsToTake, command);
                } else item.attributes.expanded = command === 'expand';
            }
        };
        collapseOrExpand(this.action.stepsToTake, command);
    }

    public informationSheet(): void {
        this.addPartialView({
            view: this.partial.base,
            partial: PartialViews.InformationSheet.with({
                config: {
                    mode: 'edit',
                    language: this.language
                },
                sheet: this.action.informationSheet
            }).whenClosed(async (result: PartialViewResults, sheet: InformationSheet) => {
                if (result === PartialViewResults.Ok) {
                    this.action.informationSheet = sheet;
                }
            }),
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                markItem: true,
                replace: true
            })
        });
    }

    public async copy(): Promise<void> {
        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('partial-views.medical-examination-actions.questions.copy.title'),
                message: this.t.tr('partial-views.medical-examination-actions.questions.copy.message'),
                btnOk: this.t.tr('translation:global.buttons.copy'),
                type: 'warning',
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        try {
                            await this.actionsApi.copy(this.actionId, this.authenticated.workspace.id);
                            this.notifications.show(
                                this.t.tr('translation:partial-views.medical-examination-actions.notifications.copied-successfully.title'),
                                this.t.tr('translation:partial-views.medical-examination-actions.notifications.copied-successfully.message'),
                                { type: 'success', duration: 3000 }
                            );
                            setTimeout(async () => this.remove({ result: PartialViewResults.Ok, updateUrl: true }), 250);
                        } catch (e) {
                            await this.errorHandler.handle('[copy-medical-question]', e);
                        }
                    }
                }
            })
        );
    }

    public addResultCheckItem(): void {
        this.action.resultCheck.items.push(
            new ResultCheckItem({
                operator: this.action.resultCheck.items.any() ? Operators.Or : null
            })
        );
        this.validation.items.push({ step: true, operator: true });
    }

    public handleStepSelected(e: CustomEvent<EventDetails<UxSelect, UxSelectOption>>): void {
        const index = e.detail.data;
        const value = e.detail.values.value;

        setTimeout(() => {
            const selected = this.itemsToCompare.find((x) => x.value === value);
            this.action.resultCheck.items[index].stepId = selected.item.id;

            this.action.resultCheck.items = [
                ...(this.action.resultCheck.items.length > 0 ? [this.action.resultCheck.items.shift()] : []), //
                ...this.action.resultCheck.items
            ];
        });
    }

    public removeResultCheckItem(index: number): void {
        this.action.resultCheck.items.splice(index, 1);
        this.validation.items.splice(index, 1);
    }

    public openSettings = async (step: MedicalExaminationActionItem, categoryId: string, categoryName: string): Promise<void> => {
        const label = step.type === MedicalExaminationActionItemTypes.Category ? 'global.labels.category' : 'global.labels.step';
        const name = step.type === MedicalExaminationActionItemTypes.Category ? step.category.name[this.language] : step.step.name[this.language];

        if (isNotDefined(this.action.flow.required)) this.action.flow.required = [];
        if (isNotDefined(this.action.flow.breakpoints)) this.action.flow.breakpoints = [];
        if (isNotDefined(this.action.flow.modelBoxes)) this.action.flow.modelBoxes = [];
        if (isNotDefined(this.action.flow.sides)) this.action.flow.sides = [];
        if (isNotDefined(this.action.flow.visibilityRequirements)) this.action.flow.visibilityRequirements = [];
        if (isNotDefined(this.action.flow.differentialDiagnosesRequirements)) this.action.flow.differentialDiagnosesRequirements = [];
        if (isNotDefined(this.action.flow.medicalExaminationActionRequirements)) this.action.flow.medicalExaminationActionRequirements = [];

        const view = PartialViews.MedicalStepSettings.with({
            language: this.language,
            container: this.action.flow,
            item: step,
            active: this.action,
            settings: ['required', 'columns', 'visibility', 'differentialDiagnosis', 'actions', 'categories', 'steps', 'sides'],
            label,
            name,
            categoryId,
            stepCategoryName: categoryName
        }).whenClosed(
            async (
                result: PartialViewResults,
                response: {
                    breakpoints: ColumnBreakpoint[];
                    required: StringBooleanKeyValuePair;
                    repeatDuringEvaluation: StringBooleanKeyValuePair;
                    visibilityRequirements: MedicalExaminationStepVisibilityRequirement[];
                    ddRequirements: DifferentialDiagnosesRequirements[];
                    actionRequirements: MedicalExaminationActionRequirements[];
                    modelBox: StringMedicalExaminationModelBoxKeyValuePair;
                    side: StringBodySidesKeyValuePair;
                    categories: StringStringKeyValuePair[];
                    connectedSteps: StringStringKeyValuePair[];
                }
            ) => {
                if (result === PartialViewResults.Ok) {
                    // Remove old breakpoints and add new ones.
                    this.action.flow.breakpoints = [
                        ...this.action.flow.breakpoints.filter((x) => x.id !== step.id), //
                        ...response.breakpoints.map(ColumnBreakpoint.fromJS)
                    ];

                    // Remove old requirements and add new one if present.
                    this.action.flow.required = this.action.flow.required.filter((x) => x.key !== step.id);
                    if (isDefined(response.required))
                        this.action.flow.required.push(
                            StringBooleanKeyValuePair.fromJS(response.required) //
                        );

                    // Remove old side and add new one if present.
                    this.action.flow.sides = this.action.flow.sides.filter((x) => x.key !== step.id);

                    if (isDefined(response.side))
                        this.action.flow.sides.push(
                            StringBodySidesKeyValuePair.fromJS(response.side) //
                        );
                    // Remove old visibilityRequirements and add new ones.
                    this.action.flow.visibilityRequirements = [
                        ...this.action.flow.visibilityRequirements.filter((x) => x.id !== step.id), //
                        ...response.visibilityRequirements.map(MedicalExaminationStepVisibilityRequirement.fromJS)
                    ];

                    // Remove old differentialDiagnosesRequirements and add new ones.
                    this.action.flow.differentialDiagnosesRequirements = [
                        ...this.action.flow.differentialDiagnosesRequirements.filter((x) => x.id !== step.id), //
                        ...response.ddRequirements.map(DifferentialDiagnosesRequirements.fromJS)
                    ];

                    // Remove old differentialDiagnosesRequirements and add new ones.
                    this.action.flow.medicalExaminationActionRequirements = [
                        ...this.action.flow.medicalExaminationActionRequirements.filter((x) => x.id !== step.id), //
                        ...response.actionRequirements.map(MedicalExaminationActionRequirements.fromJS)
                    ];

                    // Remove old connected categories and add new ones.
                    this.action.flow.connectedCategories = [
                        ...this.action.flow.connectedCategories.filter((x) => x.key !== step.id), //
                        ...response.categories.map(StringStringKeyValuePair.fromJS)
                    ];

                    // Remove old connected steps and add new ones.
                    this.action.flow.connectedSteps = [
                        ...this.action.flow.connectedSteps.filter((x) => x.key !== step.id), //
                        ...response.connectedSteps.map(StringStringKeyValuePair.fromJS)
                    ];

                    this.events.publish(CustomEvents.ExaminationStepSettingsChanged);
                }
            }
        );

        await this.removeChildViews();
        await this.addPartialView({
            view: this.partial.base,
            partial: view,
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                markItem: true
            })
        });
    };

    public async createOrEditPrice(index: number = -1): Promise<void> {
        const price = index > -1 ? this.action.prices[index] : null;
        await this.removeChildViews();
        await this.addPartialView({
            view: this.partial.base,
            partial: PartialViews.HealthcarePrices.with({
                price: cloneDeep(price), //
                index,
                prices: this.action.prices
            }).whenClosed(async (result: PartialViewResults, data: { price: HealthcarePrice; index: number }) => {
                if (result === PartialViewResults.Ok) {
                    if (data.index > -1) this.action.prices[data.index] = data.price;
                    else this.action.prices.push(data.price);

                    this.action.prices = [
                        ...(this.action.prices.length > 0 ? [this.action.prices.shift()] : []), //
                        ...this.action.prices
                    ];
                }
            }),
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                markItem: true,
                updateUrl: false
            })
        });
    }

    public async removePrice(index: number): Promise<void> {
        this.action.prices.splice(index, 1);
    }

    public formatDescription(index: number): string {
        let description = '';
        if (isDefined(this.action.prices[index].periodStart)) description += format(this.action.prices[index].periodStart, 'dd-MM-yyyy');
        if (isDefined(this.action.prices[index].periodStart)) description += ' - ';
        if (isDefined(this.action.prices[index].periodEnd)) description += format(this.action.prices[index].periodEnd, 'dd-MM-yyyy');

        if (isDefined(this.action.prices[index].insurer)) description += ` (${this.action.prices[index].insurer.translations[this.language]})`;

        return description;
    }

    public async addCode(): Promise<void> {
        await this.removeChildViews();
        this.addPartialView({
            view: this.partial.base,
            partial: PartialViews.HealthcareCodes.with({ language: this.language }).whenClosed(async (result: PartialViewResults, data: { code: HealthcareCode }) => {
                if (result === PartialViewResults.Ok) {
                    this.action.codes.push(data.code);
                }
            }),
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                markItem: true,
                replace: true
            })
        });
    }

    public async editCode(index: number): Promise<void> {
        await this.removeChildViews();
        await this.addPartialView({
            view: this.partial.base,
            partial: PartialViews.HealthcareCodes.with({
                code: this.action.codes[index],
                language: this.language,
                index
            }).whenClosed(async (result: PartialViewResults, data: { code: HealthcareCode; index: number }) => {
                if (result === PartialViewResults.Ok) {
                    this.action.codes[data.index] = data.code;

                    this.action.codes = [
                        ...(this.action.codes.length > 0 ? [this.action.codes.shift()] : []), //
                        ...this.action.codes
                    ];
                }
            }),
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                markItem: true,
                replace: true
            })
        });
    }

    public async removeCode(index: number): Promise<void> {
        await this.removeChildViews();
        this.action.codes.splice(index, 1);
    }

    private setItemsToCompare(): void {
        this.itemsToCompare = [];
        const find = (items: MedicalExaminationActionItem[], category: MedicalExaminationActionStepCategory = null): void => {
            for (const item of items) {
                if (item.type === MedicalExaminationActionItemTypes.Category) find(item.category.stepsToTake, item.category);
                else {
                    let name = item.step.name[this.language] || this.t.tr('translation:global.labels.unknown');
                    if (isDefined(category)) name = `${category.name[this.language]} - ${name}`;
                    this.itemsToCompare.push({ text: name, value: item.id, choices: item.step.choices, item });
                }
            }
        };

        find(this.action.stepsToTake);
    }

    private setValidationAndTranslations(): void {
        this.resetTranslations();
        const setValidations = (items: MedicalExaminationActionItem[], validation: any[]): void => {
            for (const item of items) {
                if (item.type === MedicalExaminationActionItemTypes.Category) {
                    validation.push({
                        valid: true,
                        childrenValid: true,
                        name: true,
                        steps: [],
                        choices: [],
                        labels: []
                    });
                    setValidations(item.category.stepsToTake, validation[validation.length - 1].steps);
                } else {
                    const choices: any[] = [];
                    for (const choice of item.step.choices) {
                        // Because the ID property was added later, we have to
                        // make sure all items have an ID property.
                        if (isNotDefined(choice.id)) choice.id = guid();
                        choices.push({
                            id: choice.id,
                            value: true,
                            score: true,
                            numeric: true,
                            unique: true
                        });
                    }

                    const labels: any[] = [];
                    for (const _ of item.step.slider.labels) {
                        labels.push({
                            value: true,
                            valueValid: true,
                            unique: true,
                            label: true
                        });
                    }

                    validation.push({
                        valid: true,
                        name: true,
                        displayOrder: true,
                        question: true,
                        norm: true,
                        norms: true,
                        inputAmount: true,
                        inputAmountValid: true,
                        filesAmount: true,
                        choices,
                        numericChoices: true,
                        inputAmountValidMultipleChoices: true,
                        defaultValue: true,
                        choice: true,
                        minRange: true,
                        maxRange: true,
                        minValue: true,
                        maxValue: true,
                        startValue: true,
                        endValue: true,
                        endValueValid: true,
                        initialValue: true,
                        initialValueValid: true,
                        majorIncrement: true,
                        majorIncrementValid: true,
                        minorIncrement: true,
                        minorIncrementValid: true,
                        labels
                    });
                }
            }
        };

        if (isNotDefined(this.action.resultCheck)) this.action.resultCheck = new ResultCheck({ items: [] });
        for (const _ of this.action.resultCheck.items) {
            this.validation.items.push({
                step: true,
                operator: true
            });
        }

        setValidations(this.action.stepsToTake, this.validation.steps);
    }

    private validate(): boolean {
        this.validation.name = validateTranslation(this.action.name, this.language);
        this.validation.displayOrder = isNotEmpty(this.action.displayOrder) && isOfType(Number(this.action.displayOrder), 'number');
        this.validation.phase = isDefined(this.action.phase);
        this.validation.any = isDefined(this.action.stepsToTake) && this.action.stepsToTake.length > 0;

        for (let index = 0; index < this.action.resultCheck.items.length; index++) {
            const item = this.action.resultCheck.items[index];
            this.validation.items[index].step = isDefined(item.stepId);
        }

        let validSteps = true;
        // Recursively validate each step to take.
        const validateSteps = (items: MedicalExaminationActionItem[], validation: any[]): void => {
            for (let index = 0; index < items.length; index++) {
                const item = items[index];
                // Make sure all properties are set to 'true'
                resetValidation(validation[index]);

                // Validate the object.
                if (item.type === MedicalExaminationActionItemTypes.Category) {
                    validation[index].name = validateTranslation(item.category.name, this.language);

                    validateSteps(item.category.stepsToTake, validation[index].steps);
                    // After validation children check if all are valid.
                    validation[index].childrenValid = validation[index].steps.every((x: any) => {
                        if (isDefined(x.childrenValid)) return x.valid && x.childrenValid;
                        return x.valid;
                    });
                } else {
                    // The reset is validation on the view 'partial-medical-examination-actions-edit-item'.
                    validation[index].name = validateTranslation(item.step.name, this.language);
                    validation[index].question = validateTranslation(item.step.question, this.language);
                }
                // Set validation status of the current item.
                validation[index].valid = validateState(validation[index]) && validation[index].choices.every(validateState);
                // // Only mutate the 'validSteps' property if it is still valid.
                if (validSteps) validSteps = validation[index].valid;
            }
        };
        validateSteps(this.action.stepsToTake, this.validation.steps);
        this.validation.validSteps = validSteps;

        this.validation.valid = validateState(this.validation) && validSteps && this.validation.items.every((x: any) => x.step);

        return this.validation.valid;
    }

    private resetTranslations(): void {
        if (isNotDefined(this.action.description)) this.action.description = setTranslation({}, this.language);
        const setTranslations = (items: MedicalExaminationActionItem[]): void => {
            for (const item of items) {
                if (item.type === MedicalExaminationActionItemTypes.Category) {
                    // Category doesn't have non-required translation values.
                    setTranslations(item.category.stepsToTake);
                } else {
                    if (isNotDefined(item.step.description)) item.step.description = setTranslation({}, this.language);
                    if (isNotDefined(item.step.placeholder)) item.step.placeholder = setTranslation({}, this.language);
                    if (isNotDefined(item.step.question)) item.step.question = setTranslation({}, this.language);
                }
            }
        };
        setTranslations(this.action.stepsToTake);
    }
}
