<import from="converters/get-ids" />
<import from="bx/bx-diagnosis-multi-selector/bx-diagnosis-multi-selector" />

<div class="grid grid-cols-12 gap-3">
    <div class="flex flex-col col-span-6">
        <div class="flex flex-col mb-1">
            <ux-label class="mb-1">
                <span t="translation:components.widgets.care-indication.labels.history-of-ulcus"></span>
            </ux-label>
        </div>
        <ux-select value.two-way="answers.hasHistoryOfUlcus" onselect.trigger="handleHasHistoryOfUlcusChanged($event)">
            <ux-select-option value.bind="1">
                <span t="translation:global.labels.yes"></span>
            </ux-select-option>
            <ux-select-option value.bind="0">
                <span t="translation:global.labels.no"></span>
            </ux-select-option>
        </ux-select>
    </div>
    <div class="flex flex-col col-span-6">
        <div class="flex flex-col mb-1">
            <ux-label>
                <span t="translation:components.widgets.care-indication.labels.history-of-charchots-foot"></span>
            </ux-label>
        </div>
        <ux-select value.two-way="answers.hasHistoryOfCharhotsFoot" onselect.trigger="handleHasHistoryOfCharhotsFootChanged($event)">
            <ux-select-option value.bind="1">
                <span t="translation:global.labels.yes"></span>
            </ux-select-option>
            <ux-select-option value.bind="0">
                <span t="translation:global.labels.no"></span>
            </ux-select-option>
        </ux-select>
    </div>
    <div class="flex flex-col col-span-6">
        <div class="flex flex-col mb-1">
            <ux-label>
                <span t="translation:components.widgets.care-indication.labels.lower-limb-amputation"></span>
            </ux-label>
            <span class="text-sm text-primary" t="translation:components.widgets.care-indication.messages.no-accident-or-cancer"></span>
        </div>
        <ux-select value.two-way="answers.lowerLimbAmputation" onselect.trigger="handleLowerLimbAmputationChanged($event)">
            <ux-select-option value.bind="1">
                <span t="translation:global.labels.yes"></span>
            </ux-select-option>
            <ux-select-option value.bind="0">
                <span t="translation:global.labels.no"></span>
            </ux-select-option>
        </ux-select>
    </div>
    <div class="flex flex-col col-span-6">
        <div class="flex flex-col mb-1">
            <ux-label>
                <span t="translation:components.widgets.care-indication.labels.renal-failure"></span>
            </ux-label>
            <!-- <span class="text-sm text-primary" t="translation:components.widgets.care-indication.messages.renal-failure"></span> -->
        </div>
        <ux-select value.two-way="answers.renalFailure" onselect.trigger="handleRenalFailureChanged($event)">
            <ux-select-option value.bind="1">
                <span t="translation:global.labels.yes"></span>
            </ux-select-option>
            <ux-select-option value.bind="0">
                <span t="translation:global.labels.no"></span>
            </ux-select-option>
        </ux-select>
    </div>
    <div class="flex flex-col col-span-6">
        <div class="flex flex-col mb-1">
            <ux-label>
                <span t="translation:components.widgets.care-indication.labels.history-of-vascular-disease"></span>
            </ux-label>
            <span class="text-sm text-primary" t="translation:components.widgets.care-indication.messages.history-of-vascular-disease"></span>
        </div>
        <ux-select value.two-way="answers.hasHistoryOfVascularDisease" onselect.trigger="handleHasHistoryOfVascularDiseaseChanged($event)">
            <ux-select-option value.bind="1">
                <span t="translation:global.labels.yes"></span>
            </ux-select-option>
            <ux-select-option value.bind="0">
                <span t="translation:global.labels.no"></span>
            </ux-select-option>
        </ux-select>
    </div>
    <div class="flex flex-col col-span-6">
        <div class="flex flex-col pb-4 mb-1">
            <ux-label>
                <span t="translation:components.widgets.care-indication.labels.active-ulcus"></span>
            </ux-label>
        </div>
        <ux-select value.two-way="answers.activeUlcus" onselect.trigger="handleActiveUlcusChanged($event)">
            <ux-select-option value.bind="1">
                <span t="translation:global.labels.yes"></span>
            </ux-select-option>
            <ux-select-option value.bind="0">
                <span t="translation:global.labels.no"></span>
            </ux-select-option>
        </ux-select>
    </div>
    <div class="flex flex-col col-span-6">
        <div class="flex flex-col mb-1">
            <ux-label>
                <span t="translation:components.widgets.care-indication.labels.suspected-active-charchots-foot"></span>
            </ux-label>
        </div>
        <ux-select value.two-way="answers.suspectedActiveCharchotsFoot" onselect.trigger="handleSuspectedActiveCharchotsFootChanged($event)">
            <ux-select-option value.bind="1">
                <span t="translation:global.labels.yes"></span>
            </ux-select-option>
            <ux-select-option value.bind="0">
                <span t="translation:global.labels.no"></span>
            </ux-select-option>
        </ux-select>
    </div>
    <div class="flex flex-col col-span-6">
        <div class="flex flex-col mb-1">
            <ux-label>
                <span t="translation:components.widgets.care-indication.labels.peripheral-sensory-loss"></span>
            </ux-label>
        </div>
        <ux-select value.two-way="answers.peripheralSensoryLoss" onselect.trigger="handlePeripheralSensoryLossChanged($event)">
            <ux-select-option value.bind="1">
                <span t="translation:global.labels.yes"></span>
            </ux-select-option>
            <ux-select-option value.bind="0">
                <span t="translation:global.labels.no"></span>
            </ux-select-option>
        </ux-select>
    </div>
    <div class="flex flex-col col-span-6">
        <div class="flex flex-col mb-1">
            <ux-label>
                <span t="translation:components.widgets.care-indication.labels.suspected-peripheral-arterial-disease"></span>
            </ux-label>
        </div>
        <ux-select value.two-way="answers.suspectedPeripheralArterialDisease" onselect.trigger="handleSuspectedPeripheralArterialDiseaseChanged($event)">
            <ux-select-option value.bind="1">
                <span t="translation:global.labels.yes"></span>
            </ux-select-option>
            <ux-select-option value.bind="0">
                <span t="translation:global.labels.no"></span>
            </ux-select-option>
        </ux-select>
    </div>
    <div class="flex flex-col col-span-6">
        <div class="flex flex-col mb-1">
            <ux-label>
                <span t="translation:components.widgets.care-indication.labels.signs-of-hyperpression"></span>
            </ux-label>
        </div>
        <ux-select value.two-way="answers.signsOfHyperpression" onselect.trigger="handleSignsOfHyperpressionChanged($event)">
            <ux-select-option value.bind="1">
                <span t="translation:global.labels.yes"></span>
            </ux-select-option>
            <ux-select-option value.bind="0">
                <span t="translation:global.labels.no"></span>
            </ux-select-option>
        </ux-select>
    </div>
</div>
<div class="flex items-center justify-between gap-5 p-5 mt-5 border border-gray-300 rounded-md">
    <div class="flex flex-col flex-1">
        <div class="flex gap-1 text-sm">
            <div class="flex">
                <p t="translation:components.widgets.care-indication.labels.sims-classification"></p>
                <p>:</p>
            </div>
            <p if.bind="sims === CareProfiles.Zero">0</p>
            <p if.bind="sims === CareProfiles.One">1</p>
            <p if.bind="sims === CareProfiles.Two">2</p>
            <p if.bind="sims === CareProfiles.Three">3</p>
            <p if.bind="sims === CareProfiles.Four">4</p>
            <p if.bind="!sims">N/A</p>
        </div>
        <div class="flex gap-1 text-sm">
            <div class="flex">
                <p t="translation:components.widgets.care-indication.labels.care-profile"></p>
                <p>:</p>
            </div>
            <p if.bind="careProfile === CareProfiles.Zero">0</p>
            <p if.bind="careProfile === CareProfiles.One">1</p>
            <p if.bind="careProfile === CareProfiles.Two">2</p>
            <p if.bind="careProfile === CareProfiles.Three">3</p>
            <p if.bind="careProfile === CareProfiles.Four">4</p>
            <p if.bind="!careProfile">N/A</p>
        </div>
    </div>
</div>
