<import from="converters/date" />
<import from="converters/currency" />
<import from="converters/declaration-send-method-to-string" />
<import from="converters/declaration-performance-status-to-color" />
<import from="converters/declaration-performance-status-to-string" />
<import from="converters/declaration-performance-type-to-string" />
<import from="templates/template-filter/template-filter" />

<template class="!flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex overflow-hidden gap-x-2" css.bind="styles">
        <div ref="scrollContainer" class="flex flex-col flex-1 overflow-y-auto">
            <div class="sticky top-0 z-10 bg-white/50 backdrop-blur-xl">
                <ux-card-header>
                    <div class="flex flex-col flex-1 px-6 py-4">
                        <div class="flex items-center justify-between">
                            <h4 class="text-lg font-semibold text-gray-800" t="translation:partial-views.declaration-performances.labels.title"></h4>
                            <div class="flex gap-1"></div>
                        </div>
                        <div class="grid grid-cols-3 mt-2 gap-x-3">
                            <div class="p-16 bg-gray-100 rounded-lg"></div>
                            <div class="p-16 bg-gray-100 rounded-lg"></div>
                            <div class="p-16 bg-gray-100 rounded-lg"></div>
                        </div>
                    </div>
                </ux-card-header>
                <ux-table-header>
                    <ux-table-row lock="false" location="header" class="border-b border-gray-200">
                        <ux-table-cell width="50" class="py-[0.3rem]">
                            <div if.bind="baseLoaded" class="flex items-center justify-center h-full">
                                <ux-checkbox checked.two-way="selectedAll" onchange.trigger="handleSelectAll($event)"></ux-checkbox>
                            </div>
                        </ux-table-cell>
                        <ux-table-cell class="py-[0.3rem] pl-3 pr-0">
                            <span class="text-gray-500" t="translation:global.labels.description"></span>
                        </ux-table-cell>
                        <!-- <ux-table-cell class="py-[0.3rem] pl-6 pr-0" width="200">
                            <span class="text-gray-500" t="translation:global.labels.created-at"></span>
                        </ux-table-cell> -->
                        <ux-table-cell class="py-[0.3rem] pl-6 pr-0" width="135">
                            <span class="text-gray-500" t="translation:global.labels.invoice"></span>
                        </ux-table-cell>
                        <ux-table-cell class="py-[0.3rem] pl-6 pr-0" width="225">
                            <span class="text-gray-500" t="translation:global.labels.method"></span>
                        </ux-table-cell>
                        <ux-table-cell class="py-[0.3rem] pl-6 pr-0" width="75">
                            <span class="text-gray-500" t="translation:global.labels.type"></span>
                        </ux-table-cell>
                        <ux-table-cell class="py-[0.3rem] pl-6 pr-0" width="125">
                            <span class="text-gray-500" t="translation:global.labels.total"></span>
                        </ux-table-cell>
                        <ux-table-cell class="py-[0.3rem] pl-6 pr-0" width="225">
                            <span class="text-gray-500" t="translation:global.labels.status"></span>
                        </ux-table-cell>
                    </ux-table-row>
                </ux-table-header>
            </div>
            <ux-table class="flex flex-1">
                <ux-table-body if.bind="baseLoaded" class="flex-1">
                    <template repeat.for="performance of performances">
                        <ux-table-row lock.bind="false" class.bind="performances.length === 1 ? 'border-b border-gray-200' : ''" id="row-${$index}">
                            <ux-table-cell width="50" class="py-[0.3rem]">
                                <div if.bind="baseLoaded" class="flex items-center justify-center h-full">
                                    <ux-checkbox
                                        if.bind="performance.status === DeclarationPerformanceStatuses.Draft"
                                        model.bind="performance"
                                        checked.bind="selection"
                                        onchange.trigger="handleItemChecked($event)">
                                    </ux-checkbox>
                                </div>
                            </ux-table-cell>
                            <ux-table-cell class="py-[0.3rem] pl-3">
                                <a click.trigger="edit(performance)" class="underline truncate" href="javascript:">
                                    <span>(${performance.code.value}) ${performance.description}</span>
                                </a>
                            </ux-table-cell>
                            <!-- <ux-table-cell class="py-[0.3rem]" width="200">${performance.createdAt | date:'dd-MM-yyyy HH:mm'}</ux-table-cell> -->
                            <ux-table-cell class="py-[0.3rem]" width="135">${performance.invoice.name}</ux-table-cell>
                            <ux-table-cell class="py-[0.3rem]" width="225">${performance.sendMethod | declarationSendMethodToString | t}</ux-table-cell>
                            <ux-table-cell class="py-[0.3rem]" width="75">${performance.type | declarationPerformanceTypeToString | t} </ux-table-cell>
                            <ux-table-cell class="py-[0.3rem]" width="125">
                                <span if.bind="performance.type === DeclarationPerformanceTypes.Debit" class="text-green-600"> ${performance.totalInclVat | currency:false} </span>
                                <span else class="text-red-600"> ${(0 - performance.totalInclVat) | currency:false} </span>
                            </ux-table-cell>
                            <ux-table-cell class="py-[0.3rem]" width="225">
                                <ux-badge type="${performance.status | declarationPerformanceStatusToColor}">
                                    <span>${performance.status | declarationPerformanceStatusToString | t}</span>
                                </ux-badge>
                            </ux-table-cell>
                        </ux-table-row>
                    </template>
                    <ux-table-row lock="false" style="height: 100%" if.bind="noResults && baseLoaded && performances.length === 0">
                        <div class="flex flex-col items-center justify-center flex-1 h-full">
                            <span class="text-sm" t="translation:global.labels.no-results"></span>
                        </div>
                    </ux-table-row>
                </ux-table-body>
                <ux-table-body else>
                    <ux-table-row repeat.for="i of 10">
                        <ux-table-cell>
                            <ux-skeleton show-background.bind="false" type="container">
                                <div class="flex flex-col w-full px-1 space-y-1">
                                    <ux-skeleton type="line"></ux-skeleton>
                                    <ux-skeleton type="line" padding="pr-2"></ux-skeleton>
                                </div>
                            </ux-skeleton>
                        </ux-table-cell>
                        <ux-table-cell width="300">
                            <ux-skeleton show-background.bind="false" type="container">
                                <div class="flex flex-col w-full px-1 space-y-1">
                                    <ux-skeleton type="line"></ux-skeleton>
                                    <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                                </div>
                            </ux-skeleton>
                        </ux-table-cell>
                        <ux-table-cell width="150">
                            <ux-skeleton show-background.bind="false" type="container">
                                <div class="flex flex-col w-full px-1 space-y-1">
                                    <ux-skeleton type="line"></ux-skeleton>
                                    <ux-skeleton type="line" padding="pr-2"></ux-skeleton>
                                </div>
                            </ux-skeleton>
                        </ux-table-cell>
                        <ux-table-cell width="150">
                            <ux-skeleton show-background.bind="false" type="container">
                                <div class="flex flex-col w-full px-1 space-y-1">
                                    <ux-skeleton type="line"></ux-skeleton>
                                    <ux-skeleton type="line" padding="pr-6"></ux-skeleton>
                                </div>
                            </ux-skeleton>
                        </ux-table-cell>
                        <ux-table-cell width="75"></ux-table-cell>
                    </ux-table-row>
                </ux-table-body>
            </ux-table>
            <div class="sticky bottom-0 flex h-[63px] justify-between gap-x-2 border-t border-gray-200 bg-white px-5 py-3">
                <div class="flex items-center flex-1 gap-2">
                    <div class="flex gap-2">
                        <ux-button click.trigger="convertToMessage()" if.bind="selection.length > 0" padding.bind="false" color="primary">
                            <div class="flex h-[38px] items-center gap-1 px-2 py-1">
                                <span t="translation:partial-views.declaration-performances.buttons.convert-to-message"> </span>
                                <span>(${selection.length})</span>
                            </div>
                        </ux-button>
                        <ux-button click.trigger="openFilters()" padding.bind="false" mode="outline" color="dark">
                            <div class="flex size-[38px] items-center justify-center">
                                <svg if.bind="pageState.values.hasFilters" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="text-red-500 size-4">
                                    <path
                                        fill-rule="evenodd"
                                        d="M3.792 2.938A49.069 49.069 0 0 1 12 2.25c2.797 0 5.54.236 8.209.688a1.857 1.857 0 0 1 1.541 1.836v1.044a3 3 0 0 1-.879 2.121l-6.182 6.182a1.5 1.5 0 0 0-.439 1.061v2.927a3 3 0 0 1-1.658 2.684l-1.757.878A.75.75 0 0 1 9.75 21v-5.818a1.5 1.5 0 0 0-.44-1.06L3.13 7.938a3 3 0 0 1-.879-2.121V4.774c0-.897.64-1.683 1.542-1.836Z"
                                        clip-rule="evenodd" />
                                </svg>
                                <svg else class="size-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 0 1-.659 1.591l-5.432 5.432a2.25 2.25 0 0 0-.659 1.591v2.927a2.25 2.25 0 0 1-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 0 0-.659-1.591L3.659 7.409A2.25 2.25 0 0 1 3 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0 1 12 3Z" />
                                </svg>
                            </div>
                        </ux-button>
                    </div>
                    <div class="flex flex-wrap flex-1 gap-2">
                        <template-filter
                            repeat.for="item of pageState.values.filters.practitioners"
                            value.bind="item.value"
                            t="[key]translation:global.labels.practitioner"
                            type="practitioners"
                            index.bind="$index"
                            on-remove.bind="removeFilter">
                        </template-filter>
                        <template-filter
                            repeat.for="item of pageState.values.filters.invoices"
                            value.bind="item.value"
                            t="[key]translation:global.labels.invoice"
                            type="invoices"
                            index.bind="$index"
                            on-remove.bind="removeFilter">
                        </template-filter>
                        <template-filter
                            repeat.for="item of pageState.values.filters.declarationSendMethods"
                            value.bind="item.value"
                            t="[key]translation:global.labels.method"
                            type="declarationSendMethods"
                            index.bind="$index"
                            on-remove.bind="removeFilter">
                        </template-filter>
                        <template-filter
                            repeat.for="item of pageState.values.filters.declarations"
                            value.bind="item.value"
                            t="[key]translation:global.labels.declaration"
                            type="declarations"
                            index.bind="$index"
                            on-remove.bind="removeFilter">
                        </template-filter>
                        <template-filter
                            repeat.for="item of pageState.values.filters.patients"
                            value.bind="item.value"
                            t="[key]translation:global.labels.patient"
                            type="patients"
                            index.bind="$index"
                            on-remove.bind="removeFilter">
                        </template-filter>
                        <template-filter
                            repeat.for="item of pageState.values.filters.performanceStatuses"
                            value.bind="item.value"
                            t="[key]translation:global.labels.status"
                            type="performanceStatuses"
                            index.bind="$index"
                            on-remove.bind="removeFilter">
                        </template-filter>
                        <template-filter
                            repeat.for="item of pageState.values.filters.performanceTypes"
                            value.bind="item.value"
                            t="[key]translation:global.labels.type"
                            type="performanceTypes"
                            index.bind="$index"
                            on-remove.bind="removeFilter">
                        </template-filter>
                        <template-filter
                            repeat.for="item of pageState.values.filters.insurers"
                            value.bind="item.value"
                            t="[key]translation:global.labels.insurer"
                            type="insurers"
                            index.bind="$index"
                            on-remove.bind="removeFilter">
                        </template-filter>
                        <template-filter
                            if.bind="pageState.values.filters.start"
                            value="${pageState.values.filters.start | date:'dd-MM-yyyy'}"
                            t="[key]translation:global.labels.start"
                            type="start"
                            replace.bind="false"
                            index.bind="$index"
                            on-remove.bind="removeFilter">
                        </template-filter>
                        <template-filter
                            if.bind="pageState.values.filters.end"
                            value="${pageState.values.filters.end | date:'dd-MM-yyyy'}"
                            t="[key]translation:global.labels.end"
                            type="end"
                            replace.bind="false"
                            index.bind="$index"
                            on-remove.bind="removeFilter">
                        </template-filter>
                    </div>
                </div>
                <div class="flex items-center justify-end text-sm gap-x-1">
                    <p class="font-medium">${performances.length}</p>
                    <p class="text-gray-700" t="translation:global.labels.from"></p>
                    <p class="font-medium">${total}</p>
                </div>
            </div>
        </div>
    </section>
</template>
