import { GetMedicalRecordRegistrationResponse, GetMedicalRecordResponse, MedicalTherapyPlanItem, MedicalTherapyPlanItemTypes } from '@wecore/sdk-healthcare';
import { isDefined, isNotDefined } from '@wecore/sdk-utilities';
import { IEventAggregator, bindable, containerless, inject } from 'aurelia';
import { CustomEvents } from '../../../../../../../infra/events';
import { generateColumns, getSettingsId } from '../../../../../../../infra/utilities';
import { FlattenedExaminationStep } from '../../../../../../../models/flattened-examination-step';
import { SelectedFile } from '../../../../../../../models/selected-file';
import { StepState } from '../../../../../../../models/step-state';

@containerless
@inject(IEventAggregator)
export class TemplateTherapyCategory {
    @bindable() public flattened: FlattenedExaminationStep[];
    @bindable() public record: GetMedicalRecordResponse;
    @bindable() public registration: GetMedicalRecordRegistrationResponse;
    @bindable() public registrations: { [key: string]: GetMedicalRecordRegistrationResponse };
    @bindable() public item: MedicalTherapyPlanItem;
    @bindable() public category: MedicalTherapyPlanItem;
    @bindable() public validation: any;
    @bindable() public states: { [key: string]: StepState };
    @bindable() public workspace: string;
    @bindable() public language: string;
    @bindable() public xScrollContainer: string;
    @bindable() public onFileSelected: (file: SelectedFile) => void;
    @bindable() public onFileRemoved: (file: SelectedFile) => void;
    @bindable() public loading: (show: boolean) => void;

    public columns: string;
    public MedicalTherapyPlanItemTypes: typeof MedicalTherapyPlanItemTypes = MedicalTherapyPlanItemTypes;
    public shouldBeDisplayed: boolean = false;
    
    private subscriptions: any[] = [];
    public container: HTMLDivElement;
    public grid: HTMLDivElement;

    public constructor(
        private readonly events: IEventAggregator //
    ) {}

    public attached(): void {
        this.columns = generateColumns(
            this.registration.therapyPlan.value.flow.breakpoints?.filter((x) => x.id === getSettingsId(this.item)) || [] //
        );
        if (isNotDefined(this.item.attributes)) this.item.attributes = {};
        if (isNotDefined(this.states[this.item.id]))
            this.states[this.item.id] = new StepState({
                stepId: this.item.id,
                expanded: false,
                answered: false
            });

        // Wait for all steps to be rendered.
        setTimeout(() => {
            const connected = this.registration.therapyPlan.value.flow.connectedCategories.filter((x) => x.key === getSettingsId(this.item));
            for (const item of connected) {
                const flatOfElementToObserve = this.flattened.find((x) => x.item.copiedFrom === item.value);
                // We are going to observe the grid elements of the connected category.
                // Note that we are not observing the container element of the current category
                // because we are going to change the heights of the container which can
                // retrigger the observer callback, which can cause an infinite loop.
                const elementToObserve = document.getElementById(`therapy-plan-category-grid-${flatOfElementToObserve.item.copiedFrom}-${this.registration.id}`);
                // Get the viewmodel of the connect step.
                const viewModelOfElementToObserve = this.states[flatOfElementToObserve.item.id].model as unknown as TemplateTherapyCategory;
                const observer = new ResizeObserver((entries) => {
                    // Request a frame to prevent resize loops
                    window.requestAnimationFrame((): void | undefined => {
                        if (!Array.isArray(entries) || !entries.length) {
                            return;
                        }
                        if (isNotDefined(this.container) || isNotDefined(this.grid)) return;
                        // Get the heighest height of all the observed elements.
                        const height = Math.max(...entries.map((x) => x.contentRect.height));

                        if (height === 0) {
                            this.matchContainerSizeToGridSize();
                            viewModelOfElementToObserve?.matchContainerSizeToGridSize();
                        } else if (height > this.grid.clientHeight) {
                            // Height is of the observered element is bigger than the grid height.
                            this.setHeight(height);
                            viewModelOfElementToObserve?.matchContainerSizeToGridSize();
                        } else if (height === this.grid.clientHeight) {
                            // The height of the observered element is the same as the grid height.
                            this.setHeight(height);
                            viewModelOfElementToObserve?.matchContainerSizeToGridSize();
                        } else if (this.grid.clientHeight > height) {
                            // The height of the grid is bigger than the height of the observered element.
                            viewModelOfElementToObserve?.setHeight(this.grid.clientHeight);
                        }
                    });
                });

                if (isDefined(elementToObserve)) observer.observe(elementToObserve);
                this.states[this.item.id].observer = observer;
            }
        });

        this.subscriptions = [
            ...(this.subscriptions ?? []),
            this.events.subscribe(CustomEvents.ExaminationStepAnswerChanged, () => this.evaluateSettings()),
            this.events.subscribe(CustomEvents.ExaminationTherapyCategoryChangedExpanded, (data: { stepId: string; expand: boolean; container: string }) => {
                const shouldChange =
                    // Only categories in the same container should be affected.
                    data.container === this.registration.id &&
                    this.registration.therapyPlan.value.flow.connectedCategories //
                        .filter((x) => x.key === getSettingsId(this.item))
                        .some((x) => x.value === data.stepId);
                if (shouldChange) this.states[this.item.id].expanded = data.expand;
            })
        ];

        // Evaluate the requirements on load of the therapy step.
        this.evaluateSettings();
    }

    public detaching(): void {
        this.subscriptions.forEach((x) => x.dispose());
    }

    public collapseOrExpand(): void {
        this.states[this.item.id].expanded = !this.states[this.item.id].expanded;
        this.events.publish(CustomEvents.ExaminationTherapyCategoryChangedExpanded, {
            stepId: getSettingsId(this.item), //
            expand: this.states[this.item.id].expanded,
            // Make sure we only affect categories in the same container.
            container: this.registration.id
        });
    }

    public setHeight(height: number): void {
        if (isNotDefined(this.container)) return;
        if (height === 0) return;
        this.container.style.height = `${height + (height > 0 ? 4 : 0)}px`;
    }

    private evaluateSettings(): void {
        const flattened = this.flattened.find((x) => x.item.id === this.item.id);
        this.shouldBeDisplayed = flattened?.isVisible ?? true;

        // TODO: Delete step values of the steps below this category (including any files)?
    }

    private matchContainerSizeToGridSize(): void {
        // Allow the step some time to resize and render its DOM content so that we get the correct sizes.
        if (isNotDefined(this.container) || isNotDefined(this.grid)) return;
        if (this.grid.offsetHeight === 0) return;
        // Make sure the content height is the same as the grid height (dynamic)
        // if (this.container.offsetHeight < this.grid.offsetHeight || force) {
        // Set the height of the container to the height of the grid.
        this.container.style.height = `${this.grid.offsetHeight + (this.grid.offsetHeight > 0 ? 4 : 0)}px`;
    }
}
