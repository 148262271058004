import { I18N } from '@aurelia/i18n';
import { BlobStorageAttachment } from '@wecore/sdk-attachments';
import {
    CreateMedicalRecordRegistrationBatchRequest,
    DifferentialDiagnosisEntityReference,
    GetMedicalRecordRegistrationResponse,
    GetMedicalRecordResponse,
    GetMedicalTherapyResponse,
    GetMedicalTreatmentProtocolResponse,
    GetPatientResponse,
    InputTypes,
    MedicalExaminationFlow,
    MedicalExaminationTemplateItemStep,
    MedicalRecordEntityReference,
    MedicalRecordRegistrationEntityReference,
    MedicalRecordRegistrationTypes,
    MedicalRecordRegistrationsApiClient,
    MedicalRecordResult,
    MedicalRecordResultReference,
    MedicalRecordResultStatuses,
    MedicalResult,
    MedicalTherapiesApiClient,
    MedicalTherapyEntityReference,
    MedicalTherapyExecution,
    MedicalTherapyExecutionItem,
    MedicalTherapyExecutionItemTypes,
    MedicalTherapyPlan,
    MedicalTherapyPlanContainer,
    MedicalTherapyPlanFlow,
    MedicalTherapyPlanItem,
    MedicalTherapyPlanItemTypes,
    MedicalTreatmentProtocolEntityReference,
    MedicalTreatmentProtocolsApiClient,
    MedicalWidgetTypes,
    StringTherapyDefaultValueKeyValuePair,
    UpdateMedicalRecordRegistrationBatchRequest,
    UpdateMedicalRecordRegistrationBatchRequestItem,
    WidgetResult,
    WidgetResultTypes
} from '@wecore/sdk-healthcare';
import { guid, isDefined, isNotDefined } from '@wecore/sdk-utilities';
import { IDisposable, IEventAggregator, bindable, containerless, inject } from 'aurelia';
import { PartialViewResults } from '../../../../../enums/partial-view-results';
import { CustomEvents } from '../../../../../infra/events';
import { PartialViews } from '../../../../../infra/partial-views';
import {
    cleanActionOrTherapyResultsAndAttributes,
    cleanTranslatables,
    cloneDeep,
    setActionStepOrTherapyStepValidation,
    setExpectedValuesForActionOrTherapy,
    setTranslation
} from '../../../../../infra/utilities';
import { ConfirmationOptions } from '../../../../../models/confirmation-options';
import { FlattenedExaminationStep } from '../../../../../models/flattened-examination-step';
import { PartialView } from '../../../../../models/partial-view';
import { SelectedFile } from '../../../../../models/selected-file';
import { StepState } from '../../../../../models/step-state';
import { ViewOptions } from '../../../../../models/view-options';
import { WidgetRegistration } from '../../../../../models/widget-registration';
import { ModalService } from '../../../../../services/service.modals';

@containerless
@inject(I18N, MedicalTreatmentProtocolsApiClient, MedicalTherapiesApiClient, IEventAggregator, ModalService, MedicalRecordRegistrationsApiClient)
export class WidgetExaminationTreatmentPlan {
    @bindable() public flattened: FlattenedExaminationStep[];
    @bindable() public registration: GetMedicalRecordRegistrationResponse;
    @bindable() public registrations: { [key: string]: GetMedicalRecordRegistrationResponse };
    @bindable() public states: { [key: string]: StepState };
    @bindable() public xScrollContainer: string;
    @bindable() public flow: MedicalExaminationFlow;
    @bindable() public record: GetMedicalRecordResponse;
    @bindable() public step: MedicalExaminationTemplateItemStep;
    @bindable() public patient: GetPatientResponse;
    @bindable() public required: boolean;
    @bindable() public validation: any;
    @bindable() public language: string;
    @bindable() public workspace: string;
    @bindable() public widgets: WidgetRegistration[] = [];
    @bindable() public manageTranslations: (translations: any, callback: (translations: any) => void, type: 'textarea' | 'input', required: boolean) => void;
    @bindable() public addPartial: (partial: PartialView, options: ViewOptions) => Promise<void>;
    @bindable() public onFileSelected: (file: SelectedFile) => void;
    @bindable() public onFileRemoved: (file: SelectedFile) => void;
    @bindable() public loading: (show: boolean) => void;

    public hasWorkingDiagnoses: boolean;
    public placeholder: string;
    public planStates: {
        [key: string]: {
            tempId: string;
            isNew: boolean;
        };
    } = {};
    public protocolSelection: {
        result: MedicalRecordResult;
        protocols: {
            value: GetMedicalTreatmentProtocolResponse;
            therapies: GetMedicalTherapyResponse[];
        }[];
    }[] = [];
    public MedicalTherapyPlanItemTypes: typeof MedicalTherapyPlanItemTypes = MedicalTherapyPlanItemTypes;
    public selection: GetMedicalRecordRegistrationResponse[] = [];
    public baseLoaded: boolean = false;
    public starting: boolean = false;
    public selectedAll: boolean = false;

    private subscriptions: IDisposable[];
    private itemsToDelete: GetMedicalRecordRegistrationResponse[] = [];
    private executionsToCreate: string[] = [];

    public constructor(
        private readonly t: I18N, //
        private readonly protocolsApi: MedicalTreatmentProtocolsApiClient,
        private readonly therapiesApi: MedicalTherapiesApiClient,
        private readonly events: IEventAggregator,
        private readonly modalService: ModalService,
        private readonly registrationsApi: MedicalRecordRegistrationsApiClient
    ) {}

    public async bound(): Promise<void> {
        if (isNotDefined(this.registration)) return;

        if (isNotDefined(this.record.treatment.plans)) this.record.treatment.plans = [];
        // Set unused labels.
        for (const plan of this.record.treatment.plans) {
            if (isNotDefined(this.registrations[plan.id].therapyPlan.label)) this.registrations[plan.id].therapyPlan.label = setTranslation({}, this.language);
            this.planStates[plan.id] = {
                tempId: null,
                isNew: false
            };
        }

        this.registration.widget.result.type = WidgetResultTypes.Dynamic;
        this.widgets.push(
            new WidgetRegistration({
                stepId: this.step.id,
                type: MedicalWidgetTypes.ExaminationTreatmentPlan,
                onSave: async (): Promise<void> => {
                    /// START: PLANS ////

                    // Clean unused labels and values.
                    for (const plan of this.record.treatment.plans) {
                        cleanTranslatables(['label'], this.registrations[plan.id].therapyPlan, 1);
                        if (isNotDefined(this.registrations[plan.id]) || isNotDefined(this.registrations[plan.id].therapyPlan)) continue;
                        cleanActionOrTherapyResultsAndAttributes(this.registrations[plan.id].therapyPlan.value.stepsToTake);
                    }

                    const itemsToCreate = this.record.treatment.plans.filter((p) => this.planStates[p.id].isNew);
                    const itemsToUpdate = this.record.treatment.plans.filter((p) => !this.planStates[p.id].isNew);

                    const [createdPlans, createdExecutions] = await Promise.all([
                        // Create the plans in one batch.
                        this.registrationsApi.createBatch(
                            this.workspace,
                            new CreateMedicalRecordRegistrationBatchRequest({
                                useIdAsStepId: true,
                                items: itemsToCreate.map((p) => this.registrations[p.id])
                            })
                        ),
                        // Create the executions in one batch.
                        this.registrationsApi.createBatch(
                            this.workspace,
                            new CreateMedicalRecordRegistrationBatchRequest({
                                useIdAsStepId: true,
                                items: this.executionsToCreate.map((id) => this.registrations[id])
                            })
                        ),
                        // Update the plans in one batch.
                        this.registrationsApi.updateBatch(
                            this.workspace,
                            new UpdateMedicalRecordRegistrationBatchRequest({
                                items: itemsToUpdate.map(
                                    (p) =>
                                        new UpdateMedicalRecordRegistrationBatchRequestItem({
                                            id: p.id,
                                            body: this.registrations[p.id]
                                        })
                                )
                            })
                        ),
                        Promise.all(this.itemsToDelete.map((x) => this.registrationsApi.delete(x.id, this.workspace)))
                    ]);

                    // Replace all temp items that are in the plan with the
                    // items that just got created, this because a new ID is set by the API for each item.
                    for (const plan of itemsToCreate) {
                        const index = this.record.treatment.plans.findIndex((p) => p.id === plan.id);
                        const created = createdPlans.find((x) => {
                            if (x.therapyPlan.results.empty()) return x.therapy.id === this.registrations[plan.id].therapy.id;
                            else return x.therapy.id === this.registrations[plan.id].therapy.id && x.therapyPlan.results[0].resultId === this.registrations[plan.id].therapyPlan.results[0].resultId;
                        });

                        // Update the ID in the validation item
                        const validationIndex = this.validation.plans.findIndex((x: any) => x.id === plan.id);
                        if (validationIndex > -1) this.validation.plans[validationIndex].id = created.id;

                        // Clean up old registration ID and add new one.
                        delete this.registrations[plan.id];
                        this.registrations[created.id] = created;

                        this.record.treatment.plans[index] = new MedicalRecordRegistrationEntityReference({
                            id: created.id,
                            translations: created.therapy.translations
                        });

                        // Because the plan can also have linked exectutions we need to update the
                        // reference of the plan in the execution.
                        const execution = this.record.treatment.executions.find((x) => this.registrations[x.id].therapyExecution.planId === plan.id);
                        if (isDefined(execution)) {
                            // If we found the execution, update the reference.
                            this.registrations[execution.id].therapyExecution.planId = created.id;
                        }
                    }

                    /// END: PLANS ////

                    /// START: EXECUTIONS ////
                    for (const id of this.executionsToCreate) {
                        cleanTranslatables(['label'], this.registrations[id].therapyExecution, 1);
                        if (isNotDefined(this.registrations[id]) || isNotDefined(this.registrations[id].therapyExecution)) continue;
                        cleanActionOrTherapyResultsAndAttributes(this.registrations[id].therapyExecution.value.stepsToTake);
                    }

                    // Replace all temp executions that are in the plan with the
                    // items that just got created, this because a new ID is set by the api for each item.
                    for (const itemId of this.executionsToCreate) {
                        const index = this.record.treatment.executions.findIndex((x) => x.id === itemId);
                        const created = createdExecutions.find((x) => {
                            if (x.therapyExecution.results.empty()) return x.therapy.id === this.registrations[itemId].therapy.id;
                            else
                                return (
                                    x.therapy.id === this.registrations[itemId].therapy.id && //
                                    x.therapyExecution.results.some((r) =>
                                        this.registrations[itemId].therapyExecution.results //
                                            .some((rr) => rr.resultId === r.resultId)
                                    )
                                );
                        });

                        // Update the ID in the validation item
                        const validationIndex = this.validation.executions.findIndex((x: any) => x.id === itemId);
                        if (validationIndex > -1) this.validation.executions[validationIndex].id = created.id;

                        // Clean up old registration ID and add new one.
                        delete this.registrations[itemId];
                        this.registrations[created.id] = created;

                        // Because this newly execution can linked by a new evaluation, we need to update the reference
                        // of the execution in the evaluation. First find the linked evaluation.
                        const evaluation = this.record.treatment.evaluations.find((x) => this.registrations[x.id].therapyEvaluation.executionId == itemId);
                        if (isDefined(evaluation)) {
                            // If we found the evaluation, update the reference.
                            this.registrations[evaluation.id].therapyEvaluation.executionId = created.id;
                        }

                        this.record.treatment.executions[index] = new MedicalRecordRegistrationEntityReference({
                            id: created.id,
                            translations: created.therapy.translations
                        });
                    }

                    /// END: EXECUTIONS ////

                    // Reset the executions items to create after creating them.
                    this.executionsToCreate = [];
                },
                validate: (result: WidgetResult, validation: any): boolean => {
                    return true;
                },
                refresh: async (): Promise<void> => {},
                onFileUploaded: async (_: BlobStorageAttachment): Promise<void> => {}
            })
        );

        this.subscriptions = [
            ...(this.subscriptions ?? []),
            this.events.subscribe(CustomEvents.ExaminationResultsChanged, (result: MedicalRecordResult) => {
                // If a status changed to something else then working diagnosis, remove it from the list.
                if (isDefined(result) && result.status !== MedicalRecordResultStatuses.WorkingDiagnosis) {
                    const index = this.protocolSelection.findIndex((x) => x.result.differentialDiagnosis.id === result.differentialDiagnosis.id);
                    if (index > -1) this.protocolSelection.splice(index, 1);
                    return;
                }
                // Check if there are new working diagnosis.
                this.fetchProtocols();
            })
        ];

        this.fetchProtocols().then(() => {
            this.baseLoaded = true;
        });
    }

    public detaching(): void {
        this.subscriptions.forEach((x) => x.dispose());
    }

    public selectProtocol(
        protocol: {
            value: GetMedicalTreatmentProtocolResponse;
            therapies: GetMedicalTherapyResponse[];
        },
        result: MedicalRecordResult
    ): void {
        for (const therapy of protocol.therapies) {
            const index = this.record.treatment.plans.findIndex((p) => this.registrations[p.id].therapy.id === therapy.id && this.registrations[p.id].therapyPlan.results[0].resultId === result.id);

            // We can not add therapies that are already started.
            if (this.registrations[this.record.treatment.plans[index]?.id]?.therapyPlan.startedTreatment ?? false) continue;

            // The therapy already exists in the list.
            if (index > -1) {
                const id = this.record.treatment.plans[index].id;
                // If this therapy comes from a different protocol, add the protocol to the list of the existing therapy.
                // This way we can track which protocols were selected for the therapy.
                const protocolIndex = this.registrations[id].therapyPlan.protocols.findIndex((x) => x.id === protocol.value.id);
                if (protocolIndex === -1) {
                    const item = new MedicalTreatmentProtocolEntityReference({
                        id: protocol.value.id,
                        translations: protocol.value.name
                    });
                    this.registrations[id].therapyPlan.protocols.push(item);
                }
                // If the therapy already exists, merge the default values of the
                // steps that can have muliple answers (multi-select).
                // If the selected protocol does not have default values, stop here.
                if (isNotDefined(protocol.value.defaultTherapyValues)) continue;
                // Fetch the default values for the current therapy.
                const def = protocol.value.defaultTherapyValues.find((x) => x.therapy.id === therapy.id);
                // If we do not have default values item for the current therapy, there is no
                // need to merge anything. So stop here.
                if (isNotDefined(def)) continue;
                // If the default values item for the current therapy does not have
                // any values, no need to merge anything so we can stop here.
                if (isNotDefined(def.defaultValues) || def.defaultValues.empty()) continue;

                const flattened = this.flattenPlan(therapy.plan, def.defaultValues);
                const mergeInto = (items: MedicalTherapyPlanItem[]) => {
                    for (const planItem of items) {
                        if (planItem.type === MedicalTherapyPlanItemTypes.Category) mergeInto(planItem.category.stepsToTake);
                        else {
                            if (planItem.step.inputType !== InputTypes.FreeEntry && planItem.step.inputType !== InputTypes.Selector) continue;
                            // Find the corrosponding step in the flattened array.
                            // We can be certain that the steps are the same by matching them
                            // by the original ID located in the 'copiedFrom' property.
                            const flat = flattened.find((x) => x.stepId === planItem.copiedFrom);
                            if (isDefined(flat) && flat.defaultValues.any()) {
                                // If the step exists in the flattened array, merge the results.
                                planItem.step.results = [
                                    ...planItem.step.results, //
                                    ...flat.defaultValues
                                        .filter((x: StringTherapyDefaultValueKeyValuePair) => isDefined(x.value) && x.value.results?.any())
                                        .map((x: StringTherapyDefaultValueKeyValuePair) => x.value.results)
                                        .selectMany((x: MedicalResult[]) => x)
                                ]
                                    .orderBy((x: MedicalResult) => x.value)
                                    .distinct((x: MedicalResult) => x.value);
                            }
                        }
                    }
                };
                mergeInto(this.registrations[id].therapyPlan.value.stepsToTake);
            } else {
                const def = protocol.value.defaultTherapyValues?.find((x) => x.therapy.id === therapy.id)?.defaultValues ?? [];
                // Note that this is a TEMP ID, it will only be used until the record is saved.
                // Then it will be replaced with the database ID of the registration.
                // See the 'onSave' method of the widget.
                const id = guid();
                const item = new GetMedicalRecordRegistrationResponse({
                    id,
                    record: new MedicalRecordEntityReference({
                        id: this.record.id,
                        translations: this.record.examination.name
                    }),
                    stepId: id,
                    type: MedicalRecordRegistrationTypes.TherapyPlan,
                    therapy: new MedicalTherapyEntityReference({
                        id: therapy.id,
                        translations: therapy.name
                    }),
                    therapyPlan: new MedicalTherapyPlanContainer({
                        prices: therapy.prices,
                        codes: therapy.codes,
                        label: setTranslation({}, this.language),
                        value: isDefined(therapy.plan)
                            ? (this.prepare(therapy.plan) as MedicalTherapyPlan)
                            : new MedicalTherapyPlan({
                                  stepsToTake: [],
                                  flow: new MedicalTherapyPlanFlow({
                                      required: [],
                                      breakpoints: [],
                                      visibilityRequirements: [],
                                      connectedCategories: [],
                                      connectedSteps: []
                                  })
                              }),
                        results: [
                            new MedicalRecordResultReference({
                                resultId: result.id,
                                differentialDiagnosis: new DifferentialDiagnosisEntityReference({
                                    id: result.differentialDiagnosis.id,
                                    translations: result.differentialDiagnosis.name
                                })
                            })
                        ],
                        addedManually: false,
                        defaultValues: cloneDeep(def),
                        protocols: [
                            new MedicalTreatmentProtocolEntityReference({
                                id: protocol.value.id,
                                translations: protocol.value.name
                            })
                        ]
                    })
                });
                // State for the entire plan.
                this.planStates[item.id] = { tempId: item.id, isNew: true };
                // Validation for the entire plan.
                const steps = setActionStepOrTherapyStepValidation(item.therapyPlan.value.stepsToTake, []);
                this.validation.plans.push({ id: item.id, valid: true, steps });
                // The registration object to use when storing values for the plan.
                this.registrations[item.id] = item;
                // Add the plan to the record.
                this.record.treatment.plans.push(
                    new MedicalRecordRegistrationEntityReference({
                        id: item.id,
                        translations: item.therapy.translations
                    })
                );
            }
        }

        // Let the record know that we added new items, so the flattened list needs to be updated.
        this.events.publish(CustomEvents.ExaminationReFlatten, { triggeredByAnswer: false });
    }

    public handleDuplicate = async (item: GetMedicalRecordRegistrationResponse): Promise<void> => {
        const therapy = await this.therapiesApi.getById(item.therapy.id, this.workspace);

        const clone = cloneDeep(item);
        // Reset some properties.
        // Note that this is a TEMP ID, it will only be used until the record is saved.
        // Then it will be replaced with the database ID of the registration.
        // See the 'onSave' method of the widget.
        clone.id = guid();
        clone.stepId = clone.id;
        clone.therapyPlan.label = setTranslation({}, this.language);
        clone.therapyPlan.protocols = [];
        // Do NOT reset the results so that it copies the new item
        // to the same medical record result.
        // clone.therapyPlan.results = [];
        clone.therapyPlan.addedManually = true;
        // Make sure to get the most resent version of the therapy plan.
        // and also make sure to reset the results (steps etc.).
        clone.therapyPlan.value = this.prepare(therapy.plan) as MedicalTherapyPlan;

        this.planStates[clone.id] = { tempId: clone.id, isNew: true };
        this.registrations[clone.id] = clone;
        this.record.treatment.plans.push(
            new MedicalRecordRegistrationEntityReference({
                id: clone.id,
                translations: clone.therapy.translations
            })
        );

        const steps = setActionStepOrTherapyStepValidation(clone.therapyPlan.value.stepsToTake, []);
        this.validation.plans.push({
            id: clone.id,
            valid: true,
            steps
        });

        // Let the record know that we added new items, so the flattened list needs to be updated.
        this.events.publish(CustomEvents.ExaminationReFlatten, { triggeredByAnswer: false });
    };

    public handleDeleteTherapy = async (item: GetMedicalRecordRegistrationResponse): Promise<void> => {
        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('translation:components.widgets.examination-treatment-plan.questions.delete.title'),
                message: this.t.tr('translation:components.widgets.examination-treatment-plan.questions.delete.message'),
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        // Delete from group if present.
                        const index = this.record.treatment.plans.findIndex((x) => x.id === item.id);
                        if (index > -1) {
                            // Mark for deletion.
                            this.itemsToDelete.push(this.registrations[this.record.treatment.plans[index].id]);
                            delete this.registration[this.record.treatment.plans[index].id];

                            // Delete from plan.
                            this.record.treatment.plans.splice(index, 1);
                        }
                        // Delete from the selected if present.
                        const selectionIndex = this.selection.findIndex((x) => x.id === item.id);
                        if (selectionIndex > -1) this.selection.splice(selectionIndex, 1);
                    }
                }
            })
        );
    };

    public handleConnectTherapy = async (item: GetMedicalRecordRegistrationResponse): Promise<void> => {
        await this.addPartial(
            PartialViews.ConnectTherapies.with({ results: item.therapyPlan.results, allResults: this.record.results }).whenClosed(async (result: PartialViewResults, data: MedicalRecordResult[]) => {
                if (result === PartialViewResults.Ok) {
                    item.therapyPlan.results = data.map(
                        (result) =>
                            new MedicalRecordResultReference({
                                resultId: result.id,
                                differentialDiagnosis: new DifferentialDiagnosisEntityReference({
                                    id: result.differentialDiagnosis.id,
                                    translations: result.differentialDiagnosis.name
                                })
                            })
                    );

                    // Lets check if we have any executions that are linked to the current therapy plan.
                    const executions = this.record.treatment.executions.filter((x) => this.registrations[x.id].therapyExecution.planId === item.id);
                    for (const execution of executions) {
                        // If we have any executions, we need to update the linked results.
                        this.registrations[execution.id].therapyExecution.results = item.therapyPlan.results;
                        // Lets check if we have any evaluations that are linked to the current execution.
                        const evaluations = this.record.treatment.evaluations.filter((x) => this.registrations[x.id].therapyEvaluation.executionId === execution.id);
                        for (const evaluation of evaluations) {
                            // If we have any evaluations, we need to update the linked results.
                            this.registrations[evaluation.id].therapyEvaluation.results = item.therapyPlan.results;
                        }
                    }

                    // Force the plans to re-render
                    this.record.treatment.plans = [
                        ...(this.record.treatment.plans.length > 0 ? [this.record.treatment.plans.shift()] : []), //
                        ...cloneDeep(this.record.treatment.plans)
                    ];

                    // Let the record know that we added new items, so the flattened list needs to be updated.
                    this.events.publish(CustomEvents.ExaminationReFlatten, { triggeredByAnswer: false });
                }
            }),
            new ViewOptions({
                scrollToView: true,
                markItem: true,
                replace: true
            })
        );
    };

    public async addTherapies(): Promise<void> {
        await this.addPartial(
            PartialViews.AddTherapiesToExamination.with({ results: this.record.results }).whenClosed(
                async (result: PartialViewResults, data: { therapy: GetMedicalTherapyResponse; results: MedicalRecordResult[] }[]) => {
                    if (result === PartialViewResults.Ok) {
                        for (const piece of data) {
                            // Note that this is a TEMP ID, it will only be used until the record is saved.
                            // Then it will be replaced with the database ID of the registration.
                            // See the 'onSave' method of the widget.
                            const id = guid();
                            const item = new GetMedicalRecordRegistrationResponse({
                                id,
                                stepId: id,
                                record: new MedicalRecordEntityReference({
                                    id: this.record.id,
                                    translations: this.record.examination.name
                                }),
                                type: MedicalRecordRegistrationTypes.TherapyPlan,
                                therapy: new MedicalTherapyEntityReference({
                                    id: piece.therapy.id,
                                    translations: piece.therapy.name
                                }),
                                therapyPlan: new MedicalTherapyPlanContainer({
                                    prices: piece.therapy.prices,
                                    codes: piece.therapy.codes,
                                    label: setTranslation({}, this.language),
                                    value: isDefined(piece.therapy.plan)
                                        ? (this.prepare(piece.therapy.plan) as MedicalTherapyPlan)
                                        : new MedicalTherapyPlan({
                                              stepsToTake: [],
                                              flow: new MedicalTherapyPlanFlow({
                                                  required: [],
                                                  breakpoints: [],
                                                  visibilityRequirements: [],
                                                  connectedCategories: [],
                                                  connectedSteps: []
                                              })
                                          }),
                                    results: piece.results.map(
                                        (result) =>
                                            new MedicalRecordResultReference({
                                                resultId: result.id,
                                                differentialDiagnosis: new DifferentialDiagnosisEntityReference({
                                                    id: result.differentialDiagnosis.id,
                                                    translations: result.differentialDiagnosis.name
                                                })
                                            })
                                    ),
                                    addedManually: true,
                                    defaultValues: [],
                                    protocols: []
                                })
                            });

                            this.planStates[item.id] = {
                                tempId: item.id,
                                isNew: true
                            };
                            this.registrations[item.id] = item;
                            const steps = setActionStepOrTherapyStepValidation(item.therapyPlan.value.stepsToTake, []);
                            this.validation.plans.push({
                                id: item.id,
                                valid: true,
                                steps
                            });
                            this.record.treatment.plans.push(
                                new MedicalRecordRegistrationEntityReference({
                                    id: item.id,
                                    translations: item.therapy.translations
                                })
                            );
                        }

                        // Let the record know that we added new items, so the flattened list needs to be updated.
                        this.events.publish(CustomEvents.ExaminationReFlatten, { triggeredByAnswer: false });
                    }
                }
            ),
            new ViewOptions({
                scrollToView: true,
                markItem: true,
                replace: true
            })
        );
    }

    public async mergeTherapies(): Promise<void> {
        if (this.selection.length < 2) return;

        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('translation:components.widgets.examination-treatment-plan.questions.merge.title'),
                message: this.t.tr('translation:components.widgets.examination-treatment-plan.questions.merge.message'),
                type: 'warning',
                btnOk: this.t.tr('translation:components.widgets.examination-treatment-plan.buttons.merge'),
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        const groupedByTherapy = this.selection.groupBy((x: GetMedicalRecordRegistrationResponse) => x.therapy.id);
                        const array = Array.from(groupedByTherapy.entries());
                        for (const [_, items] of array) {
                            // If the group has less then 2 items, continue with the next group.
                            // Since we can't merge a single item.
                            if (items.length < 2) continue;
                            for (let i = 0; i < items.length; i++) {
                                // Skip the first item because we are going to merge the other items into it.
                                if (i === 0) continue;
                                const item = items[i];

                                // Merge the protocols and make sure we don't have any duplicates.
                                // Merge to both registrations and group array.
                                this.registrations[items[0].id].therapyPlan.protocols = [...items[0].therapyPlan.protocols, ...item.therapyPlan.protocols].distinct((x) => x.id);
                                // Also merge the results (result + differential diagnosis) that added this item
                                // and make sure we don't have any duplicates by checking the result ID.
                                // Merge to both registrations and group array.
                                this.registrations[items[0].id].therapyPlan.results = [...items[0].therapyPlan.results, ...item.therapyPlan.results].distinct((x) => x.resultId);
                                // First flatten the current item.
                                const flattened = this.flattenPlan(item.therapyPlan.value);
                                const merge = (items: MedicalTherapyPlanItem[]) => {
                                    for (const planItem of items) {
                                        if (planItem.type === MedicalTherapyPlanItemTypes.Category) merge(planItem.category.stepsToTake);
                                        else {
                                            if (planItem.step.inputType !== InputTypes.FreeEntry && planItem.step.inputType !== InputTypes.Selector) continue;
                                            // Find the corrosponding step in the flattened array.
                                            // We can be certain that the steps are the same by matching them
                                            // by the original ID located in the 'copiedFrom' property.
                                            const flat = flattened.find((x) => x.copiedFrom === planItem.copiedFrom);
                                            if (isDefined(flat)) {
                                                // If the step exists in the flattened array, merge the results.
                                                planItem.step.results = [...planItem.step.results, ...flat.item.step.results].orderBy((x) => x.value).distinct((x) => x.value);
                                            }
                                        }
                                    }
                                };
                                // Make sure to merge the steps of the registrations in the registrations object.
                                merge(this.registrations[items[0].id].therapyPlan.value.stepsToTake);
                                // Delete the merged item
                                const index = this.record.treatment.plans.findIndex((x) => x.id === item.id);
                                if (index > -1) {
                                    // Mark for deletion.
                                    this.itemsToDelete.push(this.registrations[this.record.treatment.plans[index].id]);
                                    delete this.registrations[item.id];
                                    // Delete from plans.
                                    this.record.treatment.plans.splice(index, 1);
                                }
                            }
                        }
                        this.selection = [];
                        this.selectedAll = false;

                        // Let the record know that we added new items, so the flattened list needs to be updated.
                        this.events.publish(CustomEvents.ExaminationReFlatten, { triggeredByAnswer: false });
                    }
                }
            })
        );
    }

    public handleSelectAll(e: Event): void {
        const target = e.target as HTMLInputElement;
        const checked = target.checked;

        if (checked) this.selection = this.record.treatment.plans.filter((p) => !this.registrations[p.id].therapyPlan.startedTreatment).map((p) => this.registrations[p.id]);
        else this.selection = [];
    }

    public handleItemChecked = (): void => {
        this.selectedAll =
            // And all plans are selected.
            this.record.treatment.plans.filter((p) => !this.registrations[p.id].therapyPlan.startedTreatment).every((p) => this.selection.some((x) => x.id === p.id));
    };

    private async fetchProtocols(): Promise<void> {
        this.protocolSelection = [];

        // Fetch all the working diagnosis from the = results.
        const results = this.record.results.filter((x) => x.status === MedicalRecordResultStatuses.WorkingDiagnosis);

        // Check if there are any working diagnosis.
        this.hasWorkingDiagnoses = results.any();
        if (!this.hasWorkingDiagnoses) return;
        // Get the protocols for all the working diagnoses.
        const diagnosisIds = results.map((x) => x.differentialDiagnosis.id);
        const allProtocols = await this.protocolsApi.search(this.workspace, '', 100, 0, undefined, undefined, undefined, diagnosisIds);
        // Get al the therapies for the fetched protocols.
        const therapyIds = allProtocols.data.selectMany((x: GetMedicalTreatmentProtocolResponse) => x.therapies).map((x) => x.therapy.id);
        const allTherapies = await this.therapiesApi.search(this.workspace, '', 500, 0, undefined, undefined, undefined, therapyIds);
        // Loop trough all the working diagnosis.
        for (const result of results) {
            // match the protocols to the current working diagnosis.
            const protocols = allProtocols.data.filter((x) => x.differentialDiagnosis.id === result.differentialDiagnosis.id);
            // Add an item for the current working diagnosis.
            this.protocolSelection.push({
                result,
                protocols: protocols.map((p) => {
                    // Match the therapies to the current protocol.
                    const ids = p.therapies.map((x) => x.therapy.id);
                    return {
                        value: p,
                        therapies: allTherapies.data.filter((x) => ids.includes(x.id))
                    };
                })
            });
        }
    }

    private prepare(plan: MedicalTherapyPlan | MedicalTherapyExecution): MedicalTherapyPlan | MedicalTherapyExecution {
        if (isNotDefined(plan)) return plan;

        // Make sure to clear the references to the plan.
        const copy = cloneDeep(plan);

        const modify = (item: MedicalTherapyPlanItem | MedicalTherapyExecutionItem): void => {
            // Only set the copiedFrom when it is not already set.
            // Because we are going to use this property to match the steps
            // in the flow. The ID in the flow never changes, so this
            // values shouldn't change either.
            if (isNotDefined(item.copiedFrom)) item.copiedFrom = item.id;
            item.id = guid();
        };

        const generateIds = (items: MedicalTherapyPlanItem[] | MedicalTherapyExecutionItem[]) => {
            for (const item of items) {
                if (item.type === MedicalTherapyPlanItemTypes.Category || item.type === MedicalTherapyExecutionItemTypes.Category) {
                    modify(item);
                    generateIds(item.category.stepsToTake);
                } else modify(item);
            }
        };

        // Generate new IDs for each of the steps.
        generateIds(copy.stepsToTake);
        setExpectedValuesForActionOrTherapy(copy.stepsToTake);

        return copy;
    }

    private flattenPlan(plan: MedicalTherapyPlan, defaultValues?: StringTherapyDefaultValueKeyValuePair[]): any[] {
        if (isNotDefined(plan)) return [];

        const flattened = [];
        const flatten = (items: MedicalTherapyPlanItem[]) => {
            for (const item of items) {
                if (item.type === MedicalTherapyPlanItemTypes.Category) {
                    flatten(item.category.stepsToTake);
                } else {
                    if (item.step.inputType !== InputTypes.FreeEntry && item.step.inputType !== InputTypes.Selector) continue;

                    flattened.push({
                        copiedFrom: item.copiedFrom,
                        stepId: item.id,
                        item,
                        defaultValues: defaultValues?.filter((x) => x.key === item.id) ?? []
                    });
                }
            }
        };
        flatten(plan.stepsToTake);

        return flattened;
    }
}
