import {
    GetMedicalRecordRegistrationResponse,
    GetMedicalRecordResponse,
    MedicalExaminationActionFlow,
    MedicalExaminationActionItem,
    MedicalExaminationActionItemTypes,
    MedicalExaminationTemplateItemStep
} from '@wecore/sdk-healthcare';
import { isDefined, isNotDefined } from '@wecore/sdk-utilities';
import { IEventAggregator, bindable, containerless, inject } from 'aurelia';
import { CustomEvents } from '../../../../../../infra/events';
import { generateColumns } from '../../../../../../infra/utilities';
import { FlattenedExaminationStep } from '../../../../../../models/flattened-examination-step';
import { SelectedFile } from '../../../../../../models/selected-file';
import { StepState } from '../../../../../../models/step-state';

@containerless
@inject(IEventAggregator)
export class TemplateActionCategory {
    @bindable() public flattened: FlattenedExaminationStep[];
    @bindable() public record: GetMedicalRecordResponse;
    @bindable() public flow: MedicalExaminationActionFlow;
    @bindable() public step: MedicalExaminationTemplateItemStep;
    @bindable() public item: MedicalExaminationActionItem;
    @bindable() public category: MedicalExaminationActionItem;
    @bindable() public registration: GetMedicalRecordRegistrationResponse;
    @bindable() public registrations: { [key: string]: GetMedicalRecordRegistrationResponse };
    @bindable() public validation: any;
    @bindable() public workspace: string;
    @bindable() public language: string;
    @bindable() public xScrollContainer: string;
    @bindable() public isEvaluationStep: boolean = false;
    @bindable() public states: { [key: string]: StepState };
    @bindable() public onFileSelected: (file: SelectedFile) => void;
    @bindable() public onFileRemoved: (file: SelectedFile) => void;
    @bindable() public loading: (show: boolean) => void;
    @bindable() public level: number;

    public columns: string = 'col-span-12';
    public MedicalExaminationActionItemTypes: typeof MedicalExaminationActionItemTypes = MedicalExaminationActionItemTypes;
    public shouldBeDisplayed: boolean = false;

    private subscriptions: any[] = [];
    public container: HTMLDivElement;
    public grid: HTMLDivElement;

    public constructor(
        private readonly events: IEventAggregator //
    ) {}

    public attached(): void {
        this.columns = generateColumns(
            this.flow.breakpoints?.filter((x) => x.id === this.item.copiedFrom) || [] //
        );

        if (isNotDefined(this.item.attributes)) this.item.attributes = {};
        if (isNotDefined(this.states[this.item.id])) {
            this.states[this.item.id] = new StepState({
                stepId: this.item.id,
                expanded: false,
                answered: false
            });
        }

        // Wait for all steps to be rendered.
        setTimeout(() => {
            // Fetch all connected steps for the current step from the flow settings.
            const connected = this.flow.connectedCategories.filter((x) => x.key === this.item.copiedFrom);

            // We are going to loop through each connected step.
            for (const item of connected) {
                // First we get the flattened info from the current connected step.
                const flatOfElementToObserve = this.flattened.find((x) => x.item.copiedFrom === item.value);
                // We are going to observe the grid elements of the connected step.
                // Note that we are not observing the container element of the current step
                // because we are going to change the heights of the container which can
                // retrigger the observer callback, which can cause an infinite loop.
                const elementToObserve = document.getElementById(`action-category-grid-${flatOfElementToObserve.item.copiedFrom}-${this.step.id}`);
                // Get the viewmodel of the connect step. The model is a reference to the <template-action-step> component.
                // which is set by lines like component.ref="states[stepToTake.id].model".
                const viewModelOfElementToObserve = this.states[flatOfElementToObserve.item.id].model as unknown as TemplateActionCategory;
                // We are going to create a new resize observer.
                const observer = new ResizeObserver((entries) => {
                    // Request a frame to prevent resize loops
                    window.requestAnimationFrame((): void | undefined => {
                        if (!Array.isArray(entries) || !entries.length) {
                            return;
                        }
                        if (isNotDefined(this.container) || isNotDefined(this.grid)) return;
                        // Get the heighest height of all the observed elements.
                        const height = Math.max(...entries.map((x) => x.contentRect.height));

                        if (height === 0) {
                            this.matchContainerSizeToGridSize();
                            viewModelOfElementToObserve?.matchContainerSizeToGridSize();
                        } else if (height > this.grid.clientHeight) {
                            // Height is of the observered element is bigger than the grid height.
                            this.setHeight(height);
                            viewModelOfElementToObserve?.matchContainerSizeToGridSize();
                        } else if (height === this.grid.clientHeight) {
                            // The height of the observered element is the same as the grid height.
                            this.setHeight(height);
                            viewModelOfElementToObserve?.matchContainerSizeToGridSize();
                        } else if (this.grid.clientHeight > height) {
                            // The height of the grid is bigger than the height of the observered element.
                            viewModelOfElementToObserve?.setHeight(this.grid.clientHeight);
                        }
                    });
                });

                // Initialize the observer and store it in the states so we are
                // able to disconnect them when needed.
                if (isDefined(elementToObserve)) observer.observe(elementToObserve);
                this.states[this.item.id].observer = observer;
            }
        });

        this.subscriptions = [
            ...(this.subscriptions ?? []),
            this.events.subscribe(CustomEvents.ExaminationStepAnswerChanged, () => this.evaluateSettings()),
            this.events.subscribe(CustomEvents.ExaminationActionCategoryChangedExpanded, (data: { stepId: string; expand: boolean; container: string }) => {
                // Find all connected categories where the key matches the id of
                // this current step and where the values matches the id of the
                // the category that is clicked.
                const shouldChange =
                    // Only categories in the same container should be affected.
                    data.container === this.step.id &&
                    this.flow.connectedCategories //
                        .filter((x) => x.key === this.item.copiedFrom)
                        .some((x) => x.value === data.stepId);
                // If we have any settings, we know that the expanded and height state
                // of the  current category needs to change.
                if (shouldChange) this.states[this.item.id].expanded = data.expand;
            })
        ];

        // Evaluate the requirements on load of the action step.
        this.evaluateSettings();
    }

    public detaching(): void {
        this.subscriptions.forEach((x) => x.dispose());
        if (isDefined(this.states[this.item.id].observer)) this.states[this.item.id].observer.disconnect();
    }

    public collapseOrExpand(): void {
        this.states[this.item.id].expanded = !this.states[this.item.id].expanded;

        // Give the browser some time to render the new height
        // when expanding the div.
        this.events.publish(CustomEvents.ExaminationActionCategoryChangedExpanded, {
            stepId: this.item.copiedFrom, //
            expand: this.states[this.item.id].expanded,
            // Make sure we only affect categories in the same container.
            container: this.step.id
        });

        if (this.states[this.item.id].expanded) {
            // Lets collapse all other categories on the same level.
            for (const prop in this.states) {
                if (
                    // Ignore steps without a level value.
                    isNotDefined(this.states[prop].level) ||
                    // Don't collapse the current category.
                    this.states[prop].level.stepId === this.item.id ||
                    // Don't collapse categories in other containers.
                    this.states[prop].level.container !== this.step.id ||
                    // Only collapse categories that are on the same level.
                    this.states[prop].level.value != this.level
                )
                    continue;

                // Ignore categories that are connected to the current category.
                const connected = this.flow.connectedCategories.filter(
                    (x) =>
                        x.key === this.item.copiedFrom && //
                        x.value === this.states[prop].level.copiedFrom
                );
                if (connected.any()) continue;

                this.states[prop].expanded = false;
            }
        }
    }

    public setHeight(height: number): void {
        if (isNotDefined(this.container)) return;
        if (height === 0) return;
        this.container.style.height = `${height + (height > 0 ? 4 : 0)}px`;
    }

    private evaluateSettings(): void {
        const flattened = this.flattened.find((x) => x.item.id === this.item.id);
        this.shouldBeDisplayed = flattened?.isVisible ?? true;

        // TODO: Delete step values of the steps below this category (including any files)
        // when the category is hidden for example by a display rule.
    }

    private matchContainerSizeToGridSize(): void {
        // Allow the step some time to resize and render its DOM content so that we get the correct sizes.
        if (isNotDefined(this.container) || isNotDefined(this.grid)) return;
        if (this.grid.offsetHeight === 0) return;
        // Make sure the content height is the same as the grid height (dynamic)
        // if (this.container.offsetHeight < this.grid.offsetHeight || force) {
        // Set the height of the container to the height of the grid.
        this.container.style.height = `${this.grid.offsetHeight + (this.grid.offsetHeight > 0 ? 4 : 0)}px`;
    }
}
