import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { GetHealthcareInvoiceResponse, GetPatientResponse, PatientsApiClient } from '@wecore/sdk-healthcare';
import { IEventAggregator, inject } from 'aurelia';
import { PartialViewResults } from '../../enums/partial-view-results';
import { BasePartialView } from '../../infra/base-partial-view';
import { CacheService } from '../../infra/cache-service';
import { ErrorHandler } from '../../infra/error-handler';
import { CustomEvents } from '../../infra/events';
import { PartialViews } from '../../infra/partial-views';
import { State } from '../../infra/store/state';
import { PartialView } from '../../models/partial-view';
import { ViewOptions } from '../../models/view-options';
import { EventDetails } from '../../models/event-details';
import { UxInput } from '../../ux/ux-input/ux-input';
import { isNotEmpty } from '@wecore/sdk-utilities';
import { ModalService } from '../../services/service.modals';
import { ConfirmationOptions } from '../../models/confirmation-options';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, PatientsApiClient, ModalService)
export class PartialPatientMerge extends BasePartialView {
    public source: GetPatientResponse;
    public destination: GetPatientResponse;
    public patients: GetPatientResponse[] = [];
    public input: UxInput;
    public showNoResults: boolean = false;

    private sourceId: string;

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly patientsApi: PatientsApiClient,
        private readonly modalService: ModalService
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.sourceId = view.data.id;
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                const [patient] = await Promise.all([
                    this.patientsApi.getById(this.sourceId, this.authenticated.workspace.id) //
                ]);

                this.source = patient;
                this.baseLoaded = true;
            })
            .catch((x) => this.errorHandler.handle('PartialPatientMerge.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }

    public async close(): Promise<void> {
        await super.removeChildViews();
        await super.remove({
            result: PartialViewResults.Cancelled,
            updateUrl: true
        });
    }

    public async handleSearch(event: CustomEvent<EventDetails<UxInput, any>>): Promise<void> {
        const element = event.detail.element;
        element.isLoading = true;

        const query = event.detail.values?.value;
        const response = await this.patientsApi.search(this.authenticated.workspace.id, isNotEmpty(query) ? query : undefined, 100, 0);
        this.patients = response.data;

        this.showNoResults = true;

        element.isLoading = false;
    }

    public async merge(): Promise<void> {
        const phrase = this.t.tr('translation:partial-views.patient-merge.questions.merge.phrase');
        await this.modalService.confirmWithPhrase(
            phrase,
            new ConfirmationOptions({
                title: this.t.tr('translation:partial-views.patient-merge.questions.merge.title'),
                message: this.t.tr('translation:partial-views.patient-merge.questions.merge.message'),
                type: 'danger',
                btnOk: this.t.tr('translation:global.buttons.merge'),
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        try {
                            await this.patientsApi.merge(this.destination.id, this.source.id, this.authenticated.workspace.id);
                            this.remove({ result: PartialViewResults.Ok, updateUrl: true });
                        } catch (e) {
                            this.errorHandler.handle('PartialPatientMerge.merge', e);
                        }
                    }
                }
            })
        );
    }

    public clear(): void {
        this.patients = [];
        this.showNoResults = false;
        this.input.clear(null, false);
    }

    public select(patient: GetPatientResponse): void {
        this.destination = patient;
        this.clear();
    }
}
