<import from="converters/translate" />
<import from="converters/sort-therapy-plans-or-executions" />
<import from="converters/side-to-string" />
<import from="converters/date" />
<import from="bx/bx-scheduler-item-selector/bx-scheduler-item-selector" />
<import from="./templates/template-therapy-category/template-therapy-category" />
<import from="./templates/template-therapy-step/template-therapy-step" />
<import from="./templates/template-treatment-item/template-treatment-item" />
<import from="./templates/template-treatment-evaluation-item/template-treatment-evaluation-item" />

<div class="flex flex-col">
    <ux-label class="mb-1">
        <span t="translation:components.widgets.examination-treatment.labels.treatment"></span>
    </ux-label>
    <template if.bind="record.treatment.plans.length > 0">
        <div class="flex flex-col">
            <div class="grid grid-cols-12 gap-2">
                <div class="flex flex-col col-span-4 gap-2">
                    <!-- 
                        Sort the treatment plans first. For each plan (e.g. selected therapy) we are going to organize 
                        and sort the connected 'results' (e.g. selected differential diagnoses) to the therapy.
                    -->
                    <!-- 'therapygroup' is bassically a plan (therapy) with a list connected results (diagnoses). -->
                    <div repeat.for="[obj1, therapygroup] of record.treatment.plans | sortTherapyPlansOrExecutions:registrations" class="flex flex-col p-5 border border-gray-200 rounded-lg">
                        <!-- 'resultgroup' is the list of connected results (diagnoses) to the current therapy. -->
                        <let [obj2, resultgroup].bind="therapygroup"></let>
                        <!-- 'regs' is the registration ID of the current plan (therapy), we use this to get the name of it below. -->
                        <let regs.bind="resultgroup.values().next().value"></let>
                        <span class="text-xs text-gray-500" t="translation:global.labels.therapy"></span>
                        <div class="flex justify-between gap-2">
                            <p class="font-semibold truncate">${registrations[regs[0]].therapy.translations | translate:language}</p>
                        </div>
                        <ul class="flex flex-col gap-1 mt-1">
                            <!-- So for each results array in the current plan, where 'ids' is the array of results (diagnoses) -->
                            <li repeat.for="[obj3, ids] of therapygroup">
                                <div class="flex flex-col">
                                    <div class="flex w-full flex-col gap-0.5 rounded-lg bg-gray-100 py-1 pl-3 pr-3.5 text-sm">
                                        <!-- Here we going to find all FULL objects of the connected results in the curren medical record -->
                                        <let results.bind="record.results.filter((result) => registrations[ids[0]].therapyPlan.results.some((y) => y.resultId == result.id))"></let>
                                        <!-- If we have results, lets show them. -->
                                        <template if.bind="results.length > 0">
                                            <div class="flex items-center justify-between gap-2">
                                                <!-- If we only have one, show the name of the one result we found. -->
                                                <template if.bind="results.length === 1">
                                                    <p class="truncate">${results[0].differentialDiagnosis.name | translate:language}</p>
                                                    <div if.bind="results[0].side || results[0].location" class="flex gap-1 flex-nowrap">
                                                        <p class="text-xs whitespace-nowrap text-primary">${results[0].side | sideToString | t}</p>
                                                        <p class="text-xs whitespace-nowrap text-primary">${results[0].location.translations | translate:language}</p>
                                                    </div>
                                                </template>
                                                <!-- Otherwise just let the user now we have found multiple connected results. -->
                                                <template else>
                                                    <div class="flex gap-1">
                                                        <p class="truncate" t="translation:components.widgets.examination-treatment.labels.multiple-diagnoses"></p>
                                                        <p>(${results.length})</p>
                                                    </div>
                                                </template>
                                            </div>
                                        </template>
                                        <!-- No results? Notify user. -->
                                        <div else class="flex">
                                            <p class="truncate" t="translation:components.widgets.examination-treatment.labels.no-diagnoses"></p>
                                        </div>
                                    </div>
                                    <div class="pl-2">
                                        <div class="flex flex-col gap-1 pl-1 mt-1 border-l-2 border-gray-300">
                                            <!-- Lets finds the executions that are related to the current plan -->
                                            <let executions.bind="record.treatment.executions.filter((e) => registrations[e.id].therapyExecution.planId === ids[0])"></let>
                                            <template repeat.for="reference of executions">
                                                <let index.bind="validation.executions.findIndex((x) => x.id === reference.id)"></let>
                                                <button
                                                    bg-gray-100.class="execution.id !== reference.id && validation.executions[index].valid"
                                                    hover:bg-gray-200.class="execution.id !== reference.id && validation.executions[index].valid"
                                                    active:bg-gray-300.class="execution.id !== reference.id && validation.executions[index].valid"
                                                    bg-blue-100.class="execution.id === reference.id && validation.executions[index].valid"
                                                    hover:bg-blue-200.class="execution.id === reference.id && validation.executions[index].valid"
                                                    active:bg-blue-300.class="execution.id === reference.id && validation.executions[index].valid"
                                                    bg-red-100.class="!validation.executions[index].valid"
                                                    hover:bg-red-200.class="!validation.executions[index].valid"
                                                    active:bg-red-300.class="!validation.executions[index].valid"
                                                    click.trigger="setActive(reference.id)"
                                                    class="flex items-center justify-between gap-3 px-3 py-1 rounded-lg">
                                                    <div class="flex justify-between flex-1 gap-2">
                                                        <p class="text-sm text-gray-500" t="translation:components.widgets.examination-treatment.labels.execution"></p>

                                                        <!-- <template if.bind="registrations[reference.id].schedulerItem"> -->
                                                        <p class="text-sm" if.bind="registrations[reference.id].schedulerItem">${registrations[reference.id].schedulerItem.start | date}</p>
                                                        <p class="text-sm" else>${registrations[reference.id].createdAt | date}</p>
                                                        <!-- </template> -->
                                                        <!-- <p else class="text-sm text-red-800" t="translation:components.widgets.examination-treatment.messages.date-unknown"></p> -->
                                                    </div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-3 h-3">
                                                        <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                                                    </svg>
                                                </button>
                                            </template>
                                        </div>
                                    </div>
                                    <!-- Start the execution based on the current plan -->
                                    <ux-button click.trigger="startExecution(registrations[ids[0]])" class="mt-3" size="sm" type="full" mode="outline" color="dark">
                                        <span t="translation:components.widgets.examination-treatment.buttons.start-execution"></span>
                                    </ux-button>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="flex flex-col col-span-8 gap-2">
                    <div class="flex flex-col flex-1 border border-gray-200 rounded-lg p-7">
                        <template if.bind="execution">
                            <div class="flex items-center justify-between">
                                <div class="flex flex-col mb-2">
                                    <p class="font-semibold truncate">${execution.therapy.translations | translate:language}</p>
                                    <let results.bind="record.results.filter((result) => execution.therapyExecution.results.some((y) => y.resultId == result.id))"></let>
                                    <div class="flex gap-1">
                                        <div class="flex gap-1 text-xs text-gray-500" repeat.for="result of results">
                                            <p if.bind="!$first">+</p>
                                            <p>${result.differentialDiagnosis.name | translate:language}</p>
                                            <div if.bind="result.side || result.location" class="flex gap-1 flex-nowrap">
                                                <p class="text-xs whitespace-nowrap text-primary">${result.side | sideToString | t}</p>
                                                <p class="text-xs whitespace-nowrap text-primary">${result.location.translations | translate:language}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <ux-dropdown
                                        if.bind="!registration.therapyPlan.startedTreatment"
                                        transparent.bind="true"
                                        border.bind="false"
                                        type="full"
                                        color="dark"
                                        size="sm"
                                        padding.bind="false"
                                        mode="outline"
                                        placement="bottom-right">
                                        <div au-slot="label" class="p-1 text-gray-600 rounded-full ring-gray-400 focus:outline-none focus:ring-1">
                                            <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512">
                                                <path
                                                    fill="currentColor"
                                                    d="M64 368C90.51 368 112 389.5 112 416C112 442.5 90.51 464 64 464C37.49 464 16 442.5 16 416C16 389.5 37.49 368 64 368zM64 208C90.51 208 112 229.5 112 256C112 282.5 90.51 304 64 304C37.49 304 16 282.5 16 256C16 229.5 37.49 208 64 208zM64 144C37.49 144 16 122.5 16 96C16 69.49 37.49 48 64 48C90.51 48 112 69.49 112 96C112 122.5 90.51 144 64 144z" />
                                            </svg>
                                        </div>
                                        <ux-dropdown-option click.trigger="repeatExecution()">
                                            <span t="translation:components.widgets.examination-treatment.buttons.repeat-execution"></span>
                                        </ux-dropdown-option>
                                    </ux-dropdown> -->
                            </div>
                        </template>
                        <div else class="flex items-start justify-center flex-1 p-20">
                            <p class="text-sm" t="translation:components.widgets.examination-treatment.messages.no-execution-started"></p>
                        </div>
                        <div class="flex flex-col gap-1">
                            <!-- <template if.bind="evaluation && execution && execution.schedulerItem"> -->
                            <!-- if.bind="evaluation.schedulerItem" -->
                            <template if.bind="evaluation && execution">
                                <template-treatment-evaluation-item
                                    record-flattened.bind="flattened"
                                    registrations.bind="registrations"
                                    registration.bind="evaluation"
                                    states.bind="states"
                                    on-file-selected.bind="onFileSelected"
                                    on-file-removed.bind="onFileRemoved"
                                    x-scroll-container.bind="xScrollContainer"
                                    loading.bind="loading"
                                    expanded.bind="false"
                                    validation.bind="validation"
                                    record.bind="record"
                                    language.bind="language"
                                    workspace.bind="workspace"
                                    item.bind="evaluation">
                                </template-treatment-evaluation-item>
                                <!-- <div else class="flex flex-col items-start justify-center flex-1 p-5 bg-gray-100 rounded-lg">
                                    <ux-label class="mb-1">
                                        <span t="translation:components.widgets.examination-treatment.labels.select-evaluation-scheduler-item"></span>
                                    </ux-label>
                                    <bx-scheduler-item-selector
                                        on-select.bind="handleEvaluationSchedulerItemSelected"
                                        language.bind="language"
                                        workspace.bind="workspace"
                                        patients.bind="[record.patient.id]"
                                        t="[placeholder]translation:components.widgets.examination-treatment.placeholders.select-scheduler-item">
                                    </bx-scheduler-item-selector>
                                </div> -->
                            </template>
                            <template if.bind="execution">
                                <!-- if.bind="execution.schedulerItem" -->
                                <template-treatment-item
                                    record-flattened.bind="flattened"
                                    registrations.bind="registrations"
                                    registration.bind="execution"
                                    states.bind="states"
                                    on-file-selected.bind="onFileSelected"
                                    on-file-removed.bind="onFileRemoved"
                                    x-scroll-container.bind="xScrollContainer"
                                    loading.bind="loading"
                                    expanded.bind="!evaluation"
                                    validation.bind="validation"
                                    record.bind="record"
                                    language.bind="language"
                                    workspace.bind="workspace"
                                    item.bind="execution">
                                </template-treatment-item>
                                <!-- <div else class="flex flex-col items-start justify-center flex-1 p-5 bg-gray-100 rounded-lg">
                                    <ux-label class="mb-1">
                                        <span t="translation:components.widgets.examination-treatment.labels.select-execution-scheduler-item"></span>
                                    </ux-label>
                                    <bx-scheduler-item-selector
                                        on-select.bind="handleExecutionSchedulerItemSelected"
                                        language.bind="language"
                                        workspace.bind="workspace"
                                        patients.bind="[record.patient.id]"
                                        t="[placeholder]translation:components.widgets.examination-treatment.placeholders.select-sheduler-item">
                                    </bx-scheduler-item-selector>
                                </div> -->
                            </template>
                        </div>
                    </div>
                    <!-- if.bind="execution && execution.schedulerItem && !evaluation" -->
                    <div if.bind="execution && !evaluation" class="flex flex-col items-center justify-center border border-gray-200 rounded-lg p-7">
                        <ux-button click.trigger="startEvaluation()" color="primary">
                            <span t="translation:components.widgets.examination-treatment-plan.buttons.start-evaluation"></span>
                        </ux-button>
                    </div>
                </div>
            </div>
        </div>
    </template>
    <template if.bind="record.treatment.plans.length === 0">
        <div class="flex flex-col items-center justify-center gap-2 border border-gray-200 rounded-lg p-7">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-primary">
                <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
            </svg>
            <p class="text-sm" t="translation:components.widgets.examination-treatment.messages.no-treatment-plan"></p>
        </div>
    </template>
</div>
