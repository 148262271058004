<import from="converters/date" />
<import from="converters/translate" />

<template class="!flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex flex-col overflow-hidden" css.bind="styles">
        <ux-header padding="px-5 py-4">
            <div class="flex items-center justify-between">
                <p class="text-sm font-medium" t="partial-views.clinical-pathways.list.title"></p>
                <div class="flex items-center gap-x-1">
                    <ux-button if.bind="baseLoaded && hasRole(UserRoles.WriteMedicalRecords)" loading.bind="creating" click.trigger="create()" padding.bind="false" size="xs">
                        <div class="flex px-2 py-0.5">
                            <span t="translation:global.labels.new"></span>
                        </div>
                    </ux-button>
                    <ux-button if.bind="closeable" click.trigger="close()" mode="outline" color="dark" rounded.bind="true" padding.bind="false" size="xs">
                        <div class="flex px-1 py-1">
                            <svg class="size-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                <path
                                    fill-rule="evenodd"
                                    d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z"
                                    clip-rule="evenodd" />
                            </svg>
                        </div>
                    </ux-button>
                </div>
            </div>
            <!-- <ux-input debounce="450" allow-clear="true" oninput.trigger="handleSearch($event)" onclear.trigger="handleSearch($event)" t="[placeholder]global.placeholders.search"></ux-input> -->
        </ux-header>
        <div ref="scrollContainer" class="flex flex-col flex-1 h-full pb-6 overflow-y-auto scroll-smooth">
            <template if.bind="baseLoaded">
                <ul class="flex flex-col flex-1">
                    <li repeat.for="pathway of pathways" class="group">
                        <button click.trigger="details(pathway)" id.bind="pathway.id" class="flex items-center justify-between w-full gap-10 px-6 py-3 border-b border-gray-200 focus:outline-none hover:bg-gray-50 active:bg-gray-100">
                            <div class="flex items-center flex-1 gap-x-2">
                                <div class="w-1 h-10 transition-all duration-300 ease-in-out bg-blue-500 rounded-full group-hover:ring-2"></div>
                                <div class="flex flex-col w-full">
                                    <template if.bind="pathway.type === 'ProSoftwareMedicalRecord' || pathway.type === 'ProSoftwareReport'">
                                        <p class="text-sm font-semibold group-hover:text-gray-600">${ pathway.createdAt | date }</p>
                                        <div class="flex items-center w-full gap-1 text-gray-500">
                                            <span class="text-xs">ProSoftware</span>
                                            <span if.bind="pathway.type === 'ProSoftwareMedicalRecord'" class="text-xs lowercase" t="translation:global.labels.record"></span>
                                            <span if.bind="pathway.type === 'ProSoftwareReport'" class="text-xs lowercase" t="translation:global.labels.report"></span>
                                        </div>
                                    </template>
                                    <template else>
                                        <div class="flex gap-1 group-hover:text-gray-600">
                                            <p class="text-sm font-semibold" t="translation:global.labels.clinical-pathway"></p>
                                            <p class="text-sm font-semibold">#${ pathway.trackingNumber }</p>
                                        </div>
                                        <div class="flex items-center w-full gap-1 text-xs text-gray-500">
                                            <span t="translation:partial-views.clinical-pathways.messages.started-at"></span>
                                            <span>${ pathway.createdAt | date }</span>
                                        </div>
                                    </template>
                                </div>
                            </div>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 text-gray-500 group-hover:text-black">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                            </svg>
                        </button>
                    </li>
                    <li if.bind="isLoading" class="flex justify-center py-5">
                        <ux-spinner size="xs"></ux-spinner>
                    </li>
                    <li if.bind="endOfList" class="flex justify-center py-5 text-gray-500">
                        <span class="text-sm" t="global.labels.end-of-list"></span>
                    </li>
                    <li if.bind="pathways.length === 0 && !isLoading" class="flex justify-center py-5">
                        <span class="text-sm" t="global.labels.no-results"></span>
                    </li>
                </ul>
            </template>
            <template else>
                <div class="flex flex-col flex-1 px-6 pt-4 gap-y-2">
                    <ux-skeleton show-background.bind="false" type="container">
                        <div class="flex flex-col w-full py-1 space-y-5">
                            <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-0"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-2"></ux-skeleton>
                            <ux-skeleton type="line"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-2"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                            <ux-skeleton type="line"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-4"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-2"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                        </div>
                    </ux-skeleton>
                </div>
            </template>
        </div>
        <ux-footer></ux-footer>
    </section>
</template>
