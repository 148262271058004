import { I18N } from '@aurelia/i18n';
import { InputTypes, MedicalResult, MedicalTherapyPlanFlow, MedicalTherapyPlanItem, ResultTypes, TherapyDefaultValues } from '@wecore/sdk-healthcare';
import { isDefined, isNotDefined } from '@wecore/sdk-utilities';
import { bindable, containerless, inject } from 'aurelia';
import { cloneDeep, generateColumns } from '../../../../../infra/utilities';
import { StepState } from '../../../../../models/step-state';

@containerless
@inject(I18N)
export class TemplateTherapyStep {
    @bindable() public flow: MedicalTherapyPlanFlow;
    @bindable() public item: MedicalTherapyPlanItem;
    @bindable() public def: TherapyDefaultValues;
    @bindable() public states: { [key: string]: StepState };
    @bindable() public getFlat: (id: string) => {
        stepId: string;
        item: MedicalTherapyPlanItem;
        choices: MedicalResult[];
    };

    public InputTypes: typeof InputTypes = InputTypes;
    public ResultTypes: typeof ResultTypes = ResultTypes;
    public columns: string;
    public index: number;
    public container: HTMLDivElement;
    public grid: HTMLDivElement;

    public constructor(
        private readonly t: I18N //
    ) {}

    public attached(): void {
        this.setColumns();
        this.index = this.def.defaultValues.findIndex((x) => x.key === this.item.id);

        // Wait for all steps to be rendered.
        setTimeout(() => {
            const connected = this.flow.connectedSteps.filter((x) => x.key === this.item.id);
            for (const item of connected) {
                // We are going to observe the grid elements of the connected step.
                // Note that we are not observing the container element of the current step
                // because we are going to change the heights of the container which can
                // retrigger the observer callback, which can cause an infinite loop.
                const elementToObserve = document.getElementById(`therapy-step-grid-${item.value}`);
                // Get the viewmodel of the connect step.
                const viewModelOfElementToObserve = this.states[item.value].model as unknown as TemplateTherapyStep;
                const observer = new ResizeObserver((entries) => {
                    // Request a frame to prevent resize loops
                    window.requestAnimationFrame((): void | undefined => {
                        if (!Array.isArray(entries) || !entries.length) {
                            return;
                        }
                        if (isNotDefined(this.container) || isNotDefined(this.grid)) return;
                        // Get the heighest height of all the observed elements.
                        const height = Math.max(...entries.map((x) => x.contentRect.height));

                        if (height === 0) {
                            this.matchContainerSizeToGridSize();
                            viewModelOfElementToObserve?.matchContainerSizeToGridSize();
                        } else if (height > this.grid.clientHeight) {
                            // Height is of the observered element is bigger than the grid height.
                            this.setHeight(height);
                            viewModelOfElementToObserve?.matchContainerSizeToGridSize();
                        } else if (height === this.grid.clientHeight) {
                            // The height of the observered element is the same as the grid height.
                            this.setHeight(height);
                            viewModelOfElementToObserve?.matchContainerSizeToGridSize();
                        } else if (this.grid.clientHeight > height) {
                            // The height of the grid is bigger than the height of the observered element.
                            viewModelOfElementToObserve?.setHeight(this.grid.clientHeight);
                        }
                    });
                });

                if (isDefined(elementToObserve)) observer.observe(elementToObserve);
                this.states[this.item.id].observer = observer;
            }
        });
    }

    public detaching(): void {
        if (isDefined(this.states[this.item.id].observer)) this.states[this.item.id].observer.disconnect();
    }

    public toSet(choices: MedicalResult[]): { value: string; text: string; data?: any }[] {
        return choices.map((x: MedicalResult) => ({ value: x.value, text: isDefined(x.value) ? x.value : this.t.tr('translation:global.messages.missing-value') }));
    }

    public handleDefaultValueSelected = (option: { value: string; text: string }, index: number) => {
        const flat = this.getFlat(this.def.defaultValues[index].key);
        const value = flat.choices.find((x) => x.value === option.value);
        this.def.defaultValues[index].value.results.push(
            MedicalResult.fromJS(cloneDeep(value)) //
        );
    };

    public handleDefaultValueRemoved = (_: { value: string; text: string }, itemIndex: number, parentIndex: number) => {
        this.def.defaultValues[parentIndex].value.results.splice(itemIndex, 1);
    };

    public setHeight(height: number): void {
        if (isNotDefined(this.container)) return;
        this.container.style.height = `${height}px`;
    }

    private setColumns = (): void => {
        this.columns = generateColumns(
            this.flow.breakpoints.filter((x) => x.id === this.item.id) || [] //
        );
    };

    private matchContainerSizeToGridSize(): void {
        // Allow the step some time to resize and render its DOM content so that we get the correct sizes.
        if (isNotDefined(this.container) || isNotDefined(this.grid)) return;
        // Make sure the content height is the same as the grid height (dynamic)
        // if (this.container.offsetHeight < this.grid.offsetHeight || force) {
        // Set the height of the container to the height of the grid.
        this.container.style.height = `${this.grid.offsetHeight}px`;
    }
}
