import { GetMedicalRecordRegistrationResponse, MedicalRecordRegistrationEntityReference } from '@wecore/sdk-healthcare';

export class SortTherapyPlansOrExecutionsValueConverter {
    public toView(
        plans: MedicalRecordRegistrationEntityReference[],
        registrations: { [key: string]: GetMedicalRecordRegistrationResponse },
        type: 'therapyPlan' | 'therapyExecution' = 'therapyPlan'
    ): Map<string, string[]> {
        // Plans are basically the selected therapies in the medical record 'treatment plans widget'.
        // Per plan we are going to create a map with the therapy id as key and the results, e.g. the
        // selected differential diagnosis, as value.
        const grouped = new Map();

        plans
            .map((x) => x.id)
            .forEach((id) => {
                // For each plan we are going to get the connected therapy and the results.
                // We join the IDs of the result so that we get a unique key for the results.
                const therapy = registrations[id].therapy.id;
                const results = registrations[id][type].results.map((x) => x.resultId).join('/');
                // If the therapy is not yet in the grouped map, we are going to add it.
                if (!grouped.has(therapy)) grouped.set(therapy, new Map());
                // If the results are not yet in the therapy grouped map, we are going to add it.
                if (!grouped.get(therapy).has(results)) grouped.get(therapy).set(results, []);
                // Finally we are going to push the plan id to the results array.
                grouped.get(therapy).get(results).push(id);
                // We are going to sort the results so that the newest is on top.
                grouped
                    .get(therapy)
                    .get(results)
                    .sort((a: string, b: string) => {
                        return (registrations[b].createdAt as any) - (registrations[a].createdAt as any);
                    });
            });

        return grouped;
    }
}
