<import from="converters/translate" />
<import from="converters/date" />

<ux-select
    component.ref="combobox"
    valid.bind="valid"
    value.two-way="value"
    search-placeholder.bind="searchPlaceholder"
    placeholder.bind="placeholder"
    allow-clear.bind="allowClear"
    disabled.bind="disabled"
    data.bind="data"
    show-footer.bind="true"
    set-value-on-select.bind="setValueOnSelect"
    onselect.trigger="handleSelect($event)"
    onsearch.trigger="handleSearch($event)">
    <ux-select-option repeat.for="schedulerItem of schedulerItems" value.bind="schedulerItem.id">
        <div class="flex items-center gap-2">
            <div class="rounded-full size-3" css="background-color: ${schedulerItem.appointmentTypes[0].color.background}"></div>
            <span class="flex-auto">
                <span>${ schedulerItem.start | date:'dd-MM-yyyy'} ${ schedulerItem.start | date:'HH:mm'} - ${ schedulerItem.end | date:'HH:mm'}</span>
                <span>&#8226;</span>
                <span>${ schedulerItem.appointmentTypes[0].name | translate:language }</span>
            </span>
        </div>
    </ux-select-option>
</ux-select>
