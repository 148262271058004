// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.signature-container {--tw-bg-opacity:1;background-color:rgb(249 250 251 / var(--tw-bg-opacity, 1));border-width:1px;--tw-border-opacity:1;border-color:rgb(243 244 246 / var(--tw-border-opacity, 1));height:300px;border-radius:0.5rem;position:relative
}.signature-container.invalid {border-width:1px;--tw-border-opacity:1;border-color:rgb(239 68 68 / var(--tw-border-opacity, 1))
}`, "",{"version":3,"sources":["webpack://./src/partials/partial-sign-document/partial-sign-document.scss"],"names":[],"mappings":"AACE,sBAAA,iBAAA,CAAA,2DAAA,CACA,gBAAA,CACA,qBAAA,CAAA,2DAAA,CACA,YAAA,CACA,oBAAA,CACA;AALA,CAQE,8BAAA,gBAAA,CACA,qBAAA,CAAA;AADA","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
