import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { FileTypesApiClient, GetFileTypeResponse } from '@wecore/sdk-core';
import {
    AffixPositions,
    CreateMedicalQuestionRequest,
    DifferentialDiagnosesApiClient,
    DifferentialDiagnosisEntityReference,
    FileType,
    GetDifferentialDiagnosisResponse,
    GetMedicalExaminationPhaseResponse,
    GetMedicalQuestionCategoryResponse,
    GetMedicalQuestionnaireResponse,
    InformationSheet,
    InputRules,
    InputTypes,
    MedicalExaminationPhaseEntityReference,
    MedicalQuestionCategoryEntityReference,
    MedicalQuestionnaireEntityReference,
    MedicalQuestionnairesApiClient,
    MedicalQuestionsApiClient,
    MedicalResult,
    ResultMatcher,
    ResultMatcherTypes,
    ResultTypes,
    SliderSettings
} from '@wecore/sdk-healthcare';
import { isDefined, isNotDefined, isNotEmpty, isOfType, resetValidation, validateState } from '@wecore/sdk-utilities';

import { IEventAggregator, inject } from 'aurelia';
import { ComponentChoicesSelector } from '../../../components/component-choices-selector/component-choices-selector';
import { PartialViewResults } from '../../../enums/partial-view-results';
import { BasePartialView } from '../../../infra/base-partial-view';
import { CacheService } from '../../../infra/cache-service';
import { ErrorHandler } from '../../../infra/error-handler';
import { PartialViews } from '../../../infra/partial-views';
import { State } from '../../../infra/store/state';
import { cleanTranslatables, cloneDeep, getFileTypeTranslation, setTranslation, validateTranslation } from '../../../infra/utilities';
import { EventDetails } from '../../../models/event-details';
import { PartialView } from '../../../models/partial-view';
import { ViewOptions } from '../../../models/view-options';
import { UxCombobox } from '../../../ux/ux-combobox/ux-combobox';
import { UxMultiSelector } from '../../../ux/ux-multi-selector/ux-multi-selector';
import { UxSelectOption } from '../../../ux/ux-select-option/ux-select-option';
import { UxSelect } from '../../../ux/ux-select/ux-select';

@inject(
    CacheService, //
    ErrorHandler,
    IEventAggregator,
    Store<State>,
    I18N,
    MedicalQuestionsApiClient,
    MedicalQuestionnairesApiClient,
    FileTypesApiClient,
    DifferentialDiagnosesApiClient
)
export class PartialMedicalQuestionsCreate extends BasePartialView {
    public questionnaires: GetMedicalQuestionnaireResponse[];
    public diagnosis: GetDifferentialDiagnosisResponse[];
    public ResultTypes: typeof ResultTypes = ResultTypes;
    public ResultMatcherTypes: typeof ResultMatcherTypes = ResultMatcherTypes;
    public InputTypes: typeof InputTypes = InputTypes;
    public AffixPositions: typeof AffixPositions = AffixPositions;
    public inputTypesToShow: InputTypes[] = [InputTypes.FreeEntry, InputTypes.Selector];
    public inputTypeSelector: UxSelect;
    public fileTypeSelector: UxCombobox;
    public choicesSelector: ComponentChoicesSelector;
    public expectedAnswer: UxSelect;
    public expectedAnswers: UxMultiSelector;
    public defaultValue: UxSelect;
    public defaultValues: UxMultiSelector;
    public fileTypes: GetFileTypeResponse[];
    public request: CreateMedicalQuestionRequest = new CreateMedicalQuestionRequest({
        displayOrder: 0,
        filesAmount: 0,
        inputAmount: 1,
        questionnaires: [],
        allowedFileTypes: [],
        categories: [],
        choices: [],
        keywords: [],
        answerType: ResultTypes.Text,
        expectedAnswer: new MedicalResult(),
        expectedAnswers: [],
        defaultValue: new MedicalResult(),
        defaultValues: [],
        inputType: InputTypes.FreeEntry,
        affixPosition: AffixPositions.Suffix,
        inputRules: new InputRules(),
        resultMatcher: new ResultMatcher({
            type: ResultMatcherTypes.SimpleComparison
        }),
        slider: new SliderSettings({
            minorIncrement: 0,
            labels: []
        }),
        healthcareSectors: []
    });
    public validation = {
        name: true,
        displayOrder: true,
        phase: true,
        content: true,
        expectedAnswer: true,
        expectedAnswers: true,
        inputAmount: true,
        inputAmountValid: true,
        filesAmount: true,
        choices: [],
        numericChoices: true,
        inputAmountValidMultipleChoices: true,
        choice: true,
        minRange: true,
        maxRange: true,
        minValue: true,
        maxValue: true,
        startValue: true,
        defaultValue: true,
        endValue: true,
        endValueValid: true,
        initialValue: true,
        initialValueValid: true,
        majorIncrement: true,
        majorIncrementValid: true,
        minorIncrement: true,
        minorIncrementValid: true,
        labels: [],
        labelsValid: true,
        valid: true
    };

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly questionsApi: MedicalQuestionsApiClient,
        private readonly questionnairesApi: MedicalQuestionnairesApiClient,
        private readonly fileTypesApi: FileTypesApiClient,
        private readonly diagnosesApi: DifferentialDiagnosesApiClient
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.request.name = setTranslation({}, this.language);
        this.request.content = setTranslation({}, this.language);
        this.request.placeholder = setTranslation({}, this.language);
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                const [questionnaires, fileTypes, diagnoses] = await Promise.all([
                    this.getAllQuestionnaires(), //
                    this.fileTypesApi.getAll(250),
                    this.diagnosesApi.getAll(this.authenticated.workspace.id, 1000)
                ]);
                this.questionnaires = questionnaires;
                this.fileTypes = fileTypes.data;
                this.diagnosis = diagnoses.data;

                this.selectInputTypes(this.request.answerType, false);

                this.baseLoaded = true;
                await super.handleBaseLoaded();
            })
            .catch((x) => this.errorHandler.handle('PartialMedicalQuestionsCreate.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }

    public async handleInputAmountChanged(): Promise<void> {
        // Reset the expected changes when input amount changes.
        this.request.expectedAnswer.value = null;
        this.request.expectedAnswers = [];
        this.request.defaultValue.value = null;
        this.request.defaultValues = [];

        // Reset validation
        this.validation.expectedAnswer = true;
        this.validation.expectedAnswers = true;
        this.validation.inputAmount = true;
        this.validation.inputAmountValid = true;

        if (isDefined(this.expectedAnswers)) {
            await this.expectedAnswers.clear();
            this.expectedAnswers.refresh(
                this.toSet(this.request.choices, 'Default') //
            );
        }
        if (isDefined(this.defaultValues)) {
            await this.defaultValues.clear();
            this.defaultValues.refresh(
                this.toSet(this.request.choices, 'Default') //
            );
        }
    }

    public handlePhaseSelected = async (phase: GetMedicalExaminationPhaseResponse): Promise<void> => {
        this.request.phase = new MedicalExaminationPhaseEntityReference({
            id: phase.id,
            translations: phase.name
        });
    };

    public handleQuestionnaireSelected = async (questionnaires: GetMedicalQuestionnaireResponse[]): Promise<void> => {
        this.request.questionnaires = questionnaires.map(
            (questionnaire) =>
                new MedicalQuestionnaireEntityReference({
                    id: questionnaire.id,
                    translations: questionnaire.name
                })
        );
    };

    public handleDiagnosesChanged = async (diagnoses: GetDifferentialDiagnosisResponse[]): Promise<void> => {
        // Copy over the keywords used for the diagnoses
        // whilst keeping the manually added ones.
        this.request.keywords = [
            ...this.request.keywords, //
            ...(diagnoses.any() //
                ? diagnoses.selectMany<GetDifferentialDiagnosisResponse, string>((x) => x.keywords)
                : [])
        ].distinct((x) => x);
        this.request.differentialDiagnoses = diagnoses.map(
            (d) =>
                new DifferentialDiagnosisEntityReference({
                    id: d.id,
                    translations: d.name
                })
        );
    };

    public handleCategoryChanged = async (categories: GetMedicalQuestionCategoryResponse[]): Promise<void> => {
        this.request.categories = categories.map(
            (d) =>
                new MedicalQuestionCategoryEntityReference({
                    id: d.id,
                    translations: d.name
                })
        );
    };

    public handleChoicesChanged = async (): Promise<void> => {
        this.request.expectedAnswer.value = null;
        this.request.expectedAnswers = [];
        this.request.defaultValue.value = null;
        this.request.defaultValues = [];
        this.request.inputAmount = this.request.isMultipleChoice ? 2 : 1;

        setTimeout(async () => {
            if (isDefined(this.expectedAnswers)) {
                await this.expectedAnswers.clear();
                this.expectedAnswers.refresh(
                    this.toSet(this.request.choices, 'Default') //
                );
            }
            if (isDefined(this.defaultValues)) {
                await this.defaultValues.clear();
                this.defaultValues.refresh(
                    this.toSet(this.request.choices, 'Default') //
                );
            }
        }, 250);
    };

    public handleExpectedAnswersSelected = (option: { value: string; text: string }) => {
        const value = this.request.choices.find((x) => x.value === option.value);
        this.request.expectedAnswers.push(
            MedicalResult.fromJS(cloneDeep(value)) //
        );
    };

    public handleExpectedAnswersRemoved = (_: { value: string; text: string }, index: number) => {
        this.request.expectedAnswers.splice(index, 1);
    };

    public async handleExpectedAnswerSelected(e: CustomEvent<EventDetails<UxSelect, any>>): Promise<void> {
        const details = e.detail.values;
        if (isNotDefined(details)) this.request.expectedAnswer.value = null;
        else
            this.request.expectedAnswer = MedicalResult.fromJS(
                cloneDeep(this.request.choices.find((x) => x.value === details.value)) //
            );
    }

    public handleDefaultValueSelected = (option: { value: string; text: string }) => {
        const value = this.request.choices.find((x) => x.value === option.value);
        this.request.defaultValues.push(
            MedicalResult.fromJS(cloneDeep(value)) //
        );
    };

    public handleDefaultValueRemoved = (_: { value: string; text: string }, index: number) => {
        this.request.defaultValues.splice(index, 1);
    };

    public async handleDefaultChoiceSelected(e: CustomEvent<EventDetails<UxSelect, any>>): Promise<void> {
        const details = e.detail.values;
        if (isNotDefined(details)) this.request.defaultValue.value = null;
        else
            this.request.defaultValue = MedicalResult.fromJS(
                cloneDeep(this.request.choices.find((x) => x.value === details.value)) //
            );
    }

    public async handleAnswerTypeSelected(e: CustomEvent<EventDetails<UxSelect, UxSelectOption>>): Promise<void> {
        const type = e.detail.values.value as ResultTypes;

        this.request.choices = [];
        if (isDefined(this.expectedAnswers)) {
            await this.expectedAnswers.clear();
            this.expectedAnswers.refresh(
                this.toSet(this.request.choices, 'Default') //
            );
            if (isDefined(this.defaultValues)) {
                await this.defaultValues.clear();
                this.defaultValues.refresh(
                    this.toSet(this.request.choices, 'Default') //
                );
            }
        }

        // Reset certain certain when were switching the answer type.
        this.inputTypesToShow = null;
        this.request.filesAmount = 0;
        this.request.allowedFileTypes = [];
        this.request.inputAmount = 1;
        this.request.expectedAnswer.value = null;
        this.request.expectedAnswers = [];
        this.request.defaultValue.value = null;
        this.request.defaultValues = [];
        this.request.affix = null;
        this.request.affixPosition = AffixPositions.Suffix;
        this.request.resultMatcher.type = ResultMatcherTypes.SimpleComparison;
        this.request.resultMatcher.maxRange = null;
        this.request.resultMatcher.minRange = null;
        this.request.inputRules = new InputRules();
        this.request.slider = new SliderSettings({
            minorIncrement: 0,
            labels: []
        });
        this.request.isMultipleChoice = false;

        this.selectInputTypes(type, true);
    }

    public async handleInputTypeSelected(e: CustomEvent<EventDetails<UxSelect, any>>): Promise<void> {
        const type = e.detail.values.value;
        // Reset certain values when were switching the input type.
        this.request.filesAmount = 0;
        this.request.allowedFileTypes = [];
        this.request.inputAmount = 1;
        this.request.expectedAnswer.value = null;
        this.request.expectedAnswers = [];
        this.request.defaultValue.value = null;
        this.request.defaultValues = [];
        this.request.affix = null;
        this.request.affixPosition = AffixPositions.Suffix;
        this.request.resultMatcher.type = ResultMatcherTypes.SimpleComparison;
        this.request.resultMatcher.maxRange = null;
        this.request.resultMatcher.minRange = null;
        this.request.inputRules = new InputRules();
        this.request.slider = new SliderSettings({
            minorIncrement: 0,
            labels: []
        });
        this.request.isMultipleChoice = false;

        if (type === InputTypes.Selector) {
            setTimeout(() => {
                if (isDefined(this.choicesSelector) && this.request.choices.empty()) this.choicesSelector.add();
            }, 150);
        } else this.request.choices = [];
    }

    public handleFileTypeSelected = (option: { value: string; text: string }): void => {
        const type = this.fileTypes.find((x) => x.id === option.value);
        this.request.allowedFileTypes.push(type);
    };

    public handleFileTypeRemoved = (option: { value: string; text: string }): void => {
        const index = this.request.allowedFileTypes.findIndex((x) => x.id === option.value);
        if (index > -1) this.request.allowedFileTypes.splice(index, 1);
    };

    public async handleResultMatcherSelected(e: CustomEvent<EventDetails<UxSelect, any>>): Promise<void> {
        this.request.resultMatcher.minRange = null;
        this.request.resultMatcher.maxRange = null;
        this.request.expectedAnswer.value = null;
        this.request.expectedAnswers = [];
        this.request.defaultValue.value = null;
        this.request.defaultValues = [];
    }

    public async removeFileType(type: GetFileTypeResponse): Promise<void> {
        const index = this.request.allowedFileTypes.findIndex((x) => x.id === type.id);
        this.request.allowedFileTypes.splice(index, 1);

        // Force the 'used' binding to update.
        this.request.allowedFileTypes = [
            ...(this.request.allowedFileTypes.length > 0 ? [this.request.allowedFileTypes.shift()] : []), //
            ...this.request.allowedFileTypes
        ];
        // this.request.allowedFileTypes = this.request.allowedFileTypes.changed();
    }

    public async cancel(): Promise<void> {
        await super.remove({
            result: PartialViewResults.Cancelled,
            updateUrl: true
        });
    }

    public async save(): Promise<void> {
        const valid = this.validate();

        if (valid) {
            this.isLoading = true;
            try {
                cleanTranslatables(['content', 'placeholder'], this.request, 1);
                if (isDefined(this.request.expectedAnswer) && isNotDefined(this.request.expectedAnswer.value)) this.request.expectedAnswer = null;
                if (isDefined(this.request.defaultValue) && isNotDefined(this.request.defaultValue.value)) this.request.defaultValue = null;

                await this.questionsApi.create(this.authenticated.workspace.id, this.request);
                this.notifications.show(
                    this.t.tr('translation:partial-views.medical-examination-actions.notifications.save-successful.title'),
                    this.t
                        .tr('translation:partial-views.medical-examination-actions.notifications.save-successful.message') //
                        .replace('{entity}', `<span>'${this.request.name[this.language]}'</span>`),
                    { type: 'success', duration: 3000 }
                );

                setTimeout(async () => this.remove({ result: PartialViewResults.Ok, updateUrl: true }), 250);
            } catch (e) {
                // When the save() goes wrong make sure to re-set the translations object that
                // are possibly set to null and are not required for this request.
                // Otherwise the cleanTranslatables() will fail because the translations object is null.
                if (isNotDefined(this.request.placeholder)) this.request.placeholder = setTranslation({}, this.language);
                this.isLoading = false;
                await this.errorHandler.handle('[create-medical-question]', e);
            }
        }
    }

    public manageTranslationsFor(property: string, required: boolean = false): void {
        this.manageTranslations({
            translations: this.request[property],
            callback: (updatedTranslations: any) => {
                this.request[property] = updatedTranslations;
            },
            required
        });
    }

    public manageTranslationsForLabels = (translations: any, callback: (translations: any) => void, type: 'textarea' | 'input', required: boolean): void => {
        this.manageTranslations({ translations, callback, required });
    };

    public async informationSheet(): Promise<void> {
        this.addPartialView({
            view: this.partial.base,
            partial: PartialViews.InformationSheet.with({
                config: {
                    mode: 'edit',
                    language: this.language
                },
                sheet: this.request.informationSheet
            }).whenClosed(async (result: PartialViewResults, sheet: InformationSheet) => {
                if (result === PartialViewResults.Ok) {
                    this.request.informationSheet = sheet;
                }
            }),
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                markItem: true,
                replace: true
            })
        });
    }

    public toSet(choices: FileType[] | MedicalResult[], type: 'Default' | 'FileTypes'): { value: string; text: string; data?: any }[] {
        if (type === 'FileTypes') return choices.map((x: FileType) => ({ value: x.id, text: getFileTypeTranslation(x.name) }));
        return choices.map((x: MedicalResult) => ({ value: x.value, text: isDefined(x.value) ? x.value : this.t.tr('translation:global.messages.missing-value') }));
    }

    private async getAllQuestionnaires(): Promise<GetMedicalQuestionnaireResponse[]> {
        const take = 25;
        let skip = 0;

        let questionnaires: GetMedicalQuestionnaireResponse[] = [];

        let batch = await this.questionnairesApi.getAll(this.authenticated.workspace.id, take, skip);

        while (batch.data.length > 0) {
            questionnaires = questionnaires.concat(batch.data);
            skip += take;
            batch = await this.questionnairesApi.getAll(this.authenticated.workspace.id, take, skip);
        }

        return questionnaires;
    }

    private validate(): boolean {
        resetValidation(this.validation);

        this.validation.name = validateTranslation(this.request.name, this.language);
        this.validation.content = validateTranslation(this.request.content, this.language);
        this.validation.phase = isDefined(this.request.phase);
        this.validation.displayOrder = isNotEmpty(this.request.displayOrder) && isOfType(Number(this.request.displayOrder), 'number');
        this.validation.inputAmount = isNotEmpty(this.request.inputAmount) && isOfType(Number(this.request.inputAmount), 'number');
        this.validation.filesAmount = isNotEmpty(this.request.filesAmount) && isOfType(Number(this.request.filesAmount), 'number');

        if (this.request.resultMatcher.type === ResultMatcherTypes.BetweenRange) {
            this.validation.minRange = isNotEmpty(this.request.resultMatcher.minRange) && isOfType(Number(this.request.resultMatcher.minRange), 'number');
            this.validation.maxRange = isNotEmpty(this.request.resultMatcher.maxRange) && isOfType(Number(this.request.resultMatcher.maxRange), 'number');
        }

        if (this.request.inputType === InputTypes.Selector) {
            for (let index = 0; index < this.request.choices.length; index++) {
                const choice = this.request.choices[index];

                // Match the choice with the correct validation object by matching the id.
                // We do this because the user can change the order of the choices and so
                // we have to make sure the validation object matches the correct choice.
                const vIndex = this.validation.choices.findIndex((x: any) => x.id === choice.id);

                this.validation.choices[vIndex].value = isNotEmpty(choice.value);
                this.validation.choices[vIndex].unique = this.request.choices.filter((x) => x.value?.toLowerCase() === choice.value?.toLowerCase()).length === 1;
                this.validation.choices[vIndex].numeric =
                    this.request.answerType === ResultTypes.Number //
                        ? !isNaN(Number(choice.value))
                        : true;
                if (this.request.calculateScore) this.validation.choices[vIndex].score = isDefined(choice.score) && !isNaN(Number(choice.score));
            }

            this.validation.numericChoices = this.validation.choices.every((x) => x.numeric);

            // if (Number(this.request.inputAmount) === 1) this.validation.expectedAnswer = isNotEmpty(this.request.expectedAnswer);
            this.validation.inputAmountValid =
                Number(this.request.inputAmount) > 0 &&
                // Only validate the input amount when more than 1 choices are given.
                (Number(this.request.inputAmount) < 2 ? true : Number(this.request.inputAmount) < this.request.choices.length);

            // If we have multiple choice the input amount can never be lower than 2.
            if (this.request.isMultipleChoice) {
                this.validation.inputAmountValidMultipleChoices = Number(this.request.inputAmount) > 1;
            }

            if (isDefined(this.request.expectedAnswers) && this.request.expectedAnswers.any()) {
                this.validation.expectedAnswers =
                    isDefined(this.request.expectedAnswers) &&
                    (Number(this.request.inputAmount) >= 2 //
                        ? this.request.expectedAnswers.length === Number(this.request.inputAmount)
                        : true);
            }
        }

        if (this.request.inputType === InputTypes.RangeSlider) {
            this.validation.startValue = isNotEmpty(this.request.slider.startValue) && isOfType(Number(this.request.slider.startValue), 'number');
            this.validation.endValue = isNotEmpty(this.request.slider.endValue) && isOfType(Number(this.request.slider.endValue), 'number');
            this.validation.majorIncrement = isNotEmpty(this.request.slider.majorIncrement) && isOfType(Number(this.request.slider.majorIncrement), 'number');

            if (this.validation.majorIncrement) {
                this.validation.majorIncrementValid = Number(this.request.slider.majorIncrement) < Number(this.request.slider.endValue);
            }

            if (this.validation.startValue && this.validation.endValue) {
                this.validation.endValueValid = Number(this.request.slider.endValue) > Number(this.request.slider.startValue);

                if (isDefined(this.request.slider.initialValue)) {
                    this.validation.initialValue = isNotEmpty(this.request.slider.initialValue) && isOfType(Number(this.request.slider.initialValue), 'number');
                    this.validation.initialValueValid =
                        Number(this.request.slider.initialValue) >= Number(this.request.slider.startValue) && Number(this.request.slider.initialValue) <= Number(this.request.slider.endValue);
                }
            }

            if (isDefined(this.request.slider.majorIncrement)) {
                this.validation.minorIncrement = isNotEmpty(this.request.slider.minorIncrement) && isOfType(Number(this.request.slider.minorIncrement), 'number');

                if (this.validation.minorIncrement) {
                    this.validation.minorIncrementValid = Number(this.request.slider.minorIncrement) < Number(this.request.slider.majorIncrement);
                }

                for (let index = 0; index < this.request.slider.labels.length; index++) {
                    const label = this.request.slider.labels[index];

                    this.validation.labels[index].label = validateTranslation(label.value, this.language);
                    this.validation.labels[index].value = isDefined(label.sliderValues);

                    // if (this.validation.labels[index].value) {
                    //     this.validation.labels[index].unique = this.request.slider.labels.filter((x) => x.sliderValue === label.sliderValue).length === 1;
                    //     this.validation.labels[index].valueValid =
                    //         Number(label.sliderValue) >= Number(this.request.slider.startValue) && Number(label.sliderValue) <= Number(this.request.slider.endValue);
                    // }
                }
            }
        }

        this.validation.valid = validateState(this.validation) && this.validation.choices.every(validateState) && this.validation.labels.every(validateState);

        return this.validation.valid;
    }

    private selectInputTypes(type: ResultTypes, change: boolean): void {
        let inputType: InputTypes = InputTypes.FreeEntry;

        switch (type) {
            case ResultTypes.Text:
                this.inputTypesToShow = [InputTypes.FreeEntry, InputTypes.Selector];
                inputType = InputTypes.FreeEntry;
                break;
            case ResultTypes.Number:
                this.inputTypesToShow = [InputTypes.FreeEntry, InputTypes.Selector, InputTypes.RangeSlider];
                inputType = InputTypes.FreeEntry;
                break;
            case ResultTypes.File:
                this.inputTypesToShow = [InputTypes.Filepicker];
                inputType = InputTypes.Filepicker;
                break;
            case ResultTypes.Date:
                this.inputTypesToShow = [InputTypes.Datepicker];
                inputType = InputTypes.Datepicker;
                break;
        }

        if (change) this.request.inputType = inputType;
        if (isDefined(this.inputTypeSelector)) this.inputTypeSelector.setValue(inputType);
    }
}
