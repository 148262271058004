import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import {
    CreateMedicalQuestionnaireRequest,
    DifferentialDiagnosisEntityReference,
    GetDifferentialDiagnosisResponse,
    GetMedicalExaminationPhaseResponse,
    InformationSheet,
    MedicalExaminationPhaseEntityReference,
    MedicalQuestionnaireFlow,
    MedicalQuestionnairesApiClient
} from '@wecore/sdk-healthcare';
import { isDefined, isNotDefined, isNotEmpty, isOfType, resetValidation, validateState } from '@wecore/sdk-utilities';

import { IEventAggregator, inject } from 'aurelia';
import { PartialViewResults } from '../../../enums/partial-view-results';
import { BasePartialView } from '../../../infra/base-partial-view';
import { CacheService } from '../../../infra/cache-service';
import { ErrorHandler } from '../../../infra/error-handler';
import { PartialViews } from '../../../infra/partial-views';
import { State } from '../../../infra/store/state';
import { cleanTranslatables, setTranslation, validateTranslation } from '../../../infra/utilities';
import { EventDetails } from '../../../models/event-details';
import { PartialView } from '../../../models/partial-view';
import { ViewOptions } from '../../../models/view-options';
import { UxSelect } from '../../../ux/ux-select/ux-select';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, MedicalQuestionnairesApiClient)
export class PartialMedicalQuestionnairesCreate extends BasePartialView {
    public request: CreateMedicalQuestionnaireRequest = new CreateMedicalQuestionnaireRequest({
        displayOrder: 0,
        questions: [],
        flow: new MedicalQuestionnaireFlow({
            breakpoints: [],
            required: [],
            visibilityRequirements: []
        }),
        scoreResultRanges: [],
        healthcareSectors: []
    });
    public validation = {
        name: true,
        displayOrder: true,
        phase: true,
        results: [],
        valid: true
    };

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly questionnairesApi: MedicalQuestionnairesApiClient
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.request.name = setTranslation({}, this.language);
        this.request.description = setTranslation({}, this.language);
    }

    public attached(): void {
        super
            .initView()
            .then(() => {
                this.baseLoaded = true;
            })
            .catch((x) => this.errorHandler.handle('PartialMedicalQuestionnairesCreate.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }

    public handlePhaseSelected = async (phase: GetMedicalExaminationPhaseResponse): Promise<void> => {
        this.request.phase = new MedicalExaminationPhaseEntityReference({
            id: phase.id,
            translations: phase.name
        });
    };

    public async cancel(): Promise<void> {
        await super.remove({
            result: PartialViewResults.Cancelled,
            updateUrl: true
        });
    }

    public async save(): Promise<void> {
        const valid = this.validate();

        if (valid) {
            this.isLoading = true;
            try {
                cleanTranslatables(['name', 'description'], this.request, 1);
                await this.questionnairesApi.create(this.authenticated.workspace.id, this.request);
                this.notifications.show(
                    this.t.tr('translation:partial-views.medical-questionnaires.notifications.save-successful.title'),
                    this.t
                        .tr('translation:partial-views.medical-questionnaires.notifications.save-successful.message') //
                        .replace('{entity}', `<span>'${this.request.name[this.language]}'</span>`),
                    {
                        type: 'success',
                        duration: 3000
                    }
                );
                setTimeout(async () => this.remove({ result: PartialViewResults.Ok, updateUrl: true }), 250);
            } catch (e) {
                // When the save() goes wrong make sure to re-set the translations object that
                // are possibly set to null and are not required for this request.
                // Otherwise the cleanTranslatables() will fail because the translations object is null.
                if (isNotDefined(this.request.description)) this.request.description = setTranslation({}, this.language);
                this.isLoading = false;
                await this.errorHandler.handle('[create-medical-questionnaire]', e);
            }
        }
    }

    public manageTranslationsFor = (property: string, required: boolean = false, index: number = null): void => {
        this.manageTranslations({
            translations: isDefined(index) ? this.request.scoreResultRanges[index][property] : this.request[property],
            callback: (updatedTranslations: any) => {
                if (isDefined(index)) this.request.scoreResultRanges[index][property] = updatedTranslations;
                else this.request[property] = updatedTranslations;
            },
            required,
            type: property === 'description' ? 'textarea' : 'input'
        });
    };

    public async handleCalculateChanged(e: CustomEvent<EventDetails<UxSelect, any>>): Promise<void> {
        const checked = e.detail.values.checked;
        if (!checked) this.request.scoreResultRanges = [];
    }

    public handleDiagnosesChanged = async (diagnoses: GetDifferentialDiagnosisResponse[]): Promise<void> => {
        this.request.differentialDiagnoses = diagnoses.map(
            (d) =>
                new DifferentialDiagnosisEntityReference({
                    id: d.id,
                    translations: d.name
                })
        );
    };

    public async informationSheet(): Promise<void> {
        this.addPartialView({
            view: this.partial.base,
            partial: PartialViews.InformationSheet.with({
                config: {
                    mode: 'edit',
                    language: this.language
                },
                sheet: this.request.informationSheet
            }).whenClosed(async (result: PartialViewResults, sheet: InformationSheet) => {
                if (result === PartialViewResults.Ok) {
                    this.request.informationSheet = sheet;
                }
            }),
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                markItem: true,
                replace: true
            })
        });
    }

    private validate(): boolean {
        this.validation.name = validateTranslation(this.request.name, this.language);
        this.validation.displayOrder = isNotEmpty(this.request.displayOrder) && isOfType(Number(this.request.displayOrder), 'number');
        this.validation.phase = isDefined(this.request.phase);

        for (let index = 0; index < this.request.scoreResultRanges.length; index++) {
            // Always reset validation for each item.
            resetValidation(this.validation.results[index]);
            if (this.request.calculateScoreTotal) {
                const item = this.request.scoreResultRanges[index];
                this.validation.results[index].description = validateTranslation(item.description, this.language);
                this.validation.results[index].start = isNotEmpty(item.start) && isOfType(Number(item.start), 'number');
                this.validation.results[index].end = isNotEmpty(item.end) && isOfType(Number(item.end), 'number');
            }
        }

        this.validation.valid = validateState(this.validation) && this.validation.results.every(validateState);

        return this.validation.valid;
    }
}
