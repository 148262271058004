// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ux-table-cell {display: flex;align-items: center;min-width: 0px;padding-left: 1.5rem;padding-right: 1.5rem;padding-top: 0.75rem;padding-bottom: 0.75rem;font-size: 0.875rem;line-height: 1.25rem
}ux-table-cell.header {--tw-bg-opacity: 1;background-color: rgb(249 250 251 / var(--tw-bg-opacity, 1));--tw-text-opacity: 1;color: rgb(107 114 128 / var(--tw-text-opacity, 1));text-transform: uppercase;font-size: 0.75rem;line-height: 1rem
}`, "",{"version":3,"sources":["webpack://./src/ux/ux-table-cell/ux-table-cell.scss"],"names":[],"mappings":"AACE,eAAA,aAAA,CACA,mBAAA,CACA,cAAA,CACA,oBAAA,CAAA,qBAAA,CACA,oBAAA,CAAA,uBAAA,CACA,mBAAA,CAAA;AALA,CAQE,sBAAA,kBAAA,CAAA,4DAAA,CACA,oBAAA,CAAA,mDAAA,CACA,yBAAA,CACA,kBAAA,CAAA;AAHA","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
