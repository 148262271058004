import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { AttachmentEntities, FileResponse } from '@wecore/sdk-attachments';
import { GetUnsignedVersionsResponse, LegalEntities, UserRoles } from '@wecore/sdk-core';
import { HealthcareInvoicesApiClient } from '@wecore/sdk-healthcare';
import { GetAllSalesInvoicesResponse, GetSalesInvoiceResponse, MoneybirdApiClient } from '@wecore/sdk-integrations';
import {
    Address,
    GetStorageInformationResponse,
    GetWorkspaceResponse,
    HealthcareSectors,
    LegalApiClient,
    LegalDocumentVersions,
    WorkspacesApiClient as ManagementWorkspacesApiClient,
    WorkspaceExtensionHealthcare,
    WorkspaceExtensions
} from '@wecore/sdk-management';
import { isDefined, isNotDefined, isNotEmpty, isValid } from '@wecore/sdk-utilities';
import { IEventAggregator, inject } from 'aurelia';
import { FileSizeValueConverter } from '../../converters/file-size';
import { PartialViewResults } from '../../enums/partial-view-results';
import { BasePartialView } from '../../infra/base-partial-view';
import { CacheService } from '../../infra/cache-service';
import { Countries } from '../../infra/countries';
import { ErrorHandler } from '../../infra/error-handler';
import { CustomEvents } from '../../infra/events';
import { PartialViews } from '../../infra/partial-views';
import { State } from '../../infra/store/state';
import { cloneDeep } from '../../infra/utilities';
import { ConfirmationOptions } from '../../models/confirmation-options';
import { EventDetails } from '../../models/event-details';
import { PartialView } from '../../models/partial-view';
import { ViewOptions } from '../../models/view-options';
import { ModalService } from '../../services/service.modals';
import { UxTextarea } from '../../ux/ux-textarea/ux-textarea';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, ManagementWorkspacesApiClient, LegalApiClient, MoneybirdApiClient, HealthcareInvoicesApiClient, ModalService)
export class PartialManagePractice extends BasePartialView {
    public tabs: any;
    public lockType: boolean;
    public type: string;
    public workspace: GetWorkspaceResponse;
    public HealthcareSectors: typeof HealthcareSectors = HealthcareSectors;
    public validation: any = {
        valid: true,
        name: true,
        coc: true,
        agbCode: true,
        primaryContactFirstName: true,
        primaryContactLastName: true,
        phone: true,
        phoneValid: true,
        email: true,
        emailValid: true,
        invoiceEmail: true,
        invoiceEmailValid: true,
        address: true,
        accountNumber: true,
        vatNumber: true,
        invoiceTemplate: true,
        declarationTemplate: true
    };
    public addresses: Address[] = [];
    public countries: any[] = Countries.getAll();
    public versions: LegalDocumentVersions;
    public storage: GetStorageInformationResponse;
    public fileSize: FileSizeValueConverter = new FileSizeValueConverter();
    public invoices: GetSalesInvoiceResponse[];
    public pageSize: number = 8;
    public currentPage: number = 1;
    public maxPage: number = 1;
    public skip: number = 0;
    public fetching: boolean = false;
    public fileInput: HTMLInputElement;
    public logoSrc: string;
    public unsigned: GetUnsignedVersionsResponse;

    private logoToUpload: File;

    private addTagTo: 'invoiceTemplate' | 'declarationTemplate';
    private curstorStart: number = -1;
    private cursorEnd: number = -1;

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly workspacesApi: ManagementWorkspacesApiClient,
        private readonly legalApi: LegalApiClient,
        private readonly moneybird: MoneybirdApiClient,
        private readonly invoicesApi: HealthcareInvoicesApiClient,
        private readonly modalService: ModalService
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.tabs = {
            general: {
                name: this.t.tr('translation:global.labels.general'),
                active: true,
                valid: true
            },
            templates: {
                name: this.t.tr('translation:global.labels.templates'),
                active: false,
                valid: true
            },
            financial: {
                name: this.t.tr('translation:global.labels.financial'),
                active: false,
                valid: true
            },
            legal: {
                name: this.t.tr('translation:global.labels.legal'),
                active: false,
                valid: true
            },
            storage: {
                name: this.t.tr('translation:global.labels.storage'),
                active: false,
                valid: true
            }
        };
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                const [workspace, versions, storage, unsigned] = await Promise.all([
                    this.workspacesApi.getById(this.authenticated.workspace.id), //
                    this.legalApi.getDocumentVersions(),
                    this.workspacesApi.getStorageInformation(this.authenticated.workspace.id),
                    this.legalApi.getUnsignedVersions()
                ]);

                this.moneybird
                    .getAllSalesInvoices(this.authenticated.workspace.id, this.pageSize, this.skip)
                    .then((invoices) => {
                        this.invoices = invoices.data;
                        this.maxPage = Math.ceil(parseInt(invoices.total?.toString() ?? '0', 10) / this.pageSize);
                    })
                    .catch((x) => this.errorHandler.handle('PartialManagePractice.invoices', x));

                this.workspace = workspace;
                this.versions = versions;
                this.storage = storage;
                this.addresses = isDefined(this.workspace.address) ? [this.workspace.address] : [];
                this.unsigned = unsigned;

                this.getLogo();

                if (isNotDefined(this.workspace.extensions)) this.workspace.extensions = new WorkspaceExtensions();
                if (isNotDefined(this.workspace.extensions.healthcare)) this.workspace.extensions.healthcare = new WorkspaceExtensionHealthcare();

                this.baseLoaded = true;
            })
            .catch((x) => this.errorHandler.handle('PartialManagePractice.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }

    public handleAddressSelected = (address: Address, action: 'add' | 'delete'): void => {
        if (action === 'add') {
            address.translations = { nl: 'Hoofdlocatie', en: 'Primary location' };
            this.workspace.address = address;
            this.addresses = [address];
        } else this.workspace.address = null;
    };

    public async cancel(): Promise<void> {
        await super.remove({
            result: PartialViewResults.Cancelled,
            updateUrl: true
        });
    }

    public setActiveTab(key: string): void {
        for (const prop in this.tabs) this.tabs[prop].active = false;
        this.tabs[key].active = true;
    }

    public async save(): Promise<void> {
        const valid = this.validate();
        if (valid) {
            this.isLoading = true;
            try {
                await this.workspacesApi.update(this.workspace.id, this.workspace);

                if (isDefined(this.logoToUpload)) {
                    const filename = `logo.${this.logoToUpload.name.split('.').pop()}`.toLowerCase();
                    await this.deleteOldLogo();
                    await this.workspacesApi.uploadAttachment(this.workspace.id, true, {
                        data: this.logoToUpload,
                        fileName: filename
                    });
                    this.logoToUpload = null;
                }

                this.workspace = await this.workspacesApi.getById(this.workspace.id);

                // Force cache update.
                await this.cache.getAuthenticatedWorkspace(true);

                this.notifications.show(
                    this.t.tr('translation:partial-views.manage-practice.notifications.save-successful.title'),
                    this.t.tr('translation:partial-views.manage-practice.notifications.save-successful.message'),
                    { type: 'success', duration: 3000 }
                );
            } catch (e) {
                await this.errorHandler.handle('[practice-settings/edit]', e);
            }
            this.isLoading = false;
        }
    }

    public handleInvoiceTemplateBlur(e: CustomEvent<EventDetails<UxTextarea, any>>): void {
        this.curstorStart = (e.detail.element as any).input.selectionStart || 0;
        this.cursorEnd = (e.detail.element as any).input.selectionEnd || 0;
        this.addTagTo = 'invoiceTemplate';
    }

    public handleDeclarationTemplateBlur(e: CustomEvent<EventDetails<UxTextarea, any>>): void {
        this.curstorStart = (e.detail.element as any).input.selectionStart || 0;
        this.cursorEnd = (e.detail.element as any).input.selectionEnd || 0;
        this.addTagTo = 'declarationTemplate';
    }

    public addTag(tag: string, to: string): void {
        let tagToInsert = '';
        switch (tag) {
            case 'id':
                tagToInsert = '{id}';
                break;
            case 'year':
                tagToInsert = '{year}';
                break;
        }

        switch (to) {
            case 'invoiceTemplate':
                // Check if the tag is already present in the template.
                if (this.workspace.extensions.healthcare.invoiceTemplate.includes(tagToInsert)) return;
                this.workspace.extensions.healthcare.invoiceTemplate =
                    // If the invoice template was last focused on.
                    this.addTagTo === 'invoiceTemplate'
                        ? // Insert the tag at the last known cursor position.
                          this.workspace.extensions.healthcare.invoiceTemplate.slice(0, this.curstorStart) + //
                          tagToInsert +
                          this.workspace.extensions.healthcare.invoiceTemplate.slice(this.cursorEnd)
                        : // If no cursor position was known, just append the tag.
                          this.workspace.extensions.healthcare.invoiceTemplate + tagToInsert;
                break;
            case 'declarationTemplate':
                // Check if the tag is already present in the template.
                if (this.workspace.extensions.healthcare.declarationTemplate.includes(tagToInsert)) return;
                this.workspace.extensions.healthcare.declarationTemplate =
                    // If the invoice template was last focused on.
                    this.addTagTo === 'declarationTemplate'
                        ? // Insert the tag at the last known cursor position.
                          this.workspace.extensions.healthcare.declarationTemplate.slice(0, this.curstorStart) + //
                          tagToInsert +
                          this.workspace.extensions.healthcare.declarationTemplate.slice(this.cursorEnd)
                        : // If no cursor position was known, just append the tag.
                          this.workspace.extensions.healthcare.declarationTemplate + tagToInsert;
                break;
        }
    }

    public async showDocument(document: string, version: string): Promise<void> {
        const attachment = this.workspace.attachments.find(
            (x) => x.name.includes(document) && x.name.includes(`${version.replace(/\./g, '')}`) && !x.name.includes('signature') //
        );
        if (isNotDefined(attachment)) return;
        await this.addPartialView({
            view: this.partial.base, //
            partial: PartialViews.DocumentsPreview.with({
                entityId: this.workspace.id,
                entityType: AttachmentEntities.Workspaces,
                attachmentId: attachment.id,
                attachmentName: attachment.name,
                attachmentExtension: attachment.extension
            }),
            options: new ViewOptions({ scrollToView: true, markItem: false, replace: true, updateUrl: false })
        });
    }

    public hasSignedVersion(document: 'termsAndConditions' | 'processingAgreements' | 'eulas', version: string): boolean {
        if (isNotDefined(this.workspace) || isNotDefined(this.workspace.legal)) return false;
        return this.workspace.legal[document].some((x: any) => x.version === version);
    }

    public async sign(document: 'terms-and-conditions' | 'processing-agreement', version: string): Promise<void> {
        // Only owners can sign documents.
        if (!this.hasRole(UserRoles.Owner)) return;

        await this.addPartialView({
            view: this.partial.base, //
            partial: PartialViews.SignDocument.with({
                companyId: this.workspace.id,
                entity: LegalEntities.Company,
                documentUrl: `https://wecorecdn.blob.core.windows.net/docs/legal/${version}/${document}.pdf`,
                documentType: document,
                version
            }).whenClosed(async (result: PartialViewResults, _: any) => {
                if (result === PartialViewResults.Ok) {
                    this.versions = null;
                    this.workspace = await this.workspacesApi.getById(this.workspace.id);
                    this.versions = await this.legalApi.getDocumentVersions();
                    this.unsigned = await this.legalApi.getUnsignedVersions();
                    this.events.publish(CustomEvents.LegalDocumentSigned);
                }
            }),
            options: new ViewOptions({ scrollToView: true, markItem: false, replace: true, updateUrl: false })
        });
    }

    public async handleNextPage(): Promise<void> {
        if (this.currentPage.toString() === this.maxPage.toString()) return;
        this.fetching = true;

        this.skip += this.pageSize;
        this.currentPage++;

        const response = await this.fetchInvoices();
        this.invoices = response.data;

        this.invoices = [
            ...(this.invoices.length > 0 ? [this.invoices.shift()] : []), //
            ...cloneDeep(this.invoices)
        ];

        this.fetching = false;
    }

    public async handlePreviousPage(): Promise<void> {
        if (this.currentPage.toString() === '1') return;
        this.fetching = true;

        this.skip -= this.pageSize;
        this.currentPage--;

        const response = await this.fetchInvoices();
        this.invoices = response.data;
        this.invoices = [
            ...(this.invoices.length > 0 ? [this.invoices.shift()] : []), //
            ...cloneDeep(this.invoices)
        ];
        this.fetching = false;
    }

    public async showInvoice(invoice: GetSalesInvoiceResponse): Promise<void> {
        await this.addPartialView({
            view: this.partial.base, //
            partial: PartialViews.DocumentsPreview.with({
                entityId: invoice.id,
                entityType: AttachmentEntities.Workspaces,
                blobCallback: async (): Promise<[string, string, FileResponse]> => {
                    const blob = await this.moneybird.downloadSalesInvoice(invoice.id, this.authenticated.workspace.id);
                    return [invoice.invoiceNumber, '.pdf', blob];
                }
            }),
            options: new ViewOptions({ scrollToView: true, markItem: false, replace: true, updateUrl: false })
        });
    }

    public selectLogo(): void {
        this.fileInput.click();
    }

    public async deleteLogo(): Promise<void> {
        const remove = () => {
            this.logoSrc = null;
            this.logoToUpload = null;
            this.fileInput.value = null;
        };

        if (this.workspace.attachments.some((x) => x.name.includes('logo')))
            await this.modalService.confirm(
                new ConfirmationOptions({
                    title: this.t.tr('translation:partial-views.manage-practice.questions.delete-logo.title'),
                    message: this.t.tr('translation:partial-views.manage-practice.questions.delete-logo.message'),
                    callback: async (confirmed: boolean): Promise<void> => {
                        if (confirmed) {
                            remove();
                            this.deleteOldLogo();
                        }
                    }
                })
            );
        else remove();
    }

    public handleFilesSelected(e: Event): void {
        const file = this.fileInput.files[0];
        this.logoSrc = URL.createObjectURL(file);
        this.logoToUpload = file;
        this.fileInput.value = null;
    }

    public async showExample(): Promise<void> {
        await this.addPartialView({
            view: this.partial.base, //
            partial: PartialViews.DocumentsPreview.with({
                canDownload: false,
                blobCallback: async (): Promise<[string, string, FileResponse]> => {
                    const blob = await this.invoicesApi.getRestitutionExample(this.authenticated.workspace.id);
                    return ['example', '.pdf', blob];
                }
            }),
            options: new ViewOptions({ scrollToView: true, markItem: false, replace: true, updateUrl: false })
        });
    }

    private async deleteOldLogo(): Promise<void> {
        const logos = this.workspace.attachments.filter((x) => x.name.includes('logo'));
        for (const logo of logos) {
            await this.workspacesApi.deleteAttachment(this.workspace.id, logo.id);
            this.workspace.attachments = this.workspace.attachments.filter((x) => x.id !== logo.id);
        }
    }

    private async getLogo(): Promise<void> {
        const logo = this.workspace.attachments.find((x) => x.name.includes('logo'));
        if (isDefined(logo)) {
            const blob = await this.workspacesApi.downloadAttachment(this.workspace.id, logo.id);
            if (isDefined(blob) && isDefined(blob.data)) {
                this.logoSrc = URL.createObjectURL(blob.data);
            }
        }
    }

    private async fetchInvoices(): Promise<GetAllSalesInvoicesResponse> {
        return await this.moneybird.getAllSalesInvoices(this.authenticated.workspace.id, this.pageSize, this.skip);
    }

    private validate(): boolean {
        this.validation.name = isNotEmpty(this.workspace.name);
        this.validation.agbCode = isNotEmpty(this.workspace.extensions.healthcare.agbCode);
        this.validation.coc = isNotEmpty(this.workspace.coc);
        this.validation.primaryContactFirstName = isNotEmpty(this.workspace.primaryContactFirstName);
        this.validation.primaryContactLastName = isNotEmpty(this.workspace.primaryContactLastName);
        this.validation.phone = isNotEmpty(this.workspace.phone);
        this.validation.phoneValid = this.validation.phone && this.workspace.phone.length === 10;
        this.validation.address = isDefined(this.workspace.address);

        const validateEmail = (email: string): boolean => {
            return (
                isNotEmpty(email) &&
                isValid(email.toLowerCase(), /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])+/)
            );
        };

        // Validate the email
        this.validation.email = isDefined(this.workspace.email) && isNotEmpty(this.workspace.email);
        this.validation.emailValid = validateEmail(this.workspace.email);

        // Validate the invoice email
        this.validation.invoiceEmail = isDefined(this.workspace.invoiceEmail) && isNotEmpty(this.workspace.invoiceEmail);
        this.validation.invoiceEmailValid = validateEmail(this.workspace.invoiceEmail);

        this.tabs.general.valid =
            this.validation.name && //
            this.validation.email &&
            this.validation.emailValid &&
            this.validation.invoiceEmail &&
            this.validation.invoiceEmailValid &&
            this.validation.agbCode &&
            this.validation.coc &&
            this.validation.primaryContactFirstName &&
            this.validation.primaryContactLastName &&
            this.validation.phone &&
            this.validation.phoneValid &&
            this.validation.address;

        this.validation.invoiceTemplate = isNotEmpty(this.workspace.extensions.healthcare.invoiceTemplate) && this.workspace.extensions.healthcare.invoiceTemplate.includes('{id}');
        this.validation.declarationTemplate = isNotEmpty(this.workspace.extensions.healthcare.declarationTemplate) && this.workspace.extensions.healthcare.declarationTemplate.includes('{id}');

        this.tabs.templates.valid = this.validation.invoiceTemplate && this.validation.declarationTemplate;

        this.validation.accountNumber = isNotEmpty(this.workspace.bankAccountNumber);
        this.validation.vatNumber = isNotEmpty(this.workspace.vatNumber);

        this.tabs.financial.valid = this.validation.accountNumber && this.validation.vatNumber;

        this.validation.valid = this.tabs.general.valid && this.tabs.financial.valid && this.tabs.templates.valid;
        return this.validation.valid;
    }
}
