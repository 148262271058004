import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { AttachmentEntities, AttachmentsApiClient } from '@wecore/sdk-attachments';
import { BlobStorageAttachment, ContactsApiClient } from '@wecore/sdk-crm';
import {
    BodySides,
    ClinicalPathwayEntityReference,
    ClinicalPathwaysApiClient,
    CreateMedicalRecordRequest,
    DifferentialDiagnosesRequirements,
    DuplicateMedicalRecordStepRequest,
    EmailEntityTypes,
    GenerateHealthcareInvoiceFromMedicalRecordRequest,
    GetSchedulerItemResponse,
    GetClinicalPathwayResponse,
    GetHealthcareInvoiceResponse,
    GetMedicalExaminationActionResponse,
    GetMedicalExaminationPhaseResponse,
    GetMedicalExaminationResponse,
    GetMedicalQuestionResponse,
    GetMedicalRecordRegistrationResponse,
    GetMedicalRecordResponse,
    GetPatientResponse,
    HealthcareInvoiceStatuses,
    HealthcareInvoiceTypes,
    HealthcareInvoicesApiClient,
    MedicalExaminationActionFlow,
    MedicalExaminationActionItem,
    MedicalExaminationActionItemTypes,
    MedicalExaminationFlow,
    MedicalExaminationPhasesApiClient,
    MedicalExaminationTemplateItem,
    MedicalExaminationTemplateItemStep,
    MedicalExaminationTemplateItemStepTypes,
    MedicalQuestion,
    MedicalQuestionRegistration,
    MedicalQuestionnaireFlow,
    MedicalRecordQueueItem,
    MedicalRecordQueueItemActions,
    MedicalRecordQueueItemTypes,
    MedicalRecordRegistrationTypes,
    MedicalRecordRegistrationsApiClient,
    MedicalRecordsApiClient,
    MedicalReferral,
    MedicalReferralTypes,
    MedicalTherapyEvaluationFlow,
    MedicalTherapyEvaluationItem,
    MedicalTherapyEvaluationItemTypes,
    MedicalTherapyExecutionItem,
    MedicalTherapyExecutionItemTypes,
    MedicalTherapyPlanItem,
    MedicalTherapyPlanItemTypes,
    MedicalWidgetTypes,
    PatientEntityReference,
    PatientsApiClient,
    ProcessMedicalRecordQueueRequest,
    TherapyDefaultValues,
    UpdateMedicalRecordRegistrationBatchRequest,
    UpdateMedicalRecordRegistrationBatchRequestItem,
    WidgetResult
} from '@wecore/sdk-healthcare';
import { guid, isDefined, isFunction, isNotDefined, isNotEmpty, resetValidation, serveBlob } from '@wecore/sdk-utilities';

import { IEventAggregator, inject } from 'aurelia';
import { AureliaConfiguration } from 'aurelia-configuration';
import { BxSchedulerItemSelector } from '../../../bx/bx-scheduler-item-selector/bx-scheduler-item-selector';
import { BxMedicalExaminationSelector } from '../../../bx/bx-medical-examination-selector/bx-medical-examination-selector';
import { PartialViewResults } from '../../../enums/partial-view-results';
import { BasePartialView } from '../../../infra/base-partial-view';
import { CacheService } from '../../../infra/cache-service';
import { ErrorHandler } from '../../../infra/error-handler';
import { CustomEvents } from '../../../infra/events';
import { PartialViews } from '../../../infra/partial-views';

import { State } from '../../../infra/store/state';
import {
    cleanActionOrTherapyResultsAndAttributes,
    cleanQuestionAnswers,
    cloneDeep,
    fileIsImage,
    flattenRecordExamination,
    questionValidation,
    setActionStepOrTherapyStepValidation,
    setExpectedValuesForActionOrTherapy,
    setQuestionExpectedValues,
    uploadAttachment,
    validateQuestion,
    validateStep,
    widgetValidation
} from '../../../infra/utilities';
import { ModalSaveResult } from '../../../modals/modal-save-result/modal-save-result';
import { ConfirmationOptions } from '../../../models/confirmation-options';
import { FlattenedExaminationStep } from '../../../models/flattened-examination-step';
import { PartialView } from '../../../models/partial-view';
import { SelectedFile } from '../../../models/selected-file';
import { StepState } from '../../../models/step-state';
import { ViewOptions } from '../../../models/view-options';
import { WidgetRegistration } from '../../../models/widget-registration';
import { AuthenticationService } from '../../../services/service.authentication';
import { ModalService } from '../../../services/service.modals';

@inject(
    CacheService, //
    ErrorHandler,
    IEventAggregator,
    Store<State>,
    I18N,
    ClinicalPathwaysApiClient,
    MedicalRecordsApiClient,
    ModalService,
    AttachmentsApiClient,
    MedicalRecordRegistrationsApiClient,
    PatientsApiClient,
    ContactsApiClient,
    AureliaConfiguration,
    MedicalExaminationPhasesApiClient,
    HealthcareInvoicesApiClient,
    AuthenticationService
)
export class PartialClinicalPathwaysDetails extends BasePartialView {
    public pathwayId: string;
    public activeRecord: GetMedicalRecordResponse;
    public records: GetMedicalRecordResponse[] = [];
    public pathway: GetClinicalPathwayResponse;
    public activeItem: MedicalExaminationTemplateItem;
    public activeIndex: number;
    public MedicalExaminationTemplateItemStepTypes: typeof MedicalExaminationTemplateItemStepTypes = MedicalExaminationTemplateItemStepTypes;
    public validation = {
        phases: [],
        plans: [],
        executions: [],
        evaluations: []
    };
    public creationValidation = {
        examination: true,
        schedulerItem: true
    };
    public view: 'pathway' | 'record' = 'pathway';
    public saving: boolean = false;
    public savingRegistrations: boolean = false;
    public savingPatient: boolean = false;
    public generating: boolean = false;
    public savingMessage: string;
    public uploadIndex: number = -1;
    public showLoader: boolean = false;
    public registrations: { [key: string]: GetMedicalRecordRegistrationResponse } = {};
    public phases: { [key: string]: GetMedicalExaminationPhaseResponse } = {};
    public patient: GetPatientResponse;
    public scrollContainers: HTMLDivElement[] = [];
    public widgets: WidgetRegistration[] = [];
    public percentageCompleted: number = 0;
    public percentageRequiredCompleted: number = 0;
    public hasRequiredFields: boolean = false;
    public validating: boolean;
    public totalSteps: number = 0;
    public totalCompletedSteps: number = 0;
    public totalRequiredSteps: number = 0;
    public totalCompletedRequiredSteps: number = 0;
    public flattened: FlattenedExaminationStep[] = [];
    public states: { [key: string]: StepState } = {};
    public invoices: GetHealthcareInvoiceResponse[];
    public HealthcareInvoiceStatuses: typeof HealthcareInvoiceStatuses = HealthcareInvoiceStatuses;
    public HealthcareInvoiceTypes: typeof HealthcareInvoiceTypes = HealthcareInvoiceTypes;
    public uploadsFailed: boolean = false;
    public starting: boolean = false;
    public recordLoaded: boolean = false;
    public examinationSelector: BxMedicalExaminationSelector;
    public schedulerItemSelector: BxSchedulerItemSelector;
    public canceling: boolean = false;
    public examination: GetMedicalExaminationResponse;
    public schedulerItem: GetSchedulerItemResponse;
    public loadingRecords: boolean = true;

    private uploadsToUpload: SelectedFile[] = [];
    private attachmentsToDelete: BlobStorageAttachment[] = [];
    private previousRecord: string;

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly pathwaysApi: ClinicalPathwaysApiClient,
        private readonly recordsApi: MedicalRecordsApiClient,
        private readonly modalService: ModalService,
        private readonly attachmentsApi: AttachmentsApiClient,
        private readonly registrationsApi: MedicalRecordRegistrationsApiClient,
        private readonly patientsApi: PatientsApiClient,
        private readonly contactsApi: ContactsApiClient,
        private readonly config: AureliaConfiguration,
        private readonly phasesApi: MedicalExaminationPhasesApiClient,
        private readonly invoicesApi: HealthcareInvoicesApiClient,
        private readonly auth: AuthenticationService
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.pathwayId = view.data.id;
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                // Load the provided clinical pathway.
                this.pathway = await this.pathwaysApi.getById(this.pathwayId, this.authenticated.workspace.id);

                // Fetch patient and all records for the current pathway.
                const [records, patient] = await Promise.all([
                    this.recordsApi.search(this.authenticated.workspace.id, '', 100, 0, undefined, undefined, undefined, undefined, undefined, [this.pathway.id]), //
                    this.patientsApi.getById(this.pathway.patient.id, this.authenticated.workspace.id)
                ]);
                this.records = records.data;
                this.patient = patient;

                if (this.records.any()) {
                    // // Set the first record as the active record.
                    // this.activeRecord = this.records[0];
                    // await this.initRecord();
                    // this.setViewTo('record');
                } else this.setViewTo('record');

                this.loadingRecords = false;
                this.baseLoaded = true;
                setTimeout(() => {
                    for (const container of this.scrollContainers) container.addEventListener('scroll', (e) => this.handleScroll(e));
                }, 100);
            })
            .catch((x) => this.errorHandler.handle('PartialClinicalPathwaysDetails.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached, updateUrl: false });
    }

    public async cancel(): Promise<void> {
        await super.remove({
            result: PartialViewResults.Cancelled,
            updateUrl: true
        });
    }

    public async setActive(item: MedicalExaminationTemplateItem, index: number, refreshWidgets: boolean = true): Promise<void> {
        if (this.saving) return;

        // Reset child views and scroll flag.
        this.removeChildViews();
        this.hasScrolled = false;

        this.changeStyle({
            width: `${item?.width || 900}px`,
            minWidth: `${item?.width || 900}px`
        });
        this.activeItem = item;
        this.activeIndex = index;

        // Reset to optional previous scroll state.
        setTimeout(() => (this.hasScrolled = (this.scrollContainers[this.activeIndex]?.scrollTop || 0) > 0));

        // Refresh all widgets.
        if (refreshWidgets) await Promise.all(this.widgets.map((x) => x.refresh()));
        await this.scrollTo(this.partial);
    }

    public async save(): Promise<void> {
        this.saving = true;
        await this.removeChildViews();

        const handleError = async (e: any, step: string = null): Promise<string> => {
            const notify = async () => {
                // await this.errorHandler.display('[save-medical-records]', e);
                await this.modalService.open(ModalSaveResult, {
                    result: 'error',
                    error: e,
                    step,
                    workspace: this.authenticated.workspace.id,
                    payload: {
                        record: this.activeRecord,
                        patient: this.patient,
                        registrations: this.prepareRegistrations(false)
                    }
                });
            };

            try {
                const apiError = JSON.parse(e.response);
                switch (Number(apiError.httpStatusCode)) {
                    case 403:
                        await this.notifications.show(this.t.tr('translation:global.errors.default-title'), this.t.tr('translation:global.errors.default-rights'), {
                            type: 'warning'
                        });
                        this.saving = false;
                        return apiError.httpStatusCode;
                    default:
                        notify();
                }
            } catch (e) {
                notify();
            }
        };

        this.savingMessage = this.t.tr('translation:partial-views.clinical-pathways.messages.processing-attachments');

        try {
            await this.processFiles();
        } catch (e) {
            handleError(e, 'processing-files');
        }

        try {
            this.savingMessage = this.t.tr('translation:partial-views.clinical-pathways.messages.processing-widgets');
            // We have to save the widgets one by one because they can all modify the record.
            // If we would this in parallel, the record would only be updated with the
            // changes of the last widget that is updated .
            for (const widget of this.widgets) {
                try {
                    await widget.onSave();
                } catch (e) {
                    handleError(e, `processing-widgets-${widget.type}`);
                }
            }
        } catch (e) {
            handleError(e, 'processing-widgets');
        }

        this.savingMessage = this.t.tr('translation:partial-views.clinical-pathways.messages.processing-medical-record');

        try {
            this.cleanAllExpectedValues();
        } catch (e) {
            handleError(e, 'processing-medical-record-cleaning');
        }

        this.activeRecord.percentageCompleted = this.percentageCompleted;
        this.activeRecord.percentageRequiredCompleted = this.percentageRequiredCompleted;

        // Make sure to prepare the registrations after saving the widgets BEFORE saving the record.
        // Otherwise changes made by the widgets are not included in the update.
        this.savingRegistrations = true;
        const registrationsToUpdate = this.prepareRegistrations(true);

        // for (const registration of registrationsToUpdate) {
        //     this.registrationsApi
        //         .update(registration.id, this.authenticated.workspace.id, registration) //
        //         .catch((e) => this.errorHandler.display(e, 'processing-medical-record-registrations'));
        // }

        this.registrationsApi
            .updateBatch(
                this.authenticated.workspace.id, //
                new UpdateMedicalRecordRegistrationBatchRequest({
                    items: registrationsToUpdate.map(
                        (registration) =>
                            new UpdateMedicalRecordRegistrationBatchRequestItem({
                                id: registration.id,
                                body: registration
                            })
                    )
                })
            )
            .then(() => (this.savingRegistrations = false))
            .catch((e) => handleError(e, 'processing-medical-record-registrations'));

        this.savingPatient = true;
        this.patientsApi
            .update(this.patient.id, this.authenticated.workspace.id, this.patient) //
            .then(() => {
                this.events.publish(CustomEvents.PatientUpdated);
                this.savingPatient = false;
            })
            .catch((e) => handleError(e, 'processing-patient'));

        try {
            const response = await this.recordsApi.update(this.activeRecord.id, this.authenticated.workspace.id, this.activeRecord);
            this.activeRecord = response;
        } catch (e) {
            handleError(e, 'processing-medical-record-save');
            return;
        }

        try {
            if (this.activeRecord.queue.any()) {
                this.savingMessage = this.t.tr('translation:partial-views.clinical-pathways.messages.processing-queue');
                await this.processQueue();
            }
        } catch (e) {
            handleError(e, 'processing-queue');
        }

        this.attachmentsToDelete = [];
        this.validation = { phases: [], plans: [], executions: [], evaluations: [] };

        try {
            this.setValidation();
        } catch (e) {
            handleError(e, 'resetting-view-validation');
        }

        try {
            this.setAllExpectedValues();
        } catch (e) {
            handleError(e, 'resetting-view-expected');
        }

        try {
            this.checkForDefaultValues();
        } catch (e) {
            handleError(e, 'resetting-view-default-values');
        }

        try {
            this.flatten();
        } catch (e) {
            handleError(e, 'resetting-view-flatten');
        }

        try {
            this.calculateProgress();
        } catch (e) {
            handleError(e, 'resetting-view-progress');
        }

        this.widgets = [];

        this.hasScrolled = false;
        if (
            // If we do not have uploads to process
            this.uploadsToUpload.length === 0 ||
            // Or if we have proccessed all uploads successfully
            this.uploadsToUpload.every((x) => x.status === 'done')
        ) {
            // We know that everything is saved and processed, we can hide the save screen
            // and reset the uploaded files.
            this.saving = false;
            this.uploadsToUpload = [];
        } else {
            // We know a file failed to upload, we are still showing the save screen.
            // Update the message to show the user that not all files are succesfully uploaded.
            this.uploadsFailed = true;
        }

        // Force view to rebind to all inputs by refreshing the phase.
        this.setActive(this.activeRecord.examination.template.phases[this.activeIndex], this.activeIndex, false);

        this.notifications.show(
            this.t.tr('translation:partial-views.clinical-pathways.notifications.save-successful.title'),
            this.t.tr('translation:partial-views.clinical-pathways.notifications.save-successful.message'), //
            { type: 'success' }
        );

        // Make sure the record is also updated locally.
        const index = this.records.findIndex((x) => x.id === this.activeRecord.id);
        if (index > -1) this.records[index] = this.activeRecord;

        this.isLoading = false;
    }

    public collapseOrExpandAll(command: 'collapse' | 'expand'): void {
        const setState = (stepId: string, level?: { stepId: string; copiedFrom: string; value: number; container: string }) => {
            if (isNotDefined(this.states[stepId]))
                this.states[stepId] = new StepState({
                    stepId, //
                    expanded: command === 'expand',
                    answered: false,
                    level
                });
            this.states[stepId].expanded = command === 'expand';
        };

        const collapseOrExpandActionOrTherapy = (
            items: MedicalExaminationActionItem[] | MedicalTherapyPlanItem[] | MedicalTherapyExecutionItem[],
            command: 'collapse' | 'expand',
            containerId: string,
            level: number = 1
        ): void => {
            for (const item of items) {
                if (item.type === MedicalExaminationActionItemTypes.Category || item.type === MedicalTherapyPlanItemTypes.Category || item.type === MedicalTherapyExecutionItemTypes.Category) {
                    setState(item.id, {
                        stepId: item.id,
                        value: level,
                        container: containerId,
                        copiedFrom: item.copiedFrom
                    });
                    collapseOrExpandActionOrTherapy(item.category.stepsToTake, command, containerId, level + 1);
                } else setState(item.id);
            }
        };

        const collapseOrExpand = (items: MedicalExaminationTemplateItemStep[], command: 'collapse' | 'expand'): void => {
            for (const item of items) {
                switch (item.type) {
                    case MedicalExaminationTemplateItemStepTypes.Questionnaire:
                        setState(item.id);
                        for (const question of this.registrations[item.id].questionnaire.questions) setState(question.id);
                        break;
                    case MedicalExaminationTemplateItemStepTypes.Action:
                        setState(item.id);
                        collapseOrExpandActionOrTherapy(this.registrations[item.id].action.stepsToTake, command, item.id);
                        break;
                    case MedicalExaminationTemplateItemStepTypes.Widget:
                        break;
                }
            }
        };
        collapseOrExpand(this.activeItem.stepsToTake, command);
    }

    public async delete(ask: boolean = true): Promise<void> {
        const callback = async (): Promise<void> => {
            this.deleting = true;
            try {
                await this.recordsApi.delete(this.activeRecord.id, this.authenticated.workspace.id);
                this.notifications.show(
                    this.t.tr('translation:partial-views.clinical-pathways.notifications.deleted-successfully.title'),
                    this.t
                        .tr('translation:partial-views.clinical-pathways.notifications.deleted-successfully.message') //
                        .replace('{entity}', `<span>'${this.activeRecord.trackingNumber}'</span>`),
                    { type: 'success', duration: 3000 }
                );

                this.recordLoaded = false;
                this.records = this.records.filter((x) => x.id !== this.activeRecord.id);
                this.activeRecord = null;
                if (this.records.any()) {
                    // To the pathway view to select a different record.
                    this.setViewTo('pathway');
                } else {
                    // To the record create view.
                    this.activeRecord = null;
                    this.setViewTo('record');
                }
                this.deleting = false;
            } catch (e) {
                this.deleting = false;
                await this.errorHandler.handle('[delete-medical-record]', e);
            }
        };

        if (ask)
            await this.modalService.confirm(
                new ConfirmationOptions({
                    title: this.t.tr('partial-views.clinical-pathways.questions.delete.title'),
                    message: this.t
                        .tr('partial-views.clinical-pathways.questions.delete.message') //
                        .replace('{entity}', `<span>'${this.activeRecord.trackingNumber}'</span>`),
                    callback: async (confirmed: boolean): Promise<void> => {
                        if (confirmed) await callback();
                    }
                })
            );
        else callback();
    }

    public handleFileSelected = (upload: any): void => {
        this.uploadsToUpload.push(upload);
    };

    public handleFileRemoved = async (upload: any, attachment: BlobStorageAttachment): Promise<void> => {
        if (isDefined(upload)) {
            const index = this.uploadsToUpload.findIndex((x) => x.id === upload.id);
            if (index > -1) this.uploadsToUpload.splice(index, 1);
        } else this.attachmentsToDelete.push(attachment);
    };

    public isImage(contentType: string): boolean {
        return fileIsImage(contentType);
    }

    public loader = (loading: boolean): void => {
        this.showLoader = loading;
    };

    public removeStep = async (stepId: string): Promise<void> => {
        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('translation:partial-views.clinical-pathways.questions.delete-step.title'),
                message: this.t.tr('translation:partial-views.clinical-pathways.questions.delete-step.message'),
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        try {
                            for (let i = 0; i < this.activeRecord.examination.template.phases.length; i++) {
                                const phase = this.activeRecord.examination.template.phases[i];

                                const index = phase.stepsToTake.findIndex((x) => x.id === stepId);
                                if (index > -1) {
                                    phase.stepsToTake.splice(index, 1);
                                    this.validation.phases[i].phaseSteps.splice(index, 1);
                                }
                            }
                            await this.recordsApi.deleteStep(this.activeRecord.id, stepId, this.authenticated.workspace.id);
                            this.notifications.show(
                                this.t.tr('translation:partial-views.clinical-pathways.notifications.step-deleted-successfully.message'),
                                this.t.tr('translation:partial-views.clinical-pathways.notifications.step-deleted-successfully.message'),
                                { type: 'success', duration: 3000 }
                            );
                        } catch (e) {
                            await this.errorHandler.handle('[delete-anatomical-regions]', e);
                        }
                    }
                }
            })
        );
    };

    public addPartial = async (partial: PartialView, options: ViewOptions): Promise<void> => {
        // Add partial view
        await this.removeChildViews();
        options.index = this.partial.index + 1;
        await this.addPartialView({
            view: this.partial.base,
            partial,
            options
        });
    };

    public scrollToView = async (partial: PartialView): Promise<void> => {
        partial.base = this.partial.base;
        await this.scrollTo(partial);
    };

    public manageTranslationsFor = (translations: any, callback: (translations: any) => void, type: 'textarea' | 'input', required: boolean = false): void => {
        this.manageTranslations({ translations, callback, required, type });
    };

    public async export(): Promise<void> {
        this.loader(true);
        try {
            const blob = await this.recordsApi.export(this.activeRecord.id, this.authenticated.workspace.id);
            serveBlob(blob.data, `${this.patient.displayName} - ${this.activeRecord.examination.name[this.language]}.pdf`);
        } catch (e) {
            this.errorHandler.handle('[export-medical-record]', e);
        }

        this.loader(false);
    }

    public async report(): Promise<void> {
        const contact = await this.contactsApi.getById(this.patient.generalPractitioner.id, this.authenticated.workspace.id);
        const email = contact.emails.find((x) => x.isZorgmail) || contact.emails.find((x) => x.isPrimary) || contact.emails[0];

        await this.addPartialView({
            view: this.partial.base, //
            partial: PartialViews.SendEmailToEntity.with({
                email, //
                entityId: contact.id,
                entityType: EmailEntityTypes.Contact,
                view: 'MedicalRecordDetails',
                patient: this.patient.id,
                record: this.activeRecord.id
            }),
            options: new ViewOptions({ index: this.partial.index + 1, markItem: true, scrollToView: true })
        });
    }

    public async addActions(): Promise<void> {
        await this.removeChildViews();
        const actions = [];
        for (const item of this.activeRecord.examination.template.phases) {
            for (const step of item.stepsToTake) {
                if (step.type === MedicalExaminationTemplateItemStepTypes.Action) {
                    actions.push({
                        phase: item.phase.name,
                        action: step.action.id
                    });
                }
            }
        }

        await this.addPartialView({
            view: this.partial.base, //
            partial: PartialViews.AddMedicalActionsToExamination.with(actions).whenClosed(async (result: PartialViewResults, actions: GetMedicalExaminationActionResponse[]) => {
                if (result === PartialViewResults.Ok) {
                    this.savingMessage = this.t.tr('translation:partial-views.clinical-pathways.messages.processing-actions');
                    this.saving = true;
                    // We are going to use the queue to add the actions to the record.
                    // Map the actions we got from the view to queue items.
                    const queue = actions.map(
                        (x) =>
                            new MedicalRecordQueueItem({
                                id: guid(),
                                entityId: x.id,
                                entityType: MedicalRecordQueueItemTypes.Action,
                                action: MedicalRecordQueueItemActions.Add,
                                manuallyAdded: true
                            })
                    );

                    // Merge the queue with the current queue and save the record.
                    // This will also trigger the processing of the queue.
                    this.activeRecord.queue = [...this.activeRecord.queue, ...queue];

                    // Save the record.
                    await this.save();
                }
            }),
            options: new ViewOptions({
                scrollToView: true,
                markItem: false,
                replace: true,
                index: this.partial.index + 1
            })
        });
    }

    public async addDds(): Promise<void> {
        await this.removeChildViews();
        await this.addPartialView({
            view: this.partial.base, //
            partial: PartialViews.MedicalSuggestions.with({
                record: this.activeRecord,
                manuallyAdded: true
            }),
            options: new ViewOptions({
                scrollToView: true,
                markItem: false,
                replace: true,
                index: this.partial.index + 1
            })
        });
    }

    public validate(): boolean {
        this.loader(true);

        const states = flattenRecordExamination(this.activeRecord, this.registrations, null, this.uploadsToUpload);

        const validateActionOrTherapy = (
            phaseStep: MedicalExaminationTemplateItemStep | MedicalTherapyPlanItem | MedicalTherapyExecutionItem | MedicalTherapyEvaluationItem, //
            steps: MedicalExaminationActionItem[] | MedicalTherapyPlanItem[] | MedicalTherapyExecutionItem[],
            validation: any[],
            flow: MedicalExaminationActionFlow,
            registration: GetMedicalRecordRegistrationResponse
        ): boolean => {
            let valid = true;

            for (let sI = 0; sI < steps.length; sI++) {
                resetValidation(validation[sI]);
                const item = steps[sI];

                if (item.type === MedicalExaminationActionItemTypes.Category || item.type === MedicalTherapyPlanItemTypes.Category || item.type === MedicalTherapyExecutionItemTypes.Category) {
                    const state = states.find((x) => x.item.id === item.id);

                    if (state.isVisible) {
                        const validActionStep = validateActionOrTherapy(phaseStep, item.category.stepsToTake, validation[sI].steps, flow, registration);
                        // Mark category as (in)valid.
                        if (validation[sI].valid) validation[sI].valid = validActionStep;
                    }
                    // Mark entire action as (in)valid only if it is currently valid.
                    if (valid) valid = validation[sI].valid;
                } else {
                    const state = states.find((x) => x.item.id === item.id);
                    if (state.isVisible) validation[sI] = validateStep(item, flow, validation[sI], this.activeRecord, this.uploadsToUpload);
                    // Mark the entire action as (in)valid only if the action is currently still valid.
                    if (valid) valid = validation[sI].valid;
                }
            }

            return valid;
        };

        const validateTheQuestion = (
            step: MedicalExaminationTemplateItemStep | MedicalQuestionRegistration | MedicalTherapyEvaluationItem,
            question: GetMedicalQuestionResponse,
            validation: any,
            stepId: string,
            flow: MedicalExaminationFlow | MedicalQuestionnaireFlow | MedicalTherapyEvaluationFlow
        ): boolean => {
            resetValidation(validation);
            const state = states.find((x) => x.item.id === step.id);
            if (state.isVisible) validation = validateQuestion(step, flow, question, validation, stepId, this.activeRecord, this.uploadsToUpload, this.registrations);
            return validation.valid;
        };

        for (let pI = 0; pI < this.activeRecord.examination.template.phases.length; pI++) {
            const phase = this.activeRecord.examination.template.phases[pI];

            let validPhase = true;
            // Reset the phase validation.
            this.validation.phases[pI].valid = true;

            for (let psI = 0; psI < phase.stepsToTake.length; psI++) {
                const step = phase.stepsToTake[psI];
                const phaseState = states.find((x) => x.item.id === step.id);
                // When a record is created, a registration is created for each step.
                // Now, it can happen that the step data is not available anymore (e.g. when
                // the action, question, questionnaire, widget or treatment is deleted by an admin).
                // When a registration has no step data, it doesn't need to be validated.
                switch (step.type) {
                    case MedicalExaminationTemplateItemStepTypes.Action:
                        // Ignore if not visible or not required.
                        if (!phaseState.isVisible || !phaseState.isRequired) break;
                        // Only continue if action data is present (see above explanation).
                        if (isNotDefined(this.registrations[step.id].action)) break;
                        // Validate all the categories and steps in the action.
                        const validActionSteps = validateActionOrTherapy(
                            step,
                            this.registrations[step.id].action.stepsToTake,
                            this.validation.phases[pI].phaseSteps[psI].steps,
                            this.registrations[step.id].action.flow,
                            this.registrations[step.id]
                        );
                        this.validation.phases[pI].phaseSteps[psI].valid = validActionSteps;
                        // Mark the phase as (in)valid only when the phase is currently valid.
                        if (validPhase) validPhase = this.validation.phases[pI].phaseSteps[psI].valid;
                        break;
                    case MedicalExaminationTemplateItemStepTypes.Question:
                        // Ignore if not visible or not required.
                        if (!phaseState.isVisible || !phaseState.isRequired) break;
                        // Only continue if question data is present (see above explanation).
                        if (isNotDefined(this.registrations[step.id].question)) break;
                        // Validate the question.
                        const validQuestion = validateTheQuestion(
                            step, //
                            this.registrations[step.id].question,
                            this.validation.phases[pI].phaseSteps[psI],
                            step.id,
                            this.activeRecord.examination.flow
                        );
                        // Mark the phase as (in)valid only when the phase is currently valid.
                        if (validPhase) validPhase = validQuestion;
                        break;
                    case MedicalExaminationTemplateItemStepTypes.Questionnaire:
                        // Ignore if not visible or not required.
                        if (!phaseState.isVisible || !phaseState.isRequired) break;
                        // Only continue if questionnaire data is present (see above explanation).
                        if (isNotDefined(this.registrations[step.id].questionnaire)) break;
                        // Validate all the questions in connected to the questionnaire.
                        for (let qI = 0; qI < this.registrations[step.id].questionnaire.questions.length; qI++) {
                            const item = this.registrations[step.id].questionnaire.questions[qI];
                            const valid = validateTheQuestion(
                                item, //
                                item.question,
                                this.validation.phases[pI].phaseSteps[psI].questions[qI],
                                item.id,
                                this.registrations[step.id].questionnaire.flow
                            );
                            if (validPhase) validPhase = valid;
                        }

                        // Mark the phase as (in)valid only when the phase is currently valid.
                        this.validation.phases[pI].phaseSteps[psI].valid = this.validation.phases[pI].phaseSteps[psI].questions.every((x: any) => x.valid);
                        break;
                    case MedicalExaminationTemplateItemStepTypes.Widget:
                        // Validate the widget.
                        const widget = this.widgets.find((x) => x.stepId === step.id);
                        const registration = this.registrations[step.id];

                        // Ignore if not visible or not required.
                        if (!phaseState.isVisible || !phaseState.isRequired) continue;

                        // Only continue if widget data is present (see above explanation).
                        const validWidget = isDefined(widget) ? widget.validate(registration.widget.result, this.validation.phases[pI].phaseSteps[psI]) : true;
                        // Mark the phase as (in)valid only when the phase is currently valid.
                        if (validPhase) validPhase = validWidget;
                }

                this.validation.phases[pI].valid = validPhase;
            }
        }

        // Validate all plans.
        let validPlanPhase = true;
        for (const plan of this.activeRecord.treatment.plans) {
            if (isNotDefined(this.registrations[plan.id]) || isNotDefined(this.registrations[plan.id].therapyPlan)) continue;

            const indexOfValidation = this.validation.plans.findIndex((x) => x.id === plan.id);

            // Validate all the categories and steps in the action.
            const validPlanSteps = validateActionOrTherapy(
                this.registrations[plan.id].therapyPlan,
                this.registrations[plan.id].therapyPlan.value.stepsToTake,
                this.validation.plans[indexOfValidation].steps,
                this.registrations[plan.id].therapyPlan.value.flow,
                this.registrations[plan.id]
            );

            this.validation.plans[indexOfValidation].valid = validPlanSteps;
            if (validPlanPhase) validPlanPhase = validPlanSteps;
        }

        const indexOfPhaseThatHoldsTreatmentPlanWidget = this.activeRecord.examination.template.phases.findIndex((x) =>
            x.stepsToTake.some((step) => step.type === MedicalExaminationTemplateItemStepTypes.Widget && this.registrations[step.id].widget.type === MedicalWidgetTypes.ExaminationTreatmentPlan)
        );

        if (indexOfPhaseThatHoldsTreatmentPlanWidget > -1) {
            // Mark the phase as (in)valid only when the phase is currently valid.
            this.validation.phases[indexOfPhaseThatHoldsTreatmentPlanWidget].valid = this.validation.phases[indexOfPhaseThatHoldsTreatmentPlanWidget].valid && validPlanPhase; //
        }

        // Validate all executions.
        let validExecutionPhase = true;
        for (const execution of this.activeRecord.treatment.executions) {
            if (isNotDefined(this.registrations[execution.id]) || isNotDefined(this.registrations[execution.id].therapyExecution)) continue;

            const indexOfValidation = this.validation.executions.findIndex((x) => x.id === execution.id);

            // Validate all the categories and steps in the action.
            const validExecutionSteps = validateActionOrTherapy(
                this.registrations[execution.id].therapyExecution,
                this.registrations[execution.id].therapyExecution.value.stepsToTake,
                this.validation.executions[indexOfValidation].steps,
                this.registrations[execution.id].therapyExecution.value.flow,
                this.registrations[execution.id]
            );

            this.validation.executions[indexOfValidation].valid = validExecutionSteps;
            if (validExecutionPhase) validExecutionPhase = validExecutionSteps;
        }

        const indexOfPhaseThatHoldsTreatmentWidget = this.activeRecord.examination.template.phases.findIndex((x) =>
            x.stepsToTake.some((step) => step.type === MedicalExaminationTemplateItemStepTypes.Widget && this.registrations[step.id].widget.type === MedicalWidgetTypes.ExaminationTreatment)
        );

        if (indexOfPhaseThatHoldsTreatmentWidget > -1) {
            // Mark the phase as (in)valid only when the phase is currently valid.
            this.validation.phases[indexOfPhaseThatHoldsTreatmentWidget].valid = this.validation.phases[indexOfPhaseThatHoldsTreatmentWidget].valid && validExecutionPhase; //
        }

        setTimeout(() => this.loader(false), 250);

        return this.validation.phases.every((x) => x.valid) && this.validation.plans.every((x) => x.valid) && this.validation.executions.every((x) => x.valid);
    }

    public duplicateStep = async (phaseIndex: number, index: number): Promise<void> => {
        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('translation:partial-views.clinical-pathways.questions.duplicate-step.title'),
                message: this.t.tr('translation:partial-views.clinical-pathways.questions.duplicate-step.message'),
                type: 'warning',
                btnOk: this.t.tr('translation:global.buttons.add'),
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        this.loader(true);

                        // Save it and add to the list.
                        const response = await this.recordsApi.duplicateStep(
                            this.activeRecord.id,
                            this.authenticated.workspace.id,
                            new DuplicateMedicalRecordStepRequest({
                                phaseIndex: phaseIndex,
                                stepIndex: index
                            })
                        );

                        const validation = cloneDeep(this.validation.phases[phaseIndex].phaseSteps[index]);
                        this.validation.phases[phaseIndex].phaseSteps.push(validation);

                        setExpectedValuesForActionOrTherapy(response.registration.action.stepsToTake);

                        this.registrations[response.step.id] = response.registration;
                        this.activeRecord.examination.template.phases[phaseIndex].stepsToTake.push(response.step);

                        this.loader(false);
                        this.save();
                    }
                }
            })
        );
    };

    public async showQueue(): Promise<void> {
        await this.removeChildViews();
        await this.addPartialView({
            view: this.partial.base,
            partial: PartialViews.MedicalRecordQueue.with({ id: this.activeRecord.id, queue: this.activeRecord.queue }) //
                .whenClosed(async (result: PartialViewResults, data: any) => {
                    if (result === PartialViewResults.Ok) {
                        //
                    }
                }),
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                markItem: true,
                replace: true
            })
        });
    }

    public async editInvoice(invoice: GetHealthcareInvoiceResponse): Promise<void> {
        await this.removeChildViews();
        await this.addPartialView({
            view: this.partial.base, //
            partial: PartialViews.EditHealthcareInvoice.with({ id: invoice.id }).whenClosed(async (result: PartialViewResults, creditId: string) => {
                if (result === PartialViewResults.Deleted || result === PartialViewResults.Ok) {
                    if (isDefined(creditId)) setTimeout(() => this.editInvoice(new GetHealthcareInvoiceResponse({ id: creditId })), 0);
                    this.loadInvoices();
                }
            }),
            options: new ViewOptions({ index: this.partial.index + 1, markItem: true, scrollToView: true, updateUrl: false })
        });
    }

    public async generateInvoice(): Promise<void> {
        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('translation:partial-views.clinical-pathways.questions.generate-invoice.title'),
                message: this.t.tr('translation:partial-views.clinical-pathways.questions.generate-invoice.message'),
                type: 'warning',
                btnOk: this.t.tr('translation:global.buttons.generate'),
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        try {
                            this.generating = true;
                            this.showLoader = true;
                            const response = await this.invoicesApi.generateFromRecord(
                                this.authenticated.workspace.id,
                                new GenerateHealthcareInvoiceFromMedicalRecordRequest({
                                    recordId: this.activeRecord.id
                                })
                            );

                            this.invoices.push(response);
                            this.generating = false;
                            this.showLoader = false;

                            this.editInvoice(response);
                        } catch (e) {
                            this.generating = false;
                            this.showLoader = false;
                            this.errorHandler.handle('[generate-invoice]', e);
                        }
                    }
                }
            })
        );
    }

    public canDelete(): boolean {
        return isNotDefined(this.invoices) || !this.invoices.any();
        // TODO: Work this out. It is more complex than it seems.
        // // if (this.hasRole(UserRoles.Owner) || this.hasRole(UserRoles.Admin)) return true;

        // return (
        //     // The user can not have answered any steps or questions.
        //     this.percentageCompleted === 0 &&
        //     // The user can not have added any differential diagnoses.
        //     this.record.results.length === 0
        // );
    }

    public hideSavescreen(): void {
        this.saving = false;
        this.uploadsFailed = false;
        this.uploadsToUpload = [];
    }

    public async retryUpload(index: number): Promise<void> {
        const upload = this.uploadsToUpload[index];

        if (this.uploadsToUpload[index]?.status !== 'failed') return;
        await this.uploadFile(upload, index);

        try {
            // Find the registration we just updated with the uploaded file attachment ID.
            const reg = Object.values(this.registrations).find((x) => x.id === upload.registration);

            if (isDefined(this.registrations[reg?.stepId])) {
                const [record, registration] = await Promise.all([
                    this.recordsApi.getById(this.activeRecord.id, this.authenticated.workspace.id),
                    this.registrationsApi.update(reg.id, this.authenticated.workspace.id, this.registrations[reg?.stepId])
                ]);

                this.activeRecord = record;
                this.registrations[reg.stepId] = registration;
            }

            this.notifications.show(
                this.t.tr('translation:partial-views.clinical-pathways.notifications.save-successful.title'),
                this.t.tr('translation:partial-views.clinical-pathways.notifications.save-successful.message'), //
                { type: 'success' }
            );

            // Hide the save screen if all uploads are done successfully.
            if (this.uploadsToUpload.every((x) => x.status === 'done')) {
                this.hideSavescreen();
            }
        } catch (e) {
            this.errorHandler.handle('processing-medical-record-retry', e);
        }
    }

    public async startRecord(): Promise<void> {
        resetValidation(this.creationValidation);

        this.creationValidation.examination = isDefined(this.examination);
        // this.creationValidation.schedulerItem = isDefined(this.schedulerItem);

        if (this.creationValidation.examination && this.creationValidation.schedulerItem) {
            this.starting = true;
            const response = await this.recordsApi.create(
                this.authenticated.workspace.id,
                new CreateMedicalRecordRequest({
                    examination: this.examination,
                    schedulerItem: this.schedulerItem,
                    patient: new PatientEntityReference({
                        id: this.patient.id,
                        name: this.patient.displayName
                    }),
                    clinicalPathway: new ClinicalPathwayEntityReference({
                        id: this.pathway.id,
                        name: this.pathway.patient.name
                    })
                })
            );

            // Allow some time for all records to be registered. Because creating the record also
            // creates the necessary registrations, we need to wait for the registrations to be created.
            // If you don't wait, the registration will not be yet available when opening the details view.
            // The redis cache will be updated with an empty array of registrations and even a refresh
            // will not show the registrations, until the search keys are deleted and the cache is refreshed.
            setTimeout(async () => {
                this.records = [response, ...this.records];

                this.examinationSelector.clear();
                this.schedulerItemSelector.clear();
                this.activeRecord = response;
                await this.initRecord();

                this.examination = null;
                this.schedulerItem = null;
                this.starting = false;
            }, 1000);
        }
    }

    public handleExaminationSelected = async (examination: GetMedicalExaminationResponse) => {
        this.examination = examination;
    };

    public handleSchedulerItemSelected = async (schedulerItem: GetSchedulerItemResponse): Promise<void> => {
        this.schedulerItem = schedulerItem;
    };

    public async deletePathway(): Promise<void> {
        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('translation:partial-views.clinical-pathways.questions.delete-pathway.title'),
                message: this.t.tr('translation:partial-views.clinical-pathways.questions.delete-pathway.message'),
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        try {
                            await this.pathwaysApi.delete(this.pathway.id, this.authenticated.workspace.id);
                            this.notifications.show(
                                this.t.tr('translation:partial-views.clinical-pathways.notifications.deleted-pathway-successfully.title'),
                                this.t.tr('translation:partial-views.clinical-pathways.notifications.deleted-pathway-successfully.message'),
                                { type: 'success', duration: 3000 }
                            );

                            setTimeout(async () => this.remove({ result: PartialViewResults.Deleted, updateUrl: true }), 250);
                        } catch (e) {
                            this.deleting = false;
                            await this.errorHandler.handle('[delete-clinical-pathway]', e);
                        }
                    }
                }
            })
        );
    }

    public addMedicalRecord(): void {
        this.previousRecord = this.activeRecord?.id;
        this.activeRecord = null;
        this.recordLoaded = false;
        this.setViewTo('record');
    }

    public async loadPreviousRecord(): Promise<void> {
        if (isNotDefined(this.previousRecord)) return;

        this.examinationSelector.clear();
        this.schedulerItemSelector.clear();
        this.activeRecord = this.records.find((x) => x.id === this.previousRecord);
        await this.initRecord();
        this.examination = null;
        this.schedulerItem = null;
        this.canceling = false;
        this.recordLoaded = true;
    }

    public async loadMedicalRecord(record: GetMedicalRecordResponse): Promise<void> {
        this.recordLoaded = false;
        this.activeRecord = record;
        this.loadingRecords = true;
        await this.initRecord();
        this.recordLoaded = true;
        this.loadingRecords = false;
        this.view = 'record';
    }

    public setViewTo(view: 'pathway' | 'record'): void {
        this.view = view;
    }

    private async evaluateDDSettings(
        requirement: DifferentialDiagnosesRequirements, //
        stepId: string,
        value: string,
        action: 'added' | 'deleted'
    ): Promise<Promise<void>> {
        if (isNotDefined(requirement)) return;

        // Get the flattened item of the step that is adding the DD.
        const flattened = this.flattened.find((x) => x.item.id === stepId);
        // Try to find a side by looking at the parent categories (whole tree)
        // of the step that is adding the DD. We take the first one we find.
        let side: BodySides;
        for (const cat of flattened.categories) {
            // If we found a side, stop looking.
            if (isDefined(side)) break;
            // Find the flattened item of the category.
            const flat = this.flattened.find((x) => x.item.id === cat);
            if (flat.flow instanceof MedicalExaminationActionFlow) {
                // Try to find a side in the action flow of the action the category is part of.
                side = flat.flow.sides.find((x) => x.key === flat.item.copiedFrom)?.value;
            }
        }

        if (action === 'added') {
            // Add the DDs to the unprocessed results.
            this.activeRecord.queue = [
                ...this.activeRecord.queue,
                ...requirement.differentialDiagnoses.map(
                    (x) =>
                        new MedicalRecordQueueItem({
                            id: guid(),
                            entityId: x.id,
                            entityType: MedicalRecordQueueItemTypes.DifferentialDiagnosis,
                            trigger: stepId,
                            action: MedicalRecordQueueItemActions.Add,
                            manuallyAdded: false,
                            side,
                            value
                        })
                )
            ];
        } else if (action === 'deleted') {
            // Check if we have a processed result that should be deleted.
            const result = this.activeRecord.results.find(
                (x) =>
                    // The step that added the result must be the same as the step that is deleting it.
                    x.addedByStep === stepId && //
                    // Also the the DD must match the one that is being deleted.
                    requirement.differentialDiagnoses.some((y) => y.id === x.differentialDiagnosis.id)
            );

            // If we can find one, remove the queue item that is not processed yet.
            const index = this.activeRecord.queue.findIndex((x) => x.trigger === stepId && value === x.value);
            if (index > -1) this.activeRecord.queue.splice(index, 1);

            // Only add the task if there is a result to delete.
            // In case a add task was added before by this step and it is not processed yet
            // it will have been deleted a couple of lines above.
            if (isDefined(result)) {
                this.activeRecord.queue = [
                    ...this.activeRecord.queue,
                    ...requirement.differentialDiagnoses.map(
                        (x) =>
                            new MedicalRecordQueueItem({
                                id: guid(),
                                entityId: x.id,
                                entityType: MedicalRecordQueueItemTypes.DifferentialDiagnosis,
                                trigger: stepId,
                                action: MedicalRecordQueueItemActions.Remove,
                                manuallyAdded: false,
                                side,
                                value
                            })
                    )
                ];
            }
        }
    }

    private async processFiles(): Promise<void> {
        // Deliberetly done sequentially because otherwise
        // the API will get stale indexes.
        this.savingMessage = this.t.tr('translation:partial-views.clinical-pathways.messages.saving-attachments');

        for (let index = 0; index < this.uploadsToUpload.length; index++) {
            const upload = this.uploadsToUpload[index];
            await this.uploadFile(upload, index);
        }

        for (const attachment of this.attachmentsToDelete) {
            try {
                this.savingMessage = this.t
                    .tr('translation:partial-views.clinical-pathways.messages.deleting-specific-attachment')
                    .replace('{entity}', `<span class="font-bold block mx-1">'${attachment.name}${attachment.extension}'</span>`);
                await this.attachmentsApi.delete(
                    this.activeRecord.id, //
                    attachment.id,
                    this.authenticated.workspace.id,
                    AttachmentEntities.MedicalRecords
                );
            } catch (e) {
                this.errorHandler.handle('[process-files-delete-files]', {
                    attachment,
                    error: e
                });
            }
        }
    }

    private saveAttachmentToStep(stepId: string, attachmentId: string): void {
        const set = (array: MedicalExaminationActionItem[] | MedicalTherapyPlanItem[] | MedicalTherapyExecutionItem[], step: string, attachment: string): void => {
            for (const item of array) {
                if (item.type === MedicalExaminationActionItemTypes.Category || item.type === MedicalTherapyPlanItemTypes.Category || item.type === MedicalTherapyExecutionItemTypes.Category)
                    set(item.category.stepsToTake, step, attachment);
                else if (item.id === step) {
                    if (isNotDefined(item.step.attachments)) item.step.attachments = [];
                    item.step.attachments.push(attachment);
                }
            }
        };

        for (const phase of this.activeRecord.examination.template.phases) {
            for (const step of phase.stepsToTake) {
                switch (step.type) {
                    case MedicalExaminationTemplateItemStepTypes.Action:
                        set(this.registrations[step.id].action.stepsToTake, stepId, attachmentId);
                        break;
                    case MedicalExaminationTemplateItemStepTypes.Question:
                        if (step.id === stepId) {
                            if (isNotDefined(this.registrations[stepId].question.attachments)) this.registrations[stepId].question.attachments = [];
                            this.registrations[step.id].question.attachments.push(attachmentId);
                        }
                        break;
                    case MedicalExaminationTemplateItemStepTypes.Questionnaire:
                        for (const item of this.registrations[step.id].questionnaire.questions) {
                            if (item.id === stepId) {
                                if (isNotDefined(item.question.attachments)) item.question.attachments = [];
                                item.question.attachments.push(attachmentId);
                            }
                        }
                        break;
                    case MedicalExaminationTemplateItemStepTypes.Widget:
                        break;
                }
            }
        }

        for (const plan of this.activeRecord.treatment.plans) {
            const registration = this.registrations[plan.id];
            if (isNotDefined(registration) || isNotDefined(registration.therapyPlan)) continue;

            set(this.registrations[plan.id].therapyPlan.value.stepsToTake, stepId, attachmentId);
        }

        for (const execution of this.activeRecord.treatment.executions) {
            const registration = this.registrations[execution.id];
            if (isNotDefined(registration) || isNotDefined(registration.therapyExecution)) continue;

            set(this.registrations[execution.id].therapyExecution.value.stepsToTake, stepId, attachmentId);
        }

        for (const evaluation of this.activeRecord.treatment.evaluations) {
            if (isNotDefined(this.registrations[evaluation.id]) || isNotDefined(this.registrations[evaluation.id].therapyEvaluation)) break;

            for (const step of this.registrations[evaluation.id].therapyEvaluation.value.stepsToTake) {
                if (isNotDefined(this.registrations[step.id])) continue;

                switch (step.type) {
                    case MedicalTherapyEvaluationItemTypes.Action:
                        set(this.registrations[step.id].action.stepsToTake, stepId, attachmentId);
                        break;
                    case MedicalTherapyEvaluationItemTypes.Question:
                        if (step.id === stepId) {
                            if (isNotDefined(this.registrations[stepId].question.attachments)) this.registrations[stepId].question.attachments = [];
                            this.registrations[step.id].question.attachments.push(attachmentId);
                        }
                        break;
                    case MedicalTherapyEvaluationItemTypes.Questionnaire:
                        for (const item of this.registrations[step.id].questionnaire.questions) {
                            if (item.id === stepId) {
                                if (isNotDefined(item.question.attachments)) item.question.attachments = [];
                                item.question.attachments.push(attachmentId);
                            }
                        }
                        break;
                    case MedicalTherapyEvaluationItemTypes.Widget:
                        break;
                }
            }
        }
    }

    private async uploadFile(upload: SelectedFile, index: number): Promise<boolean> {
        const element = document.getElementById(`medical-record-upload-${index}`);
        if (isDefined(element)) element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
        this.uploadIndex = index;
        this.uploadsToUpload[index].progress = 0;
        this.uploadsToUpload[index].isLoading = true;
        this.uploadsToUpload[index].statusLabel = this.t
            .tr('translation:partial-views.clinical-pathways.labels.status-uploading')
            .replace('{entity}', `<span class="font-bold block mx-1">'${upload.name.toLowerCase()}'</span>`);

        let response: BlobStorageAttachment;
        try {
            response = await uploadAttachment(
                this.activeRecord.id, //
                this.authenticated.workspace.id,
                upload,
                AttachmentEntities.MedicalRecords,
                (percentage: number) => (this.uploadsToUpload[index].progress = percentage),
                this.isImage(upload.file.type),
                this.config,
                this.auth
            );
        } catch (e) {
            this.uploadsToUpload[index].status = 'failed';
            this.uploadsToUpload[index].statusLabel = this.t
                .tr('translation:partial-views.clinical-pathways.labels.status-failed')
                .replace('{entity}', `<span class="font-bold block ml-1">'${upload.name.toLowerCase()}'</span>`);
            this.errorHandler.handle('[proces-files-upload-attachment]', {
                error: e.message,
                attachment: upload
            });
            this.uploadsToUpload[index].isLoading = false;
            return false;
        }

        const widget = this.widgets.find((x) => x.stepId === upload.step);
        try {
            if (isDefined(widget)) widget.onFileUploaded(response);
        } catch (e) {
            this.errorHandler.handle('[process-files-widget]', e, {
                widget,
                blobl: response,
                attachment: upload
            });
            this.uploadsToUpload[index].isLoading = false;
            return false;
        }

        try {
            switch (upload.type) {
                case 'widget':
                    // Do nothing and let the widget handle it.
                    break;
                case 'referral':
                    this.activeRecord.referral.attachment = response.id;
                default:
                    this.saveAttachmentToStep(upload.step, response.id);
            }
        } catch (e) {
            this.errorHandler.handle('[process-files-update-step]', e, {
                blob: response,
                attachment: upload
            });
            this.uploadsToUpload[index].isLoading = false;
            return false;
        }

        this.uploadsToUpload[index].isLoading = false;
        this.uploadsToUpload[index].status = 'done';
        this.uploadsToUpload[index].statusLabel = this.t
            .tr('translation:partial-views.clinical-pathways.labels.status-done')
            .replace('{entity}', `<span class="font-bold block mx-1">'${upload.name.toLowerCase()}'</span>`);

        return true;
    }

    private calculateProgress(): void {
        // Wait for the answer to be applied and visibility rules are checked
        const allSteps = flattenRecordExamination(this.activeRecord, this.registrations, null, this.uploadsToUpload).filter((x) => x.isVisible && x.isAnswerable);
        this.totalSteps = allSteps.length;

        // Calculate total percentage completed questions/action steps.
        const completed = allSteps.filter((x) => x.isAnswered);
        this.totalCompletedSteps = completed.length;

        const required = allSteps.filter((x) => x.isRequired);
        this.totalRequiredSteps = required.length;
        this.hasRequiredFields = required.any();

        const requiredAndCompleted = required.filter((x) => x.isAnsweredAndValidated);
        this.totalCompletedRequiredSteps = requiredAndCompleted.length;
        this.percentageCompleted = completed.length > 0 ? Math.ceil((completed.length / allSteps.length) * 100) : 0;

        this.percentageRequiredCompleted = required.length > 0 ? Math.ceil((requiredAndCompleted.length / required.length) * 100) : 0;
    }

    private setValidation(): void {
        for (let pI = 0; pI < this.activeRecord.examination.template.phases.length; pI++) {
            const phase = this.activeRecord.examination.template.phases[pI];
            const validation: any = {
                valid: true,
                phaseSteps: []
            };

            for (let psI = 0; psI < phase.stepsToTake.length; psI++) {
                const step = phase.stepsToTake[psI];
                switch (step.type) {
                    case MedicalExaminationTemplateItemStepTypes.Action:
                        if (isNotDefined(this.registrations[step.id]?.action)) continue;
                        const steps = setActionStepOrTherapyStepValidation(this.registrations[step.id].action.stepsToTake, []);
                        validation.phaseSteps.push({
                            valid: true,
                            steps: steps
                        });
                        break;
                    case MedicalExaminationTemplateItemStepTypes.Question:
                        if (isNotDefined(this.registrations[step.id]?.question)) continue;
                        validation.phaseSteps.push(questionValidation(step.id));
                        break;
                    case MedicalExaminationTemplateItemStepTypes.Questionnaire:
                        if (isNotDefined(this.registrations[step.id]?.questionnaire)) continue;
                        validation.phaseSteps.push({ valid: true, questions: [] });
                        for (const item of this.registrations[step.id].questionnaire.questions) {
                            validation.phaseSteps[psI].questions.push(questionValidation(item.id));
                        }
                        break;
                    case MedicalExaminationTemplateItemStepTypes.Widget:
                        if (isNotDefined(this.registrations[step.id]?.widget)) continue;
                        validation.phaseSteps.push(widgetValidation(step.id));
                        break;
                }
            }

            this.validation.phases.push(validation);
        }

        for (const plan of this.activeRecord.treatment.plans) {
            if (isNotDefined(this.registrations[plan.id]) || isNotDefined(this.registrations[plan.id].therapyPlan)) continue;

            const steps = setActionStepOrTherapyStepValidation(this.registrations[plan.id].therapyPlan.value.stepsToTake, []);
            this.validation.plans.push({
                id: plan.id,
                valid: true,
                steps: steps
            });
        }

        for (const execution of this.activeRecord.treatment.executions) {
            if (isNotDefined(this.registrations[execution.id]) || isNotDefined(this.registrations[execution.id].therapyExecution)) continue;

            const steps = setActionStepOrTherapyStepValidation(this.registrations[execution.id].therapyExecution.value.stepsToTake, []);
            this.validation.executions.push({
                id: execution.id,
                valid: true,
                steps: steps
            });
        }

        for (const evaluation of this.activeRecord.treatment.evaluations) {
            if (isNotDefined(this.registrations[evaluation.id]) || isNotDefined(this.registrations[evaluation.id].therapyEvaluation)) break;

            const validation = { id: evaluation.id, valid: true, steps: [] };
            for (const step of this.registrations[evaluation.id].therapyEvaluation.value.stepsToTake) {
                if (isNotDefined(this.registrations[step.id])) continue;

                switch (step.type) {
                    case MedicalTherapyEvaluationItemTypes.Action:
                        if (isNotDefined(this.registrations[step.id]?.action)) continue;
                        const steps = setActionStepOrTherapyStepValidation(this.registrations[step.id].action.stepsToTake, []);
                        validation.steps.push({
                            valid: true,
                            steps: steps
                        });
                        break;
                    case MedicalTherapyEvaluationItemTypes.Question:
                        if (isNotDefined(this.registrations[step.id]?.question)) continue;
                        validation.steps.push(questionValidation(step.id));
                        break;
                    case MedicalTherapyEvaluationItemTypes.Questionnaire:
                        if (isNotDefined(this.registrations[step.id]?.questionnaire)) continue;
                        const v = { valid: true, questions: [] };
                        for (const item of this.registrations[step.id].questionnaire.questions) {
                            v.questions.push(questionValidation(item.id));
                        }
                        validation.steps.push(v);
                        break;
                    case MedicalTherapyEvaluationItemTypes.Widget:
                        if (isNotDefined(this.registrations[step.id]?.widget)) continue;
                        validation.steps.push(widgetValidation(step.id));
                        break;
                }
            }
            this.validation.evaluations.push(validation);
        }
    }

    private setAllExpectedValues(): void {
        for (const phase of this.activeRecord.examination.template.phases) {
            for (const step of phase.stepsToTake) {
                switch (step.type) {
                    case MedicalExaminationTemplateItemStepTypes.Action:
                        if (isNotDefined(this.registrations[step.id]?.action)) continue;
                        setExpectedValuesForActionOrTherapy(this.registrations[step.id].action.stepsToTake);
                        break;
                    case MedicalExaminationTemplateItemStepTypes.Question:
                        if (isNotDefined(this.registrations[step.id]?.question)) continue;
                        setQuestionExpectedValues(this.registrations[step.id].question);
                        break;
                    case MedicalExaminationTemplateItemStepTypes.Questionnaire:
                        if (isNotDefined(this.registrations[step.id]?.questionnaire)) continue;
                        for (const item of this.registrations[step.id].questionnaire.questions) setQuestionExpectedValues(item.question);
                        break;
                    case MedicalExaminationTemplateItemStepTypes.Widget:
                        if (isNotDefined(this.registrations[step.id]?.widget)) continue;
                        if (isNotDefined(this.registrations[step.id]?.widget.result)) {
                            this.registrations[step.id].widget.result = new WidgetResult();
                        }
                        break;
                }
            }
        }

        for (const plan of this.activeRecord.treatment.plans) {
            if (isNotDefined(this.registrations[plan.id]) || isNotDefined(this.registrations[plan.id].therapyPlan)) continue;
            setExpectedValuesForActionOrTherapy(this.registrations[plan.id].therapyPlan.value.stepsToTake);
        }

        for (const execution of this.activeRecord.treatment.executions) {
            if (isNotDefined(this.registrations[execution.id]) || isNotDefined(this.registrations[execution.id].therapyExecution)) continue;
            setExpectedValuesForActionOrTherapy(this.registrations[execution.id].therapyExecution.value.stepsToTake);
        }

        for (const evaluation of this.activeRecord.treatment.evaluations) {
            if (isNotDefined(this.registrations[evaluation.id]) || isNotDefined(this.registrations[evaluation.id].therapyEvaluation)) break;

            for (const step of this.registrations[evaluation.id].therapyEvaluation.value.stepsToTake) {
                if (isNotDefined(this.registrations[step.id])) continue;

                switch (step.type) {
                    case MedicalTherapyEvaluationItemTypes.Action:
                        setExpectedValuesForActionOrTherapy(this.registrations[step.id].action.stepsToTake);
                        break;
                    case MedicalTherapyEvaluationItemTypes.Question:
                        if (isNotDefined(this.registrations[step.id].question)) continue;
                        setQuestionExpectedValues(this.registrations[step.id].question);
                        break;
                    case MedicalTherapyEvaluationItemTypes.Questionnaire:
                        if (isNotDefined(this.registrations[step.id].questionnaire)) continue;
                        for (const item of this.registrations[step.id].questionnaire.questions) setQuestionExpectedValues(item.question);
                        break;
                    case MedicalTherapyEvaluationItemTypes.Widget:
                        if (isNotDefined(this.registrations[step.id].widget)) continue;
                        if (isNotDefined(this.registrations[step.id].widget.result)) {
                            this.registrations[step.id].widget.result = new WidgetResult();
                        }
                        break;
                }
            }
        }
    }

    private cleanAllExpectedValues(): void {
        for (const phase of this.activeRecord.examination.template.phases) {
            for (const step of phase.stepsToTake) {
                if (isNotDefined(this.registrations[step.id])) continue;
                // When a record is created, a registration is created for each step.
                // Now, it can happen that the step data is not available anymore (e.g. when
                // the action, question, questionnaire, widget or treatment is deleted by an admin).
                // When a registration has no step data, it doesn't need to be cleaned.
                switch (step.type) {
                    case MedicalExaminationTemplateItemStepTypes.Action:
                        // Only continue if action data is present (see above explanation).
                        if (isNotDefined(this.registrations[step.id].action)) break;
                        cleanActionOrTherapyResultsAndAttributes(this.registrations[step.id].action.stepsToTake);
                        break;
                    case MedicalExaminationTemplateItemStepTypes.Question:
                        // Only continue if question data is present (see above explanation).
                        if (isNotDefined(this.registrations[step.id].question)) break;
                        cleanQuestionAnswers(this.registrations[step.id].question);
                        break;
                    case MedicalExaminationTemplateItemStepTypes.Questionnaire:
                        // Only continue if questionnaire data is present (see above explanation).
                        if (isNotDefined(this.registrations[step.id].questionnaire)) break;
                        for (const item of this.registrations[step.id].questionnaire.questions) {
                            cleanQuestionAnswers(item.question);
                            // Also clean the attributes of the question registration.
                            // This is because we save temporary data in the attributes
                            // which has no need to be saved in the database.
                            item.attributes = {};
                        }
                        break;
                    case MedicalExaminationTemplateItemStepTypes.Widget:
                        // Only continue if widget data is present (see above explanation).
                        if (isNotDefined(this.registrations[step.id].widget)) break;

                        if (
                            isDefined(this.registrations[step.id].widget.result) && //
                            isNotDefined(this.registrations[step.id].widget.result.value)
                        )
                            this.registrations[step.id].widget.result = null;

                        break;
                }
            }
        }

        // Treatment cleaning is done on their widgets.
        for (const evaluation of this.activeRecord.treatment.evaluations) {
            if (isNotDefined(this.registrations[evaluation.id]) || isNotDefined(this.registrations[evaluation.id].therapyEvaluation)) break;

            for (const step of this.registrations[evaluation.id].therapyEvaluation.value.stepsToTake) {
                if (isNotDefined(this.registrations[step.id])) continue;

                switch (step.type) {
                    case MedicalTherapyEvaluationItemTypes.Action:
                        if (isNotDefined(this.registrations[step.id].action)) break;
                        cleanActionOrTherapyResultsAndAttributes(this.registrations[step.id].action.stepsToTake);
                        break;
                    case MedicalTherapyEvaluationItemTypes.Question:
                        if (isNotDefined(this.registrations[step.id].question)) break;
                        cleanQuestionAnswers(this.registrations[step.id].question);
                        break;
                    case MedicalTherapyEvaluationItemTypes.Questionnaire:
                        if (isNotDefined(this.registrations[step.id].questionnaire)) break;
                        for (const item of this.registrations[step.id].questionnaire.questions) cleanQuestionAnswers(item.question);
                        break;
                    case MedicalTherapyEvaluationItemTypes.Widget:
                        if (isNotDefined(this.registrations[step.id].widget)) break;
                        if (
                            isDefined(this.registrations[step.id].widget.result) && //
                            isNotDefined(this.registrations[step.id].widget.result.value)
                        )
                            this.registrations[step.id].widget.result = null;

                        break;
                }
            }
        }
    }

    private handleScroll(event: Event): void {
        const target = event.target as HTMLElement;
        this.hasScrolled = target.scrollTop > 0;
    }

    private flatten(): void {
        this.flattened = flattenRecordExamination(this.activeRecord, this.registrations, null, this.uploadsToUpload);
    }

    private checkForDefaultValues(): void {
        // Flattened may not be available here yet.
        // Definitely not when the page is initialy laoded.
        const flattened = this.flattened.any() ? this.flattened : flattenRecordExamination(this.activeRecord, this.registrations, null, this.uploadsToUpload);

        const setDefaultValues = (question: MedicalQuestion) => {
            if (
                isDefined(question.defaultValue) && //
                isNotDefined(question.givenAnswer.value)
            ) {
                question.givenAnswer = question.defaultValue;
            } else if (
                isDefined(question.defaultValues) && //
                question.defaultValues.any() &&
                question.givenAnswers.empty()
            ) {
                question.givenAnswers = question.defaultValues;
            }
        };

        const checkActionSteps = (steps: MedicalExaminationActionItem[] | MedicalTherapyPlanItem[] | MedicalTherapyExecutionItem[]): void => {
            for (let sI = 0; sI < steps.length; sI++) {
                const item = steps[sI];
                if (item.type === MedicalExaminationActionItemTypes.Category || item.type === MedicalTherapyPlanItemTypes.Category || item.type === MedicalTherapyExecutionItemTypes.Category) {
                    checkActionSteps(item.category.stepsToTake);
                } else {
                    if (
                        isDefined(item.step.defaultValue) && //
                        isNotDefined(item.step.result.value)
                    ) {
                        item.step.result = item.step.defaultValue;
                    } else if (
                        isDefined(item.step.defaultValues) && //
                        item.step.defaultValues.any() &&
                        item.step.results.empty()
                    ) {
                        item.step.results = item.step.defaultValues;
                    }
                }
            }
        };

        const checkTherapySteps = (steps: MedicalExaminationActionItem[] | MedicalTherapyPlanItem[] | MedicalTherapyExecutionItem[], def: TherapyDefaultValues): void => {
            if (isNotDefined(def)) return;
            for (let sI = 0; sI < steps.length; sI++) {
                const item = steps[sI];
                if (item.type === MedicalExaminationActionItemTypes.Category || item.type === MedicalTherapyPlanItemTypes.Category || item.type === MedicalTherapyExecutionItemTypes.Category) {
                    checkTherapySteps(item.category.stepsToTake, def);
                } else {
                    // Don't allow default values for steps that are not visible.
                    const rules = flattened.find((x) => x.item.id === item.id);
                    if (isDefined(rules) && !rules.isVisible) continue;

                    const defaultValues = def.defaultValues.find((x) => x.key === item.copiedFrom);
                    if (isNotDefined(defaultValues)) continue;
                    if (
                        isDefined(defaultValues.value.result) && //
                        isNotDefined(item.step.result.value)
                    ) {
                        item.step.result = defaultValues.value.result;
                    } else if (
                        isDefined(defaultValues.value.results) && //
                        defaultValues.value.results.any() &&
                        item.step.results.empty()
                    ) {
                        item.step.results = defaultValues.value.results;
                    }
                }
            }
        };

        for (let pI = 0; pI < this.activeRecord.examination.template.phases.length; pI++) {
            const phase = this.activeRecord.examination.template.phases[pI];
            for (let psI = 0; psI < phase.stepsToTake.length; psI++) {
                const step = phase.stepsToTake[psI];
                switch (step.type) {
                    case MedicalExaminationTemplateItemStepTypes.Action:
                        if (isNotDefined(this.registrations[step.id]?.action)) break;
                        checkActionSteps(this.registrations[step.id].action.stepsToTake);
                        break;
                    case MedicalExaminationTemplateItemStepTypes.Question:
                        if (isNotDefined(this.registrations[step.id]?.question)) break;
                        setDefaultValues(this.registrations[step.id].question);
                        break;
                    case MedicalExaminationTemplateItemStepTypes.Questionnaire:
                        if (isNotDefined(this.registrations[step.id]?.questionnaire)) break;
                        for (const item of this.registrations[step.id].questionnaire.questions) setDefaultValues(item.question);
                        break;
                    case MedicalExaminationTemplateItemStepTypes.Widget:
                        break;
                }
            }
        }

        for (const plan of this.activeRecord.treatment.plans) {
            if (isNotDefined(this.registrations[plan.id]) || isNotDefined(this.registrations[plan.id].therapyPlan)) continue;
            checkActionSteps(this.registrations[plan.id].therapyPlan.value.stepsToTake);
        }

        for (const execution of this.activeRecord.treatment.executions) {
            if (isNotDefined(this.registrations[execution.id]) || isNotDefined(this.registrations[execution.id].therapyExecution)) continue;
            checkActionSteps(this.registrations[execution.id].therapyExecution.value.stepsToTake);
        }
    }

    private prepareRegistrations(isForSave: boolean = true): GetMedicalRecordRegistrationResponse[] {
        const array = Object.entries(this.registrations) //
            .map((x) => x[1])
            // Make sure not to lose any data by filtering out undefined values.
            .filter((x) => isDefined(x));

        // Is we are saving we have to filter out certain invalid registration.
        // We only save registrations that are of type 'Action', 'Question', 'Questionnaire' or 'Widget'.
        // Other registrations are not saved by this action but are saved
        // by the 'widget-treatment-plan' or 'widget-treatment'.
        return isForSave
            ? // In the case that the step data didn't exist (anymore) when creating the registration
              // (we get a registration with 'null' as step data) we need to make sure we don't update it.
              array
                  .filter(
                      (x: GetMedicalRecordRegistrationResponse) =>
                          isDefined(x.widget) || //
                          isDefined(x.question) ||
                          isDefined(x.questionnaire) ||
                          isDefined(x.action) ||
                          isDefined(x.therapy) ||
                          isDefined(x.therapyPlan) ||
                          isDefined(x.therapyExecution) ||
                          isDefined(x.therapyEvaluation)
                  )
                  // We also filter out all registrations that are not of type 'Action', 'Question', 'Questionnaire' or 'Widget'.
                  // The registrations that are of type 'TherapyPlan', 'TherapyExecution' or 'TherapyEvaluation'.
                  // are not saved by this action. The 'widget-treatment-plan' and 'widget-treatment' are responsible for that.
                  .filter(
                      (x) =>
                          x.type === MedicalRecordRegistrationTypes.Action ||
                          x.type === MedicalRecordRegistrationTypes.Question ||
                          x.type === MedicalRecordRegistrationTypes.Questionnaire ||
                          x.type === MedicalRecordRegistrationTypes.Widget
                  )
            : array;
    }

    private async processQueue(): Promise<void> {
        const response = await this.recordsApi.processQueue(
            this.activeRecord.id, //
            this.authenticated.workspace.id,
            new ProcessMedicalRecordQueueRequest({
                queue: this.activeRecord.queue
            })
        );

        for (const registration of response.newRegistrations) this.registrations[registration.stepId] = registration;
        for (const registration of response.deletedRegistrations) delete this.registrations[registration.stepId];

        this.activeRecord = response.record;
    }

    private async loadInvoices(): Promise<void> {
        const response = await this.invoicesApi.search(this.authenticated.workspace.id, '', 100, 0, undefined, undefined, undefined, undefined, undefined, [this.activeRecord.id]);
        this.invoices = response.data;
    }

    private async initRecord(): Promise<void> {
        // Make sure all record data is reset.
        if (isDefined(this.subscriptions)) for (const subscription of this.subscriptions) subscription.dispose();
        this.registrations = {};
        this.validation = { phases: [], plans: [], executions: [], evaluations: [] };

        // Load the record data.
        const phaseIds = this.activeRecord.examination.template.phases.map((x) => x.phase.id);
        const [registrations, phases, invoice] = await Promise.all([
            this.registrationsApi.search(this.authenticated.workspace.id, 1000, 0, undefined, this.activeRecord.id), //
            this.phasesApi.search(this.authenticated.workspace.id, '', phaseIds.length, 0, undefined, undefined, undefined, phaseIds),
            this.invoicesApi.search(this.authenticated.workspace.id, '', 100, 0, undefined, undefined, undefined, undefined, undefined, [this.activeRecord.id])
        ]);
        this.invoices = invoice.data;

        // Save each registration with the step ID identifier.
        for (const registration of registrations.data) this.registrations[registration.stepId] = registration;
        for (const phase of phases.data) this.phases[phase.id] = phase;

        // When this record has an examination, load it.
        if (isDefined(this.activeRecord.examination)) {
            this.setActive(this.activeRecord.examination.template.phases[0], 0);
            this.setAllExpectedValues();
            this.setValidation();
            this.checkForDefaultValues();
            this.calculateProgress();
        }

        // Subscribe to the event that will be triggered when the record is updated
        // for outside partial views widgets.
        this.subscriptions.push(
            this.events.subscribe(
                CustomEvents.MedicalRecordUpdated,
                async (data: {
                    record: GetMedicalRecordResponse; //
                    callback: (record: GetMedicalRecordResponse, registrations: any) => Promise<[GetMedicalRecordResponse, any]>;
                }) => {
                    // Mark the record status as saving so that
                    // the view shows the loading status.
                    this.savingMessage = this.t.tr('translation:partial-views.clinical-pathways.messages.processing');
                    this.saving = true;

                    // First update the record with the new changes.
                    // because the callback after can change the record as well.
                    // If we do the callback first this the callback changes
                    // are overidden by the line below.
                    if (isDefined(data.record)) this.activeRecord = data.record;

                    // If we have a callback, execute it.
                    // NOTE: This callback can change the record and/or registrations.
                    // It could be possible that it clears some of the registrations
                    // which can cause issues like clearing given answers/results.
                    // It shouldn't, but if issues occur, this is the one of the places to look.
                    if (isFunction(data.callback)) {
                        const [record, registrations] = await data.callback(this.activeRecord, this.registrations);
                        if (isDefined(record)) this.activeRecord = record;
                        if (isDefined(registrations)) this.registrations = registrations;
                    }

                    // Refresh all registrations in case we got new ones.
                    const response = await this.registrationsApi.search(this.authenticated.workspace.id, 1000, 0, undefined, this.activeRecord.id);

                    // Add the registrations to the list.
                    for (const registration of response.data) {
                        // Only add the registration if it doesn't exist so it doesn't override current registrations.
                        if (isNotDefined(this.registrations[registration.stepId])) this.registrations[registration.stepId] = registration;
                    }

                    // Set all the expected values for the updated registrations.
                    // NOTE: This function can override given answers/results.
                    // It could be possible that it clears some of the registrations
                    // which can cause issues like clearing given answers/results.
                    // It shouldn't, but if issues occur, this is the one of the places to look.
                    this.setAllExpectedValues();

                    // Save the current record state without validating it.
                    await this.save();
                }
            ),
            this.events.subscribe(CustomEvents.ExaminationReFlatten, (body: { triggeredByAnswer: boolean }) => {
                // Reflatten the record.
                this.flatten();
                // Only fire the event when the 'triggeredByAnswer' flag is not provided or when the flag is positive.
                if (isNotDefined(body.triggeredByAnswer) || body.triggeredByAnswer) this.events.publish(CustomEvents.ExaminationStepAnswerChanged, body);
                // Otherwise just calculate the progress.
                else this.calculateProgress();
            }),
            this.events.subscribe(CustomEvents.ExaminationStepAnswerChanged, () => this.calculateProgress()),
            this.events.subscribe(CustomEvents.MedicalRecordPhaseChange, (index: number) => this.setActive(this.activeRecord.examination.template.phases[index], index)),
            this.events.subscribe(
                CustomEvents.ExaminationEvaluateDDSettings,
                (body: {
                    requirement: DifferentialDiagnosesRequirements; //
                    stepId: string;
                    value: string;
                    action: 'added' | 'deleted';
                }) => this.evaluateDDSettings(body.requirement, body.stepId, body.value, body.action)
            ),
            this.events.subscribe(CustomEvents.HealthcareInvoicesUpdated, () => this.loadInvoices())
        );

        if (isNotDefined(this.activeRecord.referral))
            this.activeRecord.referral = new MedicalReferral({
                type: MedicalReferralTypes.InstandAccess
            });

        this.flatten();

        this.recordLoaded = true;
    }
}
