import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { EmailLogAttachment, EmailLogStatuses, EmailsApiClient, GetEmailResponse } from '@wecore/sdk-healthcare';
import { IEventAggregator, inject } from 'aurelia';
import { PartialViewResults } from '../../../enums/partial-view-results';
import { BasePartialView } from '../../../infra/base-partial-view';
import { CacheService } from '../../../infra/cache-service';
import { ErrorHandler } from '../../../infra/error-handler';
import { PartialViews } from '../../../infra/partial-views';
import { State } from '../../../infra/store/state';
import { PartialView } from '../../../models/partial-view';
import { ViewOptions } from '../../../models/view-options';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, EmailsApiClient)
export class PartialPatientContactMomentsDetails extends BasePartialView {
    public log: GetEmailResponse;
    public EmailLogStatuses: typeof EmailLogStatuses = EmailLogStatuses;

    private logId: string;

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly emailsApi: EmailsApiClient
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.logId = view.data.id;
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                const [log] = await Promise.all([
                    this.emailsApi.getById(this.logId, this.authenticated.workspace.id) //
                ]);
                this.log = log;
                this.baseLoaded = true;
            })
            .catch((x) => this.errorHandler.handle('PartialPatientContactMomentsDetails.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }

    public async cancel(): Promise<void> {
        await super.remove({
            result: PartialViewResults.Cancelled,
            updateUrl: true
        });
    }

    public async showDocument(item: EmailLogAttachment): Promise<void> {
        await this.addPartialView({
            view: this.partial.base, //
            partial: PartialViews.DocumentsPreview.with({
                entityId: item.entityId,
                entityType: item.type,
                attachmentId: item.attachment.id,
                attachmentName: item.attachment.name,
                attachmentExtension: item.attachment.extension
            }),
            options: new ViewOptions({ index: this.partial.index + 1, markItem: true, scrollToView: true, updateUrl: false })
        });
    }
}
