<import from="converters/translate" />
<import from="converters/get-ids" />
<import from="bx/bx-diagnosis-selector/bx-diagnosis-selector" />
<import from="bx/bx-therapy-multi-selector/bx-therapy-multi-selector" />

<template class="!flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex flex-col" css.bind="styles">
        <div class="flex flex-col flex-1 pr-8 overflow-hidden pl-7">
            <template if.bind="baseLoaded">
                <div class="flex flex-1 pb-5 space-x-5 overflow-hidden pt-7">
                    <div class="flex flex-col flex-1 pl-1 gap-y-3">
                        <div class="flex justify-between">
                            <p class="font-medium text-gray-400" t="translation:partial-views.medical-treatment-protocols.edit.title"></p>
                            <ux-dropdown border.bind="false" type="full" color="dark" size="sm" padding.bind="false" mode="outline" placement="bottom-right">
                                <div au-slot="label" class="p-1 text-gray-600 rounded-full ring-gray-400 focus:outline-none focus:ring-1">
                                    <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512">
                                        <path
                                            fill="currentColor"
                                            d="M64 368C90.51 368 112 389.5 112 416C112 442.5 90.51 464 64 464C37.49 464 16 442.5 16 416C16 389.5 37.49 368 64 368zM64 208C90.51 208 112 229.5 112 256C112 282.5 90.51 304 64 304C37.49 304 16 282.5 16 256C16 229.5 37.49 208 64 208zM64 144C37.49 144 16 122.5 16 96C16 69.49 37.49 48 64 48C90.51 48 112 69.49 112 96C112 122.5 90.51 144 64 144z" />
                                    </svg>
                                </div>
                                <ux-dropdown-option click.trigger="informationSheet()">
                                    <span class="text-sm" t="global.buttons.information-sheet"></span>
                                </ux-dropdown-option>
                                <ux-dropdown-option click.trigger="copy()">
                                    <span class="text-sm" t="global.buttons.copy"></span>
                                </ux-dropdown-option>
                                <ux-dropdown-divider if.bind="hasRole(UserRoles.DeleteMedicalTreatmentProtocols)"></ux-dropdown-divider>
                                <ux-dropdown-option if.bind="hasRole(UserRoles.DeleteMedicalTreatmentProtocols)" click.trigger="delete()">
                                    <span class="text-sm text-red-500" t="global.buttons.delete"></span>
                                </ux-dropdown-option>
                            </ux-dropdown>
                        </div>
                        <div class="flex flex-col">
                            <div class="flex items-center space-x-1">
                                <ux-label class="mb-1">
                                    <span t="global.labels.name"></span>
                                </ux-label>
                                <span class="flex items-center h-3 text-red-500">*</span>
                            </div>
                            <ux-textarea
                                action.bind="c => manageTranslationsFor('name', true)"
                                rows="1"
                                value.two-way="protocol.name[language]"
                                valid.bind="validation.name"
                                t="[placeholder]partial-views.medical-treatment-protocols.placeholders.name">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M10.5 21l5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 016-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 01-3.827-5.802" />
                                </svg>
                            </ux-textarea>
                        </div>
                        <div class="flex flex-col">
                            <div class="flex items-center space-x-1">
                                <ux-label class="mb-1">
                                    <span t="translation:partial-views.medical-treatment-protocols.labels.purpose"></span>
                                </ux-label>
                                <span class="flex items-center h-3 text-red-500">*</span>
                            </div>
                            <ux-textarea
                                action.bind="c => manageTranslationsFor('purpose', true)"
                                rows="3"
                                value.two-way="protocol.purpose[language]"
                                valid.bind="validation.purpose"
                                t="[placeholder]partial-views.medical-treatment-protocols.placeholders.purpose">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M10.5 21l5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 016-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 01-3.827-5.802" />
                                </svg>
                            </ux-textarea>
                        </div>
                        <div class="flex flex-col">
                            <div class="flex items-center space-x-1">
                                <ux-label class="mb-1">
                                    <span t="partial-views.medical-treatment-protocols.labels.select-diagnosis"></span>
                                </ux-label>
                            </div>
                            <bx-diagnosis-selector
                                on-select.bind="handleDiagnosisSelected"
                                language.bind="language"
                                workspace.bind="authenticated.workspace.id"
                                t="[placeholder]translation:partial-views.medical-treatment-protocols.placeholders.select-diagnosis"
                                valid.bind="validation.diagnosis"
                                value.bind="protocol.differentialDiagnosis.id">
                            </bx-diagnosis-selector>
                        </div>
                        <div class="flex flex-col">
                            <div class="flex items-center space-x-1">
                                <ux-label class="mb-1">
                                    <span t="translation:partial-views.medical-treatment-protocols.labels.select-therapies"></span>
                                </ux-label>
                            </div>
                            <bx-therapy-multi-selector
                                workspace.bind="authenticated.workspace.id"
                                on-change.bind="handleTherapySelected"
                                language.bind="language"
                                on-value-click.bind="setDefaultValues"
                                valid.bind="validation.therapies"
                                t="[placeholder]translation:partial-views.medical-treatment-protocols.placeholders.select-therapies"
                                values.bind="protocol.therapies | getIds:'therapy'">
                            </bx-therapy-multi-selector>
                        </div>
                        <div class="flex flex-col">
                            <ux-label class="mb-1">
                                <span t="global.labels.healthcare-sector"></span>
                            </ux-label>
                            <ux-multi-selector values.two-way="protocol.healthcareSectors" type="healthcare-sectors"></ux-multi-selector>
                        </div>
                    </div>
                </div>
            </template>
            <template else>
                <div class="flex flex-1">
                    <div class="flex flex-1 pt-8 pb-5 space-x-6 overflow-hidden">
                        <div class="flex flex-1">
                            <div class="flex flex-col flex-1">
                                <div class="grid grid-cols-2 gap-5">
                                    <ux-skeleton class="col-span-2" show-background="true" type="container">
                                        <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                            <ux-skeleton type="line" padding="pr-60"></ux-skeleton>
                                            <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                        </div>
                                    </ux-skeleton>
                                    <ux-skeleton class="col-span-2" show-background="true" type="container">
                                        <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                            <ux-skeleton type="line" padding="pr-20"></ux-skeleton>
                                            <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                        </div>
                                    </ux-skeleton>
                                    <ux-skeleton class="col-span-2" show-background="true" type="container">
                                        <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                            <ux-skeleton type="line" padding="pr-20"></ux-skeleton>
                                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                            <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                                            <ux-skeleton type="line" padding="pr-8"></ux-skeleton>
                                            <ux-skeleton type="line" padding="pr-6"></ux-skeleton>
                                            <ux-skeleton type="line" padding="pr-0"></ux-skeleton>
                                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                        </div>
                                    </ux-skeleton>
                                    <ux-skeleton class="col-span-2" show-background="true" type="container">
                                        <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                            <ux-skeleton type="line" padding="pr-20"></ux-skeleton>
                                            <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                        </div>
                                    </ux-skeleton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <div class="sticky bottom-0 z-50 flex items-center justify-between py-4 bg-white border-t border-gray-200 gap-x-2">
                <div class="flex gap-x-2">
                    <p if.bind="!validation.valid" class="text-xs text-red-500" t="translation:global.messages.not-all-values-entered"></p>
                </div>
                <div class="flex gap-x-2">
                    <ux-button size="sm" color="dark" mode="outline" disabled.bind="isLoading" click.trigger="cancel()">
                        <span class="pointer-events-none" t="global.buttons.cancel"></span>
                    </ux-button>
                    <ux-button size="sm" color="success" disabled.bind="isLoading" loading.bind="isLoading" click.trigger="save(true)">
                        <span class="pointer-events-none" t="global.buttons.save"></span>
                    </ux-button>
                </div>
            </div>
        </div>
    </section>
</template>
