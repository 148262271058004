<import from="converters/translate" />
<import from="./template-therapy-category" />
<import from="../template-therapy-step/template-therapy-step" />

<div ref="container" class="${columns} flex flex-col">
    <div id="therapy-category-grid-${item.id}" ref="grid" class="flex flex-col">
        <div class="${ editMode ? 'space-x-4' : '' } flex items-center justify-between rounded-lg bg-gray-100 pr-3 text-sm font-medium text-gray-800">
            <a href="javascript:" click.trigger="collapseOrExpand($event)" class="flex items-center justify-between flex-1 min-w-0 py-2 pl-3 pr-2 gap-x-1">
                <div class="flex items-center min-w-0 gap-1">
                    <div class="flex min-w-[1.2rem]">
                        <template if.bind="item.attributes.expanded">
                            <svg class="h-[1.2rem] w-[1.2rem] text-indigo-500" fill="none" viewBox="0 0 24 24">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M9.25 19.25V16.75C9.25 15.6454 8.35457 14.75 7.25 14.75H4.75"></path>
                                <path
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="1.5"
                                    d="M14.75 19.25V16.75C14.75 15.6454 15.6454 14.75 16.75 14.75H19.25"></path>
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M14.75 4.75V7.25C14.75 8.35457 15.6454 9.25 16.75 9.25H19.25"></path>
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M9.25 4.75V7.25C9.25 8.35457 8.35457 9.25 7.25 9.25H4.75"></path>
                            </svg>
                        </template>
                        <template else>
                            <svg class="h-[1.2rem] w-[1.2rem] text-indigo-500" fill="none" viewBox="0 0 24 24">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M4.75 14.75V17.25C4.75 18.3546 5.64543 19.25 6.75 19.25H9.25"></path>
                                <path
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="1.5"
                                    d="M19.25 14.75V17.25C19.25 18.3546 18.3546 19.25 17.25 19.25H14.75"></path>
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M19.25 9.25V6.75C19.25 5.64543 18.3546 4.75 17.25 4.75H14.75"></path>
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M4.75 9.25V6.75C4.75 5.64543 5.64543 4.75 6.75 4.75H9.25"></path>
                            </svg>
                        </template>
                    </div>
                    <div class="flex items-center min-w-0">
                        <span class.bind="text-gray-800" class="block truncate"> ${ item.category.name | translate:language } </span>
                    </div>
                </div>
            </a>
        </div>
        <template if.bind="item.attributes.expanded">
            <div class="grid grid-cols-12 gap-1 pl-2 mt-1 ml-1 border-l-2 border-gray-300">
                <template repeat.for="step of item.category.stepsToTake">
                    <template if.bind="step.type === MedicalTherapyPlanItemTypes.Category">
                        <template-therapy-category
                            component.ref="states[step.id].model"
                            item.bind="step"
                            flow.bind="flow"
                            def.bind="def"
                            states.bind="states"
                            get-flat.bind="getFlat"></template-therapy-category>
                    </template>
                    <template else>
                        <template-therapy-step
                            component.ref="states[step.id].model"
                            item.bind="step"
                            flow.bind="flow"
                            def.bind="def"
                            states.bind="states"
                            get-flat.bind="getFlat"></template-therapy-step>
                    </template>
                </template>
            </div>
        </template>
    </div>
</div>
