import { I18N } from '@aurelia/i18n';
import { AttachmentEntities, AttachmentsApiClient } from '@wecore/sdk-attachments';

import { BlobStorageAttachment } from '@wecore/sdk-crm';
import { FileType, GetMedicalRecordRegistrationResponse, GetMedicalRecordResponse, InputTypes, MedicalResult, MedicalTherapyPlanItem, ResultTypes } from '@wecore/sdk-healthcare';
import { guid, isDefined, isNotDefined, serveBlob } from '@wecore/sdk-utilities';

import { IEventAggregator, bindable, containerless, inject } from 'aurelia';
import { CustomEvents } from '../../../../../../../infra/events';
import { cloneDeep, fileIsImage, generateColumns, generateResultPlaceholder, getFileTypeTranslation, getSettingsId } from '../../../../../../../infra/utilities';
import { ConfirmationOptions } from '../../../../../../../models/confirmation-options';
import { FlattenedExaminationStep } from '../../../../../../../models/flattened-examination-step';
import { SelectedFile } from '../../../../../../../models/selected-file';
import { StepState } from '../../../../../../../models/step-state';
import { ModalService } from '../../../../../../../services/service.modals';
import { UxMultiSelector } from '../../../../../../../ux/ux-multi-selector/ux-multi-selector';
import { UxRangeSlider } from '../../../../../../../ux/ux-range-slider/ux-range-slider';
import { UxSelect } from '../../../../../../../ux/ux-select/ux-select';

@containerless
@inject(I18N, AttachmentsApiClient, ModalService, IEventAggregator)
export class TemplateTherapyStep {
    @bindable() public flattened: FlattenedExaminationStep[];
    @bindable() public record: GetMedicalRecordResponse;
    @bindable() public item: MedicalTherapyPlanItem;
    @bindable() public registration: GetMedicalRecordRegistrationResponse;
    @bindable() public registrations: { [key: string]: GetMedicalRecordRegistrationResponse };
    @bindable() public validation: any;
    @bindable() public category: MedicalTherapyPlanItem;
    @bindable() public xScrollContainer: string;
    @bindable() public workspace: string;
    @bindable() public states: { [key: string]: StepState };
    @bindable() public language: string;
    @bindable() public onFileSelected: (file: SelectedFile) => void;
    @bindable() public onFileRemoved: (file: SelectedFile, attachment: BlobStorageAttachment) => void;
    @bindable() public loading: (show: boolean) => void;

    public ResultTypes: typeof ResultTypes = ResultTypes;
    public InputTypes: typeof InputTypes = InputTypes;
    public uploads: any[] = [];
    public contentTypes: string;
    public columns: string;
    public required: boolean = false;
    public attachments: BlobStorageAttachment[] = [];
    public urls: any = {};
    public toManyFiles: boolean = false;
    public wrongFileTypes: boolean = false;
    public shouldBeDisplayed: boolean = false;
    public container: HTMLDivElement;
    public grid: HTMLDivElement;
    public buddy: FlattenedExaminationStep;
    public hasConnections: boolean = false;
    public selector: UxSelect;
    public multiSelector: UxMultiSelector;
    public slider: UxRangeSlider;

    private subscriptions: any[] = [];

    public constructor(
        public t: I18N, //
        private readonly attachmentsApi: AttachmentsApiClient,
        private readonly modalService: ModalService,
        private readonly events: IEventAggregator
    ) {}

    public attached(): void {
        // Set the expanded default state.
        if (isNotDefined(this.states[this.item.id]))
            this.states[this.item.id] = new StepState({
                stepId: this.item.id,
                expanded: false,
                answered: false
            });

        // Generate the configured column width for the current question.
        this.columns = generateColumns(
            this.registration.therapyPlan.value.flow.breakpoints?.filter((x) => x.id === getSettingsId(this.item)) || [] //
        );

        // Wait for all steps to be rendered.
        setTimeout(() => {
            const connected = this.registration.therapyPlan.value.flow.connectedSteps.filter((x) => x.key === getSettingsId(this.item));
            for (const item of connected) {
                const flatOfElementToObserve = this.flattened.find((x) => x.item.copiedFrom === item.value);
                // We are going to observe the grid elements of the connected step.
                // Note that we are not observing the container element of the current step
                // because we are going to change the heights of the container which can
                // retrigger the observer callback, which can cause an infinite loop.
                const elementToObserve = document.getElementById(`therapy-plan-step-grid-${flatOfElementToObserve.item.copiedFrom}-${this.registration.id}`);
                // Get the viewmodel of the connect step.
                const viewModelOfElementToObserve = this.states[flatOfElementToObserve.item.id].model as unknown as TemplateTherapyStep;
                const observer = new ResizeObserver((entries) => {
                    // Request a frame to prevent resize loops
                    window.requestAnimationFrame((): void | undefined => {
                        if (!Array.isArray(entries) || !entries.length) {
                            return;
                        }
                        if (isNotDefined(this.container) || isNotDefined(this.grid)) return;
                        // Get the heighest height of all the observed elements.
                        const height = Math.max(...entries.map((x) => x.contentRect.height));

                        if (height === 0) {
                            this.matchContainerSizeToGridSize();
                            viewModelOfElementToObserve?.matchContainerSizeToGridSize();
                        } else if (height > this.grid.clientHeight) {
                            // Height is of the observered element is bigger than the grid height.
                            this.setHeight(height);
                            viewModelOfElementToObserve?.matchContainerSizeToGridSize();
                        } else if (height === this.grid.clientHeight) {
                            // The height of the observered element is the same as the grid height.
                            this.setHeight(height);
                            viewModelOfElementToObserve?.matchContainerSizeToGridSize();
                        } else if (this.grid.clientHeight > height) {
                            // The height of the grid is bigger than the height of the observered element.
                            viewModelOfElementToObserve?.setHeight(this.grid.clientHeight);
                        }
                    });
                });

                if (isDefined(elementToObserve)) observer.observe(elementToObserve);
                this.states[this.item.id].observer = observer;
            }
        });

        // Create a description string of all allowed file types.
        this.contentTypes = this.item?.step?.allowedFileTypes?.selectMany<FileType, string>((x) => x.contentTypes).join(',');

        // Find previously selected attachments if any.
        const attachments = this.item.step.attachments?.map((id) => this.record.attachments.find((y) => y.id === id)).filter((x) => isDefined(x)) || [];
        Promise.all(
            attachments.map(async (x) => {
                const id = isDefined(x.thumbnail) ? x.thumbnail.id : x.id;
                const url = await this.attachmentsApi.getUrl(this.record.id, id, this.workspace, AttachmentEntities.MedicalRecords);
                this.urls[x.id] = url;
            })
        ).then(() => (this.attachments = attachments));

        // Subscribe to the ExaminationStepAnswered event so that we can evaluate
        // whether or not to show the current question when a step is answered.
        this.subscriptions = [
            ...(this.subscriptions ?? []),
            this.events.subscribe(CustomEvents.ExaminationStepAnswerChanged, () => this.evaluateSettings()),
            this.events.subscribe(
                CustomEvents.ExaminationStepAnswerCopy,
                (data: {
                    step: MedicalTherapyPlanItem; //
                    result: MedicalResult;
                    results: MedicalResult[];
                }) => {
                    // No copying when the current step is not visible.
                    if (!this.shouldBeDisplayed) return;
                    // We look for all the connected step of the step that triggered the copy.
                    const connected = this.registration.therapyPlan.value.flow.connectedSteps.find((x) => x.key === data.step.copiedFrom && x.value === this.item.copiedFrom);
                    if (isNotDefined(connected)) return;
                    // Copy values.
                    this.item.step.result = data.result;
                    this.item.step.results = data.results;
                    // Trigger the answer changed event.
                    if (isDefined(this.item.step.result.value)) {
                        this.handleStepAnswered();
                        if (isDefined(this.selector)) this.selector.setValue(this.item.step.result.value);
                    } else {
                        this.handleStepAnswered();
                        if (isDefined(this.multiSelector)) this.multiSelector.refresh();
                    }

                    if (isDefined(this.slider)) this.slider.setValue(this.item.step.result.value);
                }
            )
        ];

        // Evaluate the requirements on load of the therapy step.
        this.evaluateSettings();
        this.hasConnections = this.registration.therapyPlan.value.flow.connectedSteps.some((x) => x.key === this.item.copiedFrom);
        this.states[this.item.id].answered = this.uploads.any() || this.attachments.any();
    }

    public detaching(): void {
        this.subscriptions.forEach((x) => x.dispose());
        if (isDefined(this.states[this.item.id].observer)) this.states[this.item.id].observer.disconnect();
    }

    public getPlaceholder(): string {
        const generated = generateResultPlaceholder(this.t, this.item.step.inputType, this.item.step.resultType);
        return isDefined(this.item.step.placeholder) ? this.item.step.placeholder[this.language] || generated : generated;
    }

    public handleMultiChoiceSelected = (option: { value: string; text: string }) => {
        this.item.step.results.push(new MedicalResult({ value: option.value }));
        this.handleStepAnswered();
    };

    public handleMultiChoiceRemoved = (_: { value: string; text: string }, index: number) => {
        this.item.step.results.splice(index, 1);
        this.handleStepAnswered();
    };

    public handleResultSelected(): void {
        this.handleStepAnswered();
    }

    public filesSelected = (files: File[]): void => {
        if (this.item.step.filesAmount > 0 && this.uploads.length >= this.item.step.filesAmount) {
            this.toManyFiles = true;
            return;
        }

        if (
            isDefined(this.item.step.allowedFileTypes) &&
            this.item.step.allowedFileTypes.any() &&
            files.some((file) => this.item.step.allowedFileTypes.every((x) => x.contentTypes.every((type) => type !== file.type)))
        ) {
            this.wrongFileTypes = true;
            return;
        }

        for (const file of files) {
            const upload = new SelectedFile({
                id: guid(),
                step: this.item.id,
                type: 'step',
                file,
                progress: 0,
                loader: null,
                statusLabel: this.t
                    .tr('translation:partial-views.clinical-pathways.labels.status-waiting')
                    .replace('{entity}', `<span class="font-medium block mx-1">${file.name.toLowerCase()}</span>`),
                extension: `.${file.name.split('.').pop()}`,
                name: file.name,
                registration: this.registration.id,
                isLoading: false
            });

            this.uploads.push(upload);

            // Add the uploads to a temporary array. This way we can check if the step is answered or not.
            if (isNotDefined(this.item.step.attributes)) this.item.step.attributes = { uploads: [] };
            if (isNotDefined(this.item.step.attributes.uploads)) this.item.step.attributes.uploads = [];
            this.item.step.attributes.uploads.push(upload.id);

            this.onFileSelected(upload);
        }

        this.states[this.item.id].answered = this.uploads.any() || this.attachments.any();

        this.handleStepAnswered();
    };

    public isImage(contentType: string): boolean {
        return fileIsImage(contentType);
    }

    public deleteFromUploads(index: number): void {
        const file = this.uploads[index];

        this.uploads.splice(index, 1);

        this.onFileRemoved(file, null);
        this.states[this.item.id].answered = this.uploads.any() || this.attachments.any();

        // Remove from temp uploads
        if (isDefined(this.item.step.attributes?.uploads)) {
            this.item.step.attributes.uploads.splice(index, 1);
        }

        this.handleStepAnswered();
    }

    public async deleteAttachment(index: number): Promise<void> {
        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('translation:partial-views.clinical-pathways.questions.delete-attachment.title'),
                message: this.t.tr('translation:partial-views.clinical-pathways.questions.delete-attachment.message'),
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        const attachment = this.attachments[index];
                        const i = this.item.step.attachments.findIndex((id) => id === attachment.id);
                        this.item.step.attachments.splice(i, 1);

                        this.attachments.splice(index, 1);

                        this.onFileRemoved(null, attachment);
                        this.states[this.item.id].answered = this.uploads.any() || this.attachments.any();

                        this.handleStepAnswered();
                    }
                }
            })
        );
    }

    public async openMenu(event: MouseEvent, index: number, type: 'attachment' | 'upload'): Promise<void> {
        await this[`${type}Menus`][index].show(event);
    }

    public getFileTypes(): string {
        return this.item.step.allowedFileTypes.map((x) => this.t.tr(getFileTypeTranslation(x.name))).join(', ');
    }

    public async open(attachment: BlobStorageAttachment): Promise<void> {
        const url = await this.attachmentsApi.getUrl(this.record.id, attachment.id, this.workspace, AttachmentEntities.MedicalRecords);
        window.open(url, '_blank');
    }

    public async download(attachment: BlobStorageAttachment): Promise<void> {
        if (isDefined(attachment)) {
            const blob = await this.attachmentsApi.download(this.record.id, attachment.id, this.workspace, AttachmentEntities.MedicalRecords);
            serveBlob(blob.data, `${attachment.name}${attachment.extension}`);
        }
    }

    public collapseOrExpand(): void {
        this.item.attributes.expanded = !this.item.attributes.expanded;
    }

    public handleBlur(): void {
        if ((this.item.step.inputType === InputTypes.FreeEntry && this.item.step.results.any()) || isDefined(this.item.step.result?.value)) this.handleStepAnswered();
    }

    public toSet(choices: MedicalResult[]): { value: string; text: string; data?: any }[] {
        return choices.map((x) => ({ value: x.value, text: x.value }));
    }

    public handleAnswerCleared(): void {
        this.handleStepAnswered();
    }

    public setHeight(height: number): void {
        if (isNotDefined(this.container)) return;
        if (height === 0) return;
        this.container.style.height = `${height + (height > 0 ? 4 : 0)}px`;
    }

    public copyValue(): void {
        this.events.publish(CustomEvents.ExaminationStepAnswerCopy, {
            step: this.item,
            result: MedicalResult.fromJS(cloneDeep(this.item.step.result)),
            results: this.item.step.results.map((x) => MedicalResult.fromJS(cloneDeep(x)))
        });
    }

    private handleStepAnswered(): void {
        this.loading(true);
        // Allow the height of the step, when answered some time
        // to propergate its height through the DOM.
        setTimeout(() => {
            // Let all listening steps that the current step has been answered.
            this.events.publish(CustomEvents.ExaminationReFlatten, {
                step: this.item,
                category: this.category,
                container: this.registration.id,
                height: this.grid?.clientHeight || 0
            });
        }, 50);
        this.loading(false);
    }

    private evaluateSettings(): void {
        const flattened = this.flattened.find((x) => x.item.id === this.item.id);
        this.shouldBeDisplayed = flattened?.isVisible ?? true;
        this.required = flattened?.isRequired ?? false;

        if (!this.shouldBeDisplayed) {
            this.item.step.result.value = null;
            this.item.step.results = [];
            this.removeAllAttachmentsAndUploads();
        }

        // If both current item and its buddy (when it has one) are not visible, we can shrink the content to zero height.
        const connected = this.registration.therapyPlan.value.flow.connectedSteps.filter((x) => x.key === this.item.copiedFrom);
        if (connected.any()) {
            const buddy = this.flattened.find((x) => x.container?.id === this.registration.id && x.item.copiedFrom === connected[0].value);
            if (isDefined(this.container) && isDefined(buddy) && !buddy.isVisible && !flattened.isVisible) this.container.style.height = '0px';

            this.buddy = buddy;
        }
    }

    private removeAllAttachmentsAndUploads(): void {
        if (this.item.step.resultType !== ResultTypes.File) return;

        for (const attachment of this.attachments) {
            const i = this.attachments.findIndex((a) => a.id === attachment.id);
            this.attachments.splice(i, 1);
            this.onFileRemoved(null, attachment);
        }

        for (const upload of this.uploads) this.onFileRemoved(upload, null);

        this.uploads = [];
        this.attachments = [];
        this.states[this.item.id].answered = false;
    }

    private matchContainerSizeToGridSize(): void {
        // Allow the step some time to resize and render its DOM content so that we get the correct sizes.
        if (isNotDefined(this.container) || isNotDefined(this.grid)) return;
        if (this.grid.offsetHeight === 0) return;
        // Make sure the content height is the same as the grid height (dynamic)
        // if (this.container.offsetHeight < this.grid.offsetHeight || force) {
        // Set the height of the container to the height of the grid.
        this.container.style.height = `${this.grid.offsetHeight + (this.grid.offsetHeight > 0 ? 4 : 0)}px`;
    }
}
