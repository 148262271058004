<import from="bx/bx-scheduler/bx-scheduler" />

<template class="relative !flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex flex-1 overflow-hidden gap-x-2" css.bind="styles">
        <bx-scheduler
            if.bind="baseLoaded"
            authenticated-user.bind="authenticated.user"
            workspace.bind="authenticated.workspace.id"
            language.bind="language"
            view-width.bind="viewWidth"
            scheduler-state.two-way="schedulerState"
            set-view-width.bind="setViewWidth"
            min-view-width.bind="partial.minWidth"
            constrain-view-width.two-way="constrainViewWidth"
            has-role.bind="hasRole"
            on-task-click.bind="handleTaskClick"
            on-new-scheduler-item.bind="handleSchedulerItemCreate"
            on-scheduler-item-click.bind="handleSchedulerItemClick"
            on-scheduler-item-create.bind="handleSchedulerItemCreate"
            on-scheduler-item-edit.bind="handleSchedulerItemEdit"
            on-scheduler-item-delete.bind="handleSchedulerItemDelete"
            on-scheduler-item-move.bind="handleSchedulerItemResizedOrMoved"
            on-scheduler-item-resize.bind="handleSchedulerItemResizedOrMoved"
            on-scheduler-item-details.bind="handleSchedulerItemDetails"
            on-patient-card.bind="handlePatientCard"
            on-confirm-scheduler-item.bind="handleConfirmSchedulerItem"
            on-mark-no-show.bind="handleMarkNoShow"
            on-unmark-no-show.bind="handleUnmarkNoShow"
            on-mark-cancelled.bind="handleMarkCancelled"
            on-unmark-cancelled.bind="handleUnmarkCancelled">
        </bx-scheduler>
        <!-- <div else class="flex items-center justify-between">
            <span if.bind="checkingSchedule" class="text-sm" t="translation:partial-views.scheduler-items.messages.searching-schedules"></span>
            <span else class="text-sm" t="translation:partial-views.scheduler-items.messages.no-schedule-found"></span>
            <ux-spinner if.bind="checkingSchedule" size="xxs"></ux-spinner>
        </div> -->
        <div else class="flex items-center justify-center flex-1">
            <ux-spinner size="xs"></ux-spinner>
        </div>
    </section>
    <div if.bind="partial.canAdjustWidth" mousedown.trigger="handleResizeMouseDown($event)" class="absolute right-0 z-[99] h-full w-2 hover:cursor-col-resize hover:bg-gray-100/50"></div>
</template>
