import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import {
    DifferentialDiagnosisEntityReference,
    GetDifferentialDiagnosisResponse,
    GetMedicalTherapyResponse,
    InformationSheet,
    MedicalTherapyEntityReference,
    MedicalTherapyProtocol,
    MedicalTreatmentProtocolsApiClient,
    TherapyDefaultValues,
    UpdateMedicalTreatmentProtocolRequest
} from '@wecore/sdk-healthcare';
import { guid, validateState } from '@wecore/sdk-utilities';

import { IEventAggregator, inject } from 'aurelia';
import { PartialViewResults } from '../../../enums/partial-view-results';
import { BasePartialView } from '../../../infra/base-partial-view';
import { CacheService } from '../../../infra/cache-service';
import { ErrorHandler } from '../../../infra/error-handler';
import { PartialViews } from '../../../infra/partial-views';
import { clearTherapyEvaluationItem } from '../../../infra/store/actions/copy-paste';
import { State } from '../../../infra/store/state';
import { cleanTranslatables, validateTranslation } from '../../../infra/utilities';
import { ConfirmationOptions } from '../../../models/confirmation-options';
import { PartialView } from '../../../models/partial-view';
import { ViewOptions } from '../../../models/view-options';
import { ModalService } from '../../../services/service.modals';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, MedicalTreatmentProtocolsApiClient, ModalService)
export class PartialMedicalTreatmentProtocolsEdit extends BasePartialView {
    public protocolId: string;
    public protocol: UpdateMedicalTreatmentProtocolRequest;
    public validation = {
        name: true,
        purpose: true,
        diagnosis: true,
        therapies: true,
        valid: true
    };

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly protocolsApi: MedicalTreatmentProtocolsApiClient,
        private readonly modalService: ModalService
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.protocolId = view.data.id;
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                this.protocol = await this.protocolsApi.getById(this.protocolId, this.authenticated.workspace.id);
                // Delay showing content to prevent flickering.
                setTimeout(async () => {
                    this.baseLoaded = true;
                    await super.handleBaseLoaded();
                }, 250);
            })
            .catch((x) => this.errorHandler.handle('PartialMedicalTreatmentProtocolsEdit.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached, updateUrl: false });
        this.store.dispatch(clearTherapyEvaluationItem);
    }

    public async cancel(): Promise<void> {
        await super.remove({
            result: PartialViewResults.Cancelled,
            updateUrl: true
        });
    }

    public handleDiagnosisSelected = (diagnosis: GetDifferentialDiagnosisResponse, index: number): void => {
        this.protocol.differentialDiagnosis = new DifferentialDiagnosisEntityReference({
            id: diagnosis.id,
            translations: diagnosis.name
        });
    };

    public handleTherapySelected = (_: GetMedicalTherapyResponse[], action: 'added' | 'deleted', therapy: GetMedicalTherapyResponse): void => {
        if (action === 'added') {
            this.protocol.therapies.push(
                new MedicalTherapyProtocol({
                    id: guid(),
                    therapy: new MedicalTherapyEntityReference({
                        id: therapy.id,
                        translations: therapy.name
                    })
                })
            );
        } else {
            const index = this.protocol.therapies.findIndex((x) => x.therapy.id === therapy.id);
            if (index >= 0) this.protocol.therapies.splice(index, 1);
            // Also remove the default values.
            this.protocol.defaultTherapyValues = this.protocol.defaultTherapyValues.filter((x) => x.therapy.id !== therapy.id);
        }
    };

    public async save(): Promise<void> {
        const valid = this.validate();

        if (valid) {
            this.isLoading = true;
            try {
                cleanTranslatables(['name', 'purpose'], this.protocol, 1);
                await this.protocolsApi.update(this.protocolId, this.authenticated.workspace.id, this.protocol);
                this.notifications.show(
                    this.t.tr('translation:partial-views.medical-treatment-protocols.notifications.save-successful.title'),
                    this.t
                        .tr('translation:partial-views.medical-treatment-protocols.notifications.save-successful.message') //
                        .replace('{entity}', `<span>'${this.protocol.name[this.language]}'</span>`),
                    { type: 'success', duration: 3000 }
                );
                setTimeout(async () => this.remove({ result: PartialViewResults.Ok, updateUrl: true }), 250);
            } catch (e) {
                this.isLoading = false;
                await this.errorHandler.handle('[create-medical-treatment]', e);
            }
        }
    }

    public async copy(): Promise<void> {
        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('translation:partial-views.medical-treatment-protocols.questions.copy.title'),
                message: this.t.tr('translation:partial-views.medical-treatment-protocols.questions.copy.message'),
                btnOk: this.t.tr('translation:global.buttons.copy'),
                type: 'warning',
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        try {
                            await this.protocolsApi.copy(this.protocolId, this.authenticated.workspace.id);
                            this.notifications.show(
                                this.t.tr('translation:partial-views.medical-treatment-protocols.notifications.copied-successfully.title'),
                                this.t.tr('translation:partial-views.medical-treatment-protocols.notifications.copied-successfully.message'),
                                { type: 'success', duration: 3000 }
                            );
                            setTimeout(async () => this.remove({ result: PartialViewResults.Ok, updateUrl: true }), 250);
                        } catch (e) {
                            await this.errorHandler.handle('[copy-treatment-protocol]', e);
                        }
                    }
                }
            })
        );
    }

    public async informationSheet(): Promise<void> {
        this.addPartialView({
            view: this.partial.base,
            partial: this.PartialViews.InformationSheet.with({
                config: {
                    mode: 'edit',
                    language: this.language
                },
                sheet: this.protocol.informationSheet
            }).whenClosed(async (result: PartialViewResults, sheet: InformationSheet) => {
                if (result === PartialViewResults.Ok) {
                    this.protocol.informationSheet = sheet;
                }
            }),
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                markItem: true,
                replace: true
            })
        });
    }

    public setDefaultValues = async (option: { value: string; text: string; data?: any }): Promise<void> => {
        await this.removeChildViews();
        const defaultValues =
            this.protocol.defaultTherapyValues.find((x) => x.therapy.id === option.value) ?? //
            new TherapyDefaultValues({
                therapy: new MedicalTherapyEntityReference({
                    id: option.value
                }),
                defaultValues: []
            });

        await this.addPartialView({
            view: this.partial.base,
            partial: PartialViews.MedicalTreatmentProtocolDefaultValues.with({ id: option.value, defaultValues }).whenClosed(async (result: PartialViewResults, values: TherapyDefaultValues) => {
                if (result !== PartialViewResults.Ok) return;
                const index = this.protocol.defaultTherapyValues.findIndex((x) => x.therapy.id === values.therapy.id);
                if (index > -1) this.protocol.defaultTherapyValues[index] = TherapyDefaultValues.fromJS(values);
                else this.protocol.defaultTherapyValues.push(TherapyDefaultValues.fromJS(values));
            }),
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                replace: true,
                markItem: true,
                updateUrl: false
            })
        });
    };

    public async delete(): Promise<void> {
        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('partial-views.medical-treatment-protocols.questions.delete.title'),
                message: this.t
                    .tr('partial-views.medical-treatment-protocols.questions.delete.message') //
                    .replace('{entity}', `<span>'${this.protocol.name[this.language]}'</span>`),
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        this.deleting = true;
                        try {
                            await this.protocolsApi.delete(this.protocolId, this.authenticated.workspace.id);
                            this.notifications.show(
                                this.t.tr('translation:partial-views.medical-treatment-protocols.notifications.deleted-successfully.title'),
                                this.t
                                    .tr('translation:partial-views.medical-treatment-protocols.notifications.deleted-successfully.message') //
                                    .replace('{entity}', `<span>'${this.protocol.name[this.language]}'</span>`),
                                { type: 'success', duration: 3000 }
                            );
                            setTimeout(async () => this.remove({ result: PartialViewResults.Deleted, updateUrl: true }), 250);
                        } catch (e) {
                            this.deleting = false;
                            await this.errorHandler.handle('[delete-medical-examination-detail]', e);
                        }
                    }
                }
            })
        );
    }

    private validate(): boolean {
        this.validation.name = validateTranslation(this.protocol.name, this.language);
        this.validation.purpose = validateTranslation(this.protocol.purpose, this.language);
        // this.validation.diagnosis = isDefined(this.protocol.differentialDiagnosis);
        // this.validation.therapies = this.protocol.therapies.any();

        this.validation.valid = validateState(this.validation);

        return this.validation.valid;
    }
}
