<import from="converters/translate" />
<import from="converters/side-to-string" />
<import from="../template-therapy-category/template-therapy-category" />
<import from="../template-therapy-step/template-therapy-step" />

<div class="flex flex-col">
    <div
        bg-gray-100.class="validation.plans[validationIndex].valid"
        bg-red-100.class="!validation.plans[validationIndex].valid"
        pb-3.class="state.expanded && (item.protocols.length > 0 || item.results.length > 1)"
        class="flex flex-col px-3 bg-gray-100 rounded-lg">
        <div class="flex items-center" py-2.class="editLabelMode">
            <input type="checkbox" change.trigger="handleItemChecked($event)" hover:cursor-pointer.class="!registration.therapyPlan.startedTreatment" model.bind="item" checked.bind="selection" />
            <a href="javascript:" click.trigger="collapseOrExpand($event)" cla class="flex items-center justify-between flex-1 min-w-0 py-2 pr-3 ml-2 text-sm gap-x-1">
                <div class="flex items-center min-w-0 gap-1">
                    <div class="flex min-w-[1.2rem]">
                        <template if.bind="state.expanded">
                            <svg class="h-[1.2rem] w-[1.2rem] text-indigo-500" fill="none" viewBox="0 0 24 24">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M9.25 19.25V16.75C9.25 15.6454 8.35457 14.75 7.25 14.75H4.75"></path>
                                <path
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="1.5"
                                    d="M14.75 19.25V16.75C14.75 15.6454 15.6454 14.75 16.75 14.75H19.25"></path>
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M14.75 4.75V7.25C14.75 8.35457 15.6454 9.25 16.75 9.25H19.25"></path>
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M9.25 4.75V7.25C9.25 8.35457 8.35457 9.25 7.25 9.25H4.75"></path>
                            </svg>
                        </template>
                        <template else>
                            <svg class="h-[1.2rem] w-[1.2rem] text-indigo-500" fill="none" viewBox="0 0 24 24">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M4.75 14.75V17.25C4.75 18.3546 5.64543 19.25 6.75 19.25H9.25"></path>
                                <path
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="1.5"
                                    d="M19.25 14.75V17.25C19.25 18.3546 18.3546 19.25 17.25 19.25H14.75"></path>
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M19.25 9.25V6.75C19.25 5.64543 18.3546 4.75 17.25 4.75H14.75"></path>
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M4.75 9.25V6.75C4.75 5.64543 5.64543 4.75 6.75 4.75H9.25"></path>
                            </svg>
                        </template>
                    </div>
                    <div class="flex items-center min-w-0">
                        <span bg-gray-100.class="validation.plans[validationIndex].valid" bg-red-100.class="!validation.plans[validationIndex].valid" class="block truncate">
                            ${item.therapy.translations | translate:language}
                        </span>
                    </div>
                </div>
            </a>
            <div class="flex items-center gap-4">
                <ux-input
                    if.bind="editLabelMode"
                    autofocus="true"
                    focus-delay="400"
                    onkeydown.trigger="handleInputChanged($event)"
                    action.bind="c => manageTranslationsFor()"
                    value.two-way="item.therapyPlan.label[language]"
                    t="[placeholder]translation:components.widgets.examination-treatment.placeholders.add-label">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M10.5 21l5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 016-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 01-3.827-5.802" />
                    </svg>
                </ux-input>
                <div class="flex items-center gap-1">
                    <p class="text-sm text-indigo-500" if.bind="!editLabelMode">${ item.therapyPlan.label[language] || '' }</p>
                    <span
                        if.bind="registration.therapyPlan.startedTreatment"
                        class="text-xs text-gray-400"
                        t="translation:components.widgets.examination-treatment-plan.messages.treatment-started"></span>
                    <ux-dropdown transparent.bind="true" border.bind="false" type="full" color="dark" size="sm" padding.bind="false" mode="outline" placement="bottom-right">
                        <div au-slot="label" class="p-1 text-gray-600 rounded-full ring-gray-400 focus:outline-none focus:ring-1">
                            <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512">
                                <path
                                    fill="currentColor"
                                    d="M64 368C90.51 368 112 389.5 112 416C112 442.5 90.51 464 64 464C37.49 464 16 442.5 16 416C16 389.5 37.49 368 64 368zM64 208C90.51 208 112 229.5 112 256C112 282.5 90.51 304 64 304C37.49 304 16 282.5 16 256C16 229.5 37.49 208 64 208zM64 144C37.49 144 16 122.5 16 96C16 69.49 37.49 48 64 48C90.51 48 112 69.49 112 96C112 122.5 90.51 144 64 144z" />
                            </svg>
                        </div>
                        <ux-dropdown-option click.trigger="addLabel()">
                            <span if.bind="item.label[language]" t="translation:global.buttons.change-label"></span>
                            <span else t="translation:global.buttons.add-label"></span>
                        </ux-dropdown-option>
                        <ux-dropdown-option if.bind="!registration.therapyPlan.startedTreatment" click.trigger="duplicate()">
                            <span t="translation:global.buttons.duplicate"></span>
                        </ux-dropdown-option>
                        <ux-dropdown-option click.trigger="connect()">
                            <span t="translation:components.widgets.examination-treatment-plan.buttons.connect-to-diagnosis"></span>
                        </ux-dropdown-option>
                        <ux-dropdown-divider if.bind="!registration.therapyPlan.startedTreatment"></ux-dropdown-divider>
                        <ux-dropdown-option if.bind="!registration.therapyPlan.startedTreatment" click.trigger="delete()">
                            <span class="text-sm text-red-500" t="global.buttons.delete"></span>
                        </ux-dropdown-option>
                    </ux-dropdown>
                </div>
            </div>
        </div>
        <div if.bind="state.expanded && (registration.therapyPlan.protocols.length > 0 || registration.therapyPlan.results.length > 1)" class="flex gap-1 pb-2 text-xs text-gray-900">
            <div if.bind="registration.therapyPlan.protocols.length > 0" class="flex flex-1 gap-1">
                <p t="translation:components.widgets.examination-treatment-plan.labels.protocols"></p>
                <div class="flex flex-col">
                    <span class="text-primary" repeat.for="protocol of registration.therapyPlan.protocols">${protocol.translations | translate:language}</span>
                </div>
            </div>
            <div if.bind="registration.therapyPlan.results.length > 1" class="flex flex-1 gap-1">
                <p t="translation:components.widgets.examination-treatment-plan.labels.diagnoses"></p>
                <div class="flex flex-col">
                    <span class="text-primary" repeat.for="result of registration.therapyPlan.results" innerhtml.bind="getExtra(result)"> </span>
                </div>
            </div>
        </div>
    </div>
    <template if.bind="state.expanded && registration">
        <div if.bind="registration.therapyPlan.value.stepsToTake.length > 0" class="pl-4">
            <div class="grid grid-cols-12 gap-1 pl-2 border-l-2 border-gray-300">
                <template repeat.for="stepToTake of registration.therapyPlan.value.stepsToTake">
                    <template if.bind="stepToTake.type === MedicalTherapyPlanItemTypes.Category">
                        <template-therapy-category
                            flattened.bind="recordFlattened"
                            record.bind="record"
                            registration.bind="registration"
                            registrations.bind="registrations"
                            component.ref="states[stepToTake.id].model"
                            states.bind="states"
                            validation.bind="validation.plans[validationIndex].steps[$index]"
                            on-file-selected.bind="onFileSelected"
                            on-file-removed.bind="onFileRemoved"
                            x-scroll-container.bind="xScrollContainer"
                            loading.bind="loading"
                            item.bind="stepToTake"
                            workspace.bind="workspace"
                            language.bind="language">
                        </template-therapy-category>
                    </template>
                    <template else>
                        <template-therapy-step
                            flattened.bind="recordFlattened"
                            record.bind="record"
                            registration.bind="registration"
                            registrations.bind="registrations"
                            component.ref="states[stepToTake.id].model"
                            states.bind="states"
                            validation.bind="validation.plans[validationIndex].steps[$index]"
                            on-file-selected.bind="onFileSelected"
                            on-file-removed.bind="onFileRemoved"
                            x-scroll-container`.bind="xScrollContainer"
                            loading.bind="loading"
                            item.bind="stepToTake"
                            workspace.bind="workspace"
                            language.bind="language">
                        </template-therapy-step>
                    </template>
                </template>
            </div>
        </div>
        <div else class="pl-4 mt-1">
            <div class="flex flex-col items-center justify-center p-2 border border-gray-200 rounded-lg">
                <p class="text-xs text-gray-500" t="translation:components.widgets.examination-treatment-plan.messages.no-plan"></p>
            </div>
        </div>
    </template>
</div>
