import { I18N } from '@aurelia/i18n';
import { AttachmentEntities, AttachmentsApiClient } from '@wecore/sdk-attachments';

import { BlobStorageAttachment } from '@wecore/sdk-crm';
import {
    FileType,
    GetMedicalRecordRegistrationResponse,
    GetMedicalRecordResponse,
    InputTypes,
    MedicalExaminationActionFlow,
    MedicalExaminationActionItem,
    MedicalExaminationTemplateItem,
    MedicalExaminationTemplateItemStep,
    MedicalRecordsApiClient,
    MedicalResult,
    ResultTypes
} from '@wecore/sdk-healthcare';
import { guid, isDefined, isNotDefined, serveBlob } from '@wecore/sdk-utilities';

import { IEventAggregator, bindable, containerless, inject } from 'aurelia';
import { CustomEvents } from '../../../../../../infra/events';
import { cloneDeep, fileIsImage, generateColumns, generateResultPlaceholder, getFileTypeTranslation, getSettingsId } from '../../../../../../infra/utilities';
import { ConfirmationOptions } from '../../../../../../models/confirmation-options';
import { EventDetails } from '../../../../../../models/event-details';
import { FlattenedExaminationStep } from '../../../../../../models/flattened-examination-step';
import { SelectedFile } from '../../../../../../models/selected-file';
import { StepState } from '../../../../../../models/step-state';
import { ModalService } from '../../../../../../services/service.modals';
import { UxComboboxOption } from '../../../../../../ux/ux-combobox-option/ux-combobox-option';
import { UxCombobox } from '../../../../../../ux/ux-combobox/ux-combobox';
import { UxMultiSelector } from '../../../../../../ux/ux-multi-selector/ux-multi-selector';
import { UxRangeSlider } from '../../../../../../ux/ux-range-slider/ux-range-slider';
import { UxSelect } from '../../../../../../ux/ux-select/ux-select';

@containerless
@inject(I18N, AttachmentsApiClient, ModalService, IEventAggregator, MedicalRecordsApiClient)
export class TemplateActionStep {
    @bindable() public flattened: FlattenedExaminationStep[];
    @bindable() public record: GetMedicalRecordResponse;
    @bindable() public flow: MedicalExaminationActionFlow;
    @bindable() public step: MedicalExaminationTemplateItemStep;
    @bindable() public item: MedicalExaminationActionItem;
    @bindable() public category: MedicalExaminationActionItem;
    @bindable() public registration: GetMedicalRecordRegistrationResponse;
    @bindable() public registrations: { [key: string]: GetMedicalRecordRegistrationResponse };
    @bindable() public validation: any;
    @bindable() public workspace: string;
    @bindable() public language: string;
    @bindable() public xScrollContainer: string;
    @bindable() public isEvaluationStep: boolean = false;
    @bindable() public states: { [key: string]: StepState };
    @bindable() public onFileSelected: (file: SelectedFile) => void;
    @bindable() public onFileRemoved: (file: SelectedFile, attachment: BlobStorageAttachment) => void;
    @bindable() public loading: (show: boolean) => void;

    public ResultTypes: typeof ResultTypes = ResultTypes;
    public InputTypes: typeof InputTypes = InputTypes;
    public uploads: any[] = [];
    public contentTypes: string;
    public columns: string = 'col-span-12';
    public required: boolean = false;
    public attachments: BlobStorageAttachment[] = [];
    public urls: any = {};
    public toManyFiles: boolean = false;
    public wrongFileTypes: boolean = false;
    public shouldBeDisplayed: boolean = false;
    public hasConnections: boolean = false;
    public container: HTMLDivElement;
    public grid: HTMLDivElement;
    public buddy: FlattenedExaminationStep;
    public selector: UxSelect;
    public multiSelector: UxMultiSelector;
    public slider: UxRangeSlider;

    private subscriptions: any[] = [];

    public constructor(
        public t: I18N, //
        private readonly attachmentsApi: AttachmentsApiClient,
        private readonly modalService: ModalService,
        private readonly events: IEventAggregator,
        private readonly recordsApi: MedicalRecordsApiClient
    ) {}

    public attached(): void {
        // Set the expanded default state.
        if (isNotDefined(this.states[this.item.id])) {
            this.states[this.item.id] = new StepState({
                stepId: this.item.id,
                expanded: false,
                answered: false
            });
        }

        // Generate the configured column width for the current question.
        this.columns = generateColumns(
            this.flow.breakpoints?.filter((x) => x.id === getSettingsId(this.item)) || [] //
        );

        // Wait for all steps to be rendered.
        setTimeout(() => {
            // Fetch all connected steps for the current step from the flow settings.
            const connected = this.flow.connectedSteps.filter((x) => x.key === this.item.copiedFrom);
            // We are going to loop through each connected step.
            for (const item of connected) {
                // First we get the flattened info from the current connected step.
                const flatOfElementToObserve = this.flattened.find((x) => x.item.copiedFrom === item.value);
                // We are going to observe the grid elements of the connected step.
                // Note that we are not observing the container element of the current step
                // because we are going to change the heights of the container which can
                // retrigger the observer callback, which can cause an infinite loop.
                const elementToObserve = document.getElementById(`action-step-grid-${flatOfElementToObserve.item.copiedFrom}-${this.step.id}`);
                // Get the viewmodel of the connect step. The model is a reference to the <template-action-step> component.
                // which is set by lines like component.ref="states[stepToTake.id].model".
                const viewModelOfElementToObserve = this.states[flatOfElementToObserve.item.id].model as unknown as TemplateActionStep;
                // We are going to create a new resize observer.
                const observer = new ResizeObserver((entries) => {
                    // Request a frame to prevent resize loops
                    window.requestAnimationFrame((): void | undefined => {
                        if (!Array.isArray(entries) || !entries.length) {
                            return;
                        }
                        if (isNotDefined(this.container) || isNotDefined(this.grid)) return;
                        // Get the heighest height of all the observed elements.
                        const height = Math.max(...entries.map((x) => x.contentRect.height));

                        if (height === 0) {
                            this.matchContainerSizeToGridSize();
                            viewModelOfElementToObserve?.matchContainerSizeToGridSize();
                        } else if (height > this.grid.clientHeight) {
                            // Height is of the observered element is bigger than the grid height.
                            this.setHeight(height);
                            viewModelOfElementToObserve?.matchContainerSizeToGridSize();
                        } else if (height === this.grid.clientHeight) {
                            // The height of the observered element is the same as the grid height.
                            this.setHeight(height);
                            viewModelOfElementToObserve?.matchContainerSizeToGridSize();
                        } else if (this.grid.clientHeight > height) {
                            // The height of the grid is bigger than the height of the observered element.
                            viewModelOfElementToObserve?.setHeight(this.grid.clientHeight);
                        }
                    });
                });

                // Initialize the observer and store it in the states so we are
                // able to disconnect them when needed.
                if (isDefined(elementToObserve)) observer.observe(elementToObserve);
                this.states[this.item.id].observer = observer;
            }
        });

        // Create a description string of all allowed file types.
        this.contentTypes = this.item?.step?.allowedFileTypes?.selectMany<FileType, string>((x) => x.contentTypes).join(',');
        this.subscriptions = [
            ...(this.subscriptions ?? []),
            this.events.subscribe(
                CustomEvents.ExaminationStepAnswerChanged,
                (data: {
                    step: MedicalExaminationActionItem | MedicalExaminationTemplateItem; //
                    category: MedicalExaminationActionItem | MedicalExaminationTemplateItem;
                    value: string;
                    height: number;
                    container: string;
                    action: 'added' | 'deleted';
                }) => {
                    this.evaluateSettings();

                    // Evaluation steps should not add new DD's to the record.
                    if (data.step instanceof MedicalExaminationTemplateItem || this.isEvaluationStep) return;

                    // Only steps in the same container should be affected.
                    if (data.container !== this.step.id) return;

                    // If the current step is not the same as the step that triggered the event, we should not do anything.
                    if (data.step.id !== this.item.id) return;

                    // Wait for the given answer to be set properly.
                    setTimeout(() => this.evaluateDdSettings(data.value, data.action));
                }
            ),
            this.events.subscribe(
                CustomEvents.ExaminationStepAnswerCopy,
                (data: {
                    step: MedicalExaminationActionItem; //
                    result: MedicalResult;
                    results: MedicalResult[];
                }) => {
                    // No copying when the current step is not visible.
                    if (!this.shouldBeDisplayed) return;
                    // We look for all the connected step of the step that triggered the copy.
                    const connected = this.flow.connectedSteps.find((x) => x.key === data.step.copiedFrom && x.value === this.item.copiedFrom);
                    if (isNotDefined(connected)) return;
                    // Copy values.
                    this.item.step.result = data.result;
                    this.item.step.results = data.results;
                    // Trigger the answer changed event.
                    if (isDefined(this.item.step.result.value)) {
                        this.handleStepAnswered(this.item.step.result.value, 'added');
                        if (isDefined(this.selector)) this.selector.setValue(this.item.step.result.value);
                    } else {
                        const value = this.item.step.results.filter((x) => x.value).map((x) => x.value)[0];
                        this.handleStepAnswered(value, 'added');
                        if (isDefined(this.multiSelector)) this.multiSelector.refresh();
                    }

                    if (isDefined(this.slider)) this.slider.setValue(this.item.step.result.value);
                }
            )
        ];

        // Find previously selected attachments if any.
        const attachments = this.item.step.attachments?.map((id) => this.record.attachments.find((y) => y.id === id)).filter((x) => isDefined(x)) || [];
        Promise.all(
            attachments.map(async (x) => {
                const id = isDefined(x.thumbnail) ? x.thumbnail.id : x.id;
                const url = await this.attachmentsApi.getUrl(this.record.id, id, this.workspace, AttachmentEntities.MedicalRecords);
                this.urls[x.id] = url;
            })
        ).then(() => (this.attachments = attachments));

        // Evaluate the requirements on load of the action step.
        this.evaluateSettings();

        this.hasConnections = this.flow.connectedSteps.some((x) => x.key === this.item.copiedFrom);
        this.states[this.item.id].answered = this.uploads.any() || this.attachments.any();
    }

    public detaching(): void {
        this.subscriptions.forEach((x) => x.dispose());
        if (isDefined(this.states[this.item.id].observer)) this.states[this.item.id].observer.disconnect();
    }

    public getPlaceholder(): string {
        const generated = generateResultPlaceholder(this.t, this.item.step.inputType, this.item.step.resultType);
        return isDefined(this.item.step.placeholder) ? this.item.step.placeholder[this.language] || generated : generated;
    }

    public handleMultiChoiceSelected = (option: { value: string; text: string }) => {
        this.item.step.results.push(new MedicalResult({ value: option.value }));
        this.handleStepAnswered(option.value, 'added');
    };

    public handleMultiChoiceRemoved = (option: { value: string; text: string }, index: number) => {
        this.item.step.results.splice(index, 1);
        this.handleStepAnswered(option?.value, 'deleted');
    };

    public handleResultSelected(e: CustomEvent<EventDetails<UxCombobox, UxComboboxOption>>): void {
        this.handleStepAnswered(e.detail.values.value, 'added');
    }

    public filesSelected = (files: File[]): void => {
        if (this.item.step.filesAmount > 0 && this.uploads.length >= this.item.step.filesAmount) {
            this.toManyFiles = true;
            return;
        }

        if (
            isDefined(this.item.step.allowedFileTypes) &&
            this.item.step.allowedFileTypes.any() &&
            files.some((file) => this.item.step.allowedFileTypes.every((x) => x.contentTypes.every((type) => type !== file.type)))
        ) {
            this.wrongFileTypes = true;
            return;
        }

        for (const file of files) {
            const upload = new SelectedFile({
                id: guid(),
                step: this.item.id,
                type: 'step',
                file,
                progress: 0,
                loader: null,
                statusLabel: this.t
                    .tr('translation:partial-views.clinical-pathways.labels.status-waiting')
                    .replace('{entity}', `<span class="font-medium block mx-1">${file.name.toLowerCase()}</span>`),
                extension: `.${file.name.split('.').pop()}`,
                name: file.name,
                registration: this.registration.id,
                isLoading: false
            });

            this.uploads.push(upload);

            // Add the uploads to a temporary array. This way we can check if the step is answered or not.
            if (isNotDefined(this.item.step.attributes)) this.item.step.attributes = { uploads: [] };
            if (isNotDefined(this.item.step.attributes.uploads)) this.item.step.attributes.uploads = [];
            this.item.step.attributes.uploads.push(upload.id);

            this.onFileSelected(upload);
        }

        this.states[this.step.id].answered = this.uploads.any() || this.attachments.any();

        this.handleStepAnswered(null, 'added');
    };

    public isImage(contentType: string): boolean {
        return fileIsImage(contentType);
    }

    public deleteFromUploads(index: number): void {
        const file = this.uploads[index];

        this.uploads.splice(index, 1);

        this.onFileRemoved(file, null);
        this.states[this.item.id].answered = this.uploads.any() || this.attachments.any();

        // Remove from temp uploads
        if (isDefined(this.item.step.attributes?.uploads)) {
            this.item.step.attributes.uploads.splice(index, 1);
        }

        this.handleStepAnswered(null, 'deleted');
    }

    public async deleteAttachment(index: number): Promise<void> {
        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('translation:partial-views.clinical-pathways.questions.delete-attachment.title'),
                message: this.t.tr('translation:partial-views.clinical-pathways.questions.delete-attachment.message'),
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        const attachment = this.attachments[index];
                        const i = this.item.step.attachments.findIndex((id) => id === attachment.id);
                        this.item.step.attachments.splice(i, 1);

                        this.attachments.splice(index, 1);

                        this.onFileRemoved(null, attachment);
                        this.states[this.item.id].answered = this.uploads.any() || this.attachments.any();

                        this.handleStepAnswered(null, 'deleted');
                    }
                }
            })
        );
    }

    public async openMenu(event: MouseEvent, index: number, type: 'attachment' | 'upload'): Promise<void> {
        await this[`${type}Menus`][index].show(event);
    }

    public getFileTypes(): string {
        return this.item.step.allowedFileTypes.map((x) => this.t.tr(getFileTypeTranslation(x.name))).join(', ');
    }

    public async open(attachment: BlobStorageAttachment): Promise<void> {
        const url = await this.attachmentsApi.getUrl(this.record.id, attachment.id, this.workspace, AttachmentEntities.MedicalRecords);
        window.open(url, '_blank');
    }

    public async download(attachment: BlobStorageAttachment): Promise<void> {
        if (isDefined(attachment)) {
            const blob = await this.attachmentsApi.download(this.record.id, attachment.id, this.workspace, AttachmentEntities.MedicalRecords);
            serveBlob(blob.data, `${attachment.name}${attachment.extension}`);
        }
    }

    public collapseOrExpand(): void {
        this.item.attributes.expanded = !this.item.attributes.expanded;
    }

    public handleBlur(): void {
        if (this.item.step.inputType === InputTypes.FreeEntry && isDefined(this.item.step.result?.value)) this.handleStepAnswered(this.item.step.result?.value, 'added');
    }

    public toSet(choices: MedicalResult[]): { value: string; text: string; data?: any }[] {
        return choices.map((x) => ({ value: x.value, text: x.value }));
    }

    public handleAnswerCleared(e: CustomEvent<EventDetails<UxCombobox, any>>): void {
        const deletedValue = e.detail.values.deletedValue;
        this.handleStepAnswered(deletedValue, 'deleted');
    }

    public copyValue(): void {
        this.events.publish(CustomEvents.ExaminationStepAnswerCopy, {
            step: this.item,
            result: MedicalResult.fromJS(cloneDeep(this.item.step.result)),
            results: this.item.step.results.map((x) => MedicalResult.fromJS(cloneDeep(x)))
        });
    }

    public setHeight(height: number): void {
        if (isNotDefined(this.container)) return;
        if (height === 0) return;
        this.container.style.height = `${height + (height > 0 ? 4 : 0)}px`;
    }

    private handleStepAnswered(value: string, action: 'added' | 'deleted'): void {
        this.loading(true);

        // Allow the height of the step, when answered some time
        // to propergate its height through the DOM.
        setTimeout(() => {
            // Let all listeners know that the current step has been answered.
            this.events.publish(CustomEvents.ExaminationReFlatten, {
                step: this.item,
                category: this.category,
                value,
                container: this.step.id,
                height: this.grid?.clientHeight || 0,
                action
            });
        }, 50);
        this.loading(false);
    }

    private evaluateDdSettings = async (value: string, action: 'added' | 'deleted'): Promise<void> => {
        const requirement = this.flow.differentialDiagnosesRequirements?.find(
            (x) => x.id === this.item.copiedFrom && x.resultToCompare.value === value //
        );
        this.events.publish(CustomEvents.ExaminationEvaluateDDSettings, {
            requirement,
            stepId: this.item.id,
            value,
            action
        });
    };

    private evaluateSettings(): void {
        const flattened = this.flattened.find((x) => x.item.id === this.item.id);
        this.shouldBeDisplayed = flattened.isVisible;
        this.required = flattened?.isRequired ?? false;

        if (!this.shouldBeDisplayed) {
            this.item.step.result.value = null;
            this.item.step.results = [];
            this.removeAllAttachmentsAndUploads();
        }

        // If both current item and its buddy (when it has one) are not visible, we can shrink the content to zero height.
        const connected = this.flow.connectedSteps.filter((x) => x.key === this.item.copiedFrom);
        if (connected.any()) {
            const buddy = this.flattened.find((x) => x.container?.id === this.step.id && x.item.copiedFrom === connected[0].value);
            if (isDefined(this.container) && isDefined(buddy) && !buddy.isVisible && !flattened.isVisible) this.container.style.height = '0px';

            this.buddy = buddy;
        }
    }

    private removeAllAttachmentsAndUploads(): void {
        if (this.item.step.resultType !== ResultTypes.File) return;

        for (const attachment of this.attachments) {
            const i = this.attachments.findIndex((a) => a.id === attachment.id);
            this.attachments.splice(i, 1);
            this.onFileRemoved(null, attachment);
        }

        for (const upload of this.uploads) this.onFileRemoved(upload, null);

        this.uploads = [];
        this.attachments = [];
        this.states[this.item.id].answered = false;
    }

    private matchContainerSizeToGridSize(): void {
        // Allow the step some time to resize and render its DOM content so that we get the correct sizes.
        if (isNotDefined(this.container) || isNotDefined(this.grid)) return;
        if (this.grid.offsetHeight === 0) return;
        // Make sure the content height is the same as the grid height (dynamic)
        // if (this.container.offsetHeight < this.grid.offsetHeight || force) {
        // Set the height of the container to the height of the grid.
        this.container.style.height = `${this.grid.offsetHeight + (this.grid.offsetHeight > 0 ? 4 : 0)}px`;
    }
}
