<import from="converters/date" />
<import from="converters/translate" />

<div
    id="scheduler-item-${schedulerItem.id}"
    ref="container"
    contextmenu.trigger="handleContextMenu($event)"
    data-type="scheduler-item"
    data-long-press-delay="400"
    opacity-40.class="state.cut && state.cut.schedulerItem.id === schedulerItem.id"
    css="background-color: ${schedulerItem.type.color.background}; color: ${schedulerItem.type.color.text};"
    class="${rounding} group absolute flex h-full min-w-0 flex-col border-2 border-[#f7fbff] transition duration-300 ease-in-out [-webkit-touch-callout:none] [-webkit-user-select:none] [user-select:none] hover:!z-[9999] hover:border-blue-300">
    <div class="absolute flex w-full h-full">
        <div class="flex w-full h-full">
            <div if.bind="schedulerItem.status === SchedulerItemStatuses.Cancelled" class="h-full w-[5px] rounded-l-md border-r-[2px] border-white bg-red-500"></div>
            <div
                if.bind="schedulerItem.type === SchedulerItemTypes.Appointment"
                repeat.for="type of schedulerItem.appointmentTypes"
                rounded-l-md.class="$index === 0 && schedulerItem.status !== SchedulerItemStatuses.Cancelled"
                rounded-r-md.class="$index === schedulerItem.appointmentTypes.length - 1 && schedulerItem.status !== SchedulerItemStatuses.Cancelled"
                class="flex flex-1 h-full"
                css="background-color: ${type.color.background}; color: ${type.color.text}"></div>
            <div if.bind="schedulerItem.status === SchedulerItemStatuses.Cancelled" class="h-full w-[5px] rounded-r-md border-l-[2px] border-white bg-red-500"></div>
        </div>
        <template if.bind="schedulerItem.type === SchedulerItemTypes.Block">
            <div class="absolute flex w-full h-full">
                <div class="absolute flex w-full h-full bg-red-100 rounded-md"></div>
                <div
                    class="absolute z-[20] flex h-full w-full rounded-md"
                    style="opacity: 0.1; background: repeating-linear-gradient(-45deg, #b22222, #b22222 5px, transparent 5px, transparent 15px)"></div>
            </div>
        </template>
        <div class="absolute z-50 flex flex-col w-full h-full min-w-0">
            <div class="z absolute left-0 top-0 z-50 hidden h-full w-full items-center justify-center rounded-md bg-white opacity-25 group-[&.loading]:flex">
                <ux-spinner size="xs"></ux-spinner>
            </div>
            <div
                if.bind="schedulerItem.status === SchedulerItemStatuses.Scheduled && !schedulerItem.historic"
                mousedown.trigger="handleResizeMouseDown($event, 'top')"
                h-2.class="height > 20"
                h-1.class="height <= 20"
                class="absolute top-0 z-20 w-full rounded-t-md hover:cursor-ns-resize hover:bg-gray-200/70"></div>
            <ux-tooltip
                if.bind="message"
                show-when.bind="(schedulerItem.type === SchedulerItemTypes.Block && schedulerItem.comments) || (schedulerItem.createOutsideSchedule && schedulerItem.type === SchedulerItemTypes.Appointment)"
                content.bind="schedulerItem.createOutsideSchedule && schedulerItem.type === SchedulerItemTypes.Appointment ? message : schedulerItem.comments"
                class="h-full">
                <div css="padding-top: ${paddingTop}px" ref="content" data-type="content" mousedown.trigger="handleMoveMouseDown($event)" class="z-10 flex-1 w-full px-3 hover:cursor-pointer">
                    <div class="flex items-center gap-1">
                        <svg
                            if.bind="schedulerItem.createOutsideSchedule && schedulerItem.type === SchedulerItemTypes.Appointment && !schedulerItem.historic"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="flex-shrink-0 text-red-500 size-4">
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" />
                        </svg>
                        <p if.bind="schedulerItem.type === SchedulerItemTypes.Appointment && !schedulerItem.historic" class="text-xs text-gray-800 truncate">
                            <strong>#${ schedulerItem.patients[0].data.number }</strong> ${ schedulerItem.patients[0].name }
                        </p>
                        <p if.bind="schedulerItem.historic && Number(schedulerItem.patients[0].data.number) !== -1" class="text-xs text-gray-800 truncate">
                            <strong>#${ schedulerItem.patients[0].data.number }</strong> ${ schedulerItem.patients[0].name }
                        </p>
                        <p if.bind="schedulerItem.historic && Number(schedulerItem.patients[0].data.number) === -1" class="text-xs font-bold text-gray-800 truncate">
                            ${ schedulerItem.appointmentTypes[0].name | translate:language }
                        </p>
                    </div>
                    <p if.bind="schedulerItem.type === SchedulerItemTypes.Block" class="text-xs font-bold text-gray-800 truncate" t="translation:global.labels.block"></p>
                    <p show.bind="height > 50 && pageState.values.columnWidth >= 210" data-type="slot" class="text-xs text-gray-800">
                        ${ schedulerItem.start | date:'HH:mm' } - ${ schedulerItem.end | date:'HH:mm' }
                    </p>
                    <p show.bind="height > 50 && pageState.values.columnWidth < 210" data-type="slot" class="text-xs text-gray-800">
                        ${ schedulerItem.start | date:'HH:mm' }-${ schedulerItem.end | date:'HH:mm' }
                    </p>
                </div>
            </ux-tooltip>
            <div
                if.bind="schedulerItem.status === SchedulerItemStatuses.NoShow"
                class="absolute flex w-full h-full bg-transparent rounded-md"
                style="opacity: 0.1; background: repeating-linear-gradient(-45deg, #000, #000 5px, transparent 5px, transparent 15px)"></div>
            <div
                if.bind="schedulerItem.status === SchedulerItemStatuses.Scheduled && !schedulerItem.historic"
                mousedown.trigger="handleResizeMouseDown($event, 'bottom')"
                h-2.class="height > 20"
                h-1.class="height <= 20"
                class="absolute bottom-0 z-20 w-full rounded-b-md hover:cursor-ns-resize hover:bg-gray-200/70"></div>
        </div>
        <div data-type="context-menu" class="absolute z-[10000] hidden w-48 rounded-md border border-gray-200 bg-white">
            <ul class="p-1">
                <li
                    if.bind="hasRole(UserRoles.WriteSchedulerItems) 
                            && (schedulerItem.status === SchedulerItemStatuses.Scheduled || schedulerItem.status === SchedulerItemStatuses.NoShow)"
                    class="flex">
                    <a href="javascript:" data-function="edit" class="flex-1 px-3 py-2 text-sm text-gray-800 rounded-md hover:bg-gray-100 hover:text-gray-900" t="translation:global.buttons.edit"></a>
                </li>
                <li
                    if.bind="hasRole(UserRoles.WriteSchedulerItems)  
                            && (schedulerItem.status === SchedulerItemStatuses.Scheduled || schedulerItem.status === SchedulerItemStatuses.NoShow) 
                            && !schedulerItem.historic"
                    class="flex">
                    <a href="javascript:" data-function="cut" class="flex-1 px-3 py-2 text-sm text-gray-800 rounded-md hover:bg-gray-100 hover:text-gray-900" t="translation:global.buttons.cut"></a>
                </li>
                <li
                    if.bind="hasRole(UserRoles.ReadPatients) 
                            && schedulerItem.type === SchedulerItemTypes.Appointment
                            && !schedulerItem.historic"
                    class="flex">
                    <a
                        href="javascript:"
                        data-function="patient-card"
                        class="flex-1 px-3 py-2 text-sm text-gray-800 rounded-md hover:bg-gray-100 hover:text-gray-900"
                        t="translation:bx.scheduler.buttons.patient-card">
                    </a>
                </li>
                <li
                    if.bind="hasRole(UserRoles.WriteSchedulerItems) 
                        && schedulerItem.type === SchedulerItemTypes.Appointment 
                        && schedulerItem.status === SchedulerItemStatuses.Scheduled
                        && !schedulerItem.historic"
                    class="flex">
                    <a
                        href="javascript:"
                        data-function="scheduler-item-confirmation"
                        class="flex-1 px-3 py-2 text-sm text-gray-800 rounded-md hover:bg-gray-100 hover:text-gray-900"
                        t="translation:bx.scheduler.buttons.scheduler-item-confirmation">
                    </a>
                </li>
                <li
                    if.bind="hasRole(UserRoles.WriteSchedulerItems) 
                        && schedulerItem.type === SchedulerItemTypes.Appointment 
                        && schedulerItem.status === SchedulerItemStatuses.Scheduled
                        && !schedulerItem.historic"
                    class="flex">
                    <a
                        href="javascript:"
                        data-function="mark-no-show"
                        class="flex-1 px-3 py-2 text-sm text-gray-800 rounded-md hover:bg-gray-100 hover:text-gray-900"
                        t="translation:bx.scheduler.buttons.no-show-mark">
                    </a>
                </li>
                <li
                    if.bind="hasRole(UserRoles.WriteSchedulerItems) 
                        && schedulerItem.type === SchedulerItemTypes.Appointment 
                        && schedulerItem.status === SchedulerItemStatuses.NoShow
                        && !schedulerItem.historic"
                    class="flex">
                    <a
                        href="javascript:"
                        data-function="unmark-no-show"
                        class="flex-1 px-3 py-2 text-sm text-gray-800 rounded-md hover:bg-gray-100 hover:text-gray-900"
                        t="translation:bx.scheduler.buttons.no-show-unmark">
                    </a>
                </li>
                <li
                    if.bind="hasRole(UserRoles.WriteSchedulerItems) 
                        && schedulerItem.type === SchedulerItemTypes.Appointment 
                        && schedulerItem.status === SchedulerItemStatuses.Scheduled
                        && !schedulerItem.historic"
                    class="flex">
                    <a
                        href="javascript:"
                        data-function="mark-cancelled"
                        class="flex-1 px-3 py-2 text-sm text-gray-800 rounded-md hover:bg-gray-100 hover:text-gray-900"
                        t="translation:bx.scheduler.buttons.cancelled-mark">
                    </a>
                </li>
                <li
                    if.bind="hasRole(UserRoles.WriteSchedulerItems) 
                        && schedulerItem.type === SchedulerItemTypes.Appointment 
                        && schedulerItem.status === SchedulerItemStatuses.Cancelled
                        && !schedulerItem.historic"
                    class="flex">
                    <a
                        href="javascript:"
                        data-function="unmark-cancelled"
                        class="flex-1 px-3 py-2 text-sm text-gray-800 rounded-md hover:bg-gray-100 hover:text-gray-900"
                        t="translation:bx.scheduler.buttons.cancelled-unmark">
                    </a>
                </li>
                <li if.bind="hasRole(UserRoles.ReadSchedulerItems)" class="flex">
                    <a
                        href="javascript:"
                        data-function="details"
                        class="flex-1 px-3 py-2 text-sm text-gray-800 rounded-md hover:bg-gray-100 hover:text-gray-900"
                        t="translation:global.buttons.details">
                    </a>
                </li>
                <li if.bind="hasRole(UserRoles.DeleteSchedulerItems)" class="flex">
                    <a
                        href="javascript:"
                        data-function="delete"
                        class="flex-1 px-3 py-2 text-sm text-red-500 rounded-md hover:bg-gray-100 hover:text-red-700"
                        t="translation:global.buttons.delete"></a>
                </li>
            </ul>
        </div>
    </div>
</div>
