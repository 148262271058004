import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import {
    GetMedicalTherapyResponse,
    InputTypes,
    MedicalResult,
    MedicalTherapiesApiClient,
    MedicalTherapyPlanItem,
    MedicalTherapyPlanItemTypes,
    StringTherapyDefaultValueKeyValuePair,
    TherapyDefaultValue,
    TherapyDefaultValues
} from '@wecore/sdk-healthcare';
import { isDefined, isEmpty, isNotDefined } from '@wecore/sdk-utilities';
import { IEventAggregator, inject } from 'aurelia';
import { PartialViewResults } from '../../../enums/partial-view-results';
import { BasePartialView } from '../../../infra/base-partial-view';
import { CacheService } from '../../../infra/cache-service';
import { ErrorHandler } from '../../../infra/error-handler';
import { clearTherapyEvaluationItem } from '../../../infra/store/actions/copy-paste';
import { State } from '../../../infra/store/state';
import { PartialView } from '../../../models/partial-view';
import { StepState } from '../../../models/step-state';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, MedicalTherapiesApiClient)
export class PartialMedicalTreatmentProtocolsDefaultValues extends BasePartialView {
    public therapy: GetMedicalTherapyResponse;
    public def: TherapyDefaultValues;
    public flattened: {
        stepId: string;
        item: MedicalTherapyPlanItem;
        choices: MedicalResult[];
    }[] = [];
    public MedicalTherapyPlanItemTypes: typeof MedicalTherapyPlanItemTypes = MedicalTherapyPlanItemTypes;
    public states: { [key: string]: StepState } = {};
    private therapyId: string;

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly therapiesApi: MedicalTherapiesApiClient
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.therapyId = view.data.id;
        this.def = view.data.defaultValues;
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                this.therapy = await this.therapiesApi.getById(this.therapyId, this.authenticated.workspace.id);
                this.def.therapy.translations = this.therapy.name;
                this.flattenTherapy();

                for (const flattened of this.flattened) {
                    this.states[flattened.stepId] = new StepState({
                        stepId: flattened.stepId,
                        expanded: false,
                        answered: false
                    });

                    // If a default value already exists, skip it.
                    if (this.def.defaultValues.some((x) => x.key === flattened.stepId)) continue;
                    // Skip datepickers and file pickers.
                    if (flattened.item.step.inputType !== InputTypes.FreeEntry && flattened.item.step.inputType !== InputTypes.Selector) continue;

                    this.def.defaultValues.push(
                        new StringTherapyDefaultValueKeyValuePair({
                            key: flattened.stepId,
                            value: new TherapyDefaultValue({
                                result: new MedicalResult({
                                    valueType: flattened.item.step.resultType
                                }),
                                results: []
                            })
                        })
                    );
                }

                for (const item of this.def.defaultValues) {
                    const flat = this.getFlat(item.key);
                    if (isNotDefined(item.value.result))
                        item.value.result = new MedicalResult({
                            valueType: flat.item.step.resultType
                        });
                }

                // Delay showing content to prevent flickering.
                setTimeout(async () => {
                    this.baseLoaded = true;
                    await super.handleBaseLoaded();
                }, 250);
            })
            .catch((x) => this.errorHandler.handle('PartialMedicalTreatmentProtocolsDefaultValues.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached, updateUrl: false });
        this.store.dispatch(clearTherapyEvaluationItem);
    }

    public async cancel(): Promise<void> {
        await super.remove({
            result: PartialViewResults.Cancelled,
            updateUrl: true
        });
    }

    public save(): void {
        // Nullify values that are not used.
        for (const item of this.def.defaultValues) {
            if (isDefined(item.value.result) && (isNotDefined(item.value.result.value) || isEmpty(item.value.result.value))) item.value.result = null;
        }

        this.def.defaultValues = this.def.defaultValues.filter(
            // Filter out steps that do not exists on the therapy anymore.
            (def) =>
                this.flattened.some((flat) => def.key === flat.stepId) &&
                // Filter out steps that do not have a value to save space
                (def.value.results.length > 0 || isDefined(def.value.result))
        );

        super.remove({
            result: PartialViewResults.Ok,
            data: this.def
        });
    }

    public getFlat = (
        stepId: string
    ): {
        stepId: string;
        item: MedicalTherapyPlanItem;
        choices: MedicalResult[];
    } => {
        return this.flattened.find((x) => x.stepId === stepId);
    };

    private flattenTherapy(): void {
        const flatten = (items: MedicalTherapyPlanItem[]) => {
            for (const item of items) {
                if (item.type === MedicalTherapyPlanItemTypes.Category) {
                    flatten(item.category.stepsToTake);
                } else {
                    this.flattened.push({
                        stepId: item.id,
                        item,
                        choices: item.step.choices
                    });
                }
            }
        };
        flatten(this.therapy.plan.stepsToTake);
    }
}
