import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { GetUserResponse, SchedulerItemTypes } from '@wecore/sdk-core';
import {
    SchedulerItemsApiClient,
    ExaminationRoom,
    GetSchedulerItemResponse,
    GetAppointmentTypeResponse,
    GetPatientResponse,
    GetPracticeLocationResponse,
    PatientEntityReference,
    PracticeLocationsApiClient,
    SchedulesApiClient,
    UserEntityReference,
    SchedulerContext
} from '@wecore/sdk-healthcare';
import { SchedulerSettings } from '@wecore/sdk-management';
import { isDefined, isNotDefined, resetValidation, validateState } from '@wecore/sdk-utilities';
import { IEventAggregator, inject } from 'aurelia';
import { addMinutes, format, isBefore, isEqual, setDate, setHours, setMinutes, setMonth, setYear } from 'date-fns';
import { BxPracticeLocationSelector } from '../../bx/bx-practice-location-selector/bx-practice-location-selector';
import { PartialViewResults } from '../../enums/partial-view-results';
import { BasePartialView } from '../../infra/base-partial-view';
import { CacheService } from '../../infra/cache-service';
import { ErrorHandler } from '../../infra/error-handler';
import { State } from '../../infra/store/state';
import { EventDetails } from '../../models/event-details';
import { PartialView } from '../../models/partial-view';
import { ViewOptions } from '../../models/view-options';
import { ModalService } from '../../services/service.modals';
import { UxComboboxOption } from '../../ux/ux-combobox-option/ux-combobox-option';
import { UxCombobox } from '../../ux/ux-combobox/ux-combobox';
import { UxDatepicker } from '../../ux/ux-datepicker/ux-datepicker';
import { UxInput } from '../../ux/ux-input/ux-input';
import { UxSelect } from '../../ux/ux-select/ux-select';
import { UxSelectOption } from '../../ux/ux-select-option/ux-select-option';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, SchedulerItemsApiClient, SchedulesApiClient, PracticeLocationsApiClient, ModalService)
export class PartialSchedulerItemsEdit extends BasePartialView {
    public schedulerItem: GetSchedulerItemResponse;
    public roomSelector: UxSelect;
    public locationSelector: BxPracticeLocationSelector;
    public errorCode: string;
    public context: SchedulerContext;
    public validation = {
        name: true,
        types: true,
        patient: true,
        practitioner: true,
        schedule: true,
        date: true,
        start: true,
        end: true,
        location: true,
        room: true
    };
    public startInput: UxInput;
    public endInput: UxInput;
    public start: string;
    public end: string;
    public checkingSchedule: boolean = false;
    public SchedulerItemTypes: typeof SchedulerItemTypes = SchedulerItemTypes;
    public SchedulerContext: typeof SchedulerContext = SchedulerContext;

    private location: GetPracticeLocationResponse;
    private schedulerSettings: SchedulerSettings;
    private itemId: string;
    private scheduleRoom: ExaminationRoom;

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly itemsApi: SchedulerItemsApiClient,
        private readonly schedulesApi: SchedulesApiClient,
        private readonly locationsApi: PracticeLocationsApiClient,
        private readonly modalService: ModalService
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.itemId = view.data.id;
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                this.schedulerSettings = this.state.schedulerSettings;
                this.schedulerItem = await this.itemsApi.getById(this.itemId, this.authenticated.workspace.id);

                if (this.schedulerItem.type === SchedulerItemTypes.Block) {
                    if (isDefined(this.schedulerItem.practitioner)) this.context = SchedulerContext.User;
                    else if (isDefined(this.schedulerItem.location)) this.context = SchedulerContext.Room;
                    else this.context = SchedulerContext.Unknown;
                } else this.context = SchedulerContext.Unknown;

                this.start = format(this.schedulerItem.start, 'HH:mm');
                this.end = format(this.schedulerItem.end, 'HH:mm');

                this.baseLoaded = true;
            })
            .catch((x) => this.errorHandler.handle('PartialSchedulerItemsEdit.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }

    public async cancel(): Promise<void> {
        await super.remove({
            result: PartialViewResults.Cancelled,
            updateUrl: true
        });
    }

    public async save(): Promise<void> {
        const valid = this.validate();

        if (valid) {
            this.errorCode = null;
            this.isLoading = true;
            try {
                // Get the timezone offset of the browser
                // NOTE: Get the timezone offset of the scheduler item date
                // and not the current date because the scheduler item date
                // can be in a different timezone that the current date.
                const timezoneOffset = this.schedulerItem.start.getTimezoneOffset();
                const schedulerItem = await this.itemsApi.update(this.itemId, this.authenticated.workspace.id, timezoneOffset, this.schedulerItem);

                this.notifications.show(
                    this.t.tr('translation:partial-views.scheduler-items.notifications.save-successful.title'),
                    this.t.tr('translation:partial-views.scheduler-items.notifications.save-successful.message'),
                    {
                        type: 'success',
                        duration: 3000
                    }
                );
                setTimeout(async () => this.remove({ result: PartialViewResults.Ok, data: schedulerItem, updateUrl: true }), 250);
            } catch (e) {
                this.isLoading = false;
                try {
                    const error = JSON.parse(e.response);
                    if (error.code === 212) {
                        this.errorCode = error.message.substring(error.message.indexOf('(') + 1, error.message.lastIndexOf(')'));
                    } else await this.errorHandler.handle('[edit-scheduler-item]', e);
                } catch (err) {
                    await this.errorHandler.handle('[edit-scheduler-item]', { e, err });
                }
            }
        }
    }

    public manageTranslationsFor(property: string, required: boolean = false): void {
        this.manageTranslations({
            translations: this.schedulerItem[property],
            callback: (updatedTranslations: any) => {
                this.schedulerItem[property] = updatedTranslations;
            },
            required,
            type: property === 'description' ? 'textarea' : 'input'
        });
    }

    public handleTypesSelected = async (types: GetAppointmentTypeResponse[], mutation: 'added' | 'deleted', item: GetAppointmentTypeResponse): Promise<void> => {
        this.schedulerItem.appointmentTypes = types;
        this.setDurationBasedOnTypes();
    };

    public handlePatientSelected = async (patient: GetPatientResponse): Promise<void> => {
        this.schedulerItem.patients = [
            new PatientEntityReference({
                id: patient.id,
                name: patient.displayName,
                data: {
                    number: patient.number,
                    avatarColor: patient.avatar.color,
                    avatarShade: patient.avatar.shade.toString()
                }
            })
        ];
    };

    public handleUserSelected = async (user: GetUserResponse): Promise<void> => {
        this.schedulerItem.practitioner = new UserEntityReference({
            id: user.id,
            name: user.displayName
        });

        this.checkIfScheduleExist();
    };

    public handleLocationSelected = (location: GetPracticeLocationResponse): void => {
        this.schedulerItem.location = location;
        this.schedulerItem.examinationRoom = null;
        this.roomSelector.clear();
    };

    public async handleDateChanged(e: CustomEvent<EventDetails<UxDatepicker, any>>): Promise<void> {
        const date = new Date(e.detail.values.date);
        this.schedulerItem.start = setMinutes(
            setYear(
                setMonth(
                    setDate(this.schedulerItem.start, date.getDate()),
                    date.getMonth() //
                ),
                date.getFullYear()
            ),
            this.schedulerItem.start.getMinutes()
        );
        this.schedulerItem.end = setMinutes(
            setYear(
                setMonth(
                    setDate(this.schedulerItem.start, date.getDate()),
                    date.getMonth() //
                ),
                date.getFullYear()
            ),
            this.schedulerItem.end.getMinutes()
        );

        this.checkIfScheduleExist();
    }

    public handleTimeInput(e: CustomEvent<EventDetails<UxInput, any>>): void {
        const value = e.detail.values.value as string;
        const type = e.detail.data as 'start' | 'end';

        let [hour, minutes] = value.split(':');

        // Make sure the hour is valid e.g checking the following:
        // 1: The hour is fully filled.
        // 2: The hour is not less than the start hour of the scheduler settings.
        // 3: The hour is not greater than the end hour of the scheduler settings.
        let startHourCorrected = false;
        let endHourCorrected = false;
        if (!hour.includes('_')) {
            if (type === 'start') {
                // The entered hour can never be before the start hour.
                if (Number(hour) < Number(this.schedulerSettings.start.hour)) {
                    hour = this.schedulerSettings.start.hour.toString();
                    startHourCorrected = true;
                }
                if (Number(hour) >= Number(this.schedulerSettings.end.hour)) {
                    // The entered start time can never begin at the end hour.
                    // For example if 20:00 is entered end hour is 20 and slot size is 5, we change the start time to 19:55.
                    // So we subtract one hour from the end hour and set the minutes to 60 - slotsize later on.
                    hour = (this.schedulerSettings.end.hour - 1).toString();
                    endHourCorrected = true;
                }
            } else if (type === 'end') {
                // The entered hour can never be before the start hour + slotsize.
                // For example if 08:00 between 08:{slotsize - 1} is entered and the start hour is 8 and slot size is 5, we change the end time to 8:05.
                if (Number(hour) === Number(this.schedulerSettings.start.hour) && Number(minutes) < Number(this.schedulerSettings.slotSize)) {
                    hour = this.schedulerSettings.start.hour.toString();
                    startHourCorrected = true;
                }
                // The entered hour can never be before the start hour.
                // For example if 07:45 is entered and the start hour is 8 , we change the end time to 8:00.
                if (Number(hour) < Number(this.schedulerSettings.start.hour)) {
                    hour = this.schedulerSettings.start.hour.toString();
                    startHourCorrected = true;
                }
                if (Number(hour) >= Number(this.schedulerSettings.end.hour)) {
                    // The entered time can never be after the end hour.
                    //  For example if 21:00 is entered end hour is 20, we change the start time to 20:00.
                    hour = this.schedulerSettings.end.hour.toString();
                    endHourCorrected = true;
                }
            }
        }

        // When start or end hour is corrected.
        if (startHourCorrected || endHourCorrected) {
            // For example if 20:00 is entered and the end hour is 20 and slot size is 5, we change the start time to 19:55.
            if (type === 'start' && endHourCorrected) minutes = (60 - this.schedulerSettings.slotSize).toString();
            // For example if 07:45 is entered and start hour is 8  and slot size is 5, we change the end time to 8:00.
            if (type === 'start' && startHourCorrected) minutes = '0';
            // For example if 21:45 is entered and end hour is 20, we change the end time to 20:00.
            if (type === 'end' && endHourCorrected) minutes = '0';
            // For example if 07:45 or 08:00 is entered and the start hour is 8 and slot size is 5, we change the end time to 8:05.
            if (type === 'end' && startHourCorrected) minutes = this.schedulerSettings.slotSize.toString();
        }
        // Else when all the minutes are properly filled in, make sure the minutes are valid.
        else if (!minutes.includes('_')) {
            // Make sure the minutes are not greater than 59 and
            // not less than 0. This has to be done before the next step.
            minutes = Number(minutes) > 59 ? '59' : minutes.toString();
            minutes = Number(minutes) < 0 ? '0' : minutes.toString();
            // Make sure the minutes are a multiple of the slot size.
            // but we don't want to round the minutes up when they come
            // close to the next hour. For example, if the slot size is 15
            // and the minutes are 50, we don't want to round it up to 60.
            minutes = (Math.round(Number(minutes) / this.schedulerSettings.slotSize) * this.schedulerSettings.slotSize).toString();
            if (Number(minutes) >= 60) minutes = (Number(minutes) - this.schedulerSettings.slotSize).toString();
        }

        // Always make sure the hours and minutes are 2 digits.
        const formatted = `${hour.padStart(2, '0')}:${minutes.padStart(2, '0')}`;

        // Only update the value if the input is valid.
        // We put the check here and not earlier because we want
        // to allow the hours and minutes to be corrected even though
        // if either one is not yet valid. Code below can only be done
        // if hours and minutes are valid.
        if (formatted.includes('_')) return;

        // Update the value
        if (type === 'start') {
            this.schedulerItem.start = setMinutes(setHours(this.schedulerItem.start, Number(hour)), Number(minutes));
            this.startInput.updateMaskValue();
            this.start = formatted;

            if (isBefore(this.schedulerItem.end, this.schedulerItem.start) || isEqual(this.schedulerItem.end, this.schedulerItem.start)) {
                this.setDurationBasedOnTypes();
            }
        } else {
            this.schedulerItem.end = setMinutes(setHours(this.schedulerItem.end, Number(hour)), Number(minutes));
            this.endInput.updateMaskValue();
            this.end = formatted;
        }

        this.checkIfScheduleExist();
    }

    public async handleIgnoringSchedule(e: CustomEvent<EventDetails<UxSelect, any>>): Promise<void> {
        const checked = e.detail.values.checked;
        if (checked || isNotDefined(this.schedulerItem.schedule)) return;

        this.schedulerItem.examinationRoom = this.scheduleRoom;

        if (isDefined(this.roomSelector) && isDefined(this.schedulerItem.examinationRoom)) this.roomSelector.setValue(this.schedulerItem.examinationRoom.id);
        setTimeout(() => {
            if (isDefined(this.locationSelector) && isDefined(this.schedulerItem.schedule)) this.locationSelector.setValue(this.schedulerItem.schedule.location.id);
        }, 50);
    }
    public handleRoomSelected(e: CustomEvent<EventDetails<UxCombobox, UxComboboxOption>>): void {
        const room = this.schedulerItem.location.rooms.find((x) => x.id === e.detail.values.value);
        this.schedulerItem.examinationRoom = room;
    }

    private async checkIfScheduleExist(): Promise<void> {
        if (isNotDefined(this.schedulerItem.practitioner)) return;
        this.checkingSchedule = true;

        // Get the timezone offset of the browser
        // NOTE: Get the timezone offset of the scheduler item date
        // and not the current date because the scheduler item date
        // can be in a different timezone that the current date.
        const timezoneOffset = this.schedulerItem.start.getTimezoneOffset();

        const response = await this.schedulesApi.getByPractitionerAndTimestamps(
            this.authenticated.workspace.id, //
            this.schedulerItem.practitioner.id,
            this.schedulerItem.start,
            this.schedulerItem.end,
            timezoneOffset
        );

        if (isDefined(response) && isDefined(response.schedule)) {
            this.location = await this.locationsApi.getById(response.schedule.location.id, this.authenticated.workspace.id);
            if (this.schedulerItem.createOutsideSchedule) {
                this.schedulerItem.schedule = response.schedule;
                this.checkingSchedule = false;
                return;
            }

            setTimeout(() => {
                this.schedulerItem.location = this.location;
                this.scheduleRoom = response.room;
                this.schedulerItem.examinationRoom = response.room;

                if (isDefined(this.roomSelector)) this.roomSelector.setValue(this.schedulerItem.examinationRoom.id);
                if (isDefined(this.locationSelector)) this.locationSelector.setValue(this.schedulerItem.location.id);

                this.schedulerItem.schedule = response.schedule;
                this.checkingSchedule = false;
            }, 250);
        } else {
            setTimeout(() => {
                this.schedulerItem.schedule = null;
                this.checkingSchedule = false;
            }, 250);
        }
    }

    public createPatient = async (): Promise<GetPatientResponse> => {
        return new Promise(async (resolve) => {
            await this.removeChildViews();
            await this.addPartialView({
                view: this.partial.base, //
                partial: this.PartialViews.CreatePatient.whenClosed(async (result: PartialViewResults, patient: GetPatientResponse) => {
                    if (result === PartialViewResults.Ok) {
                        this.handlePatientSelected(patient);
                        resolve(patient);
                    }
                }),
                options: new ViewOptions({ scrollToView: true, markItem: false, replace: true, updateUrl: false })
            });
        });
    };

    private setDurationBasedOnTypes(): void {
        let minutesToAdd: number = 0;
        for (const type of this.schedulerItem.appointmentTypes) {
            if (isDefined(type.duration) && !Number.isNaN(Number(type.duration)) && Number(type.duration) > 0) {
                minutesToAdd += Number(type.duration);
            }
        }
        if (minutesToAdd > 0) {
            this.schedulerItem.end = addMinutes(this.schedulerItem.start, minutesToAdd);
            this.end = format(this.schedulerItem.end, 'HH:mm');
        } else {
            this.schedulerItem.end = addMinutes(this.schedulerItem.start, Number(this.schedulerSettings.slotSize));
            this.end = format(this.schedulerItem.end, 'HH:mm');
        }
    }

    private validate(): boolean {
        resetValidation(this.validation);

        if (this.schedulerItem.type === SchedulerItemTypes.Appointment) {
            this.validation.types = this.schedulerItem.appointmentTypes.any();
            this.validation.patient = this.schedulerItem.patients.any();

            if (this.validation.practitioner || this.schedulerItem.createOutsideSchedule) {
                this.validation.location = isDefined(this.schedulerItem.location);
                if (this.validation.location) this.validation.room = isDefined(this.schedulerItem.examinationRoom);
            }

            if (!this.schedulerItem.createOutsideSchedule) this.validation.schedule = isDefined(this.schedulerItem.schedule);
            this.validation.practitioner = isDefined(this.schedulerItem.practitioner);
        } else if (this.schedulerItem.type === SchedulerItemTypes.Block) {
            if (this.context === SchedulerContext.User) {
                this.validation.practitioner = isDefined(this.schedulerItem.practitioner);
            } else if (this.context === SchedulerContext.Room) {
                this.validation.location = isDefined(this.schedulerItem.location);
                this.validation.room = isDefined(this.schedulerItem.examinationRoom);
            }
        }

        this.validation.date = isDefined(this.schedulerItem.start);
        this.validation.start = isDefined(this.schedulerItem.start);
        this.validation.end = isDefined(this.schedulerItem.end);

        return validateState(this.validation);
    }
}
