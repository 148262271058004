<import from="converters/sort-medical-history" />
<import from="converters/translate" />
<import from="converters/keys" />
<import from="converters/date" />
<import from="converters/month-to-string" />
<import from="converters/side-to-string" />
<import from="converters/medical-history-type-to-string" />
<import from="converters/priority-background-color" />
<import from="converters/priority-text-color" />

<template class="!flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex overflow-hidden" css.bind="styles">
        <template if.bind="baseLoaded">
            <div class="flex flex-1 h-full pl-5">
                <div class="pt-4 pr-5 border-r border-gray-200 w-44">
                    <nav class="space-y-1">
                        <button disabled.bind="!baseLoaded" class="flex items-center w-full px-3 py-2 text-sm font-medium text-gray-400 rounded-md hover:cursor-not-allowed">
                            <span class="truncate" t="translation:global.buttons.dashboard"></span>
                        </button>
                        <button disabled.bind="!baseLoaded" class="flex items-center w-full px-3 py-2 text-sm font-medium text-gray-400 rounded-md hover:cursor-not-allowed">
                            <span class="truncate" t="translation:global.buttons.activity"></span>
                        </button>
                        <a
                            if.bind="hasRole(UserRoles.ReadMedicalRecords)"
                            href="javascript:"
                            disabled.bind="!baseLoaded"
                            class.bind="view === 'ClinicalPathways' ? 'bg-gray-100 text-gray-900': 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'"
                            click.trigger="open('ClinicalPathways')"
                            class="flex items-center w-full px-3 py-2 text-sm font-medium rounded-md"
                            aria-current="page">
                            <span class="truncate" t="translation:partial-views.patients.buttons.clinical-pathways"></span>
                        </a>
                        <a
                            href="javascript:"
                            disabled.bind="!baseLoaded"
                            class.bind="view === 'PatientAppointments' ? 'bg-gray-100 text-gray-900': 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'"
                            click.trigger="open('PatientAppointments')"
                            class="flex items-center w-full px-3 py-2 text-sm font-medium rounded-md"
                            aria-current="page">
                            <span class="truncate" t="translation:partial-views.patients.buttons.appointments"></span>
                        </a>
                        <a
                            href="javascript:"
                            disabled.bind="!baseLoaded"
                            class.bind="view === 'PatientInvoices' ? 'bg-gray-100 text-gray-900': 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'"
                            click.trigger="open('PatientInvoices')"
                            class="flex items-center w-full px-3 py-2 text-sm font-medium rounded-md"
                            aria-current="page">
                            <span class="truncate" t="translation:global.buttons.invoices"></span>
                        </a>
                        <a
                            href="javascript:"
                            disabled.bind="!baseLoaded"
                            class.bind="view === 'PatientContactMoments' ? 'bg-gray-100 text-gray-900': 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'"
                            click.trigger="open('PatientContactMoments')"
                            class="flex items-center w-full px-3 py-2 text-sm font-medium rounded-md"
                            aria-current="page">
                            <span class="truncate" t="translation:partial-views.patients.buttons.contact-moments"></span>
                        </a>
                        <button disabled.bind="!baseLoaded" class="flex items-center w-full px-3 py-2 text-sm font-medium text-gray-400 rounded-md hover:cursor-not-allowed">
                            <span class="truncate" t="translation:partial-views.patients.buttons.relations"></span>
                        </button>
                        <button disabled.bind="!baseLoaded" class="flex items-center w-full px-3 py-2 text-sm font-medium text-gray-400 rounded-md hover:cursor-not-allowed">
                            <span class="truncate" t="translation:partial-views.patients.buttons.attachments"></span>
                        </button>
                        <button disabled.bind="!baseLoaded" class="flex items-center w-full px-3 py-2 text-sm font-medium text-gray-400 rounded-md hover:cursor-not-allowed">
                            <span class="truncate" t="translation:partial-views.patients.buttons.notes"></span>
                        </button>
                        <button disabled.bind="!baseLoaded" class="flex items-center w-full px-3 py-2 text-sm font-medium text-gray-400 rounded-md hover:cursor-not-allowed">
                            <span class="truncate" t="translation:partial-views.patients.buttons.tasks"></span>
                        </button>
                        <a
                            href="javascript:"
                            disabled.bind="!baseLoaded"
                            class.bind="view === 'PatientInsurances' ? 'bg-gray-100 text-gray-900': 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'"
                            click.trigger="open('PatientInsurances')"
                            class="flex items-center w-full px-3 py-2 text-sm font-medium rounded-md"
                            aria-current="page">
                            <span class="truncate" t="translation:partial-views.patients.buttons.insurances"></span>
                        </a>
                    </nav>
                </div>
                <div class="flex flex-col flex-1 w-full h-full pb-10 overflow-y-auto">
                    <div class="sticky top-0 z-10 flex flex-col gap-10 px-10 pt-6 pb-8 bg-white border-b border-gray-200">
                        <div class="flex items-center justify-between">
                            <ux-button if.bind="baseLoaded && hasRole(UserRoles.WriteSchedulerItems)" mode="outline" color="dark" click.trigger="createAppointment()" size="sm">
                                <span t="translation:partial-views.patients.buttons.new-appointment"></span>
                            </ux-button>
                            <ux-button-group full-width.bind="false">
                                <ux-dropdown type="full" color="dark" size="sm" padding.bind="false" mode="outline" if.bind="patient.emails.length > 0" placement="bottom-right" width="w-72">
                                    <div au-slot="label" class="pointer-events-none flex h-[38px] items-center px-2 text-gray-600">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                            <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                                        </svg>
                                    </div>
                                    <ux-dropdown-option repeat.for="email of patient.emails" color="dark" click.trigger="sendEmailTo(email)">
                                        <div class="flex items-center space-x-1">
                                            <span>${email.translations | translate} &bull; ${email.value}</span>
                                            <svg if.bind="email.isPrimary" class="w-4 h-4 text-yellow-500" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M4.75 16.25V5.75L9 11.25L12 5.75L15 11.25L19.25 5.75V16.25C19.25 16.25 18 18.25 12 18.25C6 18.25 4.75 16.25 4.75 16.25Z"
                                                    stroke="currentColor"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"></path>
                                            </svg>
                                        </div>
                                    </ux-dropdown-option>
                                </ux-dropdown>
                                <ux-dropdown type="full" color="dark" size="sm" padding.bind="false" mode="outline" if.bind="patient.phones.length > 0" placement="bottom-right" width="w-64">
                                    <div au-slot="label" class="pointer-events-none flex h-[38px] items-center px-2 text-gray-600">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                            <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                d="M20.25 3.75v4.5m0-4.5h-4.5m4.5 0l-6 6m3 12c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 014.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 00-.38 1.21 12.035 12.035 0 007.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 011.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 01-2.25 2.25h-2.25z" />
                                        </svg>
                                    </div>
                                    <ux-dropdown-option repeat.for="phone of patient.phones" color="dark" href="tel:${phone.callingCode}${phone.value}">
                                        <div class="flex items-center space-x-1">
                                            <span>${phone.translations | translate} &bull; +${phone.callingCode}${phone.value}</span>
                                            <svg if.bind="phone.isPrimary" class="w-4 h-4 text-yellow-500" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M4.75 16.25V5.75L9 11.25L12 5.75L15 11.25L19.25 5.75V16.25C19.25 16.25 18 18.25 12 18.25C6 18.25 4.75 16.25 4.75 16.25Z"
                                                    stroke="currentColor"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"></path>
                                            </svg>
                                        </div>
                                    </ux-dropdown-option>
                                </ux-dropdown>
                                <ux-dropdown
                                    if.bind="hasRole(UserRoles.WritePatients) || hasRole(UserRoles.DeletePatients)"
                                    type="full"
                                    color="dark"
                                    padding.bind="false"
                                    size="sm"
                                    mode="outline"
                                    placement="bottom-right">
                                    <div au-slot="label" class="pointer-events-none flex h-[38px] items-center px-2">
                                        <svg class="w-4 h-4 text-gray-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512">
                                            <path
                                                fill="currentColor"
                                                d="M64 368C90.51 368 112 389.5 112 416C112 442.5 90.51 464 64 464C37.49 464 16 442.5 16 416C16 389.5 37.49 368 64 368zM64 208C90.51 208 112 229.5 112 256C112 282.5 90.51 304 64 304C37.49 304 16 282.5 16 256C16 229.5 37.49 208 64 208zM64 144C37.49 144 16 122.5 16 96C16 69.49 37.49 48 64 48C90.51 48 112 69.49 112 96C112 122.5 90.51 144 64 144z" />
                                        </svg>
                                    </div>
                                    <ux-dropdown-option if.bind="hasRole(UserRoles.WritePatients)" color="dark" click.trigger="covCheck()">
                                        <span t="translation:partial-views.patients.buttons.cov"></span>
                                    </ux-dropdown-option>
                                    <!-- <ux-dropdown-option color="dark">BSN</ux-dropdown-option>
                                <ux-dropdown-option color="dark">DMV</ux-dropdown-option> -->
                                    <ux-dropdown-option if.bind="hasRole(UserRoles.WritePatients)" color="dark" click.trigger="edit()">
                                        <span t="translation:global.buttons.edit"></span>
                                    </ux-dropdown-option>
                                    <ux-dropdown-divider if.bind="hasRole(UserRoles.Owner) || hasRole(UserRoles.Admin)"></ux-dropdown-divider>
                                    <ux-dropdown-option if.bind="hasRole(UserRoles.Owner) || hasRole(UserRoles.Admin)" color="dark" click.trigger="merge()">
                                        <span class="text-red-500 pointer-events-none" t="translation:partial-views.patients.buttons.merge"></span>
                                    </ux-dropdown-option>
                                    <ux-dropdown-divider if.bind="hasRole(UserRoles.WritePatients) && hasRole(UserRoles.DeletePatients)"></ux-dropdown-divider>
                                    <ux-dropdown-option if.bind="hasRole(UserRoles.DeletePatients)" color="dark" click.trigger="delete()">
                                        <span class="text-red-500 pointer-events-none" t="translation:global.buttons.delete"></span>
                                    </ux-dropdown-option>
                                </ux-dropdown>
                                <ux-button if.bind="closeable" padding.bind="false" click.trigger="close()" color="dark" size="sm" mode="outline">
                                    <div class="pointer-events-none flex h-[38px] items-center px-2 text-red-500">
                                        <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                        </svg>
                                    </div>
                                </ux-button>
                            </ux-button-group>
                        </div>
                        <!-- Header -->
                        <div class="grid grid-cols-12 gap-2">
                            <div class="flex col-span-12">
                                <div class="flex items-center gap-5">
                                    <ux-avatar
                                        size="lg"
                                        name.bind="patient.displayName"
                                        color.bind="patient.avatar.color"
                                        shade.bind="patient.avatar.shade"
                                        email.bind="patient.emails[0]?.value"></ux-avatar>
                                    <div class="flex flex-col gap-2">
                                        <div class="flex items-center gap-5">
                                            <h4 class="text-2xl font-bold whitespace-nowrap">
                                                ${patient.firstName || ''} ${patient.lastNameAffix || ''} ${patient.lastName} <span if.bind="patient.initials">(${patient.initials})</span>
                                            </h4>
                                            <div class="flex gap-2">
                                                <div if.bind="patient.indications.some((x) => x.type === CareIndicationTypes.Zorgmodule)" class="flex">
                                                    <let indication.bind="patient.indications.find((x) => x.type === CareIndicationTypes.Zorgmodule)"></let>
                                                    <ux-badge type="danger" padding="px-1 py-0.5">
                                                        <div class="flex items-center p-1 font-bold flex-nowrap gap-x-1">
                                                            <div class="flex">
                                                                <p>S</p>
                                                                <p if.bind="indication.classification === SimsClassifications.Zero">0</p>
                                                                <p if.bind="indication.classification === SimsClassifications.One">1</p>
                                                                <p if.bind="indication.classification === SimsClassifications.Two">2</p>
                                                                <p if.bind="indication.classification === SimsClassifications.Three">3</p>
                                                            </div>
                                                            <div class="flex">
                                                                <p>ZP</p>
                                                                <p if.bind="indication.careProfile === CareProfiles.Zero">0</p>
                                                                <p if.bind="indication.careProfile === CareProfiles.One">1</p>
                                                                <p if.bind="indication.careProfile === CareProfiles.Two">2</p>
                                                                <p if.bind="indication.careProfile === CareProfiles.Three">3</p>
                                                                <p if.bind="indication.careProfile === CareProfiles.Four">4</p>
                                                            </div>
                                                        </div>
                                                    </ux-badge>
                                                </div>
                                                <div if.bind="patient.labels.length > 0" class="flex gap-2">
                                                    <template repeat.for="item of patient.labels">
                                                        <ux-badge background.bind="item.priority | priorityBackgroundColor:true" text.bind="item.priority | priorityTextColor" padding="px-1 py-0.5">
                                                            <div class="flex items-center p-1 font-bold flex-nowrap gap-x-1">
                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                                                    <path
                                                                        stroke-linecap="round"
                                                                        stroke-linejoin="round"
                                                                        d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                                                                </svg>
                                                                <span class="uppercase flex-nowrap">${item.label.translations | translate}</span>
                                                            </div>
                                                        </ux-badge>
                                                    </template>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flex gap-10 text-sm">
                                            <div class="flex gap-3">
                                                <div class="flex">
                                                    <p class="text-gray-500" t="translation:global.labels.number"></p>
                                                    <span>:</span>
                                                </div>
                                                <p class="font-medium text-gray-800">${patient.number || ''}</p>
                                            </div>
                                            <div class="flex gap-3">
                                                <div class="flex">
                                                    <p class="text-gray-500" t="translation:global.labels.gender"></p>
                                                    <span>:</span>
                                                </div>
                                                <p if.bind="patient.gender === Genders.Female" class="font-medium" t="translation:global.labels.female"></p>
                                                <p if.bind="patient.gender === Genders.Male" class="font-medium" t="translation:global.labels.male"></p>
                                                <p if.bind="patient.gender === Genders.Other" class="font-medium" t="translation:global.labels.other"></p>
                                                <p if.bind="patient.gender === Genders.Unknown" class="font-medium" t="translation:global.labels.unknown"></p>
                                            </div>
                                            <div class="flex gap-3">
                                                <div class="flex">
                                                    <p class="text-gray-500" t="translation:global.labels.dob"></p>
                                                    <span>:</span>
                                                </div>
                                                <p class="font-medium text-gray-800">${patient.dateOfBirth | date} (${getAge(patient.dateOfBirth)})</p>
                                            </div>
                                            <div class="flex gap-3">
                                                <div class="flex">
                                                    <p class="text-gray-500">BSN</p>
                                                    <span>:</span>
                                                </div>
                                                <p class="font-medium text-gray-800">${patient.citizensServiceNumber || ''}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="grid grid-cols-12 px-10 mt-5 gap-x-5 gap-y-3">
                        <!-- General info -->
                        <div class="flex flex-col col-span-6 gap-3">
                            <div class="flex flex-col">
                                <p class="w-full p-1 text-sm text-gray-600" t="translation:global.labels.general-information"></p>
                                <div class="mt-1 border border-gray-200 rounded-md">
                                    <dl class="divide-y divide-gray-100">
                                        <div class="py-1 pl-4 sm:grid sm:grid-cols-3">
                                            <dt class="font-medium text-gray-900 text-sm/6" t="translation:partial-views.patients.labels.head-practitioner"></dt>
                                            <dd class="pr-4 mt-1 text-gray-700 text-sm/6 sm:col-span-2 sm:mt-0">
                                                <p if.bind="headPractitioner" class="flex items-center space-x-1.5 font-medium">
                                                    <span> ${patient.headPractitioner.name}</span>
                                                </p>
                                                <p else class="text-gray-400">---------</p>
                                            </dd>
                                        </div>
                                        <div py-1.class="!patient.generalPractitioner" class="items-center pl-4 sm:grid sm:grid-cols-3">
                                            <dt class="font-medium text-gray-900 text-sm/6" t="translation:global.labels.general-practitioner"></dt>
                                            <dd class="mt-1 text-gray-700 text-sm/6 sm:col-span-2 sm:mt-0">
                                                <button
                                                    if.bind="patient.generalPractitioner"
                                                    click.trigger="showContact(patient.generalPractitioner.id)"
                                                    class="flex items-center w-full py-1 text-gray-700 rounded-br-md hover:bg-gray-50">
                                                    <span>${patient.generalPractitioner.name }</span>
                                                </button>
                                                <p else class="text-gray-400">---------</p>
                                            </dd>
                                        </div>
                                        <div class="py-1 pl-4 sm:grid sm:grid-cols-3">
                                            <dt class="font-medium text-gray-900 text-sm/6" t="translation:global.labels.weight"></dt>
                                            <dd class="pr-4 mt-1 text-gray-700 text-sm/6 sm:col-span-2 sm:mt-0">
                                                <p class="flex items-center space-x-1.5 font-medium">${patient.weight ?? 0}kg</p>
                                            </dd>
                                        </div>
                                        <div class="py-1 pl-4 sm:grid sm:grid-cols-3">
                                            <dt class="font-medium text-gray-900 text-sm/6" t="translation:global.labels.length"></dt>
                                            <dd class="pr-4 mt-1 text-gray-700 text-sm/6 sm:col-span-2 sm:mt-0">
                                                <p class="flex items-center space-x-1.5 font-medium">${patient.length ?? 0}cm</p>
                                            </dd>
                                        </div>
                                        <div class="py-1 pl-4 sm:grid sm:grid-cols-3">
                                            <dt class="font-medium text-gray-900 text-sm/6" t="translation:partial-views.patients.labels.shoe-size"></dt>
                                            <dd class="pr-4 mt-1 text-gray-700 text-sm/6 sm:col-span-2 sm:mt-0">
                                                <p if.bind="patient.shoeSize" class="flex items-center space-x-1.5 font-medium">${patient.shoeSize}</p>
                                                <p else class="text-gray-400">---------</p>
                                            </dd>
                                        </div>
                                    </dl>
                                </div>
                            </div>
                        </div>
                        <!-- Contact info -->
                        <div class="flex flex-col col-span-6 gap-3">
                            <!-- Email -->
                            <div class="flex flex-col">
                                <p class="w-full p-1 text-sm text-gray-600" t="translation:global.labels.emails"></p>
                                <div class="mt-1 border border-gray-200 rounded-md">
                                    <div class="">
                                        <dl class="divide-y divide-gray-100">
                                            <template repeat.for="email of patient.emails">
                                                <div class="py-1 pl-4 sm:grid sm:grid-cols-3">
                                                    <dt if.bind="email.translations" class="font-medium text-gray-900 text-sm/6">${email.translations | translate:language}</dt>
                                                    <dt else class="text-gray-400 text-sm/6" t="translation:global.labels.no-description"></dt>
                                                    <dd class="pr-4 mt-1 text-gray-700 text-sm/6 sm:col-span-2 sm:mt-0">
                                                        <ux-anchor size="sm" click.trigger="sendEmailTo(email)">${email.value}</ux-anchor>
                                                    </dd>
                                                </div>
                                            </template>
                                            <dt if.bind="patient.emails.length === 0" class="px-5 py-1 text-gray-400 text-sm/6">-------------</dt>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                            <!-- Phones -->
                            <div class="flex flex-col">
                                <p class="w-full p-1 text-sm text-gray-600" t="translation:global.labels.phone-numbers"></p>
                                <div class="mt-1 border border-gray-200 rounded-md">
                                    <div class="">
                                        <dl class="divide-y divide-gray-100">
                                            <template repeat.for="phone of patient.phones">
                                                <div class="py-1 pl-4 sm:grid sm:grid-cols-3">
                                                    <dt if.bind="phone.translations" class="font-medium text-gray-900 text-sm/6">${phone.translations | translate:language}</dt>
                                                    <dt else class="text-gray-400 text-sm/6" t="translation:global.labels.no-description"></dt>
                                                    <dd class="pr-4 mt-1 text-gray-700 text-sm/6 sm:col-span-2 sm:mt-0">
                                                        <ux-anchor size="sm" link="tel:${phone.callingCode}${phone.value}">+${phone.callingCode}${phone.value}</ux-anchor>
                                                    </dd>
                                                </div>
                                            </template>
                                            <dt if.bind="patient.phones.length === 0" class="px-5 py-1 text-gray-400 text-sm/6">-------------</dt>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                            <!-- Addresses -->
                            <div class="flex flex-col">
                                <p class="w-full p-1 text-sm text-gray-600" t="translation:global.labels.addresses"></p>
                                <div class="mt-1 border border-gray-200 rounded-md">
                                    <div class="">
                                        <dl class="divide-y divide-gray-100">
                                            <template repeat.for="address of patient.addresses">
                                                <div class="py-1 pl-4 sm:grid sm:grid-cols-3">
                                                    <dt if.bind="address.translations" class="font-medium text-gray-900 text-sm/6">${address.translations | translate:language}</dt>
                                                    <dt else class="text-gray-400 text-sm/6" t="translation:global.labels.no-description"></dt>
                                                    <dd class="items-center pr-4 mt-1 text-gray-700 text-sm/6 sm:col-span-2 sm:mt-0">
                                                        ${address.street} ${address.houseNumber}${address.houseNumberSuffix} ${address.zipCode}, ${address.city}
                                                        <!-- ${ 'translation:global.countries.' + address.country | t} -->
                                                    </dd>
                                                </div>
                                            </template>
                                            <dt if.bind="patient.adresses.length === 0" class="px-5 py-1 text-gray-400 text-sm/6">-------------</dt>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Medication -->
                        <div class="flex flex-col col-span-6">
                            <p class="w-full p-1 text-sm text-gray-600" t="translation:global.labels.medications"></p>
                            <div class="mt-1 border border-gray-200 rounded-md">
                                <dl class="divide-y divide-gray-100">
                                    <div repeat.for="item of patient.medications" class="py-1 pl-4 sm:grid sm:grid-cols-3">
                                        <dt class="font-medium text-gray-900 text-sm/6">${item.medication.translations | translate:language}</dt>
                                        <dd class="pr-4 mt-1 text-gray-700 text-sm/6 sm:col-span-2 sm:mt-0">
                                            <p if.bind="item.comments" class="flex items-center space-x-1.5">
                                                <span> ${item.comments | translate:language}</span>
                                            </p>
                                            <p else class="text-gray-400">---------</p>
                                        </dd>
                                    </div>
                                    <dt if.bind="patient.medications.length === 0" class="px-5 py-1 text-gray-400 text-sm/6">-------------</dt>
                                </dl>
                            </div>
                        </div>
                        <!-- Equipment -->
                        <div class="flex flex-col col-span-6">
                            <p class="w-full p-1 text-sm text-gray-600" t="translation:global.labels.medical-equipment"></p>
                            <div class="mt-1 border border-gray-200 rounded-md">
                                <dl class="divide-y divide-gray-100">
                                    <div repeat.for="item of patient.medicalEquipment" class="py-1 pl-4 sm:grid sm:grid-cols-3">
                                        <dt class="font-medium text-gray-900 text-sm/6">${item.equipment.translations | translate:language}</dt>
                                        <dd class="pr-4 mt-1 text-gray-700 text-sm/6 sm:col-span-2 sm:mt-0">
                                            <p if.bind="item.comments" class="flex items-center space-x-1.5">
                                                <span> ${item.comments | translate:language}</span>
                                            </p>
                                            <p else class="text-gray-400">---------</p>
                                        </dd>
                                    </div>
                                    <dt if.bind="patient.medicalEquipment.length === 0" class="px-5 py-1 text-gray-400 text-sm/6">-------------</dt>
                                </dl>
                            </div>
                        </div>
                        <!-- Sports -->
                        <div class="flex flex-col col-span-6">
                            <p class="w-full p-1 text-sm text-gray-600" t="translation:global.labels.sports"></p>
                            <div class="mt-1 border border-gray-200 rounded-md">
                                <dl class="divide-y divide-gray-100">
                                    <div repeat.for="item of patient.sports" class="py-1 pl-4 sm:grid sm:grid-cols-3">
                                        <dt class="font-medium text-gray-900 text-sm/6">${item.sport.translations | translate:language}</dt>
                                        <dd class="pr-4 mt-1 text-gray-700 text-sm/6 sm:col-span-2 sm:mt-0">
                                            <p if.bind="item.comments" class="flex items-center space-x-1.5">
                                                <span> ${item.comments | translate:language}</span>
                                            </p>
                                            <p else class="text-gray-400">---------</p>
                                        </dd>
                                    </div>
                                    <dt if.bind="patient.sports.length === 0" class="px-5 py-1 text-gray-400 text-sm/6">-------------</dt>
                                </dl>
                            </div>
                        </div>
                        <!-- Profession -->
                        <div class="flex flex-col col-span-6">
                            <p class="w-full p-1 text-sm text-gray-600" t="translation:global.labels.professions"></p>
                            <div class="mt-1 border border-gray-200 rounded-md">
                                <dl class="divide-y divide-gray-100">
                                    <div repeat.for="item of patient.professions" class="py-1 pl-4 sm:grid sm:grid-cols-3">
                                        <dt class="font-medium text-gray-900 text-sm/6">${item.profession.translations | translate:language}</dt>
                                        <dd class="pr-4 mt-1 text-gray-700 text-sm/6 sm:col-span-2 sm:mt-0">
                                            <p if.bind="item.comments" class="flex items-center space-x-1.5">
                                                <span> ${item.comments | translate:language}</span>
                                            </p>
                                            <p else class="text-gray-400">---------</p>
                                        </dd>
                                    </div>
                                    <dt if.bind="patient.professions.length === 0" class="px-5 py-1 text-gray-400 text-sm/6">-------------</dt>
                                </dl>
                            </div>
                        </div>
                        <!-- History -->
                        <div class="flex flex-col col-span-12">
                            <p class="w-full p-1 text-sm text-gray-600" t="translation:partial-views.patients.labels.medical-history"></p>
                            <div class="py-1 mt-1 border border-gray-200 rounded-md">
                                <dl class="divide-y divide-gray-100">
                                    <div
                                        repeat.for="type of MedicalHistoryItemTypes | keys"
                                        items-start.class="getHistoryLength(patient.medicalHistory, type) !== 0"
                                        items-center.class="getHistoryLength(patient.medicalHistory, type) === 0"
                                        class="items-center py-1 pl-4 sm:grid sm:grid-cols-3">
                                        <dt class="font-medium text-gray-900 text-sm/6">${ type | medicalHistoryTypeToString | t}</dt>
                                        <dt if.bind="getHistoryLength(patient.medicalHistory, type) === 0" class="col-span-2 text-gray-400 text-sm/6">
                                            ----------------------------------------------------------------------------------------------------
                                        </dt>
                                        <div else class="flex flex-col w-full col-span-2 text-sm/6">
                                            <dd
                                                repeat.for="item of patient.medicalHistory | sortMedicalHistory:MedicalHistoryItemTypes[type]:language"
                                                class="flex pr-4 mt-1 text-gray-700 sm:col-span-2 sm:mt-0">
                                                <p class="flex items-center space-x-1.5">
                                                    <span> ${item.name | translate:language}</span>
                                                </p>
                                                <span class="ml-1 text-right" if.bind="item.year || item.month || item.side">
                                                    (<span if.bind="item.side">${item.side | sideToString | t}</span><span if.bind="item.month || item.year">/</span
                                                    ><span if.bind="item.month">${item.month | monthToString | t}</span><span if.bind="item.month && item.year">/</span
                                                    ><span if.bind="item.year">${item.year}</span>)
                                                </span>
                                            </dd>
                                        </div>
                                    </div>
                                </dl>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <div else class="flex flex-1 px-10 py-8 mt-3">
            <ux-skeleton show-background.bind="false" type="container">
                <div class="flex flex-col w-full py-1 space-y-5">
                    <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                    <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                    <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                    <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                    <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                    <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                    <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                    <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                    <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                </div>
            </ux-skeleton>
        </div>
    </section>
</template>
