import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { InformationSheet, MedicalExaminationModelBox, MedicalExaminationModelsApiClient, UpdateMedicalExaminationModelRequest } from '@wecore/sdk-healthcare';
import { isDefined, isNotDefined, isNotEmpty, isOfType, validateState } from '@wecore/sdk-utilities';

import { IEventAggregator, inject } from 'aurelia';
import { PartialViewResults } from '../../../enums/partial-view-results';
import { BasePartialView } from '../../../infra/base-partial-view';
import { CacheService } from '../../../infra/cache-service';
import { ErrorHandler } from '../../../infra/error-handler';
import { PartialViews } from '../../../infra/partial-views';
import { State } from '../../../infra/store/state';
import { cleanTranslatables, setTranslation, validateTranslation } from '../../../infra/utilities';
import { ConfirmationOptions } from '../../../models/confirmation-options';
import { PartialView } from '../../../models/partial-view';
import { ViewOptions } from '../../../models/view-options';
import { ModalService } from '../../../services/service.modals';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, MedicalExaminationModelsApiClient, ModalService)
export class PartialMedicalExaminationModelsEdit extends BasePartialView {
    public modelId: string;
    public model: UpdateMedicalExaminationModelRequest;
    public validation = {
        name: true,
        displayOrder: true,
        boxesAmount: true,
        boxes: [],
        valid: true
    };

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly modelsApi: MedicalExaminationModelsApiClient,
        private readonly modalService: ModalService
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.modelId = view.data.id;
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                this.model = await this.modelsApi.getById(this.modelId, this.authenticated.workspace.id);

                this.setTranslationsAndValidations();

                // Delay showing content to prevent flickering.
                setTimeout(async () => {
                    this.baseLoaded = true;
                    await super.handleBaseLoaded();
                }, 250);
            })
            .catch((x) => this.errorHandler.handle('PartialMedicalExaminationModelsEdit.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }

    public async cancel(): Promise<void> {
        await super.remove({
            result: PartialViewResults.Cancelled,
            updateUrl: true
        });
    }

    public toggleExpanded(index: number): void {
        this.model.boxes[index].attributes.expanded = !this.model.boxes[index].attributes.expanded;
    }

    public addBox(): void {
        for (const box of this.model.boxes) box.attributes.expanded = false;

        this.model.boxes.push(
            new MedicalExaminationModelBox({
                name: setTranslation({}, this.language),
                description: setTranslation({}, this.language),
                registrations: [],
                attributes: {
                    expanded: true
                }
            })
        );

        this.validation.boxes.push({
            name: true
        });
    }

    public removeBox(index: number): void {
        this.model.boxes.splice(index, 1);
        this.validation.boxes.splice(index, 1);
        if (this.model.boxes.empty()) this.addBox();
    }

    public async save(): Promise<void> {
        const valid = this.validate();

        if (valid) {
            this.isLoading = true;
            try {
                cleanTranslatables(
                    [
                        'name', //
                        'description',
                        'boxes:name',
                        'boxes:description'
                    ],
                    this.model,
                    2
                );
                await this.modelsApi.update(this.modelId, this.authenticated.workspace.id, this.model);
                this.notifications.show(
                    this.t.tr('translation:partial-views.medical-examination-models.notifications.save-successful.title'),
                    this.t
                        .tr('translation:partial-views.medical-examination-models.notifications.save-successful.message') //
                        .replace('{entity}', `<span>'${this.model.name[this.language]}'</span>`),
                    {
                        type: 'success',
                        duration: 3000
                    }
                );
                setTimeout(async () => this.remove({ result: PartialViewResults.Ok, updateUrl: true }), 250);
            } catch (e) {
                // When the save() goes wrong make sure to re-set the translations object that
                // are possibly set to null and are not required for this request.
                // Otherwise the cleanTranslatables() will fail because the translations object is null.
                if (isNotDefined(this.model.description)) this.model.description = setTranslation({}, this.language);
                for (const box of this.model.boxes) {
                    if (isNotDefined(box.description)) box.description = setTranslation({}, this.language);
                }
                this.isLoading = false;
                await this.errorHandler.handle('[edit-medical-examination-model]', e);
            }
        }
    }

    public async delete(): Promise<void> {
        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('partial-views.medical-examination-models.questions.delete.title'),
                message: this.t
                    .tr('partial-views.medical-examination-models.questions.delete.message') //
                    .replace('{entity}', `<span>'${this.model.name[this.language]}'</span>`),
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        this.deleting = true;
                        try {
                            await this.modelsApi.delete(this.modelId, this.authenticated.workspace.id);
                            this.notifications.show(
                                this.t.tr('translation:partial-views.medical-examination-models.notifications.deleted-successfully.title'),
                                this.t
                                    .tr('translation:partial-views.medical-examination-models.notifications.deleted-successfully.message') //
                                    .replace('{entity}', `<span>'${this.model.name[this.language]}'</span>`),
                                { type: 'success', duration: 3000 }
                            );
                            setTimeout(async () => this.remove({ result: PartialViewResults.Deleted, updateUrl: true }), 250);
                        } catch (e) {
                            this.deleting = false;
                            await this.errorHandler.handle('[delete-medical-examination-model-detail]', e);
                        }
                    }
                }
            })
        );
    }

    public manageTranslationsFor(property: string, required: boolean = false, index: number): void {
        let translations: any;
        if (property.includes(':')) {
            const [_, prop] = property.split(':');
            translations = this.model.boxes[index][prop];
        } else translations = this.model[property];

        this.manageTranslations({
            translations,
            callback: (updatedTranslations: any) => {
                if (property.includes(':')) {
                    const [_, prop] = property.split(':');
                    this.model.boxes[index][prop] = updatedTranslations;
                } else this.model[property] = updatedTranslations;
            },
            required,
            type: property.includes('description') ? 'textarea' : 'input'
        });
    }

    public informationSheet(): void {
        this.addPartialView({
            view: this.partial.base,
            partial: PartialViews.InformationSheet.with({
                config: {
                    mode: 'edit',
                    language: this.language
                },
                sheet: this.model.informationSheet
            }).whenClosed(async (result: PartialViewResults, sheet: InformationSheet) => {
                if (result === PartialViewResults.Ok) {
                    this.model.informationSheet = sheet;
                }
            }),
            options: new ViewOptions({
                index: this.partial.index + 1,
                scrollToView: true,
                markItem: true,
                replace: true
            })
        });
    }

    private setTranslationsAndValidations(): void {
        if (isNotDefined(this.model.description)) this.model.description = setTranslation({}, this.language);
        for (const box of this.model.boxes) {
            if (isNotDefined(box.description)) box.description = setTranslation({}, this.language);
            this.validation.boxes.push({
                name: true
            });
        }
    }

    private validate(): boolean {
        this.validation.name = validateTranslation(this.model.name, this.language);
        this.validation.displayOrder = isNotEmpty(this.model.displayOrder) && isOfType(Number(this.model.displayOrder), 'number');
        this.validation.boxesAmount = isDefined(this.model.boxes) && this.model.boxes.any();

        for (let index = 0; index < this.model.boxes.length; index++) {
            const box = this.model.boxes[index];
            this.validation.boxes[index].name = validateTranslation(box.name, this.language);
        }

        this.validation.valid = validateState(this.validation) && this.validation.boxes.every(validateState);
        return this.validation.valid;
    }
}
