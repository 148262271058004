import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { MedicalRecordResult, MedicalRecordResultReference, MedicalRecordResultStatuses } from '@wecore/sdk-healthcare';

import { isDefined } from '@wecore/sdk-utilities';
import { IEventAggregator, inject } from 'aurelia';
import { SideToStringValueConverter } from '../../converters/side-to-string';
import { TranslateValueConverter } from '../../converters/translate';
import { PartialViewResults } from '../../enums/partial-view-results';
import { BasePartialView } from '../../infra/base-partial-view';
import { CacheService } from '../../infra/cache-service';
import { ErrorHandler } from '../../infra/error-handler';
import { State } from '../../infra/store/state';
import { PartialView } from '../../models/partial-view';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N)
export class PartialConnectTherapies extends BasePartialView {
    public itemResults: MedicalRecordResultReference[];
    public allResults: MedicalRecordResult[];
    public selected: string[];

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.itemResults = view.data.results;
        this.allResults = view.data.allResults;

        this.selected = this.itemResults.map((x) => x.resultId);
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                this.baseLoaded = true;
            })
            .catch((x) => this.errorHandler.handle('PartialConnectTherapies.attached', x));
    }

    public toSet(): { value: string; text: string; data?: any }[] {
        return this.allResults
            .filter((x) => x.status === MedicalRecordResultStatuses.WorkingDiagnosis)
            .map((result: MedicalRecordResult) => ({ value: result.id, text: result.differentialDiagnosis.name[this.language], data: result }));
    }

    public link(): void {
        this.remove({
            result: PartialViewResults.Ok,
            data: this.selected.map(
                (id) => this.allResults.find((result) => result.id === id) //
            )
        });
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }

    public async cancel(): Promise<void> {
        await super.remove({
            result: PartialViewResults.Cancelled,
            updateUrl: true
        });
    }

    public getExtra = (item: any): string => {
        const result = item.data as MedicalRecordResult;
        const converter = new SideToStringValueConverter();
        const translator = new TranslateValueConverter();

        let returnValue = `<span class="flex items-center gap-2 w-full justify-between">`;

        returnValue += `<span>${translator.toView(result.differentialDiagnosis.name, this.language)}</span>`;

        if (isDefined(result.side) || isDefined(result.location)) {
            returnValue += '<span>';

            if (isDefined(result.side)) returnValue += `${this.t.tr(converter.toView(result.side))}`;
            if (isDefined(result.side) && isDefined(result.location)) returnValue += ` + `;
            if (isDefined(result.location)) returnValue += `${translator.toView(result.location.translations, this.language)}`;

            returnValue += '</span>';
        }

        returnValue += '</span>';

        return returnValue;
    };
}
