<import from="converters/date" />
<import from="converters/translate" />
<import from="bx/bx-address-selector/bx-address-selector" />

<template class="!flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex flex-col overflow-hidden gap-x-2" css.bind="styles">
        <ux-header>
            <p class="text-sm font-medium" t="translation:partial-views.patient-cov-check.labels.title"></p>
        </ux-header>
        <div ref="scrollContainer" class="flex flex-col flex-1 h-full px-6 pb-6 overflow-y-auto scroll-smooth">
            <template if.bind="baseLoaded">
                <div class="flex flex-col flex-1 mt-3">
                    <div if.bind="lookup" class="flex flex-col">
                        <div if.bind="lookup.result === CovResultTypes.Found" class="flex flex-col gap-1">
                            <div repeat.for="insurance of lookup.insurances" class="flex flex-col p-4 rounded-lg bg-gray-50">
                                <div class="flex items-center justify-between gap-3">
                                    <div class="flex items-center min-w-0 gap-1 text-sm">
                                        <span class="font-semibold truncate">${insurance.name}</span>
                                        <span class="flex flex-nowrap">
                                            <span>(</span>
                                            <span
                                                class="whitespace-nowrap"
                                                if.bind="insurance.type === InsuranceTypes.Supplementary"
                                                t="translation:partial-views.patient-cov-check.labels.insurance-type-supplementary"></span>
                                            <span
                                                class="whitespace-nowrap"
                                                if.bind="insurance.type === InsuranceTypes.SupplementaryPlusDental"
                                                t="translation:partial-views.patient-cov-check.labels.insurance-type-supplementary-dental">
                                            </span>
                                            <span
                                                class="whitespace-nowrap"
                                                if.bind="insurance.type === InsuranceTypes.Awbz"
                                                t="translation:partial-views.patient-cov-check.labels.insurance-type-awbz"></span>
                                            <span
                                                class="whitespace-nowrap"
                                                if.bind="insurance.type === InsuranceTypes.Basic"
                                                t="translation:partial-views.patient-cov-check.labels.insurance-type-basic"></span>
                                            <span
                                                class="whitespace-nowrap"
                                                if.bind="insurance.type === InsuranceTypes.MainInsurance"
                                                t="translation:partial-views.patient-cov-check.labels.insurance-type-main"></span>
                                            <span
                                                class="whitespace-nowrap"
                                                if.bind="insurance.type === InsuranceTypes.Dental"
                                                t="translation:partial-views.patient-cov-check.labels.insurance-type-dental"></span>
                                            <span>)</span>
                                        </span>
                                    </div>
                                    <p if.bind="insurance.status === InsuranceStatuses.Active" class="text-sm text-green-500" t="translation:global.labels.active"></p>
                                    <p if.bind="insurance.status === InsuranceStatuses.Inactive" class="text-sm text-red-500" t="translation:global.labels.inactive"></p>
                                    <p if.bind="insurance.status === InsuranceStatuses.Unauthorized" class="text-sm text-red-500" t="translation:global.labels.unauthorized"></p>
                                </div>
                                <div class="flex gap-1 text-sm text-gray-500">
                                    <p if.bind="insurance.insurer">${insurance.insurer.translations | translate:language} -</p>
                                    <p>${insurance.insurerReference}</p>
                                    <div class="flex gap-1">
                                        <div class="flex">
                                            <span>(</span>
                                            <span>${insurance.startDate | date}</span>
                                            <span if.bind="!insurance.endDate">)</span>
                                        </div>
                                        <div if.bind="insurance.endDate" class="flex">
                                            <span class="pr-1"> - </span>
                                            <span>${insurance.endDate | date}</span>
                                            <span if.bind="insurance.insurer">)</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div else class="flex flex-col p-4 mb-3 text-sm text-red-600 rounded-lg bg-gray-50">
                            <p
                                if.bind="lookup.result === CovResultTypes.UnknownBsnDateOfBirthCombination"
                                t="translation:partial-views.patient-cov-check.labels.results-unknown-bsn-dob-combination"></p>
                            <p if.bind="lookup.result === CovResultTypes.UnknownBsn" t="translation:partial-views.patient-cov-check.labels.results-unknown-bsn"></p>
                            <p if.bind="lookup.result === CovResultTypes.InvalidBsn" t="translation:partial-views.patient-cov-check.labels.results-invalid-bsn"></p>
                            <p if.bind="lookup.result === CovResultTypes.InvalidSearchPath" t="translation:partial-views.patient-cov-check.labels.results-invalid-searchpath"></p>
                            <p if.bind="lookup.result === CovResultTypes.FoundFutureReferenceDate" t="translation:partial-views.patient-cov-check.labels.results-future-reference-date"></p>
                            <p if.bind="lookup.result === CovResultTypes.MultipleSearchPaths" t="translation:partial-views.patient-cov-check.labels.results-multiple-searchpaths"></p>
                            <p if.bind="lookup.result === CovResultTypes.MultiplePersonsFound" t="translation:partial-views.patient-cov-check.labels.results-multiple-persons"></p>
                            <p if.bind="lookup.result === CovResultTypes.NotFound" t="translation:partial-views.patient-cov-check.labels.results-not-found"></p>
                            <p if.bind="lookup.result === CovResultTypes.ReferenceDateTooOld" t="translation:partial-views.patient-cov-check.labels.results-past-reference-date"></p>
                        </div>
                    </div>
                    <div
                        if.bind="missingInformation 
                            || lookup.result === CovResultTypes.UnknownBsn
                            || lookup.result === CovResultTypes.InvalidBsn
                            || lookup.result === CovResultTypes.MultiplePersonsFound
                            || lookup.result === CovResultTypes.NotFound
                            || lookup.result === CovResultTypes.UnknownBsn
                            || lookup.result === CovResultTypes.UnknownBsnDateOfBirthCombination"
                        class="flex flex-col gap-2">
                        <p if.bind="missingInformation && !lookup" class="text-sm text-gray-500" t="translation:partial-views.patient-cov-check.messages.missing-information"></p>
                        <div class="flex flex-col">
                            <div class="flex items-center space-x-1">
                                <ux-label class="mb-1">
                                    <span t="translation:global.labels.dob"></span>
                                </ux-label>
                                <span class="flex items-center h-3 text-red-500">*</span>
                            </div>
                            <ux-datepicker valid.bind="validation.dob" value.two-way="dateOfBirth" mode="input" t="[placeholder]translation:partial-views.patients.placeholders.select-dob">
                            </ux-datepicker>
                        </div>
                        <div class="flex flex-col flex-1 col-span-6">
                            <div class="flex items-center space-x-1">
                                <ux-label class="mb-1">
                                    <span t="translation:partial-views.patients.labels.citizens-service-number"></span>
                                </ux-label>
                                <!-- <span class="flex items-center h-3 text-red-500">*</span> -->
                            </div>
                            <ux-input valid.bind="validation.bsn" value.two-way="citizensServiceNumber" t="[placeholder]translation:partial-views.patient-cov-check.placeholders.coc"></ux-input>
                        </div>
                        <div class="flex flex-col flex-1 col-span-6">
                            <div class="flex items-center space-x-1">
                                <ux-label class="mb-1">
                                    <span t="translation:global.labels.addresses"></span>
                                </ux-label>
                                <!-- <span class="flex items-center h-3 text-red-500">*</span> -->
                            </div>
                            <bx-address-selector
                                descriptions.bind="descriptions"
                                language.bind="language"
                                mode="vertical"
                                valid.bind="validation.address"
                                addresses.bind="addresses"
                                t="[label]translation:components.widgets.patient-addresses.labels.enter-addresses">
                            </bx-address-selector>
                        </div>
                    </div>
                </div>
            </template>
            <template else>
                <div class="flex flex-col flex-1 pt-4 gap-y-2">
                    <ux-skeleton show-background.bind="false" type="container">
                        <div class="flex flex-col w-full py-1 space-y-5">
                            <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-0"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-2"></ux-skeleton>
                            <ux-skeleton type="line"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-2"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                            <ux-skeleton type="line"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-4"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-2"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                        </div>
                    </ux-skeleton>
                </div>
            </template>
        </div>
        <div class="sticky bottom-0 z-50 flex justify-end px-6 py-3 bg-white border-t border-gray-200 gap-x-2">
            <ux-button size="sm" color="dark" mode="outline" disabled.bind="isLoading" click.trigger="close()">
                <span class="pointer-events-none" t="global.buttons.close"></span>
            </ux-button>
            <ux-button
                if.bind="missingInformation  || lookup.result === CovResultTypes.UnknownBsn
            || lookup.result === CovResultTypes.InvalidBsn
            || lookup.result === CovResultTypes.MultiplePersonsFound
            || lookup.result === CovResultTypes.NotFound
            || lookup.result === CovResultTypes.UnknownBsn
            || lookup.result === CovResultTypes.UnknownBsnDateOfBirthCombination"
                size="sm"
                color="primary"
                disabled.bind="isLoading"
                loading.bind="isLoading"
                click.trigger="execute()">
                <span class="pointer-events-none" t="translation:partial-views.patient-cov-check.buttons.execute"></span>
            </ux-button>
            <ux-button
                if.bind="lookup.result === CovResultTypes.Found"
                size="sm"
                color="success"
                disabled.bind="isLoading"
                loading.bind="isLoading"
                click.trigger="save()"
                t="[text-when-loading]translation:global.labels.loading-states.default">
                <span class="pointer-events-none" t="translation:partial-views.patient-cov-check.buttons.save-to-patient"></span>
            </ux-button>
        </div>
    </section>
</template>
