import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { GetProSoftwareReportResponse, ImportsApiClient } from '@wecore/sdk-healthcare';
import { IEventAggregator, inject } from 'aurelia';
import { PartialViewResults } from '../../../enums/partial-view-results';
import { BasePartialView } from '../../../infra/base-partial-view';
import { CacheService } from '../../../infra/cache-service';
import { ErrorHandler } from '../../../infra/error-handler';
import { State } from '../../../infra/store/state';
import { PartialView } from '../../../models/partial-view';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, ImportsApiClient)
export class PartialProSoftwareReport extends BasePartialView {
    public report: GetProSoftwareReportResponse;
    private reportId: string;

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly importsApi: ImportsApiClient
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.reportId = view.data.id;
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                this.report = await this.importsApi.getProSoftwareReport(this.reportId, this.authenticated.workspace.id);
                this.baseLoaded = true;
            })
            .catch((x) => this.errorHandler.handle('PartialProSoftwareReport.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached, updateUrl: false });
    }

    public async cancel(): Promise<void> {
        await super.remove({
            result: PartialViewResults.Cancelled,
            updateUrl: true
        });
    }
}
