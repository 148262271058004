import { UserRoles } from '@wecore/sdk-core';
import {
    GetHealthcareTaskListResponse,
    GetHealthcareTaskResponse,
    HealthcareTaskListEntityReference,
    HealthcareTasksApiClient,
    OrderingType,
    SortDirection,
    UpdateHealthcareTaskPositionRequest
} from '@wecore/sdk-healthcare';
import { PageState, getPageState, isDefined, isFunction, isNotDefined, savePageState } from '@wecore/sdk-utilities';
import { IDisposable, IEventAggregator, bindable, containerless, inject } from 'aurelia';
import { CustomEvents } from '../../../infra/events';

@containerless
@inject(HealthcareTasksApiClient, IEventAggregator)
export class TemplateTaskList {
    @bindable() public list: GetHealthcareTaskListResponse;
    @bindable() public tasks: GetHealthcareTaskResponse[];
    @bindable() public selected: GetHealthcareTaskResponse[];
    @bindable() public name: string;
    @bindable() public language: string;
    @bindable() public workspace: string;
    @bindable() public canEdit: boolean = true;
    @bindable() public isPersonal: boolean = false;
    @bindable() public state: { isDraggingTask: boolean; isDraggingList: boolean };
    @bindable() public expanded: boolean = true;
    @bindable() public lists: TemplateTaskList[];
    @bindable() public hasRole: (role: UserRoles) => boolean;
    @bindable() public onEdit: (list: GetHealthcareTaskListResponse) => Promise<void>;
    @bindable() public onNewTask: (list: GetHealthcareTaskListResponse, isPersonalTask: boolean, displayOrder: number) => Promise<GetHealthcareTaskResponse>;
    @bindable() public onEditTask: (task: GetHealthcareTaskResponse) => Promise<boolean>;
    @bindable() public openUserPosts: (task: GetHealthcareTaskResponse) => Promise<boolean>;
    @bindable() public onExpandedChange: (list: GetHealthcareTaskListResponse, expanded: boolean) => void;
    @bindable() public onDragStart: (e: Event, list: GetHealthcareTaskListResponse, el: HTMLDivElement) => Promise<void>;
    @bindable() public onDrag: (e: Event, list: GetHealthcareTaskListResponse, el: HTMLDivElement) => Promise<void>;
    @bindable() public onDragEnd: (e: Event, list: GetHealthcareTaskListResponse, el: HTMLDivElement) => Promise<void>;
    @bindable() public onDragCancelled: (e: KeyboardEvent, list: GetHealthcareTaskListResponse) => Promise<void>;

    public baseLoaded: boolean = false;
    public selectedAll: boolean = false;
    public container: HTMLDivElement;
    public element: HTMLDivElement;
    public pageState: PageState;
    public UserRoles: typeof UserRoles = UserRoles;

    private placeholder: HTMLDivElement;
    private subscriptions: IDisposable[];
    private displayOrderIncrement: number = 100;
    private offset: number = 0;
    private moveCb: (e: MouseEvent) => void;
    private moveEndCb: (e: MouseEvent) => void;
    private moveKeyCb: (e: KeyboardEvent) => void;
    private debounce: NodeJS.Timeout;

    public constructor(
        private readonly tasksApi: HealthcareTasksApiClient, //
        private readonly events: IEventAggregator
    ) {}

    public async bound(): Promise<void> {
        if (this.isPersonal)
            this.list = {
                name: {
                    nl: 'Persoonlijk',
                    en: 'Personal'
                } as { [key: string]: string },
                id: 'personal',
                displayOrder: 1,
                color: '#324AD8'
            } as GetHealthcareTaskListResponse;

        this.pageState =
            getPageState(this.list.id) ??
            new PageState({
                name: this.list.id,
                values: {
                    sort: {}
                }
            });

        await this.refresh(false);

        this.subscriptions = [
            ...(this.subscriptions ?? []),
            this.events.subscribe(CustomEvents.TasksListsArchived, () => {
                this.selectedAll = false;
                this.refresh();
            }),
            this.events.subscribe(CustomEvents.TasksListsDeleted, () => {
                this.selectedAll = false;
                this.refresh();
            }),
            this.events.subscribe(
                CustomEvents.TasksMoved,
                async (data: {
                    task: GetHealthcareTaskResponse; //
                    vm: TemplateTaskList;
                    displayOrder: number;
                    el: HTMLDivElement;
                }) => {
                    // Ignore if the list is not the same as the current list.
                    // Task does not always have a list. But if it does,
                    // we should check if the list is the same as the current list
                    const originalList = data.task.list;
                    if (isDefined(originalList) && this.list.id !== originalList.id && this.list.id !== data.vm.list.id) return;

                    const task = GetHealthcareTaskResponse.fromJS(data.task);
                    // Update the display order of the task.
                    task.displayOrder = data.displayOrder;
                    task.isPersonal = data.vm.isPersonal;

                    if (data.vm.isPersonal) task.list = null;
                    else
                        task.list = new HealthcareTaskListEntityReference({
                            id: data.vm.list.id,
                            translations: data.vm.list.name,
                            data: {
                                listColor: data.vm.list.color
                            }
                        });

                    // Save the changes when on the new list. Don't wait for it though (no await).
                    if (this.list.id === data.vm.list.id)
                        this.tasksApi.updatePosition(
                            task.id,
                            this.workspace,
                            new UpdateHealthcareTaskPositionRequest({
                                displayOrder: task.displayOrder,
                                list: task.list,
                                isPersonal: task.isPersonal
                            })
                        );

                    // Delete the task from the old list (doesnt matter which list is current).
                    const index = this.tasks.findIndex((x) => x.id === task.id);
                    if (index !== -1) this.tasks.splice(index, 1);

                    //  Add the task to the new list
                    if ((isDefined(task.list) && this.list.id == task.list.id) || (task.isPersonal && this.isPersonal)) {
                        let insertIndex = 0;
                        for (let i = 0; i < this.tasks.length; i++) {
                            if (this.tasks[i].displayOrder > task.displayOrder) {
                                insertIndex = i;
                                break;
                            } else {
                                insertIndex = i + 1;
                            }
                        }

                        // Insert the new item at the found index
                        this.tasks.splice(insertIndex, 0, task);
                        data.el.remove();
                    }
                }
            )
        ];

        const placeholder = document.createElement('div');
        placeholder.id = 'placeholder-task';
        placeholder.classList.add('col-span-12', 'border', 'border-dashed', 'border-gray-300', 'h-[38px]');
        this.placeholder = placeholder;

        this.baseLoaded = true;
    }

    public detaching(): void {
        this.subscriptions.forEach((x) => x.dispose());
    }

    public sort = async (field: 'Title' | 'Owner' | 'Deadline' | 'Status' | 'Priority'): Promise<void> => {
        if (isNotDefined(this.pageState.values.sort)) this.pageState.values.sort = {};

        if (this.pageState.values.sort.field === field && isNotDefined(this.pageState.values.sort.direction)) this.pageState.values.sort.direction = SortDirection.Asc;
        else if (this.pageState.values.sort.field === field && this.pageState.values.sort.direction === SortDirection.Asc) this.pageState.values.sort.direction = SortDirection.Desc;
        else if (this.pageState.values.sort.field === field && this.pageState.values.sort.direction === SortDirection.Desc) this.pageState.values.sort.direction = undefined;
        else {
            this.pageState.values.sort.field = field;
            this.pageState.values.sort.direction = SortDirection.Asc;
        }

        await this.refresh();

        savePageState(this.pageState);
    };

    public edit(): void {
        if (isFunction(this.edit)) this.onEdit(this.list);
    }

    public collapseOrExpand(): void {
        this.expanded = !this.expanded;
        if (isFunction(this.onExpandedChange)) this.onExpandedChange(this.list, this.expanded);
    }

    public async addTask(): Promise<void> {
        if (isFunction(this.onNewTask)) {
            const last = [...this.tasks].orderByDescending((x: GetHealthcareTaskResponse) => x.displayOrder)[0];
            const task = await this.onNewTask(this.list, false, (last?.displayOrder ?? 0) + this.displayOrderIncrement);
            if (isDefined(task)) this.tasks = [...this.tasks, task];
        }
    }

    public handleTaskSelected = (_: GetHealthcareTaskResponse): void => {
        if (this.selected.length === this.tasks.length) this.selectedAll = true;
        else this.selectedAll = false;
    };

    public selectTasks(): void {
        // Always remove the tasks from this list, from the selected list.
        this.selected = this.selected.filter((x) => this.tasks.every((y) => y.id !== x.id));
        // Change the flag.
        this.selectedAll = !this.selectedAll;
        // If all should be selected, add all tasks to the list.
        if (this.selectedAll) this.selected = [...this.selected, ...this.tasks];
    }

    public handleEditTask = async (task: GetHealthcareTaskListResponse): Promise<boolean> => {
        if (isFunction(this.onEditTask)) {
            const edited = await this.onEditTask(task);
            if (edited && isDefined(this.list.id)) this.refresh();
            return edited;
        } else return false;
    };

    public handleUserPosts = async (task: GetHealthcareTaskListResponse): Promise<boolean> => {
        if (isFunction(this.openUserPosts)) {
            const edited = await this.openUserPosts(task);
            if (edited && isDefined(this.list.id)) this.refresh();
            return edited;
        } else return false;
    };

    public handleTaskDragStart = async (e: MouseEvent, task: GetHealthcareTaskResponse, el: HTMLDivElement): Promise<void> => {
        // Prevent other events from firing. E.g. the drag and select events.
        e.preventDefault();
        // Clone the task list element.
        const clone = el.cloneNode(true) as HTMLDivElement;
        clone.id = `clone-${task.id}`;
        clone.style.position = 'absolute';
        clone.style.zIndex = '10000';
        clone.style.opacity = '90%';
        clone.style.width = `${el.clientWidth}px`;

        const box = this.container.getBoundingClientRect();
        this.offset = e.clientX - box.left;

        // Set the left position relative to the container left position and mouse position.
        clone.style.top = `${e.clientY - clone.clientHeight / 2}px`;
        clone.style.left = `${e.clientX - this.offset}px`;

        // Add the clone to the active container.
        document.body.appendChild(clone);
    };

    public handleTaskDrag = async (e: MouseEvent, task: GetHealthcareTaskResponse, el: HTMLDivElement): Promise<void> => {
        // Find the clone.
        const clone = document.querySelector(`#clone-${task.id}`) as HTMLDivElement;
        if (isNotDefined(clone)) return;

        // Set the left position relative to the container left position and mouse position.
        clone.style.top = `${e.clientY - clone.clientHeight / 2}px`;
        clone.style.left = `${e.clientX - this.offset}px`;

        // Find the list we're hovering over. We have to temp hide the clone
        // to find the container beneath it.
        clone.classList.add('hidden');
        // Find the element beneath the clone.
        const element = document.elementFromPoint(e.clientX, e.clientY);
        const list = element.closest('[data-type="task-list"]');

        const content = list?.querySelector('[data-type="task-list-content"]');

        // Show the clone again.
        clone.classList.remove('hidden');

        // Don't do anything if we're not hovering over a list.
        if (isNotDefined(list)) return;

        // Don't do anything if we're not hovering over a list.
        if (isNotDefined(content)) return;

        // Find the element after which the clone should be placed.
        const afterElement = this.getDragAfterElement(content, e.clientY);

        // Add the placeholder while hovering to indicate where the clone will be placed.
        if (isNotDefined(afterElement)) content.appendChild(this.placeholder);
        else content.insertBefore(this.placeholder, afterElement);

        // Hide the original element after adding the placeholder.
        el.classList.add('hidden');
    };

    public handleTaskDragEnd = async (e: MouseEvent, task: GetHealthcareTaskResponse, el: HTMLDivElement): Promise<void> => {
        // Remove the clone.
        document.querySelector(`#clone-${task.id}`)?.remove();
        // Use the placeholder to find the correct list.
        const placeholder = document.querySelector(`#placeholder-task`);
        const listContent = placeholder.closest('[data-type="task-list-content"]');

        // Get the elements after the placeholder and before the placeholder.
        const previousElement = placeholder.previousElementSibling as HTMLDivElement;
        const nextElement = placeholder.nextElementSibling as HTMLDivElement;

        // Get the list where the task is moved to.
        const newListVm = this.lists.find((x) => x.list.id === listContent.id);

        const previous = Number(previousElement?.getAttribute('data-order') ?? 0);
        const next = Number(nextElement?.getAttribute('data-order') ?? 0);

        let displayOrder = 0;

        if (next > 0) displayOrder = (previous + next) / 2;
        else displayOrder = Number(previous) + this.displayOrderIncrement;

        this.events.publish(CustomEvents.TasksMoved, {
            task,
            vm: newListVm,
            displayOrder,
            el
        });

        // We don't need the placeholder anymore.
        placeholder.remove();
    };

    public handleTaskDragCancelled = async (e: KeyboardEvent, task: GetHealthcareTaskResponse): Promise<void> => {
        if (e.key === 'Escape') {
            this.state.isDraggingTask = false;
            document.querySelector(`#clone-${task.id}`)?.remove();
            document.querySelector(`#placeholder-task`)?.remove();
        }
    };

    public async handleMoveMouseDown(e: MouseEvent): Promise<void> {
        // Only left click.
        if (e.button !== 0) return;
        // We must distinguish between a click and a drag.
        // Record the initial mouse position.
        const startX = e.clientX;
        const startY = e.clientY;
        const handleMouseMove = async (event: MouseEvent): Promise<void> => {
            // Calculate the current mouse position.
            const currentX = event.clientX;
            const currentY = event.clientY;
            // Check if the mouse has moved more than 5 pixels in either direction
            if (Math.abs(currentX - startX) > 5 || Math.abs(currentY - startY) > 5) {
                this.state.isDraggingList = true;
                // Remove the event listener that listens for the initial mouse down event.
                // Otherwise the event listener will be called multiple times.
                document.removeEventListener('mousemove', handleMouseMove);
                // Lets start the dragging process.
                this.element.classList.add('!cursor-move');
                await this.onDragStart(e, this.list, this.element);
                // Save the callbacks otherwise removeEventListener won't work.

                this.moveCb = (e) => this.handleMoveMouseMove(e);
                this.moveEndCb = (e) => this.handleMoveMouseUp(e);
                this.moveKeyCb = (e) => this.handleMoveKeyEvent(e);
                // Add the event listeners.
                document.addEventListener('mousemove', this.moveCb);
                document.addEventListener('mouseup', this.moveEndCb, { once: true });
                document.addEventListener('keydown', this.moveKeyCb, { once: true });
            }
        };

        const handleMouseUp = async () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
            // Check if the task is already being dragged, otherwise the task is clicked.
            if (this.state.isDraggingList) return;
            // Clicked/
        };

        // Lets listen for the mouse move event.
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    }

    private async handleMoveMouseMove(e: MouseEvent): Promise<void> {
        clearTimeout(this.debounce);
        this.debounce = setTimeout(async () => {
            if (this.state.isDraggingList) await this.onDrag(e, this.list, this.element);
        });
    }

    private async handleMoveMouseUp(e: MouseEvent): Promise<void> {
        this.state.isDraggingList = false;
        await this.onDragEnd(e, this.list, this.element);
        if (isDefined(this.element)) {
            this.element.classList.remove('!cursor-move');
            this.element.classList.remove('hidden');
        }

        document.removeEventListener('mousemove', this.moveCb);
        document.removeEventListener('mouseup', this.moveEndCb);
        document.removeEventListener('keydown', this.moveKeyCb);
    }

    private async handleMoveKeyEvent(e: KeyboardEvent): Promise<void> {
        if (!this.state.isDraggingList) return;

        await this.onDragCancelled(e, this.list);

        if (e.key === 'Escape' && isDefined(this.element)) {
            this.element.classList.remove('hidden');
            this.element.classList.remove('!cursor-move');

            document.removeEventListener('mousemove', this.moveCb);
            document.removeEventListener('mouseup', this.moveEndCb);
            document.removeEventListener('keydown', this.moveKeyCb);
        }
    }

    private getDragAfterElement(container: any, y: number): HTMLElement {
        const draggableElements = [...container.querySelectorAll(`.draggable-task:not(.dragging)`)];
        return draggableElements.reduce(
            (closest, child) => {
                const box = child.getBoundingClientRect();
                const offset = y - box.top - box.height / 2;
                if (offset < 0 && offset > closest.offset) return { offset, element: child };
                else return closest;
            },
            { offset: Number.NEGATIVE_INFINITY }
        ).element;
    }

    private async refresh(stopLoading: boolean = true): Promise<void> {
        this.baseLoaded = false;
        const [tasks] = await Promise.all([
            this.isPersonal
                ? this.tasksApi.getPersonal(
                      this.workspace,
                      '',
                      1000,
                      0,
                      isDefined(this.pageState.values.sort.direction) ? this.pageState.values.sort.direction : undefined,
                      isDefined(this.pageState.values.sort.field) && isDefined(this.pageState.values.sort.direction) ? this.pageState.values.sort.field : undefined,
                      OrderingType.String,
                      false
                  )
                : this.tasksApi.search(
                      this.workspace,
                      '',
                      1000,
                      0,
                      isDefined(this.pageState.values.sort.direction) ? this.pageState.values.sort.direction : undefined,
                      isDefined(this.pageState.values.sort.field) && isDefined(this.pageState.values.sort.direction) ? this.pageState.values.sort.field : undefined,
                      OrderingType.String,
                      undefined,
                      [this.list.id],
                      undefined,
                      false
                  ) //
        ]);

        this.tasks = tasks.data;
        if (stopLoading) setTimeout(() => (this.baseLoaded = true), 250);
    }
}
