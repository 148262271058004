<import from="converters/translate" />

<template class="!flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex flex-col overflow-hidden" css.bind="styles">
        <ux-header>
            <div class="flex items-center justify-between">
                <p class="text-sm font-medium" t="translation:partial-views.manage-scheduler.labels.header"></p>
            </div>
        </ux-header>
        <div ref="scrollContainer" class="flex h-full flex-1 flex-col gap-3 overflow-y-auto scroll-smooth px-6 pb-6 pt-5">
            <template if.bind="baseLoaded">
                <div class="flex flex-col">
                    <ux-label class="mb-1">
                        <span t="translation:partial-views.manage-scheduler.labels.slotsize"></span>
                    </ux-label>
                    <ux-select value.two-way="settings.slotSize" onselect.trigger="handleSlotsizeChanged($event)">
                        <ux-select-option value="5">5</ux-select-option>
                        <ux-select-option value="10">10</ux-select-option>
                        <ux-select-option value="15">15</ux-select-option>
                        <ux-select-option value="30">30</ux-select-option>
                    </ux-select>
                </div>
                <div class="flex gap-3">
                    <div class="flex w-full flex-col">
                        <ux-label class="mb-1">
                            <span t="translation:partial-views.manage-scheduler.labels.scheduler-start"></span>
                        </ux-label>
                        <ux-select value.two-way="settings.start.hour">
                            <ux-select-option repeat.for="hour of hours" value.bind="hour">${hour}</ux-select-option>
                        </ux-select>
                    </div>
                    <div class="flex w-full flex-col pt-6">
                        <ux-select value.two-way="settings.start.minute">
                            <ux-select-option repeat.for="minute of minutes" value.bind="minute">${minute}</ux-select-option>
                        </ux-select>
                    </div>
                </div>
                <div class="flex gap-3">
                    <div class="flex w-full flex-col">
                        <ux-label class="mb-1">
                            <span t="translation:partial-views.manage-scheduler.labels.scheduler-end"></span>
                        </ux-label>
                        <ux-select value.two-way="settings.end.hour">
                            <ux-select-option repeat.for="hour of hours" value.bind="hour">${hour}</ux-select-option>
                        </ux-select>
                    </div>
                    <div class="flex w-full flex-col pt-6">
                        <ux-select value.two-way="settings.end.minute">
                            <ux-select-option repeat.for="minute of minutes" value.bind="minute">${minute}</ux-select-option>
                        </ux-select>
                    </div>
                </div>
            </template>
            <template else>
                <div class="flex flex-1 flex-col gap-y-2 pt-4">
                    <ux-skeleton show-background.bind="false" type="container">
                        <div class="flex w-full flex-col space-y-5 py-1">
                            <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-0"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-2"></ux-skeleton>
                            <ux-skeleton type="line"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-2"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                            <ux-skeleton type="line"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-4"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-2"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-1"></ux-skeleton>
                            <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                        </div>
                    </ux-skeleton>
                </div>
            </template>
        </div>
        <ux-footer>
            <div class="flex w-full items-center justify-end gap-x-2">
                <ux-button size="sm" color="success" disabled.bind="isLoading" loading.bind="isLoading" click.trigger="save()" t="[text-when-loading]translation:global.labels.loading-states.default">
                    <span class="pointer-events-none" t="global.buttons.save"></span>
                </ux-button>
            </div>
        </ux-footer>
    </section>
</template>
