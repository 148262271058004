import { BlobStorageAttachment } from '@wecore/sdk-attachments';
import { GetAuthenticatedResponse } from '@wecore/sdk-core';
import {
    CareIndication,
    CareIndicationEvent,
    CareIndicationTypes,
    GetDifferentialDiagnosisResponse,
    GetMedicalRecordRegistrationResponse,
    GetPatientResponse,
    MedicalExaminationFlow,
    MedicalExaminationTemplateItemStep,
    MedicalHistoryItem,
    MedicalHistoryItemTypes,
    MedicalWidgetTypes,
    UserEntityReference,
    WidgetResult,
    WidgetResultTypes
} from '@wecore/sdk-healthcare';
import { clone, guid, isDefined, isEmpty, isNotDefined } from '@wecore/sdk-utilities';
import { bindable, containerless } from 'aurelia';
import { WidgetRegistration } from '../../../../../models/widget-registration';
import { EventDetails } from '../../../../../models/event-details';
import { UxSelect } from '../../../../../ux/ux-select/ux-select';
import { UxSelectOption } from '../../../../../ux/ux-select-option/ux-select-option';
import { SimsClassifications } from '../../../../../enums/sims-classifications';
import { CareProfiles } from '../../../../../enums/care-profiles';

@containerless
export class WidgetCareIndication {
    @bindable() public registration: GetMedicalRecordRegistrationResponse;
    @bindable() public flow: MedicalExaminationFlow;
    @bindable() public step: MedicalExaminationTemplateItemStep;
    @bindable() public patient: GetPatientResponse;
    @bindable() public currentUser: GetAuthenticatedResponse;
    @bindable() public required: boolean;
    @bindable() public validation: any;
    @bindable() public language: string;
    @bindable() public workspace: string;
    @bindable() public widgets: WidgetRegistration[] = [];
    @bindable() public manageTranslations: (translations: any, callback: (translations: any) => void, type: 'textarea' | 'input', required: boolean) => void;

    public answers: {
        hasHistoryOfUlcus: 1 | 0 | null;
        hasHistoryOfCharhotsFoot: 1 | 0 | null;
        hasHistoryOfVascularDisease: 1 | 0 | null;
        lowerLimbAmputation: 1 | 0 | null;
        renalFailure: 1 | 0 | null;
        activeUlcus: 1 | 0 | null;
        suspectedActiveCharchotsFoot: 1 | 0 | null;
        peripheralSensoryLoss: 1 | 0 | null;
        suspectedPeripheralArterialDisease: 1 | 0 | null;
        signsOfHyperpression: 1 | 0 | null;
    } = {
        hasHistoryOfUlcus: null,
        hasHistoryOfCharhotsFoot: null,
        hasHistoryOfVascularDisease: null,
        lowerLimbAmputation: null,
        renalFailure: null,
        activeUlcus: null,
        suspectedActiveCharchotsFoot: null,
        peripheralSensoryLoss: null,
        suspectedPeripheralArterialDisease: null,
        signsOfHyperpression: null
    };

    public sims: SimsClassifications;
    public careProfile: CareProfiles;
    public new: boolean = true;
    public SimsClassifications: typeof SimsClassifications = SimsClassifications;
    public CareProfiles: typeof CareProfiles = CareProfiles;

    private lastEvent: CareIndicationEvent;

    public async bound(): Promise<void> {
        if (isNotDefined(this.registration)) return;

        // Push the widget with its callbacks for use later on.
        this.registration.widget.result.type = WidgetResultTypes.Dynamic;

        if (this.patient.indications && this.patient.indications.some((x) => x.type === CareIndicationTypes.Zorgmodule)) {
            this.new = false;
            const index = this.patient.indications.findIndex((x) => x.type === CareIndicationTypes.Zorgmodule);
            this.sims = SimsClassifications[this.patient.indications[index].classification];
            this.careProfile = CareProfiles[this.patient.indications[index].careProfile];

            const sorted = this.patient.indications[index].events.sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime());
            this.lastEvent = sorted[sorted.length - 1];
            if (isDefined(this.lastEvent)) {
                this.answers = {
                    ...this.lastEvent.data.answers
                };
            }
        }

        this.widgets.push(
            new WidgetRegistration({
                stepId: this.step.id,
                type: MedicalWidgetTypes.CareIndication,
                onSave: async (): Promise<void> => {
                    const didAnswersChange = this.didAnswerChange(this.answers, this.lastEvent?.data.answers);

                    if (isDefined(this.patient.indications) && this.patient.indications.some((x) => x.type === CareIndicationTypes.Zorgmodule)) {
                        const index = this.patient.indications.findIndex((x) => x.type === CareIndicationTypes.Zorgmodule);

                        if (
                            isDefined(this.careProfile) &&
                            isDefined(this.sims) &&
                            (this.patient.indications[index].classification !== this.sims || this.patient.indications[index].careProfile != this.careProfile || didAnswersChange)
                        ) {
                            this.patient.indications[index].classification = this.sims;
                            this.patient.indications[index].careProfile = this.careProfile;
                            this.patient.indications[index].events.push(
                                new CareIndicationEvent({
                                    id: guid(),
                                    createdAt: new Date(),
                                    createdBy: new UserEntityReference({
                                        id: this.currentUser.id,
                                        name: this.currentUser.displayName
                                    }),
                                    type: 'Modified',
                                    data: {
                                        recordId: this.registration.record.id,
                                        registrationId: this.registration.id,
                                        answers: this.answers
                                    }
                                })
                            );
                        }
                    } else if (isDefined(this.careProfile) && isDefined(this.sims)) {
                        if (isNotDefined(this.patient.indications)) this.patient.indications = [];
                        this.patient.indications = [
                            new CareIndication({
                                id: guid(),
                                type: CareIndicationTypes.Zorgmodule,
                                events: [
                                    new CareIndicationEvent({
                                        id: guid(),
                                        createdAt: new Date(),
                                        createdBy: new UserEntityReference({
                                            id: this.currentUser.id,
                                            name: this.currentUser.displayName
                                        }),
                                        type: 'Created',
                                        data: {
                                            recordId: this.registration.record.id,
                                            registrationId: this.registration.id,
                                            answers: this.answers
                                        }
                                    })
                                ],
                                careProfile: this.careProfile,
                                classification: this.sims
                            })
                        ];
                    }
                },
                validate: (_: WidgetResult, __: any): boolean => {
                    return true;
                },
                refresh: async (): Promise<void> => {},
                onFileUploaded: async (_: BlobStorageAttachment): Promise<void> => {}
            })
        );
    }

    public handleHasHistoryOfUlcusChanged(e: CustomEvent<EventDetails<UxSelect, UxSelectOption>>): void {
        this.classify();
    }

    public handleHasHistoryOfCharhotsFootChanged(e: CustomEvent<EventDetails<UxSelect, UxSelectOption>>): void {
        this.classify();
    }

    public handleHasHistoryOfVascularDiseaseChanged(e: CustomEvent<EventDetails<UxSelect, UxSelectOption>>): void {
        this.classify();
    }

    public handleLowerLimbAmputationChanged(e: CustomEvent<EventDetails<UxSelect, UxSelectOption>>): void {
        this.classify();
    }

    public handleRenalFailureChanged(e: CustomEvent<EventDetails<UxSelect, UxSelectOption>>): void {
        this.classify();
    }

    public handleActiveUlcusChanged(e: CustomEvent<EventDetails<UxSelect, UxSelectOption>>): void {
        this.classify();
    }

    public handleSuspectedActiveCharchotsFootChanged(e: CustomEvent<EventDetails<UxSelect, UxSelectOption>>): void {
        this.classify();
    }

    public handlePeripheralSensoryLossChanged(e: CustomEvent<EventDetails<UxSelect, UxSelectOption>>): void {
        this.classify();
    }

    public handleSuspectedPeripheralArterialDiseaseChanged(e: CustomEvent<EventDetails<UxSelect, UxSelectOption>>): void {
        this.classify();
    }

    public handleSignsOfHyperpressionChanged(e: CustomEvent<EventDetails<UxSelect, UxSelectOption>>): void {
        this.classify();
    }

    private didAnswerChange(current: any, previous: any): boolean {
        if (isNotDefined(previous)) return false;
        // Compare each answer to see if it has changed.
        for (const key in current) {
            if (current[key] !== previous[key]) return true;
        }
        return false;
    }

    private classify(): void {
        this.sims = SimsClassifications.Two;
        this.careProfile = CareProfiles.Three;
    }
}
