import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { AttachmentEntities } from '@wecore/sdk-attachments';
import {
    BlobStorageAttachment,
    DeclarationEventTypes,
    DeclarationPerformanceTypes,
    DeclarationPerformancesApiClient,
    DeclarationSendMethods,
    DeclarationStatuses,
    DeclarationsApiClient,
    GetDeclarationPerformanceResponse,
    GetDeclarationResponse,
    InsuranceStatuses,
    InsuranceTypes
} from '@wecore/sdk-healthcare';
import { VecozoApiClient } from '@wecore/sdk-integrations';
import { isDefined, serveBlob } from '@wecore/sdk-utilities';
import { IEventAggregator, inject } from 'aurelia';
import { PartialViewResults } from '../../../enums/partial-view-results';
import { BasePartialView } from '../../../infra/base-partial-view';
import { CacheService } from '../../../infra/cache-service';
import { ErrorHandler } from '../../../infra/error-handler';
import { CustomEvents } from '../../../infra/events';
import { PartialViews } from '../../../infra/partial-views';
import { State } from '../../../infra/store/state';
import { ConfirmationOptions } from '../../../models/confirmation-options';
import { PartialView } from '../../../models/partial-view';
import { ViewOptions } from '../../../models/view-options';
import { ModalService } from '../../../services/service.modals';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, DeclarationsApiClient, DeclarationPerformancesApiClient, ModalService, VecozoApiClient)
export class PartialDeclarationsEdit extends BasePartialView {
    public declaration: GetDeclarationResponse;
    public DeclarationSendMethods: typeof DeclarationSendMethods = DeclarationSendMethods;
    public DeclarationStatuses: typeof DeclarationStatuses = DeclarationStatuses;
    public DeclarationEventTypes: typeof DeclarationEventTypes = DeclarationEventTypes;
    public DeclarationPerformanceTypes: typeof DeclarationPerformanceTypes = DeclarationPerformanceTypes;
    public InsuranceTypes: typeof InsuranceTypes = InsuranceTypes;
    public InsuranceStatuses: typeof InsuranceStatuses = InsuranceStatuses;
    public performances: GetDeclarationPerformanceResponse[];

    public isChecking: boolean = false;
    public isSending: boolean = false;
    public isDownloading: boolean = false;

    public viewState: any = {
        insured: false,
        items: [],
        events: []
    };

    private declarationId: string;

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly declarationsApi: DeclarationsApiClient,
        private readonly performancesApi: DeclarationPerformancesApiClient,
        private readonly modalService: ModalService,
        private readonly vecozo: VecozoApiClient
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.declarationId = view.data.id;
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                const [declaration, performances] = await Promise.all([
                    this.declarationsApi.getById(this.declarationId, this.authenticated.workspace.id), //
                    this.performancesApi.search(
                        this.authenticated.workspace.id,
                        500,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        [this.declarationId]
                    )
                ]);

                this.subscriptions = [
                    ...(this.subscriptions ?? []),
                    this.events.subscribe(CustomEvents.DeclarationsUpdated, async (declaration: GetDeclarationResponse) => {
                        if (isDefined(declaration)) {
                            this.declaration = await this.declarationsApi.getById(this.declarationId, this.authenticated.workspace.id);
                            this.setState();
                        }
                    })
                ];

                this.declaration = declaration;
                this.performances = performances?.data ?? [];
                this.setState();
                this.baseLoaded = true;
            })
            .catch((x) => this.errorHandler.handle('PartialDeclarationsEdit.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }

    public close(): void {
        super.remove({
            result: PartialViewResults.Ok,
            updateUrl: true
        });
    }

    public cancel(): void {
        super.remove({
            result: PartialViewResults.Cancelled,
            updateUrl: true
        });
    }

    public async send(): Promise<void> {
        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('translation:partial-views.declarations.questions.send.title'),
                message: this.t
                    .tr('translation:partial-views.declarations.questions.send.message') //
                    .replace('{entity}', `<span>'${this.declaration.conceptNumber}'</span>`),
                type: 'warning',
                btnOk: this.t.tr('translation:global.buttons.send'),
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        try {
                            this.isSending = true;
                            this.declaration = (await this.vecozo.sendDeclaration(this.declarationId, this.authenticated.workspace.id)) as GetDeclarationResponse;
                            this.setState();
                            this.notify();
                            if (this.declaration.status === DeclarationStatuses.SentToVecozo)
                                this.notifications.show(
                                    this.t.tr('translation:partial-views.declarations.notifications.sent-successful.title'),
                                    this.t
                                        .tr('translation:partial-views.declarations.notifications.sent-successful.message') //
                                        .replace('{entity}', `<span>'${this.declaration.conceptNumber}'</span>`),
                                    { type: 'success', duration: 3000 }
                                );
                            setTimeout(() => (this.isSending = false), 200);
                        } catch (e) {
                            this.isSending = false;
                            this.errorHandler.handle('[send-declaration]', e);
                        }
                    }
                }
            })
        );
    }

    public async download(): Promise<void> {
        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('translation:partial-views.declarations.questions.download.title'),
                message: this.t
                    .tr('translation:partial-views.declarations.questions.download.message') //
                    .replace('{entity}', `<span>'${this.declaration.conceptNumber}'</span>`),
                type: 'warning',
                btnOk: this.t.tr('translation:global.buttons.download'),
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        try {
                            this.isDownloading = true;
                            const blob = await this.vecozo.downloadForInfomedics(this.declarationId, this.authenticated.workspace.id);
                            // Give the API some time to process the declaration before fetching it agian.
                            setTimeout(async () => {
                                // It is known that the declaration is fetched twice (notify function), but this is necessary to get the correct status.
                                this.declaration = await this.declarationsApi.getById(this.declarationId, this.authenticated.workspace.id);
                                if (
                                    this.declaration.status !== DeclarationStatuses.Draft &&
                                    this.declaration.status !== DeclarationStatuses.FailedInternalValidation &&
                                    this.declaration.status !== DeclarationStatuses.FailedToDownload
                                )
                                    serveBlob(blob.data, `declaratiebericht-${this.declaration.trackingNumber}.xml`);
                                this.setState();
                                this.notify();
                                if (this.declaration.status === DeclarationStatuses.DownloadedForInfomedics)
                                    this.notifications.show(
                                        this.t.tr('translation:partial-views.declarations.notifications.download-successful.title'),
                                        this.t
                                            .tr('translation:partial-views.declarations.notifications.download-successful.message') //
                                            .replace('{entity}', `<span>'${this.declaration.conceptNumber}'</span>`),
                                        { type: 'success', duration: 3000 }
                                    );
                                setTimeout(() => (this.isDownloading = false), 200);
                            }, 500);
                        } catch (e) {
                            this.isDownloading = false;
                            this.errorHandler.handle('[send-declaration]', e);
                        }
                    }
                }
            })
        );
    }

    public async checkStatus(): Promise<void> {
        this.isChecking = true;
        try {
            this.declaration = (await this.vecozo.checkDeclarationStatus(this.declarationId, this.authenticated.workspace.id)) as GetDeclarationResponse;
            this.setState();
            this.notify();
            setTimeout(() => (this.isChecking = false), 200);
            this.events.publish(CustomEvents.HealthcareInvoicesUpdated);
            // this.notifications.show(
            //     this.t.tr('translation:partial-views.declarations.notifications.status-checked-successful.title'),
            //     this.t.tr('translation:partial-views.declarations.notifications.status-checked-successful.message'),
            //     { type: 'success', duration: 3000 }
            // );
        } catch (e) {
            this.isChecking = false;
            this.errorHandler.handle('[check-declaration-status]', e);
        }
    }

    public async openPerformance(id: string): Promise<void> {
        await this.removeChildViews();
        await this.addPartialView({
            view: this.partial.base, //
            partial: PartialViews.EditDeclarationPerformances.with({ id }), //
            options: new ViewOptions({ scrollToView: true, markItem: false, replace: true, updateUrl: false })
        });
    }

    public async process(): Promise<void> {
        await this.removeChildViews();
        await this.addPartialView({
            view: this.partial.base, //
            partial: PartialViews.ProcessDeclaration.with({ id: this.declarationId }), //
            options: new ViewOptions({ scrollToView: true, markItem: false, replace: true, updateUrl: false })
        });
    }

    public toggleState(type: 'insured' | 'person' | 'performance', personIndex: number, performanceIndex: number): void {
        if (type === 'insured') this.viewState[type] = !this.viewState[type];
        if (type === 'person') this.viewState.items[personIndex].open = !this.viewState.items[personIndex].open;
        if (type === 'performance') this.viewState.items[personIndex].performances[performanceIndex].open = !this.viewState.items[personIndex].performances[performanceIndex].open;
    }

    public toggleEventMessage(type: 'container' | 'message' | 'error' | 'notification', parent: number, index: number): void {
        if (isDefined(index)) {
            for (let eI = 0; eI < this.viewState.events.length; eI++) {
                for (const prop in this.viewState.events[eI]) {
                    if (eI === parent && prop === 'messages') {
                        for (let mI = 0; mI < this.viewState.events[eI].messages.length; mI++) {
                            for (const innerProp in this.viewState.events[eI].messages[mI]) {
                                if (mI === index && innerProp === type) {
                                    this.viewState.events[eI].messages[mI][innerProp] = !this.viewState.events[eI].messages[mI][innerProp];
                                } else this.viewState.events[eI].messages[mI][innerProp] = false;
                            }
                        }
                    } else if (prop !== 'messages') this.viewState.events[eI][prop] = false;
                }
            }
        } else {
            for (let eI = 0; eI < this.viewState.events.length; eI++) {
                for (const prop in this.viewState.events[eI]) {
                    if (prop === 'messages') continue;
                    if (eI === parent && prop === type) this.viewState.events[eI][prop] = !this.viewState.events[eI][prop];
                    else this.viewState.events[eI][prop] = false;
                }
            }
        }
    }

    public getPerformance(id: string): GetDeclarationPerformanceResponse {
        return this.performances.find((p) => p.id === id);
    }

    public async openAttachment(attachment: BlobStorageAttachment): Promise<void> {
        await this.addPartialView({
            view: this.partial.base, //
            partial: PartialViews.DocumentsPreview.with({
                entityId: this.declaration.id,
                entityType: AttachmentEntities.Declarations,
                attachmentId: attachment.id,
                attachmentName: attachment.name,
                attachmentExtension: attachment.extension
            }),
            options: new ViewOptions({ index: this.partial.index + 1, markItem: true, scrollToView: true, updateUrl: false })
        });
    }

    private setState(): void {
        this.viewState.items = this.declaration.insured.map((person) => ({
            open: false,
            performances: person.performances.map((_) => ({
                open: false
            }))
        }));

        this.viewState.events = this.declaration.events.map((event) => ({
            message: false,
            payload: false,
            error: false,
            container: false,
            notification: false,
            messages: event.messages.map((message) => ({
                message: false,
                payload: false,
                error: false,
                container: false,
                notification: false
            }))
        }));
    }

    private notify(): void {
        this.events.publish(CustomEvents.DeclarationsUpdated, { declaration: this.declaration });
    }
}
