<ux-combobox
    init="false"
    component.ref="combobox"
    valid.bind="valid"
    value.two-way="value"
    search-placeholder.bind="searchPlaceholder"
    placeholder.bind="placeholder"
    allow-clear.bind="allowClear && !disabled"
    disabled.bind="disabled"
    data.bind="data"
    show-footer.bind="allowNewPatient"
    onselect.trigger="handleSelect($event)"
    onclear.trigger="handleClear($event)"
    onsearch.trigger="handleSearch($event)">
    <ux-combobox-option repeat.for="patient of patients" value.bind="patient.id">${ patient.displayName }</ux-combobox-option>
    <div if.bind="allowNewPatient" au-slot="footer" class="p-1">
        <a href="javascript:" click.trigger="handleNewPatient()" class="flex items-center w-full px-3 py-2 rounded-lg gap-x-1 hover:bg-gray-50">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4 text-primary">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
            </svg>
            <p class="text-xs text-gray-500" t="translation:bx.patient-selector.labels.new-patient"></p>
        </a>
    </div>
</ux-combobox>
