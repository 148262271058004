import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { AccountsApiClient, CreateAccountRequest, Email, Link, Phone } from '@wecore/sdk-crm';
import { isDefined, isEmpty, isNotDefined, isNotEmpty, isValid, resetValidation, validateState } from '@wecore/sdk-utilities';
import { IEventAggregator, inject } from 'aurelia';
import { PartialViewResults } from '../../../enums/partial-view-results';
import { BasePartialView } from '../../../infra/base-partial-view';
import { CacheService } from '../../../infra/cache-service';
import { ErrorHandler } from '../../../infra/error-handler';
import { State } from '../../../infra/store/state';
import { PartialView } from '../../../models/partial-view';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, AccountsApiClient)
export class PartialAccountsCreate extends BasePartialView {
    public tabs: any;
    public lockType: boolean;
    public account: CreateAccountRequest = new CreateAccountRequest({
        emails: [],
        links: [],
        phones: [],
        addresses: [],
        customFields: []
    });
    public validation: any = {
        displayName: true,
        emails: [],
        phones: [],
        links: []
    };

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly accountsApi: AccountsApiClient
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.tabs = {
            general: {
                name: this.t.tr('translation:partial-views.accounts.labels.general'),
                valid: true,
                active: true
            },
            addresses: {
                name: this.t.tr('translation:partial-views.accounts.labels.addresses'),
                valid: true,
                active: false
            },
            connections: {
                name: this.t.tr('translation:global.labels.connections'),
                active: false,
                valid: true
            }
        };
    }

    public attached(): void {
        super
            .initView()
            .then(() => {
                this.baseLoaded = true;
            })
            .catch((x) => this.errorHandler.handle('PartialAccountsCreate.attached', x));
    }

    public async cancel(): Promise<void> {
        await super.remove({
            result: PartialViewResults.Cancelled,
            updateUrl: true
        });
    }

    public setActiveTab(key: string): void {
        for (const prop in this.tabs) this.tabs[prop].active = false;
        this.tabs[key].active = true;
    }

    public async save(): Promise<void> {
        const valid = this.validate();
        if (valid) {
            this.isLoading = true;

            this.account.displayName?.trim();

            // Clean values that are not valid.
            this.account.emails = this.account.emails.filter((email: Email) => {
                const cleaned = email.value?.replace(/_/g, '');
                return isDefined(cleaned) && isNotEmpty(cleaned);
            });

            this.account.phones = this.account.phones.filter((phone: Phone) => {
                const cleaned = phone.value?.replace(/_/g, '');
                return isDefined(cleaned) && isNotEmpty(cleaned);
            });

            this.account.links = this.account.links.filter((link: Link) => {
                return isDefined(link.value) && isNotEmpty(link.value);
            });

            try {
                const account = await this.accountsApi.create(this.authenticated.workspace.id, this.account);
                this.remove({ result: PartialViewResults.Ok, data: account, updateUrl: true });
            } catch (e) {
                this.isLoading = false;
                await this.errorHandler.handle('[accounts/create]', e);
            }
        }
    }

    private validate(): boolean {
        this.validation.displayName = isNotEmpty(this.account.displayName);

        for (let index = 0; index < this.account.emails.length; index++) {
            // Reset the validation.
            resetValidation(this.validation.emails[index]);

            const email = this.account.emails[index].value;
            // Only validate if user entered something.
            if (isNotDefined(email) || isEmpty(email)) continue;
            // Validate the phone number
            this.validation.emails[index].email =
                isNotEmpty(email) &&
                isValid(email.toLowerCase(), /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])+/);
            this.validation.emails[index].valid = this.validation.emails[index].email;
        }

        for (let index = 0; index < this.account.phones.length; index++) {
            // Reset the validation.
            resetValidation(this.validation.phones[index]);
            // Remove mask placeholder characters.
            const phone = this.account.phones[index].value?.replace(/_/g, '') || undefined;
            // Only validate if user entered something.
            if (isNotDefined(phone) || isEmpty(phone)) continue;
            // Validate the phone number
            this.validation.phones[index].phone = phone.length === 9;
            this.validation.phones[index].valid = this.validation.phones[index].phone;
        }

        this.tabs.general.valid =
            validateState(this.validation) && //
            this.validation.emails.every((e: any) => e.valid) &&
            this.validation.phones.every((e: any) => e.valid);

        return this.tabs.general.valid;
    }
}
