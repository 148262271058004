<import from="converters/translate"></import>

<div show.bind="!pageState.values.days[day].visible" class="w-[50px] min-w-[50px]"></div>
<!-- 
    The calculation for the height goes as follows:
     1. If the max amount of tasks is 0 or 1, we set the height to 43px.
     2. If the max amount of tasks is greater than 1, we set the height to the standard 43px (which accounts for 1 task and/or placeholder)
        and then add the remaining amount (maxAmountOfTasks - 1 because we already accounted for 1 task/placeholder) of tasks multiplied 
        by 30px (the height of each task) to the total height. This way, the column will always be at least 43px tall, 
        but will grow in height as more tasks are added.
-->

<div
    show.bind="pageState.values.days[day].visible || pageState.values.view === 'day'"
    css="min-width: ${pageState.values.columnWidth}px; width: ${pageState.values.columnWidth}px; min-height: ${maxAmountOfTasks < 1 ? 43 : 43 + ((maxAmountOfTasks - 1) * 30)}px;"
    class="flex flex-col w-full gap-1 px-2 py-2 border-r border-gray-200">
    <template if.bind="baseLoaded">
        <div
            repeat.for="task of myTasks"
            click.trigger="handleTaskSelect(task, $index, true)"
            class="flex flex-col min-w-0 px-3 py-1 bg-white border border-l-8 border-gray-300 rounded-md border-l-primary hover:cursor-pointer focus:outline-none">
            <p class="text-xs truncate">${task.title | translate:language}</p>
            <!-- <p class="text-xs text-gray-500 truncate" t="translation:global.labels.personal"></p> -->
        </div>
        <div
            css="border-left-color: ${task.list.data.listColor}"
            repeat.for="task of tasks"
            click.trigger="handleTaskSelect(task, $index,false)"
            class="flex flex-col min-w-0 px-3 py-1 bg-white border border-l-8 border-gray-300 rounded-md hover:cursor-pointer focus:outline-none">
            <p class="text-xs truncate">${task.title | translate:language}</p>
            <!-- <p class="text-xs text-gray-500 truncate">${task.list.translations | translate:language}</p> -->
        </div>
        <div if.bind="myTasks.length === 0 && tasks.length === 0" class="flex items-center justify-center w-full px-2 py-1 border border-gray-300 border-dashed rounded-md">
            <p class="text-xs text-gray-600 lowercase" t="translation:bx.scheduler.labels.no-tasks"></p>
        </div>
    </template>
    <template else>
        <div class="flex py-2">
            <ux-skeleton show-background="true" type="container">
                <ux-skeleton type="line"></ux-skeleton>
            </ux-skeleton>
        </div>
    </template>
</div>
