import { I18N } from '@aurelia/i18n';
import { IRouter } from '@aurelia/router';
import { Store } from '@aurelia/store-v1';
import { SortDirection } from '@wecore/sdk-crm';
import { GetPatientResponse, PatientsApiClient } from '@wecore/sdk-healthcare';
import { isDefined, isNotDefined, isNotEmpty } from '@wecore/sdk-utilities';

import { JamesSoftwareApiClient } from '@wecore/sdk-integrations';
import { IEventAggregator, inject } from 'aurelia';
import { PartialViewResults } from '../../enums/partial-view-results';
import { BasePartialView } from '../../infra/base-partial-view';
import { CacheService } from '../../infra/cache-service';
import { ErrorHandler } from '../../infra/error-handler';
import { PartialViews } from '../../infra/partial-views';
import { State } from '../../infra/store/state';
import { ConfirmationOptions } from '../../models/confirmation-options';
import { EventDetails } from '../../models/event-details';
import { PartialView } from '../../models/partial-view';
import { ViewOptions } from '../../models/view-options';
import { ModalService } from '../../services/service.modals';
import { UxInput } from '../../ux/ux-input/ux-input';
import IMask from 'imask/holder';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, PatientsApiClient, ModalService, IRouter, JamesSoftwareApiClient)
export class PartialPatients extends BasePartialView {
    public patients: GetPatientResponse[] = [];
    public total: number = 0;
    public sorting: any = {};
    public SortDirection: typeof SortDirection = SortDirection;
    public noResults: boolean = false;
    public initialized: boolean = false;
    public filters: { name: string; value: string }[] = [];
    public filterInputs: HTMLInputElement[] = [];
    public fetching: boolean = false;
    public searchInput: UxInput;

    private pageSize: number = 100;
    private triggerEventOn: number = 100;
    private endOfList: boolean = false;
    private skip: number = 0;
    private query: string;
    private searchTimeOut: NodeJS.Timeout;
    private blockScroll: boolean = false;
    private patientId: string;

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly patientsApi: PatientsApiClient,
        private readonly modalService: ModalService,
        private readonly router: IRouter,
        private readonly jsApi: JamesSoftwareApiClient
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.patientId = view.data.id;
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                await Promise.all([
                    this.fetch(true) //
                ]);

                this.loadViewsFromUrl({
                    open: async (view: string, entityId: string) => {
                        if (isNotDefined(view)) return;

                        if (view.includes('Edit') && isNotEmpty(entityId)) this.edit(new GetPatientResponse({ id: entityId }), false);
                        else if (view.includes('Create')) this.create();
                        else if (view.includes('PatientCard')) this.openCard(new GetPatientResponse({ id: entityId }), false);
                        else if (isNotEmpty(entityId)) await this.changeTo(PartialViews[view].with({ id: entityId }), false);
                        else await this.changeTo(PartialViews[view], false);
                    }
                });

                if (isDefined(this.patientId)) {
                    setTimeout(() => this.removeQueryParameter('id'));
                    this.openCard(new GetPatientResponse({ id: this.patientId }));
                }

                this.baseLoaded = true;
                setTimeout(() => {
                    if (isDefined(this.scrollContainer)) this.scrollContainer.addEventListener('scroll', (e) => this.handleScroll(e));
                });
            })
            .catch((x) => this.errorHandler.handle('PartialPatients.attached', x));
    }

    public async openCard(patient: GetPatientResponse, updateUrl: boolean = true): Promise<void> {
        await this.removeChildViews();
        await this.addPartialView({
            view: this.partial.base, //
            partial: PartialViews.PatientCard.with({ id: patient.id, closeable: true })
                .whenClosed(async (result: PartialViewResults, _: any) => {
                    if (result === PartialViewResults.Deleted) this.fetch(true);
                })
                .setRoute(':id/card'),
            options: new ViewOptions({ scrollToView: true, markItem: false, replace: true, updateUrl })
        });
    }

    public async details(patient: GetPatientResponse): Promise<void> {
        this.router.load(`${this.authenticated.workspace.id}/healthcare/patients/${patient.id}/details`);
    }

    public async create(updateUrl: boolean = true): Promise<void> {
        await this.removeChildViews();
        await this.addPartialView({
            view: this.partial.base, //
            partial: PartialViews.CreatePatient.whenClosed(async (result: PartialViewResults, _: any) => {
                if (result === PartialViewResults.Ok) this.fetch(true);
            }),
            options: new ViewOptions({ scrollToView: true, markItem: false, replace: true, updateUrl })
        });
    }

    public async edit(patient: GetPatientResponse, updateUrl: boolean = true): Promise<void> {
        await this.removeChildViews();
        await this.addPartialView({
            view: this.partial.base, //
            partial: PartialViews.EditPatient.with({ id: patient.id }).whenClosed(async (result: PartialViewResults, _: any) => {
                if (result === PartialViewResults.Ok || result === PartialViewResults.Deleted) this.fetch(true);
            }),
            options: new ViewOptions({ scrollToView: true, markItem: false, replace: true, updateUrl })
        });
    }

    public async fetch(reset: boolean = false, timeout: number = 0): Promise<void> {
        this.fetching = true;
        if (reset) this.blockScroll = true;

        clearTimeout(this.searchTimeOut);
        this.searchTimeOut = setTimeout(async () => {
            this.noResults = false;
            if (reset) {
                this.skip = 0;
                this.patients = [];
                this.endOfList = false;
                this.fetching = false;
                if (isDefined(this.scrollContainer)) this.scrollContainer.scrollTop = 0;
            }

            // Get all the filters grouped.
            const emails = this.filters.filter((x) => x.name === 'email' && isNotEmpty(x.value)).map((x) => x.value);
            const phones = this.filters.filter((x) => x.name === 'phone' && isNotEmpty(x.value)).map((x) => x.value.replaceAll('_', ''));
            const streets = this.filters.filter((x) => x.name === 'street' && isNotEmpty(x.value)).map((x) => x.value);
            const houseNumbers = this.filters.filter((x) => x.name === 'houseNumber' && isNotEmpty(x.value)).map((x) => x.value);
            const zipCodes = this.filters.filter((x) => x.name === 'zipCode' && isNotEmpty(x.value)).map((x) => x.value.replaceAll('_', ''));
            const cities = this.filters.filter((x) => x.name === 'city' && isNotEmpty(x.value)).map((x) => x.value);

            const response = await this.patientsApi.search(
                this.authenticated.workspace.id, //
                isNotEmpty(this.query) ? this.query : undefined,
                this.pageSize,
                this.skip,
                this.sorting.direction,
                this.sorting.field,
                undefined,
                undefined,
                emails.length > 0 ? emails : undefined,
                phones.length > 0 ? phones : undefined,
                streets.length > 0 ? streets : undefined,
                houseNumbers.length > 0 ? houseNumbers : undefined,
                zipCodes.length > 0 ? zipCodes : undefined,
                cities.length > 0 ? cities : undefined
            );

            this.total = response.total;
            if (!reset && response.data.empty() && !this.initialized) {
                this.endOfList = true;
                this.fetching = false;
                return;
            }

            this.skip += Number(this.pageSize);

            this.patients = reset ? response.data : [...this.patients, ...response.data];
            this.initialized = true;
            this.fetching = false;

            // Block the initial scroll event to prevent the result being added twice
            // when we're resetting the dataset.
            if (reset) setTimeout(() => (this.blockScroll = false), 500);
        }, timeout);
    }

    public async handleSearch(event: CustomEvent<EventDetails<UxInput, any>>): Promise<void> {
        const element = event.detail.element;
        element.isLoading = true;

        this.query = event.detail.values?.value;
        await this.fetch(true, 500);

        element.isLoading = false;
    }

    public async sort(field: 'LastName'): Promise<void> {
        if (this.sorting.field === field && isNotDefined(this.sorting.direction)) this.sorting.direction = SortDirection.Asc;
        else if (this.sorting.field === field && this.sorting.direction === SortDirection.Asc) this.sorting.direction = SortDirection.Desc;
        else if (this.sorting.field === field && this.sorting.direction === SortDirection.Desc) this.sorting.direction = undefined;
        else {
            this.sorting.field = field;
            this.sorting.direction = SortDirection.Asc;
        }
        await this.fetch(true);
    }

    public async delete(patient: GetPatientResponse): Promise<void> {
        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('translation:partial-views.patients.questions.delete.title'),
                message: this.t
                    .tr('translation:partial-views.patients.questions.delete.message') //
                    .replace('{entity}', `<span>'${patient.displayName}'</span>`),
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        this.deleting = true;
                        try {
                            await this.patientsApi.delete(patient.id, this.authenticated.workspace.id);
                            this.notifications.show(
                                this.t.tr('translation:partial-views.patients.notifications.deleted-successfully.title'),
                                this.t
                                    .tr('translation:partial-views.patients.notifications.deleted-successfully.message') //
                                    .replace('{entity}', `<span>'${patient.displayName}'</span>`),
                                { type: 'success', duration: 3000 }
                            );
                            await this.fetch(true);
                        } catch (e) {
                            this.deleting = false;
                            await this.errorHandler.handle('[patients-list/delete]', e);
                        }
                    }
                }
            })
        );
    }

    public addFilter(filter: 'email' | 'phone' | 'street' | 'zipCode' | 'city'): void {
        this.filters.push({ name: filter, value: null });

        if (filter === 'zipCode') {
            setTimeout(() => {
                IMask(this.filterInputs[this.filters.length - 1], {
                    mask: '0000 aa',
                    placeholderChar: '_',
                    lazy: false,
                    overwrite: true,
                    autofix: true
                });

                this.filterInputs[this.filters.length - 1].style.width = 'auto';
                this.filterInputs[this.filters.length - 1].style.width = `${this.filterInputs[this.filters.length - 1].scrollWidth}px`;
            });
        }

        if (filter === 'phone') {
            setTimeout(() => {
                IMask(this.filterInputs[this.filters.length - 1], {
                    mask: '+31000000000',
                    placeholderChar: '_',
                    lazy: false,
                    overwrite: true,
                    autofix: true
                });

                this.filterInputs[this.filters.length - 1].style.width = 'auto';
                this.filterInputs[this.filters.length - 1].style.width = `${this.filterInputs[this.filters.length - 1].scrollWidth}px`;
            });
        }

        setTimeout(() => this.filterInputs[this.filters.length - 1].focus());
    }

    public removeFilter(index: number): void {
        this.filters.splice(index, 1);
        this.fetch(true, 50);
    }

    public handleFilterValueChanged(e: KeyboardEvent): void {
        const input = e.target as HTMLInputElement;

        setTimeout(() => this.fetch(true, 500), 0);

        input.style.width = 'auto';
        input.style.width = `${input.scrollWidth}px`;
    }

    public async sync(): Promise<void> {
        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('translation:partial-views.patients.questions.sync.title'),
                message: this.t.tr('translation:partial-views.patients.questions.sync.message'),
                type: 'warning',
                btnOk: this.t.tr('translation:global.buttons.sync'),
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) this.jsApi.syncAll(this.authenticated.workspace.id);
                }
            })
        );
    }

    private async handleScroll(event: Event): Promise<void> {
        const target = event.target as HTMLElement;

        const isCloseToBottom = target.scrollTop + target.clientHeight >= target.scrollHeight - Number(this.triggerEventOn);
        if (isCloseToBottom && !this.endOfList && !this.isLoading && !this.fetching && !this.blockScroll) {
            this.fetch();
        }
    }
}
