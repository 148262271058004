<import from="converters/translate" />
<!-- <import from="./template-therapy-category" />-->
<import from="../template-therapy-step/template-therapy-step" />

<div id.bind="item.id" ref="container" class="${columns} flex flex-col">
    <div id="therapy-execution-category-grid-${item.copiedFrom}-${registration.id}" ref="grid" class="flex flex-col mt-1" show.bind="shouldBeDisplayed">
        <a
            href="javascript:"
            class.bind="validation.valid ? 'bg-gray-100 text-gray-800' : 'bg-red-100 text-red-600'"
            click.trigger="collapseOrExpand()"
            class="flex items-center p-2 font-medium rounded-lg gap-x-1">
            <template if.bind="states[item.id].expanded">
                <svg class="h-[1.2rem] w-[1.2rem] text-indigo-500" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M9.25 19.25V16.75C9.25 15.6454 8.35457 14.75 7.25 14.75H4.75"></path>
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M14.75 19.25V16.75C14.75 15.6454 15.6454 14.75 16.75 14.75H19.25"></path>
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M14.75 4.75V7.25C14.75 8.35457 15.6454 9.25 16.75 9.25H19.25"></path>
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M9.25 4.75V7.25C9.25 8.35457 8.35457 9.25 7.25 9.25H4.75"></path>
                </svg>
            </template>
            <template else>
                <svg class="h-[1.2rem] w-[1.2rem] text-indigo-500" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M4.75 14.75V17.25C4.75 18.3546 5.64543 19.25 6.75 19.25H9.25"></path>
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M19.25 14.75V17.25C19.25 18.3546 18.3546 19.25 17.25 19.25H14.75"></path>
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M19.25 9.25V6.75C19.25 5.64543 18.3546 4.75 17.25 4.75H14.75"></path>
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M4.75 9.25V6.75C4.75 5.64543 5.64543 4.75 6.75 4.75H9.25"></path>
                </svg>
            </template>
            <div class="flex items-center flex-1 min-w-0 pr-2 text-sm gap-x-3">
                <!-- <span class="text-gray-500" t="translation:global.labels.category"></span> -->
                <span class="flex-1 block truncate">${ item.category.name | translate:language }</span>
                <div if.bind="states[item.id].answered || false" class="w-2 h-2 bg-green-500 rounded-sm"></div>
            </div>
        </a>
        <div if.bind="states[item.id].expanded" class="grid flex-col grid-cols-12 pl-2 ml-1 border-l-2 border-gray-300 auto-cols-fr gap-x-1">
            <template repeat.for="stepToTake of item.category.stepsToTake">
                <template if.bind="stepToTake.type === MedicalTherapyExecutionItemTypes.Category">
                    <template-therapy-category
                        flattened.bind="flattened"
                        record.bind="record"
                        registration.bind="registration"
                        registrations.bind="registrations"
                        item.bind="stepToTake"
                        validation.bind="validation.steps[$index]"
                        workspace.bind="workspace"
                        category.bind="item"
                        states.bind="states"
                        component.ref="states[stepToTake.id].model"
                        flow.bind="flow"
                        on-file-selected.bind="onFileSelected"
                        on-file-removed.bind="onFileRemoved"
                        x-scroll-container.bind="xScrollContainer"
                        loading.bind="loading"
                        language.bind="language">
                    </template-therapy-category>
                </template>
                <template if.bind="stepToTake.type === MedicalTherapyExecutionItemTypes.Step">
                    <template-therapy-step
                        flattened.bind="flattened"
                        record.bind="record"
                        registration.bind="registration"
                        registrations.bind="registrations"
                        item.bind="stepToTake"
                        validation.bind="validation.steps[$index]"
                        workspace.bind="workspace"
                        category.bind="item"
                        states.bind="states"
                        component.ref="states[stepToTake.id].model"
                        flow.bind="flow"
                        on-file-selected.bind="onFileSelected"
                        on-file-removed.bind="onFileRemoved"
                        x-scroll-container.bind="xScrollContainer"
                        loading.bind="loading"
                        language.bind="language">
                    </template-therapy-step>
                </template>
            </template>
        </div>
    </div>
</div>
