<import from="converters/translate" />
<import from="./template-action-category/template-action-category" />
<import from="./template-action-step/template-action-step" />

<div class="${columns} flex flex-col" if.bind="registration.action && shouldBeDisplayed">
    <div
        class="${ showOptions ? 'py-1 px-2' : 'py-2 pr-4'} flex items-center justify-between gap-0.5 rounded-lg pl-2"
        class.bind="validation.valid ? 'bg-gray-100 text-gray-800' : 'bg-red-100 text-red-600'">
        <a href="javascript:" click.trigger="collapseOrExpand()" class="flex items-center flex-1 font-medium rounded-lg gap-x-1">
            <template if.bind="states[step.id].expanded">
                <svg class="h-[1.2rem] w-[1.2rem] text-indigo-500" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M9.25 19.25V16.75C9.25 15.6454 8.35457 14.75 7.25 14.75H4.75"></path>
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M14.75 19.25V16.75C14.75 15.6454 15.6454 14.75 16.75 14.75H19.25"></path>
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M14.75 4.75V7.25C14.75 8.35457 15.6454 9.25 16.75 9.25H19.25"></path>
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M9.25 4.75V7.25C9.25 8.35457 8.35457 9.25 7.25 9.25H4.75"></path>
                </svg>
            </template>
            <template else>
                <svg class="h-[1.2rem] w-[1.2rem] text-indigo-500" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M4.75 14.75V17.25C4.75 18.3546 5.64543 19.25 6.75 19.25H9.25"></path>
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M19.25 14.75V17.25C19.25 18.3546 18.3546 19.25 17.25 19.25H14.75"></path>
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M19.25 9.25V6.75C19.25 5.64543 18.3546 4.75 17.25 4.75H14.75"></path>
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M4.75 9.25V6.75C4.75 5.64543 5.64543 4.75 6.75 4.75H9.25"></path>
                </svg>
            </template>
            <div class="flex items-center min-w-0 gap-1 text-sm">
                <!-- <span class="text-gray-500" t="translation:global.labels.action"></span> -->
                <span class="block truncate">${ registration.action.name | translate:language }</span>
                <span if.bind="required" class="flex items-center h-3 text-red-500">*</span>
            </div>
        </a>
        <div class="flex items-center gap-4">
            <ux-input
                if.bind="editLabelMode"
                autofocus="true"
                focus-delay="400"
                onkeydown.trigger="handleInputChanged($event)"
                action.bind="c => manageTranslationsFor()"
                value.two-way="step.label[language]"
                t="[placeholder]translation:global.placeholders.add-label">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M10.5 21l5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 016-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 01-3.827-5.802" />
                </svg>
            </ux-input>
            <p class="text-sm text-indigo-500" if.bind="!editLabelMode">${ step.label[language] || '' }</p>
            <div class="flex items-center gap-1">
                <button if.bind="recent && recent.informationSheet" click.trigger="openInformationSheet()" class="flex items-center w-5 h-5 text-blue-500 rounded-full">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                    </svg>
                </button>
                <div if.bind="isAnswered" class="w-2 h-2 bg-green-500 rounded-sm"></div>
                <ux-dropdown if.bind="showOptions" transparent.bind="true" border.bind="false" type="full" color="dark" size="sm" padding.bind="false" mode="outline" placement="bottom-right">
                    <div au-slot="label" class="p-1 text-gray-600 rounded-full ring-gray-400 focus:outline-none focus:ring-1">
                        <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512">
                            <path
                                fill="currentColor"
                                d="M64 368C90.51 368 112 389.5 112 416C112 442.5 90.51 464 64 464C37.49 464 16 442.5 16 416C16 389.5 37.49 368 64 368zM64 208C90.51 208 112 229.5 112 256C112 282.5 90.51 304 64 304C37.49 304 16 282.5 16 256C16 229.5 37.49 208 64 208zM64 144C37.49 144 16 122.5 16 96C16 69.49 37.49 48 64 48C90.51 48 112 69.49 112 96C112 122.5 90.51 144 64 144z" />
                        </svg>
                    </div>
                    <ux-dropdown-option click.trigger="addLabel()">
                        <span if.bind="protocol.label[language]" t="translation:global.buttons.change-label"></span>
                        <span else t="translation:global.buttons.add-label"></span>
                    </ux-dropdown-option>
                    <ux-dropdown-option click.trigger="duplicateAction()">
                        <span t="translation:global.buttons.duplicate"></span>
                    </ux-dropdown-option>
                    <ux-dropdown-divider if.bind="step.isDynamicallyAdded && step.manuallyAdded || step.copiedFrom"></ux-dropdown-divider>
                    <ux-dropdown-option if.bind="step.isDynamicallyAdded && step.manuallyAdded || step.copiedFrom" click.trigger="deleteStep()">
                        <span class="text-red-500" t="translation:global.buttons.delete"></span>
                    </ux-dropdown-option>
                </ux-dropdown>
            </div>
        </div>
    </div>
    <template if.bind="states[step.id].expanded">
        <div class="pl-2 ml-1 border-l-2 border-gray-300">
            <div class="grid grid-cols-12 auto-cols-fr gap-x-1">
                <template repeat.for="stepToTake of registration.action.stepsToTake">
                    <template if.bind="stepToTake.type === MedicalExaminationActionItemTypes.Category">
                        <template-action-category
                            component.ref="states[stepToTake.id].model"
                            flattened.bind="flattened"
                            record.bind="record"
                            step.bind="step"
                            states.bind="states"
                            level.bind="level + 1"
                            registrations.bind="registrations"
                            registration.bind="registration"
                            validation.bind="validation.steps[$index]"
                            x-scroll-container.bind="xScrollContainer"
                            on-file-selected.bind="onFileSelected"
                            on-file-removed.bind="onFileRemoved"
                            is-evaluation-step.bind="isEvaluationStep"
                            loading.bind="loading"
                            flow.bind="registration.action.flow"
                            item.bind="stepToTake"
                            workspace.bind="workspace"
                            language.bind="language">
                        </template-action-category>
                    </template>
                    <template if.bind="stepToTake.type === MedicalExaminationActionItemTypes.Step">
                        <template-action-step
                            component.ref="states[stepToTake.id].model"
                            flattened.bind="flattened"
                            record.bind="record"
                            step.bind="step"
                            states.bind="states"
                            registrations.bind="registrations"
                            registration.bind="registration"
                            validation.bind="validation.steps[$index]"
                            x-scroll-container.bind="xScrollContainer"
                            on-file-selected.bind="onFileSelected"
                            on-file-removed.bind="onFileRemoved"
                            is-evaluation-step.bind="isEvaluationStep"
                            loading.bind="loading"
                            flow.bind="registration.action.flow"
                            item.bind="stepToTake"
                            workspace.bind="workspace"
                            language.bind="language">
                        </template-action-step>
                    </template>
                </template>
            </div>
        </div>
    </template>
</div>
<template if.bind="!registration.action">
    <div class="${columns} flex items-center justify-between rounded-lg bg-red-100 px-3 py-2 text-sm font-medium text-red-600">
        <div class="flex items-center flex-1 min-w-0 gap-x-3">
            <span class="text-gray-500" t="translation:global.labels.action"></span>
            <div class="flex justify-between flex-1 min-w-0">
                <span class="text-gray-800">${ step.action.translations | translate:language }</span>
                <div class="flex min-w-0 gap-x-3">
                    <span class="block truncate" t="translation:global.messages.deleted-action"></span>
                    <button
                        click.trigger="remove(step.id)"
                        class="flex items-center justify-center w-5 h-5 text-red-500 rounded-full ring-red-500 ring-offset-1 hover:text-red-600 focus:outline-none focus:ring-1 active:text-red-700">
                        <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
