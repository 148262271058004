<import from="converters/translate" />
<import from="converters/date" />
<import from="converters/duration" />
<import from="converters/time-ago" />

<template class="!flex overflow-hidden border-r-1 border-gray-200 bg-white" css.bind="styles">
    <section if.bind="partial" id="${partial.name}-${partial.id}" class="flex overflow-hidden gap-x-2" css.bind="styles">
        <div class="flex flex-col flex-1 w-full h-full overflow-hidden">
            <div class="flex flex-col flex-1 px-8 overflow-y-auto">
                <template if.bind="baseLoaded">
                    <div class="sticky top-0 z-50 flex flex-col gap-3 pt-5 pb-3 bg-white border-b border-gray-200">
                        <div class="flex justify-between">
                            <p class="font-medium text-gray-400" t="translation:partial-views.scheduler-items.details.title"></p>
                        </div>
                        <div class="relative flex flex-col h-full rounded-md min-h-44">
                            <div if.bind="schedulerItem.type === SchedulerItemTypes.Block" class="relative h-full rounded-md">
                                <div class="absolute flex w-full h-full bg-red-100 rounded-md"></div>
                                <div
                                    class="absolute z-[20] flex h-full w-full rounded-md"
                                    style="opacity: 0.14; background: repeating-linear-gradient(-45deg, #b22222, #b22222 5px, transparent 5px, transparent 15px)"></div>
                            </div>
                            <div
                                if.bind="schedulerItem.appointmentTypes.length === 0 && schedulerItem.type === SchedulerItemTypes.Appointment"
                                class="flex w-full h-full rounded-md"
                                style="background-color: #e5e7eb; color: '#1f2937'"></div>
                            <div if.bind="schedulerItem.appointmentTypes.length > 0 && schedulerItem.type === SchedulerItemTypes.Appointment" class="absolute z-10 flex w-full h-full">
                                <div
                                    repeat.for="type of schedulerItem.appointmentTypes"
                                    rounded-l-md.class="$index === 0"
                                    rounded-r-md.class="$index === schedulerItem.appointmentTypes.length - 1"
                                    justify-center.class="schedulerItem.appointmentTypes.length > 1"
                                    justify-start.class="schedulerItem.appointmentTypes.length === 1"
                                    class="flex items-end flex-1 w-full h-full min-w-0 p-5"
                                    css="background-color: ${type.color.background}; color: ${type.color.text}">
                                    <p class="text-xs truncate">${type.name | translate:language}</p>
                                </div>
                            </div>
                            <div class="absolute z-50 flex flex-col flex-1 p-5">
                                <div if.bind="schedulerItem.type === SchedulerItemTypes.Appointment" class="flex gap-1 text-sm font-semibold">
                                    <span>#${schedulerItem.patients[0]?.data.number ?? '12345'}</span>
                                    <span>${schedulerItem.patients[0]?.name ?? 'John Doe'}</span>
                                </div>
                                <p if.bind="schedulerItem.type === SchedulerItemTypes.Block" class="flex gap-1 text-sm font-semibold" t="translation:global.labels.block"></p>
                                <div class="flex gap-1 text-sm">
                                    <span if.bind="schedulerItem.practitioner">${schedulerItem.practitioner.name}</span>
                                    <div class="flex">
                                        <span if.bind="schedulerItem.practitioner && schedulerItem.location">(</span>
                                        <div if.bind="schedulerItem.location" class="flex gap-1 text-sm">
                                            <span if.bind="schedulerItem.location.applicationName">${schedulerItem.location.applicationName | translate:language}</span>
                                            <span else>${schedulerItem.location.name | translate:language}</span>
                                            -
                                            <span>${schedulerItem.examinationRoom.name | translate:language}</span>
                                        </div>
                                        <span if.bind="schedulerItem.practitioner && schedulerItem.location">)</span>
                                    </div>
                                </div>
                                <hr class="my-2 border-gray-700 opacity-10">
                                <div class="flex gap-1 text-sm capitalize">
                                    <span>${schedulerItem.start | date:'EEEE dd MMMM yyyy' }</span>
                                </div>
                                <div class="flex gap-1 text-sm">
                                    <span>${schedulerItem.start | date:'HH:mm' } - ${schedulerItem.end | date:'HH:mm' } (${schedulerItem.start | duration:schedulerItem.end })</span>
                                </div>
                                <p if.bind="schedulerItem.type === SchedulerItemTypes.Block && schedulerItem.comments" class="p-2 mt-3 text-sm text-gray-800 bg-red-100 rounded-md">
                                    ${schedulerItem.comments}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="flex flex-col pb-5 mt-5">
                        <ux-label class="mb-3">
                            <span t="translation:global.labels.timeline"></span>
                        </ux-label>
                        <ul role="list" class="space-y-6">
                            <li repeat.for="activity of activities" class="relative flex gap-x-2">
                                <template if.bind="activity.name === 'CreateSchedulerItem'">
                                    <div -bottom-6.class="!$last" class="absolute top-0 left-0 flex justify-center w-6">
                                        <div class="w-px bg-gray-200"></div>
                                    </div>
                                    <div class="relative flex items-center justify-center flex-none w-6 h-6 bg-white">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 text-green-500">
                                            <path
                                                fill-rule="evenodd"
                                                d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                                                clip-rule="evenodd" />
                                        </svg>
                                    </div>
                                    <p class="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                                        <span class="font-medium text-gray-900">${activity.createdBy.name}</span>
                                        <span if.bind="activity.body.type === SchedulerItemTypes.Appointment" t="translation:partial-views.scheduler-items.activities.created-appointment"></span>
                                        <span if.bind="activity.body.type === SchedulerItemTypes.Block" t="translation:partial-views.scheduler-items.activities.created-block"></span>
                                    </p>
                                </template>
                                <template if.bind="activity.name === 'UpdateSchedulerItem'">
                                    <div -bottom-6.class="!$last" class="absolute top-0 left-0 flex justify-center w-6">
                                        <div class="w-px bg-gray-200"></div>
                                    </div>
                                    <div class="relative flex items-center justify-center flex-none w-6 h-6 bg-white">
                                        <div class="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300"></div>
                                    </div>
                                    <p class="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                                        <span class="font-medium text-gray-900">${activity.createdBy.name}</span>
                                        <span if.bind="activity.body.type === SchedulerItemTypes.Appointment" t="translation:partial-views.scheduler-items.activities.modified-appointment"></span>
                                        <span if.bind="activity.body.type === SchedulerItemTypes.Block" t="translation:partial-views.scheduler-items.activities.modified-block"></span>
                                    </p>
                                </template>
                                <template if.bind="activity.name === 'MarkSchedulerItemAsNoShow'">
                                    <div -bottom-6.class="!$last" class="absolute top-0 left-0 flex justify-center w-6">
                                        <div class="w-px bg-gray-200"></div>
                                    </div>
                                    <div class="relative flex items-center justify-center flex-none w-6 h-6 bg-white">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 text-red-500">
                                            <path
                                                fill-rule="evenodd"
                                                d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z"
                                                clip-rule="evenodd" />
                                        </svg>
                                    </div>
                                    <div class="flex flex-col flex-auto">
                                        <p class="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                                            <span class="font-medium text-gray-900">${activity.createdBy.name}</span>
                                            <span t="translation:partial-views.scheduler-items.activities.marked-as-no-show"></span>
                                        </p>
                                        <div if.bind="activity.body.comments" class="px-3 py-2 mt-1 text-xs bg-gray-100 rounded-md">
                                            <p>${activity.body.comments}</p>
                                        </div>
                                    </div>
                                </template>
                                <template if.bind="activity.name === 'UnmarkSchedulerItemAsNoShow'">
                                    <div -bottom-6.class="!$last" class="absolute top-0 left-0 flex justify-center w-6">
                                        <div class="w-px bg-gray-200"></div>
                                    </div>
                                    <div class="relative flex items-center justify-center flex-none w-6 h-6 bg-white">
                                        <div class="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300"></div>
                                    </div>
                                    <p class="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                                        <span class="font-medium text-gray-900">${activity.createdBy.name}</span>
                                        <span t="translation:partial-views.scheduler-items.activities.unmarked-as-no-show"></span>
                                    </p>
                                </template>
                                <template if.bind="activity.name === 'MarkSchedulerItemAsCancelled'">
                                    <div -bottom-6.class="!$last" class="absolute top-0 left-0 flex justify-center w-6">
                                        <div class="w-px bg-gray-200"></div>
                                    </div>
                                    <div class="relative flex items-center justify-center flex-none w-6 h-6 bg-white">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 text-red-500">
                                            <path
                                                fill-rule="evenodd"
                                                d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z"
                                                clip-rule="evenodd" />
                                        </svg>
                                    </div>
                                    <div class="flex flex-col flex-auto">
                                        <p class="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                                            <span class="font-medium text-gray-900">${activity.createdBy.name}</span>
                                            <span t="translation:partial-views.scheduler-items.activities.marked-as-cancelled"></span>
                                        </p>
                                        <div if.bind="activity.body.comments" class="px-3 py-2 mt-1 text-xs bg-gray-100 rounded-md">
                                            <p>${activity.body.comments}</p>
                                        </div>
                                    </div>
                                </template>
                                <template if.bind="activity.name === 'UnmarkSchedulerItemAsCancelled'">
                                    <div -bottom-6.class="!$last" class="absolute top-0 left-0 flex justify-center w-6">
                                        <div class="w-px bg-gray-200"></div>
                                    </div>
                                    <div class="relative flex items-center justify-center flex-none w-6 h-6 bg-white">
                                        <div class="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300"></div>
                                    </div>
                                    <p class="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                                        <span class="font-medium text-gray-900">${activity.createdBy.name}</span>
                                        <span t="translation:partial-views.scheduler-items.activities.unmarked-as-cancelled"></span>
                                    </p>
                                </template>
                                <template if.bind="activity.name === 'SendEmail' && activity.body.status === EmailLogStatuses.New">
                                    <div -bottom-6.class="!$last" class="absolute top-0 left-0 flex justify-center w-6">
                                        <div class="w-px bg-gray-200"></div>
                                    </div>
                                    <div class="relative flex items-center justify-center flex-none w-6 h-6 bg-white">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5 text-primary">
                                            <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 0 1 0 3.75H5.625a1.875 1.875 0 0 1 0-3.75Z" />
                                        </svg>
                                    </div>
                                    <p class="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                                        <span class="font-medium text-gray-900">${activity.createdBy.name}</span>
                                        <span t="translation:partial-views.scheduler-items.activities.email-in-queue"></span>
                                    </p>
                                </template>
                                <template if.bind="activity.name === 'SendEmail' && activity.body.status === EmailLogStatuses.Sent">
                                    <div -bottom-6.class="!$last" class="absolute top-0 left-0 flex justify-center w-6">
                                        <div class="w-px bg-gray-200"></div>
                                    </div>
                                    <div class="relative flex items-center justify-center flex-none w-6 h-6 bg-white">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 text-primary">
                                            <path
                                                fill-rule="evenodd"
                                                d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm4.28 10.28a.75.75 0 0 0 0-1.06l-3-3a.75.75 0 1 0-1.06 1.06l1.72 1.72H8.25a.75.75 0 0 0 0 1.5h5.69l-1.72 1.72a.75.75 0 1 0 1.06 1.06l3-3Z"
                                                clip-rule="evenodd" />
                                        </svg>
                                    </div>
                                    <p class="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                                        <span t="translation:partial-views.scheduler-items.activities.email-sent"></span>
                                    </p>
                                </template>
                                <template if.bind="activity.name === 'SendEmail' && activity.body.status === EmailLogStatuses.Failed">
                                    <div -bottom-6.class="!$last" class="absolute top-0 left-0 flex justify-center w-6">
                                        <div class="w-px bg-gray-200"></div>
                                    </div>
                                    <div class="relative flex items-center justify-center flex-none w-6 h-6 bg-white">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 text-red-500">
                                            <path
                                                fill-rule="evenodd"
                                                d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z"
                                                clip-rule="evenodd" />
                                        </svg>
                                    </div>
                                    <p class="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                                        <span t="translation:partial-views.scheduler-items.activities.email-failed"></span>
                                    </p>
                                </template>
                                <ux-tooltip content="${activity.createdAt | date:'dd-MM-yyyy HH:mm'}">
                                    <time class="flex-none py-0.5 text-xs leading-5 text-gray-500">${activity.createdAt | timeAgo:false:false:true}</time>
                                </ux-tooltip>
                            </li>
                            <li if.bind="activities.length === 0" class="flex items-center justify-start">
                                <p class="text-sm" t="translation:global.labels.no-activities-found"></p>
                            </li>
                            <!-- <li class="relative flex gap-x-4">
                            <div class="absolute top-0 left-0 flex justify-center w-6 h-6">
                                <div class="w-px bg-gray-200"></div>
                            </div>
                            <div class="relative flex items-center justify-center flex-none w-6 h-6 bg-white">
                                <svg class="w-6 h-6 text-indigo-600" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true">
                                    <path
                                        fill-rule="evenodd"
                                        d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                                        clip-rule="evenodd" />
                                </svg>
                            </div>
                            <p class="flex-auto py-0.5 text-xs leading-5 text-gray-500"><span class="font-medium text-gray-900">Alex Curren</span> paid the invoice.</p>
                            <time datetime="2023-01-24T09:20" class="flex-none py-0.5 text-xs leading-5 text-gray-500">1d ago</time>
                        </li> -->
                        </ul>
                    </div>
                </template>
                <template else>
                    <div class="flex flex-col flex-1 pt-10">
                        <div class="grid grid-cols-1 gap-5">
                            <div class="flex flex-col flex-1 space-y-3">
                                <ux-skeleton show-background="true" type="container">
                                    <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                        <ux-skeleton type="line" padding="pr-60"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                    </div>
                                </ux-skeleton>
                                <ux-skeleton show-background="true" type="container">
                                    <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                        <ux-skeleton type="line" padding="pr-48"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-3"></ux-skeleton>
                                    </div>
                                </ux-skeleton>
                                <ux-skeleton show-background="true" type="container">
                                    <div class="flex flex-col w-full px-5 py-4 gap-y-3">
                                        <ux-skeleton type="line" padding="pr-20"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-10"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-8"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-6"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-0"></ux-skeleton>
                                        <ux-skeleton type="line" padding="pr-5"></ux-skeleton>
                                    </div>
                                </ux-skeleton>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
            <div class="sticky bottom-0 z-[999] flex justify-end gap-x-2 border-t border-gray-200 bg-white px-8 py-4">
                <ux-button size="sm" color="dark" mode="outline" disabled.bind="isLoading" click.trigger="cancel()">
                    <span class="pointer-events-none" t="global.buttons.close"></span>
                </ux-button>
            </div>
        </div>
    </section>
</template>
