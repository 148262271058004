<import from="converters/translate" />
<import from="converters/side-to-string" />
<import from="converters/date" />
<import from="../template-therapy-category/template-therapy-category" />
<import from="../template-therapy-step/template-therapy-step" />
<import from="../../../../templates/template-question/template-question" />
<import from="../../../../templates/template-action/template-action" />
<import from="../../../../templates/template-questionnaire/template-questionnaire" />
<import from="../../../../templates/template-widget/template-widget" />

<div class="flex flex-col">
    <div bg-gray-100.class="validation.evaluations[validationIndex].valid" bg-red-100.class="!validation.evaluations[validationIndex].valid" class="flex flex-col px-3 bg-gray-100 rounded-lg">
        <div class="flex items-center">
            <a href="javascript:" click.trigger="collapseOrExpand($event)" cla class="flex items-center justify-between flex-1 min-w-0 py-2 pr-3 ml-2 text-sm gap-x-1">
                <div class="flex items-center flex-1 min-w-0 gap-1">
                    <div class="flex min-w-[1.2rem]">
                        <template if.bind="expanded">
                            <svg class="h-[1.2rem] w-[1.2rem] text-indigo-500" fill="none" viewBox="0 0 24 24">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M9.25 19.25V16.75C9.25 15.6454 8.35457 14.75 7.25 14.75H4.75"></path>
                                <path
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="1.5"
                                    d="M14.75 19.25V16.75C14.75 15.6454 15.6454 14.75 16.75 14.75H19.25"></path>
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M14.75 4.75V7.25C14.75 8.35457 15.6454 9.25 16.75 9.25H19.25"></path>
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M9.25 4.75V7.25C9.25 8.35457 8.35457 9.25 7.25 9.25H4.75"></path>
                            </svg>
                        </template>
                        <template else>
                            <svg class="h-[1.2rem] w-[1.2rem] text-indigo-500" fill="none" viewBox="0 0 24 24">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M4.75 14.75V17.25C4.75 18.3546 5.64543 19.25 6.75 19.25H9.25"></path>
                                <path
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="1.5"
                                    d="M19.25 14.75V17.25C19.25 18.3546 18.3546 19.25 17.25 19.25H14.75"></path>
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M19.25 9.25V6.75C19.25 5.64543 18.3546 4.75 17.25 4.75H14.75"></path>
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M4.75 9.25V6.75C4.75 5.64543 5.64543 4.75 6.75 4.75H9.25"></path>
                            </svg>
                        </template>
                    </div>
                    <div class="flex items-center flex-1 min-w-0">
                        <div
                            bg-gray-100.class="validation.evaluations[validationIndex].valid"
                            bg-red-100.class="!validation.evaluations[validationIndex].valid"
                            class="flex justify-between flex-1 min-w-0 gap-2">
                            <p class="truncate" t="global.labels.evaluation"></p>
                            <p if.bind="registration.schedulerItem">${registration.schedulerItem.start | date}</p>
                            <p else>${registration.createdAt | date}</p>
                        </div>
                    </div>
                </div>
            </a>
        </div>
    </div>
    <template if.bind="expanded && registration">
        <div if.bind="registration.therapyEvaluation.value.stepsToTake.length > 0" class="pl-4">
            <div class="grid grid-cols-12 gap-1 pl-2 mt-1 border-l-2 border-gray-300">
                <template repeat.for="step of registration.therapyEvaluation.value.stepsToTake">
                    <template-action
                        if.bind="step.type === MedicalTherapyEvaluationItemTypes.Action"
                        flattened.bind="recordFlattened"
                        record.bind="record"
                        flow.bind="registration.therapyEvaluation.value.flow"
                        step.bind="step"
                        workspace.bind="workspace"
                        x-scroll-container.bind="xScrollContainer"
                        validation.bind="validation.evaluations[validationIndex].steps[$index]"
                        on-file-selected.bind="onFileSelected"
                        on-file-removed.bind="onFileRemoved"
                        is-evaluation-step.bind="true"
                        remove.bind="removeStep"
                        is-evaluation-step.bind="true"
                        show-options.bind="false"
                        loading.bind="loading"
                        states.bind="states"
                        language.bind="language"
                        registration.bind="registrations[step.id]"
                        registrations.bind="registrations">
                    </template-action>
                    <template-question
                        if.bind="step.type === MedicalTherapyEvaluationItemTypes.Question"
                        flattened.bind="recordFlattened"
                        record.bind="record"
                        flow.bind="registration.therapyEvaluation.value.flow"
                        step.bind="step"
                        x-scroll-container.bind="xScrollContainer"
                        workspace.bind="workspace"
                        validation.bind="validation.evaluations[validationIndex].steps[$index]"
                        on-file-selected.bind="onFileSelected"
                        on-file-removed.bind="onFileRemoved"
                        is-evaluation-step.bind="true"
                        loading.bind="loading"
                        states.bind="states"
                        language.bind="language"
                        registration.bind="registrations[step.id]"
                        registrations.bind="registrations">
                    </template-question>
                    <template-questionnaire
                        if.bind="step.type === MedicalTherapyEvaluationItemTypes.Questionnaire"
                        flattened.bind="recordFlattened"
                        record.bind="record"
                        flow.bind="registration.therapyEvaluation.value.flow"
                        step.bind="step"
                        is-evaluation-step.bind="true"
                        x-scroll-container.bind="xScrollContainer"
                        workspace.bind="workspace"
                        validation.bind="validation.evaluations[validationIndex].steps[$index]"
                        on-file-selected.bind="onFileSelected"
                        states.bind="states"
                        on-file-removed.bind="onFileRemoved"
                        loading.bind="loading"
                        language.bind="language"
                        registration.bind="registrations[step.id]"
                        registrations.bind="registrations">
                    </template-questionnaire>
                    <!-- <template-widget
                        if.bind="step.type === MedicalTherapyEvaluationItemTypes.Widget"
                        flattened.bind="recordFlattened"
                        record.bind="record"
                        container.bind="container"
                        step.bind="step"
                        x-scroll-container.bind="xScrollContainer"
                        validation.bind="validation.evaluations.stepsToTake[$index]"
                        workspace.bind="workspace"
                        states.bind="states"
                        on-file-selected.bind="onFileSelected"
                        on-file-removed.bind="onFileRemoved"
                        loading.bind="loading"
                        language.bind="language"
                        patient.bind="patient"
                        widgets.bind="widgets"
                        add-partial.bind="addPartial"
                        scroll-to-view.bind="scrollToView"
                        registration.bind="registration"
                        manage-translations.bind="manageTranslationsFor"
                        current-user.bind="authenticated.user"
                        registration.bind="registrations[step.id]"
                        registrations.bind="registrations">
                    </template-widget> -->
                </template>
            </div>
        </div>
        <div else class="pl-4 mt-1">
            <div class="flex flex-col items-center justify-center p-2 border border-gray-200 rounded-lg">
                <p class="text-xs text-gray-500" t="translation:components.widgets.examination-treatment.messages.no-evaluation"></p>
            </div>
        </div>
    </template>
</div>
