import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { AttachmentsApiClient } from '@wecore/sdk-attachments';
import {
    DeclarationPerformanceTypes,
    DeclarationPerformancesApiClient,
    DeclarationsApiClient,
    GetDeclarationPerformanceResponse,
    GetDeclarationResponse,
    GetPatientResponse,
    ProcessDeclarationRequest,
    ProcessDeclarationRequestItem,
    ProcessDeclarationRequestItemMutation
} from '@wecore/sdk-healthcare';
import { VecozoApiClient } from '@wecore/sdk-integrations';
import { isDefined } from '@wecore/sdk-utilities';
import { IEventAggregator, inject } from 'aurelia';
import { PartialViewResults } from '../../../enums/partial-view-results';
import { BasePartialView } from '../../../infra/base-partial-view';
import { CacheService } from '../../../infra/cache-service';
import { ErrorHandler } from '../../../infra/error-handler';
import { CustomEvents } from '../../../infra/events';
import { State } from '../../../infra/store/state';
import { ConfirmationOptions } from '../../../models/confirmation-options';
import { EventDetails } from '../../../models/event-details';
import { PartialView } from '../../../models/partial-view';
import { ModalService } from '../../../services/service.modals';
import { UxSelectOption } from '../../../ux/ux-select-option/ux-select-option';
import { UxSelect } from '../../../ux/ux-select/ux-select';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, DeclarationsApiClient, DeclarationPerformancesApiClient, ModalService, VecozoApiClient, AttachmentsApiClient)
export class PartialDeclarationsProcess extends BasePartialView {
    public declaration: GetDeclarationResponse;
    public performances: GetDeclarationPerformanceResponse[];
    public DeclarationPerformanceTypes: typeof DeclarationPerformanceTypes = DeclarationPerformanceTypes;
    public ProcessDeclarationRequestItemMutation: typeof ProcessDeclarationRequestItemMutation = ProcessDeclarationRequestItemMutation;
    public request: ProcessDeclarationRequest;

    public validation: any = {
        valid: true,
        items: []
    };
    public filters: {
        patient?: string;
        type?: DeclarationPerformanceTypes;
    } = {};

    private declarationId: string;
    public itemStates: any = {};

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly declarationsApi: DeclarationsApiClient,
        private readonly performancesApi: DeclarationPerformancesApiClient,
        private readonly modalService: ModalService
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.declarationId = view.data.id;
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                const [declaration, performances] = await Promise.all([
                    this.declarationsApi.getById(this.declarationId, this.authenticated.workspace.id), //
                    this.performancesApi.search(
                        this.authenticated.workspace.id,
                        500,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        [this.declarationId]
                    )
                ]);
                this.declaration = declaration;
                this.performances = performances.data;

                this.request = new ProcessDeclarationRequest({
                    items: this.performances.map((p) => {
                        this.validation.items.push({
                            awardedLower: true,
                            awardedNotZero: true
                        });
                        this.itemStates[p.id] = { visible: true };
                        return new ProcessDeclarationRequestItem({
                            performanceId: p.id,
                            mutation: ProcessDeclarationRequestItemMutation.Accept,
                            awarded: p.totalInclVat
                        });
                    })
                });

                this.baseLoaded = true;
            })
            .catch((x) => this.errorHandler.handle('PartialDeclarationsProcess.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }

    public handlePatientSelected = async (patient: GetPatientResponse): Promise<void> => {
        this.filters.patient = patient?.id;
        this.filter();
    };

    public async handleTypeSelected(e: CustomEvent<EventDetails<UxSelect, UxSelectOption>>): Promise<void> {
        const type = e.detail.values.value as DeclarationPerformanceTypes;
        this.filters.type = type;
        this.filter();
    }

    public close(): void {
        super.remove({
            result: PartialViewResults.Ok,
            updateUrl: true
        });
    }

    public cancel(): void {
        super.remove({
            result: PartialViewResults.Cancelled,
            updateUrl: true
        });
    }

    public async process(): Promise<void> {
        const valid = this.validate();
        if (!valid) return;

        await this.modalService.confirm(
            new ConfirmationOptions({
                title: this.t.tr('translation:partial-views.declarations.questions.process.title'),
                message: this.t
                    .tr('translation:partial-views.declarations.questions.process.message') //
                    .replace('{entity}', `<span>'${this.declaration.trackingNumber}'</span>`),
                type: 'warning',
                btnOk: this.t.tr('translation:global.buttons.process'),
                callback: async (confirmed: boolean): Promise<void> => {
                    if (confirmed) {
                        try {
                            this.isLoading = true;
                            const declaration = await this.declarationsApi.process(this.declarationId, this.authenticated.workspace.id, this.request);
                            this.events.publish(CustomEvents.DeclarationsUpdated, { declaration });
                            this.remove({ result: PartialViewResults.Ok });
                        } catch (e) {
                            this.isLoading = false;
                            this.errorHandler.handle('[process-declaration]', e);
                        }
                    }
                }
            })
        );
    }

    private filter(): void {
        for (const item of this.request.items) {
            const performance = this.performances.find((p) => p.id === item.performanceId);
            let visible = true;
            if (isDefined(this.filters.patient) && visible) {
                visible = performance.patient.id === this.filters.patient;
            }
            if (isDefined(this.filters.type) && visible) {
                visible = performance.type === this.filters.type;
            }

            this.itemStates[item.performanceId].visible = visible;
        }
    }

    private validate(): boolean {
        for (let index = 0; index < this.request.items.length; index++) {
            const item = this.request.items[index];
            const performance = this.performances.find((p) => p.id === item.performanceId);

            if (item.mutation === ProcessDeclarationRequestItemMutation.PartiallyAccept) {
                // Check if the awarded amount is less than the total of the performance.
                this.validation.items[index].awardedLower =
                    // 3. Must be lower than the performance initial total. If it is not lower,
                    // the performance should be accepted.
                    Number(item.awarded) < Number(performance.totalInclVat);
                // 2. Must be higher than 0. If it is 0, the performance should be rejected.
                this.validation.items[index].awardedNotZero = Number(item.awarded) > 0;
            }
        }

        this.validation.valid = this.validation.items.every((x: any) => x.awardedNotZero && x.awardedLower);

        return this.validation.valid;
    }
}
