import { I18N } from '@aurelia/i18n';
import { Store } from '@aurelia/store-v1';
import { ActivitiesApiClient, GetActivityResponse, SchedulerItemTypes } from '@wecore/sdk-core';
import { SchedulerItemsApiClient, GetSchedulerItemResponse, SchedulerContext, EmailLogStatuses } from '@wecore/sdk-healthcare';
import { IEventAggregator, inject } from 'aurelia';
import { PartialViewResults } from '../../enums/partial-view-results';
import { BasePartialView } from '../../infra/base-partial-view';
import { CacheService } from '../../infra/cache-service';
import { ErrorHandler } from '../../infra/error-handler';
import { State } from '../../infra/store/state';
import { PartialView } from '../../models/partial-view';

@inject(CacheService, ErrorHandler, IEventAggregator, Store<State>, I18N, SchedulerItemsApiClient, ActivitiesApiClient)
export class PartialSchedulerItemsDetails extends BasePartialView {
    public schedulerItem: GetSchedulerItemResponse;
    public SchedulerItemTypes: typeof SchedulerItemTypes = SchedulerItemTypes;
    public activities: GetActivityResponse[] = [];
    public EmailLogStatuses: typeof EmailLogStatuses = EmailLogStatuses;

    private itemId: string;

    public constructor(
        public cache: CacheService, //
        public errorHandler: ErrorHandler,
        public events: IEventAggregator,
        public store: Store<State>,
        public t: I18N,
        private readonly itemsApi: SchedulerItemsApiClient,
        private readonly activitiesApi: ActivitiesApiClient
    ) {
        super(cache, errorHandler, events, store, t);
    }

    public activate(view: PartialView): void {
        super.setView({ view });
        this.itemId = view.data.id;
    }

    public attached(): void {
        super
            .initView()
            .then(async () => {
                this.schedulerItem = await this.itemsApi.getById(this.itemId, this.authenticated.workspace.id);
                const response = await this.activitiesApi.search(this.authenticated.workspace.id, '', 250, 0, undefined, undefined, undefined, undefined, undefined, undefined, [
                    this.schedulerItem.id
                ]);
                this.activities = response.data;
                this.baseLoaded = true;
            })
            .catch((x) => this.errorHandler.handle('PartialSchedulerItemsDetails.attached', x));
    }

    public detaching(): void {
        super.removeChildViews();
        super.remove({ result: PartialViewResults.Detached });
    }

    public async cancel(): Promise<void> {
        await super.remove({
            result: PartialViewResults.Cancelled,
            updateUrl: true
        });
    }
}
